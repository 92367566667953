import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AuthWrapper from "../../../components/auth-wrapper";

import {
  changeField,
  trySubmitted,
  changeFieldErrorStatus,
  sendForm,
} from "./actions";

import styles from "./reset-password.scss";

import utils from "../../../utils";

import visibilityOn from "../../../images/eye-visibility-on.svg";
import visibilityOff from "../../../images/eye-visibility-off.svg";

const { getToken } = utils.auth;

class ResetPassword extends Component {
  state = {
    isVisiblePassword: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.trySubmitted();

    const { user, password, confirmPassword, match } = this.props;

    const { id } = match.params;

    const token = id;

    if (this.validateForm()) {
      this.props.sendForm({ user, password, confirmPassword, token });
    }
  };

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.props.changeField(name, value);
    this.validateField(name, value);
  };

  validateField(fieldName, value) {
    let statusUser = false;
    let statusPassword = false;
    let statusConfirmPassword = false;

    const { user, password, confirmPassword } = this.props;

    let passwordRegPass = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
    let emailRegPass = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // taken from https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript

    switch (fieldName) {
      case "user":
        statusUser = emailRegPass.test(String(value).toLowerCase());
        this.props.changeFieldErrorStatus("user", statusUser);
        break;
      case "password":
        statusPassword = value.length >= 8 && passwordRegPass.test(value);
        statusConfirmPassword =
          value.length >= 8 &&
          passwordRegPass.test(value) &&
          confirmPassword === value;
        this.props.changeFieldErrorStatus("password", statusPassword);
        this.props.changeFieldErrorStatus(
          "confirmPassword",
          statusConfirmPassword
        );
        break;
      case "confirmPassword":
        statusConfirmPassword =
          value.length >= 8 &&
          passwordRegPass.test(value) &&
          password === value;
        this.props.changeFieldErrorStatus(
          "confirmPassword",
          statusConfirmPassword
        );
        break;
      default:
        break;
    }
  }

  validateForm() {
    return (
      this.props.validFields.user &&
      this.props.validFields.password &&
      this.props.validFields.confirmPassword
    );
  }

  toggleVisibility = () => {
    this.setState((prevState) => ({
      isVisiblePassword: !prevState.isVisiblePassword,
    }));
  };

  componentDidMount() {
    if (getToken()) {
      this.props.history.push("/");
    }
  }

  render() {
    const {
      user,
      password,
      confirmPassword,
      isTrySubmit,
      validFields,
      error,
      loading,
    } = this.props;

    const { isVisiblePassword: isVisiblePassword } = this.state;

    const serverError = error ? (
      <div className={styles.errorBlock}>{error}</div>
    ) : null;
    const loadingBtn = loading ? "" : null;

    return (
      <AuthWrapper>
        <div className={styles.wrapper}>
          <h3 className={styles.header}>Reset Password</h3>
          <form onSubmit={this.handleSubmit}>
            {loading ? <div className={styles.loader}></div> : null}
            {serverError}
            <div
              className={`${styles.formGroup} ${
                !validFields.user && isTrySubmit ? styles.error : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                name="user"
                placeholder="E-Mail"
                value={user}
                onChange={this.handleInput}
              />
              <p className={styles.errorMsg}>Invalid Email Address</p>
            </div>
            <div
              className={`${styles.formGroup} ${
                !validFields.password && isTrySubmit ? styles.error : ""
              }`}
            >
              <div className={styles.eyeCont} onClick={this.toggleVisibility}>
                {isVisiblePassword ? (
                  <img src={visibilityOn} />
                ) : (
                  <img src={visibilityOff} />
                )}
              </div>
              <input
                type={isVisiblePassword ? "text" : "password"}
                className="form-control"
                name="password"
                placeholder="New Password"
                value={password}
                onChange={this.handleInput}
              />
              <p className={styles.errorMsg}>
                Min length 8 characters and must to be special characters(&!#..)
              </p>
            </div>
            <div
              className={`${styles.formGroup} ${
                !validFields.confirmPassword && isTrySubmit ? styles.error : ""
              }`}
            >
              <input
                type="password"
                className="form-control"
                name="confirmPassword"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={this.handleInput}
              />
              <p className={styles.errorMsg}>
                Passwords do not match or not valid
              </p>
            </div>
            <button type="submit" disabled={loading}>
              Reset Password
            </button>
          </form>
        </div>
      </AuthWrapper>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.auth.resetPassword.data.user,
    password: state.auth.resetPassword.data.password,
    confirmPassword: state.auth.resetPassword.data.confirmPassword,
    isTrySubmit: state.auth.resetPassword.isTrySubmit,
    validFields: state.auth.resetPassword.validFields,
    error: state.auth.resetPassword.error,
    loading: state.auth.resetPassword.loading,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeField,
      trySubmitted,
      changeFieldErrorStatus,
      sendForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
