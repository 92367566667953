import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Spinner from "../spinner";

import styles from "./infinite-data-table.scss";
import emptyIcon from "../../images/error-visual.svg";

function InfiniteDataTable(props) {
  const {
    table,
    dataLength,
    noDataComponent,
    fetchMoreData,
    loading,
    hasMore,
    loadingMore,
  } = props;

  const finishLoad = !hasMore ? (
    <div className={styles.finishList}></div>
  ) : null;

  if (!loading && dataLength === 0) {
    // && !competitorsList && competitorsList.length > 0
    return (
      noDataComponent ?? (
        <div className={styles.empty}>
          <div className={styles.emptyBox}>
            <img src={emptyIcon} className={styles.emptyIcon} />
          </div>
          <h4>No more data...</h4>
        </div>
      )
    );
  }

  return (
    <div className={styles.wrapper}>
      <InfiniteScroll
        dataLength={dataLength}
        next={loading ? null : fetchMoreData}
        hasMore={hasMore}
        style={{ overflow: "inherit" }}
        loader={
          <div className={styles.spinnerHasMore}>
            {loadingMore ? <Spinner height={100} /> : null}
          </div>
        }
      >
        <div>{table}</div>
      </InfiniteScroll>
      {finishLoad}
    </div>
  );
}

export default InfiniteDataTable;
