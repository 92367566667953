import React from "react";
import { chartTickPriceFormat } from "../../../../styles/cell-decorators";
import ChartComponent from "../../../../components/dashboard-components/chart-component/chart-component";

export default function CategoriesPricingChartComponent(props) {
  return (
    <ChartComponent
      {...props}
      title={"Category Average Selling Price"}
      currentKey={"currentAvgSellingPrice"}
      previousKey={"previousAvgSellingPrice"}
      changeKey={"avgSellingPriceDiff"}
      taglineSubject={"The category average selling price"}
      legendName={"Average Selling Price"}
      yTickFormat={chartTickPriceFormat}
    />
  );
}
