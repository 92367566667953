import React from "react";
import styles from "./full-heading.scss";
import {
  getEventFullDisplayName,
  getEventsDefinition,
  getSelectedEventTypeProperties,
} from "../../utils";
import CustomTriggerIcon from "../../../../images/custom-trigger-icon.svg";

const FullHeading = ({ configData, formData }) => {
  const eventsDefinition = getEventsDefinition(configData.filterDefinitions);
  const eventTypeProperties = getSelectedEventTypeProperties(
    formData?.query?.productAttributes,
    eventsDefinition
  );

  const subtitle =
    configData.mode == "new" ? "Create new trigger" : "Edit trigger";
  const title = getEventFullDisplayName(eventTypeProperties);
  return (
    <div className={styles.headingContainer}>
      <img
        className={styles.headingIcon}
        src={eventTypeProperties?.icon ?? CustomTriggerIcon}
      />
      <div className={styles.textLayout}>
        <div className={styles.subtitle}>{subtitle}</div>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default FullHeading;
