import {
  APPLY_INITIAL_STATE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
} from "./constants";

export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

export const fetchDataRequest = (component) => ({
  type: FETCH_DATA_REQUEST,
  payload: { component },
});

export const fetchDataSuccess = (component, data) => (dispatch) =>
  dispatch({
    type: FETCH_DATA_SUCCESS,
    payload: { component, data },
  });

export const fetchDataFail = (error) => ({
  type: FETCH_DATA_FAIL,
  payload: { error },
});
