import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./styles.scss";
import Button from "../../../../components/button";

import {
  fetchMarketShareInsightsAiSummary,
  fetchMarketShareInsightsAiFullInsights,
} from "../../../insights/insights-ai-sidebar/actions.js";
import MarketShareInsightsAiSidebarBody from "./body";
import MarketShareInsightsAiLoader from "./loader";
import MarketShareInsightsAiError from "./error";
import { isEmpty } from "lodash";
import {
  convertDataToClipboardText,
  convertDataToRenderedText,
} from "./text-utils";
import moment from "moment";
import { extractTextFromElement } from "../../../../utils/other/utilities";
import AiSidebarBody from "../../../insights/insights-ai-sidebar/ai-sidebar-body";
import { MARKET_SHARE_VIEW_TYPE_DATA } from "../../constants";

export const SELECTIONS = {
  none: 0,
  summary: 1,
  fullInsights: 2,
};

function MarketShareInsightsAiSidebar(props) {
  const { textToCopy, data, marketShareData } = props;

  const [currentSelection, setCurrentSelection] = useState(SELECTIONS.summary);
  const [bodyText, setBodyText] = useState("");

  const selectionName = Object.keys(SELECTIONS).find(
    (key) => SELECTIONS[key] === currentSelection
  );
  const selectionData = data[selectionName];
  const loading = selectionData?.loading ?? false;
  const hasData = !loading && bodyText !== "";

  const handleSelection = (selection) => {
    if (currentSelection !== selection) {
      setCurrentSelection(selection);
    }
  };

  useEffect(() => {
    const requestedParams = {
      insightViews: {
        ...props.currentViewConfigQuery,
        ...props.selectedDateRange,
        ...props.selectedPreviousDateRange,
        comparePeriod: props.comparePeriod,
      },
    };

    if (isEmpty(data)) {
      props.fetchMarketShareInsightsAiSummary(requestedParams);
      props.fetchMarketShareInsightsAiFullInsights(requestedParams);
    }
  }, [data]);

  const subtitle = (
    <div className={styles.subtitle}>
      {"Comparison of Sales: "}
      <strong>{moment(props.selectedDateRange.startDate).format("ll")}</strong>
      {" to "}
      <strong>{moment(props.selectedDateRange.endDate).format("ll")}</strong>
      {" vs. "}
      <strong>
        {moment(props.selectedPreviousDateRange.previousStartDate).format("ll")}
      </strong>
      {" to "}
      <strong>
        {moment(props.selectedPreviousDateRange.previousEndDate).format("ll")}
      </strong>
    </div>
  );

  useEffect(() => {
    if (currentSelection !== SELECTIONS.none && !isEmpty(selectionData?.data)) {
      setBodyText(
        convertDataToRenderedText(
          currentSelection,
          selectionData?.data,
          marketShareData
        )
      );
      textToCopy.current =
        extractTextFromElement(subtitle) +
        "\n" +
        convertDataToClipboardText(currentSelection, selectionData?.data);
    } else {
      textToCopy.current = "";
    }
  }, [currentSelection, selectionData]);

  const header = (
    <div className={styles.main}>
      {subtitle}
      <div className={styles.controls}>
        <Button
          className={styles.button}
          text={"Spotlight"}
          imageClass={styles.summaryButton}
          active={true}
          selected={currentSelection === SELECTIONS.summary}
          onClick={() => {
            handleSelection(SELECTIONS.summary);
          }}
        />
        <Button
          className={styles.button}
          text={"Full insights"}
          imageClass={styles.fullInsightsButton}
          active={true}
          selected={currentSelection === SELECTIONS.fullInsights}
          onClick={() => {
            handleSelection(SELECTIONS.fullInsights);
          }}
        />
      </div>
      {hasData && (
        <AiSidebarBody
          text={bodyText}
          viewTypeId={MARKET_SHARE_VIEW_TYPE_DATA.id}
          featureName={"insightsAi"}
          featureValue={selectionName}
        />
      )}
      {loading && <MarketShareInsightsAiLoader />}
    </div>
  );

  const error = <MarketShareInsightsAiError />;
  const isError = selectionData != null && selectionData.data == null;

  return isError ? error : header;
}

export const mapStateToProps = (state) => {
  return {
    marketShareData: state.marketShare.marketShareSortedData,
    data: state.insights.insights.insightsAiData,
    currentViewConfigQuery: state.insights.insights.currentViewConfig.query,
    settings: state.insights.insights.currentViewConfig.query.settings,
    selectedDateRange: state.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.insights.mbFilterRow.comparePeriod,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMarketShareInsightsAiSummary,
      fetchMarketShareInsightsAiFullInsights,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketShareInsightsAiSidebar);
