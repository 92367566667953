import React, { useState } from "react";
import styles from "./side-legend.scss";
import { LegendItem, LegendLabel } from "@visx/legend";
import { useRef } from "react";
import { createPortal } from "react-dom";

export default function LegendItemWrapper(props) {
  const { size = 16, label, strokeDasharray, width = 2 } = props;
  const [isHovered, setIsHovered] = useState(false);
  const [position, setPosition] = useState({});
  const itemRef = useRef();

  const handleMouseEnter = (e) => {
    setPosition(e.target.getBoundingClientRect());
    setIsHovered(true);
  };
  const handleMouseLeave = (e) => {
    setIsHovered(false);
  };

  const legendDisplayText = label.image ? (
    <div className={styles.hoverWrapper}>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={styles.displayWrapper}
      >
        <img src={label.image} style={{ width: "45px", height: "45px" }} />
        <span>{label.name}</span>
      </div>
      {isHovered &&
        createPortal(
          <div
            className={styles.hoverText}
            style={{
              top: position.top + window.scrollY,
              left: position.left + window.scrollX - 425,
            }}
          >
            <img
              src={label.image}
              style={{ width: "100px", height: "100px" }}
            />
            <div className={styles.labelName}>{label.name}</div>
            <div className={styles.graphTitle}>{label.graphtitle}</div>
            {label.totalvalue}
          </div>,
          document.body
        )}
    </div>
  ) : (
    <div className={styles.displayWrapper}>{label.text}</div>
  );

  return (
    <LegendItem ref={itemRef} className={styles.item}>
      <svg width={size} height={size}>
        <line
          x1="0"
          y1={size / 2}
          x2={size}
          y2={size / 2}
          style={{
            strokeWidth: width,
            stroke: label.value,
            strokeDasharray: strokeDasharray ?? "0",
          }}
        />
      </svg>
      {label.text && (
        <>
          <LegendLabel className={styles.label}>
            {legendDisplayText}
          </LegendLabel>
        </>
      )}
    </LegendItem>
  );
}
