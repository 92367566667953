import React, { useState, useEffect } from "react";
import styles from "./checkbox.scss";

const Selector = (props) => {
  // const [checked, setChecked] = useState(props.checked);

  // useEffect(() => {
  //   setChecked(props.checked)
  // }, [props.checked])

  const change = () => {
    // const invertedCheck = !checked
    // setChecked(invertedCheck)
    props.onChange && props.onChange(!props.checked);
  };

  const className = `${styles[props.className]} ${
    props.boldBorder ? styles.boldBorder : ""
  }`;

  return (
    <>
      <div className={className} onClick={change}>
        {props.checked ? (
          <i
            className={styles.checked}
            style={
              (props.checked ? props.styleChecked : props.styleUnchecked) ?? {}
            }
          ></i>
        ) : null}
      </div>
    </>
  );
};

export default Selector;
