import React from "react";
import BarChart from "../../../../images/bar-chart.svg";
import LineChart from "../../../../images/line-chart.svg";
import PieChart from "../../../../images/pie-chart-icon.svg";
import ButtonGroup from "../../../button-group/button-group";

export const types = [
  {
    id: "Line",
    label: "Line Chart",
    image: LineChart,
  },
  {
    id: "Bar",
    label: "Bar Chart",
    image: BarChart,
  },
  {
    id: "Pie",
    label: "Pie Chart",
    image: PieChart,
  },
];

function ChartToggleComponent(props) {
  const { availableTypes, selectedType = "Line", onSelectType } = props;

  return (
    <ButtonGroup
      buttons={types.filter((x) => availableTypes.includes(x.id))}
      toggledButton={selectedType}
      onToggle={onSelectType}
    />
  );
}

export default ChartToggleComponent;
