import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Dropdown from "../dropdown";
import HeatmapGrid from "../heatmap-grid";
import utils from "../../utils";

import styles from "./availability-heatmap.scss";

const { transformDataForHeadmap } = utils.other;

class AvailabilityHeatmap extends Component {
  state = {
    options: [
      {
        id: 1,
        name: "Last 7 days",
      },
      {
        id: 2,
        name: "Last 30 days",
      },
    ],
  };

  componentDidMount() {
    const {
      dataClient,
      dataMatched,
      nameClient,
      nameMatched,
      availabilitySelected,
    } = this.props;

    this.props.changeAvailabilityChart(
      transformDataForHeadmap(
        dataClient,
        dataMatched,
        availabilitySelected,
        nameClient,
        nameMatched
      )
    );
  }

  handleSortChange = (item) => {
    const { dataClient, dataMatched, nameClient, nameMatched } = this.props;
    this.props.sortAvailabilityChart(
      item.name,
      transformDataForHeadmap(
        dataClient,
        dataMatched,
        item.name,
        nameClient,
        nameMatched
      )
    );
  };

  componentWillUnmount() {
    this.props.sortAvailabilityChart("Last 30 days", {
      xLabels: [],
      yLabels: [],
      data: [],
    });
  }

  render() {
    const { availabilitySelected, availabilityChartData, title } = this.props;
    const { options } = this.state;
    const { xLabels, yLabels, data } = availabilityChartData;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          {title && <div className={styles.title}>{title}</div>}
          <div className={styles.legents}>
            <div className={styles.legendItem}>
              <div
                className={`${styles.legendLabel} ${styles.lightgray}`}
              ></div>
              In Stock
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.legendLabel} ${styles.darkgray}`}></div>
              Out of Stock
            </div>
            <div className={styles.legendItem}>
              <div className={`${styles.legendLabel} ${styles.white}`}></div>
              No Data
            </div>
          </div>
          {availabilitySelected && (
            <div className={styles.select}>
              <Dropdown
                size="small"
                trim={85}
                options={options}
                value={availabilitySelected}
                handleSelect={this.handleSortChange}
              />
            </div>
          )}
        </div>
        <div className={styles.grid}>
          <HeatmapGrid data={data} xLabels={xLabels} yLabels={yLabels} />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailabilityHeatmap);
