import React from "react";
import ChartComponentLoader from "../../../../../../components/dashboard-components/chart-component/chart-component-loader";
import MarketShareBaseChartLoader from "./market-share-base-chart-loader";

export default function MarketShareBarChartLoader(props) {
  return (
    <MarketShareBaseChartLoader>
      <ChartComponentLoader />
    </MarketShareBaseChartLoader>
  );
}
