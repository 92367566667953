import React from "react";
import defaultStyles from "../dropdown2.scss";

function DropdownTitleString(props) {
  const { title } = props;
  return (
    <>
      <p className={defaultStyles.headerTitle}>
        <span>{title}</span>
        {/* <span id = {title + "_value"} style={{width: trim ? trim : 'auto'}}>{value}</span> */}
      </p>
    </>
  );
}

export default DropdownTitleString;
