import React, { useEffect } from "react";

import styles from "./market-share-chart.scss";
import ChartTabs from "../../../../../components/charts/visx/chart-tabs";
import ChartToggleComponent from "../../../../../components/charts/visx/toggle/chart-toggle-component";
import MarketShareBarChartWrapper from "./market-share-bar-chart-wrapper";
import { changeMarketShareSettings } from "../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { useChartSettings } from "./market-share-chart-utils";
import MarketShareLineChartWrapper from "./market-share-line-chart-wrapper";
import { isEmpty } from "lodash";
import { analyticsChartInteraction } from "../../../../shared/analytics/actions";
import { MARKET_SHARE_VIEW_TYPE_DATA, PIVOT_NAMES } from "../../../constants";
import { CHART_TYPE_SELECTED } from "../../../../shared/analytics/constants";

const DEFAULT_CHART_SETTINGS = {
  selectedType: "Bar",
  selectedTab: 0,
  showComparePeriod: false,
};

function MarketShareChartComponent(props) {
  const {
    id,
    marketShareBarChartData = [],
    marketShareLineChartData,
    selectedDateRange,
    selectedPreviousDateRange,
    entities,
    config,
    settings,
    changeMarketShareSettings,
    columnsConfig,
    currentViewConfigQuery,
    loaderComponent,
    highlightKey,
  } = props;

  const [chartSettings, setChartSettings] = useChartSettings(
    id,
    settings,
    changeMarketShareSettings,
    DEFAULT_CHART_SETTINGS
  );
  const tabConfig = config[chartSettings.selectedTab] ?? {};

  const isLoading =
    isEmpty(marketShareBarChartData) || isEmpty(marketShareLineChartData);

  const onTabSelect = (id) => {
    const i = config.findIndex((x) => x.id === id);
    if (i >= 0) setChartSettings({ selectedTab: i }, false);
  };

  useEffect(() => {
    if (_.isEmpty(config[chartSettings.selectedTab])) onTabSelect(config[0].id);
  }, [settings.dimension]);

  const chartName = () => {
    let selectedSortedColumn = columnsConfig.find(
      (column) => column.id === currentViewConfigQuery.sortField
    );
    let sortOrder = currentViewConfigQuery.sortOrder === 1 ? "DESC" : "ASC";

    let innerString =
      settings.dimension !== 4
        ? `for the selected ${PIVOT_NAMES[settings.dimension]}`
        : `by ${settings.dimensionPivot}`;

    const sortOrderWithArrow = (
      <span
        className={`${sortOrder === "ASC" ? styles.ascIcon : styles.descIcon} ${
          styles.sortOrder
        }`}
      >
        {sortOrder}
      </span>
    );

    return (
      <span>
        {`${tabConfig.title} ${innerString} sorted by ${selectedSortedColumn?.label} `}
        {sortOrderWithArrow}
      </span>
    );
  };

  const chartComponent =
    chartSettings.selectedType === "Bar" ? (
      <MarketShareBarChartWrapper
        id={id}
        chartData={marketShareBarChartData}
        selectedDateRange={selectedDateRange}
        selectedPreviousDateRange={selectedPreviousDateRange}
        entities={entities}
        tabConfig={tabConfig}
        dimension={settings.dimension}
        chartName={chartName()}
        columnsConfig={columnsConfig}
      />
    ) : (
      <MarketShareLineChartWrapper
        id={id}
        chartData={marketShareLineChartData}
        barChartData={marketShareBarChartData}
        entities={entities}
        tabConfig={tabConfig}
        chartName={chartName()}
        columnsConfig={columnsConfig}
        highlightKey={highlightKey}
      />
    );

  const onSelectChartType = (t) => {
    setChartSettings({ selectedType: t });
    analyticsChartInteraction(
      MARKET_SHARE_VIEW_TYPE_DATA.id,
      id,
      CHART_TYPE_SELECTED,
      t
    );
  };

  return (
    <>
      {chartSettings.selectedTab < config.length && (
        <div className={styles.mainChartWrapper}>
          <div className={styles.chartControls}>
            <ChartTabs
              options={config}
              selected={tabConfig.id}
              onSelect={(id) => onTabSelect(id)}
            />
            <div className={styles.toggle}>
              <ChartToggleComponent
                availableTypes={["Line", "Bar"]}
                selectedType={chartSettings.selectedType}
                onSelectType={(t) => onSelectChartType(t)}
              />
            </div>
          </div>
          <div className={styles.chartWrapper}>
            {isLoading ? loaderComponent : chartComponent}
          </div>
        </div>
      )}
    </>
  );
}

export const mapStateToProps = (state) => {
  return {
    settings:
      state.marketShare.insights.insights.currentViewConfig.query.settings,
    currentViewConfigQuery:
      state.marketShare.insights.insights.currentViewConfig.query,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeMarketShareSettings }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketShareChartComponent);
