import {
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  FETCH_BRANDS_CANCEL,
  CLEAR_BRANDS,
} from "./constants";

import BrandsService from "../../../services/brandsService";

const brandsService = new BrandsService();

export const brandsRequested = () => ({
  type: FETCH_BRANDS_REQUEST,
});

export const brandsSuccessed = (data) => ({
  type: FETCH_BRANDS_SUCCESS,
  payload: { data },
});

export const brandsError = (error) => ({
  type: FETCH_BRANDS_FAILURE,
  payload: { error },
});

export const brandsCancel = () => ({
  type: FETCH_BRANDS_CANCEL,
});

export const fetchBrands = (rootDomain) => (dispatch) => {
  dispatch(brandsRequested());

  return brandsService
    .fetchBrands(rootDomain)
    .then((res) => {
      dispatch(brandsSuccessed(res));
      return res;
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(brandsCancel());
      } else {
        dispatch(brandsError(err.message));
      }
    });
};

export const clearBrands = () => ({
  type: CLEAR_BRANDS,
});
