import { ApiService, cancelCompetitors } from "./apiService";
const apiService = new ApiService();

export default class CompetitorsService {
  fetchCompetitors() {
    if (cancelCompetitors != undefined) {
      cancelCompetitors("cancel");
    }

    const res = apiService
      .requestCompetitorsGET(`/competitors`)
      .then((res) => {
        return Promise.resolve(this._transformData(res.data));
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  _transformData = (data = []) => {
    data.unshift({ name: "All", rootDomain: "", logoImage: undefined });
    let result = data.map((item, i) => {
      return {
        ...item,
        id: i,
      };
    });
    return result;
  };
}
