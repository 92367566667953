import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Spinner from "../../../../../components/spinner";
import InsightsRevenueLeakageListItem from "../insights-revenue-leakage-list-item";
import InsightsRevenueLeakageListInner from "../insights-revenue-leakage-list-inner/insights-revenue-leakage-list-inner";

import styles from "./insights-revenue-leakage-list.scss";
import utils from "../../../../../utils";
import {
  changeCategory,
  changeCategoryLevel1,
  fetchInsightsLevelOne,
  fetchInsightsLevelTwo,
  clearLevelOne,
  clearLevelTwo,
  fetchInsightsBrands,
} from "../../../actions";

import { fetchSkus } from "../../../insights-skus/actions";

const { generateQueryUrl, generateQueryConfig } = utils.skus;

class InsightsRevenueLeakageList extends Component {
  getSum = (item = null) => {
    let result = 0;

    if (item) {
      item.map((item) => {
        result += item.outflow;
      });
    }

    return result;
  };

  sortItems = (items = [], flag) => {
    if (flag === "Ascending") {
      return items.sort((a, b) => b.outflow - a.outflow);
    }

    return items.sort((a, b) => a.outflow - b.outflow);
  };

  handleClickItem = (name) => {
    const {
      activeCategory,
      byCategories,
      config,
      filterOptions,
      /* activeSort, */ competitorSelected,
      insightsFiltersValues,
    } = this.props;
    const { filter } = config;
    const {
      skusConfig,
      skusConfig: { activeSort },
    } = insightsFiltersValues;

    let nameCurrent = name === activeCategory ? "" : name;
    this.props.changeCategory(nameCurrent);

    if (nameCurrent === "") {
      this.props.clearLevelOne();
    } else {
      this.props.fetchInsightsLevelOne(nameCurrent, byCategories);
    }

    // let queryConfig = generateQueryConfig(this.props.skus.config, 'sort', activeSort);
    let queryConfig = generateQueryConfig(
      insightsFiltersValues.skusConfig,
      "sort",
      activeSort
    );
    queryConfig = generateQueryConfig(
      queryConfig,
      "competitorRootDomain",
      competitorSelected
    );
    const query = generateQueryUrl(queryConfig);
    const codedName = undefined; // filterOptions.find(x => x.name === filter).codedName;
    this.props.fetchSkus(
      "topCategoryLevel1ProductList",
      query,
      nameCurrent,
      undefined,
      codedName
    );
    this.props.fetchInsightsBrands(competitorSelected, nameCurrent);
  };

  handleClickInnerItem = (name) => {
    const {
      activeCategory,
      level1ActiveCategory,
      level1ByCategories,
      config,
      filterOptions,
      /* activeSort, */ competitorSelected,
      activeSortDescending,
      insightsFiltersValues,
    } = this.props;
    const { filter } = config;
    const {
      skusConfig,
      skusConfig: { activeSort },
    } = insightsFiltersValues;

    let nameCurrent = name === level1ActiveCategory ? "" : name;
    this.props.changeCategoryLevel1(nameCurrent);

    if (nameCurrent === "") {
      this.props.clearLevelTwo();
    } else {
      this.props.fetchInsightsLevelTwo(
        activeCategory,
        nameCurrent,
        level1ByCategories
      );
    }

    // let queryConfig = generateQueryConfig(this.props.skus.config, 'sort', activeSort);
    let queryConfig = generateQueryConfig(skusConfig, "sort", activeSort);
    queryConfig = generateQueryConfig(
      queryConfig,
      "competitorRootDomain",
      competitorSelected
    );
    const query = generateQueryUrl(queryConfig);
    const codedName = undefined; //filterOptions.find(x => x.name === filter).codedName;
    this.props.fetchSkus(
      "topCategoryLevel2ProductList",
      query,
      activeCategory,
      nameCurrent,
      codedName
    );
    this.props.fetchInsightsBrands(
      competitorSelected,
      activeCategory,
      nameCurrent
    );
  };

  render() {
    const {
      loadingRevenue,
      byCategories,
      sortFlag,
      loadingRevenueItem,
      skus,
      level1ByCategories,
      activeCategory,
      level1ActiveCategory,
      loadingRevenueBlocked,
    } = this.props;

    const sum = this.getSum(byCategories);
    const sortCategories = this.sortItems(byCategories, sortFlag);

    if (loadingRevenue) {
      return (
        <div className={styles.spinner}>
          <Spinner height={100} />
        </div>
      );
    }

    if (sortCategories.length === 0) {
      return <div className={styles.emptyList}></div>;
    }

    const blockedLoader =
      loadingRevenueBlocked || skus.loading ? (
        <div className={styles.blockedLoader}></div>
      ) : null;

    return (
      <div id="revenue_leakage_list" className={styles.wrapper}>
        {blockedLoader}
        {sortCategories
          ? sortCategories.map((item) => (
              <InsightsRevenueLeakageListItem
                key={item.competitorCategoryName}
                item={item}
                sum={sum}
                activeCategory={activeCategory}
                loadingRevenue={loadingRevenue}
                handleClickItem={this.handleClickItem}
              >
                <InsightsRevenueLeakageListInner
                  data={
                    item.competitorCategoryName === activeCategory
                      ? level1ByCategories
                      : []
                  }
                  sortFlag={sortFlag}
                  handleClickInnerItem={this.handleClickInnerItem}
                  level1ActiveCategory={level1ActiveCategory}
                  loadingRevenueItem={loadingRevenueItem}
                />
              </InsightsRevenueLeakageListItem>
            ))
          : null}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    filterOptions: state.insights.insights.filterOptions,
    // config: state.insights.skus.config,
    sortFlag: state.insights.insights.revenueSortValue,

    byCategories: !state.insights.insights.subNodesInfo
      ? []
      : Object.keys(
          state.insights.insights.subNodesInfo["all_domains"].subNodesInfo
        ).map((lvl1) => {
          return {
            competitorCategoryName: lvl1,
            outflow: 0,
            trend: 2,
            subNodesInfo:
              state.insights.insights.subNodesInfo["all_domains"].subNodesInfo[
                lvl1
              ].subNodesInfo,
          };
        }),

    loadingRevenue: state.insights.insights.loadingRevenue,
    activeCategory: state.insights.insights.activeCategory,

    level1ByCategories: !state.insights.insights.topCategoryLevel1.subNodesInfo
      ? []
      : Object.keys(state.insights.insights.topCategoryLevel1.subNodesInfo).map(
          (lvl2) => {
            return {
              competitorCategoryName: lvl2,
              outflow: 0,
              trend: 2,
              subNodesInfo:
                state.insights.insights.topCategoryLevel1.subNodesInfo[lvl2]
                  .subNodesInfo,
            };
          }
        ),

    loadingRevenueItem: state.insights.insights.loadingRevenueItem,
    level1ActiveCategory:
      state.insights.insights.topCategoryLevel1.activeCategory,
    loadingRevenueBlocked: state.insights.insights.loadingRevenueBlocked,
    skus: state.insights.insights.skus,
    competitorsOptions: state.competitors.data,
    competitorSelected: state.insights.insights.competitor.rootDomain,
    // activeSort: state.insights.skus.config.sort
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeCategory,
      changeCategoryLevel1,
      fetchInsightsLevelOne,
      fetchInsightsLevelTwo,
      clearLevelOne,
      clearLevelTwo,
      fetchSkus,
      fetchInsightsBrands,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsRevenueLeakageList);
