import {
  FETCH_COMPETITORS_REQUEST,
  FETCH_COMPETITORS_SUCCESS,
  FETCH_COMPETITORS_FAILURE,
  FETCH_COMPETITORS_CANCEL,
  CLEAR_COMPETITORS,
} from "./constants";

const competitorsReducer = (
  state = {
    data: [],
    loading: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_COMPETITORS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_COMPETITORS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data,
        loading: false,
      };
    }

    case FETCH_COMPETITORS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case FETCH_COMPETITORS_CANCEL: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLEAR_COMPETITORS: {
      return {
        ...state,
        data: [],
        loading: false,
        error: "",
      };
    }
  }

  return state;
};

export default competitorsReducer;
