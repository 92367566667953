import React, { useState, useRef, useEffect } from "react";
import styles from "./date-range-picker.scss";
import blueCalendarIcon from "../../images/blue-calendar.svg";
import greyCalendarIcon from "../../images/grey-calendar.svg";
import blueChevron from "../../images/blue-chevron.svg";
import greyChevron from "../../images/grey-chevron.svg";
import MbReactDateRange from "../react-date-range/mb-react-date-range";
import DatePickerService from "../../services/datePickerService";
import moment from "moment";
import { MARKET_SHARE_VIEW_TYPE_DATA } from "../../containers/market-share/constants";
import { ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA } from "../../containers/insights-board/constants";
import { analyticsCalendarUsed } from "../../containers/shared/analytics/actions";
import { CALENDAR_MODES } from "./constants";
import { PRODUCT_360_VIEW_TYPE_DATA } from "../../containers/product-360/constants";
import { RANKING_EVENTS_VIEW_TYPE_DATA } from "../../containers/ranking-events/constants";
import { CATALOG_TRACKER_VIEW_TYPE_DATA } from "../../containers/catalog-tracker/constants";
import { CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA } from "../../containers/catalog-tracker-sku-details/constants";

export const FREQUENCY_IDS_TO_NAMES = {
  0: "Last 30 days",
  1: "Last 7 days",
  2: "Last day",
  3: "Last 2 weeks",
  4: "Last 4 weeks",
};

const datePickerService = new DatePickerService();
const defaultPresets = [
  "Last week",
  "Last 4 weeks",
  "Last 13 weeks",
  "Year to date",
  "Rolling last week",
  "Rolling last 4 weeks",
  "Rolling last 13 weeks",
  "Rolling year to date",
];
const defaultModes = [CALENDAR_MODES.preset, CALENDAR_MODES.custom];

export default function DateRangePicker(props) {
  const {
    selectedDateRange,
    maxDate,
    minDate,
    viewTypeId,
    lastAvailableDate,
    modes = defaultModes,
    presets = defaultPresets,
    handlePresetClick,
    queryFrequency,
  } = props;

  const defaultPresetClickHandler = (option) => {
    var rolling = 0;
    var dateToCalculate;
    var dates;
    switch (viewTypeId) {
      case CATALOG_TRACKER_VIEW_TYPE_DATA.id:
      case CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA.id:
      case RANKING_EVENTS_VIEW_TYPE_DATA.id:
      case ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA.id:
      case MARKET_SHARE_VIEW_TYPE_DATA.id:
      case PRODUCT_360_VIEW_TYPE_DATA.id:
        if (lastAvailableDate && option.startsWith("Rolling")) {
          // sync data with last date of market share.
          dateToCalculate = moment(props.lastAvailableDate);
          break;
        }
      default:
        dateToCalculate = maxMoment;
    }
    var mode = viewTypeIdsNonWeekly.includes(viewTypeId) ? "daily" : "weekly";
    switch (option) {
      case "Rolling last day":
      case "Last day":
        dates = datePickerService.onClickLastXDays(1, dateToCalculate);
        break;
      case "Rolling last 7 days":
      case "Last 7 days":
        dates = datePickerService.onClickLastXDays(7, dateToCalculate);
        break;
      case "Last week":
        dates = datePickerService.onClickLastXWeeks(1, dateToCalculate);
        break;
      case "Last 2 weeks":
        dates = datePickerService.onClickLastXWeeks(2, dateToCalculate);
        break;
      case "Last 4 weeks":
        dates = datePickerService.onClickLastXWeeks(4, dateToCalculate);
        break;
      case "Last 30 days":
        dates = datePickerService.onClickLastXDays(30, dateToCalculate);
        break;
      case "Last 13 weeks":
        dates = datePickerService.onClickLastXWeeks(13, dateToCalculate);
        break;
      case "Year to date":
        dates = datePickerService.onClickYTD(dateToCalculate, mode);
        break;
      case "Rolling last week":
        dates = datePickerService.onClickLastXWeeks(1, dateToCalculate);
        rolling = 1;
        break;
      case "Rolling last 4 weeks":
        dates = datePickerService.onClickLastXWeeks(4, dateToCalculate);
        rolling = 2;
        break;
      case "Rolling last 13 weeks":
        dates = datePickerService.onClickLastXWeeks(13, dateToCalculate);
        rolling = 3;
        break;
      case "Rolling year to date":
        dates = datePickerService.onClickYTD(dateToCalculate, mode);
        rolling = 10;
        break;
    }
    return {
      rolling,
      ...dates,
    };
  };

  const {
    onChangeDateRange,
    onChangeSelectedPeriod,
    setCurrentViewIsUpdated,
  } = props;

  const divRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState(modes[0]);

  const maxMoment = maxDate ? moment(maxDate) : moment(new Date());

  const viewTypeIdsNonWeekly = [
    RANKING_EVENTS_VIEW_TYPE_DATA.id,
    CATALOG_TRACKER_VIEW_TYPE_DATA.id,
    CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA.id,
  ];

  const handleModeClick = (mode) => {
    setMode(mode);
  };

  const onClickApply = (dates) => {
    onChangeDateRange(dates);
    onChangeSelectedPeriod("Custom");
    setCurrentViewIsUpdated();
    analyticsCalendarUsed(viewTypeId, mode, null);
    setIsOpen(false);
  };

  const onPresetClick = (option) => {
    const dates = handlePresetClick
      ? handlePresetClick(option)
      : defaultPresetClickHandler(option);
    onChangeDateRange(dates);
    onChangeSelectedPeriod(option);
    setCurrentViewIsUpdated();
    analyticsCalendarUsed(viewTypeId, mode, option);
    setIsOpen(false);
  };

  const getDisplayDates = () => {
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return `${new Date(
      Date.parse(selectedDateRange.startDate)
    ).toLocaleDateString("en-US", dateOptions)} - ${new Date(
      Date.parse(selectedDateRange.endDate)
    ).toLocaleDateString("en-US", dateOptions)}`;
  };

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const clickAnywhere = (e) => {
    if (!divRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickAnywhere);

    return () => {
      document.removeEventListener("mousedown", clickAnywhere);
    };
  }, []);

  useEffect(() => {
    //handle change of preset view in case frequency changes from clicking on quick filters.
    if (mode == CALENDAR_MODES.preset) {
      onPresetClick(FREQUENCY_IDS_TO_NAMES[queryFrequency]);
    }
  }, [queryFrequency]);

  return (
    <div ref={divRef} className={styles.dateRangePickerWrapper}>
      <div
        className={
          isOpen
            ? styles.dateRangePickerHeaderOpen
            : styles.dateRangePickerHeader
        }
        onClick={handleClick}
      >
        <img
          src={isOpen ? blueCalendarIcon : greyCalendarIcon}
          className={styles.calendarIcon}
        />
        <input
          value={getDisplayDates()}
          className={
            isOpen ? styles.dateDisplayInputOpen : styles.dateDisplayInput
          }
          readOnly
          type="text"
        />
        <img
          className={styles.chevron}
          src={isOpen ? blueChevron : greyChevron}
        />
      </div>
      {isOpen && (
        <div className={styles.timeFrameWrapper}>
          <div className={styles.timeFrameHeader}>Select Time Frame</div>
          <div className={styles.timeFrameButtonWrapper}>
            {modes.map((m) => (
              <input
                key={m}
                className={mode == m ? styles.inputActive : undefined}
                readOnly
                type="text"
                value={m}
                onClick={() => handleModeClick(m)}
              />
            ))}
          </div>
          <div className={styles.timeFrameContentWrapper}>
            {mode == CALENDAR_MODES.preset && (
              <div className={styles.presetWrapper}>
                {presets.map((option, index) => (
                  <div
                    className={styles.presetOption}
                    key={index}
                    onClick={() => onPresetClick(option)}
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
            {mode == CALENDAR_MODES.custom && (
              <MbReactDateRange
                selectedDateRange={selectedDateRange}
                maxDate={maxDate}
                minDate={minDate || null}
                onClickApply={onClickApply}
                viewTypeId={viewTypeId}
                viewTypeIdsNonWeekly={viewTypeIdsNonWeekly}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
