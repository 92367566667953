import {
  ApiService,
  cancelProduct360Data,
  cancelProduct360Promo,
  cancelProduct360SimilarProducts,
  cancelProduct360SearchTerms,
} from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();

export default class Product360Service extends BasicViewService {
  fetchFamilyVariantsData(requestParams) {
    if (cancelProduct360Data !== undefined) {
      cancelProduct360Data("cancel");
    }
    const { requestBody } = requestParams;

    const res = apiService
      .requestProduct360DataPOST(`/marketShare/sku/family/sales`, requestBody)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchSkuComparisonData(requestParams) {
    const { requestBody } = requestParams;

    const res = apiService
      .requestProduct360DataPOST(`/marketShare/sku/kpis/graphs`, requestBody)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchPromoActivity(requestParams) {
    if (cancelProduct360Promo !== undefined) {
      cancelProduct360Promo("cancel");
    }
    const { requestBody } = requestParams;

    const res = apiService
      .requestProduct360PromoDataPOST(
        `/marketShare/sku/kpis/promoActivity`,
        requestBody
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchSimilarProductsTable(requestParams) {
    if (cancelProduct360SimilarProducts !== undefined) {
      cancelProduct360SimilarProducts("cancel");
    }
    const { requestBody } = requestParams;

    const res = apiService
      .requestProduct360SimilarProductsTablePOST(
        `/marketShare/sku/similarSkus/sales`,
        requestBody
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchSimilarProductsGraph(requestParams) {
    const { requestBody } = requestParams;
    const res = apiService
      .requestProduct360SimilarProductsGraphPOST(
        `/marketShare/sku/similarSkus/graph`,
        requestBody
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchSearchTermsData(requestParams) {
    if (cancelProduct360SearchTerms !== undefined) {
      cancelProduct360SearchTerms("cancel");
    }
    const { requestBody } = requestParams;

    const res = apiService
      .requestProduct360SearchTermsPOST(
        `/marketShare/sku/searchTerms`,
        requestBody
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }
}
