const path = "insightsBoard";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;

export const FETCH_DATA_REQUEST = `${path}/FETCH_DATA_REQUEST`;
export const FETCH_DATA_SUCCESS = `${path}/FETCH_DATA_SUCCESS`;
export const FETCH_DATA_FAIL = `${path}/FETCH_DATA_FAIL`;

export const ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA = {
  name: "account-performance",
  id: 6,
};
