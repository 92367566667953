import Dropdown from "./dropdown.js";
import DropdownWithFilter from "./dropdown-with-filter";
import DropdownWrapper from "./dropdown-wrapper";

import DropdownTitleIcon from "./dropdown-title/dropdown-title-icon";
import DropdownTitleString from "./dropdown-title/dropdown-title-string";
import DropdownTitleWrapper from "./dropdown-title/dropdown-title-wrapper";
import DropdownTitleSelectedItem from "./dropdown-title/dropdown-title-selected-item";

import DropdownHeader from "./dropdown-header/dropdown-header";
import DropdownHeaderSearchable from "./dropdown-header/dropdown-header-searchable";

import DropdownBodySingleSelect from "./dropdown-body/dropdown-body-singleselect";
import DropdownBodyMultiselect from "./dropdown-body/dropdown-body-multiselect";
import DropdownBodyLoader from "./dropdown-body/dropdown-body-loader";

import DropdownFooterSingleButton from "./dropdown-footer/dropdown-footer-single-button";

const dropdownTitle = {
  DropdownTitleIcon,
  DropdownTitleString,
  DropdownTitleWrapper,
  DropdownTitleSelectedItem,
};

const dropdownHeader = {
  DropdownHeader,
  DropdownHeaderSearchable,
};

const dropdownBody = {
  DropdownBodySingleSelect,
  DropdownBodyMultiselect,
  DropdownBodyLoader,
};

const dropdownFooter = {
  DropdownFooterSingleButton,
};

export {
  Dropdown,
  DropdownWithFilter,
  DropdownWrapper,
  dropdownTitle,
  dropdownHeader,
  dropdownBody,
  dropdownFooter,
};

export default Dropdown;
