import React, { useEffect, useRef, useState } from "react";
import {
  abbreviateNumber,
  getPercent,
  percentsNumber,
  extractNumberSign,
  numberWithCommas,
  arrayToChunks,
} from "../utils/other/utilities.js";
import styles from "./cell-decorators.scss";
import ArrowUpGreen from "../images/arrow-up-green.svg";
import ArrowDownRed from "../images/arrow-down-red.svg";
import AvgRatingStar from "../images/avg-rating-star.svg";
import { INDEX_COLORS } from "../containers/market-share/constants.js";
import Checkmark from "../components/checkbox/checkmark.js";
import LinkButton from "../components/link-button/link-button.js";
import _, { uniqueId, valuesIn } from "lodash";
import imagePlaceholder from "../images/no-image-placeholder-medium.svg";
import { isEmpty } from "lodash";
import moment from "moment";
import Radiobutton from "../components/checkbox/radiobutton.js";
import { lightBlue } from "./variables.scss";

import { getCurrencySignByDomain } from "../utils/other/utilities.js";

// TODO: eventually all cell decorators should be placed here or derived from this file
const getArrowImageForValue = (value) => {
  return value == "\u221E" || value > 0
    ? ArrowUpGreen
    : value < 0
    ? ArrowDownRed
    : null;
};

const valueIsNull = (value) => {
  return value === "" || value === undefined || value === null;
};

const NA = "N/A";

const getStylesClassForValue = (value) => {
  return value == "\u221E" || value > 0
    ? styles.changePositive
    : value < 0
    ? styles.changeNegative
    : styles.changeNeutral;
};

export const dateDecorator = ({ cell }) => {
  return <>{cell?.value && moment(cell.value).format("D MMM YY")}</>;
};

export const noDecorator = ({ cell }) => cell?.value || "";

export const basicDecorator = ({ cell }) => <span>{cell?.value || ""}</span>;

export const logoDecorator = ({ cell }) => (
  <div className={styles.logo}>
    {!cell?.value ? <span></span> : <img src={cell.value} />}
  </div>
);

export const imageDecorator = ({ cell }) => (
  <div className={styles.productLogo}>
    <img src={cell.value !== "" ? cell.value : imagePlaceholder} />
  </div>
);

export const rankDecorator = ({ cell }) => {
  return (
    <>
      <span className="daily_rank">
        {cell.value !== "" && cell.value !== null ? cell.value : NA}
      </span>
    </>
  );
};

// Creates a default numeric cell decorator for a given cell's className
export const defaultNumericDecorator = (cellClassName, places = 0) => {
  const _defaultNumericDecorator = ({ cell }) => {
    return (
      <>
        <span className={cellClassName + "_cell"}>
          {cell.value !== "" && cell.value !== null && cell.value !== undefined
            ? abbreviateNumber(cell.value, places, places)
            : NA}
        </span>
      </>
    );
  };

  return _defaultNumericDecorator;
};

export const daysDecorator = (cellClassName) => {
  const _defaultNumericDecorator = ({ cell }) => {
    return (
      <>
        <span className={cellClassName + "_cell"}>
          {cell.value !== "" && cell.value !== null && cell.value !== undefined
            ? abbreviateNumber(cell.value, 0, 0) + "d"
            : ""}
        </span>
      </>
    );
  };

  return _defaultNumericDecorator;
};

export const percentDecorator = ({ cell }) => {
  return (
    <>
      <span>{valueIsNull(cell.value) ? NA : getPercent(cell.value, 1, 1)}</span>
    </>
  );
};

export const roundPercentDecorator = ({ cell }) => {
  return (
    <>
      <span>{valueIsNull(cell.value) ? NA : getPercent(cell.value, 1, 0)}</span>
    </>
  );
};

export const boldDecorator = ({ cell }) => {
  return <b>{cell.value}</b>;
};

export const intCellDecorator = ({ cell }) => {
  return (
    <>
      <span>
        {valueIsNull(cell.value)
          ? NA
          : Number(cell.value).toLocaleString("en-US")}
      </span>
    </>
  );
};

export const priceRangeDecorator = ({ cell }) => {
  return (
    <>
      <span>
        {cell.value?.priceFrom
          ? `$${abbreviateNumber(cell.value.priceFrom)}`
          : NA}
        -{cell.value?.priceTo ? `$${abbreviateNumber(cell.value.priceTo)}` : NA}
      </span>
    </>
  );
};

export const numberDecorator = ({ cell }) => {
  return (
    <>
      <span className="number">
        {valueIsNull(cell.value)
          ? NA
          : `${abbreviateNumber(cell.value, 2, false)}`}
      </span>
    </>
  );
};

export const roundNumberDecorator = ({ cell }) => {
  return (
    <>
      <span className="number">
        {valueIsNull(cell.value) ? NA : `${abbreviateNumber(cell.value, 0, 0)}`}
      </span>
    </>
  );
};

export const displayValuesDecorator = (key, decorator) => {
  const _displayValuesDecorator = ({ cell }) => {
    const displayValues = JSON.parse(
      cell?.row?.original?.displayValues ?? "{}"
    );

    if (decorator) return decorateValue(displayValues[key], decorator);
    return (
      <span>{displayValues[key] !== undefined ? displayValues[key] : ""}</span>
    );
  };
  return _displayValuesDecorator;
};

export const familyDisplayDecorator = ({ cell }) => {
  const data = JSON.parse(cell.value ?? "[]");
  const [showHover, setShowHover] = useState(false);

  const onMouseEnter = () => {
    setShowHover(true);
  };

  const onMouseLeave = () => {
    setShowHover(false);
  };

  const getDisplayData = (shouldSlice) => {
    const displayData = shouldSlice ? data?.slice(0, 2) : data;
    return displayData ? (
      <>
        {displayData?.map((data, idx) => (
          <div
            className={
              shouldSlice
                ? styles.familyVariantWrapper
                : styles.familyVariantHoverWrapper
            }
            key={idx}
          >
            <div className={styles.nameWrapper}>{data.name}</div>
            <LinkButton href={data.url} />
          </div>
        ))}
        {data.length > 2 && shouldSlice ? (
          <text>{`+ ${data.length - 2}`}</text>
        ) : null}
      </>
    ) : (
      <></>
    );
  };

  return (
    <div
      className={styles.familyWrapper}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {getDisplayData(true)}
      {/* {showHover ||
        (true && (
          <div className={styles.familyHover}>{getDisplayData(false)}</div>
        ))} */}
    </div>
  );
};

export const numberChangeDecorator = ({ cell }) => {
  var value = cell.value;
  var sign = extractNumberSign(value);
  const decoratorImage = getArrowImageForValue(value);
  const styleClass = getStylesClassForValue(value);

  return (
    <>
      {decoratorImage && <img className={styles.arrow} src={decoratorImage} />}
      <span className={styleClass}>
        {valueIsNull(value)
          ? NA
          : `${sign}${abbreviateNumber(Math.abs(value), 2, 2)}`}
      </span>
    </>
  );
};

export const numberWithCommasDecorator = ({ cell }) => {
  return (
    <>
      <span className="daily_rank">
        {cell.value !== "" && cell.value !== null && cell.value !== undefined
          ? numberWithCommas(cell.value)
          : NA}
      </span>
    </>
  );
};

export const numberWithCommasAndFloorDecorator = ({ cell }) => {
  cell.value = cell.value ? Math.floor(cell.value) : null;
  return numberWithCommasDecorator({ cell });
};

export const catalogTrackerNumberDecorator = ({ cell }) => {
  var value = cell?.value?.current;
  return (
    <>
      <span className="daily_rank">
        {value !== "" && value !== null && value !== undefined
          ? numberWithCommas(value)
          : NA}
      </span>
    </>
  );
};

export const catalogTrackerPromoDecorator = ({ cell }) => {
  var price30D = cell?.value?.price_30d; // max price (list price)
  var price = cell?.value?.price; // promo price
  var promo = cell?.value?.is_promo;

  return (
    <div className={styles.promoCellWrapper}>
      <div className={styles.onPromoCell}>
        <div className={styles.promoDays}>
          {valueIsNull(promo)
            ? NA
            : `${abbreviateNumber(promo, 2, false)} Days`}
        </div>
        <div className={styles.pricesWrapper}>
          <div className={styles.promoPrice}>
            {valueIsNull(price) || promo == 0
              ? NA
              : `$${abbreviateNumber(price)}`}
          </div>
          <div
            className={
              promo && promo > 0 ? styles.maxPrice : styles.maxPriceNoStrike
            }
          >
            {valueIsNull(price30D) ? NA : `$${abbreviateNumber(price30D)}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export const catalogTrackerAverageRatingsDecorator = ({ cell }) => {
  var avgRating = cell?.value;
  var avgDisplay = valueIsNull(avgRating) ? (
    <span>{NA}</span>
  ) : (
    <div className={styles.ratingsAndAvg}>
      <span className={styles.blue}>{avgRating.toFixed(1)}</span>
      <img className={styles.ratingsStar} src={AvgRatingStar} />
    </div>
  );
  return avgDisplay;
};

export const catalogTrackerRatingsAndAvgDecorator = ({ cell }) => {
  var avgRating = cell?.value?.avg_rating;
  var ratings = cell?.value?.num_of_ratings;

  var avgDisplay = valueIsNull(avgRating) ? (
    NA
  ) : (
    <div className={styles.ratingsAndAvg}>
      <span>{avgRating.toFixed(1)}</span>
      <img className={styles.ratingsStar} src={AvgRatingStar} />
    </div>
  );
  var ratingsDisplay = valueIsNull(ratings)
    ? NA
    : `${numberWithCommas(Math.floor(ratings))} reviews`;
  return (
    <>
      <div className={styles.twoValueCell}>
        <span>{avgDisplay}</span>
        <span>{ratingsDisplay}</span>
      </div>
    </>
  );
};

export const catalogTrackerIndexDecorator = ({ cell }) => {
  var current = cell?.value?.current;
  var value = cell?.value?.index;
  var styleClass = value
    ? value < 1
      ? styles.changeNegative
      : value > 1
      ? styles.changePositive
      : styles.changeNeutral
    : styles.changeNeutral;
  var percent = !valueIsNull(value)
    ? `${parseFloat((value * 100).toFixed(2))}%`
    : NA;
  var currentPrice = !valueIsNull(current)
    ? `$${abbreviateNumber(current)}`
    : NA;
  return (
    <div className={styles.twoValueCell}>
      <span className={styleClass}>{percent}</span>
      <span className={styles.catalogTrackerDiffSpan}>{currentPrice}</span>
    </div>
  );
};

export const catalogTrackerOosDecorator = ({ cell }) => {
  var value = cell?.value?.current;
  return (
    <>
      <span className={styles.promoCellWrapper}>
        {value !== "" && value !== null && value !== undefined
          ? `${numberWithCommas(value)} ${value == 1 ? "Day" : "Days"}`
          : NA}
      </span>
    </>
  );
};

export const roundNumberChangeDecorator = ({ cell }) => {
  cell.value = cell.value == "Infinity" ? "\u221E" : cell.value;
  var value =
    cell.value == "\u221E"
      ? cell.value
      : valueIsNull(cell.value)
      ? null
      : cell.value;
  var sign = cell.value == "\u221E" ? "+" : extractNumberSign(value);
  const decoratorImage = getArrowImageForValue(value);
  const styleClass = getStylesClassForValue(value);

  return (
    <>
      {decoratorImage && <img className={styles.arrow} src={decoratorImage} />}
      <span className={styleClass}>
        {value == "\u221E"
          ? `${sign}${value}`
          : valueIsNull(value)
          ? NA
          : `${sign}${abbreviateNumber(Math.abs(value), 0, 0)}`}
      </span>
    </>
  );
};

export const bpsChangeDecorator = ({ cell }) => {
  var value = cell.value;
  var sign = extractNumberSign(value);
  const decoratorImage = getArrowImageForValue(value);
  const styleClass = getStylesClassForValue(value);

  return (
    <>
      {decoratorImage && <img className={styles.arrow} src={decoratorImage} />}
      <span className={styleClass}>
        {valueIsNull(value)
          ? NA
          : `${sign}${abbreviateNumber(Math.abs(value))} bps`}
      </span>
    </>
  );
};

const getCatalogTrackerValues = (cell) => {
  var value = cell?.value?.current;
  var diffValue = cell?.value?.diff;
  var diffPrecent =
    !valueIsNull(value) && !valueIsNull(diffValue)
      ? (diffValue / (value - diffValue)) * 100
      : null;
  return {
    value,
    diffPrecent,
  };
};

export const catalogTrackerAvgDecorator = ({ cell }) => {
  var value = cell?.value?.current;
  var diffValue = cell?.value?.diff;
  const styleClass = getStylesClassForValue(diffValue);
  return (
    <div className={styles.twoValueCell}>
      <span>
        {value !== "" && value !== null && value !== undefined
          ? value.toFixed(1)
          : NA}
      </span>
      <span className={styleClass}>
        {valueIsNull(diffValue)
          ? NA
          : diffValue > 0
          ? `+${diffValue.toFixed(1)}`
          : `${diffValue.toFixed(1)}`}
      </span>
    </div>
  );
};

export const catalogTrackerPriceDiffDecorator = ({ cell }) => {
  const { value, diffPrecent } = getCatalogTrackerValues(cell);
  const styleClass = getStylesClassForValue(diffPrecent);

  const priceByUnit = cell?.value?.price_by_unit;
  const hasPriceByUnit = !valueIsNull(priceByUnit);
  const priceDiv = (
    <div className={styles.twoValueCell}>
      <span>{valueIsNull(value) ? NA : `$${abbreviateNumber(value)}`}</span>
      <span className={`${styleClass} ${styles.catalogTrackerDiffSpan}`}>
        {valueIsNull(diffPrecent)
          ? NA
          : diffPrecent > 0
          ? `+${diffPrecent.toFixed(2)}%`
          : `${diffPrecent.toFixed(diffPrecent == 0 ? 0 : 2)}%`}
      </span>
    </div>
  );

  return (
    <div className={styles.priceDiffWrapper}>
      {hasPriceByUnit ? (
        <div className={`${styles.onPromoCell} ${styles.promoCellWrapper}`}>
          <div className={styles.promoDays}>{priceDiv}</div>
          <div className={styles.pricesWrapper}>{priceByUnit}</div>
        </div>
      ) : (
        priceDiv
      )}
    </div>
  );
};

export const catalogTrackerNumberDiffDecorator = ({ cell }) => {
  var value = cell?.value?.current;
  var diffValue = cell?.value?.diff;
  const styleClass = getStylesClassForValue(diffValue);
  return (
    <div className={styles.twoValueCell}>
      <span>
        {valueIsNull(value) ? NA : numberWithCommas(Math.floor(value))}
      </span>
      <span className={styleClass}>
        {valueIsNull(diffValue)
          ? NA
          : diffValue > 0
          ? `+${Math.floor(diffValue)}`
          : `${Math.floor(diffValue)}`}
      </span>
    </div>
  );
};

export const percentChangeDecorator = ({ cell }) => {
  cell.value = cell.value == "Infinity" ? "\u221E" : cell.value;
  var value =
    cell.value == "\u221E"
      ? cell.value
      : valueIsNull(cell.value)
      ? null
      : (cell.value * 100).toFixed(1);

  const decoratorImage = getArrowImageForValue(value);
  const styleClass = getStylesClassForValue(value);
  return (
    <>
      {decoratorImage && <img className={styles.arrow} src={decoratorImage} />}
      <span className={styleClass}>
        {valueIsNull(value)
          ? NA
          : value == "\u221E" || value > 0
          ? `+${value}%`
          : `${value}%`}
      </span>
    </>
  );
};

export const percentShareDecorator = ({ cell }) => {
  var value = valueIsNull(cell.value) ? NA : (cell.value * 100).toFixed(1);

  return (
    <>
      <span className="pct_diff">{valueIsNull(value) ? NA : `${value}%`}</span>
    </>
  );
};

export const priceDecoratorWithCurrency = ({ cell }) => {
  const { price, rootdomain } = cell.value;
  const currency = getCurrencySignByDomain(rootdomain);
  return (
    <span className="price">
      {valueIsNull(price)
        ? NA
        : price >= 0
        ? `${currency}${abbreviateNumber(price)}`
        : `-${currency}${abbreviateNumber(Math.abs(price))}`}
    </span>
  );
};

export const priceDecorator = ({ cell }) => {
  return (
    <span className="price">
      {valueIsNull(cell.value)
        ? NA
        : cell.value >= 0
        ? `$${abbreviateNumber(cell.value)}`
        : `-$${abbreviateNumber(Math.abs(cell.value))}`}
    </span>
  );
};

export const promoPriceDecorator = ({ cell }) => {
  return <div className={styles.promoPrice}>{priceDecorator({ cell })}</div>;
};

export const dateTimeDecorator = ({ cell }) => {
  const dateFormat = "MMM DD, YYYY hh:mm A";
  const dateTime = cell.value;
  let formatted = dateTime ? moment.utc(dateTime).format(dateFormat) : "";

  return <div style={{ width: "120px" }}>{formatted}</div>;
};

export const eventsSku = ({ cell }) => {
  const { sku, name, productImage, url } = cell.row.original;

  return (
    <div className={styles.eventsSku}>
      <img src={productImage} />
      <div className={styles.eventsSkuDetails}>
        <div className={styles.skuText}>{name}</div>
        <div>
          {" "}
          <LinkButton href={url} text={sku} />
        </div>
      </div>
    </div>
  );
};

export const rankingPositionDecorator = ({ cell }) => {
  const { rank, isSponsored } = cell.row.original;
  return (
    <div>
      <div>{rank}</div>
      {isSponsored == 1 && (
        <div className={styles.sponsoredOverlapGroup}>
          <div
            className={`${styles.sponsored} ${styles.robotoNormalBlueberry11px}`}
          >
            Sponsored
          </div>
        </div>
      )}
    </div>
  );
};

export const priceDiffDecorator = ({ cell }) => {
  return (
    <>
      <span className="price">
        {cell.value !== "" && cell.value !== null && cell.value !== undefined
          ? cell.value >= 0
            ? `+$${abbreviateNumber(cell.value)}`
            : `-$${Math.abs(abbreviateNumber(cell.value))}`
          : ""}
      </span>
    </>
  );
};

const toSalesNumber = (value) => {
  return Number(value) < 1e3
    ? Number(value).toFixed(0)
    : abbreviateNumber(value, 2, 2);
};

export const salesDecorator = (cellClassName) => {
  const _salesDecorator = ({ cell }) => {
    return (
      <>
        <span className={cellClassName + "_cell"}>
          {valueIsNull(cell.value) ? NA : toSalesNumber(cell.value)}
        </span>
      </>
    );
  };

  return _salesDecorator;
};

export const amountDollarsDecorator = (cellClassName) => {
  const _amountDollarsDecorator = ({ cell }) => {
    return (
      <>
        <span className={cellClassName + "_cell"}>
          {valueIsNull(cell.value)
            ? NA
            : Number(cell.value) < 1e3
            ? `$${Number(cell.value).toFixed(0)}`
            : `$${abbreviateNumber(cell.value, 2, 2)}`}
        </span>
      </>
    );
  };

  return _amountDollarsDecorator;
};

export const checkboxDecorator = (onUpdate, selectedKeys) => {
  const _checkboxDecorator = ({ cell }) => {
    const id = cell.row.original?.id ?? Number(cell.row.id);
    const checked = selectedKeys?.includes(id);
    return (
      <Checkmark
        checked={checked}
        onChange={(e, isChecked) => onUpdate(e, id, isChecked, selectedKeys)}
        color={checked && INDEX_COLORS[id]}
      />
    );
  };

  return _checkboxDecorator;
};

export const radioDecorator = (idAccessor, checkedId, onChecked) => {
  const _radioDecorator = ({ cell }) => {
    const id = cell.row.original[idAccessor] ?? cell.row.original.id;
    const checked = checkedId == id;
    return (
      <Radiobutton
        checked={checked}
        onChange={() => !checked && onChecked(id)}
        styleChecked={{ backgroundColor: lightBlue }}
      />
    );
  };

  return _radioDecorator;
};

export const intDecorator = ({ cell }) => {
  return (
    <>
      <span className="number">
        {cell.value !== "" && cell.value !== null && cell.value !== undefined
          ? `${abbreviateNumber(cell.value, 0, 0)}`
          : NA}
      </span>
    </>
  );
};

export const mapDecorator = ({ cell }) => {
  let className = cell.value < 0 ? styles.negativeMapDiff : "price";
  return (
    <>
      <span className={className}>
        {cell.value !== "" && cell.value !== null && cell.value !== undefined
          ? cell.value >= 0
            ? `$${abbreviateNumber(cell.value)}`
            : `-$${abbreviateNumber(Math.abs(cell.value))}`
          : ""}
      </span>
    </>
  );
};

export const intDiffColoredDecorator = ({ cell }) => {
  let className = "price";
  let sign = "";

  if (cell.value !== "" && cell.value !== null) {
    if (cell.value > 0) {
      className = styles.positiveDiff;
      sign = "+";
    }
    if (cell.value < 0) {
      className = styles.negativeDiff;
      sign = "-";
    }
  }

  return (
    <>
      <span className={className}>
        {cell.value !== "" && cell.value !== null && cell.value !== undefined
          ? cell.value >= 0
            ? `${sign}${abbreviateNumber(cell.value, 0, 0)}`
            : `${sign}${Math.abs(abbreviateNumber(cell.value, 0, 0))}`
          : NA}
      </span>
    </>
  );
};

export const percentageDecorator = ({ cell }) => {
  return (
    <>
      <span className="daily_views">
        {cell.value !== "" && cell.value !== null && cell.value !== undefined
          ? `${percentsNumber(cell.value)}%`
          : NA}
      </span>
    </>
  );
};

export const percentageDiffColoredDecorator = ({ cell }) => {
  let className = "pct_diff";

  if (cell.value !== "" && cell.value !== null) {
    if (cell.value > 0) className = styles.positiveDiff;
    if (cell.value < 0) className = styles.negativeDiff;
  }

  return (
    <>
      <span className={className}>
        {cell.value !== "" && cell.value !== null
          ? cell.value > 0
            ? `+${Math.round(cell.value * 100)}%`
            : `${Math.round(cell.value * 100)}%`
          : NA}
      </span>
    </>
  );
};

const _marketShareSkuDecorator = (skuFieldName) => {
  return (cell) => {
    const skuData = cell.row.values.skuData ?? {};
    skuData.sku = cell.row.original.name;

    const skuFields = [
      { name: skuFieldName, accessor: "sku" },
      { name: "Brand", accessor: "brand" },
      skuData?.topSeller != null
        ? { name: "Top Seller", accessor: "topSeller" }
        : null,
      { name: "Product Type", accessor: "productType" },
    ].filter((x) => x !== null);
    return skuDecorator(skuData, [skuFields]);
  };
};

export const marketShareSkuDecorator = ({ cell }) => {
  return _marketShareSkuDecorator("SKU")(cell);
};

export const marketShareFamilyDecorator = ({ cell }) => {
  return _marketShareSkuDecorator("Product Family")(cell);
};

export const skuTrackerSkuDecorator = ({ cell }) => {
  const skuData = cell.row.values.skuData ?? {};
  const attributes = skuData?.variantAttributes
    ? JSON.parse(skuData?.variantAttributes)
    : null;
  const skuFields = [
    { name: "SKU", accessor: "sku" },
    { name: "Brand", accessor: "brand" },
    { name: "Retailer", accessor: "retailer" },
    !isEmpty(skuData?.topSeller)
      ? { name: "Top Seller", accessor: "topSeller" }
      : null,
    { name: "Product Type", accessor: "productType" },
    !isEmpty(skuData?.upc) ? { name: "UPC", accessor: "upc" } : null,
    !isEmpty(skuData?.mpn) ? { name: "MPN", accessor: "mpn" } : null,
    !isEmpty(skuData?.itemNumber)
      ? { name: "Item Number", accessor: "itemNumber" }
      : null,
    !isEmpty(skuData?.storeSku)
      ? { name: "Store SKU", accessor: "storeSku" }
      : null,
  ].filter((x) => x !== null);
  return skuDecorator(
    skuData,
    arrayToChunks(skuFields, 4),
    "skuTrackerDecorator",
    attributes
  );
};

export const catalogTracker360SkuDecorator = ({ cell }) => {
  const sku = cell?.value?.sku;
  const url = cell?.value?.url;
  return (
    <div className={styles.catalogTracker360Sku}>
      <span> {valueIsNull(sku) ? NA : sku} </span>
      {valueIsNull(url) ? "N/A" : <LinkButton href={url} />}
    </div>
  );
};

export const catalogTrackerSkuDecorator = ({ cell }) => {
  const skuData = cell.row.values.skuData ?? {};
  const skuFields = [
    { name: "Brand", accessor: "brand" },
    !isEmpty(skuData?.upc) ? { name: "UPC", accessor: "upc" } : null,
    !isEmpty(skuData?.mpn) ? { name: "MPN", accessor: "mpn" } : null,
    { name: "Product Type", accessor: "productType" },
  ].filter((x) => x !== null);
  return skuDecorator(
    skuData,
    arrayToChunks(skuFields, 3),
    "catalogTrackerDecorator"
  );
};

export const rankingEventsSkuDecorator = ({ cell }) => {
  const skuData = cell.row.original ?? {};
  const skuFields = [
    { name: "SKU", accessor: "sku" },
    { name: "Brand", accessor: "brand" },
    { name: "Retailer", accessor: "rootdomain" },
    !isEmpty(skuData?.topSeller)
      ? { name: "Top Seller", accessor: "topSeller" }
      : null,
    { name: "Product Type", accessor: "category" },
    !isEmpty(skuData?.upc) ? { name: "UPC", accessor: "upc" } : null,
    !isEmpty(skuData?.mpn) ? { name: "MPN", accessor: "mpn" } : null,
  ].filter((x) => x !== null);
  return skuDecorator(
    { ...skuData, image: skuData?.productImage },
    arrayToChunks(skuFields, 4),
    "skuTrackerDecorator"
  );
};

const SkuFields = ({ skuData, fieldsConfig, decoratorsStyles }) => {
  return (
    <div className={styles.skuColumns}>
      {fieldsConfig.map((column) => (
        <div className={styles.skuColumn} key={uniqueId()}>
          {column.map((skuField) => (
            <div className={styles.skuInfoRow} key={uniqueId()}>
              <span className={styles.skuInfoRowTitle}>{skuField.name}</span>{" "}
              <span
                className={`${styles.skuValue} ${
                  styles[decoratorsStyles]?.skuValue ?? ""
                }`}
              >
                {skuData[skuField.accessor]}
              </span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

const skuDecorator = (
  skuData,
  skuFields,
  decoratorsStyles,
  variantAttributes
) => {
  return (
    <div className={`${styles.skuDecorator} ${styles[decoratorsStyles] ?? ""}`}>
      <div className={styles.skuMainLayout}>
        <div className={styles.imgWrapper}>
          <img
            src={
              !skuData.image || skuData.image === ""
                ? imagePlaceholder
                : skuData.image
            }
          />
        </div>
        <div className={styles.skuTextLayout}>
          <div className={styles.skuTitle}>{skuData.name}</div>
          <SkuFields
            skuData={skuData}
            fieldsConfig={skuFields}
            decoratorsStyles={decoratorsStyles}
          />
          {variantAttributes && (
            <div className={styles.attributesWrapper}>
              {Object.entries(variantAttributes).map(([key, value], index) => (
                <React.Fragment key={index}>
                  <span>{`${key}: ${value}`}</span>
                  {index < Object.entries(variantAttributes).length - 1 && (
                    <div
                      style={{
                        width: "1px",
                        height: "20px",
                        backgroundColor: "gray",
                        opacity: 0.5,
                        margin: "0 8px",
                      }}
                    />
                  )}
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const familySkuDecorator = ({ cell }) => {
  const skuData = cell.row.original;
  const fieldsConfig = [
    { name: "Brand", accessor: "brand" },
    !isEmpty(skuData?.topSellerName)
      ? { name: "Supplier", accessor: "topSellerName" }
      : null,
  ].filter((x) => x !== null);
  return (
    <div className={styles.skuDecorator} style={skuData.styles ?? {}}>
      <div className={styles.skuMainLayout}>
        <div className={styles.imgWrapper}>
          <img src={skuData.image} />
        </div>
        <div className={styles.skuTextLayout}>
          {skuData.isCurrentSku && (
            <div className={styles.variantCurrentBadge}>Current</div>
          )}
          <div className={styles.skuTextLayout}>
            <div className={styles.familySkuTitle}>{skuData.name}</div>
            <SkuFields skuData={skuData} fieldsConfig={[fieldsConfig]} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const revenueLossDecorator = ({ cell }) => {
  var value = cell.value;
  var sign = extractNumberSign;

  const styleClass = getStylesClassForValue(value);
  return (
    <>
      <span className={styleClass}>
        {valueIsNull(value) ? NA : `${sign}$${toSalesNumber(Math.abs(value))}`}
      </span>
    </>
  );
};

export const visibilityPeriodDecorator = ({ cell }) => {
  let topSkuVisibilityPeriods = NA;

  switch (cell.value) {
    case "0":
      topSkuVisibilityPeriods = "Unknown";
      break;
    case "1":
      topSkuVisibilityPeriods = "Only Current";
      break;
    case "2":
      topSkuVisibilityPeriods = "Only Previous";
      break;
    case "3":
      topSkuVisibilityPeriods = "Both Periods";
      break;
    case "4":
      topSkuVisibilityPeriods = "Neither Period";
      break;
  }

  return (
    <>
      <span className="daily_rank">{topSkuVisibilityPeriods}</span>
    </>
  );
};

export const abbreviatedPercentageDiffDecorator = ({ cell }) => {
  return (
    <>
      <span className="daily_view_diff">
        {cell.value !== "" && cell.value !== null
          ? cell.value > 0
            ? `+${abbreviateNumber(cell.value, 0, 0)}`
            : `-${abbreviateNumber(Math.abs(cell.value), 0, 0)}`
          : null}
      </span>
    </>
  );
};

export const percentageDiffDecorator = ({ cell }) => {
  return (
    <>
      <span className="pct_diff">
        {cell.value !== "" && cell.value !== null
          ? cell.value > 0
            ? `+${Math.round(cell.value * 100)}%`
            : `${Math.round(cell.value * 100)}%`
          : null}
      </span>
    </>
  );
};

export const insightDecorator = ({ cell }) => {
  let insightType = NA;

  switch (cell.value) {
    case 0:
      insightType = "Unknown";
      break;
    case 1:
      insightType = "Stock";
      break;
    case 2:
      insightType = "Pricing";
      break;
    case 3:
      insightType = "Discoverability";
      break;
    case 4:
      insightType = "Stock Opp";
      break;
  }

  return (
    <>
      <span className={styles.insightType}>{insightType}</span>
    </>
  );
};

export const variantDecorator = ({ cell }) => {
  const attributes = cell.row.values.variantAttributes;

  return (
    <div>
      {attributes &&
        Object.entries(attributes).map(([attrName, attrValue]) => (
          <span key={attrName}>
            {attrName}: {attrValue}
            <br />
          </span>
        ))}
    </div>
  );
};

export const linkDecorator = ({ cell }) => {
  const url = cell.row.values.url;
  return (
    <div onClick={(e) => e.stopPropagation()}>
      <LinkButton href={url ?? "#"} />
    </div>
  );
};

export const yesNoDecorator = ({ cell, e }) => {
  let value = cell.column?.cellObject?.nullAsNo ? "No" : "NA";
  switch (cell.value) {
    case 0:
      value = "No";
      break;
    case 1:
      value = "Yes";
      break;
  }

  return (
    <>
      <span className={styles.insightType}>{value}</span>
    </>
  );
};

export const decorateValue = (value, decorator) => {
  return decorator({ cell: { value: value } });
};

export function chartTickPriceFormat(price) {
  if (price >= 0) return `$${chartTickNumberFormat(price)}`;
  return `-$${chartTickNumberFormat(Math.abs(price))}`;
}

export function chartTickPercentFormat(x) {
  return getPercent(x, 1, x > 0 && x < 0.1 ? 1 : 0);
}

export function chartTickNumberFormat(x) {
  const abbr = abbreviateNumber(x, 2, x >= 1000 && x <= 9999 ? 1 : 0);
  return abbr.replace(".0", "");
}
