import BasicViewService from "./basicViewService";
import {
  ApiService,
  cancelMatchedSkus,
  cancelMoreMatchedSkus,
} from "./apiService";

const apiService = new ApiService();

export default class CatalogTrackerService extends BasicViewService {
  exportInsightsView(data) {
    const res = apiService
      .exportViewPOST(`/catalogTracker/csv`, data, {})
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }
  getMatchedSkus(data) {
    data.offset = 0;
    data.limit = 20;
    if (cancelMatchedSkus !== undefined) {
      cancelMatchedSkus("cancel");
    }
    const res = apiService
      .requestMatchedSkusPOST(`/catalogTracker/matchedSkus`, data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  getMoreMatchedSkus(data, amountOfMatches) {
    data.offset = amountOfMatches + data.offset;
    data.limit = amountOfMatches + data.limit;
    if (cancelMoreMatchedSkus !== undefined) {
      cancelMoreMatchedSkus("cancel");
    }
    const res = apiService
      .requestMatchedSkusPOST(`/catalogTracker/matchedSkus`, data)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  getSkuDetails(matchingId, requestParams) {
    const res = apiService
      .requestCatalogTrackerSkuDetailsPost(
        `/catalogTracker/skuDetails/${matchingId}`,
        requestParams
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }
}
