import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./similar-skus-graphs-container.scss";
import { changeChartsSettings } from "../../../actions";
import { useChartSettings } from "../../../../market-share/components/market-share-charts/market-share-chart-component/market-share-chart-utils";
import ChartControls from "./chart-controls";
import {
  PRODUCT_360_VIEW_TYPE_DATA,
  UNIFIED_SETTINGS_KEY,
} from "../../../constants";
import SimilarSkusGraph from "../similar-skus-graph/similar-skus-graph";
import SharedEventsProvider from "../../../../../components/charts/visx/shared-events-provider";
import LegendWrapper from "../../../../../visx_components/legend/legend";
import { BARS_COLORS } from "../../../../market-share/constants";
import { analyticsChartInteraction } from "../../../../shared/analytics/actions";
import {
  CHART_COMPARE_PERIOD_TOGGLED,
  CHART_TYPE_SELECTED,
} from "../../../../shared/analytics/constants";

const SimilarSkusGraphsContainer = ({
  entities,
  forceLoading,
  settings,
  chartsConfigs,
  changeChartsSettings,
}) => {
  const [chartSettings, setChartSettings] = useChartSettings(
    UNIFIED_SETTINGS_KEY,
    settings.charts ?? {},
    changeChartsSettings
  );

  const setChartSettingsWithAnalytics = (newSettings) => {
    setChartSettings(newSettings);

    let interactionType;
    let interactionValue;
    if (newSettings.showComparePeriod !== undefined) {
      interactionType = CHART_COMPARE_PERIOD_TOGGLED;
      interactionValue = newSettings.showComparePeriod;
    } else if (newSettings.selectedType !== undefined) {
      interactionType = CHART_TYPE_SELECTED;
      interactionValue = newSettings.selectedType;
    }

    analyticsChartInteraction(
      PRODUCT_360_VIEW_TYPE_DATA.id,
      `similarSkus`,
      interactionType,
      interactionValue
    );
  };

  const barDataKeys = {
    previous: {
      stroke: BARS_COLORS.previous,
      label: "Previous",
      displayName: "Previous",
    },
    current: {
      stroke: BARS_COLORS.current,
      label: "Current",
      displayName: "Current",
    },
  };
  !chartSettings.showComparePeriod && delete barDataKeys.previous;

  const lineDataKeys = Object.keys(entities);

  const similarSkusChart = (chartName, chartConfig, highlightKey, entities) => (
    <SimilarSkusGraph
      id={chartName}
      forceLoading={forceLoading}
      tabConfigs={chartConfig}
      highlightKey={highlightKey}
      entities={entities}
      barDataKeys={barDataKeys}
    />
  );

  const barLegend = (
    <LegendWrapper
      labels={Object.keys(barDataKeys).map((x) => barDataKeys[x].label || x)}
      labelsProps={Object.keys(barDataKeys).map((x) =>
        Object.assign({}, { ...barDataKeys[x] })
      )}
      width={16}
    />
  );

  const lineLegend = (
    <LegendWrapper
      labels={lineDataKeys}
      labelsProps={lineDataKeys.map((x) =>
        Object.assign({}, { ...entities[x] })
      )}
      width={16}
    />
  );

  return (
    <div className={styles.controlledGraphsWrapper}>
      <div className={styles.controlsRow}>
        {chartSettings.selectedType == "Bar" ? barLegend : lineLegend}
        <ChartControls
          chartSettings={chartSettings}
          setChartSettings={setChartSettingsWithAnalytics}
          chartTypes={["Line", "Bar"]}
        />
      </div>
      <div className={styles.graphs}>
        <SharedEventsProvider
          areChartsSameType={true}
          chartsConfigs={chartsConfigs}
        >
          {(chartName, chartConfig, highlightKey) =>
            similarSkusChart(chartName, chartConfig, highlightKey, entities)
          }
        </SharedEventsProvider>
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    familyVariantsData: state.product360.product360.familyVariants.data,
    selectedSku: state.product360.product360.selectedSku,
    settings:
      state.product360.insights.insights.currentViewConfig.query.settings,
    similarSkusData: state.product360.product360.similarSkusTable.data,
    similarSkusGraphs: state.product360.product360.similarSkusGraphs,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeChartsSettings,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SimilarSkusGraphsContainer);
