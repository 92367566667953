import React, { useEffect } from "react";
import { connect } from "react-redux";

import styles from "./insights-filter.scss";
import { bindActionCreators } from "redux";

import {
  updateCurrentViewConfigQuery,
  showSidebarFilter,
  setCurrentViewIsUpdated,
  setClearedFiltersClicked,
  setUpdateFiltersClicked,
} from "../../containers/insights/actions";

import { showInsightsAiSidebar } from "../../containers/insights/insights-ai-sidebar/actions";

import FilterSidebar from "../mb-filter-sidebar";

const InsightsFilter = (props) => {
  const {
    viewTypeData,
    currentViewConfig,
    lastAvailableDate,
    apiProvider,
    customerRootDomain,
    minimizeByDefault,
    hideViewManager,
  } = props;
  const showSidebar = () => {
    props.showSidebarFilter(!props.insightsFilterShowSidebar);
  };

  const onUpdateFilters = (filtersCollection) => {
    props.setUpdateFiltersClicked();
    props.updateCurrentViewConfigQuery({
      productAttributes: filtersCollection,
    });
    props.showInsightsAiSidebar(false);
    props.setCurrentViewIsUpdated();
  };

  const onClearFilters = () => {
    props.setClearedFiltersClicked();
    props.setCurrentViewIsUpdated();
    props.updateCurrentViewConfigQuery({ productAttributes: [] });
    props.showInsightsAiSidebar(false);
  };

  useEffect(() => {
    if (minimizeByDefault) props.showSidebarFilter(false);
  }, []);

  const interceptFilterDefinitions = () => {
    if (!customerRootDomain && props.viewDefinitions?.filterDefinitions)
      return props.viewDefinitions.filterDefinitions.filter(
        (x) => x.mbName != "map"
      );
    return props.viewDefinitions?.filterDefinitions;
  };

  return (
    <>
      <div className={styles.insightsFilter}>
        <i className={styles.filterIcon} onClick={showSidebar} />
      </div>
      <FilterSidebar
        {...props}
        isLoading={
          props.viewDefinitions?.filterDefinitions == null ||
          props.viewDefinitions?.filterDefinitions.length === 0
        }
        filterDefinitionsHaveLoaded={
          props.viewDefinitions?.filterDefinitionsHaveLoaded
        }
        filterDefinitions={interceptFilterDefinitions()}
        showSidebar={props.insightsFilterShowSidebar}
        setSideBarVisibility={props.showSidebarFilter}
        shouldSetSideBarVisibilityOnClickOutside={false}
        insightsFiltersCollection={props.insightsFiltersCollection}
        onClearFilters={onClearFilters}
        onUpdateFilters={onUpdateFilters}
        lastAvailableDate={lastAvailableDate}
        apiProvider={apiProvider}
        viewTypeData={viewTypeData}
        hideViewManager={hideViewManager}
      />
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    customerName: state.user.customerInfo.name,
    customerRootDomain: state.user.customerInfo.rootDomain,
    insightsFiltersCollection:
      state.insights.insights.currentViewConfig.query.productAttributes,
    viewDefinitions: state.insights.insights.viewDefinitions,
    currentViewConfig: state.insights.insights.currentViewConfig,
    insightsFilterShowSidebar:
      state.insights.insights.insightsFilterShowSidebar,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentViewConfigQuery,
      setCurrentViewIsUpdated,
      showSidebarFilter,
      showInsightsAiSidebar,
      setClearedFiltersClicked,
      setUpdateFiltersClicked,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InsightsFilter);
