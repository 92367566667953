import React, { useState } from "react";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownBody,
} from "../../../../../../../components/dropdown";
import styles from "./alert-frequency-dropdown.scss";
import { NOTIFICATION_FREQUENCY } from "../../../../../../../utils/other/constants";

const { DropdownTitleSelectedItem } = dropdownTitle;
const { DropdownBodySingleSelect } = dropdownBody;

const options = [
  { id: NOTIFICATION_FREQUENCY.hour, name: "Hour", value: "hourly" },
  { id: NOTIFICATION_FREQUENCY.day, name: "Day", value: "daily" },
  { id: NOTIFICATION_FREQUENCY.week, name: "Week", value: "weekly" },
  { id: NOTIFICATION_FREQUENCY.month, name: "Month", value: "monthly" },
];

function AlertFrequencyDropdown(props) {
  const { handleChangeNotificationFrequency, viewTypeData } = props;
  const selectedOptions =
    viewTypeData?.id === 1
      ? options.filter((item) => item.id !== NOTIFICATION_FREQUENCY.hour)
      : options;
  const handleFrequencyChange = (value) => {
    handleChangeNotificationFrequency(value);
  };

  const selectedItem =
    selectedOptions.find((item) => item.id === props.selectedItemId) ||
    selectedOptions[0];

  const dropdownTitle = (
    <DropdownTitleSelectedItem
      preTitle={"Receive alert email every:"}
      value={selectedItem?.name}
    />
  );

  return (
    <>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownBody={{
          component: DropdownBodySingleSelect,
          props: {
            options: selectedOptions,
            value: selectedItem?.value,
            radiobutton: true,
          },
          styles,
        }}
        handleClickItem={handleFrequencyChange}
        wrapDropdownBody={true}
        styles={styles}
        className="alertFrequencyDropdown"
      />
    </>
  );
}

export default AlertFrequencyDropdown;
