import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import InsightsTemplate from "../insights-template/insights-template";
import { SubspaceProvider } from "react-redux-subspace";
import CatalogTrackerService from "../../services/catalogTrackerService";

import { CATALOG_TRACKER_VIEW_TYPE_DATA } from "../../containers/catalog-tracker/constants";

import {
  convertBrowserStringSortFieldToConfig,
  convertConfigSortFieldToBrowserString,
} from "../../utils/skus/convertSortValue";
import CatalogTrackerInfiniteTable from "../../containers/catalog-tracker/catalog-tracker-infinite-table";
import CatalogTrackerSkuDetails from "../catalog-tracker-sku-details/catalog-tracker-sku-details";

import {
  applyInitialState,
  setSelectedMatchingId,
} from "../../containers/catalog-tracker-sku-details/actions";

const catalogTrackerService = new CatalogTrackerService();

const FREQUENCY_PRESETS = {
  "Last day": 2,
  "Last 7 days": 1,
  "Last 30 days": 0,
  "Last 2 weeks": 3,
  "Last 4 weeks": 4,
};

function CatalogTracker(props) {
  const {
    matchesList,
    selectedDateRange,
    lastProcessedDay,
    selectedMatchingId,
    setSelectedMatchingId,
  } = props;
  const viewTypeData = CATALOG_TRACKER_VIEW_TYPE_DATA;

  const dataControlsOptions = {
    viewTypeData,
    lastAvailableDate: lastProcessedDay ?? null,
    maxDate: lastProcessedDay ? new Date(lastProcessedDay) : null,
    apiProvider: catalogTrackerService,
    dateRange: selectedDateRange,
    hideCompareDates: false,
    handlePresetClick: null,
    datePickerPresets: Object.keys(FREQUENCY_PRESETS),
    hideFrequency: true,
    mbFilter: true,
    convertConfigSortFieldToBrowserString: convertConfigSortFieldToBrowserString,
    minimizeInsightsFilterByDefault: true,
    showEventTriggers: false,
    addDatesToUrl: true,
  };

  const catalogTrackerBody = (
    <>
      <CatalogTrackerInfiniteTable
        matchesList={matchesList}
        onSelectRow={(x) => setSelectedMatchingId(x)}
      />
    </>
  );

  useEffect(() => {
    const url = new URL(document.location.href);
    if (!selectedMatchingId && url.pathname != `/${viewTypeData.name}`) {
      url.pathname = viewTypeData.name;
      url.searchParams.delete("sku");
      history.pushState({}, "", url);
    }
  }, [selectedMatchingId]);

  return (
    <div>
      {selectedMatchingId && (
        <CatalogTrackerSkuDetails
          lastAvailableDate={
            lastProcessedDay ? new Date(lastProcessedDay) : null
          }
          parentViewTypeData={CATALOG_TRACKER_VIEW_TYPE_DATA}
          parentRedirect={true}
        />
      )}
      <div style={selectedMatchingId ? { display: "none" } : {}}>
        <SubspaceProvider
          mapState={(state) => state.catalogTracker}
          namespace="catalogTracker"
        >
          <InsightsTemplate
            apiProvider={catalogTrackerService}
            lastAvailableDate={
              lastProcessedDay ? new Date(lastProcessedDay) : null
            }
            viewTypeData={viewTypeData}
            dataControlsOptions={dataControlsOptions}
            body={catalogTrackerBody}
            convertBrowserStringSortFieldToConfig={
              convertBrowserStringSortFieldToConfig
            }
          />
        </SubspaceProvider>
      </div>
    </div>
  );
}
export const mapStateToProps = (state) => {
  const {
    selectedDateRange,
    selectedPreviousDateRange,
    selectedCompetitor,
    comparePeriod,
  } = state.catalogTracker.insights.mbFilterRow;
  const lastProcessedDay =
    state.catalogTracker.insights.insights.viewDefinitions?.viewMetadata
      ?.lastProcessedDay;

  const { matchesList } = state.catalogTracker.catalogTracker;
  const selectedMatchingId =
    state.catalogTrackerSkuDetails.catalogTrackerSkuDetails.selectedMatchingId;
  return {
    selectedDateRange,
    matchesList,
    lastProcessedDay,
    selectedMatchingId,
  };
};
export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setSelectedMatchingId, applyInitialState }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CatalogTracker);
