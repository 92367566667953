import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { changeFilterRevenueLeakage } from "./actions";
import InsightsRevenueLeakageHeader from "./components/insights-revenue-leakage-header/insights-revenue-leakage-header";
import InsightsRevenueLeakageList from "./components/insights-revenue-leakage-list/insights-revenue-leakage-list";
import styles from "./insights-revenue-leakage.scss";

class InsightsRevenueLeakage extends Component {
  handleSelectFilter = ({ name }) => {
    this.props.changeFilterRevenueLeakage(name);
  };

  render() {
    const { revenueSortValue } = this.props;

    return (
      <div className={styles.wrapper}>
        {/* <InsightsRevenueLeakageHeader
          revenueSortValue={revenueSortValue}
          handleSelect={this.handleSelectFilter} /> */}
        <InsightsRevenueLeakageList />
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    revenueSortValue: state.insights.insights.revenueSortValue,
    competitorsOptions: state.competitors.data,
    competitorSelected: state.insights.insights.competitorSelected,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeFilterRevenueLeakage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsRevenueLeakage);
