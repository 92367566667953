import {
  APPLY_INITIAL_STATE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
} from "./constants";

import insightsBoardConfig, {
  MarketShareConfig,
} from "./insights-board-config";
const components = {};
insightsBoardConfig.components.map((x) => (components[x.name] = {}));
MarketShareConfig.components.map((x) => (components[x.name] = {}));

const initialState = {
  componentsToLoad: 0,
  components,
};

const insightsBoardReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }

    case FETCH_DATA_SUCCESS: {
      const component = action.payload.component;
      const data = action.payload.data;
      return {
        ...state,
        components: {
          ...state.components,
          [component]: data,
        },
      };
    }
    case FETCH_DATA_FAIL: {
      const error = action.payload.error;
      return {
        ...state,
        components: {
          ...state.components,
          [error?.componentName ??
          "undefined component using insights board reducer"]: error,
        },
      };
    }
    case FETCH_DATA_REQUEST: {
      const component = action.payload.component;

      return {
        ...state,
        components: {
          ...state.components,
          [component]: {},
        },
      };
    }
  }
  return state;
};

export default insightsBoardReducer;
