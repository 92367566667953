import { ApiService, cancelCategories } from "./apiService";
const apiService = new ApiService();

export default class CategoriesService {
  fetchCategories(topCategoryLevel1) {
    const topCategory1 = topCategoryLevel1
      ? encodeURIComponent(topCategoryLevel1)
      : "";

    if (cancelCategories != undefined) {
      cancelCategories("cancel");
    }

    const res = apiService
      .requestCategoriesGET(`/categories/${topCategory1}`)
      .then((res) => {
        return Promise.resolve(this._transformCategories(res.data));
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  _transformCategories = (data = []) => {
    data.unshift("All");
    let result =
      data.map((item, i) => {
        return {
          name: item,
          id: i,
        };
      }) || [];

    return result;
  };
}
