import React from "react";
import ContentLoader from "react-content-loader";

const MarketShareAnalysisKpisLoader = ({ size, speed = 2 }) => {
  return (
    <>
      <ContentLoader speed={speed} width={430}>
        <rect y="20" width="108" height="10" fill="#EEEFF1" />
        <rect y="42" width="39" height="10" fill="#EEEFF1" />
        <path d="M0 42L120 60" stroke="#EEEFF1" />
        <rect y="72" width="108" height="10" fill="#EEEFF1" />
        <rect y="92" width="39" height="10" fill="#EEEFF1" />
        <rect x="135" y="20" width="108" height="10" fill="#EEEFF1" />
        <rect x="135" y="40" width="39" height="10" fill="#EEEFF1" />
        <path d="M135 40L255 60" stroke="#EEEFF1" />
        <rect x="135" y="70" width="108" height="10" fill="#EEEFF1" />
        <rect x="135" y="90" width="39" height="10" fill="#EEEFF1" />
        <rect x="270" y="20" width="108" height="10" fill="#EEEFF1" />
        <rect x="270" y="40" width="39" height="10" fill="#EEEFF1" />
        <path d="M270 40L390 60" stroke="#EEEFF1" />
        <rect x="270" y="70" width="108" height="10" fill="#EEEFF1" />
        <rect x="270" y="90" width="39" height="10" fill="#EEEFF1" />
      </ContentLoader>
    </>
  );
};

export default MarketShareAnalysisKpisLoader;
