import { ApiService, cancelSkus } from "./apiService";
const apiService = new ApiService();

export default class SkusService {
  fetchSkus(query, clientTopCategoryLevel1, clientTopCategoryLevel2) {
    const clientTopCategoryLevel1Param = clientTopCategoryLevel1
      ? "/" + encodeURIComponent(clientTopCategoryLevel1)
      : "";
    const clientTopCategoryLevel2Param = clientTopCategoryLevel2
      ? "/" + encodeURIComponent(clientTopCategoryLevel2)
      : "";
    const queryParam = query ? query : "";

    if (cancelSkus != undefined) {
      cancelSkus("cancel");
    }

    const res = apiService
      .requestGET(
        `/skus${clientTopCategoryLevel1Param}${clientTopCategoryLevel2Param}${queryParam}`
      )
      .then((res) => {
        return Promise.resolve(this._transformSkus(res.data.topSkusInsights));
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchMoreSkus(
    lengthProduct,
    query,
    clientTopCategoryLevel1,
    clientTopCategoryLevel2
  ) {
    const clientTopCategoryLevel1Param = clientTopCategoryLevel1
      ? "/" + encodeURIComponent(clientTopCategoryLevel1)
      : "";
    const clientTopCategoryLevel2Param = clientTopCategoryLevel2
      ? "/" + encodeURIComponent(clientTopCategoryLevel2)
      : "";
    const queryParam = query ? query : "";

    if (cancelSkus != undefined) {
      cancelSkus("cancel");
    }

    const res = apiService
      .requestGET(
        `/skus${clientTopCategoryLevel1Param}${clientTopCategoryLevel2Param}${queryParam}`
      )
      .then((res) => {
        return Promise.resolve(
          this._transformMoreSkus(res.data.topSkusInsights, lengthProduct)
        );
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  _transformSkus = (data = []) => {
    let result =
      data.map((item, i) => {
        return {
          competitorSku: item.competitorSku,
          matchedCustomerSku: item.matchedCustomerSku,
          insights: item.insights,
          id: i,
        };
      }) || [];

    return result;
  };

  _transformMoreSkus = (data = [], lengthProduct) => {
    let result =
      data.map((item, i) => {
        return {
          competitorSku: item.competitorSku,
          matchedCustomerSku: item.matchedCustomerSku,
          insights: item.insights,
          id: i + lengthProduct,
        };
      }) || [];

    return result;
  };
}
