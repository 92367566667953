import React, { useEffect, useRef, useState } from "react";
import styles from "./event-type.scss";
import _ from "lodash";
import {
  getEventsDefinition,
  setEventType,
  sortEventsByGroup,
} from "../../../../utils";
import {
  EVENT_GROUPS,
  EVENT_TYPE_PROPERTIES,
} from "../../../../../../containers/ranking-events/constants";
import CustomScrollbar from "../../../../../../components/scrollbar/customScrollbar";

const EventTypeField = ({ formData, setFormData, configData }) => {
  const { filterDefinitions, eventProps } = configData;
  const [open, setOpen] = useState(false);
  const containerRef = useRef();

  const eventsDefinition = getEventsDefinition(filterDefinitions);

  const onSelect = (eventIndex) => {
    const newAttributes = setEventType(
      eventsDefinition,
      eventIndex,
      formData.query.productAttributes
    );
    setFormData({
      ...formData,
      query: {
        ...formData.query,
        productAttributes: newAttributes,
      },
    });
  };

  const Dropdown = () => {
    return (
      <div className={styles.dropdown}>
        <CustomScrollbar style={{ height: "250px", width: "300px" }}>
          {Object.entries(sortEventsByGroup()).map(
            ([eventGroup, eventTypes], i) => {
              return (
                <div key={i}>
                  <div className={styles.dropdownSection}>{eventGroup}</div>
                  {eventTypes.map((x) => {
                    const eventProperties = EVENT_TYPE_PROPERTIES[x];
                    return (
                      <div
                        key={x}
                        onClick={() => onSelect(x)}
                        className={styles.dropdownItem}
                      >
                        <img src={eventProperties.icon} />
                        {eventProperties.title}
                      </div>
                    );
                  })}
                </div>
              );
            }
          )}
        </CustomScrollbar>
      </div>
    );
  };

  const onMouseOut = (e) => {
    if (!containerRef.current.contains(e.target)) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", onMouseOut);

    return () => {
      document.removeEventListener("mousedown", onMouseOut);
    };
  }, []);

  const onDropdownToggle = () => {
    // this selection method is currently not in use.
    // if (configData.mode != "new" || configData.custom) setOpen(!open);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        lineHeight: "normal",
        gap: "8px",
      }}
    >
      <div className={styles.preTitle}>I want to track for</div>
      <div
        className={styles.selection}
        onClick={() => onDropdownToggle()}
        ref={containerRef}
      >
        {eventProps ? (
          <>
            <img src={eventProps.icon} />
            {eventProps.title}
          </>
        ) : (
          "Select"
        )}
        {open && <Dropdown />}
      </div>
    </div>
  );
};

export default EventTypeField;
