import {
  SET_CURRENT_VIEW_IS_UPDATED,
  FETCH_INSIGHTS_REQUEST,
  FETCH_INSIGHTS_SUCCESS,
  FETCH_INSIGHTS_FAILURE,
  FETCH_INSIGHTS_CANCEL,
  CHANGE_ACTIVE_CATEGORY_REVENUE_LEAKAGE,
  CHANGE_ACTIVE_CATEGORY_LEVEL_ONE,
  FETCH_INSIGHTS_LEVEL_ONE_REQUEST,
  FETCH_INSIGHTS_LEVEL_ONE_SUCCESS,
  CLEAR_LEVEL_ONE,
  FETCH_INSIGHTS_LEVEL_TWO_REQUEST,
  FETCH_INSIGHTS_LEVEL_TWO_SUCCESS,
  CLEAR_LEVEL_TWO,
  CLEAR_INSIGHTS,
  UPDATE_CURRENT_VIEW_CONFIG_QUERY,
  UPDATE_VIEW_TYPE,
  SHOW_SIDEBAR_FILTER,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  CLEAR_FILTERS_SELECTED,
  UPDATE_FILTERS_SELECTED,
} from "./constants";

import InsightsService from "../../services/insightsService";
import BrandsService from "../../services/brandsService";

const insightsService = new InsightsService();
const brandsService = new BrandsService();

export const setUpdateFiltersClicked = () => ({
  type: UPDATE_FILTERS_SELECTED,
});

export const setClearedFiltersClicked = () => ({
  type: CLEAR_FILTERS_SELECTED,
});

export const setCurrentViewIsUpdated = () => ({
  type: SET_CURRENT_VIEW_IS_UPDATED,
});

export const changeDetailsVisible = (status) => ({
  type: FETCH_INSIGHTS_REQUEST,
  payload: { status },
});

export const changeCategory = (activeCategory) => ({
  type: CHANGE_ACTIVE_CATEGORY_REVENUE_LEAKAGE,
  payload: { activeCategory },
});

// insights
export const insightsRequested = () => ({
  type: FETCH_INSIGHTS_REQUEST,
});

export const insightsSuccessed = ({ subNodesInfo }) => ({
  type: FETCH_INSIGHTS_SUCCESS,
  payload: { subNodesInfo },
});

export const insightsError = () => ({
  type: FETCH_INSIGHTS_FAILURE,
});

export const insightsCancel = () => ({
  type: FETCH_INSIGHTS_CANCEL,
});

export const fetchCategoryMapping = (query) => (dispatch) => {
  dispatch(insightsRequested());

  insightsService
    .fetchCategoryMapping(null, null, query)
    .then((res) => {
      dispatch(insightsSuccessed(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(insightsCancel());
      } else {
        dispatch(insightsError());
      }
    });
};

// insights top level one

export const changeCategoryLevel1 = (activeCategory) => ({
  type: CHANGE_ACTIVE_CATEGORY_LEVEL_ONE,
  payload: { activeCategory },
});

export const insightsLevelOneRequested = () => ({
  type: FETCH_INSIGHTS_LEVEL_ONE_REQUEST,
});

export const insightsLevelOneSuccessed = ({ subNodesInfo }) => ({
  type: FETCH_INSIGHTS_LEVEL_ONE_SUCCESS,
  payload: { subNodesInfo },
});

export const fetchInsightsLevelOne = (topCategoryLevel1, byL1Categories) => (
  dispatch
) => {
  dispatch(insightsLevelOneRequested());

  var res = byL1Categories.find((l1Info) => {
    return l1Info.competitorCategoryName == topCategoryLevel1;
  });
  res = { subNodesInfo: res.subNodesInfo };

  dispatch(insightsLevelOneSuccessed(res));
};

export const clearLevelOne = () => ({
  ///111111111
  type: CLEAR_LEVEL_ONE,
});

// insights top level two
export const insightsLevelTwoRequested = () => ({
  type: FETCH_INSIGHTS_LEVEL_TWO_REQUEST,
});

export const insightsLevelTwoSuccessed = ({ totalSum, subNodesInfo }) => ({
  type: FETCH_INSIGHTS_LEVEL_TWO_SUCCESS,
  payload: { totalSum, subNodesInfo },
});

export const fetchInsightsLevelTwo = (
  topCategoryLevel1,
  topCategoryLevel2,
  byL2Categories
) => (dispatch) => {
  dispatch(insightsLevelTwoRequested());

  var res = byL2Categories.find((l2Info) => {
    return l2Info.competitorCategoryName == topCategoryLevel2;
  });
  res = { totalSum: res.outflow, subNodesInfo: res.subNodesInfo };

  dispatch(insightsLevelTwoSuccessed(res));
};

export const clearLevelTwo = () => ({
  //1111111111
  type: CLEAR_LEVEL_TWO,
});

export const clearInsights = () => ({
  type: CLEAR_INSIGHTS,
});

export const updateCurrentViewConfigQuery = (data) => ({
  type: UPDATE_CURRENT_VIEW_CONFIG_QUERY,
  payload: { data },
});

export const updateViewType = (data) => ({
  type: UPDATE_VIEW_TYPE,
  payload: { data },
});

export const showSidebarFilter = (show) => ({
  type: SHOW_SIDEBAR_FILTER,
  show,
});

// brands
export const insightsBrandsSuccessed = (data) => ({
  type: FETCH_BRANDS_SUCCESS,
  payload: { data },
});

export const insightsBrandsError = () => ({
  type: FETCH_BRANDS_FAILURE,
});

export const fetchInsightsBrands = (
  competitorRootDomain,
  clientTopCategoryLevel1,
  clientTopCategoryLevel2
) => (dispatch) => {
  return brandsService
    .fetchBrands(
      competitorRootDomain,
      clientTopCategoryLevel1,
      clientTopCategoryLevel2,
      "false"
    )
    .then((res) => {
      dispatch(insightsBrandsSuccessed(res));
      return res;
    })
    .catch((err) => {
      dispatch(insightsBrandsError());
    });
};
