import React from "react";
import styles from "./pdf-container.scss";

// Pdf Container is responsible for rendering the pdf page structure.
export default function PdfContainer(props) {
  const {
    viewName,
    selectedDateRange,
    selectedPreviousDateRange,
    componentsData,
    configuration,
    incompleteMarketShareData,
  } = props;

  return (
    <>
      <div className={styles.printContainer} style={{ padding: "10px" }}>
        <div className={styles.viewName}>View name: {viewName}</div>
        <div className={styles.viewName}>
          Selected date range: {selectedDateRange?.startDate} -{" "}
          {selectedDateRange?.endDate}
          <br />
          Previous date range: {
            selectedPreviousDateRange?.previousStartDate
          } - {selectedPreviousDateRange?.previousEndDate}
        </div>
        {incompleteMarketShareData}
        {configuration.components?.map((x, index) => {
          const ComponentMapped = x.component;
          return (
            <div key={index}>
              <ComponentMapped
                {...props}
                componentData={componentsData[x.name]}
                pdfFormat={true}
              />

              {index < configuration.components.length - 1 && (
                <div className={styles.pageBreak} />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}
