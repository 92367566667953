import React from "react";
import styles from "./metrics-column.scss";
import ContentLoader from "react-content-loader";

const MetricsColumnLoader = ({ fields = [0, 1, 2, 3], speed = 2 }) => {
  return (
    <div className={styles.rowsContainer}>
      <div className={styles.kpisTitle}>
        <ContentLoader speed={speed} width={200} height={16}>
          <rect x="0" y="0" rx="3" ry="3" width={200} height={16} />
        </ContentLoader>
      </div>
      {fields.map((i) => (
        <div key={i}>
          {i !== 0 && <div className={styles.separator}></div>}
          <div className={styles.rowContainer}>
            <div className={styles.rowTitle}>
              <ContentLoader speed={speed} width={40} height={16}>
                <rect x="0" y="0" rx="3" ry="3" width={40} height={16} />
              </ContentLoader>
            </div>
            <div>
              <span className={styles.metricValue}>
                <ContentLoader speed={speed} width={40} height={16}>
                  <rect x="0" y="0" rx="3" ry="3" width={40} height={16} />
                </ContentLoader>
              </span>
              <span className={styles.metricDiff}>
                <ContentLoader speed={speed} width={30} height={16}>
                  <rect x="0" y="0" rx="3" ry="3" width={30} height={16} />
                </ContentLoader>
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MetricsColumnLoader;
