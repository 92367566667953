import _ from "lodash";
import {
  APPLY_INITIAL_STATE,
  FETCH_FAMILY_VARIANTS_DATA_REQUEST,
  FETCH_FAMILY_VARIANTS_DATA_SUCCESS,
  FETCH_FAMILY_VARIANTS_DATA_FAILURE,
  FETCH_FAMILY_VARIANTS_DATA_CANCEL,
  DATEPICKER_CHANGED,
  SET_SELECTED_SKU,
  SET_SELECTED_SKU_IN_PAGE,
  FETCH_SIMILAR_SKUS_TABLE_DATA_CANCEL,
  FETCH_SIMILAR_SKUS_TABLE_DATA_FAILURE,
  FETCH_SIMILAR_SKUS_TABLE_DATA_REQUEST,
  FETCH_SIMILAR_SKUS_TABLE_DATA_SUCCESS,
  STATUS_IDLE,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_SUCCESS,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_CANCEL,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_FAILURE,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_REQUEST,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_SUCCESS,
  RESET_SIMILAR_SKUS_GRAPH_STATE,
  CHANGE_SIMILAR_SKUS_TABLE_DATA_ORDER,
  FETCH_SEARCH_TERMS_DATA_CANCEL,
  FETCH_SEARCH_TERMS_DATA_FAILURE,
  FETCH_SEARCH_TERMS_DATA_REQUEST,
  FETCH_SEARCH_TERMS_DATA_SUCCESS,
} from "./constants";

const initialGraphTabState = {
  status: STATUS_IDLE,
  data: [],
};

const initialState = {
  selectedSku: null,
  familyVariants: {
    status: STATUS_LOADING,
    page: 1,
    data: {},
  },
  similarSkusTable: {
    status: STATUS_LOADING,
    data: [],
  },
  searchTerms: {
    status: STATUS_LOADING,
    data: {},
  },
  similarSkusGraphs: {},
};

const product360Reducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }

    case SET_SELECTED_SKU: {
      const data = action.payload;

      return {
        ...state,
        selectedSku: data,
      };
    }

    case SET_SELECTED_SKU_IN_PAGE: {
      const data = action.payload;
      const { sku, page } = data;

      return {
        ...state,
        selectedSku: sku,
        familyVariants: {
          ...state.familyVariants,
          page: page,
        },
      };
    }

    case FETCH_FAMILY_VARIANTS_DATA_CANCEL: {
      return {
        ...state,
        familyVariants: {
          ...initialState.familyVariants,
          status: STATUS_LOADING,
        },
      };
    }
    case FETCH_FAMILY_VARIANTS_DATA_FAILURE: {
      return {
        ...state,
        familyVariants: {
          ...initialState.familyVariants,
          status: STATUS_ERROR,
        },
      };
    }
    case FETCH_FAMILY_VARIANTS_DATA_REQUEST: {
      return {
        ...state,
        familyVariants: {
          ...initialState.familyVariants,
          status: STATUS_LOADING,
        },
      };
    }
    case FETCH_FAMILY_VARIANTS_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        familyVariants: {
          ...state.familyVariants,
          data: data,
          status: STATUS_SUCCESS,
        },
      };
    }

    case FETCH_SIMILAR_SKUS_TABLE_DATA_CANCEL: {
      return {
        ...state,
        similarSkusTable: {
          ...initialState.similarSkusTable,
          status: STATUS_LOADING,
        },
      };
    }
    case FETCH_SIMILAR_SKUS_TABLE_DATA_FAILURE: {
      return {
        ...state,
        similarSkusTable: {
          ...initialState.similarSkusTable,
          status: STATUS_ERROR,
        },
      };
    }
    case FETCH_SIMILAR_SKUS_TABLE_DATA_REQUEST: {
      return {
        ...state,
        similarSkusTable: {
          ...initialState.similarSkusTable,
          status: STATUS_LOADING,
        },
      };
    }
    case FETCH_SIMILAR_SKUS_TABLE_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        similarSkusTable: {
          data: data.skus,
          status: STATUS_SUCCESS,
        },
      };
    }
    case CHANGE_SIMILAR_SKUS_TABLE_DATA_ORDER: {
      const { data } = action.payload;
      return {
        ...state,
        similarSkusTable: {
          ...state.similarSkusTable,
          data: data,
        },
      };
    }

    case RESET_SIMILAR_SKUS_GRAPH_STATE: {
      return {
        ...state,
        similarSkusGraphs: {},
      };
    }
    case FETCH_SIMILAR_SKUS_GRAPH_DATA_CANCEL: {
      const { tab } = action.payload;
      return {
        ...state,
        similarSkusGraphs: {
          ...state.similarSkusGraphs,
          [tab]: initialGraphTabState,
        },
      };
    }
    case FETCH_SIMILAR_SKUS_GRAPH_DATA_FAILURE: {
      const { tab } = action.payload;
      return {
        ...state,
        similarSkusGraphs: {
          ...state.similarSkusGraphs,
          [tab]: {
            data: [],
            status: STATUS_ERROR,
          },
        },
      };
    }
    case FETCH_SIMILAR_SKUS_GRAPH_DATA_REQUEST: {
      const { tab } = action.payload;
      return {
        ...state,
        similarSkusGraphs: {
          ...state.similarSkusGraphs,
          [tab]: {
            data: [],
            status: STATUS_LOADING,
          },
        },
      };
    }
    case FETCH_SIMILAR_SKUS_GRAPH_DATA_SUCCESS: {
      const { tab, data } = action.payload;
      return {
        ...state,
        similarSkusGraphs: {
          ...state.similarSkusGraphs,
          [tab]: {
            data: { ...data },
            status: STATUS_SUCCESS,
          },
        },
      };
    }
    case FETCH_SEARCH_TERMS_DATA_CANCEL: {
      return {
        ...state,
        searchTerms: {
          ...initialState.searchTerms,
          status: STATUS_LOADING,
        },
      };
    }
    case FETCH_SEARCH_TERMS_DATA_FAILURE: {
      return {
        ...state,
        searchTerms: {
          ...initialState.searchTerms,
          status: STATUS_ERROR,
        },
      };
    }
    case FETCH_SEARCH_TERMS_DATA_REQUEST: {
      return {
        ...state,
        searchTerms: {
          ...initialState.searchTerms,
          status: STATUS_LOADING,
        },
      };
    }
    case FETCH_SEARCH_TERMS_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        searchTerms: {
          data: data,
          status: STATUS_SUCCESS,
        },
      };
    }
    case DATEPICKER_CHANGED: {
      return {
        ...state,
        hasData: true,
      };
    }
  }

  return state;
};

export default product360Reducer;
