import React from "react";
import FilterSidebarFilterLoader from "./filter-loader";
import FrameLoader from "./frame-loader";

const FilterSidebarLoader = (props) => {
  return (
    <>
      <FrameLoader />
      <FilterSidebarFilterLoader />
      <FilterSidebarFilterLoader />
      <FilterSidebarFilterLoader />
      <FilterSidebarFilterLoader />
    </>
  );
};

export default FilterSidebarLoader;
