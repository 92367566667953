import React, { useState } from "react";
import { EventEmitterProvider, useEventEmitter } from "@visx/xychart";
import LineHighlighter from "./line-highlighter";
import {
  CHART_CLICK_EVENT,
  CHART_NEAREST_KEY_CHANGE_EVENT,
} from "../../../visx_components/constants";

const SharedEventsProvider = ({
  areChartsSameType,
  chartsConfigs,
  highlightKey,
  onClickEvent,
  children,
}) => {
  const [controllingChart, setControllingChart] = useState();
  const [localHighlightKey, setLocalHighlightKey] = useState();

  const ChartEventHandler = () => {
    useEventEmitter(CHART_NEAREST_KEY_CHANGE_EVENT, ({ event, source }) => {
      if (controllingChart === source) setLocalHighlightKey(event.value);
    });

    useEventEmitter(CHART_CLICK_EVENT, ({ event, source }) => {
      if (controllingChart === source) onClickEvent(event);
    });

    return <></>;
  };

  const chartsConfigMapper = (children) => (
    <>
      {Object.entries(chartsConfigs).map(([chartName, chartConfig]) => (
        <span
          key={chartName}
          style={{ width: "100%" }}
          onMouseOver={() => setControllingChart(chartName)}
          onMouseLeave={() => {
            setControllingChart(null);
            setLocalHighlightKey(null);
          }}
        >
          {children(chartName, chartConfig)}
        </span>
      ))}
    </>
  );

  return (
    <>
      {areChartsSameType ? (
        <EventEmitterProvider>
          <ChartEventHandler />
          {chartsConfigMapper((chartName, chartConfig) =>
            children(
              chartName,
              chartConfig,
              highlightKey ?? localHighlightKey ?? null
            )
          )}
        </EventEmitterProvider>
      ) : (
        chartsConfigMapper((chartName, chartConfig) => (
          <LineHighlighter onClickEvent={onClickEvent}>
            {(nearestKey) =>
              children(
                chartName,
                chartConfig,
                highlightKey ?? nearestKey ?? null
              )
            }
          </LineHighlighter>
        ))
      )}
    </>
  );
};

export default SharedEventsProvider;
