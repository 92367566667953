import React from "react";
import BarTooltipComponentContent from "./bar-tooltip-component-content";

const BarTooltipComponent = (props) => (datumProps) => {
  const datum = datumProps?.nearestDatum?.datum;

  const {
    section,
    valueDecorator,
    fields,
    keyFormatter = (x) => x,
    xKey,
  } = props;

  const tooltipTitleName =
    datum.fullName && datum.fullName !== ""
      ? datum.fullName
      : keyFormatter(datum[xKey]);

  return (
    datum && (
      <BarTooltipComponentContent
        datum={datum}
        section={section}
        tooltipTitleName={tooltipTitleName}
        fields={fields}
        valueDecorator={valueDecorator}
      />
    )
  );
};

export default BarTooltipComponent;
