import React from "react";
import styles from "./sku-details-cell.scss";
import LinkButton from "../../../../../components/link-button/link-button";
import _ from "lodash";
import SkuDetailsCellLoader from "./sku-details-cell-loader";

const skuFieldsConfig = [
  { name: "Product Type", accessor: "productType" },
  { name: "Brand", accessor: "brand" },
  { name: "Supplier", accessor: "topSellerName" },
  { name: "Retailer", accessor: "rootdomain" },
];

const SkuDetailsCell = ({ data }) => {
  const skuData = data?.category?.selectedSku;

  const summarizeVariants = () => {
    const sum = {};

    for (const variant of data.category.variants) {
      if (variant.variantAttributes) {
        for (const [attrName, attrVal] of Object.entries(
          variant.variantAttributes
        )) {
          if (sum[attrName]) sum[attrName].push(attrVal);
          else sum[attrName] = [attrVal];
        }
      }
    }
    return sum;
  };

  // turns a long list into a string like "red, blue, green +5"
  const formatAttributes = (arr, limit) => {
    const str = arr.join(", ");
    if (str.length > limit) {
      let i,
        lengthSum = 0;
      for (i = 0; i < arr.length; i++) {
        lengthSum += arr[i].length + 2; // '+ 2' is because of ', '
        if (lengthSum >= limit) break;
      }
      return `${arr.slice(0, i).join(", ")} +${arr.length - i}`;
    }
    return str;
  };

  return (
    <>
      {!skuData ? (
        <SkuDetailsCellLoader />
      ) : (
        <div className={styles.cellWrapper}>
          <div className={styles.cellLeftSection}>
            <img src={skuData.image} />
          </div>
          <div className={styles.cellRightSection}>
            <div className={styles.skuTitle}>{skuData.name}</div>
            <div className={styles.skuValue}>
              SKU: {skuData.sku}
              <LinkButton href={skuData.url} />
            </div>
            <table className={styles.detailsTable}>
              <colgroup>
                <col className={styles.fieldNamesCol} />
                <col />
              </colgroup>
              <tbody>
                {skuFieldsConfig.map((field) => (
                  <tr key={field.name}>
                    <td className={styles.fieldName}>{field.name}</td>
                    <td className={styles.fieldValue}>
                      {skuData[field.accessor] ?? ""}
                    </td>
                  </tr>
                ))}
                <tr>
                  <td className={styles.fieldName}>Variants</td>
                  <td className={styles.fieldValue}>
                    {Object.entries(summarizeVariants()).map(
                      ([attribute, values]) => (
                        <span key={attribute}>
                          {attribute} {"("}
                          {_.uniq(values).length}
                          {")"}: {formatAttributes(_.uniq(values), 25)}
                          <br />
                        </span>
                      )
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </>
  );
};

export default SkuDetailsCell;
