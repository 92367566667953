import React from "react";
import styles from "./chart-tabs.scss";

const ChartTab = ({ title, isActive, onClick }) => {
  return (
    <div className={styles.tab} onClick={onClick}>
      <div className={isActive ? styles.active : styles.inactive}>
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default ChartTab;
