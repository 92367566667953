import React from "react";
import moment from "moment";
import styles from "./tooltip-component.scss";
import { decorateValue } from "../../../../styles/cell-decorators";
import BaseTooltipComponent from "./base-tooltip-component";
import LegendItemWrapper from "../../../../visx_components/legend/legendItem";
import {
  currentKey,
  currentPrefix,
  previousKey,
  previousPrefix,
  removePeriodPrefix,
} from "../transformToComparativeLineData";

const ComparativeLineTooltipComponent = (props) => (
  datumProps,
  highlightKey
) => {
  const datum = datumProps?.nearestDatum?.datum;
  const { section, entities, keyFormatter = (x) => x, xKey } = props;

  const currentDatum = {};
  currentDatum[xKey] = datum[currentKey(xKey)];
  Object.entries(datum).map(
    ([k, v]) =>
      removePeriodPrefix(k) != xKey &&
      !k.startsWith(currentPrefix) &&
      (currentDatum[removePeriodPrefix(k)] = v)
  );

  const previousDatum = {};
  previousDatum[xKey] = datum[previousKey(xKey)];
  Object.entries(datum).map(
    ([k, v]) =>
      removePeriodPrefix(k) != xKey &&
      !k.startsWith(previousPrefix) &&
      (previousDatum[removePeriodPrefix(k)] = v)
  );

  const dateFormatter = (d) => moment(d).format("MM/DD/YYYY");

  return (
    datum && (
      <BaseTooltipComponent additionalStyles={styles.boxshadowStyle}>
        <table>
          <tbody>
            <tr className={styles.tooltipRow}>
              <td>{section}</td>
              <td>Current</td>
              <td>Previous</td>
            </tr>
            <tr className={styles.tooltipRow}>
              <td></td>
              <td className={styles.date}>
                {dateFormatter(currentDatum[xKey])}
              </td>
              <td className={styles.date}>
                {dateFormatter(previousDatum[xKey])}
              </td>
            </tr>
            {Object.keys(currentDatum).map(
              (x, i) =>
                x !== xKey && (
                  <tr
                    key={i}
                    className={
                      highlightKey
                        ? removePeriodPrefix(highlightKey) === x
                          ? styles.tooltipRowHighlight
                          : styles.tooltipRowDim
                        : styles.tooltipRow
                    }
                  >
                    <td className={styles.tooltipLegendWrapper}>
                      <LegendItemWrapper
                        label={{
                          value: entities[x].stroke,
                        }}
                      />
                      {keyFormatter(x)}
                    </td>
                    <td>
                      {decorateValue(previousDatum[x], entities[x].decorator)}
                    </td>
                    <td>
                      {decorateValue(currentDatum[x], entities[x].decorator)}
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </BaseTooltipComponent>
    )
  );
};

export default ComparativeLineTooltipComponent;
