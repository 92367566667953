import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./chart-component.scss";

const ChartComponentLoader = (props) => {
  const { speed = 2, width = 180, height = 140 } = props;
  return (
    <div className={styles.loader}>
      <ContentLoader speed={speed} width={width} height={height}>
        <rect
          x="3.37305"
          y="106.025"
          width="19.6634"
          height="34.0831"
          fill="#EEEFF1"
        />
        <rect
          x="34.834"
          y="98.1592"
          width="19.6634"
          height="41.9485"
          fill="#EEEFF1"
        />
        <rect
          x="66.2969"
          y="71.9414"
          width="19.6634"
          height="68.1663"
          fill="#EEEFF1"
        />
        <rect
          x="97.7578"
          y="85.0508"
          width="19.6634"
          height="55.0574"
          fill="#EEEFF1"
        />
        <rect
          x="129.219"
          y="45.7236"
          width="19.6634"
          height="94.3841"
          fill="#EEEFF1"
        />
        <rect
          x="160.68"
          y="27.3713"
          width="19.6634"
          height="112.737"
          fill="#EEEFF1"
        />
        <path d="M137.659 20.4635L177.182 3.61776L175.64 0L135.199 17.2374L105.53 54.6457L74.8609 46.8154L43.0221 79.3174L0.34375 88.3704L1.1598 92.2175L45.0002 82.918L76.0841 51.1865L107.027 59.0868L137.659 20.4635Z" />
      </ContentLoader>
    </div>
  );
};

export default ChartComponentLoader;
