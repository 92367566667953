import rankingEventsHasRank from "../../images/rankingEvents-has-rank.svg";
import rankingEventsNotAvailable from "../../images/rankingEvents-not-available.svg";
import rankingEventsRankDrop from "../../images/rankingEvents-rank-drop.svg";
import rankingEventsRankGain from "../../images/rankingEvents-rank-gain.svg";
import rankingEventsSkuSeparation from "../../images/variant-separation.svg";
import rankingEventsOrphanedSku from "../../images/orphaned_sku.svg";
import rankingEventsNewSku from "../../images/new_sku.svg";
import OutOfStock from "../../images/ranking-events-oos.svg";
import NegativeReview from "../../images/ranking-events-negative-review.svg";
import VisibilityDrops from "../../images/ranking-events-visibility-drops.svg";
import VisibilityGains from "../../images/ranking-events-visibility-gains.svg";
import RatingDrops from "../../images/ranking-events-rating-drops.svg";
import ArrowsUpDownIcon from "../../images/arrows-up-down.svg";
import VariantSeparationIcon from "../../images/variant-separation.svg";
import VisibilityIcon from "../../images/visibility.svg";
import * as Decorators from "../../styles/cell-decorators";
import { LoaderWidths } from "../../components/tables/simple-table/simple-table";

const path = "rankingEvents";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;

export const FETCH_RANKING_EVENTS_TABLE_DATA_REQUEST = `${path}/FETCH_RANKING_EVENTS_TABLE_DATA_REQUEST`;
export const FETCH_RANKING_EVENTS_TABLE_DATA_SUCCESS = `${path}/FETCH_RANKING_EVENTS_TABLE_DATA_SUCCESS`;
export const FETCH_RANKING_EVENTS_TABLE_DATA_FAILURE = `${path}/FETCH_RANKING_EVENTS_TABLE_DATA_FAILURE`;
export const FETCH_RANKING_EVENTS_TABLE_DATA_CANCEL = `${path}/FETCH_RANKING_EVENTS_TABLE_DATA_CANCEL`;

export const FETCH_MORE_RANKING_EVENTS_TABLE_DATA_REQUEST = `${path}/FETCH_MORE_RANKING_EVENTS_TABLE_DATA_REQUEST`;
export const FETCH_MORE_RANKING_EVENTS_TABLE_DATA_SUCCESS = `${path}/FETCH_MORE_RANKING_EVENTS_TABLE_DATA_SUCCESS`;
export const FETCH_MORE_RANKING_EVENTS_TABLE_DATA_FAILURE = `${path}/FETCH_MORE_RANKING_EVENTS_TABLE_DATA_FAILURE`;
export const FETCH_MORE_RANKING_EVENTS_TABLE_DATA_CANCEL = `${path}/FETCH_MORE_RANKING_EVENTS_TABLE_DATA_CANCEL`;

const insightsPath = `${path}/insights-skus`;
export const CHANGE_RANKING_EVENTS_TABLE_SORTING = `${insightsPath}/CHANGE_SKUS_SORTING`;
export const CHANGE_RANKING_EVENTS_TABLE_OFFSET = `${insightsPath}/CHANGE_SKUS_OFFSET`;
export const SET_CURRENT_VIEW_IS_UPDATED = `${insightsPath}/SET_CURRENT_VIEW_IS_UPDATED`;
export const CHANGE_VIEW_SETTINGS = `${insightsPath}/CHANGE_SKUS_SETTINGS`;

export const RANKING_EVENTS_VIEW_TYPE_DATA = {
  name: "product_page_notifications",
  id: 4,
  subspace: "rankingEvents",
};

export const EVENTS_PRESETS = ["Last day", "Last 7 days", "Last 30 days"];

export const EVENT_GROUPS = {
  itemPosition: "Position",
  variantSeparation: "Variant Separation",
  visibility: "Visibility",
  other: "Other",
};

export const EVENT_GROUPS_ICONS = {
  [EVENT_GROUPS.itemPosition]: ArrowsUpDownIcon,
  [EVENT_GROUPS.variantSeparation]: VariantSeparationIcon,
  [EVENT_GROUPS.visibility]: VisibilityIcon,
  [EVENT_GROUPS.other]: null,
};

export const EVENT_FILTER_DECORATORS = {
  float: "float",
  int: "int",
  percent: "percent",
  negativePercent: "negativePercent",
  starRating: "starRating",
};

export const COLUMNS = {
  sku: {
    id: -1,
    label: "SKU",
    accessor: "sku",
    cellDecorator: Decorators.rankingEventsSkuDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  time: {
    id: 0,
    label: "Event date",
    accessor: "timestamp",
    cellDecorator: Decorators.dateTimeDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  sales: {
    id: 1,
    label: "Sales, 30 days",
    accessor: "gmv30d",
    cellDecorator: Decorators.amountDollarsDecorator("gmv"),
    loaderWidth: LoaderWidths.Big,
  },
  category: {
    id: 3,
    label: "Category",
    accessor: "category",
    cellDecorator: Decorators.noDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  brand: {
    id: 4,
    label: "Brand",
    accessor: "brand",
    cellDecorator: Decorators.noDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  seller: {
    id: 5,
    label: "Top Seller",
    accessor: "sellerName",
    cellDecorator: Decorators.noDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  retailer: {
    id: 6,
    label: "Retailer",
    accessor: "rootdomain",
    cellDecorator: Decorators.noDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  lastSeen: {
    id: 7,
    label: "Last Time Seen",
    accessor: "lastSeen",
    cellDecorator: Decorators.dateTimeDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  lastPosition: {
    id: 8,
    label: "Last Known Position",
    accessor: "rank",
    cellDecorator: Decorators.rankingPositionDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  daysOos: {
    id: 9,
    label: "Days out of stock",
    accessor: "filterValue",
    cellDecorator: Decorators.roundNumberDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  ratingScore: {
    id: 10,
    label: "Rating Score",
    accessor: "a", // this is on purpose. react-table doesn't accept more than one column with the same accessor :)
    cellDecorator: Decorators.displayValuesDecorator(
      "review_score",
      Decorators.roundNumberDecorator
    ),
    loaderWidth: LoaderWidths.Big,
  },
  ratingText: {
    id: 11,
    label: "Provided Content",
    accessor: "b",
    cellDecorator: Decorators.displayValuesDecorator("review_text"),
    loaderWidth: LoaderWidths.Big,
  },
  visibility: {
    id: 12,
    label: "Visibility 7d",
    accessor: "c",
    cellDecorator: Decorators.displayValuesDecorator(
      "visibility_7d",
      Decorators.percentDecorator
    ),
    loaderWidth: LoaderWidths.Big,
  },
  prevVisibility: {
    id: 13,
    label: "Previous Visibility 7d",
    accessor: "d",
    cellDecorator: Decorators.displayValuesDecorator(
      "prev_visibility_7d",
      Decorators.percentDecorator
    ),
    loaderWidth: LoaderWidths.Big,
  },
  sponsored: {
    id: 14,
    label: "Sponsored Rate 7d",
    accessor: "e",
    cellDecorator: Decorators.displayValuesDecorator(
      "sponsored_7d",
      Decorators.percentDecorator
    ),
    loaderWidth: LoaderWidths.Big,
  },
  prevSponsored: {
    id: 15,
    label: "Previous Sponsored Rate 7d",
    accessor: "f",
    cellDecorator: Decorators.displayValuesDecorator(
      "prev_sponsored_7d",
      Decorators.percentDecorator
    ),
    loaderWidth: LoaderWidths.Big,
  },
  avgRating: {
    id: 16,
    label: "Average Rating",
    accessor: "g",
    cellDecorator: Decorators.displayValuesDecorator("average_customer_review"),
    loaderWidth: LoaderWidths.Big,
  },
  newFamily: {
    id: 17,
    label: "New Family",
    accessor: "newState",
    cellDecorator: Decorators.familyDisplayDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  reviewDate: {
    id: 18,
    label: "Review Date",
    accessor: "reviewDate",
    cellDecorator: Decorators.displayValuesDecorator(
      "review_date",
      Decorators.dateTimeDecorator
    ),
    loaderWidth: LoaderWidths.Big,
  },
};

const sharedColumns = [COLUMNS.sku, COLUMNS.time, COLUMNS.sales];

export const EVENT_TYPE_PROPERTIES = {
  0: {
    title: "SKU found on pages 1-3",
    icon: rankingEventsHasRank,
    type: EVENT_GROUPS.itemPosition,
    columns: [...sharedColumns, COLUMNS.lastPosition, COLUMNS.lastSeen],
  },
  1: {
    title: "SKU not found on pages 1-3",
    icon: rankingEventsNotAvailable,
    type: EVENT_GROUPS.itemPosition,
    columns: [...sharedColumns, COLUMNS.lastPosition, COLUMNS.lastSeen],
  },
  2: {
    title: "Decrease in Avg Rank Week Over Week",
    icon: rankingEventsRankDrop,
    type: EVENT_GROUPS.itemPosition,
    filterValueName: "Positions drop",
    filterValueDecorator: EVENT_FILTER_DECORATORS.int,
    filterValueCellDecorator: Decorators.roundNumberDecorator,
    tooltip: "When the product drops page position",
    columns: [...sharedColumns],
  },
  3: {
    title: "Increase in Avg Rank Week Over Week",
    icon: rankingEventsRankGain,
    type: EVENT_GROUPS.itemPosition,
    filterValueName: "Positions gain",
    filterValueDecorator: EVENT_FILTER_DECORATORS.int,
    filterValueCellDecorator: Decorators.roundNumberDecorator,
    tooltip: "When the product gains page position",
    columns: [...sharedColumns],
  },
  4: {
    title: "Family Changed",
    icon: rankingEventsSkuSeparation,
    type: EVENT_GROUPS.variantSeparation,
    columns: [...sharedColumns],
  },
  5: {
    title: "Orphaned Sku",
    icon: rankingEventsOrphanedSku,
    type: EVENT_GROUPS.variantSeparation,
    columns: [...sharedColumns],
  },
  6: {
    title: "New Sku",
    icon: rankingEventsNewSku,
    type: EVENT_GROUPS.variantSeparation,
    columns: [...sharedColumns],
  },
  7: {
    title: "Out Of Stock",
    icon: OutOfStock,
    type: null,
    filterValueName: "Days out of stock",
    filterValueDecorator: EVENT_FILTER_DECORATORS.int,
    filterValueCellDecorator: Decorators.roundNumberDecorator,
    tooltip: "Notify me when Days out of stock is within the range of",
    columns: [...sharedColumns, COLUMNS.daysOos],
  },
  8: {
    title: "Rating Drops",
    icon: RatingDrops,
    type: null,
    filterValueName: "Average Rating",
    filterValueDecorator: EVENT_FILTER_DECORATORS.float,
    filterValueCellDecorator: Decorators.numberDecorator,
    tooltip:
      "Average of the SKU's review ratings. Value must be a decimal between 0 and 5.",
    columns: [...sharedColumns, COLUMNS.avgRating],
  },
  9: {
    title: "Negative Review",
    icon: NegativeReview,
    type: null,
    filterValueName: "Star Rating",
    filterValueDecorator: EVENT_FILTER_DECORATORS.starRating,
    filterValueCellDecorator: Decorators.numberDecorator,
    tooltip: "",
    columns: [
      ...sharedColumns,
      COLUMNS.ratingScore,
      COLUMNS.ratingText,
      COLUMNS.reviewDate,
    ],
  },
  10: {
    title: "Visibility Drops Week Over Week",
    icon: VisibilityDrops,
    type: EVENT_GROUPS.visibility,
    filterValueName: "Visibility rate drops",
    filterValueDecorator: EVENT_FILTER_DECORATORS.negativePercent,
    filterValueCellDecorator: Decorators.percentDecorator,
    tooltip: "When a product visibility drops",
    columns: [
      ...sharedColumns,
      COLUMNS.visibility,
      COLUMNS.prevVisibility,
      COLUMNS.sponsored,
      COLUMNS.prevSponsored,
    ],
  },
  11: {
    title: "Visibility Gains Week Over Week",
    icon: VisibilityGains,
    type: EVENT_GROUPS.visibility,
    filterValueName: "Visibility rate gains",
    filterValueDecorator: EVENT_FILTER_DECORATORS.percent,
    filterValueCellDecorator: Decorators.percentDecorator,
    tooltip: "When a product visibility increases",
    columns: [
      ...sharedColumns,
      COLUMNS.visibility,
      COLUMNS.prevVisibility,
      COLUMNS.sponsored,
      COLUMNS.prevSponsored,
    ],
  },
};
