import React from "react";
import styles from "./tooltip-component.scss";

const BaseTooltipComponent = (props) => {
  const { additionalStyles } = props;
  return (
    <div
      className={
        additionalStyles
          ? `${styles.tooltip} ${additionalStyles}`
          : `${styles.tooltip}`
      }
    >
      {props.children}
    </div>
  );
};

export default BaseTooltipComponent;
