import React from "react";
import {
  numberDecorator,
  chartTickNumberFormat,
  roundNumberChangeDecorator,
} from "../../../../styles/cell-decorators";
import ChartComponent from "../../../../components/dashboard-components/chart-component/chart-component";

export default function CustomerActiveSellingSkusChartComponent(props) {
  return (
    <ChartComponent
      {...props}
      title={"Your Active Selling SKUs"}
      currentKey={"currentActiveSellingSkus"}
      previousKey={"previousActiveSellingSkus"}
      changeKey={"activeSellingSkusDiff"}
      taglineSubject={"Your active selling SKUs"}
      legendName={"Active Selling SKUs"}
      valueDecorator={numberDecorator}
      diffDecorator={roundNumberChangeDecorator}
      yTickFormat={chartTickNumberFormat}
    />
  );
}
