import {
  FETCH_SKU_DETAILS_DATA_CANCEL,
  FETCH_SKU_DETAILS_DATA_FAILURE,
  FETCH_SKU_DETAILS_DATA_REQUEST,
  FETCH_SKU_DETAILS_DATA_SUCCESS,
  APPLY_INITIAL_STATE,
  SET_SELECTED_MATCHING_ID,
} from "./constants";

import CatalogTrackerService from "../../services/catalogTrackerService";

const catalogTrackerService = new CatalogTrackerService();

/* Internal Requests */
export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

export const setSelectedMatchingId = (data) => ({
  type: SET_SELECTED_MATCHING_ID,
  payload: data,
});

/* Sku Details Requests */

export const skuDetailsDataRequested = () => ({
  type: FETCH_SKU_DETAILS_DATA_REQUEST,
});

export const skuDetailsDataSuccess = (data) => ({
  type: FETCH_SKU_DETAILS_DATA_SUCCESS,
  payload: { data },
});

export const skuDetailsDataError = (error) => ({
  type: FETCH_SKU_DETAILS_DATA_FAILURE,
  payload: { error },
});

export const skuDetailsDataCancel = () => ({
  type: FETCH_SKU_DETAILS_DATA_CANCEL,
});

export const fetchSkuDetailsData = (matchingId, requestParams) => (
  dispatch
) => {
  dispatch(skuDetailsDataRequested());
  catalogTrackerService
    .getSkuDetails(matchingId, requestParams)
    .then((res) => {
      dispatch(skuDetailsDataSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(skuDetailsDataCancel());
      } else {
        dispatch(skuDetailsDataError(err.message));
      }
    });
};
