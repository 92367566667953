import React, { useState } from "react";
import BaseComponent from "../../../../components/dashboard-components/base-component";
import Tabs from "../../../../components/tabs/tabs";
import MarketShareAnalysisTab from "../tab";
import styles from "./market-share-analysis.scss";
import Switch from "../../../../components/switch/switch";
import { MARKET_SHARE_ANALYSIS_GROUP_BY } from "../../constants";
import MbInsightsAiSidebar from "../../../insights/insights-ai-sidebar";
import AnalysisInsights from "../analysis-insights/analysis-insights";
import ToggleAIButton from "../../../insights/insights-ai-sidebar/toggle-ai-button";
import { analyticsInsightsAiOpened } from "../../../shared/analytics/actions";
import { useEffect } from "react";

export default function MarketShareAnalysis(props) {
  const {
    loading,
    data,
    groupBy,
    onChangeGroupBy,
    onClose,
    subjectName,
    viewTypeData,
    insightsAiShowSidebar,
    showInsightsAiSidebar,
    viewName,
  } = props;

  const [localSidebarOpen, setLocalSidebarOpen] = useState(false);

  useEffect(() => {
    if (insightsAiShowSidebar && localSidebarOpen) {
      setLocalSidebarOpen(false);
    }
  }, [insightsAiShowSidebar]);

  const subject = subjectName != null ? subjectName : "Your";

  const tabsConfig = [
    {
      id: 1,
      name: `${subject} SKUs`,
      component: (
        <MarketShareAnalysisTab
          {...props}
          data={data?.skusInsights}
          isLoading={loading}
        />
      ),
    },
    {
      id: 2,
      name: "Competing SKUs",
      component: (
        <MarketShareAnalysisTab
          {...props}
          data={data?.competingSkus}
          isLoading={loading}
        />
      ),
    },
  ];

  const [selectedTab, setSelectedTab] = useState(tabsConfig[0].id);
  const onTabSelection = (id) => {
    setSelectedTab(id);
  };
  const selectedComponent = tabsConfig.find((x) => x.id === selectedTab)
    .component;

  let sentence = "";
  const key = selectedTab == 1 ? "skusInsights" : "competingSkus";
  const prefix = selectedTab == 1 ? subject : "Competitors";
  const hasData = data != null && data[key]?.length > 0;

  if (hasData) {
    const marketShareDirection =
      data[key][0]?.skuMarketShareKpis?.salesDollarsShareChange > 0;
    const marketShareDescription = marketShareDirection ? "surges" : "declines";
    const skusDescription = marketShareDirection ? "gaining" : "dropping";
    sentence =
      prefix +
      " market share " +
      marketShareDescription +
      ": Top " +
      skusDescription +
      " SKUs";
  }

  const switchComponent = (
    <div className={styles.switch}>
      <Switch
        titleRight={"Show SKU level insights"}
        toggled={groupBy == MARKET_SHARE_ANALYSIS_GROUP_BY.SKU}
        onChange={onChangeGroupBy}
      />
    </div>
  );

  const closeButton = <i className={styles.close} onClick={onClose} />;

  const headerRightSide = (
    <div className={styles.headerRightSide}>
      {!loading && switchComponent}
      {closeButton}
    </div>
  );

  const header = (
    <div className={styles.header}>
      {sentence}
      <ToggleAIButton
        active={!loading}
        onClick={() => {
          showInsightsAiSidebar(false);
          setLocalSidebarOpen(true);
          analyticsInsightsAiOpened(viewTypeData.id, viewName);
        }}
      />
      {headerRightSide}
    </div>
  );

  const component = (
    <>
      {header}
      <Tabs
        tabs={tabsConfig}
        activeTab={selectedTab}
        setActiveTab={onTabSelection}
      />
      {selectedComponent}
      {hasData && localSidebarOpen && (
        <MbInsightsAiSidebar
          viewTypeId={viewTypeData.id}
          handleClose={() => setLocalSidebarOpen(false)}
        >
          {(textToCopy) => (
            <AnalysisInsights
              textToCopy={textToCopy}
              skus={data[key].map((x) => x.insightsText)}
              viewTypeData={viewTypeData}
            />
          )}
        </MbInsightsAiSidebar>
      )}
    </>
  );

  return <BaseComponent {...props} component={component} isEnabled={true} />;
}
