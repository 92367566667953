import {
  FETCH_BRANDS_REQUEST,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  FETCH_BRANDS_CANCEL,
  CLEAR_BRANDS,
} from "./constants";

const brandsReducer = (
  state = {
    data: [],
    loading: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_BRANDS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_BRANDS_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data,
        loading: false,
      };
    }

    case FETCH_BRANDS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case FETCH_BRANDS_CANCEL: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLEAR_BRANDS: {
      return {
        data: [],
        loading: false,
        error: "",
      };
    }
  }

  return state;
};

export default brandsReducer;
