import { isFunction } from "lodash";

// Liran: this is a very ugly solution for code reuse. I tried to encapsulate fetchData in a custom hook but was unable to connect it to redux probably
// since we are using an old version of redux. This should be fixed in the future when moving to a new version of redux.
export default class MbComponentsDataFetcher {
  createConfig(
    currentSelectedRetailer,
    selectedDateRange,
    currentViewConfig,
    selectedPreviousDateRange
  ) {
    return (
      JSON.stringify(currentSelectedRetailer) +
      JSON.stringify(selectedDateRange) +
      JSON.stringify(currentViewConfig) +
      JSON.stringify(selectedPreviousDateRange)
    );
  }

  fetchData(
    queryToSend,
    selectedDateRange,
    componentsConfig,
    fetchDataFail,
    fetchDataRequest,
    fetchDataSuccess
  ) {
    componentsConfig.components.forEach((component) => {
      fetchDataRequest(component.name);

      // Call all fetch requests for this component
      const promises = component.actions.fetch.map(
        (fetchRequest, i) =>
          new Promise((resolve, reject) => {
            let resolvedResponse;
            fetchRequest(queryToSend)
              .then((response) => {
                resolvedResponse = response.data ?? response;
                if (
                  component.actions.success &&
                  isFunction(component.actions.success[i])
                ) {
                  resolvedResponse = component.actions.success[i](
                    resolvedResponse
                  );
                }
                resolve(resolvedResponse);
              })
              .catch((error) => {
                error["componentName"] = component.name;
                if (
                  component.actions.fail &&
                  isFunction(component.actions.fail[i])
                ) {
                  resolvedResponse = component.actions.fail[i](error);
                }
                reject(resolvedResponse);
              });
          })
      );

      Promise.all(promises)
        .then((responses) => {
          // extracting data from responses and sending to callback
          fetchDataSuccess(component.name, Object.assign({}, ...responses));
        })
        .catch((error) => {
          fetchDataFail(error);
        });
    });
  }

  fetchDataIfRequired(
    currentSelectedRetailer,
    selectedDateRange,
    currentViewConfig,
    selectedPreviousDateRange,
    domainsMetadata,
    comparePeriod,
    componentsConfig,
    lastConfig,
    fetchDataFail,
    fetchDataRequest,
    fetchDataSuccess,
    viewTypeId
  ) {
    const config = this.createConfig(
      currentSelectedRetailer,
      selectedDateRange,
      currentViewConfig,
      selectedPreviousDateRange
    );

    if (
      currentSelectedRetailer &&
      domainsMetadata && // Verify we already retrieved metadata
      selectedDateRange &&
      selectedPreviousDateRange &&
      currentViewConfig &&
      currentViewConfig.query &&
      config !== lastConfig
    ) {
      const queryToSend = {
        ...currentViewConfig.query,
        previousStartDate: selectedPreviousDateRange.previousStartDate,
        previousEndDate: selectedPreviousDateRange.previousEndDate,
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        comparePeriod: comparePeriod,
        selectedDateRange: selectedDateRange,
        viewType: viewTypeId,
      };

      this.fetchData(
        queryToSend,
        selectedDateRange,
        componentsConfig,
        fetchDataFail,
        fetchDataRequest,
        fetchDataSuccess
      );

      return config;
    }
    return false;
  }
}
