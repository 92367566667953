import {
  APPLY_INITIAL_STATE,
  FETCH_FAMILY_VARIANTS_DATA_REQUEST,
  FETCH_FAMILY_VARIANTS_DATA_SUCCESS,
  FETCH_FAMILY_VARIANTS_DATA_FAILURE,
  FETCH_FAMILY_VARIANTS_DATA_CANCEL,
  DATEPICKER_CHANGED,
  SET_SELECTED_SKU,
  SET_SELECTED_SKU_IN_PAGE,
  CHANGE_VIEW_SETTINGS,
  FETCH_SIMILAR_SKUS_TABLE_DATA_REQUEST,
  FETCH_SIMILAR_SKUS_TABLE_DATA_SUCCESS,
  FETCH_SIMILAR_SKUS_TABLE_DATA_FAILURE,
  FETCH_SIMILAR_SKUS_TABLE_DATA_CANCEL,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_REQUEST,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_SUCCESS,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_FAILURE,
  FETCH_SIMILAR_SKUS_GRAPH_DATA_CANCEL,
  RESET_SIMILAR_SKUS_GRAPH_STATE,
  CHANGE_SIMILAR_SKUS_TABLE_DATA_ORDER,
  CHANGE_VIEW_CHARTS_SETTINGS,
  FETCH_SEARCH_TERMS_DATA_REQUEST,
  FETCH_SEARCH_TERMS_DATA_SUCCESS,
  FETCH_SEARCH_TERMS_DATA_FAILURE,
  FETCH_SEARCH_TERMS_DATA_CANCEL,
} from "./constants";

import Product360Service from "../../services/product360Service";

const product360Service = new Product360Service();

export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

export const setSelectedSku = (data) => ({
  type: SET_SELECTED_SKU,
  payload: data,
});

export const setSelectedSkuInPage = (sku, page) => ({
  type: SET_SELECTED_SKU_IN_PAGE,
  payload: {
    sku: sku,
    page: page,
  },
});

/*  Family Variants Data */

export const familyVariantsDataRequested = () => ({
  type: FETCH_FAMILY_VARIANTS_DATA_REQUEST,
});

export const familyVariantsDataSuccess = (data) => ({
  type: FETCH_FAMILY_VARIANTS_DATA_SUCCESS,
  payload: { data },
});

export const familyVariantsDataError = (error) => ({
  type: FETCH_FAMILY_VARIANTS_DATA_FAILURE,
  payload: { error },
});

export const familyVariantsDataCancel = () => ({
  type: FETCH_FAMILY_VARIANTS_DATA_CANCEL,
});

/* Similar SKUs Table */

export const similarSkusTableDataRequested = () => ({
  type: FETCH_SIMILAR_SKUS_TABLE_DATA_REQUEST,
});

export const similarSkusTableDataSuccess = (data) => ({
  type: FETCH_SIMILAR_SKUS_TABLE_DATA_SUCCESS,
  payload: { data },
});

export const similarSkusTableDataError = (error) => ({
  type: FETCH_SIMILAR_SKUS_TABLE_DATA_FAILURE,
  payload: { error },
});

export const similarSkusTableDataCancel = () => ({
  type: FETCH_SIMILAR_SKUS_TABLE_DATA_CANCEL,
});

export const changeSimilarSkusTableDataOrder = (data) => ({
  type: CHANGE_SIMILAR_SKUS_TABLE_DATA_ORDER,
  payload: { data },
});

/* Similar SKUs Table */

export const resetSimilarSkusGraphs = () => ({
  type: RESET_SIMILAR_SKUS_GRAPH_STATE,
});

export const similarSkusGraphDataRequested = (tab) => ({
  type: FETCH_SIMILAR_SKUS_GRAPH_DATA_REQUEST,
  payload: { tab },
});

export const similarSkusGraphDataSuccess = (tab, data) => ({
  type: FETCH_SIMILAR_SKUS_GRAPH_DATA_SUCCESS,
  payload: { tab, data },
});

export const similarSkusGraphDataError = (tab) => ({
  type: FETCH_SIMILAR_SKUS_GRAPH_DATA_FAILURE,
  payload: { tab },
});

export const similarSkusGraphDataCancel = (tab) => ({
  type: FETCH_SIMILAR_SKUS_GRAPH_DATA_CANCEL,
  payload: { tab },
});

/* Search Terms */

export const searchTermsDataRequested = () => ({
  type: FETCH_SEARCH_TERMS_DATA_REQUEST,
});

export const searchTermsDataSuccess = (data) => ({
  type: FETCH_SEARCH_TERMS_DATA_SUCCESS,
  payload: { data },
});

export const searchTermsDataError = () => ({
  type: FETCH_SEARCH_TERMS_DATA_FAILURE,
});

export const searchTermsDataCancel = () => ({
  type: FETCH_SEARCH_TERMS_DATA_CANCEL,
});

/* SETTINGS */

export const changeViewSettings = (data) => ({
  type: CHANGE_VIEW_SETTINGS,
  payload: data,
});

export const changeChartsSettings = (data) => ({
  type: CHANGE_VIEW_CHARTS_SETTINGS,
  payload: data,
});

export const fetchFamilyVariantsData = (requestParams) => (dispatch) => {
  dispatch(familyVariantsDataRequested());

  product360Service
    .fetchFamilyVariantsData(requestParams)
    .then((res) => {
      dispatch(familyVariantsDataSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(familyVariantsDataCancel());
      } else {
        dispatch(familyVariantsDataError(err.message));
      }
    });
};

export const fetchSkuComparisonData = (requestParams) => {
  return product360Service.fetchSkuComparisonData(requestParams);
};

export const fetchPromoActivity = (requestParams) => {
  return product360Service.fetchPromoActivity(requestParams);
};

export const fetchSearchTermsData = (requestParams) => (dispatch) => {
  dispatch(searchTermsDataRequested());

  product360Service
    .fetchSearchTermsData(requestParams)
    .then((res) => {
      dispatch(searchTermsDataSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(searchTermsDataCancel());
      } else {
        dispatch(searchTermsDataError(err.message));
      }
    });
};

export const fetchSimilarSkusTable = (requestParams) => (dispatch) => {
  dispatch(similarSkusTableDataRequested());

  product360Service
    .fetchSimilarProductsTable(requestParams)
    .then((res) => {
      dispatch(similarSkusTableDataSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(similarSkusTableDataCancel());
      } else {
        dispatch(similarSkusTableDataError(err.message));
      }
    });
};

export const fetchSimilarProductsGraph = (tab, requestParams) => (dispatch) => {
  dispatch(similarSkusGraphDataRequested(tab));

  product360Service
    .fetchSimilarProductsGraph(requestParams)
    .then((res) => {
      dispatch(similarSkusGraphDataSuccess(tab, res.data));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(similarSkusGraphDataCancel(tab));
      } else {
        dispatch(similarSkusGraphDataError(tab));
      }
    });
};
