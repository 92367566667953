import React, { Component } from "react";
import HeatMap from "react-heatmap-grid";

import styles from "./heatmap-grid.scss";

class HeatmapGrid extends Component {
  calcYLableWidth = (yLabels = []) => {
    const defaultWidth = 60;
    const pxOnOneSymbol = 10;
    let maxLength = 0;

    if (!yLabels) {
      return defaultWidth;
    }

    yLabels.map((item) => {
      if (item !== null) {
        if (item.length > maxLength) {
          maxLength = item.length;
        }
      }
    });

    return maxLength * pxOnOneSymbol;
  };

  render() {
    const { data, xLabels, yLabels } = this.props;

    const yLabelWidth = this.calcYLableWidth(yLabels);

    return (
      <div className={styles.wrapper}>
        <HeatMap
          xLabels={xLabels}
          yLabels={yLabels}
          xLabelsLocation={"top"}
          yLabelTextAlign="left"
          height={22}
          width={100}
          yLabelWidth={yLabelWidth}
          data={data}
          // squares
          cellStyle={(background, value, min, max, data, x, y) => {
            const bg =
              parseInt(value) === 1
                ? "#dedede"
                : parseInt(value) === 2
                ? "#021326"
                : "#fff";
            return {
              background: bg,
            };
          }}
          title={() => {}}
        />
      </div>
    );
  }
}

export default HeatmapGrid;
