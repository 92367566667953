import React, { Component } from "react";

import Dropdown from "../../../../../components/dropdown";
import styles from "./insights-revenue-leakage-header.scss";

const sortOptions = [
  {
    id: 1,
    name: "Ascending",
  },
  {
    id: 2,
    name: "Descending",
  },
];

export default class InsightsRevenueLeakageHeader extends Component {
  render() {
    const { revenueSortValue, handleSelect } = this.props;

    return (
      <div className={styles.wrapper}>
        <p className={styles.title}>Revenue Leakage</p>
        <div className={styles.dropdownBox}>
          <Dropdown
            size="small"
            options={sortOptions}
            value={revenueSortValue}
            handleSelect={handleSelect}
          />
        </div>
      </div>
    );
  }
}
