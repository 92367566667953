import React, { useMemo } from "react";

import {
  Grid,
  LineSeries,
  XYChart,
  Axis,
  Tooltip,
  buildChartTheme,
} from "@visx/xychart";
import {
  curveLinear,
  curveStep,
  curveCardinal,
  curveMonotoneX,
} from "@visx/curve";
import { scaleLinear } from "@visx/scale";
import { getNumTicks } from "./xychart-utils";

const GRID_COLOR = "#adb5bd66";

export default function XYChartDualAxis(props) {
  const {
    data,
    dataKeys,

    // y axes config
    rightScaleType = "linear",
    leftScaleType = "linear",

    rightTickFormatter = (x) => x,
    leftTickFormatter = (x) => x,

    rightScaleInvert = false,
    leftScaleInvert = false, // needs configuration

    rightLabel,
    leftLabel,

    curveType = "linear",

    // x axis
    xAxis = true,
    xScaleType = "time",
    xPadding,
    xPaddingInner,
    xValuesNumeric = true,
    xOrientation = "bottom",
    xLabel,
    xKey,
    xNumTicks = 10,
    xTickFormatter = (x) => x,

    height = 400,
    margin,

    tooltipCallback,

    animationTrajectory = "center",
    snapTooltipToDatumX = true,
    snapTooltipToDatumY = false,
    showGridRows = true,
    showGridColumns = true,
    showTooltipVerticalCrosshair = true,
    showTooltipHorizontalCrosshair = false,
    showDatumGlyph = true,
    showSeriesGlyphs = true,
  } = props;
  const rightDataKeys = {};
  const leftDataKeys = {};
  Object.entries(dataKeys).map(([k, v]) =>
    v.axis == "left" ? (leftDataKeys[k] = v) : (rightDataKeys[k] = v)
  );

  const curveKeys = Object.keys(dataKeys);
  const colors = curveKeys.map((key) => dataKeys[key].stroke);
  const theme = buildChartTheme({ colors: colors });

  const xDomain = xValuesNumeric
    ? [
        Math.min.apply(
          null,
          data.map((x) => x[xKey])
        ),
        Math.max.apply(
          null,
          data.map((x) => x[xKey])
        ),
      ]
    : data.map((x) => x[xKey]);

  const yDomainLeft = [
    Math.min(
      ...data.map((x) =>
        Math.min(...Object.keys(leftDataKeys).map((k) => x[k]))
      )
    ),
    Math.max(
      ...data.map((x) =>
        Math.max(...Object.keys(leftDataKeys).map((k) => x[k]))
      )
    ),
  ];

  const leftNumTicks = getNumTicks(leftTickFormatter, yDomainLeft);

  const xScale = {
    type: xScaleType,
    domain: xDomain,
    padding: xPadding,
    paddingInner: xPaddingInner,
  };

  const yScaleConfig = {
    type: leftScaleType,
  };

  const leftAccessors = useMemo(
    () => ({
      x: Object.assign({}, ...curveKeys.map((x) => ({ [x]: (d) => d[xKey] }))), // take X from a single place
      y: Object.assign({}, ...curveKeys.map((x) => ({ [x]: (d) => d[x] }))), // take Y from dataKey in data
    }),
    [curveKeys]
  );

  const getTickValues = () => {
    // Expects time values as unix timestamp
    if (xScaleType == "time") {
      const tickDates = data.map((x) => new Date(x[xKey]));
      if (tickDates.length > xNumTicks) {
        return tickDates.filter((x, i) => {
          return i % Math.ceil(tickDates.length / xNumTicks) === 0;
        });
      }
      return tickDates;
    }
    return undefined;
  };

  const curveTypeSelector = () => {
    switch (curveType) {
      case "curveMonotoneX": {
        return curveMonotoneX;
      }
      case "cardinal": {
        return curveCardinal;
      }
      case "step": {
        return curveStep;
      }
      default:
      case "linear": {
        return curveLinear;
      }
    }
  };

  const prepareRightScale = () => {
    var yDomainRight = [
      Math.min(
        ...data.map((x) =>
          Math.min(
            ...Object.keys(rightDataKeys)
              .map((k) => x[k])
              .filter((x) => x !== null)
          )
        )
      ),
      Math.max(
        ...data.map((x) =>
          Math.max(
            ...Object.keys(rightDataKeys)
              .map((k) => x[k])
              .filter((x) => x !== null)
          )
        )
      ),
    ];

    var rightTickFormat;
    if (rightScaleType == "percentage") {
      rightTickFormat = (x) => rightTickFormatter(x / yDomainLeft[1]);
    } else {
      if (
        yDomainRight[0] === yDomainRight[1] ||
        rightTickFormatter(yDomainRight[0]) ===
          rightTickFormatter(yDomainRight[1])
      ) {
        yDomainRight = [yDomainRight[0] - 10, yDomainRight[0] + 10];
      }
    }

    const rightNumTicks = getNumTicks(rightTickFormatter, yDomainRight);

    if (rightScaleInvert) yDomainRight = [yDomainRight[1], yDomainRight[0]];
    const rightScaleValues = scaleLinear({
      range: yDomainLeft,
      domain: yDomainRight,
    });
    const rightScaleTicks = scaleLinear({
      range: yDomainRight,
      domain: yDomainLeft,
    });
    const rightTickValues =
      rightScaleType == "percentage"
        ? [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1].map(
            (x) => x * yDomainLeft[1]
          )
        : null;
    if (!rightTickFormat)
      rightTickFormat = (x) => rightTickFormatter(rightScaleTicks(x));

    const rightAccessors = useMemo(
      () => ({
        x: Object.assign(
          {},
          ...curveKeys.map((x) => ({ [x]: (d) => d[xKey] }))
        ), // take X from a single place
        y:
          rightScaleType == "percentage"
            ? Object.assign(
                {},
                ...curveKeys.map((x) => ({
                  [x]: (d) => (d[x] == null ? null : yDomainLeft[1] * d[x]),
                }))
              )
            : Object.assign(
                {},
                ...curveKeys.map((x) => ({
                  [x]: (d) => rightScaleValues(d[x]),
                }))
              ),
      }),
      [curveKeys]
    );

    return {
      yDomainRight,
      rightNumTicks,
      rightAccessors,
      rightScaleValues,
      rightScaleTicks,
      rightTickValues,
      rightTickFormat,
    };
  };

  const {
    rightNumTicks,
    rightAccessors,
    rightTickValues,
    rightTickFormat,
  } = prepareRightScale();

  return (
    <XYChart
      xScale={xScale}
      yScale={yScaleConfig}
      height={height}
      theme={theme}
      margin={margin}
    >
      <Grid
        key={`grid-${animationTrajectory}`} // force animate on update
        rows={showGridRows}
        columns={showGridColumns}
        lineStyle={{
          stroke: GRID_COLOR,
        }}
      />
      {xAxis && (
        <Axis
          key={`x-axis`}
          label={xLabel}
          orientation={xOrientation}
          numTicks={xNumTicks}
          tickValues={getTickValues()}
          animationTrajectory={animationTrajectory}
          tickFormat={xTickFormatter}
          stroke={GRID_COLOR}
          tickStroke={GRID_COLOR}
        />
      )}
      <Axis
        key={`y-axis-right`}
        label={rightLabel}
        orientation={"right"}
        numTicks={rightNumTicks}
        scale={scaleLinear}
        tickValues={rightTickValues}
        tickFormat={rightTickFormat}
        stroke={GRID_COLOR}
        tickStroke={GRID_COLOR}
      />
      <Axis
        key={`y-axis-left`}
        label={leftLabel}
        orientation={"left"}
        numTicks={leftNumTicks}
        tickFormat={leftTickFormatter}
        stroke={GRID_COLOR}
        tickStroke={GRID_COLOR}
      />
      {Object.keys(leftDataKeys).map((dataKey) => (
        <LineSeries
          key={dataKey}
          dataKey={dataKey}
          data={data}
          xAccessor={leftAccessors.x[dataKey]}
          yAccessor={leftAccessors.y[dataKey]}
          curve={curveTypeSelector()}
          stroke={leftDataKeys[dataKey].stroke}
          strokeDasharray={leftDataKeys[dataKey].strokeDasharray}
          width={leftDataKeys[dataKey].width}
        />
      ))}
      {Object.keys(rightDataKeys).map((dataKey) => (
        <LineSeries
          key={dataKey}
          dataKey={dataKey}
          data={data}
          xAccessor={rightAccessors.x[dataKey]}
          yAccessor={rightAccessors.y[dataKey]}
          curve={curveTypeSelector()}
          stroke={rightDataKeys[dataKey].stroke}
          strokeDasharray={rightDataKeys[dataKey].strokeDasharray}
          width={rightDataKeys[dataKey].width}
        />
      ))}

      {tooltipCallback && (
        <Tooltip
          unstyled={true} // Set this to true so style can be defined by user
          applyPositionStyle={true} // Set this to true so tooltip will be positioned correctly since unstyles is true
          showVerticalCrosshair={showTooltipVerticalCrosshair}
          showHorizontalCrosshair={showTooltipHorizontalCrosshair}
          snapTooltipToDatumX={snapTooltipToDatumX}
          snapTooltipToDatumY={snapTooltipToDatumY}
          showDatumGlyph={showDatumGlyph}
          showSeriesGlyphs={showSeriesGlyphs}
          renderTooltip={({ tooltipData }) => tooltipCallback(tooltipData)}
        />
      )}
    </XYChart>
  );
}
