import React from "react";
import styles from "./legend.scss";
import { scaleOrdinal } from "@visx/scale";
import { LegendOrdinal } from "@visx/legend";
import LegendItemWrapper from "./legendItem";

export default function LegendWrapper(props) {
  const { labels, labelsProps, size, width } = props;
  const labelsScale = scaleOrdinal({
    domain: labels,
    range: labelsProps.map((x) => x.stroke),
  });
  return (
    <LegendOrdinal scale={labelsScale}>
      {(labels) => (
        <div className={styles.items}>
          {labels.map((label, i) => (
            <LegendItemWrapper
              key={`legend-${i}`}
              size={size}
              width={labelsProps[i]?.width ?? width}
              label={{
                text: labelsProps[i]?.displayName ?? label.text,
                value: labelsProps[i]?.stroke,
                name: labelsProps[i]?.name,
                image: labelsProps[i]?.image,
                graphtitle: labelsProps[i]?.graphTitle,
                totalvalue: labelsProps[i]?.totalValue,
              }}
              strokeDasharray={labelsProps[i]?.strokeDasharray}
            />
          ))}
        </div>
      )}
    </LegendOrdinal>
  );
}
