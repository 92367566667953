const getQueryParams = (search) => {
  let params = {};
  const query = search.substring(1).split("&");
  query.map((item) => {
    if (item.indexOf("q=") === 0 || item.indexOf("Q=") === 0) {
      params["q"] = decodeURIComponent(item.slice(2));
    } else {
      const [key, value] = item.split("=");
      params[key] = decodeURIComponent(value);
    }
  });
  return params;
};

export default getQueryParams;
