export const SCREENS = {
  triggerList: "triggerList",
  triggerPresets: "triggerPresets",
  customFilter: "customFilter",
  triggerDefinition: "triggerDefinition",
  requestPreset: "requestPreset",
};

export const EVENT_TYPE_MB_NAME = "event_type";
export const EVENT_GROUP_MB_NAME = "event_group";
export const EVENT_FILTER_VALUE_MB_NAME = "filter_value";
