import React from "react";
import styles from "../sku-analysis-card.scss";
import {
  amountDollarsDecorator,
  decorateValue,
  numberChangeDecorator,
  numberDecorator,
  percentChangeDecorator,
  percentDecorator,
} from "../../../../../styles/cell-decorators";

export default function SkuAnalysisProductData(props) {
  const { kpis, brand, supplier, retailer } = props;
  const productData = {
    Brand: brand,
    Supplier: supplier,
    Retailer: retailer,
  };

  const {
    currentSalesDollars,
    salesDollarsChange,
    currentUnitsSold,
    unitsSoldChange,
    currentUnitShare,
    unitShareChange,
  } = kpis;

  const componentData = {
    "Sales Dollars": {
      current: decorateValue(currentSalesDollars, amountDollarsDecorator()),
      change: decorateValue(salesDollarsChange, numberChangeDecorator),
    },
    Units: {
      current: decorateValue(currentUnitsSold, numberDecorator),
      change: decorateValue(unitsSoldChange, numberChangeDecorator),
    },
    "Units Share": {
      current: decorateValue(currentUnitShare, percentDecorator),
      change: decorateValue(unitShareChange, percentChangeDecorator),
    },
  };

  return (
    <div className={styles.productData}>
      <table className={styles.productDataTable}>
        <tbody>
          {Object.keys(productData).map((x) => (
            <tr className={styles.productDataRow} key={x}>
              <td>{x}</td>
              <td className={styles.productDataValue}>{productData[x]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <table className={styles.productDataTable}>
        <tbody>
          {Object.keys(componentData).map((x) => (
            <tr className={styles.productDataRow} key={x}>
              <td className={styles.alignLeft}>{x}</td>
              <td className={`${styles.alignRight} ${styles.productDataValue}`}>
                {componentData[x].current}
              </td>
              <td className={`${styles.alignRight} ${styles.productDataValue}`}>
                {componentData[x].change}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
