import {
  SET_SELECTED_COMPETITORS,
  SET_SELECTED_TIME_FREQUENCY,
  SET_SELECTED_DATE_RANGE,
  SET_MAX_DATE,
  SET_CHOSEN_PERIOD,
  SET_COMPARE_PERIOD,
  SET_PREVIOUS_SELECTED_DATE_RANGE,
} from "./constants";

export const setSelectedCompetitors = (data) => ({
  type: SET_SELECTED_COMPETITORS,
  payload: { data },
});

export const setSelectedTimeFrequency = (data) => ({
  type: SET_SELECTED_TIME_FREQUENCY,
  payload: { data },
});

export const setSelectedDateRange = (data) => ({
  type: SET_SELECTED_DATE_RANGE,
  payload: { data },
});

export const setMaxDate = (data) => ({
  type: SET_MAX_DATE,
  payload: { data },
});

export const setChosenPeriod = (data) => ({
  type: SET_CHOSEN_PERIOD,
  payload: { data },
});

export const setComparePeriod = (data) => ({
  type: SET_COMPARE_PERIOD,
  payload: { data },
});

export const setPreviousSelectedDateRange = (data) => ({
  type: SET_PREVIOUS_SELECTED_DATE_RANGE,
  payload: { data },
});
