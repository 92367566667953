import React from "react";
import { removeEmptyLines } from "../../../../utils/other/utilities";
import { SELECTIONS } from "./index";
import styles from "./styles.scss";
import decoratorsStyles from "../../../../styles/cell-decorators.scss";
import reactStringReplace from "react-string-replace";
import { uniqueId } from "lodash";

const greenTexts = [
  "increased",
  "increases",
  "increase",
  "increasing",
  "growth",
  "growing",
  "grown",
  "improved",
];
const redTexts = [
  "decreased",
  "decreases",
  "decrease",
  "decreasing",
  "dropped",
  "declining",
  "declined",
  "declines",
  "decline",
  "dropped",
];
const numberRegex = [
  /(\$?(?:0|(?:[1-9](?:\d*|\d{0,2}(?:,\d{3})*)))?(?:\.\d*[0-9])|\d{1,2}%?)/,
  "$",
  "%",
];

export const convertDataToClipboardText = (selection, data) => {
  if (selection === SELECTIONS.fullInsights) {
    return Object.values(data).join("\n");
  }

  // in case of summary return data as it is
  return removeEmptyLines(data);
};

export const convertDataToRenderedText = (selection, data, marketShareData) => {
  let convertedString = data;

  if (selection === SELECTIONS.summary) {
    convertedString = removeEmptyLines(data);
    convertedString = placeSeparatorsBetweenElements(
      convertedString.split("\n")
    );
    convertedString = replaceMarketShareDataInText(
      convertedString,
      marketShareData
    );
    convertedString = commonReplacements(convertedString);
  } else if (selection === SELECTIONS.fullInsights) {
    convertedString = Object.keys(data).map((x) => {
      var text = replaceMarketShareDataInText(data[x], marketShareData);
      text = commonReplacements(text);
      return replaceValuesInTextByFunction(text, [x], boldText);
    });
    convertedString = placeSeparatorsBetweenElements(convertedString);
  }

  return convertedString;
};

export const placeSeparatorsBetweenElements = (text) => {
  return text.reduce((res, item) => {
    if (!res) {
      return [item];
    }

    return [
      ...res,
      <div className={styles.separator} key={uniqueId()} />,
      item,
    ];
  });
};

const replaceMarketShareDataInText = (text, marketShareData) => {
  let names = [];
  for (let sku of marketShareData) {
    names.unshift(sku.skuData?.name); // Giving skus names priorities over brands/top sellers
    names.push(sku.skuData?.brand);
    names.push(sku.skuData?.topSeller);
  }
  names = [...new Set(names)]
    .filter((x) => x != null)
    .sort((a, b) => b.length - a.length); // Remove dups, nulls and sort by string descending to allow longer strings advantage

  for (let name of names) {
    text = replaceValuesInTextByFunction(text, [name], boldText);
  }
  return text;
};

export const commonReplacements = (text) => {
  text = replaceValuesInTextByFunction(text, greenTexts, greenText);
  text = replaceValuesInTextByFunction(text, redTexts, redText);
  text = replaceValuesInTextByFunction(text, numberRegex, boldText);
  text = replaceValuesInTextByFunction(text, ["\n"], breakText);
  return text;
};

const replaceValuesInTextByFunction = (text, values, fn) => {
  for (let value of values) {
    text = fn(text, value);
  }
  return text;
};

const boldText = (text, value) => {
  return reactStringReplace(text, value, (match, i) => (
    <strong key={uniqueId()}>{match}</strong>
  ));
};

const breakText = (text, value) => {
  return reactStringReplace(text, value, (match, i) => <br key={uniqueId()} />);
};

const greenText = (text, value) => {
  return reactStringReplace(text, value, () => (
    <span className={decoratorsStyles.changePositive} key={uniqueId()}>
      {value}
    </span>
  ));
};

const redText = (text, value) => {
  return reactStringReplace(text, value, () => (
    <span className={decoratorsStyles.changeNegative} key={uniqueId()}>
      {value}
    </span>
  ));
};
