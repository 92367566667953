import React from "react";
import DropdownTitleSelectedItem from "../../../components/dropdown/dropdown-title/dropdown-title-selected-item";
import FilterDropdown from "../dropdowns/filter-dropdown";
import styles from "./bounded-filter-selection.scss";

export default function BoundedFilterSelection(props) {
  const { item, isEnabled = true, onAddFilter } = props;

  const titleElement = (
    <DropdownTitleSelectedItem
      value={`+${item.displayName}`}
      className={styles.header}
    />
  );

  const values = Object.keys(item?.values ?? {}).map((x) => ({
    id: x,
    name: x,
    value: x,
  }));

  if (!isEnabled) {
    return (
      <div className={styles.headerDisabled}>{`+${item.displayName}`}</div>
    );
  }

  return (
    <FilterDropdown
      titleElement={titleElement}
      options={values}
      handleClickItem={(x) => onAddFilter(item, x)}
    />
  );
}
