import React from "react";

function DropdownHeader(props) {
  return (
    <>
      <h6>{props.title}</h6>
    </>
  );
}

export default DropdownHeader;
