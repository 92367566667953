import React, { useState, useRef, useEffect } from "react";
import styles from "./mb-views-manager.scss";
import chevron from "../../images/chevron.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import bookmark from "../../images/bookmark-black-outline.svg";
import bookmarkBlue from "../../images/bookmark-blue.svg";
import alertBell from "../../images/alert-bell.svg";
import alertBellBlue from "../../images/alert-bell-blue.svg";
import deleteView from "../../images/delete-view.svg";

import {
  updateCurrentViewConfigQuery,
  setCurrentViewIsUpdated,
} from "../../containers/insights/actions";

import {
  updateInsightsViewConfig,
  deleteInsightsViewConfig,
  updateFavoriteInsightsViewConfig,
  setCurrentViewConfig,
  saveInsightsViewConfig,
  updateInsightsViewConfig2,
} from "../../containers/insights/insights-data-controls/actions";

import {
  setSelectedDateRange,
  setChosenPeriod,
  setComparePeriod,
  setPreviousSelectedDateRange,
} from "../../mb_components/mb-filter-row/actions";

import SharedFunctions from "../../containers/insights/shared-functions";
import DeleteViewModal from "../../containers/insights/insights-data-controls/components/insights-view-selector/components/delete-view-modal/delete-view-modal";
import SaveAlertModal from "../../containers/insights/insights-data-controls/components/insights-view-selector/components/save-alert-modal/save-alert-modal";
import SaveViewModel from "../../containers/insights/insights-data-controls/components/insights-save-view/components/save-view-modal/save-view-modal";
import { NOTIFICATION_FREQUENCY } from "../../utils/other/constants";
import { CATALOG_TRACKER_VIEW_TYPE_DATA } from "../../containers/catalog-tracker/constants";
import { RANKING_EVENTS_VIEW_TYPE_DATA } from "../../containers/ranking-events/constants";
import { SKU_TRACKER_VIEW_TYPE_DATA } from "../../containers/skus/constants";

const sharedFunctions = new SharedFunctions();

function MbViewsManager(props) {
  // Regular props.
  const {
    apiProvider,
    viewsConfigsList,
    viewTypeData,
    lastAvailableDate,
    currentViewConfig,
    selectedDateRange,
    selectedPreviousDateRange,
    comparePeriod,
    rolling,
  } = props;

  // Dispatch function props
  const {
    updateInsightsViewConfig,
    setSelectedDateRange,
    setChosenPeriod,
    setComparePeriod,
    setPreviousSelectedDateRange,
    setCurrentViewConfig,
    deleteInsightsViewConfig,
    updateFavoriteInsightsViewConfig,
    saveInsightsViewConfig,
    updateInsightsViewConfig2,
  } = props;

  const [viewForDelete, setViewForDelete] = useState(null);
  const [alertForEdit, setAlertForEdit] = useState(null);

  const [isSaveOpen, setIsSaveOpen] = useState(false);
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  const [saveViewName, setSaveViewName] = useState(currentViewConfig?.name);

  const [deleteViewName, setDeleteViewName] = useState("");

  const saveDropdownRef = useRef(null);
  const selectDropdownRef = useRef(null);
  const saveViewRef = useRef(null);

  const onChangeViewName = (value) => {
    setSaveViewName(value);
  };

  const handleClickOutside = (event) => {
    if (
      saveDropdownRef.current &&
      !saveDropdownRef.current.contains(event.target)
    )
      setIsSaveOpen(false);
    if (
      selectDropdownRef.current &&
      !selectDropdownRef.current.contains(event.target)
    )
      setIsSelectOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    if (currentViewConfig?.id) {
      setSaveViewName(`${currentViewConfig?.name} (copy)`);
    } else {
      setSaveViewName(`${currentViewConfig?.name}`);
    }
  }, [currentViewConfig]);

  const showAlertScreensIds = [
    SKU_TRACKER_VIEW_TYPE_DATA.id,
    RANKING_EVENTS_VIEW_TYPE_DATA.id,
    CATALOG_TRACKER_VIEW_TYPE_DATA.id,
  ];
  const shouldShowAlert = showAlertScreensIds.includes(viewTypeData.id);

  const displayedViewName =
    currentViewConfig?.id === null
      ? "Select from saved views"
      : `${currentViewConfig.name}`;
  const displayBold =
    displayedViewName === "Select from saved views" ? false : true;

  const sortedViews = viewsConfigsList?.sort((a, b) =>
    a.name > b.name ? 1 : -1
  );

  const saveViewConfig = () => {
    saveInsightsViewConfig(
      apiProvider,
      saveViewName,
      currentViewConfig.query,
      viewTypeData,
      selectedDateRange.startDate,
      selectedDateRange.endDate,
      rolling,
      selectedPreviousDateRange,
      comparePeriod,
      [],
      NOTIFICATION_FREQUENCY.none
    );
  };

  const onClickSaveView = () => {
    setIsSaveOpen(false);
    if (currentViewConfig.id) {
      updateInsightsViewConfig2(
        apiProvider,
        currentViewConfig,
        selectedDateRange.startDate,
        selectedDateRange.endDate,
        rolling,
        selectedPreviousDateRange,
        comparePeriod
      );
    }
  };

  const handleSaveViewDropdownClick = () => {
    setIsSaveOpen(!isSaveOpen);
  };

  const handleSelectViewDropdownClick = () => {
    setIsSelectOpen(!isSelectOpen);
  };

  const onClickDeleteView = (event, value, name) => {
    event.stopPropagation();
    setIsSelectOpen(false);
    setViewForDelete(value);
    setDeleteViewName(name);
  };

  const deleteViewConfig = (value) => {
    setViewForDelete(null);
    deleteInsightsViewConfig(apiProvider, value);
  };

  const handleChangeFavoriteView = (
    viewConfigIdToAddFavorite = null,
    viewConfigIdToRemoveFavorite = null
  ) => {
    if (viewConfigIdToAddFavorite !== null) {
      updateFavoriteInsightsViewConfig(
        apiProvider,
        viewConfigIdToAddFavorite.id
      );
    }
    if (viewConfigIdToRemoveFavorite !== null) {
      updateFavoriteInsightsViewConfig(
        apiProvider,
        viewConfigIdToRemoveFavorite.id
      );
    }
  };

  const setNotificationForViewConfig = (viewConfigId, alertConfig) => {
    let updatedViewConfig;
    const selectedViewConfig = viewsConfigsList.find(
      (viewConfig) => viewConfig.id === viewConfigId
    );

    if (alertConfig?.emails || alertConfig?.notificationFrequency) {
      updatedViewConfig = {
        ...selectedViewConfig,
        emails: alertConfig.emails,
        notificationFrequency: alertConfig.notificationFrequency || 1,
      };
    } else {
      updatedViewConfig = {
        ...selectedViewConfig,
        emails: null,
        notificationFrequency: NOTIFICATION_FREQUENCY.none,
      };
    }
    updateInsightsViewConfig(apiProvider, updatedViewConfig);
  };

  const onViewSwitch = (viewConfigQuery) => {
    const viewClone = _.cloneDeep(viewConfigQuery);
    sharedFunctions.handleQueryDateConfig(
      viewClone.query,
      viewTypeData.id,
      lastAvailableDate,
      setPreviousSelectedDateRange,
      setComparePeriod,
      setSelectedDateRange,
      setChosenPeriod
    );
    setCurrentViewConfig({
      ...viewClone,
      lastSync: new Date().getTime(),
    });
  };

  const handleSelectView = (value) => {
    onViewSwitch(value);
    setIsSelectOpen(false);
  };

  const onClickFavoriteView = (event, item) => {
    event.stopPropagation();
    var prevFavoriteItem = null;
    for (let i = 0; i < sortedViews.length; i++) {
      if (sortedViews[i].favorite) {
        prevFavoriteItem = sortedViews[i];
        break;
      }
    }

    if (prevFavoriteItem === null) {
      // No previously selected favorite view.
      handleChangeFavoriteView(item, prevFavoriteItem);
    } else if (item.id === prevFavoriteItem.id) {
      // Chose to remove favorite from previously selected view.
      handleChangeFavoriteView(item);
    } else {
      // Chose to replace by a new favorite view.
      handleChangeFavoriteView(item, prevFavoriteItem);
    }
    handleChangeFavoriteView();
  };

  const onClickAlert = (event, value) => {
    event.stopPropagation();
    setIsSelectOpen(false);
    setAlertForEdit(value);
  };

  const onCancelModal = () => {
    setViewForDelete(null);
    setAlertForEdit(null);
  };

  const onClickSaveAsView = () => {
    setIsSaveOpen(false);
    saveViewRef.current.showModal();
  };

  const updateNotificationViewConfig = (viewConfigId, alertConfig) => {
    setNotificationForViewConfig(viewConfigId, alertConfig);
    setAlertForEdit(null);
  };

  const disableNotificationViewConfig = (viewConfigId) => {
    setNotificationForViewConfig(viewConfigId);
    setAlertForEdit(null);
  };

  const selectViewDropdownOptions =
    sortedViews == null || sortedViews.length == 0 ? (
      <div className={styles.noSavedViewsText}>No saved views</div>
    ) : (
      <>
        {sortedViews.map((item, index) => (
          <div className={styles.selectViewOptionWrapper} key={index}>
            <div
              className={styles.selectViewLeftBar}
              onClick={() => handleSelectView(item)}
            >
              {item.name}
            </div>
            <div className={styles.selectViewRightBar}>
              <img
                src={item.favorite ? bookmarkBlue : bookmark}
                onClick={(event) => onClickFavoriteView(event, item)}
              />
              {shouldShowAlert && (
                <img
                  onClick={(event) => onClickAlert(event, item)}
                  src={
                    item.emails == null || item.emails.length == 0
                      ? alertBell
                      : alertBellBlue
                  }
                />
              )}
              <img
                id={"deleteView"}
                src={deleteView}
                onClick={(event) =>
                  onClickDeleteView(event, item.id, item.name)
                }
              />
            </div>
          </div>
        ))}
      </>
    );

  return (
    <>
      <div className={styles.viewsManagerWrapper}>
        <div className={styles.viewsManagerLeftBar} ref={selectDropdownRef}>
          <div
            className={
              !isSelectOpen
                ? `${styles.selectViewDropdown}`
                : `${styles.selectViewDropdown} ${styles.openedDropdown}`
            }
            onClick={handleSelectViewDropdownClick}
          >
            <div
              className={styles.selectViewText}
              style={{ fontWeight: displayBold ? "700" : "400" }}
            >
              {displayedViewName}
            </div>
            <img src={chevron} className={styles.chevron} />
          </div>
          {isSelectOpen && (
            <div className={styles.selectViewDropdownOptions}>
              {selectViewDropdownOptions}
            </div>
          )}
        </div>
        <div className={styles.viewsManagerRight} ref={saveDropdownRef}>
          <div
            className={
              !isSaveOpen
                ? `${styles.saveViewDropdown}`
                : `${styles.saveViewDropdown} ${styles.openedDropdown}`
            }
            onClick={handleSaveViewDropdownClick}
          >
            <span className={styles.saveViewText}></span>
            <img src={chevron} />
          </div>
          {isSaveOpen && (
            <div className={styles.saveDropdownOptions}>
              <div
                className={
                  currentViewConfig?.id
                    ? `${styles.saveOption} ${styles.option}`
                    : `${styles.saveOption} ${styles.option} ${styles.disabledSave}`
                }
                onClick={onClickSaveView}
              >
                Save
              </div>
              <div
                className={`${styles.saveAsOption} ${styles.option}`}
                onClick={onClickSaveAsView}
              >
                Save as
              </div>
            </div>
          )}
        </div>
      </div>
      {viewForDelete && (
        <DeleteViewModal
          viewForDelete={viewForDelete}
          deleteViewConfig={deleteViewConfig}
          onCancelRemoving={onCancelModal}
          deleteViewName={deleteViewName}
        />
      )}

      {alertForEdit && (
        <SaveAlertModal
          alertForEdit={alertForEdit}
          updateNotificationViewConfig={updateNotificationViewConfig}
          disableNotificationViewConfig={disableNotificationViewConfig}
          onCancelEditing={onCancelModal}
          currentViewConfig={currentViewConfig}
          viewTypeData={viewTypeData}
        />
      )}

      <SaveViewModel
        viewName={saveViewName}
        modalWrapperRef={saveViewRef}
        onChangeViewName={onChangeViewName}
        handleClickConfirmButton={saveViewConfig}
        saveViewConfig={saveViewConfig}
      />
    </>
  );
}
export const mapStateToProps = (state) => {
  return {
    currentViewConfig: state.insights.insights.currentViewConfig,
    viewTypeData: state.insights.insights.viewTypeData,
    viewsConfigsList: state.insights.insights.viewsConfigsList,

    //mb-filter-row reducer
    selectedDateRange: state.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.insights.mbFilterRow.comparePeriod,
    rolling: state.insights.mbFilterRow.rolling,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentViewConfigQuery,
      setCurrentViewIsUpdated,
      deleteInsightsViewConfig,
      updateFavoriteInsightsViewConfig,
      setSelectedDateRange,
      setChosenPeriod,
      setComparePeriod,
      setPreviousSelectedDateRange,
      setCurrentViewConfig,
      updateInsightsViewConfig2,
      saveInsightsViewConfig,
      updateInsightsViewConfig,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MbViewsManager);
