const DEFAULT_COMPETITOR_ROOTDOMAIN = "wayfair.com";

export const getDefaultCompetitor = (competitorsOptions) => {
  let defaultCompetitor = competitorsOptions.find(
    (x) => x.rootDomain === DEFAULT_COMPETITOR_ROOTDOMAIN
  );
  if (defaultCompetitor === undefined) {
    let competitorsWithoutAll = competitorsOptions.filter(
      (x) => x.name !== "All"
    );
    defaultCompetitor = competitorsWithoutAll[0];
  }
  return defaultCompetitor;
};

export const getDefaultCompetitorName = (competitorsOptions) => {
  return getDefaultCompetitor(competitorsOptions).name;
};
