import React, { useEffect, useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  DEFAULT_DIMENSION,
  MARKET_SHARE_VIEW_TYPE_DATA,
  PIVOT_NAMES,
} from "../../constants";

import { changeMarketShareSettings } from "../../actions";
import PivotDropdown from "./pivot-dropdown/pivot-dropdown";
import { ROOTDOMAINS } from "../../../../utils/other/constants";
import { analyticsPivotSelected } from "../../../shared/analytics/actions";

function MarketShareDataFilter({
  changeMarketShareSettings,
  productAttributes,
  settings,
  viewDefinitions,
}) {
  const setDefaultDimensionSettings = () => {
    changeMarketShareSettings({
      ...settings,
      dimension: DEFAULT_DIMENSION,
      dimensionPivot: null,
    });
  };

  useEffect(() => {
    if (settings.dimension === undefined) {
      setDefaultDimensionSettings();
    }
  }, [settings]);

  const { dimension, dimensionPivot } = settings;

  useEffect(() => {
    if (dimension !== undefined) {
      analyticsPivotSelected(
        MARKET_SHARE_VIEW_TYPE_DATA.id,
        PIVOT_NAMES[dimension],
        dimensionPivot
      );
    }
  }, [dimension, dimensionPivot]);

  const onPivotSelect = (d, dp) => {
    changeMarketShareSettings({
      dimension: d,
      dimensionPivot: dp,
    });
  };

  const attributes = useMemo(() => {
    if (!productAttributes || !viewDefinitions) return [];

    const allCategoryFilters = productAttributes.filter(
      (x) => x.mbName == "mb_category"
    );
    const selectedCategories = [];
    for (const categoryFilter of allCategoryFilters)
      selectedCategories.push(...categoryFilter.values);

    const retailers = productAttributes.find(
      (x) => x.mbName == "rootdomain.keyword"
    )?.values;

    const attrs = viewDefinitions?.filterDefinitions?.find(
      (x) => x.mbName == "attributes"
    )?.values;

    if (!retailers || !attrs) return [];

    const relevantAttrs = [];
    for (const category of selectedCategories) {
      const catAtrPerRoot = [];
      for (const retailer of retailers) {
        if (attrs[ROOTDOMAINS[retailer]]?.[category]) {
          catAtrPerRoot.push(
            Object.keys(attrs[ROOTDOMAINS[retailer]][category])
          );
        }
      }
      if (catAtrPerRoot.length > 0) {
        relevantAttrs.push(
          ...catAtrPerRoot.reduce((a, b) => a.filter((c) => b.includes(c)))
        );
      }
    }
    relevantAttrs.sort((a, b) => a.localeCompare(b)); // sort alphabetically
    return [...new Set(relevantAttrs)]; // removes duplicates
  }, [viewDefinitions, productAttributes]);

  useEffect(() => {
    if (dimensionPivot && !attributes.includes(dimensionPivot))
      setDefaultDimensionSettings();
  }, [attributes, dimensionPivot]);

  return (
    <PivotDropdown
      selected={{
        main: dimension,
        sub: dimensionPivot,
      }}
      attributes={attributes}
      onSelect={onPivotSelect}
      filterDefinitions={viewDefinitions?.filterDefinitions}
    />
  );
}

export const mapStateToProps = (state) => {
  return {
    viewDefinitions: state.marketShare.insights.insights.viewDefinitions,
    productAttributes:
      state.marketShare.insights.insights.currentViewConfig.query
        .productAttributes,
    settings:
      state.marketShare.insights.insights.currentViewConfig.query.settings,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ changeMarketShareSettings }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketShareDataFilter);
