import React, { useState, forwardRef, useImperativeHandle } from "react";
import modalButtonsHOC from "./hoc/modal-buttons-hoc";
import defaultStyles from "./modal-wrapper.scss";
import { isFunction } from "lodash";

const ModalWrapper = forwardRef((props, ref) => {
  const { modalHeader, modalBody, modalFooter } = props;
  const { handleClickConfirmButton, handleClickCancelButton } = props;
  const { styles, className } = props;

  const useImperative = props.useImperative === false ? false : true;

  const [modalIsVisible, setModalIsVisible] = useState(props.modalIsVisible);

  useImperativeHandle(ref, () => ({
    showModal() {
      setModalIsVisible(true);
    },
  }));

  const closeModal = ({ cancelButton }) => {
    setModalIsVisible(false);
    isFunction(handleClickCancelButton) && handleClickCancelButton();
    !useImperative && cancelButton && handleClickCancelButton();
  };

  const ModalFooter = modalButtonsHOC(modalFooter.component, closeModal);

  return (
    <>
      {(modalIsVisible || !useImperative) && (
        <div
          className={`${className && styles[className]} ${
            styles.modalWrapper
          } ${defaultStyles.modalWrapper}`}
        >
          <div
            className={`${styles.modalContainer} ${defaultStyles.modalContainer}`}
          >
            <div
              className={`${styles.modalHeader} ${defaultStyles.modalHeader}`}
            >
              {modalHeader}
              <div
                className={`${styles.closeIcon} ${defaultStyles.closeIcon}`}
                onClick={() => closeModal({ cancelButton: true })}
              ></div>
            </div>
            <div className={`${styles.modalBody} ${defaultStyles.modalBody}`}>
              {modalBody}
            </div>
            <div
              className={`${styles.modalFooter} ${defaultStyles.modalFooter}`}
            >
              <ModalFooter
                onClickConfirmButton={handleClickConfirmButton}
                onClickCancelButton={handleClickCancelButton}
                {...modalFooter?.props}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default ModalWrapper;
