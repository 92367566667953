import React from "react";
import { isArray } from "lodash";
import style from "./components-container.scss";

// Component Container is responsible for rendering the page structure configuration.
export default function ComponentsContainer(props) {
  const { componentsData, configuration } = props;

  const createComponents = (components, index) => {
    return (
      <div className={style.componentsRow} key={index}>
        {components.map((x, index) => {
          return (
            <div id={x} className={style.componentInRow} key={index}>
              {createComponent(x, index)}
            </div>
          );
        })}
      </div>
    );
  };

  const createComponent = (x, index) => {
    const componentConfig = configuration.components.find((c) => c.name === x);
    const ComponentMapped = componentConfig.component;
    return (
      <ComponentMapped
        {...props}
        id={x}
        componentData={componentsData[componentConfig.name]}
        componentConfig={componentConfig}
        key={index}
      />
    );
  };

  return (
    <>
      {configuration.designPlan.map((x, index) => {
        if (isArray(x)) {
          return createComponents(x, index);
        } else {
          return createComponent(x, index);
        }
      })}
    </>
  );
}
