import React, { Component } from "react";
import { Scrollbar } from "react-scrollbars-custom";

import defaultStyles from "./customScrollbar.scss";
function CustomScrollbar(props) {
  const { styles } = props;
  return (
    <Scrollbar
      noScrollX={true}
      wrapperProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={`${defaultStyles.scrollbarsWrapper} ${
                styles?.scrollbarsWrapper ?? null
              }`}
            />
          );
        },
      }}
      scrollerProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={`${defaultStyles.scroller} ${
                styles?.scroller ?? null
              }`}
            />
          );
        },
      }}
      trackYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={`${defaultStyles.trackVertical} ${
                styles?.trackVertical ?? null
              }`}
            />
          );
        },
      }}
      thumbYProps={{
        renderer: (props) => {
          const { elementRef, ...restProps } = props;
          return (
            <div
              {...restProps}
              ref={elementRef}
              className={`${defaultStyles.thumbVertical} ${
                styles?.thumbVertical ?? null
              }`}
            />
          );
        },
      }}
      {...props}
    />
  );
}

export default CustomScrollbar;
