import React, { useEffect, useState } from "react";
import MetricsColumn from "./metrics-column/metrics-column";
import styles from "./kpi-comparison.scss";
import { fetchSkuComparisonData } from "../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Switch from "../../../../components/switch/switch";
import ComboTooltipComponent from "../../../../components/charts/visx/tooltips/combo-tooltip-component";
import * as Decorators from "../../../../styles/cell-decorators";
import ChartComponentLoader from "../../../../components/dashboard-components/chart-component/chart-component-loader";
import MetricsColumnLoader from "./metrics-column/metrics-column-loader";
import { useRef } from "react";
import {
  PIVOTS,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../constants";
import { analyticsChartInteraction } from "../../../shared/analytics/actions";
import { PRODUCT_360_VIEW_TYPE_DATA } from "../../constants";
import { CHART_COMPARE_PERIOD_TOGGLED } from "../../../shared/analytics/constants";
import { BARS_COLORS } from "../../../market-share/constants";
import { dateFormatter } from "../../utils";
import ChartTitle from "../../../../components/chart-title/chart-title";
import ComboChart from "../../../../components/charts/visx/combo-chart";

const METRICS_FIELDS = ["lowest", "highest", "average", "median"];

const GRAPH_PIVOTS = {
  [PIVOTS.gmv]: {
    name: "Sales",
    decorator: Decorators.amountDollarsDecorator("sales"),
    tickFormatter: Decorators.chartTickPriceFormat,
    diffDecorator: null,
    scaleType: "linear",
  },
  [PIVOTS.averageSellingPrice]: {
    name: "Average Selling Price",
    decorator: Decorators.amountDollarsDecorator("asp"),
    tickFormatter: Decorators.chartTickPriceFormat,
    diffDecorator: null,
    scaleType: "linear",
  },
  [PIVOTS.inStockRate]: {
    name: "In-stock rate",
    decorator: Decorators.percentDecorator,
    tickFormatter: Decorators.chartTickPercentFormat,
    diffDecorator: null,
    scaleType: "percentage",
  },
  [PIVOTS.adsSpend]: {
    name: "Ads Spend",
    decorator: Decorators.priceDecorator,
    tickFormatter: Decorators.chartTickPriceFormat,
    diffDecorator: null,
    scaleType: "linear",
  },
};

const KpiComparison = ({
  selectedSku,
  barPivot,
  linePivot,
  forceLoading = false,
  selectedDateRange,
  currentViewConfigQuery,
  selectedPreviousDateRange,
  comparePeriod,
  productAttributes,
}) => {
  const [kpisData, setKpisData] = useState(null);
  const [metricsData, setMetricsData] = useState({});
  const [status, setStatus] = useState(STATUS_LOADING);
  const [showComparison, setShowComparison] = useState(true);
  const isMounted = useRef(true);

  const fetchKpisData = async () => {
    const requestParams = {
      requestBody: {
        insightsViewQuery: {
          ...currentViewConfigQuery,
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          previousStartDate: selectedPreviousDateRange.previousStartDate,
          previousEndDate: selectedPreviousDateRange.previousEndDate,
          comparePeriod: comparePeriod,
        },
        primaryPivot: parseInt(linePivot),
        secondaryPivot: parseInt(barPivot),
        sku: selectedSku,
      },
    };

    try {
      const res = await fetchSkuComparisonData(requestParams);
      if (isMounted.current) {
        setKpisData(res);
        setStatus(STATUS_SUCCESS);
      }
    } catch {
      if (isMounted.current) {
        setStatus(STATUS_ERROR);
      }
    }
  };

  useEffect(() => {
    if (!forceLoading) fetchKpisData();
    return () => {
      isMounted.current = false;
    };
  }, [productAttributes, forceLoading]);

  useEffect(() => {
    if (kpisData?.currentStatistics && kpisData?.previousStatistics) {
      const metrics = METRICS_FIELDS.map((field) => ({
        title: field,
        value: kpisData.currentStatistics[field],
        diff:
          Number(kpisData.previousStatistics[field]) === 0 ||
          Number(kpisData.previousStatistics[field]) === NaN
            ? 0
            : (kpisData.currentStatistics[field] -
                kpisData.previousStatistics[field]) /
              kpisData.previousStatistics[field],
      }));
      if (isMounted.current) setMetricsData(metrics);
    }
  }, [kpisData]);

  const barDataKeys = {
    secondaryPivotPreviousValue: {
      label: `Previous ${GRAPH_PIVOTS[barPivot].name}`,
      stroke: BARS_COLORS.previous,
      decorator: GRAPH_PIVOTS[barPivot].decorator,
      width: 16,
    },
    secondaryPivotCurrentValue: {
      label: `Current ${GRAPH_PIVOTS[barPivot].name}`,
      stroke: BARS_COLORS.current,
      decorator: GRAPH_PIVOTS[barPivot].decorator,
      width: 16,
    },
  };

  const lineDataKeys = {
    primaryPivotCurrentValue: {
      label: `Current ${GRAPH_PIVOTS[linePivot].name}`,
      stroke: "rgba(14, 37, 72, 1)",
      decorator: GRAPH_PIVOTS[linePivot].decorator,
      width: 2,
    },
    primaryPivotPreviousValue: {
      label: `Previous ${GRAPH_PIVOTS[linePivot].name}`,
      stroke: "rgba(14, 37, 72, 1)",
      strokeDasharray: "3 6",
      decorator: GRAPH_PIVOTS[linePivot].decorator,
      width: 2,
    },
  };

  if (!showComparison) {
    delete barDataKeys.secondaryPivotPreviousValue;
    delete lineDataKeys.primaryPivotPreviousValue;
  }

  const dataKeys = { ...barDataKeys, ...lineDataKeys };
  const xKey = "currentDate";

  const tooltipCallback = () => {
    return ComboTooltipComponent({
      section: "",
      entities: dataKeys,
      keyFormatter: (x) => dataKeys[x].label,
      xKey: xKey,
    });
  };

  const ComparisonContainer = ({ children }) => {
    const chartName = `Comparing ${GRAPH_PIVOTS[barPivot].name} to ${GRAPH_PIVOTS[linePivot].name}`;

    const toggleComparison = () => {
      setShowComparison(!showComparison);
      analyticsChartInteraction(
        PRODUCT_360_VIEW_TYPE_DATA.id,
        chartName,
        CHART_COMPARE_PERIOD_TOGGLED,
        !showComparison
      );
    };

    return (
      <div className={styles.kpiComparisonWrapper}>
        <div className={styles.kpisComparisonHeader}>
          <ChartTitle>{chartName}</ChartTitle>
          <div>
            <Switch
              toggled={showComparison}
              onChange={toggleComparison}
              titleRight={"Show comparison period"}
            />
          </div>
        </div>
        <div className={styles.kpiComparisonBodyWrapper}>{children}</div>
      </div>
    );
  };

  return (
    <ComparisonContainer>
      <div className={styles.metricsColumnContainer}>
        {status == STATUS_LOADING ? (
          <MetricsColumnLoader fields={METRICS_FIELDS} />
        ) : status == STATUS_SUCCESS ? (
          <>
            <MetricsColumn
              data={metricsData}
              valueDecorator={GRAPH_PIVOTS[linePivot].decorator}
              diffDecorator={Decorators.percentChangeDecorator}
              title={`${GRAPH_PIVOTS[linePivot].name} KPIs`}
            />
          </>
        ) : (
          "An error occurred"
        )}
      </div>
      <div className={styles.comparisonChartContainer}>
        {status == STATUS_LOADING ? (
          <div className={styles.chartSkeletonWrapper}>
            <ChartComponentLoader />
          </div>
        ) : status == STATUS_SUCCESS ? (
          <ComboChart
            barData={kpisData?.data ?? []}
            lineData={kpisData?.data ?? []}
            barDataKeys={barDataKeys}
            lineDataKeys={lineDataKeys}
            xKey={xKey}
            barLabelFormatter={GRAPH_PIVOTS[barPivot].decorator}
            lineTickFormatter={GRAPH_PIVOTS[linePivot].tickFormatter}
            xTickFormatter={dateFormatter}
            baseStyles={styles}
            tooltipCallback={tooltipCallback()}
          />
        ) : (
          "An error occurred"
        )}
      </div>
    </ComparisonContainer>
  );
};

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,

    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KpiComparison);
