const getCurrentSkusField = (
  activeCategory = false,
  level1ActiveCategory = false
) => {
  if (level1ActiveCategory) {
    return "topCategoryLevel2ProductList";
  }

  if (activeCategory) {
    return "topCategoryLevel1ProductList";
  }

  return "productList";
};

export default getCurrentSkusField;
