import { extendMoment } from "moment-range";
import Moment from "moment";
const moment = extendMoment(Moment);

export const saveCsvToLocalFile = (csvData, name) => {
  const fileName = `Bright Insights ${name} ${moment().format(
    "MM-DD-YYYY"
  )} at ${moment().format("HH_mm_ss")}.csv`;
  const encodedUri = encodeURIComponent(csvData);
  const link = document.createElement("a");
  link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
};
