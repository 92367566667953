import React from "react";
import ItemTooltip from "../../../../components/item-tooltip/item-tooltip";
import { TABLE_BATCH_SELECT_AMOUNT } from "../../constants";

const SelectAllHeader = ({
  selectedKeys,
  setSelectedKeys,
  batchSelectAmount = TABLE_BATCH_SELECT_AMOUNT,
}) => {
  return (
    <>
      <ItemTooltip
        tooltipName={`selectAll`}
        tooltipData={
          selectedKeys.length > 0
            ? { headline: "Deselect all segments" }
            : { headline: `Select top ${batchSelectAmount} segments` }
        }
      >
        <input
          type="checkbox"
          checked={selectedKeys.length > 0}
          onChange={(e) =>
            selectedKeys.length > 0
              ? setSelectedKeys([])
              : setSelectedKeys([...Array(batchSelectAmount).keys()])
          }
        />
      </ItemTooltip>
    </>
  );
};

export default SelectAllHeader;
