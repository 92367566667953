const path = "marketShare";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;

export const FETCH_MARKET_SHARE_CHART_DATA_REQUEST = `${path}/FETCH_MARKET_SHARE_CHART_DATA_REQUEST`;
export const FETCH_MARKET_SHARE_CHART_DATA_SUCCESS = `${path}/FETCH_MARKET_SHARE_CHART_DATA_SUCCESS`;
export const FETCH_MARKET_SHARE_CHART_DATA_FAILURE = `${path}/FETCH_MARKET_SHARE_CHART_DATA_FAILURE`;
export const FETCH_MARKET_SHARE_CHART_DATA_CANCEL = `${path}/FETCH_MARKET_SHARE_CHART_DATA_CANCEL`;

export const BEFORE_FETCH_MARKET_SHARE_DATA = `${path}/BEFORE_FETCH_MARKET_SHARE_DATA`;
export const FETCH_MARKET_SHARE_TABLE_DATA_REQUEST = `${path}/FETCH_MARKET_SHARE_TABLE_DATA_REQUEST`;
export const FETCH_MARKET_SHARE_TABLE_DATA_PARTIAL = `${path}/FETCH_MARKET_SHARE_TABLE_DATA_PARTIAL`;
export const FETCH_MARKET_SHARE_TABLE_DATA_SUCCESS = `${path}/FETCH_MARKET_SHARE_TABLE_DATA_SUCCESS`;
export const FETCH_MARKET_SHARE_TABLE_DATA_FAILURE = `${path}/FETCH_MARKET_SHARE_TABLE_DATA_FAILURE`;
export const FETCH_MARKET_SHARE_TABLE_DATA_CANCEL = `${path}/FETCH_MARKET_SHARE_TABLE_DATA_CANCEL`;

export const FETCH_MORE_MARKET_SHARE_TABLE_DATA_REQUEST = `${path}/FETCH_MORE_MARKET_SHARE_TABLE_DATA_REQUEST`;
export const FETCH_MORE_MARKET_SHARE_TABLE_DATA_SUCCESS = `${path}/FETCH_MORE_MARKET_SHARE_TABLE_DATA_SUCCESS`;
export const FETCH_MORE_MARKET_SHARE_TABLE_DATA_FAILURE = `${path}/FETCH_MORE_MARKET_SHARE_TABLE_DATA_FAILURE`;
export const FETCH_MORE_MARKET_SHARE_TABLE_DATA_CANCEL = `${path}/FETCH_MORE_MARKET_SHARE_TABLE_DATA_CANCEL`;

export const DATEPICKER_CHANGED = `${path}/DATEPICKER_CHANGED`;

const insightsPath = `${path}/insights-skus`;
export const CHANGE_MARKET_SHARE_TABLE_SORTING = `${insightsPath}/CHANGE_SKUS_SORTING`;
export const CHANGE_MARKET_SHARE_TABLE_OFFSET = `${insightsPath}/CHANGE_SKUS_OFFSET`;
export const SET_CURRENT_VIEW_IS_UPDATED = `${insightsPath}/SET_CURRENT_VIEW_IS_UPDATED`;
export const CHANGE_VIEW_SETTINGS = `${insightsPath}/CHANGE_SKUS_SETTINGS`;
export const CHANGE_SKUS_SELECTED_COLUMNS = `${insightsPath}/CHANGE_SKUS_SELECTED_COLUMNS`;

export const MARKET_SHARE_VIEW_TYPE_DATA = {
  name: "market_share",
  id: 3,
  subspace: "marketShare",
};

export const UNKNOWNS_THRESHOLD_STATUS_CODE = 206;

// NOTICE: When adding a new pivot, make sure its configured in all 3 of the following consts:

export const DIMENSIONS = {
  brands: 0,
  sellers: 1,
  priceRange: 2,
  sku: 3,
  attribute: 4,
  productTypes: 5,
  family: 6,
  rootdomain: 7,
  manufacturer: 8
};

export const PIVOT_NAMES = {
  [DIMENSIONS.brands]: "Brands",
  [DIMENSIONS.sellers]: "Sellers",
  [DIMENSIONS.priceRange]: "Price Range",
  [DIMENSIONS.sku]: "SKUs",
  [DIMENSIONS.family]: "SKUs Family",
  [DIMENSIONS.attribute]: "Attribute",
  [DIMENSIONS.productTypes]: "Product Types",
  [DIMENSIONS.rootdomain]: "Retailer",
  [DIMENSIONS.manufacturer]: "Manufacturer"
};

export const PIVOT_DROPDOWN_ORDER = [
  DIMENSIONS.brands,
  DIMENSIONS.sellers,
  DIMENSIONS.priceRange,
  DIMENSIONS.sku,
  DIMENSIONS.family,
  DIMENSIONS.attribute,
  DIMENSIONS.productTypes,
  DIMENSIONS.rootdomain,
  DIMENSIONS.manufacturer
];

export const NUM_OF_ELEMENTS_IN_CHART = 10;
export const DEFAULT_DIMENSION = 3;
export const MAX_TABLE_RESULTS = 50;
export const TABLE_BATCH_SELECT_AMOUNT = 20;
export const DEFAULT_SELECTED_KEYS = [0, 1, 2, 3, 4];
export const MISSING_ATTRIBUTES_FILLER_KEY = "Other/Undefined";

export const MARKET_SHARE_CHARTS = {
  kpisChart: "kpisChart",
  analysisChart: "analysisChart",
};

// export const INDEX_COLORS = ["#37352F", "#5771F4", "#41CAC2", "#7FB7F5", "#D871D4", "3CC4FF"];
export const INDEX_COLORS = [
  "#5771F4",
  "#D871D4",
  "#41CAC2",
  "#7FB7F5",
  "#3CC4FF",
  "#8F3F3F",
  "#B36B6B",
  "#E6A3A3",
  "#6B8FB3",
  "#A3C1E6",
  "#4DA361",
  "#8FD18F",
  "#D1F0D1",
  "#B38FB3",
  "#E6B3E6",
  "#B3B38F",
  "#E6E6A3",
  "#8F6B4D",
  "#E6C4A3",
  "#A38F6B",
  "#D4A3D8",
  "#A38FB3",
  "#E6D1E6",
  "#FFBD66",
  "#66BDFF",
  "#FF8D66",
  "#66FFBD",
  "#BD66FF",
  "#FF668D",
  "#BDFF66",
  "#8D66FF",
  "#FF66BD",
  "#66FF8D",
  "#FF6666",
  "#66FFFF",
  "#FF66FF",
  "#FFFF66",
  "#6666FF",
  "#66FF66",
  "#FF6666",
  "#66FF66",
  "#6666FF",
  "#66FFFF",
  "#FF66FF",
  "#FFFF66",
  "#6666FF",
  "#66FF66",
  "#FF6666",
  "#66FF66",
];

export const BARS_COLORS = {
  current: "#3D7FFC",
  previous: "#B1CCFF",
};
