import React from "react";
import PropTypes from "prop-types";

import styles from "./traffic-source-list.scss";

import BarItem from "../bar-item";

const TrafficSourceList = ({
  competitorTraffic = [],
  competitorColor = "",
  customerTraffic = [],
  customerColor = "",
  extendedmMode = false,
  competitorName = "",
  customerName = "",
}) => {
  let data = [];

  customerTraffic &&
    competitorTraffic.map((item) => {
      let result = customerTraffic.find(
        (itemCust) => item.source === itemCust.source
      );

      if (result) {
        data.push({
          source: item.source,
          percent: item.percent,
          customerPercent: result.percent,
        });
      }
    });

  const average = data.length !== 0 ? data.length / 2 : 0;

  return (
    <div className={styles.wrapper}>
      <div className={styles.top}>
        <div className={styles.legents}>
          <div className={styles.legentItem}>
            <div className={`${styles.legendLabel} ${styles.pink}`}></div>
            {competitorName}
          </div>
          <div className={styles.legentItem}>
            <div className={`${styles.legendLabel} ${styles.gray}`}></div>
            {customerName}
          </div>
        </div>
      </div>

      <br></br>

      <div
        className={`${styles.wrapper} ${extendedmMode ? styles.extended : ""}`}
      >
        <div className={styles.block}>
          {data.map((item, i) => {
            if (i < average) {
              return (
                <div key={item.source} className={styles.item}>
                  <div className={styles.title}>{item.source}</div>
                  <div className={styles.bar}>
                    {item.percent ? (
                      <BarItem rate={item.percent} bgColor={competitorColor} />
                    ) : null}
                    {item.customerPercent ? (
                      <BarItem
                        rate={item.customerPercent}
                        bgColor={customerColor}
                      />
                    ) : null}
                  </div>
                </div>
              );
            }
          })}
        </div>
        <div className={styles.block}>
          {data.map((item, i) => {
            if (i > average) {
              return (
                <div key={item.source} className={styles.item}>
                  <div className={styles.title}>{item.source}</div>
                  <div className={styles.bar}>
                    {item.percent ? (
                      <BarItem rate={item.percent} bgColor={competitorColor} />
                    ) : null}
                    {item.customerPercent ? (
                      <BarItem
                        rate={item.customerPercent}
                        bgColor={customerColor}
                      />
                    ) : null}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

TrafficSourceList.propTypes = {
  iconUrl: PropTypes.string,
  title: PropTypes.string,
};

export default TrafficSourceList;
