import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./market-share-chart-loader.scss";

export function LegendLoader(props) {
  const { speed = 2 } = props;
  return (
    <div className={styles.loader}>
      <ContentLoader speed={speed} width={106} height={8}>
        <rect width="8" height="8" />
        <rect x="12" y="2" width="94" height="4" />
      </ContentLoader>
    </div>
  );
}
