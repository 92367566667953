import React from "react";
import { SimpleTable } from "../tables";
import CustomizeColumnsDropdown from "../customize-columns-dropdown/customize-columns-dropdown";
import ItemTooltip from "../item-tooltip/item-tooltip";
import defaultStyles from "../tables/tables.scss";

function DataTable(props) {
  const {
    header,
    data,
    onSaveSelectedColumns,
    excludedTableColumns,
    sorting,
    onSelectRow,
    onHoverRow,
    styles,
    prepareDataForTable,
    columnsConfig,
    selectedColumns,
    pinnedColumns = [],
    sortableColumns,
    disableTooltipForColumns,
    isLoading,
  } = props;

  function columnsGenerator(
    columns,
    sortMethod,
    selectedColumns,
    sortableColumns,
    disableTooltipForColumns
  ) {
    return columns
      .map((column) => {
        if (selectedColumns.includes(column.id)) {
          return {
            Header: () => {
              if (column.Header) {
                return <>{column.Header}</>;
              }
              return (
                <>
                  <div
                    className={`${styles.infoTitle} ${defaultStyles.infoTitle}`}
                  >
                    {column.label}
                    {!disableTooltipForColumns.includes(column.id) && (
                      <ItemTooltip
                        tooltipName={`${header}/${column.accessor}`}
                        tooltipData={{
                          text: column.tooltip,
                          headline: column.label,
                        }}
                      />
                    )}
                  </div>
                </>
              );
            },
            cellObject: column,
            accessor: column.accessor,
            Cell: column.cellDecorator,
            colspan: column.colspan,
            loaderWidth: column.loaderWidth,
            sortMethod: (() => {
              if (sortableColumns.includes(column.id)) {
                return () => sortMethod(column.id);
              }
            })(),
          };
        }
      })
      .filter((item) => !!item);
  }

  const prepareColumnsForTable = (
    header,
    onSaveSelectedColumns,
    tableColumns,
    selectedColumns,
    sortableColumns,
    sortMethod,
    frequency,
    hideColumnsDropdown
  ) => {
    var columnsList = [
      {
        Header: header,
        columns: [
          ...columnsGenerator(
            tableColumns,
            sortMethod,
            selectedColumns,
            sortableColumns,
            disableTooltipForColumns
          ),
        ],
      },
    ];

    if (!hideColumnsDropdown) {
      columnsList[0].columns.push({
        Header: () => {
          return (
            <CustomizeColumnsDropdown
              options={tableColumns
                .filter((col) => !pinnedColumns.includes(col.id))
                .map((col) => ({
                  id: col.id,
                  name: col.label,
                }))}
              selectedItems={selectedColumns}
              onSaveSelectedColumns={(cols) =>
                onSaveSelectedColumns([...pinnedColumns, ...cols])
              }
            />
          );
        },
        accessor: "null",
      });
    }
    return columnsList;
  };

  const sortMethod = (value) => {
    props.prepareSortingValues(value);
  };

  const tableData = prepareDataForTable(data);

  const tableColumns = columnsConfig.filter(
    (i) => !excludedTableColumns.includes(i.id)
  );

  const selectedColumnsFromTable =
    selectedColumns.length !== 0
      ? selectedColumns
      : tableColumns.map((x) => x.id);

  const hideColumnsDropdown = props.hideColumnsDropdown ?? false; // Explicitly setting to false and not keeping undefined

  const columns = prepareColumnsForTable(
    header,
    onSaveSelectedColumns,
    tableColumns,
    selectedColumnsFromTable,
    sortableColumns,
    sortMethod,
    data.frequency,
    hideColumnsDropdown
  );

  const sortingColumn = columnsConfig.find(
    (column) => column.id === sorting.sortingColumn
  );

  return (
    <>
      <SimpleTable
        {...props}
        columns={columns}
        data={tableData}
        sorting={{ ...sorting, sortingColumn }}
        styles={styles}
        onSelectRow={onSelectRow}
        onHoverRow={onHoverRow}
        isLoading={isLoading}
      />
    </>
  );
}

export default DataTable;
