import React from "react";
import * as Decorators from "../../../../styles/cell-decorators";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";
import TableComponent from "../../../../components/dashboard-components/table-component/table-component";

export default function CategoryInStockRateTableComponent(props) {
  const title = "In-Stock Rates";

  const columns = [
    {
      id: 1,
      label: "Account Name",
      accessor: "accountName",
      tooltip: "Customer Account",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 2,
      label: "Department",
      accessor: "department",
      tooltip: "Department Name. L1 or L2 in retailers' taxonomy.",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 3,
      label: "Category",
      accessor: "productType",
      tooltip: "Name of product category.",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 4,
      label: "Category In Stock Rate",
      accessor: "categoryInStockRate",
      tooltip:
        "The percent of time that items have been in stock for the selected time period and category",
      cellDecorator: Decorators.roundPercentDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 5,
      label: "Category In Stock Rate Diff",
      accessor: "categoryInStockRateDiff",
      tooltip:
        "Percent change in in-stock rate between the current time period and the previous period listed above.",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 6,
      label: "Account In Stock Rate",
      accessor: "accountInStockRate",
      tooltip:
        "The percent of time that items have been in stock for your account for the selected time period and category.",
      cellDecorator: Decorators.roundPercentDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 7,
      label: "Account In Stock Rate Diff",
      accessor: "accountInStockRateDiff",
      tooltip:
        "Percent change in in-stock rate between the current time period and the previous period listed above",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
  ];

  return (
    <TableComponent
      {...props}
      title={title}
      isEnabled={true}
      columns={columns}
      sortingColumn={columns[0]}
      hideColumnsDropdown={true}
      csvName={title}
    />
  );
}
