import React, { useCallback } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Button from "../../../components/button";
import styles from "./button.scss";
import { useSubspace } from "react-redux-subspace";
import { marketShareAnalysisToggleShowHide } from "../../market-share-analysis/actions";
import { ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA } from "../constants";

function InsightsBoardMarketShareAnalysisButton(props) {
  const mapState = useCallback((state) => state.marketShareAnalysis);
  const subspace = useSubspace(mapState);

  return (
    <Button
      text={"Analysis"}
      active={true}
      imageClass={styles.image}
      selected={props.isShowingMarketShareAnalysis}
      onClick={() => {
        subspace.dispatch(
          props.marketShareAnalysisToggleShowHide(
            ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA.id
          )
        );
      }}
    />
  );
}

export const mapStateToProps = (state) => {
  return {
    isShowingMarketShareAnalysis:
      state.marketShareAnalysis.showMarketShareAnalysis,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ marketShareAnalysisToggleShowHide }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsBoardMarketShareAnalysisButton);
