import React, { useRef } from "react";
import styles from "./filter-item-base.scss";
import trash from "../../../images/trash.svg";

export default function FilterItemBase(props) {
  const {
    filterSelection,
    disposable = true,
    isChild = false,
    controls,
    onDispose,
    filterChildrenSelection,
    filterChildren,
  } = props;

  return (
    <>
      <div className={isChild ? styles.filterChildBase : styles.filterBase}>
        <div className={styles.header}>
          <div className={styles.filterName}>{filterSelection}</div>
          {!isChild && (
            <div className={styles.filterChildrenSelection}>
              {filterChildrenSelection}
            </div>
          )}
          {disposable && (
            <div className={styles.dispose} onClick={onDispose}>
              <img src={trash}></img>
            </div>
          )}
        </div>
        <div className={styles.controls}>{controls}</div>
        {props.children}
      </div>
      {!isChild && (
        <div className={styles.filterChildren}>{filterChildren}</div>
      )}
    </>
  );
}
