import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { TAB_NAMES } from "../../constants";

const TabContentContainer = ({ header, tabsConfig, settings }) => {
  const { selectedTab } = settings;

  const preloadTabs = [TAB_NAMES.skuSales, TAB_NAMES.seoAnalysis];

  // This allows to lazy load the tabs. The default state is the tabs will be pre-loaded
  const [loadedTabs, setLoadedTabs] = useState(
    preloadTabs.map((name) => tabsConfig.find((t) => t.name == name).id)
  );

  useEffect(() => {
    if (!loadedTabs.includes(selectedTab))
      setLoadedTabs([...loadedTabs, selectedTab]);
  }, [selectedTab]);

  return (
    <>
      {header}
      {tabsConfig.map((tab) => (
        <span
          key={tab.id}
          style={tab.id === selectedTab ? {} : { display: "none" }}
        >
          {loadedTabs.includes(tab.id) && tab.component}
        </span>
      ))}
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    settings:
      state.product360.insights.insights.currentViewConfig.query.settings,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TabContentContainer);
