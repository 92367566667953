import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Decorators from "../../../../../styles/cell-decorators";
import styles from "./similar-skus-table.scss";

import _ from "lodash";
import DataTable from "../../../../../components/data-table/data-table";
import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_SUCCESS,
  SIMILAR_SKUS_RESULTS_SIZE,
  SIMILAR_SKUS_TABLE_SIZE,
  CURRENT_SKU_TABLE_ROW_BACKGROUND,
} from "../../../constants";
import {
  fetchSimilarSkusTable,
  changeSimilarSkusTableDataOrder,
  setSelectedSku,
} from "../../../actions";
import { LoaderWidths } from "../../../../../components/tables/simple-table/simple-table";
import { sortTableData } from "../../../utils";
import MbRequestsErrors from "../../../../../mb_components/mb-requests-errors/mb-requests-errors";
import SelectAllHeader from "../../../../market-share/components/market-share-table/select-all-header";

const DEFAULT_SORT_CONFIG = {
  field: 1,
  direction: 0,
};

function SimilarSkusTable(props) {
  const {
    selectedKeys,
    setSelectedKeys,
    selectedSku,
    setSelectedSku,
    familyVariantsData,
    tableData,
    changeSimilarSkusTableDataOrder,
    status,
    forceLoading = false,
    productAttributes,
    selectedCompetitor,
    selectedDateRange,
    currentViewConfigQuery,
    selectedPreviousDateRange,
    comparePeriod,
    fetchSimilarSkusTable,
  } = props;
  const sortableColumns = [1, 2, 3, 4, 5, 6];
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const isMounted = useRef(true);

  const initializedSelectedKeys = useRef(false);
  const onSelectionUpdate = (e, id, checked) => {
    e.preventDefault();
    e.stopPropagation();
    let newSelected;
    if (checked) {
      if (!selectedKeys.includes(id)) {
        newSelected = [...selectedKeys, id];
      }
    } else {
      if (selectedKeys.includes(id)) {
        const i = selectedKeys.indexOf(id);
        newSelected = [...selectedKeys];
        newSelected.splice(i, 1);
      }
    }
    if (newSelected) {
      newSelected.sort((a, b) => {
        return a - b;
      });
      setSelectedKeys(newSelected);
    }
  };

  const columnsConfig = [
    {
      id: -2,
      label: "Selected",
      accessor: "selected",
      cellDecorator: Decorators.checkboxDecorator(
        onSelectionUpdate,
        selectedKeys
      ),
      Header: (
        <SelectAllHeader
          selectedKeys={selectedKeys}
          setSelectedKeys={setSelectedKeys}
          batchSelectAmount={5}
        />
      ),
    },
    {
      id: -1,
      label: "Name",
      accessor: "name",
      cellDecorator: Decorators.familySkuDecorator,
      loaderWidth: LoaderWidths.Large,
    },
    {
      id: 0,
      label: "Variant",
      accessor: "variantAttributes",
      cellDecorator: Decorators.variantDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 1,
      label: "Total Sales Dollars",
      accessor: "currentSkuSalesDollars",
      tooltip: "Estimated retail sales for the selected time period",
      cellDecorator: Decorators.amountDollarsDecorator("gmv"),
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 2,
      label: "Sales Dollars Diff",
      accessor: "skuSalesDollarsChange",
      tooltip:
        "Sales Dollars difference between the current time period and the previous period listed above",
      cellDecorator: Decorators.numberChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 3,
      label: "Total Units",
      accessor: "currentSkuUnitsSold",
      tooltip: "Estimated units sold for the selected time period",
      cellDecorator: Decorators.salesDecorator("sales"),
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 4,
      label: "Units Diff",
      accessor: "skuUnitsSoldChange",
      tooltip:
        "Units sold difference between the current time period and the previous period listed above",
      cellDecorator: Decorators.roundNumberChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 5,
      label: "Average Selling Price",
      accessor: "currentSkuAverageSellingPrice",
      cellDecorator: Decorators.priceDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 6,
      label: "Average Selling Price Diff",
      accessor: "skuAverageSellingPriceChange",
      cellDecorator: Decorators.numberChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 99,
      label: "Link",
      accessor: "url",
      cellDecorator: Decorators.linkDecorator,
      loaderWidth: LoaderWidths.Big,
    },
  ];

  const setDefaultSelectedKeys = () => {
    setSelectedKeys([...Array(5).keys()]);
  };

  useEffect(() => {
    if (tableData?.length > 0) {
      if (!initializedSelectedKeys.current) {
        initializedSelectedKeys.current = true;
        setDefaultSelectedKeys();
      }
    }
  }, [tableData]);

  const fetchData = async () => {
    if (!familyVariantsData.selectedSku) return;

    const requestParams = {
      requestBody: {
        insightsViewQuery: {
          ...currentViewConfigQuery,
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          previousStartDate: selectedPreviousDateRange.previousStartDate,
          previousEndDate: selectedPreviousDateRange.previousEndDate,
          comparePeriod: comparePeriod,
        },
        sku: selectedSku,
        similarSkus:
          familyVariantsData.selectedSku.similarSkus?.split(",") ?? [],
        size: SIMILAR_SKUS_RESULTS_SIZE,
      },
    };

    fetchSimilarSkusTable(requestParams);
  };

  const requestParams = {
    requestBody: {
      insightsViewQuery: {
        ...currentViewConfigQuery,
        previousStartDate: selectedPreviousDateRange.previousStartDate,
        previousEndDate: selectedPreviousDateRange.previousEndDate,
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        comparePeriod: comparePeriod,
      },
      sku: selectedSku,
      size: SIMILAR_SKUS_RESULTS_SIZE,
    },
  };

  const prepareSortingValues = (newSelectedSortingColumn) => {
    if (sortConfig.field === newSelectedSortingColumn) {
      setSortConfig({
        field: sortConfig.field,
        direction: sortConfig.direction ? 0 : 1,
      });
    } else {
      setSortConfig({
        field: newSelectedSortingColumn,
        direction: 0,
      });
    }
  };

  const handleFilterChange = () => {
    // Verify offset is reset to 0
    requestParams.requestBody.insightsViewQuery.offset = 0;
    if (!forceLoading) fetchData(requestParams);
  };

  useEffect(() => {
    handleFilterChange();
  }, [
    forceLoading,
    productAttributes,
    selectedDateRange,
    selectedCompetitor,
    comparePeriod,
    familyVariantsData,
  ]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const sortData = () => {
    const columnConfig = columnsConfig.find((x) => x.id === sortConfig.field);
    if (columnConfig) {
      let sorted = sortTableData(sortConfig, columnConfig, tableData);
      sorted = sorted.slice(0, SIMILAR_SKUS_TABLE_SIZE);
      for (const sortedItem of sorted) {
        if (sortedItem.sku === selectedSku) {
          sortedItem.isCurrentSku = true;
          sortedItem.rowStyle = {
            backgroundColor: CURRENT_SKU_TABLE_ROW_BACKGROUND,
          };
        }
      }
      setDefaultSelectedKeys();
      changeSimilarSkusTableDataOrder(sorted);
    }
  };

  useEffect(() => {
    if (familyVariantsData?.variants?.length > 0) sortData();
  }, [sortConfig]);

  const onRowClick = (rowIndex) => {
    setSelectedSku(tableData[rowIndex].sku);
  };

  return status !== STATUS_ERROR ? (
    <DataTable
      header={"Similar Skus"}
      columnsConfig={columnsConfig}
      sorting={{
        sortingColumn: sortConfig.field,
        selectedSortingDirection: sortConfig.direction,
      }}
      hideColumnsDropdown={true}
      sortableColumns={sortableColumns}
      prepareSortingValues={prepareSortingValues}
      styles={styles}
      data={tableData ?? []}
      selectedColumns={columnsConfig.map((x) => x.id)}
      disableTooltipForColumns={[-1]}
      excludedTableColumns={[]}
      prepareDataForTable={(x) => x}
      onSelectRow={onRowClick}
      isLoading={status !== STATUS_SUCCESS}
    />
  ) : (
    <MbRequestsErrors errorType={"beError"} />
  );
}

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    familyVariantsData: state.product360.product360.familyVariants.data,
    tableData: state.product360.product360.similarSkusTable.data,
    status: state.product360.product360.similarSkusTable.status,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedSku,
      fetchSimilarSkusTable,
      changeSimilarSkusTableDataOrder,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SimilarSkusTable);
