import React from "react";
import styles from "./no-data.scss";
import NoDataImage from "../../../../images/no-data-error-msg.svg";

export default function MarketShareAnalysisNoData(props) {
  return (
    <div className={styles.noData}>
      <img src={NoDataImage} />
      <div>No data available for this analysis</div>
    </div>
  );
}
