import React, { useState, useEffect, useRef } from "react";
import bdLogo from "../../../src/images/bd-white-logo.svg";
import userNavIcon from "../../../src/images/user-nav-icon.svg";
import chevron from "../../../src/images/chevron-nav-icon.svg";
import styles from "./top-nav-bar.scss";
import TopNavDropdown from "./top-nav-dropdown";
import logoutIcon from "../../../src/images/logout-icon.svg";

export default function TopNavBar(props) {
  const { navLinks, userName, clickLogout } = props;

  const [logoutOpen, setLogoutOpen] = useState(false);
  const logoutRef = useRef(null);

  const l1ToUserScreenOptions = {
    insights: [
      {
        name: "Account Performance",
        navLink: navLinks?.filter((p) => p.props.name == "Account Performance"),
      },
    ],
    salesShare: [
      {
        name: "Sales and Market Share",
        navLink: navLinks?.filter(
          (p) => p.props.name == "Sales and Market Share"
        ),
      },
    ],
    eCommTracking: [
      {
        name: "SKU Performance Tracker",
        navLink: navLinks?.filter(
          (p) => p.props.name == "SKU Performance Tracker"
        ),
      },
      {
        name: "Product Page Notifications",
        navLink: navLinks?.filter(
          (p) => p.props.name == "Product Page Notifications"
        ),
      },
      {
        name: "Competitive Retail Tracker",
        navLink: navLinks?.filter(
          (p) => p.props.name == "Competitive Retail Tracker"
        ),
      },
      {
        name: "Brand Performance",
        navLink: navLinks?.filter((p) => p.props.name == "Brand Performance"),
      },
    ],
    administration: [
      {
        name: "Administration",
        navLink: navLinks?.filter((p) => p.props.name == "Administration"),
      },
    ],
  };

  const showL1MenuItem = (l1Name) => {
    return l1ToUserScreenOptions[l1Name]?.some(
      (item) => item.navLink?.length > 0
    );
  };

  const handleToggleLogout = () => {
    setLogoutOpen(!logoutOpen);
  };

  const clickAnywhere = (e) => {
    if (!logoutRef.current.contains(e.target)) setLogoutOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickAnywhere);

    return () => {
      document.removeEventListener("mousedown", clickAnywhere);
    };
  }, []);

  return (
    <div className={styles.navBarWrapper}>
      <div className={styles.innerNavBar}>
        <div className={styles.leftNavBar}>
          <img src={bdLogo} className={styles.navBarLogo} />
          {showL1MenuItem("salesShare") && (
            <TopNavDropdown
              title="Sales and Share"
              options={l1ToUserScreenOptions.salesShare.filter(
                (item) => item?.navLink?.length > 0
              )}
            />
          )}
          {showL1MenuItem("insights") && (
            <TopNavDropdown
              title="Insights"
              options={l1ToUserScreenOptions.insights.filter(
                (item) => item?.navLink?.length > 0
              )}
            />
          )}
          {showL1MenuItem("eCommTracking") && (
            <TopNavDropdown
              title="eComm Tracking"
              options={l1ToUserScreenOptions.eCommTracking.filter(
                (item) => item?.navLink?.length > 0
              )}
            />
          )}
          {showL1MenuItem("administration") && (
            <TopNavDropdown
              title="Administration"
              options={l1ToUserScreenOptions.administration.filter(
                (item) => item?.navLink?.length > 0
              )}
            />
          )}
        </div>
        <div className={styles.rightNavBar} ref={logoutRef}>
          <div className={styles.logoutWrapper} onClick={handleToggleLogout}>
            <img src={userNavIcon} className={styles.userNavIcon} />
            <div className={styles.userNameText}>{userName}</div>
            <img src={chevron} className={styles.chevronNavIcon} />
          </div>
          {logoutOpen && (
            <div className={styles.navLogoutWrapper}>
              <div className={styles.navLogoutPopUp} onClick={clickLogout}>
                <img src={logoutIcon} className={styles.navLogOutOption} />
                <div className={styles.navLogOutOption}>Log out</div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
