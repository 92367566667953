import React from "react";
import TableComponent from "../../../../components/dashboard-components/table-component/table-component";
import * as Decorators from "../../../../styles/cell-decorators";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";

export default function AverageSellingPriceTableComponent(props) {
  const title = "Average Selling Price";

  const columns = [
    {
      id: 1,
      label: "Account Name",
      accessor: "accountName",
      tooltip: "Customer Account",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 2,
      label: "Department",
      accessor: "department",
      tooltip: "Department Name. L1 or L2 in retailers' taxonomy.",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 3,
      label: "Category",
      accessor: "productType",
      tooltip: "Name of product category",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 4,
      label: "Category Avg. Selling Price",
      accessor: "categoryAvgSellingPrice",
      tooltip: "The average selling price for all items in a given category.",
      cellDecorator: Decorators.priceDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 5,
      label: "Category Avg. Selling Price Diff",
      accessor: "categoryAvgSellingPriceDiff",
      tooltip:
        "The average selling price for all items sold in a given category",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 6,
      label: "Account Avg. Selling Price",
      accessor: "accountAvgSellingPrice",
      tooltip:
        "The average selling price for all items sold  in a given category for your account.",
      cellDecorator: Decorators.priceDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 7,
      label: "Account Avg. Selling Price Diff",
      accessor: "accountAvgSellingPriceDiff",
      tooltip:
        "Percent change in your company's average selling price between the current time period and the previous period listed above.",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 8,
      label: "Account Avg. Selling Price Diff vs Category",
      accessor: "accountAvgSellingPriceDiffVsCategory",
      tooltip:
        "Percent difference between your company's selling prices and the overall category's selling prices (negative = your company's selling prices are lower, positive = higher)",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
  ];

  return (
    <TableComponent
      {...props}
      title={title}
      isEnabled={true}
      columns={columns}
      sortingColumn={columns[0]}
      hideColumnsDropdown={true}
      csvName={title}
    />
  );
}
