import {
  ApiService,
  cancelMarketShareData,
  cancelMarketShareInsightsAiSummary,
  cancelMarketShareInsightsAiFullInsights,
} from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();

export default class MarketShareService extends BasicViewService {
  exportInsightsView(data, viewType, requestParams, settings, previousDates) {
    data = {
      ...data,
      previousStartDate: previousDates?.previousStartDate,
      previousEndDate: previousDates?.previousEndDate,
      startDate: requestParams?.startDate,
      endDate: requestParams?.endDate,
    };
    const res = apiService
      .exportViewPOST(`/marketShare/csv`, data, {})
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        console.error(error);
        return Promise.reject(error);
      });
    return res;
  }

  fetchData(requestParams) {
    if (cancelMarketShareData !== undefined) {
      cancelMarketShareData("cancel");
    }
    const { insightViews } = requestParams;

    const res = apiService
      .requestMarketShareDataPOST(`/marketShare/data`, insightViews)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchInsightsAiSummary(requestParams) {
    if (cancelMarketShareInsightsAiSummary !== undefined) {
      cancelMarketShareInsightsAiSummary("cancel");
    }
    const { insightViews } = requestParams;

    const res = apiService
      .requestMarketShareInsightsAiSummaryPOST(
        `/marketShare/ai/summary`,
        insightViews
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchInsightsAiFullInsights(requestParams) {
    if (cancelMarketShareInsightsAiFullInsights !== undefined) {
      cancelMarketShareInsightsAiFullInsights("cancel");
    }
    const { insightViews } = requestParams;

    const res = apiService
      .requestMarketShareInsightsAiFullInsightsPOST(
        `/marketShare/ai/fullInsights`,
        insightViews
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }
}
