import {
  CHANGE_FIELD_LOGIN,
  CHANGE_FIELD_ERROR_STATUS_LOGIN,
  TRY_SUBMIT_LOGIN,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  CHANGE_FIELD_FORGOT_PASS,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_FAILURE,
  CHANGE_FIELD_RESET_PASS,
  CHANGE_FIELD_ERROR_STATUS_RESET_PASS,
  TRY_SUBMIT_RESET_PASS,
  RESET_PASS_REQUEST,
  RESET_PASS_SUCCESS,
  RESET_PASS_FAILURE,
  OPEN_LOGOUT_BLOCK,
  CLOSE_LOGOUT_BLOCK,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "./constants";

const authReducer = (
  state = {
    login: {
      data: {
        user: "",
        password: "",
      },
      isTrySubmit: false,
      validFields: {
        user: false,
        password: false,
      },
      error: "",
      loading: false,
    },
    logout: {
      visible: false,
      error: "",
      loading: false,
    },
    forgotPassword: {
      email: "",
      isTrySubmit: false,
      validEmail: false,
      error: "",
      loading: false,
    },
    resetPassword: {
      data: {
        user: "",
        password: "",
        confirmPassword: "",
      },
      isTrySubmit: false,
      validFields: {
        user: false,
        password: false,
        confirmPassword: false,
      },
      error: "",
      loading: false,
    },
  },
  action
) => {
  switch (action.type) {
    // login
    case CHANGE_FIELD_LOGIN: {
      const { field, value } = action.payload;

      return {
        ...state,
        login: {
          ...state.login,
          data: {
            ...state.login.data,
            [field]: value,
          },
        },
      };
    }

    case CHANGE_FIELD_ERROR_STATUS_LOGIN: {
      const { field, value } = action.payload;

      return {
        ...state,
        login: {
          ...state.login,
          validFields: {
            ...state.login.validFields,
            [field]: value,
          },
        },
      };
    }

    case TRY_SUBMIT_LOGIN: {
      return {
        ...state,
        login: {
          ...state.login,
          isTrySubmit: true,
        },
      };
    }

    case LOGIN_REQUEST: {
      return {
        ...state,
        login: {
          ...state.login,
          loading: true,
        },
      };
    }

    case LOGIN_SUCCESS: {
      return {
        ...state,
        login: {
          data: {
            user: "",
            password: "",
          },
          isTrySubmit: false,
          validFields: {
            user: false,
            password: false,
          },
          error: "",
          loading: false,
        },
      };
    }

    case LOGIN_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        login: {
          ...state.login,
          error,
          loading: false,
        },
      };
    }

    // forgot password
    case CHANGE_FIELD_FORGOT_PASS: {
      const { field, value } = action.payload;

      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          [field]: value,
        },
      };
    }

    case FORGOT_PASS_REQUEST: {
      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          loading: true,
        },
      };
    }

    case FORGOT_PASS_SUCCESS: {
      return {
        ...state,
        forgotPassword: {
          email: "",
          isTrySubmit: false,
          validEmail: false,
          error: "",
          loading: false,
        },
      };
    }

    case FORGOT_PASS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        forgotPassword: {
          ...state.forgotPassword,
          error,
          loading: false,
        },
      };
    }

    // reset password
    case CHANGE_FIELD_RESET_PASS: {
      const { field, value } = action.payload;

      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          data: {
            ...state.resetPassword.data,
            [field]: value,
          },
        },
      };
    }

    case CHANGE_FIELD_ERROR_STATUS_RESET_PASS: {
      const { field, value } = action.payload;

      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          validFields: {
            ...state.resetPassword.validFields,
            [field]: value,
          },
        },
      };
    }

    case TRY_SUBMIT_RESET_PASS: {
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          isTrySubmit: true,
        },
      };
    }

    case RESET_PASS_REQUEST: {
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          loading: true,
        },
      };
    }

    case RESET_PASS_SUCCESS: {
      return {
        ...state,
        resetPassword: {
          data: {
            user: "",
            password: "",
            confirmPassword: "",
          },
          isTrySubmit: false,
          validFields: {
            user: "",
            password: false,
            confirmPassword: false,
          },
          error: "",
          loading: false,
        },
      };
    }

    case RESET_PASS_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          error,
          loading: false,
        },
      };
    }

    // logout
    case OPEN_LOGOUT_BLOCK: {
      return {
        ...state,
        logout: {
          ...state.logout,
          visible: true,
        },
      };
    }

    case CLOSE_LOGOUT_BLOCK: {
      return {
        ...state,
        logout: {
          visible: false,
          error: "",
          loading: false,
        },
      };
    }

    case LOGOUT_REQUEST: {
      return {
        ...state,
        logout: {
          ...state.logout,
          loading: true,
        },
      };
    }

    case LOGOUT_SUCCESS: {
      return {
        ...state,
        logout: {
          visible: false,
          error: "",
          loading: false,
        },
      };
    }

    case LOGOUT_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          error,
          loading: false,
        },
      };
    }
  }
  return state;
};

export default authReducer;
