import React, { Component } from "react";
import { Line } from "react-chartjs-2";

import Dropdown from "../dropdown";

import styles from "./line-chart.scss";

class LineChart extends Component {
  state = {
    options: [
      {
        id: 1,
        name: "Last 7 days",
      },
      {
        id: 2,
        name: "Last 30 days",
      },
      {
        id: 3,
        name: "Last 90 days",
      },
      {
        id: 4,
        name: "All",
      },
    ],
  };

  handleSortChange = (item) => {
    this.props.handleChange(item.name);
  };

  render() {
    const {
      data,
      title,
      selected,
      tooltipCallback,
      yAxesCallback,
    } = this.props;
    const { options } = this.state;
    const { labels } = data;

    data.datasets.map((item) => {
      item.fill = false;
    });
    data.datasets = data.datasets.map((dataset) => {
      return {
        ...dataset,
        lineTension: 0.2,
      };
    });

    let optionsLine = {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              callback: function (value) {
                if (yAxesCallback) {
                  return yAxesCallback(value);
                }
                return value;
              },
            },
            scaleLabel: {
              display: false,
              fontSize: 8,
            },
          },
        ],
      },
      legend: undefined,
      fill: false,
    };

    if (tooltipCallback) {
      optionsLine = {
        ...optionsLine,
        tooltips: {
          callbacks: {
            label: tooltipCallback,
          },
        },
      };
    }

    const chart =
      labels.length !== 0 ? <Line data={data} options={optionsLine} /> : null;
    const empty = !chart ? <div className={styles.empty}></div> : null;

    return (
      <div className={styles.wrapper}>
        <div className={styles.titleCont}>
          <p className={styles.title}>{title}</p>
          <div className={styles.dropdownCont}>
            <Dropdown
              size="small"
              trim={80}
              options={options}
              value={selected}
              handleSelect={this.handleSortChange}
            />
          </div>
        </div>
        {chart}
        {empty}
      </div>
    );
  }
}

export default LineChart;
