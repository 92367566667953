import React from "react";
import styles from "../dropdown2.scss";

function DropdownTitleIcon(props) {
  return (
    <>
      {props.dots && <div className={styles.kebab}></div>}
      {props.customizeColumnIcon && (
        <div className={styles.customizeColumnIcon}></div>
      )}
    </>
  );
}

export default DropdownTitleIcon;
