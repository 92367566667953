import React from "react";
import * as Decorators from "../../../../styles/cell-decorators";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";
import TableComponent from "../../../../components/dashboard-components/table-component/table-component";

export default function CategoryAssortmentTableComponent(props) {
  const title = "Assortment";

  const columns = [
    {
      id: 1,
      label: "Account Name",
      accessor: "accountName",
      tooltip: "Customer Account",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 2,
      label: "Department",
      accessor: "department",
      tooltip:
        "Department Name. Contains a collection of similar product categories.",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 3,
      label: "Category",
      accessor: "productType",
      tooltip: "Name of product category.",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 4,
      label: "Total SKUs",
      accessor: "totalCategorySkus",
      tooltip:
        "Total number of SKU Variants (distinct products) for a selected category and time period that have sold at least once in their entire product lifetime",
      cellDecorator: Decorators.numberDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 5,
      label: "Category Active Selling SKUs",
      accessor: "categoryActiveSellingSkus",
      tooltip:
        "Total number of SKU Variants (distinct products) that have been sold during the selected time period and category",
      cellDecorator: Decorators.numberDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 6,
      label: "Category % Active Selling SKUs",
      accessor: "categoryPercentActiveSellingSkus",
      tooltip:
        "Percent of Active SKU Variants from the Total SKUs (distinct variants) in the category and time period (Category Active Selling SKUs / Total SKUs)",
      cellDecorator: Decorators.roundPercentDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 7,
      label: "Category Active Selling SKUs Diff",
      accessor: "categoryActiveSellingSkusDiff",
      tooltip:
        "Percent change of Active SKU Variants from the Total SKUs (distinct variants) in the category and time period (Category Active Selling SKUs / Total SKUs)",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 8,
      label: "Account Active Selling SKUs",
      accessor: "accountActiveSellingSkus",
      tooltip:
        "Total number of SKU Variants (distinct products) that have sold at least one unit during the selected time period and category for your account",
      cellDecorator: Decorators.numberDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 9,
      label: "Account Active Selling SKUs Diff",
      accessor: "accountActiveSellingSkusDiff",
      tooltip:
        "Percent change in actively selling SKUs (SKU variants) for your company between the current time period and the previous period listed above",
      cellDecorator: Decorators.roundNumberChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
  ];

  return (
    <TableComponent
      {...props}
      title={title}
      isEnabled={true}
      columns={columns}
      sortingColumn={columns[0]}
      hideColumnsDropdown={true}
      csvName={title}
    />
  );
}
