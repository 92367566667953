import React, { useState } from "react";
import styles from "./feedback.scss";

import { analyticsFeatureFeedback } from "../../containers/shared/analytics/actions";

export default function Feedback(props) {
  const { viewTypeId, featureName, featureValue } = props;
  const [selected, setSelected] = useState();

  const selectCallback = (selection) => {
    if (selection !== selected) {
      setSelected(selection);
      analyticsFeatureFeedback(
        viewTypeId,
        featureName,
        featureValue,
        selection
      );
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>Was this helpful?</div>
      <div className={styles.controls}>
        <i
          className={`${styles.notLike} ${selected === false && styles.active}`}
          onClick={() => {
            selectCallback(false);
          }}
        />
        <i
          className={`${styles.like} ${selected === true && styles.active}`}
          onClick={() => {
            selectCallback(true);
          }}
        />
      </div>
    </div>
  );
}
