import React, { useEffect, useState } from "react";
import styles from "./insights-export-view.scss";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownBody,
} from "../../../../../components/dropdown";

const { DropdownTitleSelectedItem } = dropdownTitle;
const { DropdownBodySingleSelect } = dropdownBody;

function InsightsExportsViewDropdown(props) {
  const { selectedItem, handleClickItem, options } = props;

  const dropdownTitle = (
    <DropdownTitleSelectedItem
      preTitle={"Export "}
      value={selectedItem?.name}
      postTitle={" Results"}
    />
  );

  return (
    <>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownBody={{
          component: DropdownBodySingleSelect,
          props: { options, value: selectedItem?.value, radiobutton: true },
          styles,
        }}
        handleClickItem={handleClickItem}
        wrapDropdownBody={true}
        styles={styles}
        className="exportViewSubDropdown"
      />
    </>
  );
}

export default InsightsExportsViewDropdown;
