import React, { useEffect, useState } from "react";
import styles from "./pagination.scss";
import arrowNavigation from "../../images/arrow-navigation.svg";

const Pagination = ({ page = 1, setPage, limit = 999 }) => {
  const minCenter = 2;
  const maxCenter = limit - 1;
  const [center, setCenter] = useState(Math.max(minCenter, page));

  useEffect(() => {
    if (limit <= 3) return;
    if (page >= maxCenter) setCenter(maxCenter);
    else if (page >= minCenter) setCenter(page);
    else setCenter(minCenter);
  }, [page]);

  const changePage = (toPage) => {
    if (toPage >= 1 && toPage <= limit) setPage(toPage);
  };

  const pageOptions =
    limit > 3
      ? [center - 1, center, center + 1]
      : Array.from({ length: limit }, (_, i) => i + 1);

  return (
    <div className={styles.pagination}>
      <div
        className={page > 1 ? styles.navEnabled : styles.navDisabled}
        onClick={() => changePage(page - 1)}
      >
        <img
          width={14}
          height={14}
          src={arrowNavigation}
          style={{ transform: "rotate(180deg)" }}
        />
      </div>
      {pageOptions.map((i) => (
        <div
          key={i}
          className={i === page ? styles.currentPage : styles.page}
          onClick={() => changePage(i)}
        >
          {i}
        </div>
      ))}
      <div
        className={page < limit ? styles.navEnabled : styles.navDisabled}
        onClick={() => changePage(page + 1)}
      >
        <img width={14} height={14} src={arrowNavigation} />
      </div>
    </div>
  );
};

export default Pagination;
