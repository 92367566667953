import axios from "axios";

import { API_URL, API_URL_V2, ERROR_LOG_URL } from "../config";
import utils from "../utils";

let CancelToken = axios.CancelToken;
let cancelCompetitors;
let cancelUser;
let cancelCategories;
let cancelSkus;
let cancelProductTypesDefinitions;
let cancelBrands;
let cancelInsights;
let cancelSkusInsights;
let cancelSaveInsightsViewConfig;
let cancelFetchInsightsViewsConfigs;
let cancelUpdateInsightsViewConfig;
let cancelDeleteInsightsViewConfig;
let cancelFetchInsightsViewsDefinitions;
let cancelFetchInsightsFilterDefinitions;
let cancelExportInsightsView;

let cancelMarketShareData;
let cancelMarketShareAnalysisData;

let cancelMatchedSkus;
let cancelMoreMatchedSkus;
let cancelCatalogTrackerSkuDetails;

let cancelProduct360Data;
let cancelProduct360Promo;
let cancelProduct360SimilarProducts;
let cancelProduct360SearchTerms;

let cancelMarketShareInsightsAiSummary;
let cancelMarketShareInsightsAiFullInsights;

let cancelRankingEventsTableData;
let cancelBrandPerformanceTable;
let cancelBrandPerformanceGraph;
let cancelUsersTableData;
let cancelAllCategories;
let cancelAllCompetitors;
let cancelAllScreens;
let cancelAddNewUser;
let cancelDeleteUser;
let cancelUpdateUser;

let cancelSalesAndShare;
let cancelAverageListedPrice;
let cancelAverageSellingPrice;
let cancelCategoryAssortment;
let cancelCategoryInStockRate;
let cancelCustomerPerformanceSummary;
let cancelCategoriesPerformanceSummary;
let cancelCustomerTotalRevenueGraph;
let cancelCategoriesTotalRevenueGraph;
let cancelMarketShareGraph;
let cancelCustomerPricingGraph;
let cancelCategoriesPricingGraph;
let cancelCustomerInStockRatesGraph;
let cancelCategoriesInStockRatesGraph;
let cancelCustomerActiveSellingSkusGraph;
let cancelCategoriesActiveSellingSkusGraph;

let cancelExportSalesAndShare;
let cancelExportAverageListedPrice;
let cancelExportAverageSellingPrice;
let cancelExportCategoryAssortment;
let cancelExportCategoryInStockRate;

let cancelErrorLog;

const { handleServerError } = utils.other;

class ApiService {
  requestGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelSkus = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestUserGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelUser = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCompetitorsGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelCompetitors = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoriesGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelCategories = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestBrandsGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelBrands = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelInsights = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsSkusPOST(url, data) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelSkusInsights = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsViewConfigPOST(url, data) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelSaveInsightsViewConfig = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsViewConfigGET(url) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelFetchInsightsViewsConfigs = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsViewConfigPUT(url, data) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "PUT",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelUpdateInsightsViewConfig = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsViewConfigUpdatePOST(url, data) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelUpdateInsightsViewConfig = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsViewConfigDELETE(url) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "DELETE",
        cancelToken: new CancelToken(function executor(c) {
          cancelDeleteInsightsViewConfig = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsViewDefinitionsGET(url, headersData) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "GET",
        headers: { ...headersData, ...axios.defaults.headers },
        cancelToken: new CancelToken(function executor(c) {
          cancelFetchInsightsViewsDefinitions = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestInsightsFilterDefinitionsGET(url, headersData) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "GET",
        headers: { ...headersData, ...axios.defaults.headers },
        cancelToken: new CancelToken(function executor(c) {
          cancelFetchInsightsFilterDefinitions = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  exportInsightsViewPOST(url, data) {
    return axios
      .request({
        url: `${API_URL_V2}${url}`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelExportInsightsView = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  exportViewPOST(url, data, headersData) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "POST",
        data: data,
        headers: headersData,
        cancelToken: new CancelToken(function executor(c) {
          cancelExportInsightsView = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  // Market Share

  requestMarketShareDataPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelMarketShareData = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestMarketShareAnalysisDataPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelMarketShareAnalysisData = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestMatchedSkusPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelMatchedSkus = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCatalogTrackerSkuDetailsPost(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelCatalogTrackerSkuDetails = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestProduct360DataPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelProduct360Data = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestProduct360PromoDataPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelProduct360Promo = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestProduct360SimilarProductsTablePOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelProduct360SimilarProducts = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestProduct360SimilarProductsGraphPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestProduct360SearchTermsPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelProduct360SearchTerms = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestMarketShareInsightsAiSummaryPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,

        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelMarketShareInsightsAiSummary = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestMarketShareInsightsAiFullInsightsPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelMarketShareInsightsAiFullInsights = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestRankingEventsDataPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelRankingEventsTableData = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  // Brand Performance
  requestBrandPerformanceTablePOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelBrandPerformanceTable = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestBrandPerformanceGraphPOST(url, data) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        data: data,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelBrandPerformanceGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  // Administration/Users
  requestDeleteUserPOST(url, headersData) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        headers: headersData,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelDeleteUser = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestAddNewUserPOST(url, headersData) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        headers: headersData,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelAddNewUser = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestUpdateUserPOST(url, headersData) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        headers: headersData,
        method: "POST",
        cancelToken: new CancelToken(function executor(c) {
          cancelAddNewUser = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestUsersTableDataGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelUsersTableData = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestAllScreensGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelAllScreens = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestAllCompetitorsGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelAllCompetitors = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestAllCategoriesGET(url) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "GET",
        cancelToken: new CancelToken(function executor(c) {
          cancelAllCategories = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  // Insights Board

  requestFetchSalesAndSharePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/salesAndShare/table`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelSalesAndShare = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestAverageListedPricePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/pricing/listed/table`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelAverageListedPrice = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestAverageSellingPricePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/pricing/selling/table`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelAverageSellingPrice = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoryAssortmentPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/categoryAssortment/table`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCategoryAssortment = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoryInStockRatePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/inStockRate/table`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCategoryInStockRate = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCustomerPerformanceSummaryPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/customerPerformanceSummary`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCustomerPerformanceSummary = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoriesPerformanceSummaryPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/${data.viewType}/categoriesPerformanceSummary`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCategoriesPerformanceSummary = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoriesTotalRevenuePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/categories/revenue/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCategoriesTotalRevenueGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCustomerTotalRevenuePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/customer/revenue/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCustomerTotalRevenueGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestMarketSharePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/marketShare/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelMarketShareGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCustomerPricingPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/customer/pricing/selling/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCustomerPricingGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoriesPricingPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/categories/pricing/selling/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCategoriesPricingGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCustomerInStockRatesPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/customer/InStockRates/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCustomerInStockRatesGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCustomerActiveSellingSkusPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/customer/assortment/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCustomerActiveSellingSkusGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoriesActiveSellingSkusPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/categories/assortment/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCategoriesActiveSellingSkusGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestCategoriesInStockRatesPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/categories/InStockRates/graph`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelCategoriesInStockRatesGraph = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  exportAverageListedPricePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/pricing/listed/csv`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelExportAverageListedPrice = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  exportAverageSellingPricePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/pricing/selling/csv`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelExportAverageSellingPrice = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  exportCategoryAssortmentPOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/categoryAssortment/csv`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelExportCategoryAssortment = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  exportCategoryInStockRatePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/instockRate/csv`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelExportCategoryInStockRate = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  exportSalesAndSharePOST(data) {
    return axios
      .request({
        url: `${API_URL}/insightsBoard/salesAndShare/csv`,
        method: "POST",
        data: data,
        cancelToken: new CancelToken(function executor(c) {
          cancelExportSalesAndShare = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  // General

  requestPOST(url, data = {}) {
    return axios
      .request({
        url: `${API_URL}${url}`,
        method: "POST",
        data,
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestErrorLogPOST(url, data) {
    return axios
      .request({
        url: `${ERROR_LOG_URL}${url}`,
        method: "POST",
        headers: { "Content-Type": "application/json" },
        data,
        cancelToken: new CancelToken(function executor(c) {
          cancelErrorLog = c;
        }),
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  cancelAllRequested() {
    if (cancelUser !== undefined) {
      cancelUser("cancel");
    }
    if (cancelCompetitors !== undefined) {
      cancelCompetitors("cancel");
    }
    if (cancelCategories !== undefined) {
      cancelCategories("cancel");
    }
    if (cancelSkus !== undefined) {
      cancelSkus("cancel");
    }
    if (cancelBrands !== undefined) {
      cancelBrands("cancel");
    }
    if (cancelInsights !== undefined) {
      cancelInsights("cancel");
    }
    if (cancelSkusInsights !== undefined) {
      cancelSkusInsights("cancel");
    }
    if (cancelProductTypesDefinitions !== undefined) {
      cancelProductTypesDefinitions("cancel");
    }
    if (cancelMarketShareData !== undefined) {
      cancelMarketShareData("cancel");
    }
    if (cancelMarketShareAnalysisData !== undefined) {
      cancelMarketShareAnalysisData("cancel");
    }
    if (cancelProduct360Data !== undefined) {
      cancelProduct360Data("cancel");
    }
    if (cancelProduct360Promo !== undefined) {
      cancelProduct360Promo("cancel");
    }
    if (cancelProduct360SimilarProducts !== undefined) {
      cancelProduct360SimilarProducts("cancel");
    }
    if (cancelProduct360SearchTerms !== undefined) {
      cancelProduct360SearchTerms("cancel");
    }
    if (cancelMarketShareInsightsAiSummary !== undefined) {
      cancelMarketShareInsightsAiSummary("cancel");
    }
    if (cancelMarketShareInsightsAiFullInsights !== undefined) {
      cancelMarketShareInsightsAiFullInsights("cancel");
    }
    if (cancelRankingEventsTableData !== undefined) {
      cancelRankingEventsTableData("cancel");
    }
    if (cancelBrandPerformanceTable !== undefined) {
      cancelBrandPerformanceTable("cancel");
    }
    if (cancelBrandPerformanceGraph !== undefined) {
      cancelBrandPerformanceGraph("cancel");
    }
    if (cancelAddNewUser !== undefined) {
      cancelAddNewUser("cancel");
    }
    if (cancelDeleteUser !== undefined) {
      cancelDeleteUser("cancel");
    }
    if (cancelUpdateUser !== undefined) {
      cancelUpdateUser("cancel");
    }
    if (cancelUsersTableData !== undefined) {
      cancelUsersTableData("cancel");
    }
    if (cancelAllCategories !== undefined) {
      cancelAllCategories("cancel");
    }
    if (cancelAllScreens !== undefined) {
      cancelAllScreens("cancel");
    }
    if (cancelAllCompetitors !== undefined) {
      cancelAllCompetitors("cancel");
    }
    if (cancelSalesAndShare !== undefined) {
      cancelSalesAndShare("cancel");
    }
    if (cancelAverageListedPrice !== undefined) {
      cancelAverageListedPrice("cancel");
    }
    if (cancelAverageSellingPrice !== undefined) {
      cancelAverageSellingPrice("cancel");
    }
    if (cancelCategoryAssortment !== undefined) {
      cancelCategoryAssortment("cancel");
    }
    if (cancelCategoryInStockRate !== undefined) {
      cancelCategoryInStockRate("cancel");
    }
    if (cancelCustomerPerformanceSummary !== undefined) {
      cancelCustomerPerformanceSummary("cancel");
    }
    if (cancelCategoriesPerformanceSummary !== undefined) {
      cancelCategoriesPerformanceSummary("cancel");
    }
    if (cancelCategoriesTotalRevenueGraph !== undefined) {
      cancelCategoriesTotalRevenueGraph("cancel");
    }
    if (cancelCustomerTotalRevenueGraph !== undefined) {
      cancelCustomerTotalRevenueGraph("cancel");
    }
    if (cancelMarketShareGraph !== undefined) {
      cancelMarketShareGraph("cancel");
    }
    if (cancelCustomerPricingGraph !== undefined) {
      cancelCustomerPricingGraph("cancel");
    }
    if (cancelCategoriesPricingGraph !== undefined) {
      cancelCategoriesPricingGraph("cancel");
    }
    if (cancelCustomerInStockRatesGraph !== undefined) {
      cancelCustomerInStockRatesGraph("cancel");
    }
    if (cancelCategoriesInStockRatesGraph !== undefined) {
      cancelCategoriesInStockRatesGraph("cancel");
    }
    if (cancelCustomerActiveSellingSkusGraph !== undefined) {
      cancelCustomerActiveSellingSkusGraph("cancel");
    }
    if (cancelCategoriesActiveSellingSkusGraph !== undefined) {
      cancelCategoriesActiveSellingSkusGraph("cancel");
    }
    if (cancelExportSalesAndShare !== undefined) {
      cancelExportSalesAndShare("cancel");
    }
    if (cancelExportAverageListedPrice !== undefined) {
      cancelExportAverageListedPrice("cancel");
    }
    if (cancelExportAverageSellingPrice !== undefined) {
      cancelExportAverageSellingPrice("cancel");
    }
    if (cancelExportCategoryAssortment !== undefined) {
      cancelExportCategoryAssortment("cancel");
    }
    if (cancelExportCategoryInStockRate !== undefined) {
      cancelExportCategoryInStockRate("cancel");
    }
    if (cancelErrorLog !== undefined) {
      cancelErrorLog("cancel");
    }
    if (cancelMatchedSkus !== undefined) {
      cancelMatchedSkus("cancel");
    }
    if (cancelMoreMatchedSkus !== undefined) {
      cancelMoreMatchedSkus("cancel");
    }
    if (cancelCatalogTrackerSkuDetails !== undefined) {
      cancelCatalogTrackerSkuDetails("cancel");
    }
  }
}

export {
  ApiService,
  cancelUser,
  cancelCompetitors,
  cancelCategories,
  cancelInsights,
  cancelSkus,
  cancelProductTypesDefinitions,
  cancelBrands,
  cancelSkusInsights,
  cancelSaveInsightsViewConfig,
  cancelFetchInsightsViewsConfigs,
  cancelUpdateInsightsViewConfig,
  cancelDeleteInsightsViewConfig,
  cancelFetchInsightsViewsDefinitions,
  cancelFetchInsightsFilterDefinitions,
  cancelExportInsightsView,
  cancelMarketShareData,
  cancelMarketShareAnalysisData,
  cancelProduct360Data,
  cancelProduct360Promo,
  cancelProduct360SimilarProducts,
  cancelProduct360SearchTerms,
  cancelMarketShareInsightsAiSummary,
  cancelMarketShareInsightsAiFullInsights,
  cancelRankingEventsTableData,
  cancelBrandPerformanceTable,
  cancelBrandPerformanceGraph,
  cancelAddNewUser,
  cancelDeleteUser,
  cancelUpdateUser,
  cancelUsersTableData,
  cancelAllCategories,
  cancelAllCompetitors,
  cancelAllScreens,
  cancelSalesAndShare,
  cancelAverageListedPrice,
  cancelAverageSellingPrice,
  cancelCategoryAssortment,
  cancelCategoryInStockRate,
  cancelCustomerPerformanceSummary,
  cancelCategoriesPerformanceSummary,
  cancelCustomerTotalRevenueGraph,
  cancelCategoriesTotalRevenueGraph,
  cancelMarketShareGraph,
  cancelCustomerPricingGraph,
  cancelCategoriesPricingGraph,
  cancelCustomerInStockRatesGraph,
  cancelCategoriesInStockRatesGraph,
  cancelCustomerActiveSellingSkusGraph,
  cancelCategoriesActiveSellingSkusGraph,
  cancelExportSalesAndShare,
  cancelExportAverageListedPrice,
  cancelExportAverageSellingPrice,
  cancelExportCategoryAssortment,
  cancelExportCategoryInStockRate,
  cancelErrorLog,
  cancelMatchedSkus,
  cancelMoreMatchedSkus,
  cancelCatalogTrackerSkuDetails,
};
