import {
  APPLY_INITIAL_STATE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
  MARKET_SHARE_ANALYSIS_CHANGE_GROUP_BY,
  MARKET_SHARE_ANALYSIS_TOGGLE_SHOW_HIDE,
  MARKET_SHARE_ANALYSIS_SET_SUBJECT_NAME,
} from "./constants";

import MarketShareAnalysisService from "../../services/marketShareAnalysisService";
import { analyticsMarketShareAnalysisData } from "../shared/analytics/actions";
const marketShareAnalysisService = new MarketShareAnalysisService();

export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

export const fetchDataRequest = (component) => ({
  type: FETCH_DATA_REQUEST,
  payload: { component },
});

export const fetchDataSuccess = (res) => (dispatch) =>
  dispatch({
    type: FETCH_DATA_SUCCESS,
    payload: res,
  });

export const fetchDataFail = (error) => ({
  type: FETCH_DATA_FAIL,
  payload: { error },
});

export const marketShareAnalysisChangeGroupBy = () => ({
  type: MARKET_SHARE_ANALYSIS_CHANGE_GROUP_BY,
});

export const marketShareAnalysisToggleShowHide = (viewTypeId) => ({
  type: MARKET_SHARE_ANALYSIS_TOGGLE_SHOW_HIDE,
  viewTypeId: viewTypeId,
});

export const marketShareAnalysisSetSubjectName = (name) => ({
  type: MARKET_SHARE_ANALYSIS_SET_SUBJECT_NAME,
  name: name,
});

export const fetchMarketShareAnalysisData = (view_id, requestParams) => (
  dispatch
) => {
  dispatch(fetchDataRequest());

  marketShareAnalysisService
    .fetchMarketShareAnalysisData(requestParams)
    .then((res) => {
      dispatch(fetchDataSuccess(res));
      analyticsMarketShareAnalysisData(view_id, requestParams, res);
    })
    .catch((err) => {
      fetchDataFail(err.message);
    });
};
