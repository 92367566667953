import React from "react";
import styles from "./loading-cover.scss";
import Spinner from "../../../../components/spinner";

const LoadingCover = () => {
  return (
    <div className={styles.loadingCover}>
      <div>
        <Spinner height={150} width={300} />
      </div>
    </div>
  );
};

export default LoadingCover;
