import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import FrequencyTimeDropdown from "../mb-frequency-dropdown/mb-frequency-dropdown";
import styles from "./mb-filter-row.scss";
import { isFunction } from "../../utils/other/utilities";
import DatePickerService from "../../services/datePickerService";
import ComparePeriod from "../../components/mb-compare-period/compare-period";
import DateRangePicker from "../../components/date-range-picker/date-range-picker";
const datePickerService = new DatePickerService();

import utils from "../../utils";
const { getDefaultCompetitor } = utils.skus;

import {
  setSelectedCompetitors,
  setSelectedTimeFrequency,
  setSelectedDateRange,
  setMaxDate,
  setChosenPeriod,
  setComparePeriod,
  setPreviousSelectedDateRange,
} from "./actions";

import { setCurrentViewIsUpdated } from "../../containers/insights/insights-data-controls/actions";
import { analyticsComparePeriodSelected } from "../../containers/shared/analytics/actions";

function MbFilterRow(props) {
  const [hasResetSettings, setHasResetSettings] = useState(false);
  const [currentMaxDate, setCurrentMaxDate] = useState(null);

  const {
    competitorsList,
    selectedDateRange,
    selectedCompetitor,
    selectedTimeFrequency,

    onSelectDateRange,
    onSelectTimeFrequency,
    onSelectCompetitor,

    resetDateRange,
    resetTimeFrequency,
    resetCompetitor,

    hideFrequency,

    maxDate,
    minDate = null,
    mbFilterMode,

    comparePeriod,
    hideCompareDates,
    selectedPreviousDateRange,

    datePickerPresets,
    datePickerModes,
    comparePeriods,
    handlePresetClick,

    viewTypeId,
    lastAvailableDate,
    queryFrequency,
  } = props;

  const onChangeSelectedPeriod = (chosenPeriod) => {
    props.setChosenPeriod(chosenPeriod);
  };
  const onChangeCompetitor = (selectedItems) => {
    props.setSelectedCompetitors(selectedItems);
    if (isFunction(onSelectCompetitor)) onSelectCompetitor(selectedItems);
  };

  const onChangeTimeFrequency = ({ id }) => {
    props.setSelectedTimeFrequency(id);
    if (isFunction(onSelectTimeFrequency)) onSelectTimeFrequency(id);
  };

  const onChangeDateRange = (data) => {
    props.setPreviousSelectedDateRange(
      datePickerService.getPreviousDates(data, comparePeriod)
    );
    props.setSelectedDateRange(data);
    if (isFunction(onSelectDateRange)) onSelectDateRange(data);
  };

  const onChangeMaxDate = (range) => {
    props.setMaxDate(range);
    if (isFunction(onSelectDateRange)) setCurrentMaxDate(range);
  };

  const onChooseComparePeriod = (value) => {
    props.setComparePeriod(value);
    props.setPreviousSelectedDateRange(
      datePickerService.getPreviousDates(selectedDateRange, value)
    );
    analyticsComparePeriodSelected(viewTypeId, value);
    props.setCurrentViewIsUpdated();
  };

  useEffect(() => {
    if (!hasResetSettings) {
      if (resetCompetitor !== undefined) {
        onChangeCompetitor(resetCompetitor);
      }

      if (resetDateRange !== undefined) {
        onChangeDateRange(resetDateRange);
      }

      if (resetTimeFrequency !== undefined) {
        onChangeTimeFrequency(resetTimeFrequency);
      }

      setHasResetSettings(true);
    }
  }, [
    selectedDateRange,
    selectedCompetitor,
    selectedTimeFrequency,
    selectedPreviousDateRange,
  ]);

  useEffect(() => {
    if (!selectedCompetitor || Object.keys(selectedCompetitor).length == 0) {
      const defaultCompetitor = getDefaultCompetitor(competitorsList);
      onChangeCompetitor(defaultCompetitor);
    }
  }, [selectedCompetitor]);

  useEffect(() => {
    if (
      maxDate !== null &&
      (currentMaxDate === null || currentMaxDate.getTime() > maxDate.getTime())
    ) {
      setCurrentMaxDate(maxDate);
    }
  }, [maxDate]);

  return (
    <div className={styles.filters}>
      {!hideFrequency && (
        <div className={styles.timeFrequency}>
          <FrequencyTimeDropdown
            selectedFrequencyId={selectedTimeFrequency}
            handleFrequencyChange={onChangeTimeFrequency}
          />
        </div>
      )}

      <div className={styles.dateRangeDiv}>
        <DateRangePicker
          selectedDateRange={selectedDateRange}
          onChangeDateRange={onChangeDateRange}
          maxDate={props.maxDate}
          minDate={minDate}
          chosenPeriod={props.chosenPeriod}
          onChangeSelectedPeriod={onChangeSelectedPeriod}
          mbFilterMode={mbFilterMode}
          setCurrentViewIsUpdated={props.setCurrentViewIsUpdated}
          viewTypeId={viewTypeId}
          lastAvailableDate={lastAvailableDate}
          modes={datePickerModes}
          presets={datePickerPresets}
          handlePresetClick={handlePresetClick}
          queryFrequency={queryFrequency}
        />
      </div>
      {!hideCompareDates && (
        <ComparePeriod
          selectedDateRange={selectedDateRange}
          selectedItemName={comparePeriod}
          handleSelect={onChooseComparePeriod}
          comparePeriods={comparePeriods}
        />
      )}
    </div>
  );
}

export const mapStateToProps = (state) => {
  return {
    selectedDateRange: state.selectedDateRange,
    selectedCompetitor: state.selectedCompetitor,
    selectedTimeFrequency: state.selectedTimeFrequency,
    chosenPeriod: state.chosenPeriod,
    comparePeriod: state.comparePeriod,
    selectedPreviousDateRange: state.selectedPreviousDateRange,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedDateRange,
      setSelectedTimeFrequency,
      setSelectedCompetitors,
      setMaxDate,
      setChosenPeriod,
      setComparePeriod,
      setPreviousSelectedDateRange,
      setCurrentViewIsUpdated,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MbFilterRow);
