import { isEmpty } from "lodash";
import React, { useState } from "react";
import FilterControls from "../filter-controls";
import TextValue from "./text-value";
import styles from "./text-controls.scss";

export default function TextControls(props) {
  const { item, onUpdateFilter, conditionOptions, isActive } = props;
  const [text, setText] = useState("");

  const onChangeValue = ({ value }) => {
    setText(value);
  };

  const onKeyDown = (event) => {
    if (event.key === "Enter") {
      let trimmedText = text.trim();
      if (!isEmpty(trimmedText) && !item.selectedProperties?.includes(trimmedText)) {
        onUpdateFilter(item.id, {
          selectedProperties: [...(item.selectedProperties ?? []), trimmedText],
        });
      }
      setText("");
    }
  };

  const onPaste = (e) => {
    const value = text + e.clipboardData.getData("Text");

    if (!isEmpty(value)) {
      // keep previous strings and concat new, non empty strings. dedup in the end.
      let text = [
        ...new Set(
          (item.selectedProperties ?? [])
            .concat(value.split("\n"))
            .filter((x) => !isEmpty(x))
            .map(x=>x.trim())
        ),
      ];
      onUpdateFilter(item.id, {
        selectedProperties: text,
      });
      setText("");
      e.preventDefault();
    }
  };

  const onDelete = (value) => {
    onUpdateFilter(item.id, {
      selectedProperties: item.selectedProperties.filter((x) => x !== value),
    });
  };

  const formatValue = (value) => {
    return value != null && value.length > 32
      ? `${value.substring(0, 31)}... `
      : value;
  };

  const shouldDisplayInput = isActive || isEmpty(item.selectedProperties);
  const editableValues = item.selectedProperties?.map((x) => (
    <TextValue
      key={x}
      value={formatValue(x)}
      onClick={() => {
        onDelete(x);
      }}
    />
  ));

  const selectedProperties = item.selectedProperties ?? [];
  const nonEditableValues = (
    <div className={styles.nonEditableValues}>
      {selectedProperties?.length > 2
        ? `${formatValue(selectedProperties[0])}, ${formatValue(
            selectedProperties[1]
          )} and ${selectedProperties.length - 2} more`
        : selectedProperties.map((x) => formatValue(x)).join(", ")}
    </div>
  );

  const propertiesFilter = (
    <>
      <div className={styles.values}>
        {isActive ? editableValues : nonEditableValues}
        {shouldDisplayInput && (
          <div className={styles.inputWrapper}>
            <input
              onChange={() => onChangeValue(event.target)}
              onKeyDown={onKeyDown}
              onPaste={onPaste}
              value={text}
              type="text"
            />
          </div>
        )}
      </div>
    </>
  );

  return (
    <FilterControls
      item={item}
      onUpdateFilter={onUpdateFilter}
      propertyFilterComponent={propertiesFilter}
      conditionOptions={conditionOptions}
    />
  );
}
