import history from "../../history";
import deleteToken from "../auth/deleteToken";

function handleServerError(error) {
  if (error.response) {
    const { status, statusText, data } = error.response;

    if (status === 403 || status === 400 || status === 500) {
      throw new Error(
        `Error: ${status}, ${statusText}, ${JSON.stringify(data)}`
      );
    } else if (status === 401) {
      deleteToken();
      history.push("/login");

      if (data) {
        throw new Error(data);
      }
    } else if (status === 432) {
      return new Error(`Error: ${status}, ${data}`);
    } else {
      throw new Error(`Error: ${status}, ${statusText}`);
    }
  } else if (error.message) {
  } else {
    throw new Error(`error`);
  }
}

export default handleServerError;
