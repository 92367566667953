import React from "react";
import defaultStyles from "../dropdown2.scss";

function DropdownTitleSelectedItem(props) {
  const { preTitle, value, postTitle, arrow = false, className = null } = props;

  return (
    <>
      <p className={`${defaultStyles.headerTitle} ${className}`}>
        {preTitle && <span>{preTitle}</span>}
        <span /*style={{width: trim ? trim : 'auto'}}*/>{value}</span>
        {postTitle && <span>{postTitle}</span>}
        {arrow && <span className={defaultStyles.arrowDown}></span>}
      </p>
    </>
  );
}

export default DropdownTitleSelectedItem;
