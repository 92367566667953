import {
  CHANGE_PRICE_CHART,
  SORT_PRICE_CHART,
  CHANGE_DAILY_VIEWS_CHART,
  SORT_DAILY_VIEWS_CHART,
  CHANGE_DAILY_SALES_CHART,
  SORT_DAILY_SALES_CHART,
  CHANGE_AVAILABILITY_CHART,
  SORT_AVAILABILITY_CHART,
} from "../constants";

export const changePriceChart = (data) => ({
  type: CHANGE_PRICE_CHART,
  payload: { data },
});

export const sortPriceChart = (priceSelected, data) => ({
  type: SORT_PRICE_CHART,
  payload: { priceSelected, data },
});

export const changeDailyViewsChart = (data) => ({
  type: CHANGE_DAILY_VIEWS_CHART,
  payload: { data },
});

export const sortDailyViewsChart = (dailyViewsSelected, data) => ({
  type: SORT_DAILY_VIEWS_CHART,
  payload: { dailyViewsSelected, data },
});

export const changeDailySalesChart = (data) => ({
  type: CHANGE_DAILY_SALES_CHART,
  payload: { data },
});

export const sortDailySalesChart = (dailySalesSelected, data) => ({
  type: SORT_DAILY_SALES_CHART,
  payload: { dailySalesSelected, data },
});

export const changeAvailabilityChart = (data) => ({
  type: CHANGE_AVAILABILITY_CHART,
  payload: { data },
});

export const sortAvailabilityChart = (availabilitySelected, data) => ({
  type: SORT_AVAILABILITY_CHART,
  payload: { availabilitySelected, data },
});
