import React, { useEffect, useState, useCallback, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  SubspaceProvider,
  ParentSpaceProvider,
  useSubspace,
} from "react-redux-subspace";
import { hasSelectedCategories } from "../../../src/containers/market-share/utils";
import InsightsTemplate from "../insights-template/insights-template";

import marketShareStyles from "./market-share.scss";
import styles from "../insights-template/insights-template.scss";

import {
  convertMarketShareTableBrowserStringSortFieldToConfig,
  convertMarketShareTableConfigSortFieldToBrowserString,
} from "../../utils/market-share/convertSortValue";

import MarketShareDataFilter from "../../containers/market-share/components/market-share-data-filter/market-share-data-filter";
import MarketShareCharts from "../../containers/market-share/components/market-share-charts/market-share-charts";
import MarketShareTable from "../../containers/market-share/components/market-share-table/market-share-table";

import MarketShareService from "../../services/marketShareService";
export const marketShareService = new MarketShareService();

import {
  setDatepickerChanged,
  beforeMarketShareFetch,
  changeMarketShareTableSorting,
  changeMarketShareSettings,
} from "../../containers/market-share/actions";
import { forceSingleRetailerSelection } from "../../utils/other/mbDataUtils";
import ComponentsContainer from "../../containers/insights-board/components-container/components-container";
import { MarketShareConfig } from "../../containers/insights-board/insights-board-config";
import { getCategoriesFromConfig } from "../../utils/skus/getCategories";
import MbComponentsDataFetcher from "../../mb_components/mb-components-data-fetcher/mb-components-data-fetcher";
import MbMarketShareWarningContainer from "../../mb_components/mb-market-share-warning/mb-market-share-warning-container.js";

import * as Decorators from "../../styles/cell-decorators";

const mbComponentsDataFetcher = new MbComponentsDataFetcher();

import {
  fetchDataFail,
  fetchDataRequest,
  fetchDataSuccess,
} from "../../containers/insights-board/actions";
import { setSelectedSku } from "../../containers/product-360/actions";
import InsightsTemplateLandingPage from "../../mb_components/mb-insights-template-landing-page/insights-template-landing-page";
import MarketShareInsightsAiSidebar from "../../containers/market-share/components/insights-ai-sidebar";
import { isEmpty } from "lodash";
import { LoaderWidths } from "../../components/tables/simple-table/simple-table";
import MarketTrendsButtons from "../../mb_components/market-trends-buttons/market-trends-buttons";
import Product360 from "../product-360/product-360";
import {
  DIMENSIONS,
  MARKET_SHARE_VIEW_TYPE_DATA,
} from "../../containers/market-share/constants";
import MarketShareAnalysisWrapper from "../../containers/market-share-analysis";
import { MARKET_SHARE_ANALYSIS_SUBJECT_TYPE } from "../../containers/market-share-analysis/constants";
import { marketShareAnalysisToggleShowHide } from "../../containers/market-share-analysis/actions";
import MbInsightsAiSidebar from "../../containers/insights/insights-ai-sidebar";
import ToggleAIButton from "../../containers/insights/insights-ai-sidebar/toggle-ai-button";
import { showInsightsAiSidebar } from "../../containers/insights/insights-ai-sidebar/actions";
import { analyticsInsightsAiOpened } from "../../containers/shared/analytics/actions";
import getLastPredictionDate from "../../utils/skus/getLastPredictionDate.js";

function MarketShare(props) {
  const [lastConfig, setLastConfig] = useState("");
  const {
    selectedDateRange,
    currentViewConfig,
    selectedPreviousDateRange,
    domainsMetadata,
    comparePeriod,
    productAttributes,
    marketShareSortedData,
    marketShareLineChartData,
    marketTrendsConfigs,
    selectedSku,
    dimension,
    showMarketShareAnalysis,
    marketShareAnalysisToggleShowHide,
    showInsightsAiSidebar,
    insightsAiShowSidebar,
  } = props;
  const viewTypeData = MARKET_SHARE_VIEW_TYPE_DATA;
  const mapState = useCallback((state) => state[viewTypeData.subspace]);
  const subspace = useSubspace(mapState, viewTypeData.subspace);
  const search = props.location.search;
  const excludedTableColumns = [];
  const [hoveredKey, setHoveredKey] = useState(null);
  const marketShareAnalysisRef = useRef();

  useEffect(() => {
    props.setDatepickerChanged();
  }, [props.selectedDateRange]);

  const currentSelectedRetailer = forceSingleRetailerSelection(
    props.filterDefinitions,
    props.insightsFiltersCollection
  );

  const lastAvailableDate = getLastPredictionDate(
    domainsMetadata,
    currentSelectedRetailer,
    productAttributes
  );

  const categories = getCategoriesFromConfig(currentViewConfig, {
    return_as_product_types: true,
  });

  const columnsConfig = [
    {
      id: 0,
      label: "Sales Dollars",
      accessor: "gmv",
      tooltip: "Estimated retail sales for the selected time period",
      cellDecorator: Decorators.amountDollarsDecorator("gmv"),
      loaderWidth: LoaderWidths.Medium,
      tabName: "Sales Dollars",
    },
    {
      id: 1,
      label: "Sales Dollars Diff",
      accessor: "gmvChange",
      tooltip:
        "Sales Dollars difference between the current time period and the previous period listed above",
      cellDecorator: Decorators.numberChangeDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 2,
      label: "Sales Dollars Share",
      accessor: "gmvShare",
      tooltip: "Estimated sales dollar share for the selected time period",
      cellDecorator: Decorators.percentShareDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: "Sales Dollars Share",
    },
    {
      id: 3,
      label: "Sales Dollars Share Diff",
      accessor: "gmvShareChange",
      tooltip:
        "Percent sales dollar share change between the current time period and the previous period listed above",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 17,
      label: "Sales Dollars Percentage Change",
      accessor: "salesDollarsPercentChange",
      tooltip:
        "Sales dollars percent change between the current time period and the previous period listed above",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 4,
      label: "Units Sold",
      accessor: "sales",
      tooltip: "Estimated units sold for the selected time period",
      cellDecorator: Decorators.salesDecorator("sales"),
      loaderWidth: LoaderWidths.Small,
      tabName: "Units Sold",
    },
    {
      id: 5,
      label: "Units Sold Diff",
      accessor: "salesChange",
      tooltip:
        "Units sold difference between the current time period and the previous period listed above",
      cellDecorator: Decorators.roundNumberChangeDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 6,
      label: "Units Share",
      accessor: "salesShare",
      tooltip: "Estimated units sold share for the selected time period",
      cellDecorator: Decorators.percentShareDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: "Units Sold Share",
    },
    {
      id: 7,
      label: "Units Share Diff",
      accessor: "salesShareChange",
      tooltip:
        "Percent units sold share change between the current time period and the previous period listed above",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 8,
      label: "ASP",
      accessor: "asp",
      tooltip:
        "The weighted Average Selling Price (ASP) for all items sold during this period. Excludes non-selling items.",
      cellDecorator: Decorators.priceDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 9,
      label: "ASP Change",
      accessor: "aspChange",
      tooltip:
        "Percent ASP share change between the current time period and the previous period listed above",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 10,
      label: "In-Stock Rate",
      accessor: "inStockRate",
      tooltip:
        "The percent of time that items have been in stock for the selected time period.",
      cellDecorator: Decorators.percentShareDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: "In Stock Rate",
    },
    {
      id: 11,
      label: "In-Stock Rate Change",
      accessor: "inStockRateChange",
      tooltip:
        "Percent in-stock rate change between the current time period and the previous period listed above",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 12,
      label: "Average Retail Price",
      accessor: "avgListedPrice",
      tooltip:
        "Amount of sales dollars made by the retailer for the current time period",
      cellDecorator: Decorators.amountDollarsDecorator("avgListedPrice"),
      loaderWidth: LoaderWidths.Small,
      tabName: "Average Retail Price",
    },
    {
      id: 13,
      label: "Active Selling SKUs",
      accessor: "activeSellingSkus",
      tooltip:
        "Total number of SKUs that have sold at least one unit during the selected time period",
      cellDecorator: Decorators.salesDecorator("activeSellingSkus"),
      loaderWidth: LoaderWidths.Small,
      tabName: "Active Selling SKUs",
    },
    {
      id: 14,
      label: "Ads Spend",
      accessor: "adsSpend",
      tooltip:
        "Estimated Ad Spend on Search Campaigns during the selected period.",
      cellDecorator: Decorators.amountDollarsDecorator("adsSpend"),
      loaderWidth: LoaderWidths.Small,
      tabName: null,
    },
    {
      id: 15,
      label: "Promotion Sales Dollars Share",
      accessor: "promoShare",
      tooltip:
        "Percentage of total sales dollars attributed to promotional events during the selected period.",
      cellDecorator: Decorators.percentShareDecorator,
      loaderWidth: LoaderWidths.Small,
      tabName: "Promotion Sales Dollars Share",
    },
    {
      id: 16,
      label: "Promotion Events",
      accessor: "promoDays",
      tooltip:
        "Count of promotional activities conducted during the selected period.",
      cellDecorator: Decorators.salesDecorator("promoDays"),
      loaderWidth: LoaderWidths.Small,
      tabName: "Promotion Events",
    },
    {
      id: 17,
      label: "Units Sold Per Store",
      accessor: "salesPerStore",
      tooltip: "Estimated units sold per store for the selected time period",
      cellDecorator: Decorators.salesDecorator("sales"),
      loaderWidth: LoaderWidths.Small,
      tabName: "Units Sold Per Store",
    },
  ];

  const marketShareAnalysisMapState = useCallback(
    (state) => state.marketShareAnalysis
  );
  const marketShareAnalysisSubspace = useSubspace(
    marketShareAnalysisMapState,
    "marketShare"
  );

  const closeMarketShareAnalysis = (showMarketShareAnalysis) => {
    if (showMarketShareAnalysis) {
      marketShareAnalysisSubspace.dispatch(marketShareAnalysisToggleShowHide());
    }
  };

  const retailers = props.insightsFiltersCollection.filter(
    (x) => x.displayName == "Retailer" || x.mbName == "rootdomain.keyword"
  )[0]?.values;

  useEffect(() => {
    if (hasSelectedCategories(currentViewConfig)) {
      const result = mbComponentsDataFetcher.fetchDataIfRequired(
        currentSelectedRetailer,
        selectedDateRange,
        currentViewConfig,
        selectedPreviousDateRange,
        domainsMetadata,
        comparePeriod,
        MarketShareConfig,
        lastConfig,
        props.fetchDataFail,
        props.fetchDataRequest,
        props.fetchDataSuccess,
        viewTypeData.id
      );
      if (result) {
        props.beforeMarketShareFetch();
        setLastConfig(result);
      }
    }
  }, [
    retailers,
    selectedDateRange,
    selectedPreviousDateRange,
    productAttributes,
  ]);

  useEffect(() => {
    const url = new URL(document.location.href);
    if (!selectedSku && url.pathname != `/${viewTypeData.name}`) {
      url.pathname = viewTypeData.name;
      url.searchParams.delete("sku");
      history.pushState({}, "", url);
    }
  }, [selectedSku]);

  const onChangeTrendCallback = (config) => {
    closeMarketShareAnalysis(showMarketShareAnalysis);
    props.changeMarketShareTableSorting({
      sortField: config.overridingViewQuery.sortField,
      sortOrder: config.overridingViewQuery.sortOrder,
    });
    props.changeMarketShareSettings({
      ...currentViewConfig.query.settings,
      kpisChart: {
        ...currentViewConfig.query.settings.kpisChart,
        selectedTab: config.overridingViewQuery.settings.kpisChart.selectedTab,
      },
    });
  };

  const incompleteMarketShareData = (
    <MbMarketShareWarningContainer
      currentEndDate={selectedDateRange?.endDate}
      previousStartDate={selectedPreviousDateRange?.previousStartDate}
      maxDate={lastAvailableDate}
      type="date"
    />
  );

  const noSegmentsChosen = (
    <MbMarketShareWarningContainer
      currentEndDate={selectedDateRange?.endDate}
      previousStartDate={selectedPreviousDateRange?.previousStartDate}
      maxDate={lastAvailableDate}
      type="segment"
    />
  );

  const NoData = ({ msg }) => {
    return (
      <div className={marketShareStyles.noData}>
        <InsightsTemplateLandingPage displayText={msg} />
      </div>
    );
  };

  const headline = (
    <ComponentsContainer
      selectedDateRange={selectedDateRange}
      selectedPreviousDateRange={selectedPreviousDateRange}
      comparePeriod={comparePeriod}
      configuration={MarketShareConfig}
      componentsData={props.components}
      categories={categories}
    />
  );

  const dataFilter = (
    <ParentSpaceProvider>
      <div className={styles.dataFilterWrapper}>
        <MarketShareDataFilter />
        <MarketTrendsButtons
          onChangeTrendCallback={onChangeTrendCallback}
          marketTrendsConfigs={marketTrendsConfigs}
          currentViewConfigQuery={currentViewConfig.query}
          viewName={currentViewConfig?.name}
          viewTypeId={viewTypeData.id}
        />
      </div>
    </ParentSpaceProvider>
  );
  const chart = (
    <>
      <ParentSpaceProvider>
        <MarketShareCharts
          noSegmentsChosen={noSegmentsChosen}
          columnsConfig={columnsConfig}
          hoveredKey={hoveredKey}
          marketShareAnalysisRef={marketShareAnalysisRef}
        />
      </ParentSpaceProvider>
    </>
  );

  const table = (
    <ParentSpaceProvider>
      <MarketShareTable
        excludedTableColumns={excludedTableColumns}
        columnConfigs={columnsConfig}
        onHoverRow={setHoveredKey}
      />
    </ParentSpaceProvider>
  );

  const displayTextMarketShareError =
    "It looks like there are no results for the filters you've selected. Please adjust your filter settings to see Insights.";

  const displayTextPartialDataError =
    "Seller data can't be attributed for your query, please modify your query and try again.";

  const loading =
    isEmpty(marketShareSortedData) || isEmpty(marketShareLineChartData);

  const BottomRowLeftComponent = () => {
    const handleInsightsAiSidebarButton = () => {
      analyticsInsightsAiOpened(viewTypeData.id, currentViewConfig?.name);
      subspace.dispatch(showInsightsAiSidebar(!insightsAiShowSidebar));
    };
    return (
      <>
        <ToggleAIButton
          active={!loading && hasSelectedCategories(currentViewConfig)}
          onClick={() => handleInsightsAiSidebarButton()}
        />
        {insightsAiShowSidebar && (
          <MbInsightsAiSidebar viewTypeId={MARKET_SHARE_VIEW_TYPE_DATA.id}>
            {(textToCopy) => (
              <MarketShareInsightsAiSidebar textToCopy={textToCopy} />
            )}
          </MbInsightsAiSidebar>
        )}
      </>
    );
  };

  const dataControlsOptions = {
    viewTypeData,
    lastAvailableDate,
    dateRange: props.selectedDateRange,
    maxDate: lastAvailableDate,
    mbFilter: true,
    apiProvider: marketShareService,
    hideDate: false,
    hideFrequency: true,
    hideAlert: true,
    hideRetailer: true,
    convertConfigSortFieldToBrowserString: convertMarketShareTableConfigSortFieldToBrowserString,
    addDatesToUrl: true,
    hideCompareDates: false,
    dataAvailableUntilLabel: "Data available until",
    dataUnavailableLabel: "Data is unavailable",
    loading: loading,
    BottomRowLeftComponent: <BottomRowLeftComponent />,
  };

  const marketShareAnalysisSubjectType =
    dimension != null
      ? dimension === DIMENSIONS.brands
        ? MARKET_SHARE_ANALYSIS_SUBJECT_TYPE.BRAND
        : dimension === DIMENSIONS.sellers
        ? MARKET_SHARE_ANALYSIS_SUBJECT_TYPE.SELLER
        : null
      : null;

  const body = (
    <>
      <div className={styles.warning}> {incompleteMarketShareData}</div>
      <div>{headline}</div>
      <div>{dataFilter}</div>
      <div style={props.partialData ? { display: "none" } : {}}>
        <div className={styles.chartWrapper}>{chart}</div>
        {marketShareAnalysisSubjectType != null && showMarketShareAnalysis && (
          <div ref={marketShareAnalysisRef}>
            <MarketShareAnalysisWrapper
              viewTypeData={viewTypeData}
              subjectType={marketShareAnalysisSubjectType}
            />
          </div>
        )}
        <div className={styles.wrapper}>{table}</div>
      </div>
      {props.partialData && <NoData msg={displayTextPartialDataError} />}
    </>
  );

  return (
    <div>
      {selectedSku && <Product360 parentRedirect={true} />}
      <div style={selectedSku ? { display: "none" } : {}}>
        <SubspaceProvider
          mapState={(state) => state.marketShare}
          namespace="marketShare"
        >
          <InsightsTemplate
            dataControlsOptions={dataControlsOptions}
            lastAvailableDate={lastAvailableDate}
            search={search}
            viewTypeData={viewTypeData}
            apiProvider={marketShareService}
            body={body}
            noDataMessage={
              !props.hasData && <NoData msg={displayTextMarketShareError} />
            }
            convertBrowserStringSortFieldToConfig={
              convertMarketShareTableBrowserStringSortFieldToConfig
            }
            insightsAiComponent={<MarketShareInsightsAiSidebar />}
          />
        </SubspaceProvider>
      </div>
    </div>
  );
}

export const mapStateToProps = (state) => {
  const competitorsList = state.competitors.data;
  const {
    selectedDateRange,
    selectedPreviousDateRange,
    selectedCompetitor,
    comparePeriod,
  } = state.marketShare.insights.mbFilterRow;

  const { domainsMetadata } =
    state.marketShare.insights.insights.viewDefinitions?.viewMetadata ?? {};

  return {
    showMarketShareAnalysis:
      state.marketShare.marketShareAnalysis.showMarketShareAnalysis,
    marketShareSortedData: state.marketShare.marketShare.marketShareSortedData,
    marketShareLineChartData:
      state.marketShare.marketShare.marketShareLineChartData,
    partialData: state.marketShare.marketShare.tableStatus.partial,
    hasData: state.marketShare.marketShare.hasData,
    competitorsList,
    selectedDateRange,
    selectedPreviousDateRange,
    selectedCompetitor,
    domainsMetadata,
    filterDefinitions:
      state.marketShare.insights.insights.viewDefinitions?.filterDefinitions,
    currentViewConfig: state.marketShare.insights.insights.currentViewConfig,
    productAttributes:
      state.marketShare.insights.insights.currentViewConfig.query
        .productAttributes,
    insightsFiltersCollection:
      state.marketShare.insights.insights.currentViewConfig.query
        .productAttributes,
    dimension:
      state.marketShare.insights.insights.currentViewConfig.query?.settings
        ?.dimension,
    comparePeriod,
    components: state.marketShare.insightsBoard.components,
    marketTrendsConfigs:
      state.marketShare.insights.insights.marketTrendsConfigs,
    selectedSku: state.product360.product360.selectedSku,
    insightsAiShowSidebar:
      state.marketShare.insights.insights.insightsAiShowSidebar,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setDatepickerChanged,
      fetchDataFail,
      fetchDataRequest,
      fetchDataSuccess,
      beforeMarketShareFetch,
      changeMarketShareTableSorting,
      changeMarketShareSettings,
      setSelectedSku,
      marketShareAnalysisToggleShowHide,
      showInsightsAiSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MarketShare);
