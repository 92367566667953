import Moment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(Moment);

const sortDate = (a, b) =>
  Moment(a.date, "MM-DD-YYYY") - Moment(b.date, "MM-DD-YYYY");

const sortDataLineChart = (data, filter = "Last 30 days") => {
  let endDate = Moment();
  let startDate;
  let result = [];

  switch (filter) {
    case "Last 7 days":
      startDate = Moment()
        .subtract(7 - 1, "d")
        .format("MM-DD-YYYY");
      break;
    case "Last 30 days":
      startDate = Moment()
        .subtract(30 - 1, "d")
        .format("MM-DD-YYYY");
      break;
    case "Last 90 days":
      startDate = Moment()
        .subtract(90 - 1, "d")
        .format("MM-DD-YYYY");
      break;
    default:
      startDate = null;
  }

  if (!data) {
    return null;
  }

  if (!startDate) {
    return data;
  }

  let range = moment().range(startDate, endDate);

  data.map((item) => {
    if (range.contains(Moment(item.date))) {
      result.push(item);
    }
  });

  return result.sort(sortDate);
};

export default sortDataLineChart;
