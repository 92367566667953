const UsersTableSortBy = {
    FirstName: 0,
    LastName: 1,
    RootDomain: 2
};

const convertUsersTableSortValue = (sortValue) => {
    if (sortValue == "First Name" || sortValue == 0) {
        return "FirstName";
    }
    if (sortValue == "Last Name" || sortValue == 1) {
        return "LastName";
    }
    if (sortValue == "Domain" || sortValue == 2) {
        return "RootDomain";
    }

    return sortValue;
};

export default convertUsersTableSortValue;

export const convertUsersTableSortValueToColumn = (sortValue) => {
    if (sortValue == UsersTableSortBy.FirstName) {
        return 0;
    }
    if (sortValue == UsersTableSortBy.LastName) {
        return 1;
    }

    if (sortValue == UsersTableSortBy.RootDomain) {
        return 2;
    }

    return sortValue;
};

export const convertUsersTableSortValue2 = (sortValue) => {
    if (sortValue == "First Name" || sortValue == 0) {
        return UsersTableSortBy.FirstName;
    }
    if (sortValue == "Last Name" || sortValue == 1) {
        return UsersTableSortBy.LastName;
    }
    if (sortValue == "Domain" || sortValue == 2) {
        return UsersTableSortBy.RootDomain;
    }

    return sortValue;
};

export const convertUsersTableConfigSortFieldToBrowserString = (
    viewTypeId,
    sortValue
) => {
    return Object.keys(UsersTableSortBy).find(
        (key) => UsersTableSortBy[key] === sortValue
    );
};

export const convertUsersTableBrowserStringSortFieldToConfig = (
    viewTypeId,
    sortValue,
    frequency
) => {
    var key = Object.keys(UsersTableSortBy).find(
        (k) => k.toLowerCase() === sortValue.toLowerCase()
    );
    return UsersTableSortBy[key];
};