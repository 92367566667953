import React, { useState } from "react";
import styles from "./copy-button.scss";
import { isEmpty, isFunction } from "lodash";

export default function CopyButton(props) {
  const { textToCopy, onTextCopied } = props;
  const [showCopied, setShowCopied] = useState(false);

  const handleClick = () => {
    var copied = false;
    if (textToCopy != null) {
      if (typeof textToCopy === "string" && !isEmpty(textToCopy)) {
        navigator.clipboard.writeText(textToCopy);
        copied = true;
      }
      if (typeof textToCopy === "object" && !isEmpty(textToCopy.current)) {
        // Reference
        navigator.clipboard.writeText(textToCopy.current);
        copied = true;
      }
    }
    if (copied) {
      setShowCopied(true);
      setTimeout(() => setShowCopied(false), 3000);
      if (isFunction(onTextCopied)) {
        onTextCopied();
      }
    }
  };

  return (
    <>
      {showCopied && <div className={styles.copied}>Copied!</div>}
      <i className={styles.copyButton} onClick={handleClick} />
    </>
  );
}
