import {
  APPLY_INITIAL_STATE,
  BEFORE_FETCH_MARKET_SHARE_DATA,
  FETCH_MARKET_SHARE_CHART_DATA_FAILURE,
  FETCH_MARKET_SHARE_CHART_DATA_REQUEST,
  FETCH_MARKET_SHARE_CHART_DATA_SUCCESS,
  FETCH_MARKET_SHARE_TABLE_DATA_CANCEL,
  FETCH_MARKET_SHARE_TABLE_DATA_FAILURE,
  FETCH_MARKET_SHARE_TABLE_DATA_REQUEST,
  FETCH_MARKET_SHARE_TABLE_DATA_SUCCESS,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_REQUEST,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_SUCCESS,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_FAILURE,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_CANCEL,
  DATEPICKER_CHANGED,
  CHANGE_MARKET_SHARE_TABLE_SORTING,
  CHANGE_MARKET_SHARE_TABLE_OFFSET,
  CHANGE_VIEW_SETTINGS,
  SET_CURRENT_VIEW_IS_UPDATED,
  CHANGE_SKUS_SELECTED_COLUMNS,
  FETCH_MARKET_SHARE_TABLE_DATA_PARTIAL,
  UNKNOWNS_THRESHOLD_STATUS_CODE,
} from "./constants";

import MarketShareService from "../../services/marketShareService";

const marketShareService = new MarketShareService();

export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

/*  Market Share Data */

export const marketShareChartDataRequested = () => ({
  type: FETCH_MARKET_SHARE_CHART_DATA_REQUEST,
});

export const marketShareChartDataSuccess = (data) => ({
  type: FETCH_MARKET_SHARE_CHART_DATA_SUCCESS,
  payload: { data },
});

export const marketShareChartDataError = (error) => ({
  type: FETCH_MARKET_SHARE_CHART_DATA_FAILURE,
  payload: { error },
});

export const marketShareChartDataCancel = () => ({
  type: FETCH_MARKET_SHARE_CHART_DATA_CANCEL,
});

export const beforeMarketShareFetch = () => ({
  type: BEFORE_FETCH_MARKET_SHARE_DATA,
});

export const marketShareDataRequested = () => ({
  type: FETCH_MARKET_SHARE_TABLE_DATA_REQUEST,
});

export const marketShareDataSuccess = (data) => ({
  type: FETCH_MARKET_SHARE_TABLE_DATA_SUCCESS,
  payload: { data },
});

export const marketShareDataPartial = () => ({
  type: FETCH_MARKET_SHARE_TABLE_DATA_PARTIAL,
});

export const marketShareDataError = (error) => ({
  type: FETCH_MARKET_SHARE_TABLE_DATA_FAILURE,
  payload: { error },
});

export const marketShareDataCancel = () => ({
  type: FETCH_MARKET_SHARE_TABLE_DATA_CANCEL,
});

export const changeMarketShareSettings = (
  value,
  closeInsightsAiSidebar = true
) => ({
  type: CHANGE_VIEW_SETTINGS,
  payload: value,
  closeInsightsAiSidebar: closeInsightsAiSidebar,
});

export const changeMarketShareTableSorting = (data) => ({
  type: CHANGE_MARKET_SHARE_TABLE_SORTING,
  payload: { data },
});

export const changeMarketShareTableOffset = (value) => ({
  type: CHANGE_MARKET_SHARE_TABLE_OFFSET,
  payload: { value },
});

export const changeSkusSelectedColumns = (value) => ({
  type: CHANGE_SKUS_SELECTED_COLUMNS,
  payload: { value },
});

export const setCurrentViewIsUpdated = () => ({
  type: SET_CURRENT_VIEW_IS_UPDATED,
});

export const setDatepickerChanged = () => ({
  type: DATEPICKER_CHANGED,
});

export const fetchMarketShareData = (requestParams) => (dispatch) => {
  dispatch(marketShareDataRequested());
  dispatch(marketShareUpdateOffset(0));

  marketShareService
    .fetchData(requestParams)
    .then((res) => {
      if (res.status === UNKNOWNS_THRESHOLD_STATUS_CODE) {
        dispatch(marketShareDataPartial());
      } else {
        const { data } = res;
        const parsed = {
          tableData: data["tableData"].map((row, i) => {
            return { ...row, id: i };
          }),
          lineChartData: data["lineChartData"],
        };
        dispatch(marketShareDataSuccess(parsed));
      }
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(marketShareDataCancel());
      } else {
        dispatch(marketShareDataError(err.message));
      }
    });
};

// More table data
export const marketShareMoreDataRequested = () => ({
  type: FETCH_MORE_MARKET_SHARE_TABLE_DATA_REQUEST,
});

export const marketShareUpdateOffset = (value) => ({
  type: CHANGE_MARKET_SHARE_TABLE_OFFSET,
  payload: value,
});

export const marketShareMoreDataSucceeded = (data = []) => ({
  type: FETCH_MORE_MARKET_SHARE_TABLE_DATA_SUCCESS,
  payload: { data },
});

export const marketShareMoreDataError = (error) => ({
  type: FETCH_MORE_MARKET_SHARE_TABLE_DATA_FAILURE,
  payload: error,
});

export const marketShareMoreDataCancel = () => ({
  type: FETCH_MORE_MARKET_SHARE_TABLE_DATA_CANCEL,
});

export const fetchMoreMarketShareData = (requestParams) => (dispatch) => {
  const newOffset =
    (requestParams.insightViews.offset ?? 0) + requestParams.insightViews.limit;
  const updatedRequestParams = {
    ...requestParams,
    insightViews: {
      ...requestParams.insightViews,
      offset: newOffset,
    },
  };
  dispatch(marketShareMoreDataRequested());
  dispatch(marketShareUpdateOffset(newOffset));

  marketShareService
    .fetchData(updatedRequestParams)
    .then((res) => {
      dispatch(marketShareMoreDataSucceeded(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(marketShareMoreDataCancel());
      } else {
        dispatch(marketShareMoreDataError(err.message));
      }
    });
};
