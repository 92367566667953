import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Dropdown from "../../../components/dropdown";

import { changeFieldCompetitors } from "./actions";
import { fetchCategoryMapping, fetchInsightsBrands } from "../actions";
import { fetchSkus } from "../insights-skus/actions";
import utils from "../../../utils";
import { insightsSkusLimit } from "../../../config";
const { generateQueryUrl, generateQueryConfig } = utils.skus;
import InsightsBreadcrumbs from "../insights-breadcrumbs/insights-breadcrumbs";

import styles from "./insights-header-filter.scss";

class InsightsHeaderFilter extends Component {
  handleSelectCompetitor = ({ name, rootDomain }) => {
    this.props.changeFieldCompetitors(name, rootDomain);

    const { activeSort, activeSortDescending } = this.props;

    let queryConfig2 = generateQueryConfig(
      { limit: insightsSkusLimit, offset: 0 },
      "competitorRootDomain",
      rootDomain
    );
    queryConfig2 = generateQueryConfig(queryConfig2, "sort", activeSort);
    queryConfig2 = generateQueryConfig(
      queryConfig2,
      "sortDescending",
      activeSortDescending
    );
    queryConfig2 = generateQueryConfig(
      queryConfig2,
      "getMissingMatchedSkus",
      "false"
    );
    const query2 = generateQueryUrl(queryConfig2);
    const query1 = query2.replace(`limit=${insightsSkusLimit}offset=0&`, "");

    this.props.fetchCategoryMapping(query1);
    this.props.fetchSkus("productList", query2);
    this.props.fetchInsightsBrands(rootDomain);
  };

  render() {
    const { competitorsOptions, competitorSelected } = this.props;
    const filteredOpts = competitorsOptions.filter(
      (item) => item.name !== "All"
    );

    return (
      <>
        <div className={styles.wrapper}>
          <Dropdown
            size="small"
            title="Competitor Site:"
            options={filteredOpts}
            value={competitorSelected}
            handleSelect={this.handleSelectCompetitor}
          />
        </div>
        <InsightsBreadcrumbs />
      </>
    );
  }
}

export const mapStateToProps = (state) => {
  const {
    insights,
    insights: { insightsFiltersValues },
  } = state;
  return {
    competitorsOptions: state.competitors.data,
    competitorSelected: insights.insights.competitor.name,
    insightsBrands: insights.insights.brands,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeFieldCompetitors,
      fetchCategoryMapping,
      fetchInsightsBrands,
      fetchSkus,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsHeaderFilter);
