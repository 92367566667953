import React, { useRef, useEffect, useState } from "react";

import FilterItem, {
  getFilterConditionOptions,
} from "./filter-item/filter-item";

import CustomScrollbar from "../../components/scrollbar/customScrollbar";
import styles from "./filter-sidebar.scss";

import Button from "../../components/button";
import _, { isEmpty, isNull, uniqueId } from "lodash";

import {
  ConvertInsightsFiltersToFilterSidebar,
  hiddenFilter,
} from "./filter-conversions";
import { deepClone } from "../../utils/other/utilities";
import { UpdateFilterBySubjectTo } from "../../utils/filters/filtersUtils";

const FilterCollection = ({
  filtersCollection,
  setFiltersCollection,
  insightsFiltersCollection,
  filterDefinitions,
  filterDefinitionsHaveLoaded,
  scrollable = true,
  height,
}) => {
  const [shouldOpenNewFilter, setShouldOpenNewFilter] = useState(false);

  const [
    anchoredFiltersDefinitions,
    nonAnchoredFiltersDefinitions,
  ] = filterDefinitions?.reduce(
    ([anchoredFilters, nonAnchoredFilters], filter) => {
      if (filter.constraints?.Bounded)
        return [anchoredFilters, nonAnchoredFilters];

      return filter.constraints?.SelectedByDefault
        ? [[...anchoredFilters, filter], nonAnchoredFilters]
        : [anchoredFilters, [filter, ...nonAnchoredFilters]];
    },
    [[], []]
  );

  const [
    UpdatedFiltersAfterSelection,
    setUpdatedFiltersAfterSelection,
  ] = useState([]);

  useEffect(() => {
    setUpdatedFiltersAfterSelection(
      deepClone(filterDefinitions).map((filter) =>
        UpdateFilterBySubjectTo(filter, filtersCollection)
      )
    );
  }, [filtersCollection, filterDefinitions]);

  useEffect(() => {
    // fill values for all filters
    if (filterDefinitionsHaveLoaded) {
      filtersCollection
        .filter((x) => isNull(x.values))
        .forEach((filter) => {
          const filterDefinition = filterDefinitions.find(
            (x) => x.mbName === filter.mbName
          );
          filter.values = filterDefinition.values;
        });
    }
  }, [filterDefinitionsHaveLoaded]);

  const onAddFilter = () => {
    const id = uniqueId();
    filtersCollection.unshift({ id: id });
    setFiltersCollection([...filtersCollection]);
    setShouldOpenNewFilter(id);
  };

  const onDisposeFilter = (id) => {
    const index = filtersCollection.findIndex((x) => x.id === id);
    filtersCollection.splice(index, 1);
    setFiltersCollection([...filtersCollection]);
    setShouldOpenNewFilter(false);
  };

  const clearFiltersOnChange = (filter) => {
    let updatedFiltersCollection = filtersCollection;
    if (filter.constraints?.ClearFiltersOnChange ?? false) {
      for (let filterName of filter.constraints.ClearFiltersOnChange) {
        const filterDefinition = filterDefinitions.find(
          (x) => x.mbName === filterName
        );
        updatedFiltersCollection = updatedFiltersCollection?.filter(
          (x) => x.mbName && x.mbName !== filterDefinition.mbName
        );
        for (let selectedFilter of updatedFiltersCollection) {
          selectedFilter.childFilters = selectedFilter.childFilters?.filter(
            (x) => x.mbName && x.mbName !== filterDefinition.mbName
          );
        }
      }
    }
    return updatedFiltersCollection;
  };

  const onUpdateFilter = (id, data) => {
    const index = filtersCollection.findIndex((x) => x.id === id);
    const filter = isEmpty(data.displayName)
      ? {}
      : filterDefinitions.find((x) => x.displayName === data.displayName);

    // if filter !== null, user has selected a new filter. set condition to default by filter type
    const defaultCondition = isEmpty(filter)
      ? {}
      : { selectedCondition: getFilterConditionOptions(filter)[0].value };

    filtersCollection[index] = {
      ...filtersCollection[index],
      ...filter,
      ...defaultCondition,
      ...data,
    };

    const filterDefinition = filterDefinitions.find(
      (x) => x.displayName === filtersCollection[index].displayName
    );
    const clearedFiltersCollection = clearFiltersOnChange(filterDefinition);
    setFiltersCollection([...clearedFiltersCollection]);
    setShouldOpenNewFilter(false);
  };

  useEffect(() => {
    const updatedFilters = deepClone(filterDefinitions).map((filter) =>
      UpdateFilterBySubjectTo(filter, insightsFiltersCollection)
    );

    setFiltersCollection(
      ConvertInsightsFiltersToFilterSidebar(
        insightsFiltersCollection,
        updatedFilters,
        anchoredFiltersDefinitions
      )
    );
  }, [insightsFiltersCollection]);

  const totalAnchoredFilters = anchoredFiltersDefinitions.length;
  const addFilterButtonActive =
    filterDefinitions &&
    filterDefinitions.length > 0 &&
    (filtersCollection.length === 0 ||
      filtersCollection.length === totalAnchoredFilters ||
      filtersCollection[0].displayName != null);

  const nonAnchoredFiltersCollection = filtersCollection
    .filter((item) => !hiddenFilter(item))
    .map((item) =>
      item.constraints?.SelectedByDefault ? null : (
        <FilterItem
          key={item.id}
          selectableFiltersDefinitions={_.sortBy(
            nonAnchoredFiltersDefinitions,
            "displayName"
          ).filter((x) => !hiddenFilter(x))}
          filterDefinitions={UpdatedFiltersAfterSelection}
          item={item}
          onDispose={onDisposeFilter}
          onUpdateFilter={onUpdateFilter}
          openByDefault={shouldOpenNewFilter === item.id}
        />
      )
    );

  const collectionContent = (
    <div>
      {filtersCollection
        ?.filter(
          (item) => item.constraints?.SelectedByDefault && !hiddenFilter(item)
        )
        ?.map((item) => (
          <FilterItem
            key={item.displayName}
            filterDefinitions={filterDefinitions}
            item={item}
            onUpdateFilter={onUpdateFilter}
            forceSelectedFilterDisplayName={item.displayName}
          />
        ))}
      <Button
        id={"addFilterBtn"}
        onClick={onAddFilter}
        text={"+ Add Filter"}
        active={addFilterButtonActive}
      />
      {nonAnchoredFiltersCollection}
    </div>
  );

  return scrollable ? (
    <CustomScrollbar
      className={styles.customScrollbar}
      styles={styles}
      style={{ height: height }}
    >
      {collectionContent}
    </CustomScrollbar>
  ) : (
    collectionContent
  );
};

export default FilterCollection;
