import React from "react";
import SkuCard from "./sku-card/sku-card";
import { INDEX_COLORS } from "../../constants";

const MarketShareCards = ({
  data,
  selectedKeys,
  onSelectionUpdate,
  onClick,
}) => {
  return (
    <div
      style={{ display: "flex", gap: "16px", flexWrap: "wrap", width: "100%" }}
    >
      {data.map((x, i) => (
        <SkuCard
          key={i}
          data={{ ...x?.skuData, ...x }}
          selected={selectedKeys?.includes(i)}
          entityColor={INDEX_COLORS[i]}
          onSelectionUpdate={(e, checked) => onSelectionUpdate(e, i, checked)}
          onClick={() => onClick(x)}
        />
      ))}
    </div>
  );
};

export default MarketShareCards;
