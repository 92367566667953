import {
  GET_MATCHES_REQUEST,
  GET_MATCHES_SUCCESS,
  GET_MATCHES_CANCEL,
  GET_MATCHES_FAILURE,
  GET_MORE_MATCHES_CANCEL,
  GET_MORE_MATCHES_FAILURE,
  GET_MORE_MATCHES_REQUEST,
  GET_MORE_MATCHES_SUCCESS,
  UPDATE_MAIN_ROOTDOMAIN,
} from "./constants";

import { insightsSkusLimit } from "../../config";

const initialState = {
  isLoading: false,
  loadingMore: false,
  hasMore: false,
  mainRootdomain: "",
  offset: 0,
  matchesList: [],
};

const catalogTrackerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MATCHES_REQUEST:
      return {
        ...state,
        matchesList: [],
        isLoading: true,
        hasMore: true,
      };
    case GET_MATCHES_SUCCESS: {
      const { data } = action.payload;
      var matchesList = data.matchesList;
      return {
        ...state,
        isLoading: false,
        matchesList: matchesList,
      };
    }
    case GET_MATCHES_CANCEL: {
      return {
        ...state,
        isLoading: true,
        hasMore: true,
      };
    }
    case GET_MATCHES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        loadingMore: false,
        hasMore: true,
        matchesList: [],
        offset: 0,
      };
    }
    case GET_MORE_MATCHES_REQUEST: {
      const offset = state.offset + insightsSkusLimit;
      return {
        ...state,
        isLoading: false,
        loadingMore: true,
        offset: offset,
      };
    }
    case GET_MORE_MATCHES_SUCCESS: {
      const { data } = action.payload;
      var matchesList = data.matchesList;
      return {
        ...state,
        isLoading: false,
        loadingMore: false,
        matchesList: [...state.matchesList, ...matchesList],
        hasMore: matchesList.length !== 0 ? true : false,
      };
    }
    case GET_MORE_MATCHES_CANCEL: {
      return {
        ...state,
        hasMore: true,
        loadingMore: true,
        loading: false,
      };
    }
    case GET_MORE_MATCHES_FAILURE: {
      return {
        ...state,
        hasMore: false,
        loadingMore: false,
        isLoading: false,
      };
    }
    case UPDATE_MAIN_ROOTDOMAIN: {
      const { mainRootdomain } = action.payload;
      return {
        ...state,
        mainRootdomain,
      };
    }

    default:
      return state;
  }
};

export default catalogTrackerReducer;
