import React from "react";
import PropTypes from "prop-types";

import styles from "./title-small.scss";

const TitleSmall = (props) => {
  return (
    <div id={props.id} className={styles.wrapper}>
      <img src={props.iconUrl} />
      <h1>{props.title}</h1>
    </div>
  );
};

TitleSmall.propTypes = {
  iconUrl: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
};

export default TitleSmall;
