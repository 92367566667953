import React from "react";

const HeaderContainer = ({ children }) => {
  return (
    <div style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.10)" }}>
      {children}
    </div>
  );
};

export default HeaderContainer;
