import React from "react";

const CenteredTitle = ({ children }) => {
  return (
    <div style={{ width: "100%", height: "50px" }}>
      <div
        style={{
          width: "min-content",
          height: "min-content",
          color: "#021426",
          fontSize: "16px",
          textAlign: "center",
          fontWeight: 700,
          whiteSpace: "nowrap",
          margin: "auto",
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default CenteredTitle;
