import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubspaceProvider, ParentSpaceProvider } from "react-redux-subspace";
import styles from "./product-360.scss";
import Product360Service from "../../services/product360Service";
import SkuDetails from "../../containers/product-360/components/sku-details/sku-details";
import FamilyVariantsTable from "../../containers/product-360/components/family-variants-table/family-variants-table";
import KpiComparison from "../../containers/product-360/components/kpi-comparison/kpi-comparison";
import SellerTypeQuickFilter from "../../mb_components/mb-quick-filter/seller-type-quick-filter";
import { forceSingleRetailerSelection } from "../../utils/other/mbDataUtils";
import SkuPromotions from "../../containers/product-360/components/sku-promotions/sku-promotions";
import { MARKET_SHARE_VIEW_TYPE_DATA } from "../../containers/market-share/constants";
import SimilarSkusTable from "../../containers/product-360/components/similar-skus/similar-skus-table/similar-skus-table";
import SkuTabs from "../../containers/product-360/components/sku-tabs/sku-tabs";
import TabContentContainer from "../../containers/product-360/components/sku-tabs/tab-content-container";
import * as Decorators from "../../styles/cell-decorators";
import SimilarSkus from "../../containers/product-360/components/similar-skus/similar-skus";
import _ from "lodash";
import MbMarketShareWarningContainer from "../../mb_components/mb-market-share-warning/mb-market-share-warning-container";
import SimilarSkusGraphsContainer from "../../containers/product-360/components/similar-skus/similar-skus-graphs-container/similar-skus-graphs-container";
import SearchTermsTable from "../../containers/product-360/components/seo-analysis/search-terms-table/search-terms-table";
import SearchTerms from "../../containers/product-360/components/seo-analysis/search-terms";
import SearchTermsGraph from "../../containers/product-360/components/seo-analysis/search-terms-graph/search-terms-graph";
import AdsSpendGraph from "../../containers/product-360/components/seo-analysis/ads-spend-graph/ads-spend-graph";
import getLastPredictionDate from "../../utils/skus/getLastPredictionDate";
import InsightsTemplateBackButton from "../insights-template-back-button/insights-template-back-button";

import {
  PIVOTS,
  PRODUCT_360_VIEW_TYPE_DATA,
  TAB_NAMES,
} from "../../containers/product-360/constants";

import {
  applyInitialState,
  setSelectedSku,
} from "../../containers/product-360/actions";

export const product360Service = new Product360Service();

function Product360({
  selectedSku,
  setSelectedSku,
  selectedDateRange,
  domainsMetadata,
  filterDefinitions,
  productAttributes,
  viewsConfigsList,
  applyInitialState,
  loadedInitialView,
  parentRedirect = false,
}) {
  const viewTypeData = PRODUCT_360_VIEW_TYPE_DATA;
  const parentViewTypeData = MARKET_SHARE_VIEW_TYPE_DATA;

  const currentSelectedRetailer = forceSingleRetailerSelection(
    filterDefinitions,
    productAttributes
  );

  const lastAvailableDate = getLastPredictionDate(
    domainsMetadata,
    currentSelectedRetailer,
    productAttributes
  );

  const forceLoading = !loadedInitialView;

  const Section = ({ children }) => {
    return <div className={styles.sectionWrapper}>{children}</div>;
  };

  const SectionHeader = ({ children }) => {
    return <div className={styles.sectionHeader}>{children}</div>;
  };

  const salesGraphConfig = [
    {
      id: "gmv",
      title: "Sales Dollars",
      decorator: Decorators.priceDecorator,
      yTickFormatter: Decorators.chartTickPriceFormat,
      pivot: PIVOTS.gmv,
    },
    {
      id: "units",
      title: "Units Sold",
      decorator: Decorators.salesDecorator("sales"),
      yTickFormatter: Decorators.chartTickNumberFormat,
      pivot: PIVOTS.unitsSold,
    },
  ];

  const kpisGraphConfig = [
    {
      id: "promoEvents",
      title: "Promo Events",
      decorator: Decorators.roundNumberDecorator,
      yTickFormatter: Decorators.chartTickNumberFormat,
      pivot: PIVOTS.promoEvents,
    },
    {
      id: "promoGmvShare",
      title: "Promo Sales Dollars Share",
      decorator: Decorators.percentDecorator,
      yTickFormatter: Decorators.chartTickPercentFormat,
      pivot: PIVOTS.promoGmvShare,
      isPercentage: true,
    },
    {
      id: "asp",
      title: "Avg Selling Price",
      decorator: Decorators.priceDecorator,
      yTickFormatter: Decorators.chartTickPriceFormat,
      pivot: PIVOTS.averageSellingPrice,
    },
    {
      id: "inStockRate",
      title: "In Stock Rate",
      decorator: Decorators.percentDecorator,
      yTickFormatter: Decorators.chartTickPercentFormat,
      pivot: PIVOTS.inStockRate,
      isPercentage: true,
    },
  ];

  const chartsConfigs = {
    salesChart: salesGraphConfig,
    kpisChart: kpisGraphConfig,
  };

  const header = (
    <Section>
      <SkuDetails />
    </Section>
  );

  const similarProductsTab = (
    <div className={styles.mainWrapper}>
      <Section>
        <SectionHeader>Comparing sales to similar products</SectionHeader>
        <SimilarSkus forceLoading={forceLoading} chartsConfigs={chartsConfigs}>
          {(selectedKeys, setSelectedKeys, entities) => (
            <>
              {!(selectedKeys?.length > 0) && <MbMarketShareWarningContainer />}
              <SimilarSkusTable
                forceLoading={forceLoading}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
              />
              {selectedKeys?.length > 0 && (
                <SimilarSkusGraphsContainer
                  forceLoading={forceLoading}
                  entities={entities}
                  chartsConfigs={chartsConfigs}
                />
              )}
            </>
          )}
        </SimilarSkus>
      </Section>
    </div>
  );

  const skuSalesTab = (
    <div className={styles.mainWrapper}>
      <Section>
        <SectionHeader>Top 5 Family Variants</SectionHeader>
        <FamilyVariantsTable forceLoading={forceLoading} />
      </Section>
      <Section>
        <KpiComparison
          forceLoading={forceLoading}
          barPivot={PIVOTS.gmv}
          linePivot={PIVOTS.averageSellingPrice}
        />
      </Section>
      <Section>
        <KpiComparison
          forceLoading={forceLoading}
          barPivot={PIVOTS.gmv}
          linePivot={PIVOTS.inStockRate}
        />
      </Section>
      <Section>
        <SectionHeader>Comparing Sales to Promotional Activities</SectionHeader>
        <SkuPromotions forceLoading={forceLoading} />
      </Section>
    </div>
  );

  const seoTab = (
    <div className={styles.mainWrapper}>
      <Section>
        <SearchTerms>
          {(selectedTerm, setSelectedTerm) => (
            <>
              <SearchTermsTable
                forceLoading={forceLoading}
                selectedTerm={selectedTerm}
                setSelectedTerm={setSelectedTerm}
              />
              <SearchTermsGraph selectedTerm={selectedTerm} />
              {currentSelectedRetailer == "wayfair.com" && (
                <AdsSpendGraph forceLoading={forceLoading} />
              )}
            </>
          )}
        </SearchTerms>
      </Section>
    </div>
  );

  const tabsConfig = [
    {
      id: 1,
      name: TAB_NAMES.skuSales,
      component: skuSalesTab,
    },
    {
      id: 2,
      name: TAB_NAMES.seoAnalysis,
      component: seoTab,
      hideQuickFilter: true,
    },
    {
      id: 3,
      name: TAB_NAMES.similarSkus,
      component: similarProductsTab,
    },
  ];

  const body = (
    <ParentSpaceProvider>
      <TabContentContainer header={header} tabsConfig={tabsConfig} />
    </ParentSpaceProvider>
  );

  const BottomRowLeftComponent = ({}) => {
    return (
      <div className={styles.bottomRowLeft}>
        <SkuTabs tabsConfig={tabsConfig} />
        <SellerTypeQuickFilter tabsConfig={tabsConfig} />
      </div>
    );
  };

  const dataControlsOptions = {
    viewTypeData,
    lastAvailableDate,
    dateRange: selectedDateRange,
    maxDate: lastAvailableDate,
    mbFilter: true,
    apiProvider: product360Service,
    hideDate: false,
    hideFrequency: true,
    hideAlert: true,
    hideRetailer: true,
    hideExport: true,
    addDatesToUrl: true,
    hideCompareDates: false,
    dataAvailableUntilLabel: "Product data available until",
    dataUnavailableLabel: "Product data is unavailable",
    BottomRowLeftComponent: <BottomRowLeftComponent />,
  };

  return (
    <SubspaceProvider
      mapState={(state) => state.product360}
      namespace="product360"
    >
      <InsightsTemplateBackButton
        dataControlsOptions={dataControlsOptions}
        viewTypeData={viewTypeData}
        parentViewTypeData={parentViewTypeData}
        parentRedirect={parentRedirect}
        pageTitle={"Product 360"}
        setSelectedSku={setSelectedSku}
        selectedSku={selectedSku}
        apiProvider={product360Service}
        body={body}
        hideInsightsFilter={true}
        applyInitialState={applyInitialState}
      />
    </SubspaceProvider>
  );
}

export const mapStateToProps = (state) => {
  const {
    selectedDateRange,
    selectedPreviousDateRange,
    selectedCompetitor,
    comparePeriod,
  } = state.product360.insights.mbFilterRow;

  const { domainsMetadata } =
    state.product360.insights.insights.viewDefinitions?.viewMetadata ?? {};

  return {
    selectedDateRange,
    selectedPreviousDateRange,
    selectedCompetitor,
    comparePeriod,
    domainsMetadata,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    filterDefinitions:
      state.product360.insights.insights.viewDefinitions.filterDefinitions,
    viewsConfigsList: state.product360.insights.insights.viewsConfigsList,
    loadedInitialView: state.product360.insights.insights.loadedInitialView,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedSku,
      applyInitialState,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Product360);
