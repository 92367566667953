import React from "react";
import InsightsBoardService from "../../services/insightsBoardService";
const insightsBoardService = new InsightsBoardService();

import SalesAndShareTableComponent from "./mb-components/tables/sales-and-share-table-component";
import AverageListedPriceTableComponent from "./mb-components/tables/avg-listed-price-table-component";
import AverageSellingPriceTableComponent from "./mb-components/tables/avg-selling-price-table-component";
import CategoryAssortmentTableComponent from "./mb-components/tables/category-assortment-table-component";
import CategoryInStockRateTableComponent from "./mb-components/tables/category-in-stock-rates-table-component";
import CategoryCustomerPanelComponent from "./mb-components/category-customer-panel-component/category-customer-panel-component";
import MarketShareChartComponent from "./mb-components/charts/market-share-chart-component";
import CategoriesPricingChartComponent from "./mb-components/charts/categories-pricing-chart-component";
import CustomerPricingChartComponent from "./mb-components/charts/customer-pricing-chart-component";
import CustomerInStockRateChartComponent from "./mb-components/charts/customer-in-stock-rate-chart-component";
import CategoriesInStockRateChartComponent from "./mb-components/charts/categories-in-stock-rate-chart-component";
import CategoriesActiveSellingSkusChartComponent from "./mb-components/charts/categories-active-selling-skus-chart-component";
import CustomerActiveSellingSkusChartComponent from "./mb-components/charts/customer-active-selling-skus-chart-component";
import MarketSharePanelComponent from "../market-share/components/panel/market-share-panel-component";
import CustomerTotalRevenueChartComponent from "./mb-components/charts/customer-total-revenue-chart-component";
import CategoriesTotalRevenueChartComponent from "./mb-components/charts/categories-total-revenue-chart-component copy";
import InsightsBoardMarketShareAnalysisButton from "./market-share-analysis-button";

const chartComponentFail = (error) => {
  return {
    statusCode: error.response?.status,
    componentName: error.componentName,
    message: error.response?.data?.Message,
  };
};

const InsightsBoardConfig = {
  components: [
    {
      name: "Panel",
      component: CategoryCustomerPanelComponent,
      actions: {
        fetch: [
          insightsBoardService.fetchCustomerPerformanceSummary,
          insightsBoardService.fetchCategoriesPerformanceSummary,
        ],
        success: [
          (response) => {
            return {
              customer: response,
            };
          },
          (response) => {
            return {
              category: response,
            };
          },
        ],
      },
      options: {},
    },
    {
      name: "CustomerTotalRevenue",
      component: CustomerTotalRevenueChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCustomerTotalRevenue],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "CategoriesTotalRevenue",
      component: CategoriesTotalRevenueChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCategoriesTotalRevenue],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "MarketShare",
      component: MarketShareChartComponent,
      summaryWidget: <InsightsBoardMarketShareAnalysisButton />,
      actions: {
        fetch: [insightsBoardService.fetchMarketShare],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "CategoriesPricing",
      component: CategoriesPricingChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCategoriesPricing],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "CustomerPricing",
      component: CustomerPricingChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCustomerPricing],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "CategoriesActiveSellingSkus",
      component: CategoriesActiveSellingSkusChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCategoriesActiveSellingSkus],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "CustomerActiveSellingSkus",
      component: CustomerActiveSellingSkusChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCustomerActiveSellingSkus],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "CategoriesInStockRate",
      component: CategoriesInStockRateChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCategoriesInStockRates],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "CustomerInStockRate",
      component: CustomerInStockRateChartComponent,
      actions: {
        fetch: [insightsBoardService.fetchCustomerInStockRates],
        success: "",
        fail: [chartComponentFail],
      },
      options: {},
    },
    {
      name: "SalesAndShare",
      component: SalesAndShareTableComponent,
      actions: {
        fetch: [insightsBoardService.fetchSalesAndShare],
        exportCsv: insightsBoardService.exportSalesAndShare,
      },
      options: {},
    },
    {
      name: "AverageListedPrice",
      component: AverageListedPriceTableComponent,
      actions: {
        fetch: [insightsBoardService.fetchAverageListedPrice],
        exportCsv: insightsBoardService.exportAverageListedPrice,
      },
      options: {},
    },
    {
      name: "AverageSellingPrice",
      component: AverageSellingPriceTableComponent,
      actions: {
        fetch: [insightsBoardService.fetchAverageSellingPrice],
        exportCsv: insightsBoardService.exportAverageSellingPrice,
      },
      options: {},
    },
    {
      name: "CategoryAssortment",
      component: CategoryAssortmentTableComponent,
      actions: {
        fetch: [insightsBoardService.fetchCategoryAssortment],
        exportCsv: insightsBoardService.exportCategoryAssortment,
      },
      options: {},
    },
    {
      name: "CategoryInStockRate",
      component: CategoryInStockRateTableComponent,
      actions: {
        fetch: [insightsBoardService.fetchCategoryInStockRate],
        exportCsv: insightsBoardService.exportCategoryInStockRate,
      },
      options: {},
    },
  ],
  designPlan: [
    "Panel",
    ["MarketShare"],
    ["CategoriesTotalRevenue", "CustomerTotalRevenue"],
    ["CategoriesPricing", "CustomerPricing"],
    ["CategoriesActiveSellingSkus", "CustomerActiveSellingSkus"],
    ["CategoriesInStockRate", "CustomerInStockRate"],
    "SalesAndShare",
    "AverageSellingPrice",
    "AverageListedPrice",
    "CategoryAssortment",
    "CategoryInStockRate",
  ],
};

export const MarketShareConfig = {
  components: [
    {
      name: "Panel",
      component: MarketSharePanelComponent,
      actions: {
        fetch: [insightsBoardService.fetchCategoriesPerformanceSummary],
        success: [
          (response) => {
            return {
              category: response,
            };
          },
        ],
      },
      options: {},
    },
  ],
  designPlan: ["Panel"],
};

export default InsightsBoardConfig;
