import React from "react";

function DropdownHeaderSearchable(props) {
  return (
    <>
      <input
        autoFocus
        onChange={props.onChangeHandler}
        placeholder="Search..."
        value={props.filterValue}
      />
    </>
  );
}

export default DropdownHeaderSearchable;
