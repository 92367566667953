import {
  CHANGE_FIELD_LOGIN,
  CHANGE_FIELD_ERROR_STATUS_LOGIN,
  TRY_SUBMIT_LOGIN,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
} from "../constants";

import AuthService from "../../../services/authService";
import utils from "../../../utils";
import history from "../../../history";

import {
  analyticsSetUser,
  analyticsLoginSuccess,
  analyticsModuleLoad,
} from "../../shared/analytics/actions";

const { setToken, setDefaultAuthHeader } = utils.auth;

const authService = new AuthService();

export const changeField = (field, value) => ({
  type: CHANGE_FIELD_LOGIN,
  payload: { field, value },
});

export const changeFieldErrorStatus = (field, value) => ({
  type: CHANGE_FIELD_ERROR_STATUS_LOGIN,
  payload: { field, value },
});

export const trySubmitted = () => ({
  type: TRY_SUBMIT_LOGIN,
});

export const authRequested = () => ({
  type: LOGIN_REQUEST,
});

export const authSucceed = () => ({
  type: LOGIN_SUCCESS,
});

export const authError = (error) => ({
  type: LOGIN_FAILURE,
  payload: { error },
});

export const supportPortalAuth = () => {
  const requiredParams = ["return_to", "need_login"];
  const params = new URLSearchParams(window.location.search);
  if (requiredParams.every((x) => params.has(x))) {
    authService
      .requestSupportPortalAuth({ returnTo: params.get("return_to") })
      .then((res) => {
        const { link } = res.data;
        if (link) window.location.href = link;
      });
  }
};

export const sendForm = (data) => (dispatch) => {
  dispatch(authRequested());

  authService
    .sendData(data)
    .then((res) => {
      const token = res.data;
      setToken(token);
      setDefaultAuthHeader();
      dispatch(authSucceed());
      analyticsSetUser(data.user);
      analyticsLoginSuccess();
      supportPortalAuth();
      history.push("/");
    })
    .catch((err) => {
      dispatch(authError(err.message));
    });
};

export const thirdPartyAuthenticate = (data) => (dispatch) => {
  authService
    .requestThirdPartyAuth(data)
    .then((res) => {
      setToken(res.data);
      setDefaultAuthHeader();
      history.push("/");
      dispatch(authSucceed());
      analyticsSetUser(data.user);
      analyticsLoginSuccess();
    })
    .catch((err) => {
      dispatch(authError(err.message));
    });
};
