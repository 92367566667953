import React from "react";
import styles from "./styles.scss";
import ScrollbarWrapper from "../../../components/scrollbar-wrapper";
import Feedback from "../../../mb_components/mb-feedback";

export default function AiSidebarBody(props) {
  const { text } = props;

  return (
    <>
      <ScrollbarWrapper>
        <div className={styles.text}>{text}</div>
      </ScrollbarWrapper>
      <div className={styles.separator} />
      <Feedback {...props} />
    </>
  );
}
