import React, { useState, useEffect } from "react";
import styles from "./market-trends-buttons.scss";
import TopGainers from "../../images/top-gainers-black.svg";
import TopGainersSelected from "../../images/top-gainers-blue.svg";
import TopLosers from "../../images/top-losers-black.svg";
import TopLosersSelected from "../../images/top-losers-blue.svg";
import TopSellers from "../../images/top-sellers-black.svg";
import TopSellersSelected from "../../images/top-sellers-blue.svg";
import TopTrending from "../../images/top-trending-black.svg";
import TopTrendingSelected from "../../images/top-trending-blue.svg";
import Custom from "../../images/custom-black.svg";
import CustomSelected from "../../images/custom-blue.svg";
import OnPromo from "../../images/on-promo-black.svg";
import OnPromoSelected from "../../images/on-promo-blue.svg";
import Sponsored from "../../images/sponsored.svg";
import SponsoredSelected from "../../images/sponsored-blue.svg";
import DigitalContent from "../../images/digital-content.svg";
import DigitalContentSelected from "../../images/digital-content-blue.svg";
import OOS from "../../images/oos-black.svg";
import OOSSelected from "../../images/oos-blue.svg";
import RetailerMargins from "../../images/retailer-margins-black.svg";
import RetailerMarginsSelected from "../../images/retailer-margins-blue.svg";
import Unlisted from "../../images/unlisted-black.svg";
import UnlistedSelected from "../../images/unlisted-blue.svg";
import AllSkus from "../../images/all-skus-black.svg";
import AllSkusSelected from "../../images/all-skus-blue.svg";
import RatingsStar from "../../images/rating-star.svg";
import RatingsStarSelected from "../../images/rating-star-blue.svg";
import AverageRating from "../../images/average-rating-black.svg";
import AverageRatingSelected from "../../images/average-rating-blue.svg";
import StoreFront from "../../images/storefront-black.svg";
import StoreFrontSelected from "../../images/storefront-blue.svg";
import PriceIndex from "../../images/price-index.svg";
import PriceIndexSelected from "../../images/price-index-blue.svg";
import PriceDropsSelected from "../../images/price-drops-blue.svg";
import PriceDrops from "../../images/price-drops-black.svg";
import AmazonAPlus from "../../images/amazon-aplus.svg";
import AmazonAPlusSelected from "../../images/amazon-aplus-blue.svg";

import { analyticsMarketTrendSelected } from "../../containers/shared/analytics/actions";
import MarketTrendToggled from "./market-trend-toggled";
import { CATALOG_TRACKER_VIEW_TYPE_DATA } from "../../containers/catalog-tracker/constants";

export default function MarketTrendsButtons(props) {
  const {
    marketTrendsConfigs,
    onChangeTrendCallback,
    currentViewConfigQuery,
    viewName,
    viewTypeId,
    clearFiltersSelected,
    updateFiltersSelected,
    insightsFilterShowSidebar,
    mySkusSelected,
  } = props;

  const [selectedTrend, setSelectedTrend] = useState("");

  // Used to track when state changed from here or from sorting the table itself.
  const [selectedSortOrder, setSelectedSortOrder] = useState("");
  const [selectedSortField, setSelectedSortField] = useState("");
  const [internalChange, setInternalChange] = useState(false);
  const [selectedFrequency, setSelectedFrequency] = useState(
    currentViewConfigQuery.queryFrequency
  );

  const getIconByTrend = (trendName, selected) => {
    switch (trendName) {
      case "In Store":
        return !selected ? StoreFront : StoreFrontSelected;
      case "Average Rating":
        return !selected ? AverageRating : AverageRatingSelected;
      case "Top Trending":
        return !selected ? TopTrending : TopTrendingSelected;
      case "Top Sellers":
      case "Top Ranks":
        return !selected ? TopSellers : TopSellersSelected;
      case "Top Gainers":
        return !selected ? TopGainers : TopGainersSelected;
      case "Top Losers":
        return !selected ? TopLosers : TopLosersSelected;
      case "Full Catalog":
        return !selected ? AllSkus : AllSkusSelected;
      case "Out of Stock":
        return !selected ? OOS : OOSSelected;
      case "Unlisted":
        return !selected ? Unlisted : UnlistedSelected;
      case "Price Drops":
      case "Price":
      case "Average Price":
        return !selected ? PriceDrops : PriceDropsSelected;
      case "Retailer Margins":
        return !selected ? RetailerMargins : RetailerMarginsSelected;
      case "On Promo":
        return !selected ? OnPromo : OnPromoSelected;
      case "Sponsored":
        return !selected ? Sponsored : SponsoredSelected;
      case "Digital Content":
        return !selected ? DigitalContent : DigitalContentSelected;
      case "Custom":
        return !selected ? Custom : CustomSelected;
      case "Ratings":
      case "Rating Drops":
        return !selected ? RatingsStar : RatingsStarSelected;
      case "Price Index":
        return !selected ? PriceIndex : PriceIndexSelected;
      case "Amazon A+":
        return !selected ? AmazonAPlus : AmazonAPlusSelected;
      default:
        return null;
    }
  };

  const handleClickTrend = (config) => {
    if (config.trendName !== "Custom") {
      analyticsMarketTrendSelected(
        viewTypeId,
        config.trendName,
        mySkusSelected
      );
      onChangeTrendCallback(config);
      setInternalChange(true);
      setSelectedTrend(config.trendName);
      setSelectedSortOrder(config.overridingViewQuery.sortOrder);
      setSelectedSortField(config.overridingViewQuery.sortField);
      setSelectedFrequency(
        config.overridingViewQuery.queryFrequency ?? selectedFrequency
      );
    } else {
      onChangeTrendCallback(config);
      setSelectedTrend(config.trendName);
    }
  };

  const getMatchingSortConfig = () => {
    const matchingSortConfig = marketTrendsConfigs.filter(
      (item) =>
        item.overridingViewQuery.sortField ===
          currentViewConfigQuery.sortField &&
        item.overridingViewQuery.sortOrder === currentViewConfigQuery.sortOrder
    );
    return matchingSortConfig;
  };

  const setSortingStates = (config) => {
    setSelectedTrend(config.trendName);
    setSelectedSortField(config.overridingViewQuery.sortField);
    setSelectedSortOrder(config.overridingViewQuery.sortOrder);
  };

  useEffect(() => {
    setSelectedTrend("Custom");
  }, [updateFiltersSelected]);

  useEffect(() => {
    if (!insightsFilterShowSidebar) {
      setSelectedTrend("");
    }
  }, [clearFiltersSelected]);

  useEffect(() => {
    if (
      insightsFilterShowSidebar &&
      viewTypeId !== CATALOG_TRACKER_VIEW_TYPE_DATA.id
    ) {
      setSelectedTrend("Custom");
    }
  }, [insightsFilterShowSidebar]);

  useEffect(() => {
    const matchingSortConfig = getMatchingSortConfig();
    if (viewTypeId === 3 && matchingSortConfig.length > 0) {
      setSortingStates(matchingSortConfig[0]);
      setInternalChange(false);
      return;
    }
    if (
      !internalChange &&
      selectedTrend !== "Custom" &&
      !(currentViewConfigQuery.queryFrequency !== selectedFrequency) &&
      (selectedSortField !== currentViewConfigQuery.sortField ||
        selectedSortOrder !== currentViewConfigQuery.sortOrder)
    ) {
      setSelectedTrend("");
    }
    setInternalChange(false);
    setSelectedFrequency(currentViewConfigQuery.queryFrequency);
  }, [currentViewConfigQuery.sortField, currentViewConfigQuery.sortOrder]);

  useEffect(() => {
    if (viewTypeId === 3) {
      setSelectedTrend("");
    }
    if (viewTypeId === CATALOG_TRACKER_VIEW_TYPE_DATA.id) {
      setSelectedTrend("Price");
    } else {
      setSelectedTrend("Custom");
    }
  }, [viewName]);

  useEffect(() => {
    const matchingSortConfig = getMatchingSortConfig();
    if (viewTypeId === 3 && matchingSortConfig.length > 0) {
      setSortingStates(matchingSortConfig[0]);
      setInternalChange(false);
    }
    if (viewTypeId === 1) {
      setSelectedTrend("Full Catalog");
    }
    if (viewTypeId === CATALOG_TRACKER_VIEW_TYPE_DATA.id) {
      var trendConfig = {
        overridingViewQuery: marketTrendsConfigs.find(
          (item) => item.trendName === "Average Price"
        )?.nonDefaultOverridingViewQuery,
        trendName: "Average Price",
      };
      setSelectedTrend("Average Price");
      handleClickTrend(trendConfig);
    }
  }, []);

  const trendButtons = () => {
    return (
      <>
        {marketTrendsConfigs?.map((config, index) =>
          !config.isToggled ? (
            <div
              className={`${styles.trendButton} ${
                selectedTrend === config.trendName ? styles.selectedTrend : ""
              }`}
              key={`${index}-${config.trendName}`}
              onClick={() => handleClickTrend(config)}
            >
              <img
                src={getIconByTrend(
                  config.trendName,
                  selectedTrend === config.trendName
                )}
              />
              <span>{config.trendName}</span>
            </div>
          ) : (
            <MarketTrendToggled
              key={`${index}-${config.trendName}`}
              handleClickTrend={handleClickTrend}
              config={config}
              trend={selectedTrend}
              viewTypeId={viewTypeId}
            />
          )
        )}
      </>
    );
  };

  return (
    <div className={styles.marketTrendsButtonsWrapper}>{trendButtons()}</div>
  );
}
