import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { clearLevelOne, clearLevelTwo } from "../actions";

import styles from "./insights-breadcrumbs.scss";

class InsightsBreadcrumbs extends Component {
  getLinks = (activeCategory, level1ActiveCategory) => {
    if (activeCategory && level1ActiveCategory) {
      return (
        <Fragment>
          <div
            className={styles.item}
            onClick={() => {
              this.props.clearLevelOne();
            }}
          >
            ../
          </div>
          <div
            className={styles.item}
            onClick={() => {
              this.props.clearLevelTwo();
            }}
          >
            {activeCategory}
          </div>
          <div className={styles.item}>&nbsp; > {level1ActiveCategory}</div>
        </Fragment>
      );
    }

    if (activeCategory && !level1ActiveCategory) {
      return (
        <Fragment>
          <div
            className={styles.item}
            onClick={() => {
              this.props.clearLevelOne();
            }}
          >
            ../
          </div>
          <div className={styles.item}>{activeCategory}</div>
        </Fragment>
      );
    }

    return <div className={styles.item}>../</div>;
  };

  render() {
    const { activeCategory, level1ActiveCategory, loading } = this.props;

    const blockedLoader = loading ? (
      <div className={styles.blockedLoader}></div>
    ) : null;

    return (
      <div className={styles.wrapper}>
        {blockedLoader}
        {this.getLinks(activeCategory, level1ActiveCategory)}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    activeCategory: state.insights.insights.activeCategory,
    level1ActiveCategory:
      state.insights.insights.topCategoryLevel1.activeCategory,
    loading: state.insights.insights.loading,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearLevelOne,
      clearLevelTwo,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsBreadcrumbs);
