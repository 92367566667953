import setDefaultAuthHeader from "./auth/setDefaultAuthHeader";
import setToken from "./auth/setToken";
import getToken from "./auth/getToken";
import deleteToken from "./auth/deleteToken";

import dymanicPathFroImage from "./other/dymanicPathForImage";
import handleServerError from "./other/handleServerError";
import createDataForLineChart from "./other/createDataForLineChart";
import sortDataLineChart from "./other/sortDataLineChart";
import transfromDataLineChart from "./other/transfromDataLineChart";
import checkOwnProperty from "./other/checkOwnProperty";
import transformDataForHeadmap from "./other/transformDataForHeadmap";

import {
  generateQueryUrl,
  generateApiQueryUrlForInsightsSkus,
  generateQueryStringFromViewConfigQuery,
} from "./skus/generateQueryUrl";
import {
  generateQueryConfig,
  generateViewConfigQueryFromQueryString,
  generateViewConfigQueryFromEncodedJson,
} from "./skus/generateQueryConfig";
import getQueryParams from "./skus/getQueryParams";
import convertSortValue, {
  convertSortValue2,
  convertSortValueToColumn,
  convertSortValueAfterChangeFrequency,
  convertConfigSortFieldToBrowserString,
  convertBrowserStringSortFieldToConfig,
} from "./skus/convertSortValue";
import convertMarketShareTableSortValue, {
  convertMarketShareTableSortValue2,
  convertMarketShareTableSortValueToColumn,
  convertMarketShareTableConfigSortFieldToBrowserString,
  convertMarketShareTableBrowserStringSortFieldToConfig,
} from "./market-share/convertSortValue";
import convertUsersTableSortValue, {
  convertUsersTableSortValue2,
  convertUsersTableSortValueToColumn,
  convertUsersTableConfigSortFieldToBrowserString,
  convertUsersTableBrowserStringSortFieldToConfig,
} from "./administration/convertSortValue";
import getDefaultSkusConfig, {
  generateFieldsSkusConfig,
} from "./skus/getDefaultSkusConfig";
import getDefaultSort from "./skus/getDefaultSort";
import {
  getDefaultCompetitorName,
  getDefaultCompetitor,
} from "./skus/getDefaultCompetitor";
import getCurrentSkusField from "./skus/getCurrentSkusField";
import getSortOptions from "./skus/getSortOptions";
import {
  abbreviateNumber,
  deepClone,
  getPercent,
  percentsNumber,
  objectsEqual,
  getCurrencySignByDomain,
} from "./other/utilities";

import { ROOTDOMAINS } from "./other/constants";

export default {
  auth: {
    setToken,
    getToken,
    deleteToken,
    setDefaultAuthHeader,
  },
  other: {
    dymanicPathFroImage,
    handleServerError,
    createDataForLineChart,
    sortDataLineChart,
    transfromDataLineChart,
    checkOwnProperty,
    transformDataForHeadmap,
    abbreviateNumber,
    deepClone,
    getPercent,
    percentsNumber,
    ROOTDOMAINS,
    objectsEqual,
    getCurrencySignByDomain,
  },
  skus: {
    generateQueryUrl,
    generateApiQueryUrlForInsightsSkus,
    generateQueryStringFromViewConfigQuery,
    generateQueryConfig,
    generateViewConfigQueryFromQueryString,
    generateViewConfigQueryFromEncodedJson,
    convertBrowserStringSortFieldToConfig,
    convertSortValue,
    convertSortValue2,
    convertSortValueAfterChangeFrequency,
    convertSortValueToColumn,
    convertConfigSortFieldToBrowserString,

    getDefaultSort,
    getDefaultCompetitor,
    getDefaultCompetitorName,
    getCurrentSkusField,
    getSortOptions,
    getQueryParams,
    getDefaultSkusConfig,
    generateFieldsSkusConfig,
  },
  marketShare: {
    convertMarketShareTableSortValue,
    convertMarketShareTableSortValueToColumn,
    convertMarketShareTableSortValue2,
    convertMarketShareTableConfigSortFieldToBrowserString,
    convertMarketShareTableBrowserStringSortFieldToConfig,
  },
  rankingEvents: {
    convertMarketShareTableSortValue,
    convertMarketShareTableSortValueToColumn,
    convertMarketShareTableSortValue2,
    convertMarketShareTableConfigSortFieldToBrowserString,
    convertMarketShareTableBrowserStringSortFieldToConfig,
  },
  administration: {
    convertUsersTableSortValue,
    convertUsersTableSortValueToColumn,
    convertUsersTableSortValue2,
    convertUsersTableConfigSortFieldToBrowserString,
    convertUsersTableBrowserStringSortFieldToConfig,
  },
};
