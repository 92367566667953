import React, { useEffect, useState, useRef } from "react";
import styles from "./mb-react-date-range.scss";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./calendar-override.css";
import { Calendar } from "react-date-range";

export default function MbReactDateRange(props) {
  const {
    selectedDateRange,
    maxDate,
    minDate,
    onClickApply,
    viewTypeId,
    viewTypeIdsNonWeekly,
  } = props;

  const [startDate, setStartDate] = useState(
    new Date(Date.parse(selectedDateRange.startDate))
  );
  const [endDate, setEndDate] = useState(
    new Date(Date.parse(selectedDateRange.endDate))
  );
  const [calendarOpen, setCalendarOpen] = useState("from");

  const startInputRef = useRef(null);
  const endInputRef = useRef(null);

  const scroll = {
    enabled: true,
  };

  const isButtonDisabled = startDate === null || endDate === null;

  const formatDateToShow = (date) => {
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return date ? date.toLocaleDateString("en-US", dateOptions) : "";
  };

  const formatDateToUpdate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}/${month}/${day}`;
  };

  const handleStartDateClick = (selectedDate) => {
    setStartDate(selectedDate);
    endInputRef.current.focus();
    setCalendarOpen("to");
    if (selectedDate >= endDate) {
      setEndDate(null);
    }
  };

  const handleClickClear = () => {
    setStartDate(null);
    setEndDate(null);
    startInputRef.current.focus();
    setCalendarOpen("from");
  };

  const handleClickApply = () => {
    onClickApply({
      startDate: formatDateToUpdate(startDate),
      endDate: formatDateToUpdate(endDate),
      rolling: 0,
    });
  };

  const disableDates = (date, dayNumToAllow) => {
    if (viewTypeIdsNonWeekly.includes(viewTypeId)) {
      return false;
    }
    return date.getDay() !== dayNumToAllow;
  };

  useEffect(() => {
    startInputRef.current.focus();
  }, []);

  return (
    <div className={styles.customDatesWrapper}>
      <div className={styles.toFromInputsWrapper}>
        <input
          className={styles.fromDate}
          type="text"
          readOnly
          value={formatDateToShow(startDate)}
          placeholder="From date"
          onClick={() => setCalendarOpen("from")}
          ref={startInputRef}
        />
        <input
          className={styles.toDate}
          type="text"
          readOnly
          value={formatDateToShow(endDate)}
          placeholder="To date"
          onClick={() => setCalendarOpen("to")}
          ref={endInputRef}
        />
      </div>
      <div className={styles.calendarWrapper}>
        {calendarOpen == "from" && (
          <Calendar
            className={styles.calendarPicker}
            date={startDate}
            onChange={handleStartDateClick}
            scroll={scroll}
            editableDateInputs={true}
            showMonthAndYearPickers={false}
            showMonthArrow={false}
            disabledDay={(date) => disableDates(date, 1)}
            weekStartsOn={1}
            maxDate={maxDate}
            minDate={minDate || new Date(Date.parse("2021-06-01"))}
          />
        )}

        {calendarOpen == "to" && (
          <Calendar
            className={styles.calendarPicker}
            date={endDate}
            onChange={setEndDate}
            scroll={scroll}
            editableDateInputs={true}
            showMonthAndYearPickers={false}
            showMonthArrow={false}
            disabledDay={(date) => disableDates(date, 0)}
            weekStartsOn={1}
            maxDate={maxDate}
            minDate={startDate || new Date(Date.parse("2021-06-01"))}
          />
        )}
      </div>
      <div className={styles.calendarButtonWrappers}>
        <button className={styles.clearButton} onClick={handleClickClear}>
          CLEAR
        </button>
        <button
          className={styles.applyButton}
          onClick={handleClickApply}
          disabled={isButtonDisabled}
        >
          APPLY
        </button>
      </div>
    </div>
  );
}
