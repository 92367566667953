import React, { useRef, useState } from "react";
import styles from "./toast.scss";
import InfoIcon from "../../images/info.svg";
import ErrorIcon from "../../images/attention.svg";
import SuccessIcon from "../../images/checkmark.svg";

const Toast = ({ children }) => {
  const initialToastConfig = {
    open: false,
  };
  const [toastConfig, setToastConfig] = useState(initialToastConfig);
  const timeout = useRef();

  // variants: "success", "error", "info"
  const sendToast = (msg, variant = "info", duration = 3) => {
    if (timeout.current) clearTimeout(timeout.current);

    const newConfig = {
      open: true,
      msg,
      variant,
    };
    setToastConfig(newConfig);

    timeout.current = setTimeout(
      () =>
        setToastConfig({
          ...newConfig,
          open: false,
        }),
      duration * 1000
    );
  };

  const getVariantClass = (v) => {
    switch (v) {
      case "info":
        return styles.variantInfo;
      case "success":
        return styles.variantSuccess;
      case "error":
        return styles.variantError;
      default:
        return "";
    }
  };

  const getVariantIcon = (v) => {
    switch (v) {
      case "info":
        return InfoIcon;
      case "success":
        return SuccessIcon;
      case "error":
        return ErrorIcon;
      default:
        return null;
    }
  };

  return (
    <div className={styles.toastContainer}>
      {children(sendToast)}
      <div
        className={`${
          toastConfig.open ? styles.toast : styles.toastHidden
        } ${getVariantClass(toastConfig.variant)}`}
      >
        <img src={getVariantIcon(toastConfig.variant)} /> {toastConfig.msg}
      </div>
    </div>
  );
};

export default Toast;
