import { ParentSize } from "@visx/responsive";
import React from "react";
import BarChart from "./bar-chart.js";
import styles from "./bar-chart.scss";

export default function BarChartWrapper(props) {
  const {
    id,
    data,
    xDomain,
    xKey,
    xTickFormat,
    yFormatter,
    graphTitle,
    dataKeys,
    curveKeysToHoverNames,
    reverseKeys,
  } = props;
  return (
    <div className={styles.parentSizeWrapper}>
      <ParentSize>
        {({ width, height }) => (
          <BarChart
            id={id}
            width={width}
            height={height}
            data1={data}
            xDomain={xDomain}
            xKey={xKey}
            xTickFormat={xTickFormat}
            yFormatter={yFormatter}
            graphTitle={graphTitle}
            dataKeys={dataKeys}
            curveKeysToHoverNames={curveKeysToHoverNames}
            reverseKeys={reverseKeys}
          />
        )}
      </ParentSize>
    </div>
  );
}
