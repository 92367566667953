import moment from "moment";

import {
  SET_SELECTED_COMPETITORS,
  SET_SELECTED_TIME_FREQUENCY,
  SET_SELECTED_DATE_RANGE,
  SET_MAX_DATE,
  SET_CHOSEN_PERIOD,
  SET_COMPARE_PERIOD,
  SET_PREVIOUS_SELECTED_DATE_RANGE,
} from "./constants";

const initialState = {
  selectedDateRange: {
    startDate: moment()
      .startOf("isoWeek")
      .subtract(4, "weeks")
      .format("YYYY/MM/DD"),
    endDate: moment().format("YYYY/MM/DD"),
  },
  rolling: 0,
  chosenPeriod: "Last 4 weeks",
  comparePeriod: "PreviousPeriod",
  selectedPreviousDateRange: {
    previousStartDate: moment()
      .startOf("isoWeek")
      .subtract(8 * 7, "days")
      .endOf("days")
      .format("YYYY/MM/DD"),
    previousEndDate: moment()
      .startOf("isoWeek")
      .subtract(4 * 7 + 1, "days")
      .endOf("days")
      .format("YYYY/MM/DD"),
  },
  selectedCompetitor: {},
  selectedTimeFrequency: 1,
};

const MbFilterRowReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COMPETITORS: {
      const { data } = action.payload;
      return {
        ...state,
        selectedCompetitor: data,
      };
    }

    case SET_SELECTED_TIME_FREQUENCY: {
      const { data } = action.payload;

      return {
        ...state,
        selectedTimeFrequency: data,
      };
    }

    case SET_SELECTED_DATE_RANGE: {
      const { data } = action.payload;
      return {
        ...state,
        selectedDateRange: {
          startDate: data.startDate,
          endDate: data.endDate,
        },
        rolling: data.rolling,
      };
    }

    case SET_MAX_DATE: {
      const { data } = action.payload;
      return {
        ...state,
        selectedDateRange: {
          startDate: moment(data)
            .subtract(3, "weeks")
            .startOf("isoWeek")
            .format("YYYY/MM/DD"),
          endDate: moment(data).format("YYYY/MM/DD"),
        },
        selectedPreviousDateRange: {
          previousStartDate: moment(data)
            .subtract(8 * 7 - 1, "days")
            .endOf("days")
            .format("YYYY/MM/DD"),
          previousEndDate: moment(data)
            .subtract(4 * 7, "days")
            .endOf("days")
            .format("YYYY/MM/DD"),
        },
      };
    }

    case SET_CHOSEN_PERIOD: {
      const { data } = action.payload;
      return {
        ...state,
        chosenPeriod: data,
      };
    }

    case SET_COMPARE_PERIOD: {
      const { data } = action.payload;
      return {
        ...state,
        comparePeriod: data,
      };
    }

    case SET_PREVIOUS_SELECTED_DATE_RANGE: {
      const { data } = action.payload;
      return {
        ...state,
        selectedPreviousDateRange: {
          previousStartDate: data.previousStartDate,
          previousEndDate: data.previousEndDate,
        },
      };
    }
  }
  return state;
};

export default MbFilterRowReducer;
