import React, { useState, useEffect, useRef } from "react";
import styles from "./market-trends-buttons.scss";
import chevron from "../../images/chevron.svg";
import blueChevron from "../../images/blue-chevron.svg";

import RankDropsSelected from "../../images/top-losers-blue.svg";
import RankDrops from "../../images/top-losers-black.svg";
import RankGainsSelected from "../../images/rank-gains-blue.svg";
import RankGains from "../../images/rank-gains.svg";

import VisibilityDropsSelected from "../../images/visibility-drops-blue.svg";
import VisibilityDrops from "../../images/visibility-drops.svg";
import VisibilityGainsSelected from "../../images/visibility-gains-blue.svg";
import VisibilityGains from "../../images/visibility-gains.svg";

import PriceDropsSelected from "../../images/price-drops-blue.svg";
import PriceDrops from "../../images/price-drops-black.svg";
import PriceGainsSelected from "../../images/price-gains-blue.svg";
import PriceGains from "../../images/price-gains.svg";

export default function MarketTrendToggled(props) {
  const { trend, config, handleClickTrend, viewTypeId } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [selectedType, setSelectedType] = useState(config.defaultToggleType);
  const [selectedTrend, setSelectedTrend] = useState(trend);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setSelectedTrend(trend);
  }, [trend]);

  useEffect(() => {
    if (viewTypeId === 9 && config.trendName == "Price") {
      setSelectedType("Gains");
    }
  }, []);

  const getIcon = () => {
    if (`${config.trendName} ${selectedType}` === selectedTrend) {
      switch (selectedTrend) {
        case "Price Drops":
          return PriceDropsSelected;
        case "Price Gains":
          return PriceGainsSelected;
        case "Visibility Drops":
          return VisibilityDropsSelected;
        case "Visibility Gains":
          return VisibilityGainsSelected;
        case "Rank Drops":
          return RankDropsSelected;
        case "Rank Gains":
          return RankGainsSelected;
      }
    } else {
      switch (config.trendName) {
        case "Price":
          return selectedType == "Gains" ? PriceGains : PriceDrops;
        case "Visibility":
          return selectedType == "Gains" ? VisibilityGains : VisibilityDrops;
        case "Rank":
          return selectedType == "Gains" ? RankGains : RankDrops;
      }
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target))
      setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleChooseOption = (option) => {
    const trendName = `${config.trendName} ${option}`;
    setSelectedTrend(trendName);
    setSelectedType(option);
    const overridingViewQuery =
      option === config.defaultToggleType
        ? config.overridingViewQuery
        : config.nonDefaultOverridingViewQuery;
    const configToUpdate = {
      trendName: trendName,
      overridingViewQuery: overridingViewQuery,
    };
    handleClickTrend(configToUpdate);
    setIsOpen(false);
  };

  const dropDownOptions = (
    <>
      {config.toggleOptions.map((item, index) => (
        <div
          className={styles.option}
          key={index}
          onClick={() => handleChooseOption(item)}
        >
          {item}
        </div>
      ))}
    </>
  );

  const isSelected = `${config.trendName} ${selectedType}` === selectedTrend;

  return (
    <div className={styles.marketTrendToggleWrapper} ref={dropdownRef}>
      <div
        className={`${styles.trendButton} ${
          isSelected ? styles.selectedTrend : ""
        }`}
        onClick={handleClick}
      >
        <img src={getIcon()} />
        <span>{`${config.trendName}: ${selectedType}`}</span>
        <img
          className={styles.chevron}
          src={isSelected ? blueChevron : chevron}
        />
      </div>
      {isOpen && (
        <div
          className={styles.optionsWrapper}
          style={{ width: dropdownRef.current?.offsetWidth }}
        >
          {dropDownOptions}
        </div>
      )}
    </div>
  );
}
