import moment from "moment";
import { abbreviateNumber } from "./utilities.js";

const sortDate = (a, b) =>
  moment(a.date, "MM-DD-YYYY") - moment(b.date, "MM-DD-YYYY");

const transfromDataLineChart = (
  clientData = [],
  matchedData = [],
  dataField = "data"
) => {
  let result = [];
  let formattedClienData = [];
  let formattedMatchedData = [];

  if (clientData?.length > 0) {
    formattedClienData = clientData.map((item) => {
      const formattedItem = { ...item };
      formattedItem.date = moment(item.date).format("MM-DD-YYYY");
      return formattedItem;
    });
  }

  if (matchedData?.length > 0) {
    formattedMatchedData = matchedData.map((item) => {
      const formattedItem = { ...item };
      formattedItem.date = moment(item.date).format("MM-DD-YYYY");
      return formattedItem;
    });
  }

  let sortedClients = formattedClienData
    ? formattedClienData.sort(sortDate)
    : null;
  let sortedMatched = formattedMatchedData
    ? formattedMatchedData.sort(sortDate)
    : null;

  if (sortedClients && !sortedMatched) {
    sortedClients.map((item) => {
      let data = {
        date: item.date,
        client: item[dataField],
      };

      result.push(data);
    });

    return result.sort(sortDate);
  }

  if (sortedMatched && !sortedClients) {
    sortedMatched.map((item) => {
      let data = {
        date: item.date,
        matched: item[dataField],
      };

      result.push(data);
    });

    return result.sort(sortDate);
  }

  if (!sortedClients && !sortedMatched) {
    return null;
  }

  sortedClients.map((item) => {
    let data = {
      date: item.date,
      client: item[dataField],
      matched: null,
    };

    let match = sortedMatched.find((itemInn) => item.date === itemInn.date);

    data = {
      ...data,
      matched: match ? match[dataField] : null,
    };

    result.push(data);
  });

  sortedMatched.map((item) => {
    let changedResult = null;
    let data = {
      date: item.date,
      client: null,
      matched: null,
    };

    let match = result.some((itemInn) => item.date === itemInn.date);

    if (!match) {
      changedResult = {
        ...data,
        matched: item[dataField],
      };
    }

    if (changedResult) {
      result.push(changedResult);
    }
  });

  return result.sort(sortDate);
};

export default transfromDataLineChart;
