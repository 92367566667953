import React, { useEffect, useState } from "react";
import styles from "./filter-value.scss";
import FilterItem, {
  getFilterConditionOptions,
} from "../../../../../mb-filter-sidebar/filter-item/filter-item";
import { EVENT_FILTER_VALUE_MB_NAME } from "../../../../consts";
import { uniqueId } from "lodash";
import {
  convertFilterSidebarToInsightsFilter,
  mergeInsightsFilterWithFilterDefinition,
  validateFilter,
} from "../../../../../mb-filter-sidebar/filter-conversions";
import NumericControls from "../../../../../mb-filter-sidebar/numeric-controls";
import { EVENT_FILTER_DECORATORS } from "../../../../../../containers/ranking-events/constants";
import ItemTooltip from "../../../../../../components/item-tooltip/item-tooltip";
import { NumericOptions } from "../../../../../mb-filter-sidebar/filter-controls/condition-options";

const NEGATIVE_REVIEW_MAX_STARS = 3;

const EventFilterValue = ({
  formData,
  setFormData,
  configData,
  setConfigData,
}) => {
  const { filterDefinitions, eventProps } = configData;
  const filterValueDefinition =
    filterDefinitions.find((x) => x.mbName == EVENT_FILTER_VALUE_MB_NAME) ?? [];

  const filterValue = formData.query.productAttributes.find(
    (x) => x.mbName == EVENT_FILTER_VALUE_MB_NAME
  ) ?? {
    id: uniqueId(),
    mbName: EVENT_FILTER_VALUE_MB_NAME,
  };

  const [localFilter, setLocalFilter] = useState({});

  useEffect(() => {
    if (
      eventProps &&
      localFilter.displayName != filterValueDefinition.displayName
    ) {
      setLocalFilter(
        mergeInsightsFilterWithFilterDefinition(
          filterValue,
          filterValueDefinition,
          [filterValueDefinition]
        )
      );
    }
  }, [eventProps]);

  useEffect(() => {
    if (validateFilter(localFilter)) {
      if (
        Object.entries(localFilter.selectedProperties).some(
          ([k, v]) => v === undefined
        )
      ) {
        setLocalFilter({ ...localFilter, selectedProperties: null });
        setConfigData({ ...configData, filterValueAttribute: null });
      } else {
        const converted = convertFilterSidebarToInsightsFilter(localFilter);
        setConfigData({ ...configData, filterValueAttribute: converted });
      }
    }
  }, [localFilter]);

  const StarRatingInput = () => {
    const selectedValue = localFilter?.selectedProperties?.value1;
    useEffect(() => {
      if (!selectedValue) handleChange(3);
    }, []);

    const handleChange = (v) => {
      // according to numeric controls
      setLocalFilter({
        ...localFilter,
        selectedProperties: {
          value1: parseFloat(v),
        },
        selectedCondition:
          v === NEGATIVE_REVIEW_MAX_STARS
            ? NumericOptions.find((x) => x.name == "Less than or Equals to")
                .value
            : NumericOptions.find((x) => x.name == "Equals to").value,
      });
    };

    return (
      <>
        Is
        <select
          value={selectedValue}
          onChange={(e) => handleChange(parseFloat(e.target.value))}
        >
          <option value={3}>3☆ or less</option>
          <option value={2}>2☆</option>
          <option value={1}>1☆</option>
        </select>
      </>
    );
  };

  const CustomInput = (props) => {
    const getMultiplier = () => {
      switch (eventProps.filterValueDecorator) {
        case EVENT_FILTER_DECORATORS.percent:
          return 100;
        case EVENT_FILTER_DECORATORS.negativePercent:
          return -100;
        default:
          return 1;
      }
    };
    const multiplier = getMultiplier();

    const isPercentage =
      eventProps.filterValueDecorator == EVENT_FILTER_DECORATORS.percent ||
      eventProps.filterValueDecorator ==
        EVENT_FILTER_DECORATORS.negativePercent;

    return (
      <div style={{ display: "flex" }}>
        <div>
          <input
            style={{ width: "35px" }}
            value={
              configData.filterValueAttribute
                ? props.defaultValue * multiplier
                : ""
            }
            onChange={(e) => {
              if (e.target.value?.length === 0) {
                setConfigData({
                  ...configData,
                  filterValueAttribute: null,
                });
                // props.onChange(null, props.valueName);
              } else
                props.onChange(e.target.value / multiplier, props.valueName);
            }}
            type="number"
          />
        </div>
        {isPercentage && <div>%</div>}
      </div>
    );
  };

  return (
    <div className={styles.filterValueContainer}>
      <div className={styles.preTitle}>
        <div>When </div>
        <div style={{ display: "flex" }}>
          <div>{eventProps.filterValueName}</div>
          <div>
            {/* <ItemTooltip
              tooltipName={eventProps.filterValueName}
              tooltipData={{
                headline: eventProps.tooltip,
              }}
            /> */}
          </div>
        </div>
      </div>
      <div className={styles.numericControls}>
        {eventProps.filterValueDecorator ==
        EVENT_FILTER_DECORATORS.starRating ? (
          <StarRatingInput />
        ) : (
          <NumericControls
            item={localFilter}
            conditionOptions={getFilterConditionOptions(filterValueDefinition)}
            onUpdateFilter={(id, data) =>
              data && setLocalFilter({ ...localFilter, ...data })
            }
            customInput={CustomInput}
          />
        )}
      </div>
    </div>
  );
};

export default EventFilterValue;
