import React from "react";
import LineChartComponent from "../../../../components/charts/visx/line-chart-component";
import LegendWrapper from "../../../../visx_components/legend/side-legend";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownBody,
} from "../../../../components/dropdown";

import { GRAPH_OPTIONS } from "../../constants";

const { DropdownTitleSelectedItem } = dropdownTitle;
const { DropdownBodySingleSelect } = dropdownBody;

import styles from "./brand-performance-linechart.scss";
import { objectToArray } from "../../../../utils/other/utilities";
import { INDEX_COLORS } from "../../../market-share/constants";
import ChartComponentLoader from "../../../../components/dashboard-components/chart-component/chart-component-loader";
import LineTooltipComponent from "../../../../components/charts/visx/tooltips/line-tooltip-component";

const BrandPerformanceLineChart = ({
  id,
  data = {},
  graphType = 0,
  onGraphTypeChange,
}) => {
  const lineData = objectToArray(data, "date");
  if (lineData) {
    lineData.forEach((x) => {
      x.date = x.date.substring(0, x.date.indexOf("T")) || x.date;
    });
  }
  const dataKeys = data
    ? Object.keys(data).reduce((obj, x, i) => {
        obj[x] = {
          name: x,
          stroke: INDEX_COLORS[i],
          displayName: x,
        };
        return obj;
      }, {})
    : false;

  const legendLabelProps =
    data != null
      ? Object.keys(data).map((x, i) =>
          Object.assign(
            {},
            {
              stroke: INDEX_COLORS[i],
              displayName: x,
              strokeDasharray: "0",
              name: x,
              width: 16,
            }
          )
        )
      : {};

  const selectedGraphType = GRAPH_OPTIONS.find((x) => x.value == graphType);

  const dropdownTitle = (
    <DropdownTitleSelectedItem
      value={selectedGraphType.name}
      className={styles.dropdownTitleSelectedItem}
      arrow={true}
    />
  );

  const handleClickItem = (value) => {
    onGraphTypeChange(value);
  };

  const tooltipCallback = () => {
    return LineTooltipComponent({
      section: selectedGraphType.name,
      valueDecorator: selectedGraphType.decorator,
      entities: dataKeys,
      keyFormatter: (x) => x,
      xKey: "date",
    });
  };

  return (
    <div className={styles.wrapper}>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownBody={{
          component: DropdownBodySingleSelect,
          props: {
            options: GRAPH_OPTIONS,
            value: graphType,
            radiobutton: false,
          },
          styles,
        }}
        handleClickItem={handleClickItem}
        wrapDropdownBody={true}
        styles={styles}
        className={"dropdownWrapper"}
      />
      {dataKeys ? (
        <>
          <LineChartComponent
            id={id}
            data={lineData}
            dataKeys={dataKeys}
            keyFormatter={(x) => x}
            xValuesNumeric={false}
            xScaleType={"band"}
            xKey={"date"}
            xAxis={true}
            yTickFormat={selectedGraphType.yTickFormatter}
            tooltipCallback={tooltipCallback()}
            baseStyles={styles}
            hideLegend={true}
            legendLabelProps={legendLabelProps}
          />
          <div className={styles.legendWrapper}>
            <LegendWrapper
              labels={Object.keys(data)}
              labelsProps={legendLabelProps}
              itemsStyle={styles.itemsStyle}
              height={"100%"}
            />
          </div>
        </>
      ) : (
        <ChartComponentLoader />
      )}
    </div>
  );
};

export default BrandPerformanceLineChart;
