import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_CANCEL,
  CLEAR_CATEGORIES,
} from "./constants";

const categoriesReducer = (
  state = {
    data: [],
    loading: false,
    error: "",
  },
  action
) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case FETCH_CATEGORIES_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data,
        loading: false,
      };
    }

    case FETCH_CATEGORIES_FAILURE: {
      const { error } = action.payload;

      return {
        ...state,
        error,
        loading: false,
      };
    }

    case FETCH_CATEGORIES_CANCEL: {
      return {
        ...state,
        loading: true,
      };
    }

    case CLEAR_CATEGORIES: {
      return {
        data: [],
        loading: false,
        error: "",
      };
    }
  }

  return state;
};

export default categoriesReducer;
