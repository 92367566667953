import React from "react";

function dropdownButtonHOC(WrappingComponent, toggleDropdownVisibility) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }
    onClickHandler = (value) => {
      toggleDropdownVisibility && toggleDropdownVisibility();
      this.props.handleClickButton && this.props.handleClickButton(value);
    };

    render() {
      return (
        <WrappingComponent
          onClickButton={this.onClickHandler}
          {...this.props}
        />
      );
    }
  };
}

export default dropdownButtonHOC;
