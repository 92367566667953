import { AxisBottom } from "@visx/axis";
import { Group } from "@visx/group";
import { scaleBand, scaleLinear } from "@visx/scale";
import { Bar } from "@visx/shape";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { localPoint } from "@visx/event";
import { useEventEmitter } from "@visx/xychart";
import React, { useMemo } from "react";
import {
  decorateValue,
  amountDollarsDecorator,
} from "../../styles/cell-decorators";

import styles from "./bar-chart.scss";
import BarTooltipComponentContent from "../../components/charts/visx/tooltips/bar-tooltip-component-content";
import {
  CHART_CLICK_EVENT,
  CHART_POINTER_MOVE_EVENT,
  CHART_TOOLTIP_HIDE_EVENT,
} from "../constants";

const verticalMargin = 100;
const horizontalMargin = 15;

const MIN_BAR_WIDTH = 30;

const defaultCellDecorator = amountDollarsDecorator("");

function BarChart({
  id,
  width,
  height,
  data1,
  xDomain,
  xKey,
  xTickFormat,
  yFormatter,
  graphTitle,
  dataKeys,
  reverseKeys = false,
  curveKeysToHoverNames = (x) => x,
}) {
  const curveKeys = Object.keys(dataKeys);
  const emit = useEventEmitter();

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const tickLabelProps = {
    fill: "rgb(73,80,87)",
    fontSize: 11,
    fontWeight: 200,
    letterSpacing: 0.4,
    fontFamily: '"Roboto", Verdana, Arial, Helvetica, sans-serif',
    textAnchor: "middle",
  };

  const getTooltipData = (d, tick) => {
    const fields = curveKeys.reduce((acc, key) => {
      acc[key] = { displayName: curveKeysToHoverNames(key) };
      return acc;
    }, {});
    const tooltipName = d.fullName && d.fullName != "" ? d.fullName : tick;
    return (
      <BarTooltipComponentContent
        datum={d}
        fields={fields}
        section={graphTitle}
        valueDecorator={yFormatter}
        tooltipTitleName={tooltipName}
        reverseKeys={reverseKeys}
      />
    );
  };

  const handleMouseOver = (event, d, tick) => {
    const coords = localPoint(event.target.ownerSVGElement, event);
    showTooltipAt(coords, d, tick);
    emit && emit(CHART_POINTER_MOVE_EVENT, { coords, d, tick }, id);
  };

  const handleMouseOut = () => {
    hideTooltip();
    emit && emit(CHART_TOOLTIP_HIDE_EVENT, {}, id);
  };

  const handleMouseUp = (e, datum) => {
    emit &&
      emit(
        CHART_CLICK_EVENT,
        {
          value: {
            entity: datum.name,
            x: e.clientX,
            y: e.pageY,
            datum: datum,
          },
        },
        id
      );
  };

  const showTooltipAt = (coords, d, tick) => {
    showTooltip({
      tooltipLeft: coords.x,
      tooltipTop: coords.y,
      tooltipData: getTooltipData(d, tick),
    });
  };

  // Bounds
  const xMax = width - horizontalMargin;
  const yMax = height - verticalMargin;

  // Scales (memoize for performance)
  // More info: https://observablehq.com/@d3/d3-scaleband
  const newXScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        // round: true,
        domain: xDomain,
        padding: 0.38,
        paddingInner: 0.75,
      }),
    [xMax, data1]
  );

  const getDecoratedValue = (value) => {
    var dec = decorateValue(value, yFormatter);
    return dec.props.children.props?.children ?? dec.props.children;
  };

  const getMaxYValue = () => {
    return curveKeys.reduce(function (max, key) {
      var keyMax = data1.reduce(function (currentMax, item) {
        return Math.max(currentMax, item[key]);
      }, Number.NEGATIVE_INFINITY);

      return Math.max(max, keyMax);
    }, Number.NEGATIVE_INFINITY);
  };

  const newYScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        // round: true,
        domain: [0, getMaxYValue()],
      }),
    [yMax, data1]
  );

  useEventEmitter(CHART_POINTER_MOVE_EVENT, ({ event, source }) => {
    if (source === id) return;
    const localDatum = data1.find((x) => x[xKey] === event.d?.[xKey]);
    showTooltipAt(event.coords, localDatum, event.tick);
  });

  useEventEmitter(CHART_TOOLTIP_HIDE_EVENT, ({ event, source }) => {
    if (source === id) return;
    hideTooltip();
  });

  return (
    <svg
      width={"100%"}
      height={"100%"}
      key={`svg-${data1.length}`}
      ref={containerRef}
    >
      <Group
        left={horizontalMargin / 2}
        top={verticalMargin / 2}
        key={`group-${data1.length}`}
      >
        {data1.map((d) => {
          const tick = d[xKey];
          const newbarWidth = Math.min(newXScale.bandwidth(), MIN_BAR_WIDTH);

          const newBarX = newXScale(tick);

          const offset = newbarWidth / curveKeys.length + 0.5;

          return (
            <React.Fragment key={`labeled-group-${tick}`}>
              <Group
                onMouseOut={handleMouseOut}
                onMouseMove={(event) =>
                  handleMouseOver(event, d, xTickFormat(tick))
                }
              >
                <React.Fragment key={`labeled-bar-${tick}-prev`}>
                  {curveKeys.map((item, index) => {
                    const yVal = d[item];
                    const barHeight = yMax - (newYScale(yVal ?? 0) ?? 0);
                    if (barHeight < 0) {
                      return <React.Fragment key={index}></React.Fragment>;
                    }
                    const barY = yMax - barHeight;
                    const xPlacement =
                      index == 0
                        ? curveKeys.length === 1
                          ? newBarX + newXScale.bandwidth() / 2 - offset / 2
                          : newBarX + newXScale.bandwidth() / 2 - offset * 2
                        : newBarX + newXScale.bandwidth() / 2 + 1;
                    return (
                      <React.Fragment key={`labeled-bar-${tick}-${index}`}>
                        <Bar
                          onMouseUp={(e) => handleMouseUp(e, d)}
                          key={`bar-${tick}-${item}-${index}`}
                          x={xPlacement}
                          y={barY}
                          width={newbarWidth}
                          height={barHeight}
                          fill={dataKeys[item].stroke}
                          style={{ cursor: "pointer" }}
                        />
                        <text
                          className={styles.topBarVal}
                          key={`text-label-${tick}-${item}-${index}`}
                          x={xPlacement}
                          y={barY}
                          dx={newbarWidth / 2}
                          dy="-.25em"
                          fontSize={10}
                          textAnchor="middle"
                        >
                          {getDecoratedValue(yVal)}
                        </text>
                      </React.Fragment>
                    );
                  })}
                </React.Fragment>
              </Group>
              {tooltipOpen && (
                <TooltipInPortal
                  key={Math.random()}
                  top={tooltipTop}
                  left={tooltipLeft}
                >
                  {tooltipData}
                </TooltipInPortal>
              )}
            </React.Fragment>
          );
        })}
        <AxisBottom
          hideTicks={true}
          scale={newXScale}
          top={yMax}
          numTicks={data1.length}
          tickFormat={xTickFormat}
          tickLabelProps={() => tickLabelProps}
        />
      </Group>
    </svg>
  );
}

export default BarChart;
