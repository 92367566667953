import {
  chartTickNumberFormat,
  chartTickPercentFormat,
  chartTickPriceFormat,
  numberDecorator,
  percentDecorator,
  priceDecorator,
} from "../../styles/cell-decorators";

export const BRAND_PERFORMANCE_VIEW_TYPE_DATA = {
  name: "brand_performance",
  id: 11,
};

const path = "brandPerformance";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;
export const FETCH_TABLE_REQUEST = `${path}/FETCH_TABLE_REQUEST`;
export const FETCH_TABLE_SUCCESS = `${path}/FETCH_TABLE_SUCCESS`;
export const FETCH_TABLE_FAILURE = `${path}/FETCH_TABLE_FAILURE`;
export const FETCH_TABLE_CANCEL = `${path}/FETCH_TABLE_CANCEL`;

export const FETCH_MORE_TABLE_REQUEST = `${path}/FETCH_MORE_TABLE_REQUEST`;
export const FETCH_MORE_TABLE_SUCCESS = `${path}/FETCH_MORE_TABLE_SUCCESS`;
export const FETCH_MORE_TABLE_FAILURE = `${path}/FETCH_MORE_TABLE_FAILURE`;
export const FETCH_MORE_TABLE_CANCEL = `${path}/FETCH_MORE_TABLE_CANCEL`;

export const FETCH_GRAPH_REQUEST = `${path}/FETCH_GRAPH_REQUEST`;
export const FETCH_GRAPH_SUCCESS = `${path}/FETCH_GRAPH_SUCCESS`;
export const FETCH_GRAPH_FAILURE = `${path}/FETCH_GRAPH_FAILURE`;
export const FETCH_GRAPH_CANCEL = `${path}/FETCH_GRAPH_CANCEL`;

export const REMOVE_SELECTED_SEARCH_TERM_GRAPH = `${path}/REMOVE_SELECTED_SEARCH_TERM_GRAPH`;
export const ADD_SELECTED_SEARCH_TERM_GRAPH = `${path}/ADD_SELECTED_SEARCH_TERM_GRAPH`;
export const CHANGE_SELECTED_OPTIONS_PER_GRAPH = `${path}/CHANGE_SELECTED_OPTIONS_PER_GRAPH`;

export const GRAPH_OPTIONS = [
  {
    name: "# of PDPs",
    value: 0,
    decorator: numberDecorator,
    yTickFormatter: chartTickNumberFormat,
  },
  {
    name: "In Stock Rate",
    value: 1,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Average Selling Price",
    value: 11,
    decorator: priceDecorator,
    yTickFormatter: chartTickPriceFormat,
  },
  {
    name: "Share Of Promotion",
    value: 2,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Reviews",
    value: 3,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Sponsored",
    value: 4,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Page 1",
    value: 5,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Top 1",
    value: 6,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Top 3",
    value: 7,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Top 5",
    value: 8,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Top 10",
    value: 9,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    name: "Share Of Top 20",
    value: 10,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
];

export const DEFAULT_GRAPH_OPTIONS = [5, 10, 4, 2];
export const BRAND_PRODUCT_ATTRIBUTE_MBNAME = "brand.keyword";
