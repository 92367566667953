import React, { useState } from "react";
import styles from "./filter-dropdown.scss";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownBody,
} from "../../../components/dropdown";
import DropdownHeaderSearchable from "../../../components/dropdown/dropdown-header/dropdown-header-searchable";

const { DropdownTitleSelectedItem } = dropdownTitle;
const { DropdownBodySingleSelect } = dropdownBody;

function FilterDropdown(props) {
  const {
    titleElement = null,
    selectedItem,
    handleClickItem,
    options,
    selectedItemClassName,
    openByDefault,
  } = props;

  const [filterValue, setFilterValue] = useState("");

  const dropdownTitle = titleElement ?? (
    <DropdownTitleSelectedItem
      value={selectedItem?.name}
      className={selectedItem?.id !== -1 ? selectedItemClassName : null}
    />
  );

  const shouldAddSearchBar = options && options.length > 8;
  const onChangeFilterValue = ({ target }) => {
    setFilterValue(target.value);
  };

  const dropdownHeader = shouldAddSearchBar ? (
    <DropdownHeaderSearchable
      onChangeHandler={onChangeFilterValue}
      filterValue={filterValue}
    />
  ) : null;

  const filteredOptions =
    shouldAddSearchBar && filterValue
      ? options.filter((item) =>
          item.name.toLowerCase().includes(filterValue.toLowerCase())
        )
      : options;

  return (
    <>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownHeader={dropdownHeader}
        dropdownBody={{
          component: DropdownBodySingleSelect,
          props: { options: filteredOptions, value: selectedItem?.value },
          styles,
        }}
        handleClickItem={handleClickItem}
        wrapDropdownBody={true}
        styles={styles}
        className={styles.filterConditionDropdown}
        openByDefault={openByDefault}
      />
    </>
  );
}

export default FilterDropdown;
