export default function getLastPredictionDate(
  domainsMetadata,
  currentSelectedRetailer,
  productAttributes
) {
  const getLastPrediction = (retailer) => {
    const lpd = domainsMetadata[retailer]?.default?.default?.lastPredictionDate;
    if (lpd) {
      return new Date(lpd);
    }
    return null;
  };

  let lastPrediction = null;

  if (currentSelectedRetailer && domainsMetadata) {
    if (Array.isArray(currentSelectedRetailer)) {
      const lpd = Math.min.apply(
        null,
        currentSelectedRetailer.map(getLastPrediction)
      );
      if (lpd) {
        lastPrediction = new Date(lpd);
      } else {
        lastPrediction = null;
      }
    } else lastPrediction = getLastPrediction(currentSelectedRetailer);

    if (lastPrediction === null) {
      return null;
    }

    // check if a selected product attribute has specific last prediction date
    if (
      productAttributes &&
      ((Array.isArray(currentSelectedRetailer) &&
        currentSelectedRetailer.length === 1) ||
        !Array.isArray(currentSelectedRetailer))
    ) {
      for (var productAttribute in productAttributes) {
        let name = productAttributes[productAttribute].mbName;
        if (productAttributes[productAttribute].values?.length == 1) {
          let value = productAttributes[productAttribute].values[0];
          if (
            (domainsMetadata[currentSelectedRetailer][name] ?? {})[value]
              ?.lastPredictionDate
          ) {
            lastPrediction = new Date(
              domainsMetadata[currentSelectedRetailer][name][
                value
              ].lastPredictionDate
            );
            break;
          }
        }
      }
    }
  }
  return lastPrediction;
}
