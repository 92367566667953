import React, { useCallback, useEffect, useState } from "react";
import styles from "./event-wizard.scss";
import TriggerList from "./screens/trigger-list/trigger-list";
import TriggerPresets from "./screens/trigger-presets/trigger-presets";
import CustomPresetFilter from "./screens/custom-preset-filter/custom-preset-filter";
import { SCREENS } from "./consts";
import TriggerDefinition from "./screens/trigger-definition/trigger-definition";
import Popup from "../../components/popup";
import xIcon from "../../images/x.svg";
import ArrowIcon from "../../images/arrow-navigation.svg";
import Toast from "../../components/toast";
import { useSubspace } from "react-redux-subspace";
import {
  getEventsDefinition,
  getSelectedEventTypeProperties,
  hasNotifications,
} from "./utils";
import { RANKING_EVENTS_VIEW_TYPE_DATA } from "../../containers/ranking-events/constants";
import BasicViewService from "../../services/basicViewService";
import {
  fetchInsightsViewsConfigs,
  fetchInsightsViewsDefinitions,
} from "../../containers/insights/insights-data-controls/actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RequestPreset from "./screens/request-preset/request-preset";
import { useRef } from "react";

const viewService = new BasicViewService();

const screensConfig = {
  [SCREENS.triggerList]: {
    Component: TriggerList,
  },
  [SCREENS.triggerPresets]: {
    Component: TriggerPresets,
  },
  [SCREENS.customFilter]: {
    Component: CustomPresetFilter,
  },
  [SCREENS.triggerDefinition]: {
    Component: TriggerDefinition,
  },
  [SCREENS.requestPreset]: {
    Component: RequestPreset,
  },
};

const INITIAL_SCREEN = SCREENS.triggerList;

const sortViewsInitially = (views) => {
  const enabled = [],
    disabled = [];
  for (const view of views) {
    hasNotifications(view) ? enabled.push(view) : disabled.push(view);
  }
  return enabled.concat(disabled);
};

const EventWizard = ({
  apiProvider,
  close,
  viewTypeData,

  filterDefinitions,
  viewsConfigsList,
}) => {
  const [screensHistory, setScreensHistory] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(INITIAL_SCREEN);
  const [formData, setFormData] = useState({});
  const mapState = useCallback(
    (state) => state[RANKING_EVENTS_VIEW_TYPE_DATA.subspace]
  );
  const subspace = useSubspace(
    mapState,
    RANKING_EVENTS_VIEW_TYPE_DATA.subspace
  );
  const [configData, setConfigData] = useState({
    filterDefinitions,
    apiProvider,
    parentViewTypeData: viewTypeData,
    viewTypeData: RANKING_EVENTS_VIEW_TYPE_DATA,
    views: viewsConfigsList ?? [],
    subspace,
  });
  const initialSortDone = useRef(false);

  const [loadingDefinitions, setLoadingDefinitions] = useState(false);

  useEffect(() => {
    // load ranking events views (if wizard is opened from a different screen)
    if (
      configData.parentViewTypeData.id != configData.viewTypeData.id &&
      !viewsConfigsList
    ) {
      setLoadingDefinitions(true);
      Promise.all([
        configData.subspace.dispatch(
          fetchInsightsViewsDefinitions(
            viewService,
            configData.viewTypeData.id,
            null,
            null
          )
        ),
        configData.subspace.dispatch(
          fetchInsightsViewsConfigs(
            viewService,
            configData.viewTypeData.id,
            null,
            null
          )
        ),
      ])
        .then((res) => {
          setLoadingDefinitions(false);
        })
        .catch((err) => {
          // show error
        });
    }
  }, []);

  useEffect(() => {
    if (!initialSortDone.current && viewsConfigsList.length > 0) {
      setConfigData({
        ...configData,
        views: sortViewsInitially(viewsConfigsList),
        filterDefinitions,
      });
      initialSortDone.current = true;
    }
  }, [viewsConfigsList, filterDefinitions]);

  const eventsDefinition = getEventsDefinition(filterDefinitions);

  useEffect(() => {
    if (formData?.query?.productAttributes) {
      const eventProps = getSelectedEventTypeProperties(
        formData.query.productAttributes,
        eventsDefinition
      );
      setConfigData({ ...configData, eventProps: eventProps });
    }
  }, [formData]);

  const moveToScreen = (screen) => {
    setScreensHistory([...screensHistory, currentScreen]);
    setCurrentScreen(screen);
  };

  const onBack = () => {
    const newHist = [...screensHistory];
    const lastScreen = newHist.splice(newHist.length - 1, 1);
    setCurrentScreen(lastScreen);
    setScreensHistory(newHist);
  };

  const DialogControls = () => {
    return (
      <div className={styles.dialogControlsContainer}>
        <div className={styles.controlsBack} onClick={() => onBack()}>
          {screensHistory.length > 0 && (
            <>
              <img src={ArrowIcon} />
              Back
            </>
          )}
        </div>
        <div className={styles.controlsClose} onClick={() => close()}>
          <img src={xIcon} />
        </div>
      </div>
    );
  };

  const Screen = screensConfig[currentScreen].Component;
  return (
    <div className={styles.popupWrapper}>
      <Popup visible={true} handleClose={close}>
        <div className={styles.popupContainer}>
          <Toast>
            {(sendToast) => (
              <div className={styles.popupContent}>
                <DialogControls />
                <Screen
                  moveToScreen={moveToScreen}
                  resetScreensHistory={() => setScreensHistory([])}
                  formData={formData}
                  setFormData={setFormData}
                  configData={configData}
                  setConfigData={setConfigData}
                  sendToast={sendToast}
                  loadingDefinitions={loadingDefinitions}
                />
              </div>
            )}
          </Toast>
        </div>
      </Popup>
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    viewsConfigsList:
      state[RANKING_EVENTS_VIEW_TYPE_DATA.subspace].insights.insights
        .viewsConfigsList,
    filterDefinitions:
      state[RANKING_EVENTS_VIEW_TYPE_DATA.subspace].insights.insights
        .viewDefinitions.filterDefinitions,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventWizard);
