import React, { useEffect, useState } from "react";
import styles from "./ads-spend-graph.scss";
import { fetchSkuComparisonData } from "../../../actions";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Decorators from "../../../../../styles/cell-decorators";
import ChartComponentLoader from "../../../../../components/dashboard-components/chart-component/chart-component-loader";
import { useRef } from "react";
import {
  PIVOTS,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../../constants";
import { analyticsChartInteraction } from "../../../../shared/analytics/actions";
import { PRODUCT_360_VIEW_TYPE_DATA } from "../../../constants";
import { CHART_COMPARE_PERIOD_TOGGLED } from "../../../../shared/analytics/constants";
import LineChartComponent from "../../../../../components/charts/visx/line-chart-component";
import LineTooltipComponent from "../../../../../components/charts/visx/tooltips/line-tooltip-component";
import ChartControls from "../../similar-skus/similar-skus-graphs-container/chart-controls";
import BarChartComponent from "../../../../../components/charts/visx/bar-chart-component";
import { dateFormatter } from "../../../utils";
import { BARS_COLORS } from "../../../../market-share/constants";
import ChartTitle from "../../../../../components/chart-title/chart-title";
import MbRequestsErrors from "../../../../../mb_components/mb-requests-errors/mb-requests-errors";

const AdsSpendGraph = ({
  selectedSku,
  forceLoading = false,
  selectedDateRange,
  currentViewConfigQuery,
  selectedPreviousDateRange,
  comparePeriod,
  productAttributes,
}) => {
  const [chartSettings, setChartSettings] = useState({
    showComparePeriod: true,
  });
  const [data, setData] = useState();
  const [status, setStatus] = useState(STATUS_LOADING);
  const isMounted = useRef(true);

  const fetchData = async () => {
    const requestParams = {
      requestBody: {
        insightsViewQuery: {
          ...currentViewConfigQuery,
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          previousStartDate: selectedPreviousDateRange.previousStartDate,
          previousEndDate: selectedPreviousDateRange.previousEndDate,
          comparePeriod: comparePeriod,
        },
        primaryPivot: PIVOTS.adsSpend,
        sku: selectedSku,
      },
    };

    try {
      const res = await fetchSkuComparisonData(requestParams);
      if (isMounted.current) {
        setData(res?.data);
        setStatus(STATUS_SUCCESS);
      }
    } catch {
      if (isMounted.current) {
        setStatus(STATUS_ERROR);
      }
    }
  };

  useEffect(() => {
    if (!forceLoading) fetchData();
    return () => {
      isMounted.current = false;
    };
  }, [productAttributes, forceLoading]);

  const getDataKeys = () => {
    return {
      previous: {
        label: "Previous Ads Spend",
        stroke: BARS_COLORS.previous,
        strokeDasharray: "3 6",
        decorator: Decorators.priceDecorator,
        width: 1,
      },
      current: {
        label: "Current Ads Spend",
        stroke: BARS_COLORS.current,
        decorator: Decorators.priceDecorator,
        width: 2,
      },
    };
  };

  const dataKeys = chartSettings.showComparePeriod
    ? getDataKeys()
    : { current: getDataKeys().current };

  const dataPoints =
    data?.map((dataPoint) => {
      const dp = {
        currentDate: new Date(dataPoint.currentDate).getTime(),
        current: dataPoint.primaryPivotCurrentValue,
      };
      if (chartSettings.showComparePeriod)
        dp.previous = dataPoint.primaryPivotPreviousValue;
      return dp;
    }) ?? [];

  const hasData = dataPoints.some((dp) => dp.current > 0 || dp.previous > 0);

  const barData = [
    {
      current: dataPoints.reduce((sum, point) => sum + point.current, 0),
      previous: dataPoints.reduce((sum, point) => sum + point.previous, 0),
    },
  ];

  const xKey = "currentDate";

  const tooltipCallback = () => {
    return LineTooltipComponent({
      keyFormatter: (x) => dataKeys[x].label,
      xKey: xKey,
      valueDecorator: Decorators.priceDecorator,
      entities: dataKeys,
    });
  };

  const setChartSettingsWithAnalytics = (newSettings) => {
    setChartSettings({ ...chartSettings, ...newSettings });
    if (newSettings.showComparePeriod !== undefined) {
      analyticsChartInteraction(
        PRODUCT_360_VIEW_TYPE_DATA.id,
        `seoAnalysis_adsSpend`,
        CHART_COMPARE_PERIOD_TOGGLED,
        newSettings.showComparePeriod
      );
    }
  };

  const chartId = "seo-analysis-ads-spend";

  return (
    <>
      <div className={styles.comparisonChartContainer}>
        <div className={styles.chartHeader}>
          <ChartTitle>Ads Spend</ChartTitle>
          <ChartControls
            chartSettings={chartSettings}
            setChartSettings={setChartSettingsWithAnalytics}
            chartTypes={["Line", "Bar"]}
          />
        </div>
        <div className={styles.chartSkeletonWrapper}>
          {status == STATUS_LOADING ? (
            <ChartComponentLoader />
          ) : status == STATUS_SUCCESS ? (
            !hasData ? (
              <MbRequestsErrors errorType={"noDataError"} />
            ) : chartSettings.selectedType == "Bar" ? (
              <BarChartComponent
                id={chartId}
                data={barData}
                dataKeys={dataKeys}
                keyFormatter={(x) => dataKeys[x].label}
                xTickFormat={() => "Ads Spend"}
                yFormatter={Decorators.priceDecorator}
                baseStyles={styles}
                xNumTicks={8}
                reverseKeys={true}
              />
            ) : (
              <LineChartComponent
                id={chartId}
                data={dataPoints}
                dataKeys={dataKeys}
                keyFormatter={(x) => dataKeys[x].label}
                xScaleType={"time"}
                xKey={xKey}
                yTickFormat={Decorators.chartTickPriceFormat}
                tooltipCallback={tooltipCallback()}
                xNumTicks={data?.length ?? 16}
              />
            )
          ) : (
            <MbRequestsErrors errorType={"beError"} />
          )}
        </div>
      </div>
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AdsSpendGraph);
