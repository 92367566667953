import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showInsightsAiSidebar } from "../../../insights/insights-ai-sidebar/actions";
import styles from "./styles.scss";
import Button from "../../../../components/button";

function MarketShareInsightsAiError(props) {
  return (
    <div className={styles.errorWrapper}>
      <i className={styles.errorImage} />
      Something went wrong
      <div className={styles.subtitle}>
        An error occurred while retrieving the data.
        <br />
        please try again later.
      </div>
      <Button
        text={"OK"}
        active={true}
        onClick={() => props.showInsightsAiSidebar(false)}
      />
    </div>
  );
}

export const mapStateToProps = () => {
  return {};
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showInsightsAiSidebar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketShareInsightsAiError);
