import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./header.scss";
import logo from "../../images/bi-logo.png";

class Header extends Component {
  static propTypes = {
    smallLogoImage: PropTypes.string,
  };

  render() {
    const { logoImage } = this.props;

    const logoCustomer = logoImage ? (
      <img id="customer_logo" src={`${logoImage}?120323`} className={styles.logoCustomer} />
    ) : null;

    return (
      <div className={styles.wrapper}>
        <div className={styles.leftBox}>
          <div className={styles.logoCont}>{logoCustomer}</div>
        </div>
        {this.props.children}
        <div className={styles.logo}>
          <img id="MB_logo" src={logo} />
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    logoImage: state.user.customerInfo.logoImage,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Header);
