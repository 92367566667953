export const PRODUCT_360_VIEW_TYPE_DATA = { name: "product", id: 7 };

const path = "product360";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;

export const SET_SELECTED_SKU = `${path}/SET_SELECTED_SKU`;
export const SET_SELECTED_SKU_IN_PAGE = `${path}/SET_SELECTED_SKU_IN_PAGE`;

export const FETCH_FAMILY_VARIANTS_DATA_REQUEST = `${path}/FETCH_FAMILY_VARIANTS_DATA_REQUEST`;
export const FETCH_FAMILY_VARIANTS_DATA_SUCCESS = `${path}/FETCH_FAMILY_VARIANTS_DATA_SUCCESS`;
export const FETCH_FAMILY_VARIANTS_DATA_FAILURE = `${path}/FETCH_FAMILY_VARIANTS_DATA_FAILURE`;
export const FETCH_FAMILY_VARIANTS_DATA_CANCEL = `${path}/FETCH_FAMILY_VARIANTS_DATA_CANCEL`;

export const FETCH_SIMILAR_SKUS_TABLE_DATA_REQUEST = `${path}/FETCH_SIMILAR_SKUS_TABLE_DATA_REQUEST`;
export const FETCH_SIMILAR_SKUS_TABLE_DATA_SUCCESS = `${path}/FETCH_SIMILAR_SKUS_TABLE_DATA_SUCCESS`;
export const FETCH_SIMILAR_SKUS_TABLE_DATA_FAILURE = `${path}/FETCH_SIMILAR_SKUS_TABLE_DATA_FAILURE`;
export const FETCH_SIMILAR_SKUS_TABLE_DATA_CANCEL = `${path}/FETCH_SIMILAR_SKUS_TABLE_DATA_CANCEL`;
export const CHANGE_SIMILAR_SKUS_TABLE_DATA_ORDER = `${path}/CHANGE_SIMILAR_SKUS_TABLE_DATA_ORDER`;

export const RESET_SIMILAR_SKUS_GRAPH_STATE = `${path}/RESET_SIMILAR_SKUS_GRAPH_STATE`;
export const FETCH_SIMILAR_SKUS_GRAPH_DATA_REQUEST = `${path}/FETCH_SIMILAR_SKUS_GRAPH_DATA_REQUEST`;
export const FETCH_SIMILAR_SKUS_GRAPH_DATA_SUCCESS = `${path}/FETCH_SIMILAR_SKUS_GRAPH_DATA_SUCCESS`;
export const FETCH_SIMILAR_SKUS_GRAPH_DATA_FAILURE = `${path}/FETCH_SIMILAR_SKUS_GRAPH_DATA_FAILURE`;
export const FETCH_SIMILAR_SKUS_GRAPH_DATA_CANCEL = `${path}/FETCH_SIMILAR_SKUS_GRAPH_DATA_CANCEL`;

export const FETCH_SEARCH_TERMS_DATA_REQUEST = `${path}/FETCH_SEARCH_TERMS_DATA_REQUEST`;
export const FETCH_SEARCH_TERMS_DATA_SUCCESS = `${path}/FETCH_SEARCH_TERMS_DATA_SUCCESS`;
export const FETCH_SEARCH_TERMS_DATA_FAILURE = `${path}/FETCH_SEARCH_TERMS_DATA_FAILURE`;
export const FETCH_SEARCH_TERMS_DATA_CANCEL = `${path}/FETCH_SEARCH_TERMS_DATA_CANCEL`;

export const DATEPICKER_CHANGED = `${path}/DATEPICKER_CHANGED`;

const insightsSkus = `insights-skus`;

const insightsPath = `${path}/${insightsSkus}`;
export const CHANGE_FAMILY_VARIANTS_TABLE_SORTING = `${insightsPath}/CHANGE_SKUS_SORTING`;
export const CHANGE_FAMILY_VARIANTS_TABLE_OFFSET = `${insightsPath}/CHANGE_SKUS_OFFSET`;
export const SET_CURRENT_VIEW_IS_UPDATED = `${insightsPath}/SET_CURRENT_VIEW_IS_UPDATED`;
export const CHANGE_VIEW_SETTINGS = `${insightsPath}/CHANGE_SKUS_SETTINGS`;
export const CHANGE_VIEW_CHARTS_SETTINGS = `${insightsPath}/CHANGE_SKUS_CHARTS_SETTINGS`;

export const FAMILY_VARIANTS_TABLE_SIZE = 5; // Top x results to display in the table
export const FAMILY_VARIANTS_RESULTS_SIZE = 2000; // Actual max result count to request (for UI sorting)

export const SIMILAR_SKUS_TABLE_SIZE = 5;
export const SIMILAR_SKUS_RESULTS_SIZE = 2000;

export const CURRENT_SKU_TABLE_ROW_BACKGROUND = "#F3F7FF";

export const STATUS_IDLE = "idle";
export const STATUS_LOADING = "loading";
export const STATUS_ERROR = "error";
export const STATUS_SUCCESS = "success";

export const UNIFIED_SETTINGS_KEY = `both`;

// TO ADD A NEW TAB, please see sku-tabs.js for conditional visibility and product-360.js for tabs config.
export const TAB_NAMES = {
  skuSales: "SKU Sales",
  seoAnalysis: "SEO Analysis",
  similarSkus: "Similar Products",
};

// Should mirror MarketShareSkuGraphPivot enum
export const PIVOTS = {
  gmv: 0,
  averageSellingPrice: 1,
  inStockRate: 2,
  adsSpend: 3,
  unitsSold: 4,
  promoGmvShare: 5,
  promoEvents: 6,
};
