import React from "react";
import styles from "./trigger-list.scss";
import ContentLoader from "react-content-loader";

const TriggerSkeleton = ({ speed = 2 }) => {
  return (
    <div style={{ padding: "20px 0px" }} className={styles.viewItem}>
      <div className={styles.viewLeftSide}>
        <div className={styles.triggerIcon}>
          <ContentLoader speed={speed} width={48} height={48}>
            <rect x="0" y="0" rx="3" ry="3" width={48} height={48} />
          </ContentLoader>
        </div>
        <div className={styles.viewDescription}>
          <h3 style={{ width: "min-content", margin: 0 }}>
            <ContentLoader speed={speed} width={100} height={18}>
              <rect x="0" y="0" rx="3" ry="3" width={100} height={18} />
            </ContentLoader>
          </h3>
          <div style={{ whiteSpace: "nowrap" }}>
            <ContentLoader speed={speed} width={250} height={16}>
              <rect x="0" y="0" rx="3" ry="3" width={250} height={16} />
            </ContentLoader>
          </div>
        </div>
      </div>
      <div className={styles.viewActionsVertical}>
        <div className={styles.viewAlertToggle}></div>
        <div className={styles.viewActionsHorizontal}></div>
      </div>
    </div>
  );
};

export default TriggerSkeleton;
