import React from "react";

function modalButtonsHOC(WrappingComponent, closeModal) {
  return class extends React.Component {
    constructor(props) {
      super(props);
    }

    onClickConfirmButton = () => {
      closeModal && closeModal({ confirmButton: true });
      this.props.onClickConfirmButton && this.props.onClickConfirmButton();
    };

    onClickCancelButton = () => {
      closeModal && closeModal({ cancelButton: true });
      this.props.onClickCancelButton && this.props.onClickCancelButton();
    };

    render() {
      const {
        onClickConfirmButton,
        onClickCancelButton,
        ...otherProps
      } = this.props;
      return (
        <WrappingComponent
          onClickConfirmButton={this.onClickConfirmButton}
          onClickCancelButton={this.onClickCancelButton}
          {...otherProps}
        />
      );
    }
  };
}

export default modalButtonsHOC;
