import React, { useState } from "react";
import { extendMoment } from "moment-range";
import Moment from "moment";
const moment = extendMoment(Moment);

import defaultStyles from "./base-component.scss";
import ExportButton from "../export-button/export-button";
import { isFunction, isString } from "lodash";
import Spinner from "../spinner/spinner";
import { saveCsvToLocalFile } from "../../utils/other/csvUtils";

export default function BaseComponent(props) {
  const {
    isEnabled,
    isLoading,
    useDefaultLoading = true,
    component,
    title,
    dataRelevantTo,
    componentConfig = {},
    csvName,
    exportCsvCallback,
    hideHeader,
    hideBackground,
    selectedPreviousDateRange,
    pdfFormat,
    styles = {},
  } = props;

  const { visible } = componentConfig;

  const [showExportCsvSpinner, setShowExportCsvSpinner] = useState(false);

  const isVisible = isFunction(visible)
    ? visible()
    : isString(visible)
    ? eval(visible)
    : visible == null
    ? true
    : visible;

  let dataRelevantToComponent = null;
  if (dataRelevantTo) {
    const { relevantStartDate, relevantEndDate } = dataRelevantTo;
    const format = pdfFormat ? "L" : "LL";
    dataRelevantToComponent = (
      <>
        <div className={defaultStyles.showingDataRelevant}>
          Showing data relevant to
          <div className={defaultStyles.showingDataRelevantDates}>
            {moment(relevantStartDate).format(format)} -{" "}
            {moment(relevantEndDate).format(format)}
          </div>
          compared to{" "}
          <div className={defaultStyles.showingDataRelevantDates}>
            {moment(selectedPreviousDateRange.previousStartDate).format(format)}{" "}
            - {moment(selectedPreviousDateRange.previousEndDate).format(format)}
          </div>
        </div>
      </>
    );
  }

  const exportCsvComponentCallback = componentConfig?.actions?.exportCsv;
  const shouldExportCsv = isFunction(exportCsvComponentCallback);

  const exportCsvLocalCallback = () => {
    if (shouldExportCsv) {
      setShowExportCsvSpinner(true);
      exportCsvCallback(exportCsvComponentCallback)
        .then((data) => {
          saveCsvToLocalFile(data.data, csvName);
        })
        .catch((error) => {
          console.log("Csv error: " + error);
        })
        .finally(() => {
          setShowExportCsvSpinner(false);
        });
    }
  };

  return (
    <>
      {isVisible && (
        <div className={`${defaultStyles.wrapper} ${styles?.wrapper ?? ""}`}>
          {!hideHeader && (
            <div className={defaultStyles.header}>
              <div className={defaultStyles.leftPanel}>
                {title && <div className={defaultStyles.title}>{title}</div>}
              </div>
              {isEnabled && !isLoading && (
                <div className={defaultStyles.rightPanel}>
                  {dataRelevantToComponent}
                  {shouldExportCsv && (
                    <ExportButton
                      onClick={exportCsvLocalCallback}
                      showSpinner={showExportCsvSpinner || false}
                      title="CSV"
                    ></ExportButton>
                  )}
                </div>
              )}
            </div>
          )}
          <div
            className={
              hideBackground
                ? defaultStyles.componentNoBackground
                : defaultStyles.component
            }
          >
            {!isEnabled && <div>Component not available</div>}
            {isEnabled &&
              (isLoading && useDefaultLoading ? (
                <Spinner width={"100px"} />
              ) : (
                component
              ))}
          </div>
        </div>
      )}
    </>
  );
}
