import React from "react";
import PresetButton from "./preset-button/preset-button";
import CustomButton from "./custom-button/custom-button";
import { SCREENS } from "../../consts";
import HeaderContainer from "../../components/header-container";
import CenteredTitle from "../../components/centered-title";
import { EVENT_TYPE_PROPERTIES } from "../../../../containers/ranking-events/constants";
import { getEventsDefinition, setEventType } from "../../utils";
import CustomScrollbar from "../../../../components/scrollbar/customScrollbar";
import { NOTIFICATION_FREQUENCY } from "../../../../utils/other/constants";
import ContactUsButton from "./contact-us-button/contact-us-button";

const TriggerPresets = ({
  moveToScreen,
  formData,
  setFormData,
  configData,
  setConfigData,
}) => {
  const eventsDefinition = getEventsDefinition(configData.filterDefinitions);

  const prepareViewCreation = (productAttributes, custom) => {
    setFormData({
      query: {
        productAttributes: productAttributes,
        selectedColumns: [],
        sortField: 0,
        sortOrder: 1,
        settings: {},
      },
      notificationFrequency: NOTIFICATION_FREQUENCY.hour,
      favorite: false,
      lastReportDate: new Date(),
      name: "",
      viewType: configData.viewTypeData.id,
    });
    setConfigData({
      ...configData,
      mode: "new",
      custom: custom,
      filterValueAttribute: null,
    });
  };

  const onPresetSelected = (eventIndex) => {
    const productAttributes = setEventType(eventsDefinition, eventIndex, []);
    prepareViewCreation(productAttributes, false);
    moveToScreen(SCREENS.customFilter);
  };

  const onCustomSelected = () => {
    prepareViewCreation([], true);
    moveToScreen(SCREENS.customFilter);
  };

  return (
    <>
      <HeaderContainer>
        <CenteredTitle>Create your Event Trigger</CenteredTitle>
      </HeaderContainer>
      <CustomScrollbar style={{ height: 400 }}>
        <div
          style={{
            display: "flex",
            gap: "16px",
            flexWrap: "wrap",
            width: "737px",
            margin: "auto",
            marginTop: "20px",
          }}
        >
          {Object.entries(EVENT_TYPE_PROPERTIES).map(
            ([eventIndex, eventProps]) => (
              <PresetButton
                key={eventIndex}
                eventProps={eventProps}
                onClick={() => onPresetSelected(eventIndex)}
              />
            )
          )}
        </div>
      </CustomScrollbar>
      <ContactUsButton moveToScreen={moveToScreen} />
      {/* <CustomButton onClick={() => onCustomSelected()} /> */}
    </>
  );
};

export default TriggerPresets;
