import { isEmpty, uniqueId } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import FilterDropdown from "../dropdowns/filter-dropdown";
import FilterItemBase from "../filter-item-base/filter-item-base";
import FilterItemChild from "../filter-item-child";
import BoundedFilterSelection from "../bounded-filter-selection";
import {
  CollectionOptions,
  NumericOptions,
  SingleValueCollectionOptions,
  TextOptions,
} from "../filter-controls/condition-options";
import MultipleValuesCollectionFilterControls from "../collection-controls/multiple-values";
import SingleValueCollectionFilterControls from "../collection-controls/single-value";
import NumericControls from "../numeric-controls";
import TextControls from "../text-controls";
import styles from "./filter-item.scss";
import { deepClone } from "../../../utils/other/utilities";
import BoolControls from "../bool-controls";

function FilterItem(props) {
  const filterItemRef = useRef(null);
  const {
    item,
    filterDefinitions,
    selectableFiltersDefinitions,
    forceSelectedFilterDisplayName = null,
    onDispose,
    onUpdateFilter,
    openByDefault,
  } = props;

  const filtersOptions = forceSelectedFilterDisplayName
    ? null
    : selectableFiltersDefinitions.map((x, i) => ({
        id: i,
        name: x.displayName,
        value: x.displayName,
      }));

  const [selectedFilter, setSelectedFilter] = useState(
    item.displayName
      ? {
          id: 0,
          name: item.displayName,
          value: item.displayName,
        }
      : {
          id: -1,
          name: "Select filter",
          value: "",
        }
  );

  const [active, setActive] = useState(true);

  const isFilterSelected = !(selectedFilter.id === -1);
  const isPropertySelected = !isEmpty(item.selectedProperties);
  const selectedFilterDefinition = isFilterSelected
    ? filterDefinitions.filter((x) => x.displayName === selectedFilter.value)[0]
    : {};

  const handleFilterChange = (selectedOption) => {
    setSelectedFilter(selectedOption);
    onUpdateFilter(item.id, {
      displayName: selectedOption.value,
      selectedCondition: 0,
      selectedProperties: null,
      childFilters: [],
    });
  };

  const filterSelection = (
    <div className={styles.filterOptionsContainer}>
      {forceSelectedFilterDisplayName ? (
        <div className={styles.filterName}>
          {forceSelectedFilterDisplayName}
        </div>
      ) : (
        <FilterDropdown
          selectedItemClassName={styles.filterName}
          options={filtersOptions}
          selectedItem={selectedFilter}
          handleClickItem={handleFilterChange}
          openByDefault={openByDefault}
        />
      )}
    </div>
  );

  const filterChildrenDefinitions = item.constraints?.Children?.map((child) => {
    var childDefinition = filterDefinitions.find((x) => x.mbName === child);
    if (childDefinition) return deepClone(childDefinition);
  });

  const onChildFilterAdd = (childFilter, selectedOption) => {
    const filter = filterChildrenDefinitions.find(
      (x) => x.mbName === childFilter.mbName
    );
    const newFilter = {
      id: uniqueId(),
      mbName: filter.mbName,
      type: filter.type,
      value: selectedOption.value,
      values: filter.values[selectedOption.value],
      selectedCondition: getFilterConditionOptions(filter)[0].value,
      selectedProperties: null,
      definition: filter,
    };

    onUpdateFilter(item.id, {
      childFilters: [...(item.childFilters ?? []), newFilter],
    });
  };

  const onChildFilterDispose = (id) => {
    onUpdateFilter(item.id, {
      childFilters: item.childFilters.filter((x) => x.id !== id),
    });
  };

  const onChildFilterUpdate = (childFilterId, selectedOption) => {
    const childFilter = item.childFilters?.find((x) => x.id === childFilterId);
    childFilter.selectedProperties =
      selectedOption.selectedProperties || childFilter.selectedProperties;
    childFilter.selectedCondition =
      selectedOption.selectedCondition || childFilter.selectedCondition;

    onUpdateFilter(item.id, {
      childFilters: item.childFilters,
    });
  };

  const filterChildrenSelection = (
    <div className={styles.filterOptionsContainer}>
      {filterChildrenDefinitions?.map((filter) => {
        return (
          <BoundedFilterSelection
            key={filter.displayName}
            item={filter}
            isEnabled={isPropertySelected && !isEmpty(filter.values)}
            onAddFilter={onChildFilterAdd}
          />
        );
      })}
    </div>
  );

  const filterChildren = item.childFilters?.map((filter) => (
    <FilterItemChild
      key={filter.id}
      item={filter}
      onDispose={() => onChildFilterDispose(filter.id)}
      onUpdateFilter={onChildFilterUpdate}
    />
  ));

  // Active / in-active state handling
  const handleClickOutside = (event) => {
    if (filterItemRef.current) {
      setActive(filterItemRef.current.contains(event.target));
    }
  };

  useEffect(() => {
    if (
      selectedFilterDefinition?.type &&
      selectedFilterDefinition?.type === "Text"
    ) {
      document.addEventListener("mousedown", handleClickOutside);
      return () =>
        document.removeEventListener("mousedown", handleClickOutside);
    }
  }, [selectedFilterDefinition]);

  // Wrapping up controls
  const controls = isFilterSelected
    ? getFilterDefinitionComponent(selectedFilterDefinition, {
        ...props,
        isActive: active,
      })
    : null;

  return (
    <div ref={filterItemRef}>
      <FilterItemBase
        filterSelection={filterSelection}
        controls={controls}
        filterChildrenSelection={filterChildrenSelection}
        filterChildren={filterChildren}
        disposable={isEmpty(forceSelectedFilterDisplayName)}
        onDispose={() => onDispose(item.id)}
      />
    </div>
  );
}

export function getFilterDefinitionComponent(filterDefinition, props) {
  return (
    <div className={styles.filterOptionsContainer}>
      {getFilterDefinitionBaseComponent(filterDefinition, props)}
    </div>
  );
}

function getFilterDefinitionBaseComponent(filterDefinition, props) {
  let Component;
  if (filterDefinition == null) return null;
  switch (filterDefinition.type) {
    case "Enum":
    case "Compound":
    case "CollectionDictionary":
    case "Collection": {
      Component = getFilterCollectionComponent(filterDefinition);
      break;
    }
    case "Numeric": {
      Component = NumericControls;
      break;
    }
    case "Text": {
      Component = TextControls;
      break;
    }
    case "Bool": {
      Component = BoolControls;
    }
  }
  return (
    <Component
      {...props}
      conditionOptions={getFilterConditionOptions(filterDefinition)}
    />
  );
}

export function getFilterCollectionComponent(filterDefinition) {
  return filterDefinition.constraints?.SingleValue
    ? SingleValueCollectionFilterControls
    : MultipleValuesCollectionFilterControls;
}

export function getFilterConditionOptions(filter) {
  if (filter == null) return SingleValueCollectionOptions;
  switch (filter.type) {
    case "Collection":
    case "Compound":
    case "CollectionDictionary":
    case "Enum": {
      return filter.constraints?.SingleValue
        ? SingleValueCollectionOptions
        : CollectionOptions;
    }
    case "Numeric": {
      return NumericOptions;
    }
    case "Text": {
      return TextOptions;
    }
    case "Bool": {
      return [{ value: null }];
    }
    default:
      return [];
  }
}

export default FilterItem;
