import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./administration-header.scss";
import { showPopup, toggleUpdateUser } from "../../actions";

class AdministrationHeader extends Component {
  render() {
    return (
      <div
        className={styles.wrapper}
        onClick={() => {
          this.props.toggleUpdateUser(false);
          this.props.showPopup();
        }}
      >
        <div className={styles.createNewUser}>
          <p className={styles.headerTitle}>
            <span id="add_new_user_title">Create New User</span>
          </p>
        </div>
        <i className={styles.imgBox} id="add_new_user_icon" />
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showPopup,
      toggleUpdateUser,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdministrationHeader);
