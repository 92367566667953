import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

import { STATUS_LOADING, UNIFIED_SETTINGS_KEY } from "../../../constants";
import { changeChartsSettings, setSelectedSku } from "../../../actions";
import MbRequestsErrors from "../../../../../mb_components/mb-requests-errors/mb-requests-errors";
import LineChartComponent from "../../../../../components/charts/visx/line-chart-component";
import LineTooltipComponent from "../../../../../components/charts/visx/tooltips/line-tooltip-component";
import BarChartComponent from "../../../../../components/charts/visx/bar-chart-component";
import { BARS_COLORS, INDEX_COLORS } from "../../../../market-share/constants";
import ComparativeLineTooltipComponent from "../../../../../components/charts/visx/tooltips/comparative-line-tooltip-component";
import styles from "./similar-skus-graph.scss";
import MarketShareBarChartLoader from "../../../../market-share/components/market-share-charts/market-share-chart-component/loaders/market-share-bar-chart-loader";
import { shortenSkuName } from "../../../../market-share/utils";
import { useChartSettings } from "../../../../market-share/components/market-share-charts/market-share-chart-component/market-share-chart-utils";
import ChartTabs from "../../../../../components/charts/visx/chart-tabs";
import transformToComparativeLineData, {
  removePeriodPrefix,
} from "../../../../../components/charts/visx/transformToComparativeLineData";

const lineXKey = `date`;
const barXKey = `name`;
const currentKey = `currentValue`;
const previousKey = `previousValue`;

const SimilarSkusBarChart = ({
  id,
  data,
  tabConfig,
  entities,
  keyFormatter,
  barDataKeys,
}) => {
  const transformDataToBars = () => {
    const bars = [];
    for (const key of Object.keys(entities)) {
      const dataPoints = data[key];
      const bar = {
        name: key,
        fullName: entities[key].displayName,
        image: entities[key].image,
        current: dataPoints.reduce((acc, x) => acc + x[currentKey], 0),
        previous: dataPoints.reduce((acc, x) => acc + x[previousKey], 0),
      };
      if (tabConfig.isPercentage) {
        bar.current /= dataPoints.length;
        bar.previous /= dataPoints.length;
      }
      bars.push(bar);
    }
    return bars;
  };

  return (
    <BarChartComponent
      id={id}
      data={transformDataToBars()}
      xTickFormat={keyFormatter}
      dataKeys={barDataKeys}
      xKey={barXKey}
      baseStyles={styles}
      yFormatter={tabConfig.decorator}
      graphTitle={tabConfig.title}
      reverseKeys={true}
      hideLegend={true}
    />
  );
};

const SimilarSkusLineChart = ({
  id,
  data,
  entities,
  chartSettings,
  highlightKey,
  keyFormatter,
  valueDecorator,
  yTickFormatter,
}) => {
  const comparativeTooltipCallback = () => {
    return ComparativeLineTooltipComponent({
      valueDecorator: valueDecorator,
      entities: entities,
      keyFormatter: keyFormatter,
      xKey: lineXKey,
    });
  };

  const tooltipCallback = () => {
    return LineTooltipComponent({
      valueDecorator: valueDecorator,
      entities: entities,
      keyFormatter: keyFormatter,
      xKey: lineXKey,
    });
  };

  const transformDataToLines = () => {
    const currentData = [];
    const previousData = [];
    for (const key of Object.keys(entities)) {
      for (let i = 0; i < data?.[key]?.length; i++) {
        const currentValue = data[key][i][currentKey];
        const previousValue = data[key][i][previousKey];
        if (!currentData[i]) {
          currentData.push({
            [lineXKey]: new Date(data[key][i].currentDate).getTime(),
          });
          previousData.push({
            [lineXKey]: new Date(data[key][i].previousDate).getTime(),
          });
        }
        currentData[i] = {
          ...currentData[i],
          [key]: currentValue,
        };
        previousData[i] = {
          ...previousData[i],
          [key]: previousValue,
        };
      }
    }
    return { currentData, previousData };
  };

  const { currentData, previousData } = transformDataToLines();

  const comparative = transformToComparativeLineData(
    currentData,
    previousData,
    entities,
    lineXKey
  );

  return (
    <LineChartComponent
      data={chartSettings.showComparePeriod ? comparative.data : currentData}
      dataKeys={
        chartSettings.showComparePeriod ? comparative.dataKeys : entities
      }
      keyFormatter={keyFormatter}
      yTickFormat={yTickFormatter}
      xScaleType={"time"}
      xNumTicks={16}
      id={id}
      xKey={lineXKey}
      baseStyles={styles}
      tooltipCallback={
        chartSettings.showComparePeriod
          ? comparativeTooltipCallback()
          : tooltipCallback()
      }
      highlightKey={highlightKey}
      hideLegend={true}
    />
  );
};

const useUnifiedChartSettings = (
  chartId,
  settings,
  changeChartsSettings,
  tabConfigs
) => {
  const [unifiedSettings, setUnifiedSettings] = useChartSettings(
    UNIFIED_SETTINGS_KEY,
    settings?.charts ?? {},
    changeChartsSettings,
    {
      selectedType: "Line",
      showComparePeriod: false,
    }
  );

  const [privateSettings, setPrivateSettings] = useChartSettings(
    chartId,
    settings?.charts ?? {},
    changeChartsSettings,
    {
      selectedTab: tabConfigs[0].id,
    }
  );

  const interceptSettingsChange = (newSettings) => {
    if (newSettings.selectedTab !== undefined)
      return setPrivateSettings(newSettings);
    return setUnifiedSettings(newSettings);
  };

  const mergedSettings = {
    ...unifiedSettings,
    selectedTab: privateSettings.selectedTab,
  };

  return [mergedSettings, interceptSettingsChange];
};

const SimilarSkusGraph = ({
  id,
  similarSkusGraphs,
  settings,
  entities,
  highlightKey,
  barDataKeys,
  tabConfigs,
  changeChartsSettings,
  forceLoading = false,
}) => {
  const [chartSettings, setChartSettings] = useUnifiedChartSettings(
    id,
    settings,
    changeChartsSettings,
    tabConfigs
  );

  const keyFormatter = (x) => {
    const key = removePeriodPrefix(x);
    return shortenSkuName(entities[key].displayName);
  };

  const { data, status = STATUS_LOADING } =
    similarSkusGraphs?.[chartSettings.selectedTab] ?? {};

  const currentTabConfig = tabConfigs.find(
    (x) => x.id == chartSettings.selectedTab
  );

  return (
    <div className={styles.similarSkusGraphContainer}>
      <ChartTabs
        options={tabConfigs}
        selected={chartSettings.selectedTab}
        onSelect={(id) => setChartSettings({ selectedTab: id })}
      />
      {status === STATUS_LOADING || forceLoading || !currentTabConfig ? (
        <MarketShareBarChartLoader />
      ) : (
        <>
          {chartSettings.selectedType === "Bar" && (
            <SimilarSkusBarChart
              id={id}
              data={data}
              chartSettings={chartSettings}
              entities={entities}
              tabConfig={currentTabConfig}
              keyFormatter={keyFormatter}
              barDataKeys={barDataKeys}
            />
          )}
          {chartSettings.selectedType === "Line" && (
            <SimilarSkusLineChart
              id={id}
              data={data}
              chartSettings={chartSettings}
              entities={entities}
              highlightKey={highlightKey}
              yTickFormatter={currentTabConfig.yTickFormatter}
              valueDecorator={currentTabConfig.decorator}
              keyFormatter={keyFormatter}
            />
          )}
        </>
      )}
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    familyVariantsData: state.product360.product360.familyVariants.data,
    similarSkusGraphs: state.product360.product360.similarSkusGraphs,
    selectedSku: state.product360.product360.selectedSku,
    settings:
      state.product360.insights.insights.currentViewConfig.query.settings,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setSelectedSku,
      changeChartsSettings,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SimilarSkusGraph);
