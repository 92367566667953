export const currentPrefix = `current_`;
export const previousPrefix = `previous_`;
export const currentKey = (k) => `${currentPrefix}${k}`;
export const previousKey = (k) => `${previousPrefix}${k}`;
export const removePeriodPrefix = (k) => {
  for (const prefix of [currentPrefix, previousPrefix])
    if (k.startsWith(prefix)) return k.slice(prefix.length);
  return k;
};

const transformToComparativeLineData = (
  data,
  previousData,
  dataKeys = {},
  xKey
) => {
  const mergedData = [];
  for (let i = 0; i < data.length; i++) {
    const dataPoint = {};
    dataPoint[xKey] = data[i][xKey]; // This is the value that will be used by x scale
    Object.entries(data[i]).map(
      ([key, curValue]) => (dataPoint[currentKey(key)] = curValue)
    );
    Object.entries(previousData[i]).map(
      ([key, prevValue]) => (dataPoint[previousKey(key)] = prevValue)
    );
    mergedData.push(dataPoint);
  }

  const extendedKeys = {};
  for (const [k, v] of Object.entries(dataKeys)) {
    extendedKeys[currentKey(k)] = v;
    extendedKeys[previousKey(k)] = {
      ...v,
      strokeDasharray: "6 6",
    };
  }

  return { data: mergedData, dataKeys: extendedKeys };
};

export default transformToComparativeLineData;
