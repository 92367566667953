import React from "react";
import styles from "./triggers-default.scss";
import TriggersDefaultImg from "../../../../images/triggers-default.svg";

const TriggersDefault = () => {
  return (
    <div className={styles.triggersDefault}>
      <img src={TriggersDefaultImg} />
      <div>
        <b>Select a trigger to manage notifications</b>
      </div>
      <div>Or use the 'Event Triggers' button to create a new one</div>
    </div>
  );
};

export default TriggersDefault;
