import { range } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import FilterAttributesDropdown from "../../../../components/filter-attributes-dropdown/filter-attributes-dropdown";
import styles from "./user-form.scss";

import {
  changeSelectedCategories,
  changeSelectedCompetitors,
  changeSelectedScreens,
  addNewUser,
  deleteUser,
  updateUser,
} from "../../actions";

function UserForm(props) {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [domain, setDomain] = useState("");
  const [password, setPassword] = useState("");
  const [multipleCreation, setMultipleCreation] = useState(false);
  const [copyPermissionsUser, setCopyPermissionsUser] = useState([]);

  const {
    competitors,
    categories,
    screens,
    selectedCompetitors,
    selectedCategories,
    selectedScreens,
  } = props;

  const administrationKey = "fjvnw3q4VT54tyb7UoY_hTEd34Teg45y56";
  competitors.sort((a, b) => a.rootDomain?.localeCompare(b.rootDomain));

  const copyPermissionUserList = props.usersList.map((x, i) => ({
    id: i,
    name: x["userName"],
  }));

  const categoriesDropdownOptions = categories.map((x, i) => ({
    id: i,
    name: x,
  }));

  const screensDropdownOptions = screens.map((x, i) => ({
    id: i,
    name: x,
  }));

  const competitorsDropdownOptions = competitors.map((x, i) => ({
    id: i,
    name: x.name,
  }));

  const allowedCharacters =
    "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&_-?=+[]().";

  const deleteUser = () => {
    if (
      window.confirm("Are you sure you want to delete user: " + email + "?")
    ) {
      const userInfo = {
        email: email,
        adminKey: administrationKey,
      };
      props.deleteUser(userInfo);
    }
  };
  const getUserDetails = () => {
    const competitorsDomains = selectedCompetitors.map((x) => {
      return competitors[x]["rootDomain"];
    });
    const categoriesStr = selectedCategories
      .map((x) => {
        return categories[x];
      })
      .join(";");
    const screensStr = selectedScreens
      .map((x) => {
        return screens[x];
      })
      .join(", ");
    const userInfo = {
      adminKey: administrationKey,
      competitors: competitorsDomains,
      categories: encodeURIComponent(categoriesStr),
      Screens: screensStr,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
      rootdomain: domain,
    };
    return userInfo;
  };
  const addUser = () => {
    const userDetails = getUserDetails();
    props.addNewUser(userDetails, multipleCreation);
    if (multipleCreation) {
      setTimeout(() => {
        setFirstName("");
        setLastName("");
        setPassword("");
        setEmail("");
      }, 1500);
    }
  };
  const updateUser = () => {
    const userDetails = getUserDetails();
    props.updateUser(userDetails);
  };
  const onSubmit = (e) => {
    e.preventDefault();
    if (!props.update && multipleCreation) {
      e.preventDefault();
    }
  };
  const generatePassword = () => {
    let pass = "";
    for (var i in range(0, 20)) {
      pass += allowedCharacters.charAt(
        Math.floor(Math.random() * allowedCharacters.length)
      );
    }
    setPassword(pass);
  };

  const getCompetitorIdsByNames = (names) => {
    // convert competitor rootdomains to their displayed names to later filter out ids.
    names = competitors
      .filter((x) => names.includes(x.rootDomain))
      .map((y) => y.name);
    const filteredObjects = competitorsDropdownOptions.filter((obj) =>
      names.includes(obj.name)
    );
    return filteredObjects.map((obj) => obj.id);
  };

  const getScreenIdsByList = (screensStr) => {
    var screensList = screensStr?.split(", ");
    var filteredScreens = screensDropdownOptions.filter((obj) =>
      screensList.includes(obj.name)
    );
    var screenIds = filteredScreens.map((x) => x.id);
    return screenIds;
  };

  const getCategoriesIdsByNames = (names) => {
    names = names.map((x) => x.replaceAll(" > ", ">"));
    var filteredCategories = categoriesDropdownOptions.filter((obj) =>
      names.includes(obj.name)
    );
    var categoriesIds = filteredCategories.map((x) => x.id);
    return categoriesIds;
  };

  const copyPermissions = (userDetails) => {
    props.changeSelectedCompetitors(
      getCompetitorIdsByNames(userDetails.competitors)
    );
    props.changeSelectedCategories(
      getCategoriesIdsByNames(userDetails.permittedCategories)
    );
    props.changeSelectedScreens(getScreenIdsByList(userDetails.screens[0]));
    setDomain(userDetails.rootDomain[0]);
  };

  const onChooseCopyPermissions = (selectedItem) => {
    var selectedCopyPermissionsUser = [];
    var selected = selectedItem[0];
    if (selected) {
      //user has been selected to copy from.
      selectedCopyPermissionsUser.push(selected.id);
      var name = selected.name;
    } else {
      // unselecting user, put permissions back to original.
      selectedCopyPermissionsUser = [];
      var name = email;
    }
    var userDetails = props.usersList.find((user) => user.userName == name);
    if (userDetails) {
      copyPermissions(userDetails);
    } else {
      // in add new user screen and unselect the copy permissions user.
      copyPermissions({
        competitors: [],
        screens: [""],
        permittedCategories: [],
        rootDomain: [""],
      });
    }
    setCopyPermissionsUser(selectedCopyPermissionsUser);
  };

  const onChangeCategoriesValue = (selectedItems) => {
    const filtered = selectedItems.map((x) => x.id);
    props.changeSelectedCategories(filtered);
  };

  const onChangeScreensValue = (selectedItems) => {
    const filtered = selectedItems.map((x) => x.id);
    props.changeSelectedScreens(filtered);
  };

  const onChangeCompetitorsValue = (selectedItems) => {
    const filtered = selectedItems.map((x) => x.id);
    props.changeSelectedCompetitors(filtered);
  };

  useEffect(() => {
    setFirstName(props.selectedUser.firstName);
    setLastName(props.selectedUser.lastName);
    setDomain(props.selectedUser.domain);
    setEmail(props.selectedUser.email);
  }, [props.selectedUser]);

  const title = props.update ? "Delete / Update" : "Create New";
  return (
    <form className={styles.createUserFormWrapper} onSubmit={onSubmit}>
      {props.tableStatus.loading ? <div className={styles.loader}></div> : null}
      <div className={styles.createuserForm}>
        <h1 className={styles.createUserTitle}>{title} User</h1>
        {props.httpError.active ? (
          <div className={styles.httpError}>{props.httpError.message}</div>
        ) : null}
        {!props.update && (
          <div className={styles.multipleCreationWrapper}>
            <div className={styles.multipleCreationLeft}>
              <input
                className={styles.checkboxInput}
                type="checkbox"
                defaultChecked={multipleCreation}
                onChange={(e) => setMultipleCreation(!multipleCreation)}
              />
            </div>
            <div className={styles.multipleCreationRight}>
              Multiple Creations
            </div>
          </div>
        )}
        <label className={styles.userLabel}>
          Email:
          <input
            className={styles.userInput}
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </label>

        {!props.update && (
          <label className={styles.userLabel}>
            Password:
            <input
              className={styles.userInput}
              name="password"
              type="text"
              value={password}
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label
              className={styles.generatePassbutton}
              onClick={() => generatePassword()}
            >
              Generate
            </label>
          </label>
        )}

        <label className={styles.userLabel}>
          First Name:
          <input
            className={styles.userInput}
            name="text"
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
          />
        </label>

        <label className={styles.userLabel}>
          Last Name:
          <input
            className={styles.userInput}
            name="text"
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
          />
        </label>

        {
          <label className={styles.userLabel}>
            Copy Permissions From:
            <FilterAttributesDropdown
              maxSelectedItems={1}
              options={copyPermissionUserList}
              selectedItems={copyPermissionsUser}
              handleChangeAttributesValues={onChooseCopyPermissions}
            />
          </label>
        }

        <label className={styles.userLabel}>
          Domain:
          <select
            className={styles.userInput}
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          >
            <option value="Choose" hidden>
              Choose Domain...
            </option>
            <option value={null}>None</option>
            {competitors.map((option) => (
              <option value={option.rootDomain} key={option.rootDomain}>
                {option.name}
              </option>
            ))}
          </select>
        </label>

        <label className={styles.userLabel}>
          Categories:
          <FilterAttributesDropdown
            keepInput={true}
            maxSelectedItems={categories.length}
            selectedItems={selectedCategories}
            options={categoriesDropdownOptions}
            handleChangeAttributesValues={onChangeCategoriesValue}
          />
        </label>
        <label className={styles.userLabel}>
          Competitors:
          <FilterAttributesDropdown
            maxSelectedItems={competitors.length}
            selectedItems={selectedCompetitors}
            options={competitorsDropdownOptions}
            handleChangeAttributesValues={onChangeCompetitorsValue}
          />
        </label>
        <label className={styles.userLabel}>
          Screens:
          <FilterAttributesDropdown
            maxSelectedItems={screens.length}
            selectedItems={selectedScreens}
            options={screensDropdownOptions}
            handleChangeAttributesValues={onChangeScreensValue}
          />
        </label>

        {!props.update && (
          <button className={styles.createUserButton} onClick={addUser}>
            Submit
          </button>
        )}
        {props.update && (
          <button className={styles.createUserButton} onClick={updateUser}>
            Update
          </button>
        )}
        {props.update && (
          <button className={styles.deleteUserButton} onClick={deleteUser}>
            Delete
          </button>
        )}
      </div>
    </form>
  );
}

export const mapStateToProps = (state) => {
  return {
    httpError: state.administration.administration.httpError,
    tableStatus: state.administration.administration.tableStatus,
    competitors: state.administration.administration.competitors,
    categories: state.administration.administration.categories,
    screens: state.administration.administration.screens,

    selectedCompetitors:
      state.administration.administration.selectedCompetitors,
    selectedCategories: state.administration.administration.selectedCategories,
    selectedScreens: state.administration.administration.selectedScreens,

    selectedUser: state.administration.administration.selectedUser,
    usersList: state.administration.administration.usersTableData,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSelectedCategories,
      changeSelectedCompetitors,
      changeSelectedScreens,
      addNewUser,
      deleteUser,
      updateUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UserForm);
