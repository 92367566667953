import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./nested-dropdown.scss";
import CustomScrollbar from "../scrollbar/customScrollbar";
import ListSearch from "../list-search/list-search";
import CheckBlack from "../../images/check-black.svg";
import DropdownChevron from "../../images/dropdown.svg";

/*
    options: [
        {
            key: "Feature",
            options: [
                {
                    key: "Color"
                },
                {
                    key: "Size"
                }
            ]
        }
    ]
*/

const DropdownBody = ({ options, selected, onSelect, setOpenSub }) => {
  const [hoverIndex, setHoverIndex] = useState(null);
  const [searchedOptions, setSearchedOptions] = useState(options);

  useEffect(() => {
    if (setOpenSub) {
      if (hoverIndex !== null && options[hoverIndex].options)
        setOpenSub(hoverIndex);
      else setOpenSub(null);
    }
  }, [hoverIndex]);

  useEffect(() => {
    setSearchedOptions(options);
  }, [options]);

  const DropdownContent = () => {
    return (
      <ul className={styles.dropDownItems}>
        {searchedOptions.map((option, i) =>
          !option.disabled ? (
            <li
              className={styles.dropDownItem}
              key={option.key}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                onSelect(option.key);
              }}
              onMouseEnter={() => setHoverIndex(i)}
            >
              <div
                className={
                  selected === option.key ? styles.selected : styles.unselected
                }
              >
                <div className={styles.dropDownItemContents}>
                  {selected === option.key && (
                    <img className={styles.checkmark} src={CheckBlack} />
                  )}
                  {option.key}
                  {option.options && (
                    <img
                      className={styles.chevron}
                      style={{ transform: "rotate(270deg)" }}
                      src={DropdownChevron}
                    />
                  )}
                </div>
              </div>
            </li>
          ) : (
            <li className={styles.dropDownItemDisabled} key={option.key}>
              {option.key}
            </li>
          )
        )}
      </ul>
    );
  };

  const hasSearch = options.length > 8;

  return (
    <div className={styles.dropDownBody}>
      {hasSearch && (
        <ListSearch
          unfilteredData={options}
          setFilteredData={setSearchedOptions}
          fieldAccessor={"key"}
        />
      )}
      <div
        style={hasSearch ? { paddingBottom: "4px" } : {}}
        className={!setOpenSub ? styles.subDropdown : undefined}
      >
        {hasSearch ? (
          <CustomScrollbar
            style={{ height: 300, innerWidth: 200 }}
            styles={styles}
          >
            <DropdownContent />
          </CustomScrollbar>
        ) : (
          <DropdownContent />
        )}
      </div>
    </div>
  );
};

const NestedDropdown = ({ options, selected, onSelect }) => {
  const [openSub, setOpenSub] = useState(null);

  return (
    <div className={styles.dropDownsContainer}>
      <div className={styles.dropDownsLayout}>
        <DropdownBody
          options={options}
          selected={selected.main}
          onSelect={(mainKey) =>
            !openSub &&
            onSelect({
              main: mainKey,
            })
          }
          setOpenSub={setOpenSub}
        />
        {openSub !== null && (
          <DropdownBody
            options={options[openSub].options}
            selected={selected.sub}
            onSelect={(subKey) =>
              onSelect({
                main: options[openSub].key,
                sub: subKey,
              })
            }
          />
        )}
      </div>
    </div>
  );
};

export default NestedDropdown;
