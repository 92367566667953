import { getDefaultCompetitorName } from "./getDefaultCompetitor";

const getDefaultSkusConfig = (competitorsOptions) => {
  const competitorRootDomain = getDefaultCompetitorName(
    competitorsOptions,
    "",
    true
  );
  const config = {
    limit: 20,
    offset: 0,
    competitorRootDomain: competitorRootDomain,
    competitorProductNameQuery: "",
    sort: "Views Diff",
    filter: "",
    sortDescending: "true",
    getMissingMatchedSkus: "false",
  };

  return config;
};

const generateFieldsSkusConfig = (filterConfig, skusConfig) => {
  const {
    price,
    unitRank,
    daysOutOfStock,
    daysOutOfStockComp,
    competitorBrand,
    competitorRootDomain,
    competitorProductNameQuery,
    priceChecked,
    unitRankChecked,
    daysOutOfStockChecked,
    daysOutOfStockCompChecked,
    competitorBrandChecked,
  } = filterConfig;

  return {
    ...skusConfig,
    priceLower: priceChecked ? price.from : undefined,
    priceUpper: priceChecked ? price.to : undefined,

    unitRankLower: unitRankChecked ? unitRank.from : undefined,
    unitRankUpper: unitRankChecked ? unitRank.to : undefined,

    daysOutOfStockLower: daysOutOfStockChecked
      ? daysOutOfStock.from
      : undefined,
    daysOutOfStockUpper: daysOutOfStockChecked ? daysOutOfStock.to : undefined,

    daysOutOfStockCompLower: daysOutOfStockCompChecked
      ? daysOutOfStockComp.from
      : undefined,
    daysOutOfStockCompUpper: daysOutOfStockCompChecked
      ? daysOutOfStockComp.to
      : undefined,

    competitorBrand: competitorBrandChecked ? competitorBrand : undefined,
    competitorProductNameQuery: competitorProductNameQuery?.length
      ? competitorProductNameQuery[0]
      : skusConfig.competitorProductNameQuery,
    competitorRootDomain:
      competitorRootDomain || skusConfig.competitorRootDomain,
  };
};

export default getDefaultSkusConfig;

export { getDefaultSkusConfig, generateFieldsSkusConfig };
