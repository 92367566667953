import React, { useState, useEffect, useRef } from "react";
import styles from "./top-nav-dropdown.scss";
import salesAndShareIcon from "../../../src/images/sales-share-nav-icon.svg";
import insightsIcon from "../../../src/images/insights-nav-icon.svg";
import catalogTrackingIcon from "../../../src/images/tracking-nav-icon.svg";
import chevron from "../../../src/images/chevron-nav-icon.svg";
import userNavIcon from "../../../src/images/user-nav-icon.svg";

export default function TopNavDropdown(props) {
  const { title, options } = props;

  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const handleToggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const clickAnywhere = (e) => {
    if (!dropdownRef.current.contains(e.target)) setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickAnywhere);

    return () => {
      document.removeEventListener("mousedown", clickAnywhere);
    };
  }, []);

  const getImgIcon = (icon) => {
    switch (icon) {
      case "Sales and Share":
        return salesAndShareIcon;
      case "Insights":
        return insightsIcon;
      case "eComm Tracking":
        return catalogTrackingIcon;
      case "Administration":
        return userNavIcon;
    }
  };

  return (
    <div className={styles.topNavDropdownWrapper} ref={dropdownRef}>
      <div className={styles.topNavDropdown} onClick={handleToggleDropdown}>
        <img src={getImgIcon(title)} className={styles.navDropdownIcon} />
        <div className={styles.navDropdownTitle}>{title}</div>
        <img src={chevron} className={styles.navChevronIcon} />
      </div>

      {/* {isOpen && ( */}
      <div
        className={styles.topNavDropdownContent}
        style={{ display: isOpen ? "block" : "none" }}
      >
        {options?.map((option, index) => (
          <div
            key={index}
            onClick={handleToggleDropdown}
          >
            <div>{option.navLink[0]}</div>
          </div>
        ))}
      </div>
      {/* )} */}
    </div>
  );
}
