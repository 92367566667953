import React, { Fragment } from "react";

import TrafficSourceList from "../../components/traffic-source-list";

import styles from "./product-details.scss";

import utils from "../../utils";

const { getCurrencySignByDomain } = utils.other;

import {
  capitalizeFirstLetter,
  percentsNumber,
} from "../../utils/other/utilities.js";

export const DiscoverabilityChartType = {
  COMPARISON: 0,
  NON_COMPARISON: 1,
};

const getImages = (competitors = [], rootDomain) => {
  let result = "";

  competitors.map((item) => {
    if (item.rootDomain === rootDomain) {
      result = item.logoImage;
    }
  });

  return result;
};

const getTermName = (competitors = [], rootDomain) => {
  let result = "";

  competitors.map((item) => {
    if (item.rootDomain === rootDomain) {
      result = item.name;
    }
  });

  return result;
};

const ProductDetails = ({
  item,
  viewTypeId,
  extendedmMode = false,
  competitors = [],
  customerLogo = "",
  graphs,
  discoverabilityChartType,
}) => {
  const { competitorSku, matchedCustomerSku, insights } = item;
  const categoryPath = !!competitorSku ? competitorSku.categoryPath : null;
  const matchedCategoryPath = !!matchedCustomerSku
    ? matchedCustomerSku.categoryPath
    : null;

  const imgUrl =
    !!competitorSku && competitorSku.images && competitorSku.images.length > 0
      ? competitorSku.images[0]
      : "";
  const matchedImgUrl =
    !!matchedCustomerSku &&
    matchedCustomerSku.images &&
    matchedCustomerSku.images.length > 0
      ? matchedCustomerSku.images[0]
      : "";

  const imgProductStndMode =
    !extendedmMode && imgUrl !== "" ? (
      <div className={styles.imgProductCont}>
        <div style={{ backgroundImage: `url(${imgUrl})` }}></div>
      </div>
    ) : null;

  const imgProductExtMode =
    extendedmMode && imgUrl !== "" ? (
      <div className={styles.imgProductCont}>
        <div style={{ backgroundImage: `url(${imgUrl})` }}></div>
      </div>
    ) : null;

  const matchedImgProductExtMode = extendedmMode ? (
    <div className={styles.imgProductCont}>
      <div style={{ backgroundImage: `url(${matchedImgUrl})` }}></div>
    </div>
  ) : null;

  const matchedImgProductStndMode = !extendedmMode ? (
    <div className={styles.imgProductCont}>
      <div style={{ backgroundImage: `url(${matchedImgUrl})` }}></div>
    </div>
  ) : null;

  const breadcrumbs = categoryPath
    ? categoryPath.map((item, i) => {
        const breadcrumbsLength = categoryPath.length;
        if (breadcrumbsLength === i + 1) {
          return <span key={item + i}>{item}</span>;
        } else {
          return (
            <Fragment key={item}>
              <span>{item}</span>{" "}
            </Fragment>
          );
        }
      })
    : null;

  const matchedBreadcrumbs = matchedCategoryPath
    ? matchedCategoryPath.map((item, i) => {
        const breadcrumbsLength = matchedCategoryPath.length;
        if (breadcrumbsLength === i + 1) {
          return <span key={item + i}>{item}</span>;
        } else {
          return (
            <Fragment key={item}>
              <span>{item}</span>{" "}
            </Fragment>
          );
        }
      })
    : null;

  const logoCompetitor = extendedmMode ? (
    <div className={styles.logoCompetitor}>
      <img src={getImages(competitors, competitorSku.rootDomain)} />
    </div>
  ) : null;

  const logoMatchedCustomer = extendedmMode ? (
    <div className={styles.logoCompetitor}>
      <img src={customerLogo} />
    </div>
  ) : null;

  const termName = getTermName(competitors, competitorSku.rootDomain);

  const noSearchTerms =
    competitorSku.searchTerms?.googleSearchTerms == 0 &&
    competitorSku.searchTerms?.internalSearchTerms == 0 &&
    ((matchedCustomerSku &&
      matchedCustomerSku.searchTerms?.googleSearchTerms == 0 &&
      matchedCustomerSku.searchTerms?.internalSearchTerms == 0) ||
      !matchedCustomerSku);

  let variantsArray = competitorSku?.mbVariants?.split(",")?.slice() ?? [];
  const maxArrayDisplaySize = 10;
  variantsArray = variantsArray?.filter((x) => x != competitorSku.sku);
  const variantsLen = variantsArray?.length ?? 0;
  variantsArray?.sort();
  if (variantsLen > maxArrayDisplaySize && variantsLen > 0) {
    variantsArray.length = maxArrayDisplaySize;
    variantsArray.push("...");
  }
  const variantsList = variantsArray?.map((variant, index) => (
    <li key={"variant_" + index}>{variant}</li>
  ));

  return (
    <div
      className={`${styles.wrapper} ${extendedmMode ? styles.extended : ""}`}
    >
      {/* customer */}

      {viewTypeId == 0 && !matchedCustomerSku && (
        <div className={styles.section}>
          <div className={styles.col}> </div>
          <div className={styles.col}>
            <p className={styles.info}>
              Listed:<span> No</span>
            </p>
            <div className={styles.empty}></div>
          </div>
        </div>
      )}

      {viewTypeId == 0 && matchedCustomerSku && (
        <div className={styles.section}>
          {logoMatchedCustomer}
          <div className={styles.col}>{matchedImgProductStndMode}</div>
          <div className={styles.col}> </div>
          <div className={styles.col}>
            <p className={styles.title}>{matchedCustomerSku.name}</p>
            <div className={styles.breadcrumbs}>{matchedBreadcrumbs}</div>
            <div className={styles.info}>
              Brand:<span> {matchedCustomerSku.brand}</span>
            </div>
            <div className={styles.info}>
              Price:<span> ${matchedCustomerSku.price}</span>
            </div>
            {!extendedmMode ? <div className={styles.lineVert}></div> : null}
            <div className={styles.info}>
              SKU:
              {matchedCustomerSku.url ? (
                <a
                  id="product_details_sku_text"
                  href={matchedCustomerSku.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {matchedCustomerSku.sku}
                </a>
              ) : (
                <span id="product_details_sku_text">
                  {" "}
                  {matchedCustomerSku.sku}
                </span>
              )}
            </div>
            <div className={styles.article}>{matchedImgProductExtMode}</div>
            <p className={styles.info}>
              Listed:<span> Yes</span>
            </p>
            <div className={styles.line}></div>
            <div className={styles.details}>
              <div>
                MPN
                <span>{matchedCustomerSku.mpn}</span>
              </div>
              <div>
                UPC
                <span>{matchedCustomerSku.upc}</span>
              </div>
              <div>
                EAN
                <span>{matchedCustomerSku.ean}</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* competitor */}

      <div className={styles.section}>
        {logoCompetitor}
        <div className={styles.col}>{imgProductStndMode}</div>
        <div className={styles.col}>
          <p className={styles.title}>{competitorSku.name}</p>
          <div className={styles.breadcrumbs}>{breadcrumbs}</div>
          <div className={styles.info}>
            Brand:<span> {competitorSku.brand}</span>
          </div>
          <div className={styles.info}>
            Price:
            <span>
              {competitorSku.price
                ? `${getCurrencySignByDomain(competitorSku.rootDomain)}${
                    competitorSku.price
                  }`
                : "N/A"}
            </span>
          </div>
          {!extendedmMode ? <div className={styles.lineVert}></div> : null}
          <div className={styles.info}>
            SKU:
            {competitorSku.url ? (
              <a
                id="product_details_sku_text"
                href={competitorSku.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {" "}
                {competitorSku.sku}{" "}
              </a>
            ) : (
              <span id="product_details_sku_text"> {competitorSku.sku} </span>
            )}
          </div>
          {variantsLen > 0 && (
            <div className={styles.info}>
              Variants:
              <span id="product_details_variants_text">
                <ul className={styles.info}>{variantsList}</ul>{" "}
              </span>
            </div>
          )}
          <div className={styles.article}>
            {imgProductExtMode}
            {/* //competitorSku.description is actually competitorSku.name in backened - NEED TO FIX */}
            {/* <div>
                            <p className={styles.desc}>{competitorSku.description}</p>
                        </div> */}
          </div>
          <div className={styles.details}>
            <div>
              MPN
              <span> {competitorSku.mpn}</span>
            </div>
            <div>
              UPC
              <span>{competitorSku.upc}</span>
            </div>
            <div>
              EAN
              <span>{competitorSku.ean}</span>
            </div>
          </div>
        </div>
      </div>

      {/* graphs */}

      {
        <div className={styles.section}>
          <div className={styles.col}> </div>

          {graphs && (
            <ul style={{ padding: 0 }}>
              {graphs?.map((chartWrapper, index) => {
                if (chartWrapper.graph.props.dataClient) {
                  return (
                    <li key={"graph_" + index} style={{ listStyle: "none" }}>
                      {index == 0 && <h6> {chartWrapper.title} </h6>}
                      {index != 0 && <h5> {chartWrapper.title} </h5>}
                      <span></span>
                      {chartWrapper.graph}
                    </li>
                  );
                }
              }) ?? (
                <li key={"graph_empty"}>
                  <h5> </h5>
                </li>
              )}
            </ul>
          )}

          <div className={styles.col}> </div>

          {/* search terms */}

          {discoverabilityChartType &&
            discoverabilityChartType == DiscoverabilityChartType.COMPARISON &&
            renderComparisonDiscoverabilityChart(
              noSearchTerms,
              competitorSku,
              matchedCustomerSku,
              termName
            )}

          {discoverabilityChartType &&
            discoverabilityChartType ==
              DiscoverabilityChartType.NON_COMPARISON &&
            renderNonComparisonDiscoverabilityChart(competitorSku)}
        </div>
      }
    </div>
  );
};

const renderNonComparisonDiscoverabilityChart = (competitorSku) => {
  return (
    <div>
      {competitorSku?.searchTermsHistory &&
        Object.keys(competitorSku?.searchTermsHistory).length > 0 && (
          <div className={styles.termsCont}>
            <h4>Discoverability</h4>
            <p className={styles.subhead}>Internal Search Engine Results</p>
            <table>
              <tbody>
                <tr>
                  <th>Top Search Term</th>
                  <th>Share of Search</th>
                </tr>
              </tbody>
              {competitorSku.searchTermsHistory &&
                Object.keys(competitorSku.searchTermsHistory).map((item, i) => (
                  <tbody key={i}>
                    <tr>
                      <td>{item}</td>
                      <td>
                        {percentsNumber(competitorSku.searchTermsHistory[item])}
                        %
                      </td>
                    </tr>
                  </tbody>
                ))}
            </table>
          </div>
        )}
    </div>
  );
};

const renderComparisonDiscoverabilityChart = (
  noSearchTerms,
  competitorSku,
  matchedCustomerSku,
  termName
) => {
  return (
    <div>
      {!noSearchTerms && (
        <div className={styles.termsCont}>
          <h4>Discoverability</h4>
          <p className={styles.subhead}>Internal Search Engine Results</p>
          <table>
            <tbody>
              <tr>
                <th>Top Search Term</th>
                {<th>Your Rank</th>}
                <th>{termName} Rank</th>
              </tr>
            </tbody>
            {matchedCustomerSku &&
              matchedCustomerSku.searchTerms &&
              matchedCustomerSku.searchTerms.internalSearchTerms.map(
                (item, i) => (
                  <tbody key={i}>
                    <tr>
                      <td>{item.term}</td>
                      <td>
                        {parseFloat(item.rank) > 10
                          ? "Not in 1st page"
                          : item.rank}
                      </td>
                      <td>
                        {competitorSku.searchTerms.internalSearchTerms.some(
                          (item_competitor) => item_competitor.term == item.term
                        )
                          ? competitorSku.searchTerms.internalSearchTerms.find(
                              (item_competitor) =>
                                item_competitor.term == item.term
                            ).rank > 10
                            ? "Not in 1st page"
                            : competitorSku.searchTerms.internalSearchTerms.find(
                                (item_competitor) =>
                                  item_competitor.term == item.term
                              ).rank
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                )
              )}
            {competitorSku.searchTerms &&
              matchedCustomerSku?.searchTerms &&
              competitorSku.searchTerms.internalSearchTerms
                .filter(
                  (item_competitor) =>
                    !matchedCustomerSku.searchTerms.internalSearchTerms.some(
                      (item_client) => item_client.term == item_competitor.term
                    )
                )
                .map((item, i) => (
                  <tbody key={i}>
                    <tr>
                      <td>{item.term}</td>
                      <td></td>
                      <td>
                        {parseFloat(item.rank) > 10
                          ? "Not in 1st page"
                          : item.rank}
                      </td>
                    </tr>
                  </tbody>
                ))}
          </table>
        </div>
      )}

      {!noSearchTerms && <br></br>}
      {!noSearchTerms && <br></br>}

      {!noSearchTerms && (
        <div className={styles.termsCont}>
          <h4>SEO</h4>
          <p className={styles.subhead}>Organic Top search terms for product</p>
          <table>
            <tbody>
              <tr>
                <th>Top Search Term</th>
                <th>Your Rank</th>
                <th>{termName} Rank</th>
              </tr>
            </tbody>
            {matchedCustomerSku &&
              matchedCustomerSku.searchTerms &&
              matchedCustomerSku.searchTerms.googleSearchTerms.map(
                (item, i) => (
                  <tbody key={i}>
                    <tr>
                      <td>{item.term}</td>
                      <td>
                        {parseFloat(item.rank) > 10
                          ? "Not in 1st page"
                          : item.rank}
                      </td>
                      <td>
                        {competitorSku.searchTerms.googleSearchTerms.some(
                          (item_competitor) => item_competitor.term == item.term
                        )
                          ? competitorSku.searchTerms.googleSearchTerms.find(
                              (item_competitor) =>
                                item_competitor.term == item.term
                            ).rank > 10
                            ? "Not in 1st page"
                            : competitorSku.searchTerms.googleSearchTerms.find(
                                (item_competitor) =>
                                  item_competitor.term == item.term
                              ).rank
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                )
              )}
            {competitorSku.searchTerms &&
              matchedCustomerSku?.searchTerms &&
              competitorSku.searchTerms.googleSearchTerms
                .filter(
                  (item_competitor) =>
                    !matchedCustomerSku.searchTerms.googleSearchTerms.some(
                      (item_client) => item_client.term == item_competitor.term
                    )
                )
                .map((item, i) => (
                  <tbody key={i}>
                    <tr>
                      <td>{item.term}</td>
                      <td></td>
                      <td>
                        {parseFloat(item.rank) > 10
                          ? "Not in 1st page"
                          : item.rank}
                      </td>
                    </tr>
                  </tbody>
                ))}
          </table>
        </div>
      )}
    </div>
  );
};

export default ProductDetails;
