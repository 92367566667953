import React, { useEffect } from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { INDEX_COLORS } from "../../../market-share/constants";
import {
  fetchSimilarProductsGraph,
  resetSimilarSkusGraphs,
} from "../../actions";
import { SIMILAR_SKUS_RESULTS_SIZE, STATUS_IDLE } from "../../constants";

const SimilarSkus = ({
  selectedSku,
  similarSkusGraphs,
  fetchSimilarProductsGraph,
  forceLoading,
  similarSkusData,
  resetSimilarSkusGraphs,
  chartsConfigs,
  settings,
  familyVariantsData,
  currentViewConfigQuery,
  productAttributes,
  selectedDateRange,
  selectedPreviousDateRange,
  comparePeriod,
  children,
}) => {
  const skus = [
    selectedSku,
    ...(familyVariantsData?.selectedSku?.similarSkus?.split(",") ?? []),
  ];

  useEffect(() => {
    resetSimilarSkusGraphs();
  }, [
    familyVariantsData,
    productAttributes,
    selectedDateRange,
    selectedPreviousDateRange,
    comparePeriod,
  ]);

  const fetchTabGraphData = (tabConfigs, tabId) => {
    const pivot = tabConfigs.find((x) => x.id === tabId)?.pivot;
    if (pivot === undefined) return;
    const requestParams = {
      requestBody: {
        insightsViewQuery: {
          ...currentViewConfigQuery,
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          previousStartDate: selectedPreviousDateRange.previousStartDate,
          previousEndDate: selectedPreviousDateRange.previousEndDate,
          comparePeriod: comparePeriod,
        },
        skus: skus,
        pivot: pivot,
        size: SIMILAR_SKUS_RESULTS_SIZE,
      },
    };
    fetchSimilarProductsGraph(tabId, requestParams);
  };

  useEffect(() => {
    if (forceLoading || !familyVariantsData?.selectedSku) return;
    for (const chartName of Object.keys(chartsConfigs)) {
      const tabId = settings.charts?.[chartName]?.selectedTab;
      if (
        !similarSkusGraphs?.[tabId]?.status ||
        similarSkusGraphs?.[tabId]?.status == STATUS_IDLE
      ) {
        fetchTabGraphData(chartsConfigs[chartName], tabId);
      }
    }
  }, [similarSkusGraphs, settings]);

  const [selectedKeys, setSelectedKeys] = useState([]);

  const getEntities = () => {
    const keys = {};
    if (forceLoading || !(similarSkusData?.length > 0)) return keys;
    for (const i of selectedKeys) {
      const skuData = similarSkusData[i];
      if (!skuData) continue;
      keys[skuData.sku] = {
        stroke: INDEX_COLORS[i],
        displayName: skuData.name,
        name: skuData.name,
        image: skuData.image,
      };
    }
    return keys;
  };

  const entities = getEntities();

  return children(selectedKeys, setSelectedKeys, entities);
};

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    familyVariantsData: state.product360.product360.familyVariants.data,
    selectedSku: state.product360.product360.selectedSku,
    settings:
      state.product360.insights.insights.currentViewConfig.query.settings,
    similarSkusData: state.product360.product360.similarSkusTable.data,
    similarSkusGraphs: state.product360.product360.similarSkusGraphs,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      resetSimilarSkusGraphs,
      fetchSimilarProductsGraph,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SimilarSkus);
