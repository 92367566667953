export const getEnumDisplayNameFromDefinitions = (
  filterDefinitions,
  item,
  value
) => {
  var filterDefinition = filterDefinitions.find(
    (x) =>
      x.mbName === item.mbName &&
      x.type === item.type &&
      (x.displayName ?? "" === item.displayName ?? "")
  );
  return getEnumDisplayNameFromDefinition(filterDefinition, value);
};

export const getEnumDisplayNameFromDefinition = (filterDefinition, value) => {
  if (filterDefinition) {
    var enumDictionaryIndex = Object.keys(
      filterDefinition.enumDictionary
    ).findIndex((x) => x === value);
    return filterDefinition.values[enumDictionaryIndex];
  }
};

export const getEnumValueNameFromDefinitions = (
  filterDefinitions,
  item,
  value
) => {
  var filterDefinition = filterDefinitions.find(
    (x) => x.mbName === item.mbName
  );
  return getEnumValueNameFromDefinition(filterDefinition, value);
};

export const getEnumValueNameFromDefinition = (filterDefinition, value) => {
  var enumDictionaryIndex = filterDefinition.values.findIndex(
    (x) => x === value
  );
  return Object.keys(filterDefinition.enumDictionary)[enumDictionaryIndex];
};
