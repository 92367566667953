import {
  OPEN_LOGOUT_BLOCK,
  CLOSE_LOGOUT_BLOCK,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
} from "../constants";

import AuthService from "../../../services/authService";
import utils from "../../../utils";
import history from "../../../history";

const { deleteToken } = utils.auth;

const authService = new AuthService();

export const openLogoutBlock = () => ({
  type: OPEN_LOGOUT_BLOCK,
});

export const closeLogoutBlock = () => ({
  type: CLOSE_LOGOUT_BLOCK,
});

export const logoutRequested = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccessed = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutError = (error) => ({
  type: LOGOUT_FAILURE,
  payload: { error },
});

export const logoutRequest = () => (dispatch) => {
  dispatch(logoutRequested());

  deleteToken();
  history.push("/login");
  dispatch(logoutSuccessed());

  // authService.logoutRequest()
  //     .then(()=> {
  //         deleteToken();
  //         history.push('/login');
  //         dispatch(logoutSuccessed())
  //     })
  //     .catch((err) => {
  //         dispatch(logoutError(err.message))
  //     })
};
