import React from "react";
import styles from "./text-controls.scss";

export default function TextValue(props) {
  const { value, onClick } = props;
  return (
    <div className={styles.value}>
      {value != null && value.length > 32
        ? `${value.substring(0, 31)}...`
        : value}
      <div className={styles.closeButton} onClick={onClick}></div>
    </div>
  );
}
