import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubspaceProvider } from "react-redux-subspace";
import moment from "moment";
import { useReactToPrint } from "react-to-print";

import InsightsTemplate from "../insights-template/insights-template";
import styles from "./insights-board.scss";

import {
  convertBrowserStringSortFieldToConfig,
  convertConfigSortFieldToBrowserString,
} from "../../utils/skus/convertSortValue";

import ComponentsContainer from "../../containers/insights-board/components-container/components-container";
import InsightsBoardConfig from "../../containers/insights-board/insights-board-config";

import InsightsBoardService from "../../services/insightsBoardService";
const insightsBoardService = new InsightsBoardService();

import { forceSingleRetailerSelection } from "../../utils/other/mbDataUtils";

import { analyticsExportPdf } from "../../containers/shared/analytics/actions";
import PdfContainer from "../../containers/insights-board/pdf-container/pdf-container";
import { getCategoriesFromConfig } from "../../utils/skus/getCategories";
import MbComponentsDataFetcher from "../../mb_components/mb-components-data-fetcher/mb-components-data-fetcher";
import MbMarketShareWarningContainer from "../../mb_components/mb-market-share-warning/mb-market-share-warning-container.js";
import { hasSelectedCategories } from "../../containers/market-share/utils";

const mbComponentsDataFetcher = new MbComponentsDataFetcher();

import {
  fetchDataFail,
  fetchDataRequest,
  fetchDataSuccess,
} from "../../containers/insights-board/actions";
import { ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA } from "../../containers/insights-board/constants";
import Product360 from "../product-360/product-360";
import getLastPredictionDate from "../../utils/skus/getLastPredictionDate.js";

function InsightsBoard(props) {
  const [lastConfig, setLastConfig] = useState("");
  const pdfExportComponent = useRef();
  const {
    selectedDateRange,
    currentViewConfig,
    domainsMetadata,
    selectedPreviousDateRange,
    comparePeriod,
    insightsBoard,
    selectedSku,
  } = props;
  const viewTypeData = ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA;
  const search = props.location.search;

  const defaultDateRange = {
    startDate: moment()
      .subtract(30 - 1, "days")
      .endOf("days")
      .format("YYYY/MM/DD"),
    endDate: moment().format("YYYY/MM/DD"),
  };

  const currentSelectedRetailer = forceSingleRetailerSelection(
    props.filterDefinitions,
    props.insightsFiltersCollection
  );

  const lastAvailableDate = getLastPredictionDate(
    domainsMetadata,
    currentSelectedRetailer,
    props.insightsFiltersCollection
  );

  const incompleteMarketShareData = (
    <MbMarketShareWarningContainer
      currentEndDate={selectedDateRange?.endDate}
      previousStartDate={selectedPreviousDateRange?.previousStartDate}
      maxDate={lastAvailableDate}
      type="date"
    />
  );

  const queryToSend = {
    ...currentViewConfig.query,
    previousStartDate: selectedPreviousDateRange.previousStartDate,
    previousEndDate: selectedPreviousDateRange.previousEndDate,
    endDate: selectedDateRange.endDate,
    startDate: selectedDateRange.startDate,
    comparePeriod: comparePeriod,
    // maybe remove the bottom here?
    selectedDateRange: selectedDateRange,
  };

  const categories = getCategoriesFromConfig(currentViewConfig, {
    return_as_product_types: true,
  });

  useEffect(() => {
    if (hasSelectedCategories(currentViewConfig)) {
      const result = mbComponentsDataFetcher.fetchDataIfRequired(
        currentSelectedRetailer,
        selectedDateRange,
        currentViewConfig,
        selectedPreviousDateRange,
        domainsMetadata,
        comparePeriod,
        InsightsBoardConfig,
        lastConfig,
        props.fetchDataFail,
        props.fetchDataRequest,
        props.fetchDataSuccess,
        viewTypeData.id
      );
      if (result) {
        setLastConfig(result);
      }
    }
  }, [
    currentSelectedRetailer,
    selectedDateRange,
    currentViewConfig,
    selectedPreviousDateRange,
  ]);

  const exportCsvCallback = (exportCsvComponentCallback) => {
    return exportCsvComponentCallback(queryToSend);
  };

  const handlePrint = useReactToPrint({
    documentTitle: `Account Performance ${selectedDateRange.startDate}-${selectedDateRange.endDate} ${props.currentViewConfig?.name}`,
    content: () => pdfExportComponent.current,
    onBeforePrint: () =>
      analyticsExportPdf(viewTypeData.id, currentViewConfig.name),
  });

  const pdfContainer = (
    <>
      <div className={styles.pdfContainer}>
        <div ref={pdfExportComponent}>
          <PdfContainer
            selectedDateRange={selectedDateRange}
            selectedPreviousDateRange={selectedPreviousDateRange}
            comparePeriod={comparePeriod}
            viewName={props.currentViewConfig?.name}
            configuration={InsightsBoardConfig}
            componentsData={props.components}
            incompleteMarketShareData={incompleteMarketShareData}
            categories={categories}
          />
        </div>
      </div>
    </>
  );

  const componentsContainer = (
    <ComponentsContainer
      selectedDateRange={selectedDateRange}
      selectedPreviousDateRange={selectedPreviousDateRange}
      comparePeriod={comparePeriod}
      configuration={InsightsBoardConfig}
      componentsData={props.components}
      exportCsvCallback={exportCsvCallback}
      categories={categories}
      state={insightsBoard}
    />
  );

  const body = (
    <>
      {incompleteMarketShareData}
      {pdfContainer}
      {componentsContainer}
    </>
  );

  const dataControlsOptions = {
    lastAvailableDate,
    maxDate: lastAvailableDate,
    viewTypeData,
    convertConfigSortFieldToBrowserString: convertConfigSortFieldToBrowserString,
    apiProvider: insightsBoardService,
    hideFrequency: true,
    hideExport: true,
    hideRetailer: true,
    hideExportPdf: false,
    hideAlert: true,
    exportPdfCallback: handlePrint,
    resetDateRange: defaultDateRange,
    addDatesToUrl: true,
    mbFilter: true,
    dataAvailableUntilLabel: "Data available until",
    dataUnavailableLabel: "Data is unavailable",
  };

  useEffect(() => {
    const url = new URL(document.location.href);
    if (!selectedSku && url.pathname != `/${viewTypeData.name}`) {
      url.pathname = viewTypeData.name;
      url.searchParams.delete("sku");
      history.pushState({}, "", url);
    }
  }, [selectedSku]);

  return (
    <>
      {selectedSku && <Product360 parentRedirect={true} />}
      <div style={selectedSku ? { display: "none" } : {}}>
        <SubspaceProvider
          mapState={(state) => state.insightsBoard}
          namespace="insightsBoard"
        >
          <InsightsTemplate
            apiProvider={insightsBoardService}
            lastAvailableDate={lastAvailableDate}
            viewTypeData={viewTypeData}
            dataControlsOptions={dataControlsOptions}
            search={search}
            body={body}
            convertBrowserStringSortFieldToConfig={
              convertBrowserStringSortFieldToConfig
            }
          />
        </SubspaceProvider>
      </div>
    </>
  );
}

export const mapStateToProps = (state) => {
  const {
    selectedDateRange,
    selectedCompetitor,
    selectedTimeFrequency,
    selectedPreviousDateRange,
    comparePeriod,
  } = state.insightsBoard.insights.mbFilterRow;

  const { domainsMetadata } =
    state.insightsBoard.insights.insights.viewDefinitions?.viewMetadata ?? {};

  return {
    domainsMetadata,
    selectedDateRange,
    selectedCompetitor,
    selectedTimeFrequency,
    currentViewConfig: state.insightsBoard.insights.insights.currentViewConfig,
    filterDefinitions:
      state.insightsBoard.insights.insights.viewDefinitions?.filterDefinitions,
    insightsFiltersCollection:
      state.insightsBoard.insights.insights.currentViewConfig.query
        .productAttributes,
    components: state.insightsBoard.insightsBoard.components,
    selectedPreviousDateRange,
    comparePeriod,
    insightsBoard: state.insightsBoard,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { fetchDataFail, fetchDataRequest, fetchDataSuccess },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InsightsBoard);
