import styles from "./insights-template-landing-page.scss";
import React from "react";
import pic from "../../images/no-category-selected.svg";

function InsightsTemplateLandingPage(props) {
  var displayText = props.displayText
    ? props.displayText
    : "Select at least one category to get insights.";
  return (
    <div className={styles.landingPageContainer}>
      <div className={styles.imageContainer}>
        <img src={pic} />
      </div>
      <div className={styles.textContainer}>
        <p className={styles.selectCategoryText}>{displayText}</p>
      </div>
    </div>
  );
}

export default InsightsTemplateLandingPage;
