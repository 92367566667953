import React from "react";
import { chartTickPriceFormat } from "../../../../styles/cell-decorators";
import ChartComponent from "../../../../components/dashboard-components/chart-component/chart-component";

export default function CustomerPricingChartComponent(props) {
  return (
    <ChartComponent
      {...props}
      title={"Your Average Selling Price"}
      currentKey={"currentAvgSellingPrice"}
      previousKey={"previousAvgSellingPrice"}
      changeKey={"avgSellingPriceDiff"}
      taglineSubject={"Your average selling price"}
      legendName={"Average Selling Price"}
      yTickFormat={chartTickPriceFormat}
    />
  );
}
