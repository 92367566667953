import { extendMoment } from "moment-range";
import Moment from "moment";
import DatePickerService from "../../services/datePickerService";
import _ from "lodash";

const moment = extendMoment(Moment);
const datePickerService = new DatePickerService();

export const getDatesByChosenPeriod = (chosenPeriod, maxMoment) => {
  var dates;
  switch (chosenPeriod) {
    case "last week":
      dates = datePickerService.onClickLastXWeeks(1, maxMoment);
      break;
    case "last 4 weeks":
      dates = datePickerService.onClickLastXWeeks(4, maxMoment);
      break;
    case "last 13 weeks":
      dates = datePickerService.onClickLastXWeeks(13, maxMoment);
      break;
    case "today":
      dates = datePickerService.onClickToday(maxMoment);
      break;
    case "yesterday":
      dates = datePickerService.onClickYesterday(maxMoment);
      break;
    case "this week":
      dates = datePickerService.onClickThisWeek(maxMoment);
      break;
    case "this month":
      dates = datePickerService.onClickThisMonth(maxMoment);
      break;
    case "last month":
      dates = datePickerService.onClickLastMonth(maxMoment);
      break;
    case "this quarter":
      dates = datePickerService.onClickThisQuarter(maxMoment);
      break;
    case "last quarter":
      dates = datePickerService.onClickLastQuarter(maxMoment);
      break;
    case "this year":
      dates = datePickerService.onClickThisYear(maxMoment);
      break;
    case "last year":
      dates = datePickerService.onClickLastYear(maxMoment);
      break;
    case "year to date":
      dates = datePickerService.onClickYTD(maxMoment);
      break;
  }
  return dates;
};

export const getLastXWeeksDates = (maxDate, numOfWeeks) => {
  const maxMoment = moment(maxDate);
  var lastMonday, lastSunday;
  if (maxMoment.weekday() == 0) {
    lastSunday = maxMoment.clone();
    lastMonday = maxMoment.clone().startOf("isoWeek");
    numOfWeeks -= 1;
  } else {
    lastMonday = maxMoment.clone().startOf("isoWeek");
    lastSunday = lastMonday.clone().subtract(1, "days");
  }
  const data = {
    startDate: lastMonday.subtract(numOfWeeks, "weeks").format("YYYY/MM/DD"),
    endDate: lastSunday.format("YYYY/MM/DD"),
  };
  return data;
};

export const getLastXDaysDates = (maxDate, numOfDays) => {
  const maxMoment = moment(maxDate);
  var startDate = maxMoment
    .clone()
    .subtract(numOfDays - 1, "days")
    .endOf("days")
    .format("YYYY/MM/DD");
  var endDate = maxMoment.format("YYYY/MM/DD");
  const data = {
    startDate: startDate,
    endDate: endDate,
  };
  return data;
};

export const getDateRangeInfo = (
  maxDate,
  rollingId,
  selectedDateRange,
  mode = null
) => {
  const maxMoment = moment(maxDate);
  var data = {
    chosenPeriod: null,
    startDate: null,
    endDate: null,
  };
  var tempDateRange;
  switch (rollingId) {
    case 0:
      data.chosenPeriod = "Custom";
      tempDateRange = datePickerService.onClickLastXWeeks(4, maxMoment);
      break;
    case 1:
      data.chosenPeriod = "Rolling Last week";
      tempDateRange = datePickerService.onClickLastXWeeks(1, maxMoment);
      break;
    case 2:
      data.chosenPeriod = "Rolling Last 4 weeks";
      tempDateRange = datePickerService.onClickLastXWeeks(4, maxMoment);
      break;
    case 3:
      data.chosenPeriod = "Rolling Last 13 weeks";
      tempDateRange = datePickerService.onClickLastXWeeks(13, maxMoment);
      break;
    case 4:
      data.chosenPeriod = "Rolling Today";
      data.startDate = maxMoment._i;
      data.endDate = maxMoment._i;
      break;
    case 5:
      data.chosenPeriod = "Rolling Last 7 days";
      tempDateRange = datePickerService.onClickLastXDays(7, maxMoment);
      break;
    case 6:
      data.chosenPeriod = "Rolling Last 14 days";
      tempDateRange = datePickerService.onClickLastXDays(14, maxMoment);
      break;
    case 7:
      data.chosenPeriod = "Rolling Last 30 days";
      tempDateRange = datePickerService.onClickLastXDays(30, maxMoment);
      break;
    case 8:
      data.chosenPeriod = "Rolling Last 60 days";
      tempDateRange = datePickerService.onClickLastXDays(60, maxMoment);
      break;
    case 9:
      data.chosenPeriod = "Rolling Last 90 days";
      tempDateRange = datePickerService.onClickLastXDays(90, maxMoment);
      break;
    case 10:
      data.chosenPeriod = "Rolling Year to Date";
      tempDateRange = datePickerService.onClickYTD(maxMoment, mode);
      break;
    case 11:
      data.chosenPeriod = "Rolling Last 2 weeks";
      tempDateRange = datePickerService.onClickLastXWeeks(2, maxMoment);
      break;
  }
  if (tempDateRange) {
    data.startDate = tempDateRange.startDate;
    data.endDate = tempDateRange.endDate;
  } else {
    data.startDate = selectedDateRange.startDate;
    data.endDate = selectedDateRange.endDate;
  }
  return data;
};

export const getPreviousDates = (dateRange, comparePeriod) => {
  return datePickerService.getPreviousDates(dateRange, comparePeriod);
};

// In case date is null, return last Sunday according to today's date
export const getLastSunday = (date = null) => {
  var t = verifyDate(date);
  t.setDate(t.getDate() - t.getDay());
  return t;
};

export const getNextSunday = (date = null) => {
  var t = verifyDate(date);
  t.setDate(t.getDate() - t.getDay() + 7);
  return t;
};

const verifyDate = (date) => {
  return date
    ? date instanceof Date
      ? date
      : typeof date === "string"
      ? new Date(date)
      : new Date()
    : new Date();
};

export const getTimeBetween = (startDate, endDate, resolution = "days") => {
  return moment(endDate).diff(moment(startDate), resolution, true);
};
