export const CollectionOptions = [
  { id: 0, name: "Is", value: 0 },
  { id: 1, name: "Is not", value: 1 },
];

export const SingleValueCollectionOptions = [{ id: 0, name: "Is", value: 0 }];

export const TextOptions = [
  { id: 0, name: "Contains", value: 0 },
  { id: 1, name: "Doesn't contain", value: 1 },
];

export const NumericOptions = [
  { id: 0, name: "Equals to", value: 0 },
  { id: 1, name: "Less than", value: 1 },
  { id: 2, name: "Less than or Equals to", value: 2 },
  { id: 3, name: "Greater than", value: 3 },
  {
    id: 4,
    name: "Greater than or Equals to",
    value: 4,
  },
  { id: 5, name: "Between", value: 5 },
  { id: 6, name: "Not Between", value: 6 },
];
