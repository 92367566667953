import React from "react";
import styles from "./bool-controls.scss";
import { Radiobutton } from "../../../components/checkbox";
import { useEffect } from "react";
import { validateFilter } from "../filter-conversions";

export default function BoolControls(props) {
  const { item, onUpdateFilter } = props;

  const selectedValue = item.selectedProperties;

  useEffect(() => {
    if (!validateFilter(item)) onChangeValue(true);
  }, []);

  const onChangeValue = (value) => {
    onUpdateFilter(item.id, {
      selectedProperties: value,
    });
  };

  return (
    <div className={styles.controls}>
      <div className={styles.control}>
        <Radiobutton
          checked={selectedValue}
          onChange={() => onChangeValue(true)}
        />
        Yes
      </div>
      <div className={styles.control}>
        <Radiobutton
          checked={!selectedValue}
          onChange={() => onChangeValue(false)}
        />
        No
      </div>
    </div>
  );
}
