import React, { useEffect, useMemo, useState } from "react";

import {
  Grid,
  LineSeries,
  BarGroup,
  BarSeries,
  XYChart,
  Axis,
  Tooltip,
  buildChartTheme,
  useEventEmitter,
  BarStack,
  AreaStack,
  AreaSeries,
} from "@visx/xychart";
import {
  curveLinear,
  curveStep,
  curveCardinal,
  curveMonotoneX,
} from "@visx/curve";
import {
  CHART_CLICK_EVENT,
  CHART_NEAREST_KEY_CHANGE_EVENT,
} from "../constants";
import { getNumTicks } from "./xychart-utils";

const GRID_COLOR = "rgb(119 123 127 / 17%)";
const AXIS_COLOR = "#adb5bd66";

export default function XYChartWrapper(props) {
  const {
    id,
    showGridRows = true,
    showGridColumns = true,
    animationTrajectory = "center",

    data,
    dataKeys, // dataKeys is an object which keys are names of keys in data and value are props to pass to LineSeries
    curveType = "linear",
    xAxis = true,
    xScaleType = "time",
    xPadding,
    xPaddingInner,
    xValuesNumeric = true,
    xOrientation = "bottom",
    xLabel,
    xKey,
    xNumTicks = 10,
    xTickFormat = (x) => x,
    yAxis = true,
    yScaleType = "linear",
    yOrientation = "left",
    yLabel,
    yTickFormat = (x) => x,
    tooltipCallback,
    snapTooltipToDatumX = true,
    snapTooltipToDatumY = false,
    showTooltipVerticalCrosshair = true,
    showTooltipHorizontalCrosshair = false,
    height = 350,
    margin,
    highlightKey,
    chartType,
  } = props;
  const emit = useEventEmitter();
  const [nearestKey, setNearestKey] = useState(highlightKey);

  useEffect(() => {
    if (emit) emit(CHART_NEAREST_KEY_CHANGE_EVENT, { value: nearestKey }, id);
  }, [nearestKey]);

  const curveKeys = Object.keys(dataKeys);
  const colors = curveKeys.map((key) => dataKeys[key].stroke);
  const theme = buildChartTheme({ colors: colors });

  const xDomain = xValuesNumeric
    ? [
        Math.min.apply(
          null,
          data.map((x) => x[xKey])
        ),
        Math.max.apply(
          null,
          data.map((x) => x[xKey])
        ),
      ]
    : data.map((x) => x[xKey]);

  const yDomain = [
    data.length === 0
      ? 0
      : Math.min(...data.map((d) => Math.min(...curveKeys.map((x) => d[x])))),
    data.length === 0
      ? 0
      : Math.max(...data.map((d) => Math.max(...curveKeys.map((x) => d[x])))),
  ];

  const xScale = {
    type: xScaleType,
    domain: xDomain,
    padding: xPadding,
    paddingInner: xPaddingInner,
  };

  const yScaleConfig = {
    type: yScaleType,
    domain: yDomain,
  };

  const accessors = useMemo(
    () => ({
      x: Object.assign({}, ...curveKeys.map((x) => ({ [x]: (d) => d[xKey] }))), // take X from a single place
      y: Object.assign({}, ...curveKeys.map((x) => ({ [x]: (d) => d[x] }))), // take Y from dataKey in data
    }),
    [curveKeys]
  );

  const curveTypeSelector = () => {
    switch (curveType) {
      case "cardinal": {
        return curveCardinal;
      }
      case "step": {
        return curveStep;
      }
      case "monotoneCurve": {
        return curveMonotoneX;
      }
      default:
      case "linear": {
        return curveLinear;
      }
    }
  };

  const shouldHighlight = (dataKey) => {
    if (highlightKey) {
      if (highlightKey === dataKey) return true;
      return false;
    }
    return true;
  };

  const showGlyph = () => {
    if (highlightKey) {
      if (highlightKey === nearestKey) return true;
      return false;
    }
    if (nearestKey) return true;
    return false;
  };

  const getTickValues = () => {
    // Expects time values as unix timestamp
    if (xScaleType == "time") {
      const tickDates = data.map((x) => new Date(x[xKey]));
      if (tickDates.length > xNumTicks) {
        return tickDates.filter((x, i) => {
          return i % Math.ceil(tickDates.length / xNumTicks) === 0;
        });
      }
      return tickDates;
    }
    return undefined;
  };

  const lineGroup = (
    <>
      {curveKeys.map((dataKey) => (
        <LineSeries
          key={dataKey}
          dataKey={dataKey}
          data={data}
          xAccessor={accessors.x[dataKey]}
          yAccessor={accessors.y[dataKey]}
          curve={curveTypeSelector()}
          opacity={shouldHighlight(dataKey) ? 1 : 0.25}
          {...dataKeys[dataKey]}
        />
      ))}
    </>
  );

  const barGroup = (
    <BarGroup>
      {curveKeys.map((dataKey) => (
        <BarSeries
          key={dataKey}
          dataKey={dataKey}
          data={data}
          xAccessor={accessors.x[dataKey]}
          yAccessor={accessors.y[dataKey]}
        />
      ))}
    </BarGroup>
  );

  const barStack = (
    <BarStack>
      {curveKeys.map((dataKey) => (
        <BarSeries
          key={dataKey}
          dataKey={dataKey}
          data={data}
          xAccessor={accessors.x[dataKey]}
          yAccessor={accessors.y[dataKey]}
        />
      ))}
    </BarStack>
  );

  const lineStack = (
    <AreaStack curve={curveTypeSelector()} renderLine={true}>
      {curveKeys.map((dataKey) => (
        <AreaSeries
          key={dataKey}
          dataKey={dataKey}
          data={data}
          xAccessor={accessors.x[dataKey]}
          yAccessor={accessors.y[dataKey]}
          fillOpacity={shouldHighlight(dataKey) ? 0.4 : 0.1}
        />
      ))}
    </AreaStack>
  );

  const chart = () => {
    switch (chartType) {
      case "bar":
        return barGroup;
      case "barStack":
        return barStack;
      case "lineStack":
        return lineStack;
      case "lineGroup":
        return lineGroup;
      default:
        return <></>;
    }
  };

  const handlePointerUp = (e) => {
    if (emit)
      emit(
        CHART_CLICK_EVENT,
        {
          value: {
            entity: nearestKey,
            x: e.event.clientX,
            y: e.event.pageY,
            datum: e.datum,
          },
        },
        id
      );
  };

  const yNumTicks = getNumTicks(yTickFormat, yDomain);

  return (
    <XYChart
      theme={theme}
      xScale={xScale}
      yScale={yScaleConfig}
      height={height}
      margin={margin}
      pointerEventsDataKey={"nearest"}
      onPointerMove={(e) => setNearestKey(e.key)}
      onPointerUp={handlePointerUp}
    >
      <Grid
        key={`grid-${animationTrajectory}`} // force animate on update
        rows={showGridRows}
        columns={showGridColumns}
        lineStyle={{
          stroke: GRID_COLOR,
        }}
      />
      {xAxis && (
        <Axis
          key={`x-axis`}
          label={xLabel}
          orientation={xOrientation}
          numTicks={xNumTicks}
          animationTrajectory={animationTrajectory}
          tickValues={getTickValues()}
          tickFormat={xTickFormat}
          stroke={AXIS_COLOR}
          tickStroke={AXIS_COLOR}
        />
      )}
      {yAxis && (
        <Axis
          key={`y-axis`}
          label={yLabel}
          orientation={yOrientation}
          numTicks={yNumTicks}
          tickFormat={yTickFormat}
          stroke={AXIS_COLOR}
          tickStroke={AXIS_COLOR}
        />
      )}
      {chart()}

      {tooltipCallback && (
        <Tooltip
          unstyled={true} // Set this to true so style can be defined by user
          applyPositionStyle={true} // Set this to true so tooltip will be positioned correctly since unstyles is true
          showVerticalCrosshair={showTooltipVerticalCrosshair}
          showHorizontalCrosshair={showTooltipHorizontalCrosshair}
          snapTooltipToDatumX={snapTooltipToDatumX}
          snapTooltipToDatumY={snapTooltipToDatumY}
          showDatumGlyph={true}
          showSeriesGlyphs={false}
          glyphStyle={showGlyph() ? { opacity: 1 } : { opacity: 0 }}
          renderTooltip={({ tooltipData }) =>
            tooltipCallback(tooltipData, highlightKey ?? nearestKey)
          }
        />
      )}
    </XYChart>
  );
}
