import React from "react";
import FilterControls from "../filter-controls";
import styles from "./numeric-controls.scss";

export default function NumericControls(props) {
  const {
    item,
    item: { selectedCondition },
    onUpdateFilter,
    conditionOptions,
    customInput,
  } = props;

  const selectedValue = item.selectedProperties;

  const onChangeValue = (value, valueName) => {
    let newSelectedValue;
    const parsedValue = parseFloat(value);
    if (value === "") {
      newSelectedValue = { ...selectedValue, [valueName]: undefined };
    } else {
      newSelectedValue = !isNaN(parsedValue)
        ? { ...selectedValue, [valueName]: parsedValue }
        : selectedValue;
    }

    onUpdateFilter(item.id, {
      selectedProperties: newSelectedValue,
    });
  };

  const defaultInput = (props) => (
    <input
      onChange={(e) => props.onChange(e.target.value, props.valueName)}
      defaultValue={props.defaultValue}
      type="number"
    />
  );

  const propertyInput = (valueName) => {
    const inputProps = {
      valueName: valueName,
      onChange: (value, valueName) => onChangeValue(value, valueName),
      defaultValue: selectedValue?.[valueName],
    };
    if (customInput !== undefined) return customInput(inputProps);
    return defaultInput(inputProps);
  };

  const propertiesFilter = (
    <>
      {selectedCondition >= 5 ? (
        <>
          <div className={styles.inputWrapper}>{propertyInput("value1")}</div>
          <div className={styles.filterConditionName}>and</div>
          <div className={styles.inputWrapper}>{propertyInput("value2")}</div>
        </>
      ) : (
        <div className={styles.inputWrapper}>{propertyInput("value1")}</div>
      )}
    </>
  );

  return (
    <FilterControls
      item={item}
      onUpdateFilter={onUpdateFilter}
      propertyFilterComponent={propertiesFilter}
      conditionOptions={conditionOptions}
    />
  );
}
