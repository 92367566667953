import React from "react";
import defaultStyles from "./scrollbar-wrapper.scss";

export default function ScrollbarWrapper(props) {
  return (
    <div className={`${defaultStyles.scrollbarWrapper} ${props.styles ?? ""}`}>
      {props.children}
    </div>
  );
}
