import React from "react";
import styles from "./chart-tabs.scss";
import ChartTab from "./chart-tab";

const ChartTabs = ({ options, selected, onSelect }) => {
  return (
    <div className={styles.chartTabs}>
      {options.map((option) => (
        <ChartTab
          key={option.id}
          title={option.title}
          isActive={selected == option.id}
          onClick={() => onSelect(option.id)}
        />
      ))}
    </div>
  );
};

export default ChartTabs;
