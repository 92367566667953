import React from "react";
import LegendWrapper from "../../../../visx_components/legend/legend";
import styles from "./pie-chart-component.scss";
import defaultStyles from "../base-chart-component.scss";
import PieChart from "../../../../visx_components/pie-chart/pie-chart";
import BaseChartComponent from "../base-chart-component";

export default function PieChartComponent(props) {
  const { size, data = [], dataKeys = [], valueFormatter, baseStyles } = props;

  const legendLabelProps = Object.keys(dataKeys).map((x) =>
    Object.assign(
      {},
      {
        stroke: dataKeys[x]["stroke"],
        strokeDasharray: "0",
        displayName: dataKeys[x].displayName,
      }
    )
  );

  const legendLabels = Object.keys(dataKeys).map((x) => dataKeys[x].label || x);

  return (
    <BaseChartComponent styles={baseStyles} data={data}>
      <div className={defaultStyles.legend}>
        <LegendWrapper
          labels={legendLabels}
          labelsProps={legendLabelProps}
          width={16}
        />
      </div>

      <PieChart
        valueFormatter={valueFormatter}
        size={size}
        data={data}
        dataKeys={dataKeys}
      />
    </BaseChartComponent>
  );
}
