import React, { useState, useEffect, useRef } from "react";
import dropdownItemHOC from "./hoc/dropdown-item-hoc";
import dropdownButtonHOC from "./hoc/dropdown-button-hoc";
import CustomScrollbar from "../scrollbar/customScrollbar";

import defaultStyles from "./dropdown2.scss";

const trim = null;

function DropdownWrapper(props) {
  const wrapperRef = useRef(null);
  const {
    size,
    dropdownTitle,
    dropdownHeader,
    dropdownBody,
    dropdownFooter,
    scrolled,
    isOpen,
    wrapDropdownBody,
    wrapDropdownFooter,
    handleClickItem,
    handleClickButton,
    styles,
    className,
    arrow,
    openByDefault = false,
  } = props;

  const [dropdownIsOpen, setDropdownIsOpen] = useState(openByDefault);
  useEffect(() => {
    if (isOpen) {
      setDropdownIsOpen(true);
    }
  }, [isOpen]);

  const toggleDropdownVisibility = () => {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setDropdownIsOpen(false);
    }
  };

  const DropdownBody = wrapDropdownBody
    ? dropdownItemHOC(dropdownBody.component, toggleDropdownVisibility)
    : dropdownBody?.component;
  const DropdownFooter = wrapDropdownFooter
    ? dropdownButtonHOC(dropdownFooter.component, toggleDropdownVisibility)
    : dropdownFooter?.component;

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const arrowClassName = arrow
    ? `${defaultStyles.arrow} ${dropdownIsOpen ? defaultStyles.open : ""}`
    : null;

  let body = scrolled ? (
    <>
      <CustomScrollbar
        style={{
          height: props.height || 200,
        }}
        styles={styles}
      >
        <DropdownBody
          handleClickItem={handleClickItem}
          {...dropdownBody?.props}
        />
      </CustomScrollbar>
    </>
  ) : (
    <DropdownBody handleClickItem={handleClickItem} {...dropdownBody?.props} />
  );

  return (
    <>
      <div
        ref={wrapperRef}
        className={`${defaultStyles.dropdownWrapper} ${styles[size]} ${
          trim ? styles.trim : ""
        } ${styles[className]}`}
      >
        <div
          className={`${styles.dropdownTitle} ${defaultStyles.dropdownTitle} ${arrowClassName}`}
          onClick={toggleDropdownVisibility}
        >
          {dropdownTitle}
        </div>
        {dropdownIsOpen && (
          <>
            <div className={`${styles.dropdown} ${defaultStyles.dropdown} `}>
              <div
                className={`${styles.dropdownHeader} ${defaultStyles.dropdownHeader}`}
              >
                {dropdownHeader}
              </div>
              <div
                className={`${defaultStyles.dropdownBody} ${styles.dropdownBody}`}
              >
                {dropdownBody?.component && body}
              </div>
              <div
                className={`${defaultStyles.dropdownFooter} ${styles.dropdownFooter}`}
              >
                {dropdownFooter?.component && (
                  <DropdownFooter
                    handleClickButton={handleClickButton}
                    {...dropdownFooter?.props}
                  />
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default DropdownWrapper;
