import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ScrollArea from "react-scrollbar";
import Popup from "../../../../components/popup";
import ErrorBoundry from "../../../../components/error-boundry";

import { closePopupShowStatus } from "../../actions";

import styles from "./user-details.scss";
import UserForm from "../user-form/user-form";
import CustomScrollbar from "../../../../components/scrollbar/customScrollbar";
import styles1 from "../../../../components/dropdown/dropdown.scss";

const scrollBarStyle = {
  height: "100vh",
  position: "fixed",
  width: "100%",
  left: "0",
  zIndex: 111,
  outline: 0,
};

class UsersDetails extends Component {
  render() {
    const content = this.props.showPopup ? (
      <div className={styles.popupWrapper}>
        <CustomScrollbar style={{ height: "100vh" }} styles={styles1}>
          <Popup visible={true} handleClose={this.props.closePopupShowStatus}>
            <div ref="wrap" tabIndex={0} className={styles.wrapper}>
              <div className={styles.margin}></div>
              <UserForm update={this.props.updateUserFlag} />
              <div className={styles.margin}></div>
            </div>
          </Popup>
        </CustomScrollbar>
      </div>
    ) : null;
    return content;
  }
}

// export default UsersDetails;
export const mapStateToProps = (state) => {
  return {
    showPopup: state.administration.administration.showPopup,
    updateUserFlag: state.administration.administration.updateUserFlag,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closePopupShowStatus,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersDetails);
