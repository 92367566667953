import React, { Component } from "react";
import Lottie from "react-lottie";
import animationData from "./spinner.json";

import styles from "./spinner.scss";

export default class Spinner extends Component {
  render() {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div className={styles.wrapper}>
        <Lottie
          options={defaultOptions}
          height={this.props.height}
          width={this.props.width || "100%"}
        />
      </div>
    );
  }
}
