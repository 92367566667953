import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Tabs from "../../../../components/tabs/tabs";
import styles from "./sku-tabs.scss";
import { changeViewSettings } from "../../actions";
import { PRODUCT_360_VIEW_TYPE_DATA, TAB_NAMES } from "../../constants";
import { useParentSpace } from "react-redux-subspace";
import { analyticsPageTabSelected } from "../../../shared/analytics/actions";

const SkuTabs = ({
  familyVariantsData,
  searchTermsData,
  settings,
  tabsConfig,
}) => {
  const parentSpace = useParentSpace();

  const onSelect = (tabId) => {
    parentSpace.dispatch(
      changeViewSettings({
        selectedTab: tabId,
      })
    );
    const tabName = tabsConfig.find((x) => x.id == tabId)?.name;
    analyticsPageTabSelected(PRODUCT_360_VIEW_TYPE_DATA.id, tabName);
  };

  /* 
    Conditional tab visiblity
    * Notice if the condition depends on the tab making a request, it has to be configured to 
      pre-load in tab-content-container.js 
  */
  const hasContent = {
    [TAB_NAMES.skuSales]: true,
    [TAB_NAMES.seoAnalysis]: searchTermsData?.tableData?.length > 0,
    [TAB_NAMES.similarSkus]:
      familyVariantsData?.selectedSku?.similarSkus?.length > 0,
  };

  const editedConfig = tabsConfig.map((tabConfig) =>
    hasContent[tabConfig.name]
      ? tabConfig
      : {
          ...tabConfig,
          disabled: true,
        }
  );

  useEffect(() => {
    if (settings && settings.selectedTab === undefined)
      onSelect(tabsConfig[0].id);
    else if (
      editedConfig.find((tab) => tab.id === settings.selectedTab)?.disabled
    )
      onSelect(editedConfig.find((tab) => !tab.disabled).id);
  }, [settings]);

  return (
    <div className={styles.tabs}>
      <Tabs
        tabs={editedConfig}
        activeTab={settings.selectedTab}
        setActiveTab={onSelect}
      />
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    settings: state.insights.insights.currentViewConfig.query.settings,
    familyVariantsData: state.product360.familyVariants.data,
    searchTermsData: state.product360.searchTerms.data,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SkuTabs);
