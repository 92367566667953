import React, { Component, Fragment } from "react";
import styles from "./no-match.scss";
import Header from "../../components/header";
import Content from "../../components/content";
import pic from "../../images/no-category-selected.svg";

export default class NoMatch extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Content>
          <div className={styles.wrongPageContainer}>
            <div className={styles.imageContainer}>
              <img src={pic} />
            </div>
            <div className={styles.textContainer}>
              <p className={styles.selectCategoryText}>
                This screen is no longer supported.
              </p>
              <p className={styles.learnMoreText}>
                Please start using our new Sales and Market Share tool to stay
                up-to-date with the latest data and insights.
              </p>
            </div>
          </div>
        </Content>
      </Fragment>
    );
  }
}
