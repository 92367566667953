import {
  APPLY_INITIAL_STATE,
  CHANGE_USERS_TABLE_SORTING,
  FETCH_USERS_TABLE_DATA_REQUEST,
  FETCH_USERS_TABLE_DATA_CANCEL,
  FETCH_USERS_TABLE_DATA_FAILURE,
  FETCH_USERS_TABLE_DATA_SUCCESS,
  FETCH_ALL_POSSIBLE_CATEGORIES_SUCCESS,
  FETCH_ALL_POSSIBLE_CATEGORIES_FAILURE,
  FETCH_ALL_POSSIBLE_CATEGORIES_CANCEL,
  FETCH_ALL_POSSIBLE_CATEGORIES_REQUEST,
  FETCH_ALL_POSSIBLE_COMPETITORS_SUCCESS,
  FETCH_ALL_POSSIBLE_COMPETITORS_FAILURE,
  FETCH_ALL_POSSIBLE_COMPETITORS_CANCEL,
  FETCH_ALL_POSSIBLE_COMPETITORS_REQUEST,
  FETCH_ALL_POSSIBLE_SCREENS_SUCCESS,
  FETCH_ALL_POSSIBLE_SCREENS_FAILURE,
  FETCH_ALL_POSSIBLE_SCREENS_CANCEL,
  FETCH_ALL_POSSIBLE_SCREENS_REQUEST,
  CHANGE_CATEGORIES_SELECTED_KEYS,
  CHANGE_SCREENS_SELECTED_KEYS,
  CHANGE_COMPETITORS_SELECTED_KEYS,
  CHANGE_USERS_TABLE_OFFSET,
  CLOSE_SHOW_POPUP_STATUS,
  SHOW_POPUP,
  TOGGLE_UPDATE_USER,
  SET_SELECTED_USER_DATA,
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  ADD_NEW_USER_CANCEL,
  DELETE_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_FAILURE,
  DELETE_USER_CANCEL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_CANCEL,
  SET_CURRENT_VIEW_IS_UPDATED,
  CRUD_OPERATION_FAILURE,
} from "./constants";

import AdministrationService from "../../services/administrationService";

const adminService = new AdministrationService();

export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

export const usersTableDataRequested = () => ({
  type: FETCH_USERS_TABLE_DATA_REQUEST,
});

export const usersTableDataSuccess = (data) => ({
  type: FETCH_USERS_TABLE_DATA_SUCCESS,
  payload: { data },
});

export const usersTableDataCancel = () => ({
  type: FETCH_USERS_TABLE_DATA_CANCEL,
});

export const usersTableDataError = (error) => ({
  type: FETCH_USERS_TABLE_DATA_FAILURE,
  payload: { error },
});

export const usersTableUpdateOffset = (value) => ({
  type: CHANGE_USERS_TABLE_OFFSET,
  payload: value,
});

export const allScreensRequested = () => ({
  type: FETCH_ALL_POSSIBLE_SCREENS_REQUEST,
});
export const allScreensSuccess = (data) => ({
  type: FETCH_ALL_POSSIBLE_SCREENS_SUCCESS,
  payload: { data },
});
export const allScreensCancel = () => ({
  type: FETCH_ALL_POSSIBLE_SCREENS_CANCEL,
});
export const allScreensError = (error) => ({
  type: FETCH_ALL_POSSIBLE_SCREENS_FAILURE,
  payload: { error },
});

export const allCategoriesRequested = () => ({
  type: FETCH_ALL_POSSIBLE_CATEGORIES_REQUEST,
});
export const allCategoriesSuccess = (data) => ({
  type: FETCH_ALL_POSSIBLE_CATEGORIES_SUCCESS,
  payload: { data },
});
export const allCategoriesCancel = () => ({
  type: FETCH_ALL_POSSIBLE_CATEGORIES_CANCEL,
});
export const allCategoriesError = (error) => ({
  type: FETCH_ALL_POSSIBLE_CATEGORIES_FAILURE,
  payload: { error },
});

export const allCompetitorsRequested = () => ({
  type: FETCH_ALL_POSSIBLE_COMPETITORS_REQUEST,
});
export const allCompetitorsSuccess = (data) => ({
  type: FETCH_ALL_POSSIBLE_COMPETITORS_SUCCESS,
  payload: { data },
});
export const allCompetitorsCancel = () => ({
  type: FETCH_ALL_POSSIBLE_COMPETITORS_CANCEL,
});
export const allCompetitorsError = (error) => ({
  type: FETCH_ALL_POSSIBLE_COMPETITORS_FAILURE,
  payload: { error },
});
export const changeUsersTableSorting = (data) => ({
  type: CHANGE_USERS_TABLE_SORTING,
  payload: { data },
});
export const changeSelectedCategories = (values) => ({
  type: CHANGE_CATEGORIES_SELECTED_KEYS,
  payload: { values },
});
export const changeSelectedCompetitors = (data) => ({
  type: CHANGE_COMPETITORS_SELECTED_KEYS,
  payload: { data },
});
export const changeSelectedScreens = (data) => ({
  type: CHANGE_SCREENS_SELECTED_KEYS,
  payload: { data },
});
export const closePopupShowStatus = (data) => ({
  type: CLOSE_SHOW_POPUP_STATUS,
  payload: { data },
});
export const showPopup = () => ({
  type: SHOW_POPUP,
});
export const toggleUpdateUser = (data) => ({
  type: TOGGLE_UPDATE_USER,
  payload: { data },
});
export const setSelectedUserData = (data) => ({
  type: SET_SELECTED_USER_DATA,
  payload: { data },
});
export const addNewUserRequested = () => ({
  type: ADD_NEW_USER_REQUEST,
});
export const addNewUserError = (error) => ({
  type: CRUD_OPERATION_FAILURE,
  payload: { error },
});
export const addNewUserCancel = () => ({
  type: ADD_NEW_USER_CANCEL,
});
export const addNewUserSuccess = (data) => ({
  type: ADD_NEW_USER_SUCCESS,
  payload: { data },
});

export const deleteUserRequested = () => ({
  type: DELETE_USER_REQUEST,
});
export const deleteUserError = (error) => ({
  type: CRUD_OPERATION_FAILURE,
  payload: { error },
});
export const deleteUserCancel = () => ({
  type: DELETE_USER_CANCEL,
});
export const deleteUserSuccess = (data) => ({
  type: DELETE_USER_SUCCESS,
  payload: { data },
});

export const updateUserRequested = () => ({
  type: UPDATE_USER_REQUEST,
});
export const updateUserError = (error) => ({
  type: CRUD_OPERATION_FAILURE,
  payload: { error },
});
export const updateUserCancel = () => ({
  type: UPDATE_USER_CANCEL,
});
export const updateUserSuccess = (data) => ({
  type: UPDATE_USER_SUCCESS,
  payload: { data },
});

export const setCurrentViewIsUpdated = () => ({
  type: SET_CURRENT_VIEW_IS_UPDATED,
});

export const fetchUsersTableData = () => (dispatch) => {
  dispatch(usersTableDataRequested());
  dispatch(usersTableUpdateOffset(0));
  adminService
    .fetchTableData()
    .then((res) => {
      dispatch(usersTableDataSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(usersTableDataCancel());
      } else {
        dispatch(usersTableDataError(err.message));
      }
    });
};

export const fetchAllPossibleCategories = () => (dispatch) => {
  dispatch(allCategoriesRequested());
  adminService
    .fetchAllCategories()
    .then((res) => {
      dispatch(allCategoriesSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(allCategoriesCancel());
      } else {
        dispatch(allCategoriesError(err.message));
      }
    });
};

export const fetchAllPossibleCompetitors = () => (dispatch) => {
  dispatch(allCompetitorsRequested());
  adminService
    .fetchAllCompetitors()
    .then((res) => {
      dispatch(allCompetitorsSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(allCompetitorsCancel());
      } else {
        dispatch(allCompetitorsError(err.message));
      }
    });
};

export const fetchAllPossibleScreens = () => (dispatch) => {
  dispatch(allScreensRequested());
  adminService
    .fetchAllScreens()
    .then((res) => {
      dispatch(allScreensSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(allScreensCancel());
      } else {
        dispatch(allScreensError(err.message));
      }
    });
};

export const addNewUser = (data, multiple) => (dispatch) => {
  dispatch(addNewUserRequested());
  adminService
    .addNewUser(data)
    .then((res) => {
      dispatch(addNewUserSuccess(res));
      if (!multiple) {
        dispatch(closePopupShowStatus());
      }
      dispatch(fetchUsersTableData());
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(addNewUserCancel());
      } else {
        dispatch(addNewUserError(err.message));
      }
    });
};

export const deleteUser = (data) => (dispatch) => {
  dispatch(deleteUserRequested());
  adminService
    .deleteUser(data)
    .then((res) => {
      dispatch(deleteUserSuccess(res));
      dispatch(closePopupShowStatus());
      dispatch(fetchUsersTableData());
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(deleteUserCancel());
      } else {
        dispatch(deleteUserError(err.message));
      }
    });
};

export const updateUser = (data) => (dispatch) => {
  dispatch(updateUserRequested());
  adminService
    .updateUser(data)
    .then((res) => {
      dispatch(updateUserSuccess(res));
      dispatch(closePopupShowStatus());
      dispatch(fetchUsersTableData());
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(updateUserCancel());
      } else {
        dispatch(updateUserError(err.message));
      }
    });
};
