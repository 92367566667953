const BACKEND_DNS_NAME = process.env.BACKEND_DNS_NAME;
const BACKEND_PORT = process.env.BACKEND_PORT;
const BACKEND_PROTOCOL = process.env.BACKEND_PROTOCOL;
const API_URL = `${BACKEND_PROTOCOL}://${BACKEND_DNS_NAME}:${BACKEND_PORT}/api/etailInsights/v3`;
const API_URL_V2 = `${BACKEND_PROTOCOL}://${BACKEND_DNS_NAME}:${BACKEND_PORT}/api/etailInsights/v2`;
const ERROR_LOG_URL = `${BACKEND_PROTOCOL}://${BACKEND_DNS_NAME}:${BACKEND_PORT}/api/errorLog`;
const INTERCOM_API_KEY = process.env.INTERCOM_API_KEY;
const MIXPANEL_PROJECT_TOKEN = process.env.MIXPANEL_PROJECT_TOKEN;

const skusLimit = 20;
const insightsSkusLimit = 20;
const insightsSkusSort = 20;

export {
  API_URL,
  API_URL_V2,
  ERROR_LOG_URL,
  INTERCOM_API_KEY,
  MIXPANEL_PROJECT_TOKEN,
  skusLimit,
  insightsSkusLimit,
};
