import React, { Component } from "react";

import styles from "./insights-revenue-leakage-list-inner-item.scss";
import { abbreviateNumber } from "../../../../../utils/other/utilities.js";

export default class InsightsRevenueLeakageListInnerItem extends Component {
  getRange = (inflow, outflow) => {
    if (outflow) {
      return outflow;
    }
    if (inflow) {
      return inflow;
    }
    return null;
  };

  render() {
    const { data, level1ActiveCategory, handleClickInnerItem } = this.props;
    const { inflow, outflow, competitorCategoryName } = data;
    const categoryl2_btn = competitorCategoryName.split(" ").join("") + "_btn";

    let range = this.getRange(inflow, outflow);

    const active =
      level1ActiveCategory === competitorCategoryName ? "active" : "";

    return (
      <div
        id={categoryl2_btn}
        className={`${styles.itemInner} ${active}`}
        onClick={() => {
          handleClickInnerItem(competitorCategoryName);
        }}
      >
        <div className={styles.itemInnertitle}>{competitorCategoryName}</div>
        {/* <div className={styles.itemInnerRange}>
            { range ? `$${abbreviateNumber(range)}` : '$0' }
        </div> */}
      </div>
    );
  }
}
