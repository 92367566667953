import React, { useEffect, useRef } from "react";
import InsightsTemplate from "../insights-template/insights-template";
import styles from "./insights-template-back-button.scss";

const InsightsTemplateBackButton = (props) => {
  const {
    dataControlsOptions,
    apiProvider,
    hideInsightsFilter,
    body,
    viewTypeData,
    parentViewTypeData,
    parentRedirect,
    pageTitle,
    setSelectedSku,
    selectedSku,
    applyInitialState,
  } = props;

  const preserveUrlQuery = useRef();

  useEffect(() => {
    if (selectedSku) {
      const url = new URL(window.location.href);
      url.pathname = viewTypeData.name;
      url.searchParams.set("sku", selectedSku);
      history.replaceState({}, "", url);
    }
  }, [selectedSku]);

  useEffect(() => {
    const url = new URL(window.location.href);
    if (parentRedirect) preserveUrlQuery.current = url.searchParams.get("b");
    if (!selectedSku) {
      if (url.searchParams.has("b") && url.searchParams.has("sku")) {
        setSelectedSku(url.searchParams.get("sku"));
      } else {
        // No SKU provided, redirect to parent view.
        window.location.href = `/${parentViewTypeData.name}`;
      }
    }
    return () => {
      applyInitialState();
    };
  }, []);

  const TitleComponent = () => {
    const onBackClick = () => {
      const url = new URL(window.location.href);
      url.searchParams.set("b", preserveUrlQuery.current);
      history.replaceState({}, "", url);
      setSelectedSku(null);
    };

    return (
      <>
        {parentRedirect && (
          <div onClick={() => onBackClick()} className={styles.backButton}>
            {"< Back"}
          </div>
        )}
        {pageTitle}
      </>
    );
  };

  const updatedDataControlsOptions = {
    ...dataControlsOptions,
    TitleComponent: TitleComponent,
  };

  return (
    <InsightsTemplate
      dataControlsOptions={updatedDataControlsOptions}
      viewTypeData={viewTypeData}
      apiProvider={apiProvider}
      hideInsightsFilter={hideInsightsFilter}
      body={body}
    />
  );
};

export default InsightsTemplateBackButton;
