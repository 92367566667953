import React from "react";
import {
  percentDecorator,
  chartTickPercentFormat,
} from "../../../../styles/cell-decorators";
import ChartComponent from "../../../../components/dashboard-components/chart-component/chart-component";
import MarketShareAnalysis from "../../../market-share-analysis";
import styles from "./market-share-chart-component.scss";
import { MARKET_SHARE_ANALYSIS_SUBJECT_TYPE } from "../../../market-share-analysis/constants";
import { ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA } from "../../constants";

export default function MarketShareChartComponent(props) {
  const { state } = props;

  const marketShareAnalysisComponent =
    state != null && state.marketShareAnalysis.showMarketShareAnalysis ? (
      <MarketShareAnalysis
        viewTypeData={ACCOUNT_PERFORMANCE_VIEW_TYPE_DATA}
        subjectType={MARKET_SHARE_ANALYSIS_SUBJECT_TYPE.CUSTOMER}
      />
    ) : null;

  return (
    <div className={styles.components}>
      <ChartComponent
        {...props}
        title={"Your Market Share"}
        currentKey={"currentMarketShare"}
        previousKey={"previousMarketShare"}
        changeKey={"marketShareChange"}
        taglineSubject={"Your market share"}
        legendName={"Market share"}
        valueDecorator={percentDecorator}
        yTickFormat={chartTickPercentFormat}
      />
      {marketShareAnalysisComponent}
    </div>
  );
}
