export const CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA = {
  name: "sku_details",
  id: 10,
};

const path = "catalogTrackerSkuDetails";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;

export const SET_SELECTED_MATCHING_ID = `${path}/SET_SELECTED_MATCHING_ID`;

export const FETCH_SKU_DETAILS_DATA_REQUEST = `${path}/FETCH_SKU_DETAILS_DATA_REQUEST`;
export const FETCH_SKU_DETAILS_DATA_SUCCESS = `${path}/FETCH_SKU_DETAILS_DATA_SUCCESS`;
export const FETCH_SKU_DETAILS_DATA_FAILURE = `${path}/FETCH_SKU_DETAILS_DATA_FAILURE`;
export const FETCH_SKU_DETAILS_DATA_CANCEL = `${path}/FETCH_SKU_DETAILS_DATA_CANCEL`;

export const STATUS_IDLE = "idle";
export const STATUS_LOADING = "loading";
export const STATUS_ERROR = "error";
export const STATUS_SUCCESS = "success";
