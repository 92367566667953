import React from "react";
import XYChartWrapper from "../../../visx_components/xychart/xychart";
import LegendWrapper from "../../../visx_components/legend/legend";
import styles from "./base-chart-component.scss";
import BaseChartComponent from "./base-chart-component";

export default function BarStackComponent(props) {
  const {
    data = [],
    dataKeys = [],
    xTickFormat = (x) => x,
    xLabel,
    xKey,
    xNumTicks,
    yTickFormat = (x) => x,
    tooltipCallback,
    chartName,
    baseStyles,
  } = props;

  const legendLabelProps = Object.keys(dataKeys).map((x) =>
    Object.assign(
      {},
      {
        stroke: dataKeys[x]["stroke"],
        strokeDasharray: "0",
        displayName: dataKeys[x].displayName,
      }
    )
  );

  const legendLabels = Object.keys(dataKeys).map((x) => dataKeys[x].label || x);

  return (
    <BaseChartComponent styles={baseStyles} data={data}>
      <div className={styles.chartInfoWrapper}>
        <div className={styles.chartName}>{chartName}</div>
        <div className={styles.legend}>
          <LegendWrapper
            labels={legendLabels}
            labelsProps={legendLabelProps}
            width={16}
          />
        </div>
      </div>

      <XYChartWrapper
        data={data}
        dataKeys={dataKeys}
        curveType={"barStack"}
        xLabel={xLabel}
        xKey={xKey}
        xTickFormat={xTickFormat}
        xScaleType={"band"}
        xValuesNumeric={false}
        xPaddingInner={data?.length >= 10 ? 0.2 : 0.5}
        xPadding={0}
        xNumTicks={xNumTicks ?? data.length}
        yScaleType={"linear"}
        yTickFormat={yTickFormat}
        showGridColumns={false}
        tooltipCallback={tooltipCallback}
        chartType={"barStack"}
      ></XYChartWrapper>
    </BaseChartComponent>
  );
}
