import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_CANCEL,
  CLEAR_USER,
} from "./constants";

import UserService from "../../../services/userService";
import { analyticsSetDomain } from "../analytics/actions";

const userService = new UserService();

export const userRequested = () => ({
  type: FETCH_USER_REQUEST,
});

export const userSuccessed = (data) => ({
  type: FETCH_USER_SUCCESS,
  payload: { data },
});

export const userError = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: { error },
});

export const userCancel = () => ({
  type: FETCH_USER_CANCEL,
});

export const fetchUser = () => (dispatch) => {
  dispatch(userRequested());

  return userService
    .fetchUser()
    .then((res) => {
      analyticsSetDomain(res.customerInfo.rootDomain);
      dispatch(userSuccessed(res));
      return res;
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(userCancel());
      } else {
        dispatch(userError(err.message));
      }
    });
};

export const clearUser = () => ({
  type: CLEAR_USER,
});
