import React from "react";
import styles from "./sku-details-cell.scss";
import ContentLoader from "react-content-loader";

const SkuDetailsCellLoader = ({ speed = 2 }) => {
  return (
    <div className={styles.cellWrapper}>
      <div className={styles.cellLeftSection}>
        <ContentLoader speed={speed} width={220} height={220}>
          <rect x="0" y="0" rx="3" ry="3" width={220} height={220} />
        </ContentLoader>
      </div>
      <div className={styles.cellRightSection}>
        <div className={styles.skuTitle}>
          <ContentLoader speed={speed} width={900} height={28}>
            <rect x="0" y="0" rx="3" ry="3" width={500} height={28} />
          </ContentLoader>
        </div>
        <div className={styles.skuValue}>
          <ContentLoader speed={speed} width={200} height={18}>
            <rect x="0" y="0" rx="3" ry="3" width={200} height={18} />
          </ContentLoader>
        </div>
        <table className={styles.detailsTable}>
          <colgroup>
            <col className={styles.fieldNamesCol} />
            <col />
          </colgroup>
          <tbody>
            {[0, 1, 2, 4].map((i) => (
              <tr key={i}>
                <td className={styles.fieldName}>
                  <ContentLoader speed={speed} width={60} height={16}>
                    <rect x="0" y="0" rx="3" ry="3" width={60} height={16} />
                  </ContentLoader>
                </td>
                <td className={styles.fieldValue}>
                  <ContentLoader speed={speed} width={120} height={16}>
                    <rect x="0" y="0" rx="3" ry="3" width={120} height={16} />
                  </ContentLoader>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SkuDetailsCellLoader;
