import React, { useState } from "react";
import styles from "./sku-analysis-card.scss";
import SkuAnalysisProductData from "./sections/product-data";
import MarketShareAnalysisSearchTerms from "./sections/search-terms";
import MarketShareAnalysisKpis from "./sections/analysis-kpis";
import MarketShareAnalysisImageLoader from "./loaders/image";
import MarketShareAnalysisSkuLoader from "./loaders/sku";
import MarketShareAnalysisKpisLoader from "./loaders/kpis";
import MarketShareAnalysisSearchTermsLoader from "./loaders/search-terms";
import SkuAnalysisSalesDollarsShare from "./sections/sales-dollars-share";

export default function SkuAnalysisCard(props) {
  const { data = {}, isLoading, OnSelectRow } = props;
  const [isShowingSearchTerms, setIsShowingSearchTerms] = useState(false);

  const {
    image,
    name,
    sku,
    brand,
    topSellerName,
    rootdomain,
    searchTermsKpis = [],
    skuMarketShareKpis = {},
    isNewProduct = false,
  } = data;

  const leftComponent = isLoading ? (
    <MarketShareAnalysisImageLoader className={styles.image} />
  ) : (
    <>
      <SkuAnalysisSalesDollarsShare data={skuMarketShareKpis} />
      <img src={image} className={styles.image} />
    </>
  );

  const skuComponent = isLoading ? (
    <MarketShareAnalysisSkuLoader />
  ) : (
    <>
      <div className={styles.name}>{name}</div>
      {isNewProduct && <div>New Product</div>}
      <div className={styles.sku}>SKU: {sku}</div>
      <SkuAnalysisProductData
        brand={brand}
        supplier={topSellerName}
        retailer={rootdomain}
        kpis={skuMarketShareKpis}
      />
    </>
  );

  const kpisComponent = isLoading ? (
    <MarketShareAnalysisKpisLoader />
  ) : (
    <MarketShareAnalysisKpis {...data} />
  );
  const searchTermsComponent = isLoading ? (
    <MarketShareAnalysisSearchTermsLoader />
  ) : (
    <MarketShareAnalysisSearchTerms searchTermsKpis={searchTermsKpis} />
  );

  const cardBody = (
    <>
      <div
        className={styles.product360ClickableArea}
        onClick={() => {
          OnSelectRow(sku);
        }}
      >
        <div className={styles.leftComponent}>{leftComponent}</div>
        <div className={styles.skuInfo}>{skuComponent}</div>
      </div>
      <div className={styles.analysis}>
        {isShowingSearchTerms ? searchTermsComponent : kpisComponent}
        {!isLoading && (
          <div
            className={styles.topSearchTerms}
            onClick={() => {
              setIsShowingSearchTerms(!isShowingSearchTerms);
            }}
          >
            <i className={styles.topSearchTermsImage} />
            {isShowingSearchTerms
              ? "View analysis kpis"
              : "View top search terms"}
          </div>
        )}
      </div>
    </>
  );

  const cardWrapper = isLoading ? (
    <div className={styles.loading}>{cardBody}</div>
  ) : (
    <div className={styles.card}>{cardBody}</div>
  );

  return <div className={styles.base}>{cardWrapper}</div>;
}
