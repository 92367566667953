import { COMPARE_PERIODS } from "../../components/mb-compare-period/constants";
import DatePickerService from "../../services/datePickerService";
const datePickerService = new DatePickerService();

export const TopSkusChosenPeriodToDateConfig = (chosenPeriod, maxMoment) => {
  var rolling = 0;
  var dates;
  var lastSunday = maxMoment.clone().startOf("isoWeek").subtract(1, "days");
  switch (chosenPeriod) {
    case "Last 30 days":
      dates = datePickerService.onClickLastXDays(30, maxMoment);
      rolling = 7;
      break;
    case "Last 7 days":
      dates = datePickerService.onClickLastXDays(7, maxMoment);
      rolling = 5;
      break;
    case "Last day":
      dates = datePickerService.onClickLastXDays(1, maxMoment);
      rolling = 4;
      break;
    case "Last 2 weeks":
      dates = datePickerService.onClickLastXWeeks(2, lastSunday);
      rolling = 11;
      break;
    case "Last 4 weeks":
      dates = datePickerService.onClickLastXWeeks(4, lastSunday);
      rolling = 2;
      break;
  }
  return {
    rolling,
    comparePeriod: COMPARE_PERIODS.previousPeriod,
    ...dates,
  };
};
