import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Decorators from "../../../../styles/cell-decorators";
import styles from "./family-variants-table.scss";

import _ from "lodash";
import DataTable from "../../../../components/data-table/data-table";
import {
  FAMILY_VARIANTS_TABLE_SIZE,
  FAMILY_VARIANTS_RESULTS_SIZE,
  CURRENT_SKU_TABLE_ROW_BACKGROUND,
  STATUS_LOADING,
  STATUS_SUCCESS,
} from "../../constants";
import { fetchFamilyVariantsData, setSelectedSkuInPage } from "../../actions";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";
import { sortTableData } from "../../utils";
import PaginatedTable from "../../../../components/tables/paginated-table/paginated-table";

const columnsConfig = [
  {
    id: -1,
    label: "Sku",
    accessor: "sku",
    cellDecorator: Decorators.familySkuDecorator,
    loaderWidth: LoaderWidths.Large,
  },
  {
    id: 0,
    label: "Variant",
    accessor: "variantAttributes",
    cellDecorator: Decorators.variantDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  {
    id: 1,
    label: "Total Sales Dollars",
    accessor: "currentSkuSalesDollars",
    tooltip: "Estimated retail sales for the selected time period",
    cellDecorator: Decorators.amountDollarsDecorator("gmv"),
    loaderWidth: LoaderWidths.Big,
  },
  {
    id: 2,
    label: "Sales Dollars Diff",
    accessor: "skuSalesDollarsChange",
    tooltip:
      "Sales Dollars difference between the current time period and the previous period listed above",
    cellDecorator: Decorators.numberChangeDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  {
    id: 3,
    label: "Total Units",
    accessor: "currentSkuUnitsSold",
    tooltip: "Estimated units sold for the selected time period",
    cellDecorator: Decorators.salesDecorator("sales"),
    loaderWidth: LoaderWidths.Big,
  },
  {
    id: 4,
    label: "Units Diff",
    accessor: "skuUnitsSoldChange",
    tooltip:
      "Units sold difference between the current time period and the previous period listed above",
    cellDecorator: Decorators.roundNumberChangeDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  {
    id: 5,
    label: "Average Selling Price",
    accessor: "currentSkuAverageSellingPrice",
    cellDecorator: Decorators.priceDecorator,
    loaderWidth: LoaderWidths.Big,
  },
  {
    id: 99,
    label: "Link",
    accessor: "url",
    cellDecorator: Decorators.linkDecorator,
    loaderWidth: LoaderWidths.Big,
  },
];

const DEFAULT_SORT_CONFIG = {
  field: 1,
  direction: 0,
};

function FamilyVariantsTable(props) {
  const {
    selectedSku,
    setSelectedSkuInPage,
    familyVariantsData,
    status,
    forceLoading = false,
    productAttributes,
    selectedCompetitor,
    selectedDateRange,
    currentViewConfigQuery,
    selectedPreviousDateRange,
    comparePeriod,
    fetchFamilyVariantsData,
    selectedPage,
  } = props;
  const sortableColumns = [1, 2, 3, 4, 5];
  const [tableData, setTableData] = useState();
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const pageRef = useRef(selectedPage); // Since every click on a row will trigger a re-render, we keep the current page in the state

  const requestParams = {
    requestBody: {
      insightsViewQuery: {
        ...currentViewConfigQuery,
        previousStartDate: selectedPreviousDateRange.previousStartDate,
        previousEndDate: selectedPreviousDateRange.previousEndDate,
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        comparePeriod: comparePeriod,
      },
      sku: selectedSku,
      size: FAMILY_VARIANTS_RESULTS_SIZE,
    },
  };

  const prepareSortingValues = (newSelectedSortingColumn) => {
    if (sortConfig.field === newSelectedSortingColumn) {
      setSortConfig({
        field: sortConfig.field,
        direction: sortConfig.direction ? 0 : 1,
      });
    } else {
      setSortConfig({
        field: newSelectedSortingColumn,
        direction: 0,
      });
    }
  };

  const handleFilterChange = () => {
    // Verify offset is reset to 0
    requestParams.requestBody.insightsViewQuery.offset = 0;
    if (!forceLoading) fetchFamilyVariantsData(requestParams);
  };

  useEffect(() => {
    handleFilterChange();
  }, [productAttributes, selectedDateRange, selectedCompetitor, comparePeriod]);

  const sortData = () => {
    const columnConfig = columnsConfig.find((x) => x.id === sortConfig.field);
    if (columnConfig) {
      let sorted = sortTableData(
        sortConfig,
        columnConfig,
        familyVariantsData.variants
      );
      for (const sortedItem of sorted) {
        if (sortedItem.sku === selectedSku) {
          sortedItem.isCurrentSku = true;
          sortedItem.rowStyle = {
            backgroundColor: CURRENT_SKU_TABLE_ROW_BACKGROUND,
          };
        }
      }
      setTableData(sorted);
    }
  };

  useEffect(() => {
    if (familyVariantsData?.variants?.length > 0) sortData();
  }, [sortConfig, familyVariantsData.variants]);

  useEffect(() => {
    setSortConfig(DEFAULT_SORT_CONFIG);
  }, [familyVariantsData.variants]);

  const onRowClick = (rowIndex, e) => {
    let alignedIndex =
      rowIndex + (pageRef.current - 1) * FAMILY_VARIANTS_TABLE_SIZE;
    setSelectedSkuInPage(tableData[alignedIndex].sku, pageRef.current);
  };

  return (
    <PaginatedTable
      pageRef={pageRef}
      tableData={tableData ?? []}
      maxRowsPerPage={FAMILY_VARIANTS_TABLE_SIZE}
    >
      {(pageData) => (
        <DataTable
          header={"Top 5 Family Variants"}
          columnsConfig={columnsConfig}
          sorting={{
            sortingColumn: sortConfig.field,
            selectedSortingDirection: sortConfig.direction,
          }}
          hideColumnsDropdown={true}
          sortableColumns={sortableColumns}
          prepareSortingValues={prepareSortingValues}
          styles={styles}
          data={pageData}
          selectedColumns={columnsConfig.map((x) => x.id)}
          disableTooltipForColumns={[-1]}
          excludedTableColumns={[]}
          prepareDataForTable={(x) => x}
          onSelectRow={onRowClick}
          isLoading={status !== STATUS_SUCCESS}
          amountOfLoadingRows={FAMILY_VARIANTS_TABLE_SIZE}
        />
      )}
    </PaginatedTable>
  );
}

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    familyVariantsData: state.product360.product360.familyVariants.data,
    selectedPage: state.product360.product360.familyVariants.page,
    status: state.product360.product360.familyVariants.status,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchFamilyVariantsData,
      setSelectedSkuInPage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyVariantsTable);
