import React from "react";
import styles from "./analysis-kpis-component-config.scss";
import PanelComponent from "../../../../../components/dashboard-components/panel-component/panel-component";
import { AnalysisKpisComponentConfig } from "./analysis-kpis-component-config";

export default function MarketShareAnalysisKpis(props) {
  const {
    analysisKpis,
    currentSkuAverageSellingPrice,
    skuAverageSellingPriceChange,
    currentPromoDays,
    promotionChange,
    currentStockPosition,
    stockPositionChange,
  } = props;
  const componentData = {
    marketShareAnalysisKpis: {
      ...analysisKpis,
      currentSkuAverageSellingPrice,
      skuAverageSellingPriceChange,
      currentPromoDays,
      promotionChange,
      currentStockPosition,
      stockPositionChange,
    },
  };

  const designPlan = {
    rows: [
      {
        className: styles.row,
        columns: [
          {
            className: styles.frame,
            cells: [
              AnalysisKpisComponentConfig.serpRank,
              AnalysisKpisComponentConfig.horizontalSeparator,
              AnalysisKpisComponentConfig.visibility,
            ],
          },
          {
            cells: [AnalysisKpisComponentConfig.separator],
          },
          {
            className: styles.frame,
            cells: [
              AnalysisKpisComponentConfig.asp,
              AnalysisKpisComponentConfig.horizontalSeparator,
              AnalysisKpisComponentConfig.promotion,
            ],
          },
          {
            cells: [AnalysisKpisComponentConfig.separator],
          },
          {
            className: styles.frame,
            cells: [
              AnalysisKpisComponentConfig.advertising,
              AnalysisKpisComponentConfig.horizontalSeparator,
              AnalysisKpisComponentConfig.stockPosition,
            ],
          },
        ],
      },
    ],
  };

  return (
    <PanelComponent
      {...props}
      isEnabled={true}
      styles={styles}
      designPlan={designPlan}
      hideHeader={true}
      hideBackground={true}
      componentData={componentData}
    />
  );
}
