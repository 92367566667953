import { ApiService, cancelMarketShareAnalysisData } from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();

export default class MarketShareAnalysisService extends BasicViewService {
  fetchMarketShareAnalysisData(params) {
    if (cancelMarketShareAnalysisData !== undefined) {
      cancelMarketShareAnalysisData("cancel");
    }

    const res = apiService
      .requestMarketShareAnalysisDataPOST(`/marketShare/analysis`, params)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }
}
