import React from "react";
import styles from "./button.scss";

const Button = ({
  id,
  text,
  icon,
  onClick,
  disabled = false,
  variant,
  className,
}) => {
  return (
    <div
      id={id}
      disabled={disabled}
      className={`${className ?? ""} ${
        variant == "contained" ? styles.contained : styles.pale
      } `}
      onClick={(e) => !disabled && onClick(e)}
    >
      {icon && <img src={icon} />}
      {text}
    </div>
  );
};

export default Button;
