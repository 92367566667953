import React, { useEffect, useState } from "react";
import styles from "../trigger-definition/trigger-definitions.scss";
import Button from "../../../../components/button";
import {
  EVENT_FILTER_VALUE_MB_NAME,
  EVENT_GROUP_MB_NAME,
  EVENT_TYPE_MB_NAME,
  SCREENS,
} from "../../consts";
import HeaderContainer from "../../components/header-container";
import FullHeading from "../../components/full-heading/full-heading";
import FilterCollection from "../../../mb-filter-sidebar/filter-collection";
import { ConvertFilterSidebarToInsightsFilter } from "../../../mb-filter-sidebar/filter-conversions";
import EventFilterValue from "../trigger-definition/fields/filter-value/filter-value";
import EventTypeField from "../trigger-definition/fields/event-type/event-type";
import CustomScrollbar from "../../../../components/scrollbar/customScrollbar";

const EVENTS_RELATED_FILTERS = [
  EVENT_TYPE_MB_NAME,
  EVENT_GROUP_MB_NAME,
  EVENT_FILTER_VALUE_MB_NAME,
];

const CustomPresetFilter = ({
  moveToScreen,
  formData,
  setFormData,
  configData,
  setConfigData,
  sendToast,
}) => {
  const insightsFiltersCollection = formData?.query?.productAttributes;
  const [filtersCollection, setFiltersCollection] = useState([]);

  const setViewFiltersCollection = (collection) => {
    const filters = ConvertFilterSidebarToInsightsFilter(
      _.cloneDeep(collection)
    ).filter((x) => !EVENTS_RELATED_FILTERS.includes(x.mbName));

    filters.push(
      ...insightsFiltersCollection.filter((x) =>
        [EVENT_TYPE_MB_NAME, EVENT_GROUP_MB_NAME].includes(x.mbName)
      )
    );
    if (configData.filterValueAttribute != null)
      filters.push(configData.filterValueAttribute);

    setFormData({
      ...formData,
      query: {
        ...formData.query,
        productAttributes: filters,
      },
    });
  };

  const onClickNext = () => {
    setViewFiltersCollection(filtersCollection);
    moveToScreen(SCREENS.triggerDefinition);
  };

  return (
    <>
      <HeaderContainer>
        <FullHeading configData={configData} formData={formData} />
      </HeaderContainer>
      <div
        style={{
          padding: "20px 150px",
          display: "flex",
          minHeight: "400px",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className={styles.configSectionTitle}>Filter Population</div>
        <CustomScrollbar
          className={styles.customScrollbar}
          styles={styles}
          style={{ height: 400 }}
        >
          <div
            className={styles.conditionFilterItem}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "12px",
              margin: "8px",
            }}
          >
            <EventTypeField
              formData={formData}
              setFormData={setFormData}
              configData={configData}
            />
          </div>
          {configData?.eventProps?.filterValueName && (
            <div
              className={styles.conditionFilterItem}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
                margin: "8px",
              }}
            >
              <EventFilterValue
                formData={formData}
                setFormData={setFormData}
                configData={configData}
                setConfigData={setConfigData}
              />
            </div>
          )}
          <FilterCollection
            filtersCollection={filtersCollection}
            setFiltersCollection={setFiltersCollection}
            insightsFiltersCollection={insightsFiltersCollection}
            filterDefinitions={configData.filterDefinitions}
            scrollable={false}
          />
        </CustomScrollbar>
        <div style={{ width: "100px", margin: "16px auto 0px auto" }}>
          <Button
            variant={"contained"}
            onClick={() => onClickNext()}
            text={"Next"}
          />
        </div>
      </div>
    </>
  );
};

export default CustomPresetFilter;
