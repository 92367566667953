import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Spinner from "../../../components/spinner";

import styles from "./insights-main-spinner.scss";

class InsightsMainSpinner extends Component {
  render() {
    return (
      <div className={styles.wrapper}>
        <Spinner height={250} />
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsMainSpinner);
