import {
  EVENT_MODULE_LOAD,
  EVENT_LOAD_VIEW,
  EVENT_SAVE_VIEW,
  EVENT_LOGIN_SUCCESS,
  EVENT_EXPORT_PDF,
  EVENT_FEATURE_FEEDBACK,
  EVENT_TEXT_COPIED,
  EVENT_INSIGHTS_AI_OPENED,
  EVENT_MARKET_TREND_SELECTED,
  EVENT_PIVOT_SELECTED,
  EVENT_CHART_INTERACTION,
  EVENT_CALENDAR_USED,
  EVENT_COMPARE_PERIOD_SELECTED,
  EVENT_TOGGLED_MY_SKUS,
  EVENT_PAGE_TAB_SELECTED,
  EVENT_MARKET_SHARE_ANALYSIS_CLICKED,
  EVENT_MARKET_SHARE_ANALYSIS_DATA,
  EVENT_FILTER_FROM_GRAPH,
  EVENT_FILTER_APPLIED,
  EVENT_SUPPORT_MESSAGE,
  EVENT_TRIGGER_CREATED,
  EVENT_TRIGGER_SELECTED,
  EVENT_ALERTS_TABLE_OPTION,
  EVENT_SKU_VIEW_MODE,
  EVENT_EXPORT_INSIGHTS_VIEW_FAILURE,
} from "./constants";

import AnalyticsService from "../../../services/analyticsService";
import { getFilterConditionOptions } from "../../../mb_components/mb-filter-sidebar/filter-item/filter-item";
import { splitString } from "../../../utils/other/utilities";

const analyticsService = new AnalyticsService();

// should be gone with https://marketbeyond.atlassian.net/browse/GOOD-7519
const ANALYTICS_MODULES = {
  0: "Insights",
  1: "SKU Tracker",
  2: "Discoverability",
  3: "Market Share",
  4: "Ranking Events",
  5: "Category Insights",
  6: "Account Performance",
  7: "Product 360",
  8: "Market Share Analysis",
};

const getModuleName = (moduleId) => {
  return ANALYTICS_MODULES[moduleId] ?? null;
};

export const analyticsSetUser = (user) => {
  analyticsService.setUser(user);
};

export const analyticsSetDomain = (domain) => {
  analyticsService.setDomain(domain);
};

export const analyticsLoginSuccess = () => {
  analyticsService.sendEvent(EVENT_LOGIN_SUCCESS);
};

export const analyticsModuleLoad = (moduleId) => {
  analyticsService.sendEvent(EVENT_MODULE_LOAD, {
    module_name: getModuleName(moduleId),
  });
};

export const analyticsLoadView = (moduleId, viewName) => {
  const moduleName = getModuleName(moduleId);
  if (moduleName)
    analyticsService.sendEvent(EVENT_LOAD_VIEW, {
      module_name: moduleName,
      view_name: viewName,
    });
};

export const analyticsSaveView = (moduleId, viewName) => {
  analyticsService.sendEvent(EVENT_SAVE_VIEW, {
    module_name: getModuleName(moduleId),
    view_name: viewName,
  });
};

export const analyticsExportPdf = (moduleId, viewName) => {
  analyticsService.sendEvent(EVENT_EXPORT_PDF, {
    module_name: getModuleName(moduleId),
    view_name: viewName,
  });
};

export const analyticsFeatureFeedback = (
  moduleId,
  featureName,
  featureValue = null,
  like = true
) => {
  analyticsService.sendEvent(EVENT_FEATURE_FEEDBACK, {
    module_name: getModuleName(moduleId),
    feature_name: featureName,
    feature_value: featureValue,
    like: like,
  });
};

export const analyticsTextCopied = (moduleId, featureName) => {
  analyticsService.sendEvent(EVENT_TEXT_COPIED, {
    module_name: getModuleName(moduleId),
    featureName: featureName,
  });
};

export const analyticsInsightsAiOpened = (moduleId, viewName) => {
  analyticsService.sendEvent(EVENT_INSIGHTS_AI_OPENED, {
    module_name: getModuleName(moduleId),
    view_name: viewName,
  });
};

export const analyticsMarketTrendSelected = (
  moduleId,
  trendName,
  customer_skus_only
) => {
  const toggleValue =
    customer_skus_only === undefined
      ? "irrelevant"
      : customer_skus_only
      ? "My SKUs"
      : "All SKUs";
  analyticsService.sendEvent(EVENT_MARKET_TREND_SELECTED, {
    module_name: getModuleName(moduleId),
    featureName: trendName,
    sku_mode: toggleValue,
  });
};

export const analyticsMySkusToggled = (moduleId, toggleValue) => {
  analyticsService.sendEvent(EVENT_TOGGLED_MY_SKUS, {
    module_name: getModuleName(moduleId),
    toggled: toggleValue ? "My SKUs" : "All SKUs",
  });
};

export const analyticsPivotSelected = (moduleId, pivot, pivotDimension) => {
  analyticsService.sendEvent(EVENT_PIVOT_SELECTED, {
    module_name: getModuleName(moduleId),
    selected_pivot: pivot,
    pivot_dimension: pivotDimension,
  });
};

export const analyticsChartInteraction = (
  moduleId,
  chartName,
  interactionType,
  interactionValue
) => {
  analyticsService.sendEvent(EVENT_CHART_INTERACTION, {
    module_name: getModuleName(moduleId),
    chart_name: chartName,
    interaction_type: interactionType,
    interaction_value: interactionValue,
  });
};

export const analyticsCalendarUsed = (
  moduleId,
  calendarMode,
  calendarValue
) => {
  analyticsService.sendEvent(EVENT_CALENDAR_USED, {
    module_name: getModuleName(moduleId),
    calendar_mode: calendarMode,
    calendar_value: calendarValue,
  });
};

export const analyticsComparePeriodSelected = (moduleId, selectedPeriod) => {
  analyticsService.sendEvent(EVENT_COMPARE_PERIOD_SELECTED, {
    module_name: getModuleName(moduleId),
    selected_period: selectedPeriod,
  });
};

export const analyticsPageTabSelected = (moduleId, selectedTab) => {
  analyticsService.sendEvent(EVENT_PAGE_TAB_SELECTED, {
    module_name: getModuleName(moduleId),
    selected_tab: selectedTab,
  });
};

export const analyticsMarketShareAnalysisClicked = (moduleId, show) => {
  analyticsService.sendEvent(EVENT_MARKET_SHARE_ANALYSIS_CLICKED, {
    module_name: getModuleName(moduleId),
    show: show,
  });
};

export const analyticsMarketShareAnalysisData = (
  moduleId,
  request,
  response
) => {
  const params = Object.fromEntries(
    request?.insightsViewQuery?.productAttributes.map((x) => [
      [x.displayName],
      [x.values],
    ])
  );
  const your_skus_has_data = response.skusInsights?.length > 0;
  const competing_skus_has_data = response.competingSkus?.length > 0;

  analyticsService.sendEvent(EVENT_MARKET_SHARE_ANALYSIS_DATA, {
    module_name: getModuleName(moduleId),
    your_skus_has_data: your_skus_has_data,
    competing_skus_has_data: competing_skus_has_data,
    ...params,
  });
};

export const analyticsFilterFromGraph = (
  moduleId,
  selectedPivot,
  selectedOption
) => {
  analyticsService.sendEvent(EVENT_FILTER_FROM_GRAPH, {
    module_name: getModuleName(moduleId),
    selected_pivot: selectedPivot,
    selected_option: selectedOption,
  });
};

export const analyticsFiltersApplied = (moduleId, filters) => {
  filters.forEach((f) => {
    const options = getFilterConditionOptions(f);
    const condition = options.find((x) => x.value == f.selectedCondition)?.name;
    const value = ["rootdomain.keyword"].includes(f.mbName)
      ? f.selectedProperties
      : null;
    analyticsService.sendEvent(EVENT_FILTER_APPLIED, {
      module_name: getModuleName(moduleId),
      filter_name: f.displayName,
      filter_condition: condition,
      filter_value: value,
    });
  });
};

export const analyticsSupportMessage = (
  moduleId,
  subject,
  message,
  callback
) => {
  analyticsService.sendEvent(
    EVENT_SUPPORT_MESSAGE,
    {
      module_name: getModuleName(moduleId),
      subject,
      message,
    },
    callback
  );
};

export const analyticsTriggerCreated = (moduleId, notificationType) => {
  analyticsService.sendEvent(EVENT_TRIGGER_CREATED, {
    module_name: getModuleName(moduleId),
    notification_type: notificationType,
  });
};

export const analyticsTriggerSelected = (
  moduleId,
  triggerName,
  triggerType
) => {
  analyticsService.sendEvent(EVENT_TRIGGER_SELECTED, {
    module_name: getModuleName(moduleId),
    trigger_name: triggerName,
    trigger_type: triggerType,
  });
};

export const analyticsAlertsTableOption = (
  moduleId,
  triggerType,
  selectedOption
) => {
  analyticsService.sendEvent(EVENT_ALERTS_TABLE_OPTION, {
    module_name: getModuleName(moduleId),
    trigger_type: triggerType,
    selected_option: selectedOption,
  });
};

export const analyticsSkuViewMode = (moduleId, mode) => {
  analyticsService.sendEvent(EVENT_SKU_VIEW_MODE, {
    module_name: getModuleName(moduleId),
    mode,
  });
};

export const analyticsExportInsightsViewFailure = (
  moduleId,
  parameters,
  err
) => {
  analyticsService.sendEvent(EVENT_EXPORT_INSIGHTS_VIEW_FAILURE, {
    module_name: getModuleName(moduleId),
    parameters,
    error: errorToJson(err),
  });
};

const errorToJson = (err) => {
  return splitString(
    JSON.stringify({
      name: err?.name,
      message: err?.message,
      stack: err?.stack,
      ...err,
    }),
    200
  );
};
