import React from "react";
import styles from "./market-share-chart-loader.scss";
import MarketShareBaseChartLoader from "./market-share-base-chart-loader";
import ContentLoader from "react-content-loader";

export default function MarketShareLineChartLoader(props) {
  const { speed = 2, width = 1280 } = props;
  return (
    <MarketShareBaseChartLoader>
      <div className={styles.lineChart}>
        <ContentLoader speed={speed} width={width} height={142}>
          <path d="M207.867 34.8433L2.26108 7.7714C1.06316 7.61367 0 8.54601 0 9.75428V140C0 141.105 0.895438 142 2.00001 142H1277C1278.1 142 1279 141.105 1279 140V35.7339C1279 34.5012 1277.9 33.562 1276.68 33.7598L1058.83 69.1784C1058.52 69.2295 1058.2 69.2049 1057.9 69.1066L845.582 0.183524C845.213 0.063763 844.817 0.0538593 844.443 0.155018L638.933 55.6749C638.62 55.7594 638.292 55.7666 637.976 55.696L422.329 7.55009C422.102 7.49954 421.869 7.48876 421.639 7.51825L208.382 34.8442C208.211 34.8661 208.038 34.8658 207.867 34.8433Z" />
        </ContentLoader>
      </div>
    </MarketShareBaseChartLoader>
  );
}
