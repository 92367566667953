import React from "react";
import TextControls from "../../mb-filter-sidebar/text-controls";
import styles from "./basic-text-filter.scss";
import { ConvertFilterSidebarToInsightsFilter } from "../../mb-filter-sidebar/filter-conversions";

const BasicTextFilter = (props) => {
  const {
    updateFilters,
    title,
    filterDefinition,
    insightsFiltersCollection,
  } = props;

  const getUpdatedFilters = () => {
    const values = insightsFiltersCollection?.filter(
      (d) => d.displayName === filterDefinition.displayName
    );
    return {
      ...filterDefinition,
      selectedProperties:
        values.length > 0 ? values[0].values : filterDefinition.values,
    };
  };

  const updatedFilter = getUpdatedFilters();

  const onUpdateFilters = (id, value) => {
    filterDefinition.selectedProperties = value?.selectedProperties;

    const insightsFilter = ConvertFilterSidebarToInsightsFilter([
      filterDefinition,
    ]);

    const mergedFilters = insightsFilter.concat(
      insightsFiltersCollection.filter(
        (collection) => collection.displayName !== title
      )
    );
    updateFilters(mergedFilters);
  };

  return (
    <div className={styles.basicTextFilterWrapper}>
      {title}
      <span>Contains</span>{" "}
      <TextControls
        item={updatedFilter}
        isActive={true}
        conditionOptions={
          filterDefinition?.basicFilterConstraints?.ConditionOptions
        }
        onUpdateFilter={onUpdateFilters}
      />
    </div>
  );
};

export default BasicTextFilter;
