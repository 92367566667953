import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ScrollArea from "react-scrollbar";

import Popup from "../../../components/popup";
import ProductDetails from "../../../components/product-details";
import SkusPriceLineChart from "../../skus/skus-price-line-chart/skus-price-line-chart";
import AvailabilityHeatmap from "../../../components/availability-heatmap";
import ErrorBoundry from "../../../components/error-boundry";
import utils from "../../../utils";

import { changeSkusDetailId } from "../insights-skus/actions";
import {
  changePriceChart,
  sortPriceChart,
  changeDailyViewsChart,
  sortDailyViewsChart,
  changeAvailabilityChart,
  sortAvailabilityChart,
  changeDailySalesChart,
  sortDailySalesChart,
} from "./actions";
import styles from "./insights-details.scss";

import { CopyToClipboard } from "react-copy-to-clipboard";
import shareImg from "../../../images/share.svg";
import checkIcon from "../../../images/check.svg";
import TitleSmall from "../../../components/title-small";
import { convertSkuToSkusFlat } from "../../../utils/skus/convertSku";
import { encodeViewConfigQuery } from "../../../utils/skus/generateQueryUrl";

const { checkOwnProperty } = utils.other;

const scrollBarStyle = {
  height: "100vh",
  position: "fixed",
  width: "100%",
  left: "0",
  zIndex: 111,
  outline: 0,
};

class InsightsDetails extends Component {
  getCurrentSkusList = () => {
    const { activeCategory, level1ActiveCategory, skus } = this.props;
    const {
      productList,
      topCategoryLevel1ProductList,
      topCategoryLevel2ProductList,
    } = skus;

    if (level1ActiveCategory) {
      return topCategoryLevel2ProductList;
    }

    if (activeCategory) {
      return topCategoryLevel1ProductList;
    }

    return productList;
  };

  focusDiv() {
    findDOMNode(this.refs.wrap).focus();
  }

  showCopied() {
    this.setState({ copiedURL: true });
  }

  componentDidUpdate(prevProps) {
    const { loading, productDetails } = this.props;
    const { activeId, isVisibleProductDetails } = productDetails;
    const productList = this.getCurrentSkusList();

    if (
      isVisibleProductDetails === true &&
      prevProps.productDetails.isVisibleProductDetails === false
    ) {
      this.setState({ copiedURL: false });
    }
    if (!loading && productList.length !== 0 && (activeId === 0 || activeId)) {
      this.focusDiv();
    }
  }

  combineDataForChart = (viewsHistory, priceHistory, availabilityHistory) => {
    return viewsHistory.map((viewsHistoryElement) => {
      let availability = undefined;
      let availabilityNode = availabilityHistory?.find(
        (x) => x.date === viewsHistoryElement.date
      );
      if (availabilityNode) {
        switch (availabilityNode.availability) {
          case 1:
            availability = "In Stock";
            break;
          case 2:
            availability = "Out of Stock";
            break;
        }
      }

      let price = undefined;
      let priceNode = priceHistory?.find(
        (x) => x.date === viewsHistoryElement.date
      );
      if (priceNode) price = priceNode.price;

      return {
        date: viewsHistoryElement.date,
        data: {
          Views: viewsHistoryElement.dailyViews,
          Price: price,
          Availability: availability,
        },
      };
    });
  };

  constructor() {
    super();
    this.state = {
      copiedURL: false,
    };
    this.showCopied = this.showCopied.bind(this);
  }

  render() {
    const {
      loading,
      productDetails,
      competitors,
      customerLogo,
      priceChartData,
      priceSelected,
      dailyViewsChartData,
      viewTypeId,
      dailyViewsSelected,
      availabilityChartData,
      availabilitySelected,
      dailySalesChartData,
      dailySalesSelected,
    } = this.props;
    const { activeId, isVisibleProductDetails } = productDetails;
    const url = window.location.href.split("?")[0];
    let productList = this.getCurrentSkusList();

    if (viewTypeId === 1) {
      productList = productList.map((sku, index) => {
        return convertSkuToSkusFlat(sku, index);
      });
    }
    const itemIndex = productList.findIndex(({ id }) => id === activeId);

    const dataClient = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "competitorSku"),
      "priceHistory"
    );
    const dataMatched = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "matchedCustomerSku"),
      "priceHistory"
    );
    const nameClient = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "competitorSku"),
      "rootDomain"
    );
    const nameMatched = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "matchedCustomerSku"),
      "rootDomain"
    );

    const dailyViewsDataClient = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "competitorSku"),
      "dailyViewsHistory"
    );

    const availabilityDataClient = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "competitorSku"),
      "availabilityHistory"
    );
    const availabilityDataMatched = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "matchedCustomerSku"),
      "availabilityHistory"
    );
    const availabilityNameClient = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "competitorSku"),
      "rootDomain"
    );
    const availabilityNameMatched = checkOwnProperty(
      checkOwnProperty(productList[itemIndex], "matchedCustomerSku"),
      "rootDomain"
    );

    let tooltipsData = null;
    if (dailyViewsDataClient) {
      tooltipsData = this.combineDataForChart(
        dailyViewsDataClient,
        dataClient,
        availabilityDataClient
      );
    }

    const copiedMode =
      this.state !== null ? (
        this.state.copiedURL ? (
          <TitleSmall id="copied_url" title="Link Copied" iconUrl={checkIcon} />
        ) : null
      ) : null;
    const urlLink =
      itemIndex >= 0
        ? `${url}?b=${encodeViewConfigQuery({
            sortField: 18,
            sortOrder: 1,
            selectedColumns: [],
            queryFrequency: 0,
            productAttributes: [
              {
                displayName: "Text",
                mbName: "text",
                type: "Text",
                values: [productList[itemIndex].competitorSku.sku],
              },
              {
                displayName: "Retailer",
                mbName: "rootdomain.keyword",
                type: "Collection",
                values: [productList[itemIndex].competitorSku.rootDomain],
              },
            ],
          })}`
        : { url };
    const content =
      !loading && productList.length !== 0 && (activeId === 0 || activeId) ? (
        <div className={styles.popupWrapper}>
          <ScrollArea horizontal={false} style={scrollBarStyle}>
            <Popup
              visible={isVisibleProductDetails}
              handleClose={this.props.changeSkusDetailId}
            >
              <div ref="wrap" tabIndex={0} className={styles.wrapper}>
                <div className={styles.margin}></div>
                <ErrorBoundry>
                  <div className={styles.row}>
                    <img className={styles.shareImg} src={shareImg}></img>
                    <CopyToClipboard text={urlLink}>
                      <button
                        className={styles.shareBtn}
                        onClick={() => this.showCopied()}
                      >
                        Share Product
                      </button>
                    </CopyToClipboard>{" "}
                    {copiedMode}
                  </div>
                  <ProductDetails
                    item={productList[itemIndex]}
                    viewTypeId={viewTypeId}
                    competitors={competitors}
                    customerLogo={customerLogo}
                    extendedmMode={true}
                    graphs={[
                      {
                        title:
                          viewTypeId == 0
                            ? "Price Comparison: "
                            : "Price History: ",
                        graph: (
                          <SkusPriceLineChart
                            dataClient={dataClient}
                            dataMatched={viewTypeId == 0 && dataMatched}
                            nameClient={nameClient}
                            nameMatched={viewTypeId == 0 && nameMatched}
                            priceChartData={priceChartData}
                            priceSelected={priceSelected}
                            changePriceChart={this.props.changePriceChart}
                            sortPriceChart={this.props.sortPriceChart}
                          />
                        ),
                      },
                      {
                        title: "Product Availability: ",
                        graph: (
                          <AvailabilityHeatmap
                            dataClient={availabilityDataClient}
                            dataMatched={
                              viewTypeId == 0 && availabilityDataMatched
                            }
                            nameClient={availabilityNameClient}
                            nameMatched={
                              viewTypeId == 0 && availabilityNameMatched
                            }
                            availabilityChartData={availabilityChartData}
                            availabilitySelected={availabilitySelected}
                            changeAvailabilityChart={
                              this.props.changeAvailabilityChart
                            }
                            sortAvailabilityChart={
                              this.props.sortAvailabilityChart
                            }
                          />
                        ),
                      },
                    ]}
                  ></ProductDetails>
                </ErrorBoundry>
                <div className={styles.margin}></div>
              </div>
            </Popup>
          </ScrollArea>
        </div>
      ) : null;

    return content;
  }
}

export const mapStateToProps = (state) => {
  return {
    loading: state.insights.insights.skus.loading,
    productDetails: state.insights.insights.skus.productDetails,
    competitors: state.competitors.data,
    customerLogo: state.user.customerInfo.logoImage,
    skus: state.insights.insights.skus,
    activeCategory: state.insights.insights.activeCategory,
    level1ActiveCategory:
      state.insights.insights.topCategoryLevel1.activeCategory,
    priceChartData: state.insights.insights.skus.priceChart.data,
    priceSelected: state.insights.insights.skus.priceChart.priceSelected,
    dailyViewsChartData: state.insights.insights.skus.dailyViewsChart.data,
    dailyViewsSelected:
      state.insights.insights.skus.dailyViewsChart.dailyViewsSelected,
    dailySalesChartData: state.insights.insights.skus.dailySalesChart.data,
    dailySalesSelected:
      state.insights.insights.skus.dailySalesChart.dailySalesSelected,
    availabilityChartData: state.insights.insights.skus.availabilityChart.data,
    availabilitySelected:
      state.insights.insights.skus.availabilityChart.availabilitySelected,
    viewTypeId: state.insights.insights.viewTypeData.id,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSkusDetailId,
      changePriceChart,
      sortPriceChart,
      changeDailyViewsChart,
      sortDailyViewsChart,
      changeDailySalesChart,
      sortDailySalesChart,
      changeAvailabilityChart,
      sortAvailabilityChart,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InsightsDetails);
