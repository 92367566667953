import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { clearLevelOne, clearLevelTwo } from "../actions";

import styles from "./insights-statistic.scss";
import down from "../../../images/down-arrow.svg";
import { abbreviateNumber } from "../../../utils/other/utilities.js";

class InsightsStatistic extends Component {
  getSum = (item = null) => {
    let result = 0;

    if (item) {
      item.map((item) => {
        if (item.outflow) {
          result = parseFloat(result) + parseFloat(item.outflow);
          result = parseFloat(result.toFixed(2));
        }
      });
    }

    return result;
  };

  getCurrentCategory = () => {
    const {
      activeCategory,
      level1ActiveCategory,
      byCompetitors,
      byCompetitorsLevel1,
      byCompetitorsLevel2,
      byCategories,
      byCategoriesLevel1,
      byCategoriesLevel2,
    } = this.props;

    if (level1ActiveCategory) {
      if (!byCompetitorsLevel2) {
        return [];
      } else {
        return byCompetitorsLevel2;
      }
    }

    if (activeCategory) {
      if (
        (byCategoriesLevel1 && byCategoriesLevel1.length === 0) ||
        !byCategoriesLevel1
      ) {
        return [];
      } else {
        return byCompetitorsLevel1;
      }
    }

    if ((byCategories && byCategories.length === 0) || !byCategories) {
      return [];
    } else {
      return byCompetitors;
    }
  };

  searchCurrentSum = (name, category = []) => {
    let result = category.find((item) => name === item.competitorCategoryName);
    return result ? result.outflow : null;
  };

  getCurrentSum = () => {
    const {
      activeCategory,
      level1ActiveCategory,
      byCategories,
      byCategoriesLevel1,
    } = this.props;

    if (level1ActiveCategory) {
      return this.searchCurrentSum(level1ActiveCategory, byCategoriesLevel1);
    }

    if (activeCategory) {
      return this.searchCurrentSum(activeCategory, byCategories);
    }

    return this.getSum(byCategories);
  };

  getCurrentCompetitorsList = () => {
    const {
      activeCategory,
      level1ActiveCategory,
      byCompetitors,
      byCompetitorsLevel1,
      byCompetitorsLevel2,
    } = this.props;

    if (level1ActiveCategory) {
      return byCompetitorsLevel2 || [];
    }

    if (activeCategory) {
      return byCompetitorsLevel1 || [];
    }

    return byCompetitors || [];
  };

  getImages = (category = []) => {
    const { competitors } = this.props;
    let result = [];

    category.map((item) => {
      competitors.map((itemInner) => {
        if (itemInner.rootDomain === item.competitorRootDomain) {
          result.push(itemInner.logoImage);
        }
      });
    });

    return result;
  };

  render() {
    const { loading } = this.props;

    const category = this.getCurrentCategory();
    const sum = this.getCurrentSum();
    const images = this.getImages(category);
    const competitorsList = this.getCurrentCompetitorsList();

    if (loading) {
      return null;
    }

    if (competitorsList.length === 0) {
      return <div className={styles.empty}></div>;
    }

    return (
      <div className={styles.wrapper}>
        <div id="cmpt_logos" className={styles.logos}>
          {images.map((item, i) => (
            <div className={styles.logoCont} key={i}>
              <img
                id={competitorsList[i + 1].competitorRootDomain + "_logo"}
                src={item}
              />
            </div>
          ))}
        </div>
        <div className={styles.info}>
          <div className={styles.imgBox}>
            <img src={down} />
          </div>
          <div className={styles.sumBox}>
            <div className={styles.title}>Revenue Lost to Competitors</div>
            <div id="statistic_sum" className={styles.sum}>
              ${abbreviateNumber(sum)}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    loading: state.insights.insights.loading,
    activeCategory: state.insights.insights.activeCategory,
    level1ActiveCategory:
      state.insights.insights.topCategoryLevel1.activeCategory,

    byCompetitors: !state.insights.insights.subNodesInfo
      ? []
      : Object.keys(state.insights.insights.subNodesInfo).map((competitor) => {
          return {
            competitorRootDomain: competitor,
            outflow: 0,
            competitorName: competitor,
          };
        }),

    byCompetitorsLevel1:
      state.insights.insights.activeCategory == ""
        ? []
        : Object.keys(state.insights.insights.subNodesInfo)
            .filter((competitor) => {
              return state.insights.insights.subNodesInfo[competitor]
                .subNodesInfo[state.insights.insights.activeCategory];
            })
            .map((competitor) => {
              return {
                competitorRootDomain: competitor,
                outflow: 0,
                competitorName: competitor,
              };
            }),

    byCompetitorsLevel2:
      state.insights.insights.topCategoryLevel1.activeCategory == ""
        ? []
        : Object.keys(state.insights.insights.subNodesInfo)
            .filter((competitor) => {
              return (
                state.insights.insights.subNodesInfo[competitor].subNodesInfo[
                  state.insights.insights.activeCategory
                ] &&
                state.insights.insights.subNodesInfo[competitor].subNodesInfo[
                  state.insights.insights.activeCategory
                ].subNodesInfo[
                  state.insights.insights.topCategoryLevel1.activeCategory
                ]
              );
            })
            .map((competitor) => {
              return {
                competitorRootDomain: competitor,
                outflow: 0,
                competitorName: competitor,
              };
            }),

    byCategories: !state.insights.insights.subNodesInfo
      ? []
      : Object.keys(
          state.insights.insights.subNodesInfo["all_domains"].subNodesInfo
        ).map((lvl1) => {
          return {
            competitorCategoryName: lvl1,
            outflow: 0,
            trend: 2,
          };
        }),

    byCategoriesLevel1: !state.insights.insights.topCategoryLevel1.subNodesInfo
      ? []
      : Object.keys(state.insights.insights.topCategoryLevel1.subNodesInfo).map(
          (lvl2) => {
            return {
              competitorCategoryName: lvl2,
              outflow: 0,
              trend: 2,
            };
          }
        ),

    byCategoriesLevel2: !state.insights.insights.topCategoryLevel2.subNodesInfo
      ? []
      : Object.keys(state.insights.insights.topCategoryLevel2.subNodesInfo).map(
          (lvl2) => {
            return {
              competitorCategoryName: lvl2,
              outflow: 0,
              trend: 2,
            };
          }
        ),

    competitors: state.competitors.data,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      clearLevelOne,
      clearLevelTwo,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InsightsStatistic);
