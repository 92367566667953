import React from "react";
import defaultStyles from "../modal-wrapper.scss";

function ModalFooterTwoButton(props) {
  const {
    cancelButtonLabel,
    onClickCancelButton,
    cancelButtonDisabled,
    styles,
  } = props;
  const {
    confirmButtonLabel,
    onClickConfirmButton,
    confirmButtonDisabled,
  } = props;
  return (
    <>
      <button
        className={`${styles.cancelButton} ${defaultStyles.cancelButton}`}
        onClick={onClickCancelButton}
        disabled={cancelButtonDisabled}
      >
        {cancelButtonLabel}
      </button>
      <button
        className={`${styles.confirmButton} ${defaultStyles.confirmButton}`}
        onClick={onClickConfirmButton}
        disabled={confirmButtonDisabled}
      >
        {confirmButtonLabel}
      </button>
    </>
  );
}

export default ModalFooterTwoButton;
