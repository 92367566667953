import { isEmpty, isUndefined } from "lodash";
import React, { useState } from "react";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownHeader,
  dropdownBody,
  dropdownFooter,
} from "../dropdown";
import defaultStyles from "./filter-attributes-dropdown.scss";
import DropdownBodyLoader from "../dropdown/dropdown-body/dropdown-body-loader";

const { DropdownTitleSelectedItem } = dropdownTitle;
const { DropdownHeaderSearchable } = dropdownHeader;
const { DropdownBodySingleSelect, DropdownBodyMultiselect } = dropdownBody;
const { DropdownFooterSingleButton } = dropdownFooter;

function FilterAttributesDropdown(props) {
  const [filterValue, setFilterValue] = useState("");
  const {
    title,
    titleClassName,
    selectedItems,
    options,
    closeButton,
    keepInput,
    styles,
    isOpen,
    multiSelect = true,
  } = props;

  const handleClickItemMultiSelect = ({ selectedItems, isOverflowed }) => {
    if (!isOverflowed) {
      if (!keepInput) setFilterValue("");
      props.handleChangeAttributesValues(selectedItems);
    }
  };

  const handleClickItemSingleSelect = (selectedItem) => {
    if (!keepInput) setFilterValue("");
    props.handleChangeAttributesValues([selectedItem]);
  };

  const onChangeFilterValue = ({ target }) => {
    setFilterValue(target.value);
  };
  const remappedSelectedItems = selectedItems
    ? options?.filter((option) => selectedItems.includes(option.id)) ?? []
    : [];
  const titles = isEmpty(title)
    ? remappedSelectedItems.length
      ? remappedSelectedItems.map((item) => item.name).join(", ")
      : "Select item"
    : title;
  const filteredOptions = filterValue
    ? options.filter((item) =>
        item.name.toLowerCase().includes(filterValue.toLowerCase())
      )
    : options;

  const dropdownTitle = (
    <DropdownTitleSelectedItem value={titles} className={titleClassName} />
  );
  const dropdownHeader = (
    <DropdownHeaderSearchable
      onChangeHandler={onChangeFilterValue}
      filterValue={filterValue}
    />
  );

  const isLoading = isUndefined(filteredOptions);
  if (isLoading) {
    return (
      <>
        <DropdownWrapper
          dropdownTitle={dropdownTitle}
          dropdownBody={{
            component: DropdownBodyLoader,
          }}
          wrapDropdownBody={false}
          styles={styles ?? defaultStyles}
          className="filterAttributesDropdown"
          isOpen={isOpen}
        />
      </>
    );
  }

  const propsConfig = {
    options: filteredOptions,
    selectedItems: remappedSelectedItems,
    maxSelectedItems: props.maxSelectedItems,
    scrolled: props.scrolled || true,
    styles: styles ?? defaultStyles,
    width: "auto",
    height:
      filteredOptions.length <= 7
        ? 45 + filteredOptions.length * 21
        : undefined,
  };

  const dropdownFooter = closeButton && {
    component: DropdownFooterSingleButton,
    props: { title: "Close" },
    styles: styles ?? defaultStyles,
  };

  return (
    <>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownHeader={dropdownHeader}
        dropdownBody={{
          component: multiSelect
            ? DropdownBodyMultiselect
            : DropdownBodySingleSelect,
          props: propsConfig,
          styles: styles ?? defaultStyles,
        }}
        dropdownFooter={dropdownFooter}
        handleClickItem={
          multiSelect ? handleClickItemMultiSelect : handleClickItemSingleSelect
        }
        wrapDropdownBody={true}
        wrapDropdownFooter={closeButton}
        styles={styles ?? defaultStyles}
        className="filterAttributesDropdown"
        isOpen={isOpen}
      />
    </>
  );
}

export default FilterAttributesDropdown;
