import * as Decorators from "../../styles/cell-decorators";

export const getNumTicks = (tickFormatter, domain) => {
  if (
    [
      Decorators.chartTickNumberFormat,
      Decorators.chartTickPriceFormat,
    ].includes(tickFormatter) &&
    domain[1] > domain[0] &&
    domain[1] - domain[0] < 10
  )
    return Math.ceil(domain[1] - domain[0]);
  return undefined;
};
