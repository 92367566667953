import React from "react";
import DataTable from "../../../components/data-table/data-table";
import * as Decorators from "../../../styles/cell-decorators";
import styles from "./catalog-tracker-table.scss";
import { LoaderWidths } from "../../../components/tables/simple-table/simple-table";

import {
  DOMAINS_TO_COLUMNS_CONFIG,
  DOMAIN_TO_ACCESSOR_MAP,
  CATALOG_TRACKER_VALUE_TYPE,
} from "../constants";

let smallLoader = {
  loaderWidth: LoaderWidths.Small,
};

const CatalogTrackerTable = (props) => {
  const {
    matchesList,
    valueType,
    isLoading,
    rootdomains,
    mainRootdomain,
    onSelectRow,
  } = props;

  const selectedColumns = [
    0,
    ...rootdomains.map((domain) => {
      return DOMAINS_TO_COLUMNS_CONFIG[domain].id;
    }),
  ];

  const getCellDecorator = (valueType) => {
    switch (valueType) {
      case CATALOG_TRACKER_VALUE_TYPE.number:
        return Decorators.catalogTrackerNumberDecorator;
      case CATALOG_TRACKER_VALUE_TYPE.price_diff:
        return Decorators.catalogTrackerPriceDiffDecorator;
      case CATALOG_TRACKER_VALUE_TYPE.number_diff:
        return Decorators.catalogTrackerNumberDiffDecorator;
      case CATALOG_TRACKER_VALUE_TYPE.average:
        return Decorators.catalogTrackerAvgDecorator;
      case CATALOG_TRACKER_VALUE_TYPE.oos:
        return Decorators.catalogTrackerOosDecorator;
      case CATALOG_TRACKER_VALUE_TYPE.index:
        return Decorators.catalogTrackerIndexDecorator;
      case CATALOG_TRACKER_VALUE_TYPE.ratings_and_avg:
        return Decorators.catalogTrackerRatingsAndAvgDecorator;
      case CATALOG_TRACKER_VALUE_TYPE.promo:
        return Decorators.catalogTrackerPromoDecorator;
      default:
        return Decorators.basicDecorator;
    }
  };

  const skuColumnConfig = {
    id: 0,
    label: "SKU",
    accessor: "skuData",
    tooltip: "SKU",
    loaderWidth: LoaderWidths.Huge,
    cellDecorator: Decorators.catalogTrackerSkuDecorator,
  };

  const prepareColumnsForTable = () => {
    const cellDecorator = getCellDecorator(valueType);
    let relevantColumns = [
      skuColumnConfig,
      {
        ...DOMAINS_TO_COLUMNS_CONFIG[mainRootdomain],
        cellDecorator: cellDecorator,
        loaderWidth: smallLoader.loaderWidth,
      },
    ];
    rootdomains.forEach((domain) => {
      if (domain !== mainRootdomain) {
        relevantColumns.push({
          ...DOMAINS_TO_COLUMNS_CONFIG[domain],
          cellDecorator: cellDecorator,
          loaderWidth: smallLoader.loaderWidth,
        });
      }
    });
    return relevantColumns;
  };

  const prepareDataForTable = (data) => {
    return data?.map((item) => {
      const domainsData = {};
      rootdomains.forEach((domain) => {
        domainsData[DOMAIN_TO_ACCESSOR_MAP[domain]] = item.domainsInfo[domain];
      });
      return {
        skuData: {
          name: item.name,
          mpn: item.mpn,
          upc: item.upc,
          image: item.url,
          brand: item.brand,
          productType: item.productType,
        },
        ...domainsData,
      };
    });
  };

  const columnsConfig = prepareColumnsForTable();

  const disableTooltipForColumns = selectedColumns;
  const sortableColumns = [];

  const sorting = {
    sortingColumn: 1,
  };

  const onSelectRowHandler = (id) => {
    onSelectRow(matchesList[id].matchingId);
  };

  return (
    <>
      <DataTable
        header="Competitive Retail Tracker"
        selectedColumns={selectedColumns}
        onSelectRow={onSelectRowHandler}
        data={matchesList}
        prepareDataForTable={prepareDataForTable}
        isSticky={true}
        columnsConfig={columnsConfig}
        sortableColumns={sortableColumns}
        disableTooltipForColumns={disableTooltipForColumns}
        isLoading={isLoading}
        excludedTableColumns={[]}
        sorting={sorting}
        hideColumnsDropdown={true}
        styles={styles}
      />
    </>
  );
};

export default CatalogTrackerTable;
