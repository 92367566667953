import React, { useCallback, useState } from "react";
import styles from "./event-sku-dropdown.scss";
import BaseTooltipComponent from "../../../../../components/charts/visx/tooltips/base-tooltip-component";
import baseStyles from "../../../../../components/charts/visx/tooltips/tooltip-component.scss";
import {
  updateCurrentViewConfigQuery,
  updateInsightsViewConfig,
} from "../../../../insights/insights-data-controls/actions";
import InsightsService from "../../../../../services/insightsService";
import _ from "lodash";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { RANKING_EVENTS_VIEW_TYPE_DATA } from "../../../constants";
import { useSubspace } from "react-redux-subspace";
import EntityFilterIcon from "../../../../../images/entity-filter.svg";
import EntityExcludeIcon from "../../../../../images/entity-exclude.svg";
import EntityViewSkusIcon from "../../../../../images/entity-view-skus.svg";
import ExcludeNotificationsIcon from "../../../../../images/exclude-notifications.svg";
import LimitNotificationsIcon from "../../../../../images/limit-notifications.svg";
import { analyticsAlertsTableOption } from "../../../../shared/analytics/actions";
const insightsService = new InsightsService();

const OPTIONS = {
  viewSku: { text: "View alerts for this SKU", icon: EntityFilterIcon },
  excludeSku: { text: "Hide alerts for this SKU", icon: EntityExcludeIcon },
  openSku: { text: "View SKU", icon: EntityViewSkusIcon },
  limitSkuAlerts: {
    text: "Receive only SKU-specific future alerts",
    icon: LimitNotificationsIcon,
  },
  excludeSkuAlerts: {
    text: "Exclude SKU from future alerts",
    icon: ExcludeNotificationsIcon,
  },
};

const EventSkuDropdown = ({
  dropdownConfig,
  setDropdownConfig,
  currentViewConfig,
  views,
  entityFilterDefinitions,
  sendToast,
  selectedEvent,
}) => {
  const { x, y, sku, url } = dropdownConfig;

  const mapState = useCallback(
    (state) => state[RANKING_EVENTS_VIEW_TYPE_DATA.subspace]
  );
  const subspace = useSubspace(mapState, [
    RANKING_EVENTS_VIEW_TYPE_DATA.subspace,
  ]);

  const dynamicAttribute = entityFilterDefinitions[0];

  const onOptionClick = (option) => {
    analyticsAlertsTableOption(
      RANKING_EVENTS_VIEW_TYPE_DATA.id,
      selectedEvent?.title,
      option
    );
    let updateTrigger = false;
    let condition;
    switch (option) {
      case OPTIONS.openSku.text:
        window.open(url, "_blank");
        return;
      case OPTIONS.limitSkuAlerts.text:
        updateTrigger = true;
      case OPTIONS.viewSku.text:
        condition = "include";
        break;
      case OPTIONS.excludeSkuAlerts.text:
        updateTrigger = true;
      case OPTIONS.excludeSku.text:
        condition = "exclude";
        break;
    }
    const prodAttr = _.cloneDeep(dynamicAttribute[condition]);
    prodAttr.hasValue = true;
    prodAttr.values = [sku];

    // should it modify the actual trigger
    if (updateTrigger) {
      setDropdownConfig({ ...dropdownConfig, open: false, loading: true });
      const originalView = _.cloneDeep(
        views.find((x) => x.id === currentViewConfig.id)
      );
      if (!originalView) return;
      originalView.query.productAttributes.push(prodAttr);
      subspace
        .dispatch(updateInsightsViewConfig(insightsService, originalView))
        .then((res) => {
          sendToast(`Modified trigger "${currentViewConfig.name}"`, "success");
        })
        .catch((err) => {
          sendToast(`An error occurred. Could not modify trigger`, "error");
        })
        .finally(() => {
          setDropdownConfig({ open: false, loading: false });
        });
    } else {
      // update just the current filters
      const newAttrs = [...currentViewConfig.query.productAttributes, prodAttr];
      subspace.dispatch(
        updateCurrentViewConfigQuery({ productAttributes: newAttrs })
      );
      setDropdownConfig({ open: false });
    }
  };

  const relevantOptions =
    currentViewConfig.id != null
      ? [...Object.entries(OPTIONS).map(([k, v]) => v)]
      : [OPTIONS.viewSku, OPTIONS.excludeSku, OPTIONS.openSku];

  return (
    <div
      onMouseLeave={() => setDropdownConfig({ open: false })}
      style={{
        width: "300px",
        position: "absolute",
        top: y - 10,
        left: x - 40,
        zIndex: 99,
      }}
    >
      <BaseTooltipComponent
        additionalStyles={`${baseStyles.boxshadowStyle} ${styles.baseTooltipOverride}`}
      >
        <div className={styles.options}>
          {relevantOptions.map(({ text, icon }) => (
            <div
              key={text}
              className={styles.option}
              onClick={() => onOptionClick(text)}
            >
              <img src={icon} />
              {text}
            </div>
          ))}
        </div>
      </BaseTooltipComponent>
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    currentViewConfig: state.insights.insights.currentViewConfig,
    settings: state.insights.insights.currentViewConfig.query.settings,
    views: state.insights.insights.viewsConfigsList,
    entityFilterDefinitions:
      state.insights.insights.viewDefinitions.entityFilterDefinitions,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventSkuDropdown);
