import React from "react";
import styles from "./error-popup.scss";
import Popup from "../popup";
import { isFunction } from "lodash";
import Button from "../button";
import Attention from "../../images/attention.svg";

export const PREDEFINED_ERRORS = {
  csvExport: CsvExportError,
};

export default function ErrorPopup(props) {
  const { visible = true, onClose, predefinedError = null } = props;
  const body = isFunction(predefinedError)
    ? predefinedError(props)
    : props.children;
  return (
    <div className={styles.popupWrapper}>
      <Popup visible={visible} handleClose={onClose}>
        <div className={styles.popupContainer}>{body}</div>
      </Popup>
    </div>
  );
}

export function CsvExportError(props) {
  return (
    <div className={styles.csvExportError}>
      <div className={styles.csvExportErrorUpperRow}>
        <img src={Attention} className={styles.csvExportErrorAlertBell} />
        We apologize, but your CSV export could not be generated.
        <br />
        This may be due to the size or complexity of the data requested.
        <br />
        Please try again later, or consider refining your request to include a
        smaller data set.
        <br />
        If the issue persists, feel free to contact our support team for further
        assistance.
        <br />
        Thank you for your understanding.
      </div>
      <Button
        text="Close"
        onClick={props.onClose}
        variant="contained"
        className={styles.csvExportErrorButton}
      />
    </div>
  );
}
