import React from "react";
import ChartToggleComponent from "../../../../../components/charts/visx/toggle/chart-toggle-component";
import Switch from "../../../../../components/switch/switch";
import styles from "./chart-controls.scss";

const ChartControls = ({ chartSettings, setChartSettings, chartTypes }) => {
  return (
    <div className={styles.chartControls}>
      <div className={styles.rightSection}>
        <Switch
          toggled={chartSettings.showComparePeriod}
          onChange={() =>
            setChartSettings({
              showComparePeriod: !chartSettings.showComparePeriod,
            })
          }
          titleRight={"Show comparison"}
        />
        <ChartToggleComponent
          availableTypes={chartTypes}
          selectedType={chartSettings.selectedType}
          onSelectType={(t) => setChartSettings({ selectedType: t })}
        />
      </div>
    </div>
  );
};

export default ChartControls;
