import React, { useEffect, useRef, useState } from "react";
import styles from "./sku-promotions.scss";
import PromotionActivitiesTable from "./promotion-activities-table/promotion-activities-table";
import PromotionActivitiesCharts from "./promotion-activities-charts/promotion-activities-charts";
import { connect } from "react-redux";
import { fetchPromoActivity } from "../../actions";
import { bindActionCreators } from "redux";
import { STATUS_ERROR, STATUS_LOADING, STATUS_SUCCESS } from "../../constants";
import MbRequestsErrors from "../../../../mb_components/mb-requests-errors/mb-requests-errors";

const SkuPromotions = ({
  selectedSku,
  forceLoading,

  currentViewConfigQuery,
  selectedCompetitor,
  productAttributes,
  selectedDateRange,
  selectedPreviousDateRange,
  comparePeriod,
}) => {
  const [data, setData] = useState();
  const [status, setStatus] = useState(STATUS_LOADING);
  const isMounted = useRef(true);

  const fetchData = async () => {
    const requestParams = {
      requestBody: {
        insightsViewQuery: {
          ...currentViewConfigQuery,
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          previousStartDate: selectedPreviousDateRange.previousStartDate,
          previousEndDate: selectedPreviousDateRange.previousEndDate,
          comparePeriod: comparePeriod,
        },
        sku: selectedSku,
      },
    };

    try {
      const res = await fetchPromoActivity(requestParams);
      if (isMounted.current) {
        setData(res.data);
        setStatus(STATUS_SUCCESS);
      }
    } catch {
      if (isMounted.current) {
        setStatus(STATUS_ERROR);
      }
    }
  };

  useEffect(() => {
    if (!forceLoading) fetchData();

    return () => {
      isMounted.current = false;
    };
  }, [productAttributes, selectedDateRange, selectedCompetitor, comparePeriod]);

  const prepareDataForTable = () => {
    if (status === STATUS_SUCCESS) {
      const filtered = [];
      for (const row of data) {
        if (row.currentPromoDays > 0) {
          if (row.currentPromoGmv > 0 && row.currentPromoUnitsSold == 0)
            continue;
          filtered.push(row);
        }
      }
      return filtered;
    }
  };

  return (
    <div className={styles.skuPromotions}>
      {status === STATUS_ERROR ? (
        <MbRequestsErrors errorType={"beError"} />
      ) : (
        <>
          <PromotionActivitiesTable
            data={prepareDataForTable()}
            isLoading={status === STATUS_LOADING}
          />
          <div className={styles.separator}></div>
          <PromotionActivitiesCharts
            chartsData={data}
            isLoading={status === STATUS_LOADING}
          />
        </>
      )}
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SkuPromotions);
