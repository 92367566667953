import React, { useEffect, useState } from "react";
import styles from "./list-search.scss";

const ListSearch = ({ unfilteredData, setFilteredData, fieldAccessor }) => {
  const [filterTerm, setFilterTerm] = useState();

  const getNestedField = (item, accessor) => {
    if (typeof accessor !== "string") return undefined;
    if (accessor.indexOf(".") !== -1) {
      const currentItem = item[accessor.split(".")[0]];
      if (currentItem !== undefined)
        if (accessor.split(".").length > 1)
          return getNestedField(
            currentItem,
            accessor.split(".").slice(1).join(".")
          );
      return currentItem;
    }
    return item[accessor];
  };

  useEffect(() => {
    const filteredData = filterTerm
      ? unfilteredData.filter((item) =>
          getNestedField(item, fieldAccessor)
            .toLowerCase()
            .includes(filterTerm.toLowerCase())
        )
      : unfilteredData;
    setFilteredData(filteredData);
  }, [filterTerm, unfilteredData, fieldAccessor]);

  return (
    <div className={styles.searchbox}>
      <input
        autoFocus
        value={filterTerm ?? ""}
        onChange={(e) => setFilterTerm(e.target.value)}
        placeholder={"Search..."}
      />
    </div>
  );
};

export default ListSearch;
