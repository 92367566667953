import React, { useState, useRef, useEffect } from "react";
import styles from "./compare-period.scss";
import blueArrows from "../../images/blue-compare-arrows.svg";
import greyArrows from "../../images/grey-compare-arrows.svg";
import blueChevron from "../../images/blue-chevron.svg";
import greyChevron from "../../images/grey-chevron.svg";
import DatePickerService from "../../services/datePickerService";
import { COMPARE_PERIODS } from "./constants";

const datePickerService = new DatePickerService();

const defaultPeriods = [
  COMPARE_PERIODS.previousPeriod,
  COMPARE_PERIODS.previousYear,
];

export default function ComparePeriod(props) {
  const {
    selectedDateRange,
    selectedItemName,
    handleSelect,
    comparePeriods = defaultPeriods,
  } = props;
  const divRef = useRef(null);

  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handlePeriodClick = (period) => {
    handleSelect(period);
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    document.addEventListener("mousedown", clickAnywhere);

    return () => {
      document.removeEventListener("mousedown", clickAnywhere);
    };
  }, []);

  const clickAnywhere = (e) => {
    if (!divRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const getComparePeriodDisplayName = (period) => {
    const result = period.replace(/([a-z])([A-Z])/g, "$1 $2");
    return result.charAt(0).toUpperCase() + result.slice(1).toLowerCase();
  };

  const getComparePeriodString = (period) => {
    const dateOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };

    const {
      previousStartDate,
      previousEndDate,
    } = datePickerService.getPreviousDates(selectedDateRange, period);

    return selectedDateRange !== undefined
      ? `${getComparePeriodDisplayName(period)} (${new Date(
          Date.parse(previousStartDate)
        ).toLocaleDateString("en-US", dateOptions)} - ${new Date(
          Date.parse(previousEndDate)
        ).toLocaleDateString("en-US", dateOptions)})`
      : "";
  };

  return (
    <div ref={divRef} className={styles.comparePeriodWrapper}>
      <div
        className={
          isOpen ? styles.comparePeriodHeaderOpen : styles.comparePeriodHeader
        }
        onClick={handleClick}
      >
        <img
          src={isOpen ? blueArrows : greyArrows}
          className={styles.arrowsIcon}
        />
        <input
          value={getComparePeriodString(selectedItemName)}
          className={
            isOpen ? styles.comparePeriodInputOpen : styles.comparePeriodInput
          }
          readOnly
          type="text"
        />
        <img
          className={styles.chevron}
          src={isOpen ? blueChevron : greyChevron}
        />
      </div>
      {isOpen && (
        <div className={styles.comparePeriodContentWrapper}>
          <div className={styles.comparePeriodContentHeader}>
            Select Compare Period
          </div>
          <div className={styles.periodWrapper}>
            {comparePeriods.map((option, index) => (
              <div
                className={styles.periodOption}
                key={index}
                onClick={() => handlePeriodClick(option)}
              >
                {getComparePeriodString(option)}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
