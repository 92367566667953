import React, { useState } from "react";
import styles from "./request-preset.scss";
import Button from "../../../../components/button";
import { SCREENS } from "../../consts";
import HeaderContainer from "../../components/header-container";
import CenteredTitle from "../../components/centered-title";
import LoadingCover from "../../components/loading-cover/loading-cover";
import { analyticsSupportMessage } from "../../../../containers/shared/analytics/actions";

const RequestPreset = ({
  moveToScreen,
  formData,
  setFormData,
  configData,
  setConfigData,
  sendToast,
}) => {
  const [inputText, setInputText] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitResponse = (res) => {
    setIsLoading(false);
    if (res === 1) {
      sendToast("Your request was submitted", "success");
      moveToScreen(SCREENS.triggerPresets);
    } else {
      sendToast("An error occurred. Please try again later", "error");
    }
  };

  const onSubmit = () => {
    if (inputText.trim().length === 0) {
      sendToast("Message cannot be empty", "error");
      return;
    }
    setIsLoading(true);
    analyticsSupportMessage(
      configData.viewTypeData.id,
      "New preset request",
      inputText,
      onSubmitResponse
    );
  };

  return (
    <>
      <HeaderContainer>
        <CenteredTitle>Request a custom trigger</CenteredTitle>
      </HeaderContainer>
      <div className={styles.formBody}>
        <textarea
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          placeholder="Type your message"
        />
        <Button variant="contained" text="Submit" onClick={() => onSubmit()} />
      </div>
      {isLoading && <LoadingCover />}
    </>
  );
};

export default RequestPreset;
