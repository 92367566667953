import React from "react";
import beError from "../../images/backend-error-msg.svg";
import infoError from "../../images/info-error-msg.svg";
import dataError from "../../images/no-data-error-msg.svg";
import marketShareError from "../../images/no-category-selected.svg";

import styles from "./mb-requests-errors.scss";

function MbRequestsErrors(props) {
  const getErrorInfo = () => {
    var errorInfo = { errorMsg: null, errorImg: null };
    switch (props.errorType) {
      case "marketshareError":
        errorInfo.errorMsg = "";
        errorInfo.errorImg = marketShareError;
        break;
      case "beError":
        errorInfo.errorMsg = "Something went wrong.";
        errorInfo.errorImg = beError;
        break;
      case "infoError":
        errorInfo.errorMsg = props.errorMsg;
        errorInfo.errorImg = infoError;
        break;
      case "noDataError":
      default:
        errorInfo.errorMsg = "No data available for this report.";
        errorInfo.errorImg = dataError;
        break;
    }
    return errorInfo;
  };
  const { errorMsg, errorImg } = getErrorInfo();
  return (
    <div className={styles.errorContainer}>
      <div className={styles.errorImgContainer}>
        <img src={errorImg} />
      </div>
      <div className={styles.errorTextContainer}>
        <p className={styles.errorMsg}>{errorMsg}</p>
      </div>
    </div>
  );
}
export default MbRequestsErrors;
