import React from "react";
import styles from "./tooltip-component.scss";
import { decorateValue } from "../../../../styles/cell-decorators";
import BaseTooltipComponent from "./base-tooltip-component";
import imagePlaceholder from "../../../../images/no-image-tooltip.svg";

export default function BarTooltipComponentContent(props) {
  const {
    datum,
    section,
    tooltipTitleName,
    fields,
    valueDecorator,
    reverseKeys = false,
  } = props;
  const fieldKeys = reverseKeys
    ? Object.keys(fields).reverse()
    : Object.keys(fields);
  return (
    <BaseTooltipComponent>
      <div className={styles.tooltipTitleWrapper}>
        {datum.image && (
          <img
            src={
              !datum.image || datum.image === ""
                ? imagePlaceholder
                : datum.image
            }
          />
        )}
        <div className={styles.tooltipTitle}>{tooltipTitleName}</div>
      </div>
      <div className={styles.tooltipSubtitle}>{section}</div>

      <table>
        <tbody>
          {fieldKeys.map((field) => (
            <tr key={field} className={styles.tooltipRow}>
              <td className={styles.tooltipField}>
                {fields[field].displayName}
              </td>
              <td className={styles.tooltipData}>
                {decorateValue(datum[field], valueDecorator)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </BaseTooltipComponent>
  );
}
