import React from "react";
import ContentLoader from "react-content-loader";

const MarketShareAnalysisImageLoader = ({ size, speed = 2 }) => {
  return (
    <>
      <ContentLoader speed={speed}>
        <rect width="160" height="160" rx="2" fill="#EEEFF1" />
      </ContentLoader>
    </>
  );
};

export default MarketShareAnalysisImageLoader;
