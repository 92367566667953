import React from "react";
import LegendWrapper from "../../../visx_components/legend/side-legend";
import styles from "./base-chart-component.scss";
import BaseChartComponent from "./base-chart-component";
import BarChartWrapper from "../../../visx_components/bar-chart/bar-chart-wrapper";

export default function BarChartComponent(props) {
  const {
    id,
    data = [],
    dataKeys = [],
    xTickFormat = (x) => x,
    xLabel,
    xKey,
    xNumTicks,
    yTickFormat = (x) => x,
    tooltipCallback,
    chartName,
    baseStyles,
    yFormatter,
    graphTitle,
    curveKeysToHoverNames,
    reverseKeys,
    hideLegend = false,
  } = props;

  const legendLabelProps = Object.keys(dataKeys).map((x) =>
    Object.assign(
      {},
      {
        stroke: dataKeys[x]["stroke"],
        strokeDasharray: "0",
        displayName: dataKeys[x].displayName,
      }
    )
  );

  const legendLabels = Object.keys(dataKeys).map((x) => dataKeys[x].label || x);
  const xDomain = data.map((x) => x[xKey]);

  return (
    <BaseChartComponent styles={baseStyles} data={data}>
      <div style={{ width: "100%", position: "relative" }}>
        <div className={styles.chartName}>{chartName}</div>
        <BarChartWrapper
          id={id}
          data={data}
          xDomain={xDomain}
          xKey={xKey}
          xTickFormat={xTickFormat}
          yFormatter={yFormatter}
          graphTitle={graphTitle}
          dataKeys={dataKeys}
          curveKeysToHoverNames={curveKeysToHoverNames}
          reverseKeys={reverseKeys}
        />
      </div>
      <div className={styles.chartInfoWrapper}>
        {!hideLegend && (
          <div className={styles.legendBar}>
            <LegendWrapper
              labels={legendLabels}
              labelsProps={legendLabelProps}
              width={16}
            />
          </div>
        )}
      </div>
    </BaseChartComponent>
  );
}
