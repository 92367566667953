const convertSortValue = (sortValue) => {
  if (sortValue == "Views Diff" || sortValue == 8) {
    return "CompetitorViews1dDiff";
  }

  if (sortValue == "Views" || sortValue == 7) {
    return "CompetitorViews1d";
  }

  if (sortValue == "Share of Monthly Search" || sortValue == 12) {
    return "CompetitorViews";
  }

  if (sortValue == "Search Change" || sortValue == 9) {
    return "Competitor1DDiffPct";
  }

  if (sortValue == "Price Diff") {
    return "PriceDiff";
  }

  if (sortValue == "Days OOS") {
    return "OutOfStockDays";
  }

  if (sortValue == "Comp. Days OOS") {
    return "OutOfStockDaysComp";
  }

  return sortValue;
};

export default convertSortValue;

const InsightSkusResultSortBy = {
  // Sort by daily views
  CompetitorViews1d: 0,
  // Sort by daily views diff
  CompetitorViews1dDiff: 1,
  // Sort by daily views diff %
  Competitor1DDiffPct: 2,
  // Sort by daily views rank
  CompetitorViewsRank1d: 3,
  // Sort by daily Search Rank Diff
  CompetitorViewsRankDiff1d: 4,
  // Sort by weekly views
  CompetitorViews7d: 5,
  // Sort by weekly views diff
  CompetitorViews7dDiff: 6,
  // Sort by weekly views diff %
  Competitor7dDiffPct: 7,
  // Sort by weekly views rank
  CompetitorViewsRank7d: 8,
  // Sort by weekly views Search Rank Diff
  CompetitorViewsRankDiff7d: 9,
  // Sort by monthly views
  CompetitorViews: 10,
  // Sort by monthly views diff
  CompetitorViewsDiff: 11,
  // Sort by monthly views diff pct
  CompetitorDiffPct: 12,
  // Sort by Monthly Search Rank
  CompetitorViewsRank: 13,
  // Sort by monthly views Search Rank Diff
  CompetitorViewsRankDiff: 14,
  // Sort by Price
  Price: 15,
  // Sort by Price Diff
  PriceDiff: 16,
  // Sort by comp OOS days
  CompetitorOutOfStockDays: 17,
  // Sort by customer OOS days
  CustomerOutOfStockDays: 18,
  // Sort by upc
  UPC: 19,
  // Sort by daily price diff pct
  TopSkuPriceDiffPct1d: 20,
  // Sort by weekly price diff pct
  TopSkuPriceDiffPct7d: 21,
  // Sort by monthly price diff pct
  TopSkuPriceDiffPct: 22,

  // Sort by daily sales
  Sales1d: 23,
  // Sort by weekly sales
  Sales7d: 24,
  // Sort by monthly sales
  Sales: 25,

  // Sort by daily GMV
  Gmv1d: 26,
  // Sort by weekly GMV
  Gmv7d: 27,
  // Sort by monthly GMV
  Gmv: 28,
  // Sort by daily review count diff
  ReviewCountDiff1d: 29,
  // Sort by weekly review count diff
  ReviewCountDiff7d: 30,
  // Sort by monthly review count diff
  ReviewCountDiff: 31,
  // Sort by average rating (average customer review)
  AverageRating: 32,
  // Sort by daily review count diff
  RatingCountDiff1d: 33,
  // Sort by weekly review count diff
  RatingCountDiff7d: 34,
  // Sort by monthly review count diff
  RatingCountDiff: 35,
  Sales1p1d: 36,
  Sales1p7d: 37,
  Sales1p: 38,
  Sales3p1d: 39,
  Sales3p7d: 40,
  Sales3p: 41,
  Gmv1p1d: 42,
  Gmv1p7d: 43,
  Gmv1p: 44,
  Gmv3p1d: 45,
  Gmv3p7d: 46,
  Gmv3p: 47,
  CustomerPrice: 48,
  UnitsSold30D: 49,
  SalesDollars30D: 50,
  SalesDollars30DShare: 51,
  UnitsSold60D: 52,
  SalesDollars60D: 53,
  UnitsSold90D: 54,
  SalesDollars90D: 55,
};

const SkusResultSortBy = {
  TopSkus_UPC: 6,
  TopSkus_Price: 7,
  TopSkus_PriceDiff1D: 8,
  TopSkus_PriceDiff7D: 9,
  TopSkus_PriceDiff30D: 10,
  TopSkus_VisibilityPeriods1D: 11,
  TopSkus_VisibilityPeriods7D: 12,
  TopSkus_VisibilityPeriods30D: 13,
  TopSkus_DayOOS: 14,
  TopSkus_AverageRating: 15,
  TopSkus_NumberOfRatingsDiff1D: 16,
  TopSkus_NumberOfRatingsDiff7D: 17,
  TopSkus_NumberOfRatingsDiff30D: 18,
  TopSkus_Map: 19,
  TopSkus_MapDiff: 20,
  TopSkus_LastSeen: 21,
  TopSkus_MPN: 22,
  TopSkus_WholesalePrice1d: 23,
  TopSkus_WholesalePrice7d: 24,
  TopSkus_WholesalePrice30d: 25,
  TopSkus_WholesalePrice2cw: 26,
  TopSkus_WholesalePrice4cw: 27,
  TopSkus_WholesalePriceDiff1d: 28,
  TopSkus_WholesalePriceDiff7d: 29,
  TopSkus_WholesalePriceDiff30d: 30,
  TopSkus_WholesalePriceDiff2cw: 31,
  TopSkus_WholesalePriceDiff4cw: 32,
  TopSkus_WholesaleRevenue1d: 33,
  TopSkus_WholesaleRevenue7d: 34,
  TopSkus_WholesaleRevenue30d: 35,
  TopSkus_WholesaleRevenue2cw: 36,
  TopSkus_WholesaleRevenue4cw: 37,
  TopSkus_WholesaleRevenueDiff1d: 38,
  TopSkus_WholesaleRevenueDiff7d: 39,
  TopSkus_WholesaleRevenueDiff30d: 40,
  TopSkus_WholesaleRevenueDiff2cw: 41,
  TopSkus_WholesaleRevenueDiff4cw: 42,
  TopSkus_SalesDollars1d: 43,
  TopSkus_SalesDollars7d: 44,
  TopSkus_SalesDollars30d: 45,
  TopSkus_SalesDollars2cw: 46,
  TopSkus_SalesDollars4cw: 47,
  TopSkus_SalesDollarsDiff1d: 48,
  TopSkus_SalesDollarsDiff7d: 49,
  TopSkus_SalesDollarsDiff30d: 50,
  TopSkus_SalesDollarsDiff2cw: 51,
  TopSkus_SalesDollarsDiff4cw: 52,
  TopSkus_Margin1d: 53,
  TopSkus_Margin7d: 54,
  TopSkus_Margin30d: 55,
  TopSkus_Margin2cw: 56,
  TopSkus_Margin4cw: 57,
  TopSkus_MarginDiff1d: 58,
  TopSkus_MarginDiff7d: 59,
  TopSkus_MarginDiff30d: 60,
  TopSkus_MarginDiff2cw: 61,
  TopSkus_MarginDiff4cw: 62,
  TopSkus_Sales1d: 63,
  TopSkus_Sales7d: 64,
  TopSkus_Sales30d: 65,
  TopSkus_Sales2cw: 66,
  TopSkus_Sales4cw: 67,
  TopSkus_SalesDiff1d: 68,
  TopSkus_SalesDiff7d: 69,
  TopSkus_SalesDiff30d: 70,
  TopSkus_SalesDiff2cw: 71,
  TopSkus_SalesDiff4cw: 72,

  TopSkus_PriceDiff2CW: 73,
  TopSkus_PriceDiff4CW: 74,
  TopSkus_NumberOfRatingsDiff2CW: 75,
  TopSkus_NumberOfRatingsDiff4CW: 76,

  TopSkus_SponsoredRatio1d: 77,
  TopSkus_SponsoredRatio7d: 78,
  TopSkus_SponsoredRatio30d: 79,
  TopSkus_SponsoredRatio2cw: 80,
  TopSkus_SponsoredRatio4cw: 81,
  TopSkus_SponsoredRatioDiff7d: 83,
  TopSkus_SponsoredRatioDiff30d: 84,
  TopSkus_SponsoredRatioDiff2cw: 85,
  TopSkus_SponsoredRatioDiff4cw: 86,
  TopSkus_RankPos1d: 87,
  TopSkus_RankPos7d: 88,
  TopSkus_RankPos30d: 89,
  TopSkus_RankPos2cw: 90,
  TopSkus_RankPos4cw: 91,
  TopSkus_RankPosDiff1d: 92,
  TopSkus_RankPosDiff7d: 93,
  TopSkus_RankPosDiff30d: 94,
  TopSkus_RankPosDiff2cw: 95,
  TopSkus_RankPosDiff4cw: 96,
  TopSkus_VisibilityPeriod1d: 97,
  TopSkus_VisibilityPeriod7d: 98,
  TopSkus_VisibilityPeriod30d: 99,
  TopSkus_VisibilityPeriod2cw: 100,
  TopSkus_VisibilityPeriod4cw: 101,
  TopSkus_VisibilityPeriodDiff1d: 102,
  TopSkus_VisibilityPeriodDiff7d: 103,
  TopSkus_VisibilityPeriodDiff30d: 104,
  TopSkus_VisibilityPeriodDiff2cw: 105,
  TopSkus_VisibilityPeriodDiff4cw: 106,
  TopSkus_Price7d: 107,
  TopSkus_Price30d: 108,
  TopSkus_Price2cw: 109,
  TopSkus_Price4cw: 110,
  TopSkus_AverageRatingDiff1d: 111,
  TopSkus_AverageRatingDiff7d: 112,
  TopSkus_AverageRatingDiff30d: 113,
  TopSkus_AverageRatingDiff2cw: 114,
  TopSkus_AverageRatingDiff4cw: 115,
  TopSkus_NumberOfRatings: 116,
  TopSkus_LastCollectedPrice: 117,
  TopSkus_IsPromo: 118,
  TopSkus_PromoDays7d: 119,
  TopSkus_PromoDays30d: 120,
  TopSkus_PromoDays2cw: 121,
  TopSkus_PromoDays4cw: 122,
  TopSkus_AvailableUnits: 124,
  TopSkus_ImageCount: 125,
  TopSkus_VideoCount: 126,
  TopSkus_DocumentCount: 127,
  TopSkus_PanoramaCount: 128,
  TopSkus_IsAPlus: 129,
  TopSkus_APlusPremium: 130,
  TopSkus_APlusComparison: 131,
  TopSkus_APlusVideo: 132,
  TopSkus_APlusFaq: 133,
  TopSkus_ListPrice: 134,
  TopSkus_ListPrice7d: 135,
  TopSkus_ListPrice30d: 136,
  TopSkus_ListPrice2cw: 137,
  TopSkus_ListPrice4cw: 138,
  TopSkus_ListPriceDiff1d: 139,
  TopSkus_ListPriceDiff7d: 140,
  TopSkus_ListPriceDiff30d: 141,
  TopSkus_ListPriceDiff2cw: 142,
  TopSkus_ListPriceDiff4cw: 143,
};

export const convertSortValue2 = (viewTypeId, sortValue, frequency) => {
  if (viewTypeId == 0) {
    return convertSortValueForInsightsSkus(sortValue, frequency);
  }
  return convertSortValueForTopSkus(sortValue, frequency);
};

function convertSortValueForInsightsSkus(sortValue, frequency) {
  if (sortValue == "Price" || sortValue == 14) {
    return InsightSkusResultSortBy.Price;
  }
  if (sortValue == 48) {
    return InsightSkusResultSortBy.CustomerPrice;
  }
  if (sortValue == "Price Diff" || sortValue == 15) {
    return InsightSkusResultSortBy.PriceDiff;
  }

  if (sortValue == "Days OOS" || sortValue == 16) {
    return InsightSkusResultSortBy.CompetitorOutOfStockDays;
  }

  if (sortValue == "Your Days OOS" || sortValue == 17) {
    return InsightSkusResultSortBy.CustomerOutOfStockDays;
  }
  if (sortValue == "UPC" || sortValue == 19) {
    return InsightSkusResultSortBy.UPC;
  }
  if (sortValue == "Units Sold 30D" || sortValue == 52) {
    return InsightSkusResultSortBy.UnitsSold30D;
  }
  if (sortValue == "Sales Dollars 30D" || sortValue == 53) {
    return InsightSkusResultSortBy.SalesDollars30D;
  }
  if (sortValue == "Sales Dollars 30D Share" || sortValue == 54) {
    return InsightSkusResultSortBy.SalesDollars30DShare;
  }
  if (sortValue == "Units Sold 60D" || sortValue == 55) {
    return InsightSkusResultSortBy.UnitsSold60D;
  }
  if (sortValue == "Sales Dollars 60D" || sortValue == 56) {
    return InsightSkusResultSortBy.SalesDollars60D;
  }
  if (sortValue == "Units Sold 90D" || sortValue == 57) {
    return InsightSkusResultSortBy.UnitsSold90D;
  }
  if (sortValue == "Sales Dollars 90D" || sortValue == 58) {
    return InsightSkusResultSortBy.SalesDollars90D;
  }

  if (frequency == 2) {
    //daily
    if (sortValue == "Views" || sortValue == 7) {
      return InsightSkusResultSortBy.CompetitorViews1d;
    }

    if (sortValue == "Views Diff" || sortValue == 8) {
      return InsightSkusResultSortBy.CompetitorViews1dDiff;
    }

    if (sortValue == "Search Change" || sortValue == 9) {
      return InsightSkusResultSortBy.Competitor1DDiffPct;
    }

    if (
      sortValue.toString().includes("Competitor Search Rank") ||
      sortValue == 10
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRank1d;
    }

    if (sortValue == "Search Rank Diff" || sortValue == 11) {
      return InsightSkusResultSortBy.CompetitorViewsRankDiff1d;
    }

    if (sortValue == "Share of Monthly Search" || sortValue == 12) {
      return InsightSkusResultSortBy.CompetitorViews;
    }
    if (sortValue == "Monthly Search Rank" || sortValue == 13) {
      return InsightSkusResultSortBy.CompetitorViewsRank;
    }
    if (sortValue == "Price Diff %" || sortValue == 22) {
      return InsightSkusResultSortBy.TopSkuPriceDiffPct1d;
    }
    if (sortValue == "Sales" || sortValue == 24) {
      return InsightSkusResultSortBy.Sales1d;
    }
    if (sortValue == "Number Of Reviews" || sortValue == 25) {
      return InsightSkusResultSortBy.ReviewCountDiff1d;
    }
    if (sortValue == "Sales Dollars" || sortValue == 26) {
      return InsightSkusResultSortBy.Gmv1d;
    }
    if (sortValue == "Average Rating" || sortValue == 27) {
      return InsightSkusResultSortBy.AverageRating;
    }
    if (sortValue == "Number Of Ratings" || sortValue == 28) {
      return InsightSkusResultSortBy.RatingCountDiff1d;
    }
    if (sortValue == "Sales 1st Party" || sortValue == 29) {
      return InsightSkusResultSortBy.Sales1p1d;
    }
    if (sortValue == "Sales 3rd Party" || sortValue == 30) {
      return InsightSkusResultSortBy.Sales3p1d;
    }
    if (sortValue == "Sales Dollars 1st Party" || sortValue == 31) {
      return InsightSkusResultSortBy.Gmv1p1d;
    }
    if (sortValue == "Sales Dollars 3rd Party" || sortValue == 32) {
      return InsightSkusResultSortBy.Gmv3p1d;
    }
  } else if (frequency == 1) {
    //weekly
    if (sortValue == "Views" || sortValue == 7) {
      return InsightSkusResultSortBy.CompetitorViews7d;
    }
    if (sortValue == "Views Diff" || sortValue == 8) {
      return InsightSkusResultSortBy.CompetitorViews7dDiff;
    }

    if (sortValue == "Search Change" || sortValue == 9) {
      return InsightSkusResultSortBy.Competitor7dDiffPct;
    }
    if (
      sortValue.toString().includes("Competitor Search Rank") ||
      sortValue == 10
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRank7d;
    }
    if (sortValue == "Search Rank Diff" || sortValue == 11) {
      return InsightSkusResultSortBy.CompetitorViewsRankDiff7d;
    }
    if (sortValue == "Share of Monthly Search" || sortValue == 12) {
      return InsightSkusResultSortBy.CompetitorViews;
    }
    if (sortValue == "Monthly Search Rank" || sortValue == 13) {
      return InsightSkusResultSortBy.CompetitorViewsRank;
    }
    if (sortValue == "Price Diff %" || sortValue == 22) {
      return InsightSkusResultSortBy.TopSkuPriceDiffPct7d;
    }
    if (sortValue == "Sales" || sortValue == 24) {
      return InsightSkusResultSortBy.Sales7d;
    }
    if (sortValue == "Number Of Reviews" || sortValue == 25) {
      return InsightSkusResultSortBy.ReviewCountDiff7d;
    }
    if (sortValue == "Sales Dollars" || sortValue == 26) {
      return InsightSkusResultSortBy.Gmv7d;
    }
    if (sortValue == "Average Rating" || sortValue == 27) {
      return InsightSkusResultSortBy.AverageRating;
    }
    if (sortValue == "Number Of Ratings" || sortValue == 28) {
      return InsightSkusResultSortBy.RatingCountDiff7d;
    }
    if (sortValue == "Sales 1st Party" || sortValue == 29) {
      return InsightSkusResultSortBy.Sales1p7d;
    }
    if (sortValue == "Sales 3rd Party" || sortValue == 30) {
      return InsightSkusResultSortBy.Sales3p7d;
    }
    if (sortValue == "Sales Dollars 1st Party" || sortValue == 31) {
      return InsightSkusResultSortBy.Gmv1p7d;
    }
    if (sortValue == "Sales Dollars 3rd Party" || sortValue == 32) {
      return InsightSkusResultSortBy.Gmv3p7d;
    }
  } else {
    //monthly
    if (sortValue == "Views" || sortValue == 7) {
      return InsightSkusResultSortBy.CompetitorViews;
    }
    if (sortValue == "Views Diff" || sortValue == 8) {
      return InsightSkusResultSortBy.CompetitorViewsDiff;
    }

    if (sortValue == "Search Change" || sortValue == 9) {
      return InsightSkusResultSortBy.CompetitorDiffPct;
    }
    if (
      sortValue.toString().includes("Competitor Search Rank") ||
      sortValue == 10
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRank;
    }
    if (sortValue == "Search Rank Diff" || sortValue == 11) {
      return InsightSkusResultSortBy.CompetitorViewsRankDiff;
    }
    if (sortValue == "Share of Monthly Search" || sortValue == 12) {
      return InsightSkusResultSortBy.CompetitorViews;
    }
    if (sortValue == "Monthly Search Rank" || sortValue == 13) {
      return InsightSkusResultSortBy.CompetitorViewsRank;
    }
    if (sortValue == "Price Diff %" || sortValue == 22) {
      return InsightSkusResultSortBy.TopSkuPriceDiffPct;
    }
    if (sortValue == "Sales" || sortValue == 24) {
      return InsightSkusResultSortBy.Sales;
    }
    if (sortValue == "Number Of Reviews" || sortValue == 25) {
      return InsightSkusResultSortBy.ReviewCountDiff;
    }
    if (sortValue == "Sales Dollars" || sortValue == 26) {
      return InsightSkusResultSortBy.Gmv;
    }
    if (sortValue == "Average Rating" || sortValue == 27) {
      return InsightSkusResultSortBy.AverageRating;
    }
    if (sortValue == "Number Of Ratings" || sortValue == 28) {
      return InsightSkusResultSortBy.RatingCountDiff;
    }
    if (sortValue == "Sales 1st Party" || sortValue == 29) {
      return InsightSkusResultSortBy.Sales1p;
    }
    if (sortValue == "Sales 3rd Party" || sortValue == 30) {
      return InsightSkusResultSortBy.Sales3p;
    }
    if (sortValue == "Sales Dollars 1st Party" || sortValue == 31) {
      return InsightSkusResultSortBy.Gmv1p;
    }
    if (sortValue == "Sales Dollars 3rd Party" || sortValue == 32) {
      return InsightSkusResultSortBy.Gmv3p;
    }
  }
}

function convertSortValueForTopSkus(sortValue, frequency) {
  // Sync with backend, see InsightsView.cs
  // A word from Liran - so sorry for the mess in that file, surely this can (and should) be implemented much better. Just need more time.
  if (sortValue == "UPC" || sortValue == 6) {
    return SkusResultSortBy.TopSkus_UPC;
  }

  if (sortValue == "Retail Price" || sortValue == 7) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_Price30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_Price7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_Price;
    if (frequency == 3) return SkusResultSortBy.TopSkus_Price2cw;
    return SkusResultSortBy.TopSkus_Price4cw;
  }

  if (sortValue == 134) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_ListPrice30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_ListPrice7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_ListPrice;
    if (frequency == 3) return SkusResultSortBy.TopSkus_ListPrice2cw;
    return SkusResultSortBy.TopSkus_ListPrice4cw;
  }

  if (sortValue == 135) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_ListPriceDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_ListPriceDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_ListPriceDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_ListPriceDiff2cw;
    return SkusResultSortBy.TopSkus_ListPriceDiff4cw;
  }

  if (sortValue == "Retail Price Diff" || sortValue == 8) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_PriceDiff30D;
    if (frequency == 1) return SkusResultSortBy.TopSkus_PriceDiff7D;
    if (frequency == 2) return SkusResultSortBy.TopSkus_PriceDiff1D;
    if (frequency == 3) return SkusResultSortBy.TopSkus_PriceDiff2CW;
    return SkusResultSortBy.TopSkus_PriceDiff4CW;
  }

  if (sortValue == "Visibility Periods" || sortValue == 9) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_VisibilityPeriods30D;
    if (frequency == 1) return SkusResultSortBy.TopSkus_VisibilityPeriods7D;
    return SkusResultSortBy.TopSkus_VisibilityPeriods1D;
  }

  if (sortValue == "Day OOS" || sortValue == 10) {
    return SkusResultSortBy.TopSkus_DayOOS;
  }

  if (sortValue == "Average Rating" || sortValue == 11) {
    return SkusResultSortBy.TopSkus_AverageRating;
  }

  if (sortValue == "Number Of Ratings Diff" || sortValue == 12) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff30D;
    if (frequency == 1) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff7D;
    if (frequency == 2) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff1D;
    if (frequency == 3) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff2CW;
    return SkusResultSortBy.TopSkus_NumberOfRatingsDiff4CW;
  }

  if (sortValue == "Map" || sortValue == 13) {
    return SkusResultSortBy.TopSkus_Map;
  }

  if (sortValue == "Map Diff" || sortValue == 14) {
    return SkusResultSortBy.TopSkus_MapDiff;
  }

  if (sortValue == "Last Seen" || sortValue == 16) {
    return SkusResultSortBy.TopSkus_LastSeen;
  }

  if (sortValue == "MPN" || sortValue == 17) {
    return SkusResultSortBy.TopSkus_MPN;
  }

  if (sortValue == "Wholesale Price" || sortValue == 18) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesalePrice30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesalePrice7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesalePrice1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesalePrice2cw;
    return SkusResultSortBy.TopSkus_WholesalePrice4cw;
  }

  if (sortValue == "Wholesale Price Diff" || sortValue == 19) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesalePriceDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesalePriceDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesalePriceDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesalePriceDiff2cw;
    return SkusResultSortBy.TopSkus_WholesalePriceDiff4cw;
  }

  if (sortValue == "Wholesale Revenue" || sortValue == 20) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesaleRevenue30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesaleRevenue7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesaleRevenue1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesaleRevenue2cw;
    return SkusResultSortBy.TopSkus_WholesaleRevenue4cw;
  }

  if (sortValue == "Wholesale Revenue Diff" || sortValue == 21) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff2cw;
    return SkusResultSortBy.TopSkus_WholesaleRevenueDiff4cw;
  }

  if (sortValue == "Retail Sales Dollars" || sortValue == 22) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SalesDollars30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SalesDollars7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SalesDollars1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SalesDollars2cw;
    return SkusResultSortBy.TopSkus_SalesDollars4cw;
  }

  if (sortValue == "Retail Sales Dollars Diff" || sortValue == 23) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SalesDollarsDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SalesDollarsDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SalesDollarsDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SalesDollarsDiff2cw;
    return SkusResultSortBy.TopSkus_SalesDollarsDiff4cw;
  }

  if (sortValue == "Retail Margin" || sortValue == 24) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_Margin30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_Margin7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_Margin1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_Margin2cw;
    return SkusResultSortBy.TopSkus_Margin4cw;
  }

  if (sortValue == "Retail Margin Diff" || sortValue == 25) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_MarginDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_MarginDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_MarginDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_MarginDiff2cw;
    return SkusResultSortBy.TopSkus_MarginDiff4cw;
  }

  if (sortValue == "Unit Sold" || sortValue == 26) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_Sales30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_Sales7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_Sales1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_Sales2cw;
    return SkusResultSortBy.TopSkus_Sales4cw;
  }

  if (sortValue == "Unit Sold Diff" || sortValue == 27) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SalesDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SalesDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SalesDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SalesDiff2cw;
    return SkusResultSortBy.TopSkus_SalesDiff4cw;
  }

  if (sortValue == "Sponsored Rate" || sortValue == 28) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SponsoredRatio30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SponsoredRatio7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SponsoredRatio1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SponsoredRatio2cw;
    return SkusResultSortBy.TopSkus_SponsoredRatio4cw;
  }

  if (sortValue == "Sponsored Rate Diff" || sortValue == 29) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SponsoredRatioDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SponsoredRatioDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SponsoredRatioDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SponsoredRatioDiff2cw;
    return SkusResultSortBy.TopSkus_SponsoredRatioDiff4cw;
  }

  if (sortValue == "Rank" || sortValue == 30) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_RankPos30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_RankPos7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_RankPos1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_RankPos2cw;
    return SkusResultSortBy.TopSkus_RankPos4cw;
  }

  if (sortValue == "Rank Diff" || sortValue == 31) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_RankPosDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_RankPosDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_RankPosDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_RankPosDiff2cw;
    return SkusResultSortBy.TopSkus_RankPosDiff4cw;
  }

  if (sortValue == "Visibility Period" || sortValue == 32) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_VisibilityPeriod30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_VisibilityPeriod7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_VisibilityPeriod1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_VisibilityPeriod2cw;
    return SkusResultSortBy.TopSkus_VisibilityPeriod4cw;
  }

  if (sortValue == "Visibility Period Diff" || sortValue == 33) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff2cw;
    return SkusResultSortBy.TopSkus_VisibilityPeriodDiff4cw;
  }

  if (sortValue == "Average Rating Diff" || sortValue == 34) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_AverageRatingDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_AverageRatingDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_AverageRatingDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_AverageRatingDiff2cw;
    return SkusResultSortBy.TopSkus_AverageRatingDiff4cw;
  }

  if (sortValue == "Number Of Ratings" || sortValue == 35) {
    return SkusResultSortBy.TopSkus_NumberOfRatings;
  }

  if (sortValue == "Promotion Days" || sortValue == 118) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_PromoDays30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_PromoDays7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_IsPromo;
    if (frequency == 3) return SkusResultSortBy.TopSkus_PromoDays2cw;
    return SkusResultSortBy.TopSkus_PromoDays4cw;
  }

  return sortValue;
}

export const convertSortValueToColumn = (viewTypeId, sortValue) => {
  if (viewTypeId == 0) {
    return convertSortValueToColumnForInsightsSkus(sortValue);
  }
  return convertSortValueToColumnForTopSkus(sortValue);
};

export const convertSortValueToColumnForInsightsSkus = (sortValue) => {
  if (
    sortValue == InsightSkusResultSortBy.CompetitorViews1d ||
    sortValue == InsightSkusResultSortBy.CompetitorViews7d
  ) {
    return 7; // 'Views'
  }

  if (
    sortValue == InsightSkusResultSortBy.CompetitorViews1dDiff ||
    sortValue == InsightSkusResultSortBy.CompetitorViews7dDiff ||
    sortValue == InsightSkusResultSortBy.CompetitorViewsDiff
  ) {
    return 8; // 'Views Diff'
  }

  if (
    sortValue == InsightSkusResultSortBy.Competitor1DDiffPct ||
    sortValue == InsightSkusResultSortBy.Competitor7dDiffPct ||
    sortValue == InsightSkusResultSortBy.CompetitorDiffPct
  ) {
    return 9; // 'Search Change'
  }

  if (
    sortValue == InsightSkusResultSortBy.CompetitorViewsRank1d ||
    sortValue == InsightSkusResultSortBy.CompetitorViewsRank7d ||
    sortValue == InsightSkusResultSortBy.CompetitorViewsRank
  ) {
    return 10; // 'Search Rank'
  }

  if (
    sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff1d ||
    sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff7d ||
    sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff
  ) {
    return 11; // 'Search Rank Diff'
  }

  if (sortValue == InsightSkusResultSortBy.CompetitorViews) {
    return 12; // 'Share of Monthly Search'
  }

  if (sortValue == InsightSkusResultSortBy.Price) {
    return 14;
  }

  if (sortValue == InsightSkusResultSortBy.CustomerPrice) {
    return 48;
  }

  if (sortValue == InsightSkusResultSortBy.PriceDiff) {
    return 15;
  }

  if (sortValue == InsightSkusResultSortBy.CompetitorOutOfStockDays) {
    // 'Days OOS'
    return 16;
  }

  if (sortValue == InsightSkusResultSortBy.CustomerOutOfStockDays) {
    // 'Your Days OOS'
    return 17;
  }

  if (
    sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct1d ||
    sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct7d ||
    sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct
  ) {
    return 22;
  }

  if (
    sortValue == InsightSkusResultSortBy.Sales1d || // Sales
    sortValue == InsightSkusResultSortBy.Sales7d ||
    sortValue == InsightSkusResultSortBy.Sales
  ) {
    return 24;
  }

  if (
    sortValue == InsightSkusResultSortBy.ReviewCountDiff1d ||
    sortValue == InsightSkusResultSortBy.ReviewCountDiff7d ||
    sortValue == InsightSkusResultSortBy.ReviewCountDiff1d
  ) {
    return 25;
  }

  if (
    sortValue == InsightSkusResultSortBy.Gmv1d || // GMV
    sortValue == InsightSkusResultSortBy.Gmv7d ||
    sortValue == InsightSkusResultSortBy.Gmv
  ) {
    return 26;
  }

  if (sortValue == InsightSkusResultSortBy.AverageRating) {
    // 'Days OOS'
    return 27;
  }

  if (
    sortValue == InsightSkusResultSortBy.RatingCountDiff1d ||
    sortValue == InsightSkusResultSortBy.RatingCountDiff7d ||
    sortValue == InsightSkusResultSortBy.RatingCountDiff
  ) {
    return 28;
  }

  if (
    sortValue == InsightSkusResultSortBy.Sales1p1d || // Sales 1p
    sortValue == InsightSkusResultSortBy.Sales1p7d ||
    sortValue == InsightSkusResultSortBy.Sales1p
  ) {
    return 29;
  }

  if (
    sortValue == InsightSkusResultSortBy.Sales3p1d || // Sales 3p
    sortValue == InsightSkusResultSortBy.Sales3p7d ||
    sortValue == InsightSkusResultSortBy.Sales3p
  ) {
    return 30;
  }

  if (
    sortValue == InsightSkusResultSortBy.Gmv1p1d || // GMV 1p
    sortValue == InsightSkusResultSortBy.Gmv1p7d ||
    sortValue == InsightSkusResultSortBy.Gmv1p
  ) {
    return 31;
  }

  if (
    sortValue == InsightSkusResultSortBy.Gmv3p1d || // GMV 3p
    sortValue == InsightSkusResultSortBy.Gmv3p7d ||
    sortValue == InsightSkusResultSortBy.Gmv3p
  ) {
    return 32;
  }

  if (sortValue == InsightSkusResultSortBy.UnitsSold30D) return 52;
  if (sortValue == InsightSkusResultSortBy.SalesDollars30D) return 53;
  if (sortValue == InsightSkusResultSortBy.SalesDollars30DShare) return 54;
  if (sortValue == InsightSkusResultSortBy.UnitsSold60D) return 55;
  if (sortValue == InsightSkusResultSortBy.SalesDollars60D) return 56;
  if (sortValue == InsightSkusResultSortBy.UnitsSold90D) return 57;
  if (sortValue == InsightSkusResultSortBy.SalesDollars90D) return 58;

  return sortValue;
};

export const convertSortValueToColumnForTopSkus = (sortValue) => {
  if (sortValue == SkusResultSortBy.TopSkus_UPC) return 6;

  if (
    sortValue == SkusResultSortBy.TopSkus_Price ||
    sortValue == SkusResultSortBy.TopSkus_Price7d ||
    sortValue == SkusResultSortBy.TopSkus_Price30d ||
    sortValue == SkusResultSortBy.TopSkus_Price2cw ||
    sortValue == SkusResultSortBy.TopSkus_Price4cw
  )
    return 7;

  if (
    sortValue == SkusResultSortBy.TopSkus_ListPrice ||
    sortValue == SkusResultSortBy.TopSkus_ListPrice7d ||
    sortValue == SkusResultSortBy.TopSkus_ListPrice30d ||
    sortValue == SkusResultSortBy.TopSkus_ListPrice2cw ||
    sortValue == SkusResultSortBy.TopSkus_ListPrice4cw
  )
    return 134;

  if (
    sortValue == SkusResultSortBy.TopSkus_ListPriceDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_ListPriceDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_ListPriceDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_ListPriceDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_ListPriceDiff4cw
  )
    return 135;

  if (
    sortValue == SkusResultSortBy.TopSkus_PriceDiff1D ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff7D ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff30D ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff2CW ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff4CW
  )
    return 8;

  if (
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriods1D ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriods7D ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriods30D
  )
    return 9;

  if (sortValue == SkusResultSortBy.TopSkus_DayOOS) return 10;

  if (sortValue == SkusResultSortBy.TopSkus_AverageRating) return 11;

  if (
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff1D ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff7D ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff30D ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff2CW ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff4CW
  )
    return 12;

  if (sortValue == SkusResultSortBy.TopSkus_Map) return 13;
  if (sortValue == SkusResultSortBy.TopSkus_MapDiff) return 14;
  if (sortValue == SkusResultSortBy.TopSkus_LastSeen) return 16;
  if (sortValue == SkusResultSortBy.TopSkus_MPN) return 17;

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice4cw
  )
    return 18;

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff4cw
  )
    return 19;

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue4cw
  )
    return 20;

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff4cw
  )
    return 21;

  if (
    sortValue == SkusResultSortBy.TopSkus_SalesDollars1d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars7d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars30d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars2cw ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars4cw
  )
    return 22;

  if (
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff4cw
  )
    return 23;

  if (
    sortValue == SkusResultSortBy.TopSkus_Margin1d ||
    sortValue == SkusResultSortBy.TopSkus_Margin7d ||
    sortValue == SkusResultSortBy.TopSkus_Margin30d ||
    sortValue == SkusResultSortBy.TopSkus_Margin2cw ||
    sortValue == SkusResultSortBy.TopSkus_Margin4cw
  )
    return 24;

  if (
    sortValue == SkusResultSortBy.TopSkus_MarginDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff4cw
  )
    return 25;

  if (
    sortValue == SkusResultSortBy.TopSkus_Sales1d ||
    sortValue == SkusResultSortBy.TopSkus_Sales7d ||
    sortValue == SkusResultSortBy.TopSkus_Sales30d ||
    sortValue == SkusResultSortBy.TopSkus_Sales2cw ||
    sortValue == SkusResultSortBy.TopSkus_Sales4cw
  )
    return 26;

  if (
    sortValue == SkusResultSortBy.TopSkus_SalesDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff4cw
  )
    return 27;

  if (
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatio1d ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatio7d ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatio30d ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatio2cw ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatio4cw
  )
    return 28;

  if (
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatioDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatioDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatioDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatioDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_SponsoredRatioDiff4cw
  )
    return 29;

  if (
    sortValue == SkusResultSortBy.TopSkus_RankPos1d ||
    sortValue == SkusResultSortBy.TopSkus_RankPos7d ||
    sortValue == SkusResultSortBy.TopSkus_RankPos30d ||
    sortValue == SkusResultSortBy.TopSkus_RankPos2cw ||
    sortValue == SkusResultSortBy.TopSkus_RankPos4cw
  )
    return 30;

  if (
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff4cw
  )
    return 31;

  if (
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod1d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod7d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod30d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod2cw ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod4cw
  )
    return 32;

  if (
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff4cw
  )
    return 33;

  if (
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff4cw
  )
    return 34;

  if (sortValue == SkusResultSortBy.TopSkus_NumberOfRatings) return 35;
  if (sortValue == SkusResultSortBy.TopSkus_LastCollectedPrice) return 117;

  if (
    sortValue == SkusResultSortBy.TopSkus_IsPromo ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays7d ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays30d ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays2cw ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays4cw
  )
    return 118;

  if (sortValue == SkusResultSortBy.TopSkus_AvailableUnits) return 124;
  if (sortValue == SkusResultSortBy.TopSkus_ImageCount) return 125;
  if (sortValue == SkusResultSortBy.TopSkus_VideoCount) return 126;
  if (sortValue == SkusResultSortBy.TopSkus_DocumentCount) return 127;
  if (sortValue == SkusResultSortBy.TopSkus_PanoramaCount) return 128;
  if (sortValue == SkusResultSortBy.TopSkus_IsAPlus) return 129;
  if (sortValue == SkusResultSortBy.TopSkus_APlusPremium) return 130;
  if (sortValue == SkusResultSortBy.TopSkus_APlusComparison) return 131;
  if (sortValue == SkusResultSortBy.TopSkus_APlusVideo) return 132;
  if (sortValue == SkusResultSortBy.TopSkus_APlusFaq) return 133;
};

export const convertSortValueAfterChangeFrequency = (
  viewTypeId,
  sortValue,
  frequency
) => {
  if (viewTypeId == 0) {
    return convertSortValueAfterChangeFrequencyForInsightsSkus(
      sortValue,
      frequency
    );
  }
  return convertSortValueAfterChangeFrequencyForTopSkus(sortValue, frequency);
};

export const convertSortValueAfterChangeFrequencyForInsightsSkus = (
  sortValue,
  frequency
) => {
  let isSortBySalesField =
    sortValue == InsightSkusResultSortBy.Sales1d ||
    sortValue == InsightSkusResultSortBy.Sales7d ||
    sortValue == InsightSkusResultSortBy.Sales;
  let isSortBySales1pField =
    sortValue == InsightSkusResultSortBy.Sales1p1d ||
    sortValue == InsightSkusResultSortBy.Sales1p7d ||
    sortValue == InsightSkusResultSortBy.Sales1p;
  let isSortBySales3pField =
    sortValue == InsightSkusResultSortBy.Sales3p1d ||
    sortValue == InsightSkusResultSortBy.Sales3p7d ||
    sortValue == InsightSkusResultSortBy.Sales3p;
  let isSortByGmvField =
    sortValue == InsightSkusResultSortBy.Gmv1d ||
    sortValue == InsightSkusResultSortBy.Gmv7d ||
    sortValue == InsightSkusResultSortBy.Gmv;
  let isSortByGmv1pField =
    sortValue == InsightSkusResultSortBy.Gmv1p1d ||
    sortValue == InsightSkusResultSortBy.Gmv1p7d ||
    sortValue == InsightSkusResultSortBy.Gmv1p;
  let isSortByGmv3pField =
    sortValue == InsightSkusResultSortBy.Gmv3p1d ||
    sortValue == InsightSkusResultSortBy.Gmv3p7d ||
    sortValue == InsightSkusResultSortBy.Gmv3p;
  let isSortByReviewCountDiffField =
    sortValue == InsightSkusResultSortBy.ReviewCountDiff1d ||
    sortValue == InsightSkusResultSortBy.ReviewCountDiff7d ||
    sortValue == InsightSkusResultSortBy.ReviewCountDiff;
  let isSortByAverageRatingField =
    sortValue == InsightSkusResultSortBy.AverageRating;
  let isSortByRatingCountDiffField =
    sortValue == InsightSkusResultSortBy.RatingCountDiff1d ||
    sortValue == InsightSkusResultSortBy.RatingCountDiff7d ||
    sortValue == InsightSkusResultSortBy.RatingCountDiff;

  if (frequency == 2) {
    //daily
    if (
      sortValue == InsightSkusResultSortBy.CompetitorViews1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViews7d
    ) {
      return InsightSkusResultSortBy.CompetitorViews1d;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViews1dDiff ||
      sortValue == InsightSkusResultSortBy.CompetitorViews7dDiff ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsDiff
    ) {
      return InsightSkusResultSortBy.CompetitorViews1dDiff;
    }

    if (
      sortValue == InsightSkusResultSortBy.Competitor1DDiffPct ||
      sortValue == InsightSkusResultSortBy.Competitor7dDiffPct ||
      sortValue == InsightSkusResultSortBy.CompetitorDiffPct
    ) {
      return InsightSkusResultSortBy.Competitor1DDiffPct;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank7d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRank1d;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff7d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRankDiff1d;
    }

    if (
      sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct1d ||
      sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct7d
    ) {
      return InsightSkusResultSortBy.TopSkuPriceDiffPct1d;
    }

    if (isSortBySalesField) {
      return InsightSkusResultSortBy.Sales1d;
    }

    if (isSortBySales1pField) {
      return InsightSkusResultSortBy.Sales1p1d;
    }
    if (isSortBySales3pField) {
      return InsightSkusResultSortBy.Sales3p1d;
    }
    if (isSortByGmvField) {
      return InsightSkusResultSortBy.Gmv1d;
    }
    if (isSortByGmv1pField) {
      return InsightSkusResultSortBy.Gmv1p1d;
    }
    if (isSortByGmv3pField) {
      return InsightSkusResultSortBy.Gmv3p1d;
    }

    if (isSortByReviewCountDiffField) {
      return InsightSkusResultSortBy.ReviewCountDiff1d;
    }

    if (isSortByAverageRatingField) {
      return InsightSkusResultSortBy.AverageRating;
    }

    if (isSortByRatingCountDiffField) {
      return InsightSkusResultSortBy.RatingCountDiff1d;
    }
  } else if (frequency == 1) {
    //weekly
    if (
      sortValue == InsightSkusResultSortBy.CompetitorViews1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViews7d
    ) {
      return InsightSkusResultSortBy.CompetitorViews7d;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViews1dDiff ||
      sortValue == InsightSkusResultSortBy.CompetitorViews7dDiff ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsDiff
    ) {
      return InsightSkusResultSortBy.CompetitorViews7dDiff;
    }

    if (
      sortValue == InsightSkusResultSortBy.Competitor1DDiffPct ||
      sortValue == InsightSkusResultSortBy.Competitor7dDiffPct ||
      sortValue == InsightSkusResultSortBy.CompetitorDiffPct
    ) {
      return InsightSkusResultSortBy.Competitor7dDiffPct;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank7d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRank7d;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff7d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRankDiff7d;
    }

    if (
      sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct1d ||
      sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct7d
    ) {
      return InsightSkusResultSortBy.TopSkuPriceDiffPct7d;
    }

    if (isSortBySalesField) {
      return InsightSkusResultSortBy.Sales7d;
    }
    if (isSortBySales1pField) {
      return InsightSkusResultSortBy.Sales1p7d;
    }
    if (isSortBySales3pField) {
      return InsightSkusResultSortBy.Sales3p7d;
    }
    if (isSortByGmvField) {
      return InsightSkusResultSortBy.Gmv7d;
    }
    if (isSortByGmv1pField) {
      return InsightSkusResultSortBy.Gmv1p7d;
    }
    if (isSortByGmv3pField) {
      return InsightSkusResultSortBy.Gmv3p7d;
    }
    if (isSortByReviewCountDiffField) {
      return InsightSkusResultSortBy.ReviewCountDiff7d;
    }

    if (isSortByAverageRatingField) {
      return InsightSkusResultSortBy.AverageRating;
    }

    if (isSortByRatingCountDiffField) {
      return InsightSkusResultSortBy.RatingCountDiff7d;
    }
  } else {
    //monthly
    if (
      sortValue == InsightSkusResultSortBy.CompetitorViews1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViews7d
    ) {
      return InsightSkusResultSortBy.CompetitorViews;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViews1dDiff ||
      sortValue == InsightSkusResultSortBy.CompetitorViews7dDiff ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsDiff
    ) {
      return InsightSkusResultSortBy.CompetitorViewsDiff;
    }

    if (
      sortValue == InsightSkusResultSortBy.Competitor1DDiffPct ||
      sortValue == InsightSkusResultSortBy.Competitor7dDiffPct ||
      sortValue == InsightSkusResultSortBy.CompetitorDiffPct
    ) {
      return InsightSkusResultSortBy.CompetitorDiffPct;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank7d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRank
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRank;
    }

    if (
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff1d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff7d ||
      sortValue == InsightSkusResultSortBy.CompetitorViewsRankDiff
    ) {
      return InsightSkusResultSortBy.CompetitorViewsRankDiff;
    }

    if (
      sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct1d ||
      sortValue == InsightSkusResultSortBy.TopSkuPriceDiffPct7d
    ) {
      return InsightSkusResultSortBy.TopSkuPriceDiffPct;
    }

    if (isSortBySalesField) {
      return InsightSkusResultSortBy.Sales;
    }
    if (isSortBySales1pField) {
      return InsightSkusResultSortBy.Sales1p;
    }
    if (isSortBySales3pField) {
      return InsightSkusResultSortBy.Sales3p;
    }
    if (isSortByGmvField) {
      return InsightSkusResultSortBy.Gmv;
    }
    if (isSortByGmv1pField) {
      return InsightSkusResultSortBy.Gmv1p;
    }
    if (isSortByGmv3pField) {
      return InsightSkusResultSortBy.Gmv3p;
    }
    if (isSortByReviewCountDiffField) {
      return InsightSkusResultSortBy.ReviewCountDiff;
    }

    if (isSortByAverageRatingField) {
      return InsightSkusResultSortBy.AverageRating;
    }

    if (isSortByRatingCountDiffField) {
      return InsightSkusResultSortBy.RatingCountDiff;
    }
  }
};

export const convertSortValueAfterChangeFrequencyForTopSkus = (
  sortValue,
  frequency
) => {
  if (
    sortValue == SkusResultSortBy.TopSkus_Price ||
    sortValue == SkusResultSortBy.TopSkus_Price7d ||
    sortValue == SkusResultSortBy.TopSkus_Price30d ||
    sortValue == SkusResultSortBy.TopSkus_Price2cw ||
    sortValue == SkusResultSortBy.TopSkus_Price4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_Price30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_Price7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_Price;
    if (frequency == 3) return SkusResultSortBy.TopSkus_Price2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_Price4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_PriceDiff1D ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff7D ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff30D ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff2CW ||
    sortValue == SkusResultSortBy.TopSkus_PriceDiff4CW
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_PriceDiff30D;
    if (frequency == 1) return SkusResultSortBy.TopSkus_PriceDiff7D;
    if (frequency == 2) return SkusResultSortBy.TopSkus_PriceDiff1D;
    if (frequency == 3) return SkusResultSortBy.TopSkus_PriceDiff2CW;
    if (frequency == 4) return SkusResultSortBy.TopSkus_PriceDiff4CW;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriods1D ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriods7D ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriods30D
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_VisibilityPeriods30D;
    if (frequency == 1) return SkusResultSortBy.TopSkus_VisibilityPeriods7D;
    return SkusResultSortBy.TopSkus_VisibilityPeriods1D;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff1D ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff7D ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff30D ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff2CW ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatingsDiff4CW
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff30D;
    if (frequency == 1) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff7D;
    if (frequency == 2) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff1D;
    if (frequency == 3) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff2CW;
    if (frequency == 4) return SkusResultSortBy.TopSkus_NumberOfRatingsDiff4CW;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePrice4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesalePrice30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesalePrice7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesalePrice1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesalePrice2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_WholesalePrice4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesalePriceDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesalePriceDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesalePriceDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesalePriceDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesalePriceDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_WholesalePriceDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenue4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesaleRevenue30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesaleRevenue7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesaleRevenue1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesaleRevenue2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_WholesaleRevenue4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_WholesaleRevenueDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_WholesaleRevenueDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_SalesDollars1d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars7d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars30d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars2cw ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollars4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SalesDollars30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SalesDollars7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SalesDollars1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SalesDollars2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_SalesDollars4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_SalesDollarsDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SalesDollarsDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SalesDollarsDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SalesDollarsDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SalesDollarsDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_SalesDollarsDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_Margin1d ||
    sortValue == SkusResultSortBy.TopSkus_Margin7d ||
    sortValue == SkusResultSortBy.TopSkus_Margin30d ||
    sortValue == SkusResultSortBy.TopSkus_Margin2cw ||
    sortValue == SkusResultSortBy.TopSkus_Margin4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_Margin30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_Margin7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_Margin1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_Margin2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_Margin4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_MarginDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_MarginDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_MarginDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_MarginDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_MarginDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_MarginDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_MarginDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_Sales1d ||
    sortValue == SkusResultSortBy.TopSkus_Sales7d ||
    sortValue == SkusResultSortBy.TopSkus_Sales30d ||
    sortValue == SkusResultSortBy.TopSkus_Sales2cw ||
    sortValue == SkusResultSortBy.TopSkus_Sales4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_Sales30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_Sales7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_Sales1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_Sales2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_Sales4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_SalesDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_SalesDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_SalesDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_SalesDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_SalesDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_SalesDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_SalesDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_RankPos1d ||
    sortValue == SkusResultSortBy.TopSkus_RankPos7d ||
    sortValue == SkusResultSortBy.TopSkus_RankPos30d ||
    sortValue == SkusResultSortBy.TopSkus_RankPos2cw ||
    sortValue == SkusResultSortBy.TopSkus_RankPos4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_RankPos30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_RankPos7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_RankPos1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_RankPos2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_RankPos4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_RankPosDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_RankPosDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_RankPosDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_RankPosDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_RankPosDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_RankPosDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod1d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod7d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod30d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod2cw ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriod4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_VisibilityPeriod30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_VisibilityPeriod7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_VisibilityPeriod1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_VisibilityPeriod2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_VisibilityPeriod4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_VisibilityPeriodDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_VisibilityPeriodDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff1d ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff7d ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff30d ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff2cw ||
    sortValue == SkusResultSortBy.TopSkus_AverageRatingDiff4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_AverageRatingDiff30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_AverageRatingDiff7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_AverageRatingDiff1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_AverageRatingDiff2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_AverageRatingDiff4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_IsPromo ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays7d ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays30d ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays2cw ||
    sortValue == SkusResultSortBy.TopSkus_PromoDays4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_PromoDays30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_PromoDays7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_IsPromo;
    if (frequency == 3) return SkusResultSortBy.TopSkus_PromoDays2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_PromoDays4cw;
  }

  if (
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatings1d ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatings7d ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatings30d ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatings2cw ||
    sortValue == SkusResultSortBy.TopSkus_NumberOfRatings4cw
  ) {
    if (frequency == 0) return SkusResultSortBy.TopSkus_NumberOfRatings30d;
    if (frequency == 1) return SkusResultSortBy.TopSkus_NumberOfRatings7d;
    if (frequency == 2) return SkusResultSortBy.TopSkus_NumberOfRatings1d;
    if (frequency == 3) return SkusResultSortBy.TopSkus_NumberOfRatings2cw;
    if (frequency == 4) return SkusResultSortBy.TopSkus_NumberOfRatings4cw;
  }

  return sortValue;
};

export const convertConfigSortFieldToBrowserString = (
  viewTypeId,
  sortValue
) => {
  let data = SkusResultSortBy;
  if (viewTypeId == 0) {
    data = InsightSkusResultSortBy;
  }

  return Object.keys(data).find((key) => data[key] === sortValue);
};

export const convertBrowserStringSortFieldToConfig = (
  viewTypeId,
  sortValue,
  frequency
) => {
  let data = SkusResultSortBy;
  if (viewTypeId == 0) {
    data = InsightSkusResultSortBy;
  }

  var key = Object.keys(data).find(
    (k) => k.toLowerCase() === sortValue.toLowerCase()
  );
  return data[key];
};
