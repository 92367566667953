import {
  numberDecorator,
  percentDecorator,
  priceDecorator,
  chartTickNumberFormat,
  chartTickPercentFormat,
  chartTickPriceFormat,
  roundNumberDecorator,
} from "../../../../styles/cell-decorators";

import {
  DIMENSIONS
} from "../../constants"

import {
  ROOTDOMAINS, ROOTDOMAINS_ENUM_NAMES
} from "../../../../utils/other/constants";

export const kpisConfig = [
  {
    id: "gmv",
    title: "Sales Dollars",
    isComparative: true,
    decorator: priceDecorator,
    yTickFormatter: chartTickPriceFormat,
  },
  {
    id: "gmvShare",
    title: "Sales Dollars Share",
    isComparative: true,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    id: "sales",
    title: "Units Sold",
    isComparative: true,
    decorator: roundNumberDecorator,
    yTickFormatter: chartTickNumberFormat,
  },
  {
    id: "salesShare",
    title: "Units Sold Share",
    isComparative: true,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
];

export const analysisConfig = [
  {
    id: "asp",
    title: "Average Selling Price",
    isComparative: true,
    decorator: priceDecorator,
    yTickFormatter: chartTickPriceFormat,
  },
  {
    id: "avgListedPrice",
    title: "Average Retail Price",
    isComparative: true,
    decorator: priceDecorator,
    yTickFormatter: chartTickPriceFormat,
  },
  {
    id: "activeSellingSkus",
    title: "Active Selling SKUs",
    isComparative: true,
    unsupportedDimensions: [DIMENSIONS.sku],
    decorator: numberDecorator,
    yTickFormatter: chartTickNumberFormat,
  },
  {
    id: "inStockRate",
    title: "In Stock Rate",
    isComparative: true,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    id: "adsSpend",
    title: "Ads Spend",
    isComparative: true,
    supportedDimensions: [DIMENSIONS.brands, DIMENSIONS.sellers, DIMENSIONS.productTypes, DIMENSIONS.family],
    supportedDomains : [ROOTDOMAINS_ENUM_NAMES.WayfairCom],
    decorator: priceDecorator,
    yTickFormatter: chartTickPriceFormat,
  },
  {
    id: "promoShare",
    title: "Promotion Sales Dollars Share",
    isComparative: true,
    decorator: percentDecorator,
    yTickFormatter: chartTickPercentFormat,
  },
  {
    id: "promoDays",
    title: "Promotion Events",
    isComparative: true,
    decorator: numberDecorator,
    yTickFormatter: chartTickNumberFormat,
  },
  {
    id: "salesPerStore",
    title: "Units Sold Per Store",
    isComparative: true,
    supportedDomains : [ROOTDOMAINS_ENUM_NAMES.TargetCom],
    decorator: roundNumberDecorator,
    yTickFormatter: chartTickNumberFormat,
  }
];
