import React from "react";
import ContentLoader from "react-content-loader";

const MarketShareAnalysisSearchTermsLoader = ({ size, speed = 2 }) => {
  return (
    <>
      <ContentLoader speed={speed} width={430}>
        <rect y="20" width="78" height="10" fill="#EEEFF1" />
        <rect y="40" width="78" height="10" fill="#EEEFF1" />
        <rect y="60" width="78" height="10" fill="#EEEFF1" />
        <rect y="80" width="78" height="10" fill="#EEEFF1" />
        <rect y="100" width="78" height="10" fill="#EEEFF1" />
        <rect x="88" y="20" width="78" height="10" fill="#EEEFF1" />
        <rect x="88" y="40" width="78" height="10" fill="#EEEFF1" />
        <rect x="88" y="60" width="78" height="10" fill="#EEEFF1" />
        <rect x="88" y="80" width="78" height="10" fill="#EEEFF1" />
        <rect x="88" y="100" width="78" height="10" fill="#EEEFF1" />
        <rect x="176" y="20" width="78" height="10" fill="#EEEFF1" />
        <rect x="176" y="40" width="78" height="10" fill="#EEEFF1" />
        <rect x="176" y="60" width="78" height="10" fill="#EEEFF1" />
        <rect x="176" y="80" width="78" height="10" fill="#EEEFF1" />
        <rect x="176" y="100" width="78" height="10" fill="#EEEFF1" />
        <rect x="264" y="20" width="78" height="10" fill="#EEEFF1" />
        <rect x="264" y="40" width="78" height="10" fill="#EEEFF1" />
        <rect x="264" y="60" width="78" height="10" fill="#EEEFF1" />
        <rect x="264" y="80" width="78" height="10" fill="#EEEFF1" />
        <rect x="264" y="100" width="78" height="10" fill="#EEEFF1" />
        <rect x="352" y="20" width="78" height="10" fill="#EEEFF1" />
        <rect x="352" y="40" width="78" height="10" fill="#EEEFF1" />
        <rect x="352" y="60" width="78" height="10" fill="#EEEFF1" />
        <rect x="352" y="80" width="78" height="10" fill="#EEEFF1" />
        <rect x="352" y="100" width="78" height="10" fill="#EEEFF1" />
      </ContentLoader>
    </>
  );
};

export default MarketShareAnalysisSearchTermsLoader;
