import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./loader.scss";

const FrameLoader = (props) => {
  const { speed = 2, width = 330, height = 30, thickness = 1 } = props;
  return (
    <div className={styles.frameLoader}>
      <ContentLoader speed={speed} width={width} height={height}>
        <rect
          x="0"
          y="0"
          rx="3"
          ry="3"
          width={width - thickness - 1}
          height={thickness}
        />
        <rect
          x="0"
          y="1"
          rx="3"
          ry="3"
          width={thickness}
          height={height - thickness}
        />
        <rect
          x="0"
          y={height - thickness}
          rx="3"
          ry="3"
          width={width - thickness - 1}
          height={thickness}
        />
        <rect
          x={width - 2 * thickness - 1}
          y="1"
          rx="3"
          ry="3"
          width={thickness}
          height={height}
        />
        {props.children}
      </ContentLoader>
    </div>
  );
};

export default FrameLoader;
