import { ApiService } from "./apiService";
const apiService = new ApiService();

export default class BasicViewService {
  saveInsightsViewConfig(name, data, viewType) {
    const res = apiService.requestInsightsViewConfigPOST(
      `/insights/views/create/${viewType}?name=${name}`,
      data
    );
    return res;
  }

  updateInsightsViewConfig(id, data) {
    const res = apiService
      .requestInsightsViewConfigUpdatePOST(`/insights/views/update/${id}`, data)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  updateFavoriteInsightViewConfig(id) {
    const res = apiService
      .requestInsightsViewConfigPUT(`/insights/views/favorite/${id}`, null)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  updateInsightsViewConfig2(id, data) {
    const res = apiService
      .requestInsightsViewConfigPOST(`/insights/views/update/${id}`, data)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  deleteInsightsViewConfig(id) {
    const res = apiService
      .requestInsightsViewConfigDELETE(`/insights/views/${id}`)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  fetchInsightsViewsDefinitions(viewType, dateRange = null) {
    const res = apiService
      .requestInsightsViewDefinitionsGET(
        `/insights/views/quickDefinitions/${viewType}`,
        dateRange
      )
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  fetchInsightsFilterDefinitions(viewType, dateRange = null) {
    const res = apiService
      .requestInsightsFilterDefinitionsGET(
        `/insights/views/filterDefinitions/${viewType}`,
        dateRange
      )
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  fetchInsightsViewsConfigs(viewType) {
    const res = apiService
      .requestInsightsViewConfigGET(`/insights/views/${viewType}`)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }
}
