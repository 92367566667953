import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import LineChart from "../../../components/line-chart";

import styles from "./skus-price-line-chart.scss";

import utils from "../../../utils";
import { abbreviateNumber } from "../../../utils/other/utilities";

const {
  createDataForLineChart,
  sortDataLineChart,
  transfromDataLineChart,
} = utils.other;
const dataField = "price";

class SkusPriceLineChart extends Component {
  componentDidMount() {
    const {
      dataClient,
      dataMatched,
      nameClient,
      nameMatched,
      priceSelected,
    } = this.props;

    this.props.changePriceChart(
      createDataForLineChart(
        sortDataLineChart(
          transfromDataLineChart(dataClient, dataMatched, dataField),
          priceSelected
        ),
        nameClient,
        nameMatched
      )
    );
  }

  componentWillReceiveProps(nextProps) {
    const { dataClient, dataMatched } = this.props;
    if (
      nextProps.dataClient !== dataClient ||
      nextProps.dataMatched !== dataMatched
    ) {
      this.props.changePriceChart(
        createDataForLineChart(
          sortDataLineChart(
            transfromDataLineChart(
              nextProps.dataClient,
              nextProps.dataMatched,
              dataField
            ),
            nextProps.priceSelected
          ),
          nextProps.nameClient,
          nextProps.nameMatched
        )
      );
    }
  }

  sortPriceChart = (name) => {
    const { dataClient, dataMatched, nameClient, nameMatched } = this.props;

    this.props.sortPriceChart(
      name,
      createDataForLineChart(
        sortDataLineChart(
          transfromDataLineChart(dataClient, dataMatched, dataField),
          name
        ),
        nameClient,
        nameMatched
      )
    );
  };

  tooltipCallback = (tooltipItem) => {
    const tooltipData = [];
    tooltipData.push(`price:    ${abbreviateNumber(tooltipItem.value, 2, 2)}`);
    return tooltipData;
  };

  componentWillUnmount() {
    this.props.sortPriceChart("Last 30 days", { labels: [], datasets: [] });
  }

  render() {
    const {
      priceChartData,
      priceSelected,
      nameClient,
      nameMatched,
    } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.top}>
          <div className={styles.legents}>
            <div className={styles.legentItem}>
              <div className={`${styles.legendLabel} ${styles.pink}`}></div>
              {nameClient}
            </div>
            {nameMatched && (
              <div className={styles.legentItem}>
                <div className={`${styles.legendLabel} ${styles.gray}`}></div>
                {nameMatched}
              </div>
            )}
          </div>
        </div>
        <LineChart
          data={priceChartData}
          priceSelected={priceSelected}
          handleChange={this.sortPriceChart}
          title=""
          tooltipCallback={this.tooltipCallback}
        />
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SkusPriceLineChart);
