import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import animationData from "./insightsloader.json";

import styles from "./styles.scss";

const COPY_LINES = [
  "Gathering information...",
  "Analyzing data...",
  "Crunching the numbers...",
  "Searching for relevant insights...",
  "Processing insights...",
  "Formulating a response...",
];

export default function MarketShareInsightsAiLoader(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [index, setIndex] = useState(0);
  const [copy, setCopy] = useState([]);

  useEffect(() => {
    setCopy(COPY_LINES.slice(0, index));
    if (index < COPY_LINES.length) {
      setTimeout(() => {
        setIndex(index + 1);
      }, 4000);
    }
  }, [index]);

  return (
    <div className={styles.loaderWrapper}>
      <Lottie
        options={defaultOptions}
        height={props.height}
        width={props.width || "100%"}
      />
      <div>Please wait, this might take few seconds...</div>
      <div className={styles.copy}>
        {copy.map((x) => (
          <div key={x}>{x}</div>
        ))}
      </div>
    </div>
  );
}
