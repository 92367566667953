import React from "react";
import { connect } from "react-redux";
import { ParentSpaceProvider, SubspaceProvider } from "react-redux-subspace";
import { bindActionCreators } from "redux";
import InsightsTemplateBackButton from "../insights-template-back-button/insights-template-back-button";

import {
  applyInitialState,
  setSelectedMatchingId,
} from "../../containers/catalog-tracker-sku-details/actions";
import { CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA } from "../../containers/catalog-tracker-sku-details/constants";

import CatalogTrackerService from "../../services/catalogTrackerService";
import CatalogTrackerSkuDetailsBody from "../../containers/catalog-tracker-sku-details/components/catalog-tracker-sku-details-body/catalog-tracker-sku-details-body";

const catalogTrackerService = new CatalogTrackerService();

const FREQUENCY_PRESETS = {
  "Last day": 2,
  "Last 7 days": 1,
  "Last 30 days": 0,
  "Last 2 weeks": 3,
  "Last 4 weeks": 4,
};

const CatalogTrackerSkuDetails = (props) => {
  const {
    selectedDateRange,
    selectedMatchingId,
    lastAvailableDate,
    parentViewTypeData,
    setSelectedMatchingId,
    applyInitialState,
    loadedInitialView,
    parentRedirect = false,
  } = props;

  const forceLoading = !loadedInitialView;

  const dataControlsOptions = {
    viewTypeData: CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA,
    lastAvailableDate,
    dateRange: selectedDateRange,
    maxDate: lastAvailableDate,
    mbFilter: true,
    apiProvider: catalogTrackerService,
    hideDate: false,
    hideFrequency: true,
    hideAlert: true,
    hideRetailer: true,
    hideExport: true,
    addDatesToUrl: true,
    hideCompareDates: true,
    datePickerPresets: Object.keys(FREQUENCY_PRESETS),
    dataAvailableUntilLabel: "Data available until",
    dataUnavailableLabel: "Data is unavailable",
  };

  const body = (
    <ParentSpaceProvider>
      <CatalogTrackerSkuDetailsBody forceLoading={forceLoading} />
    </ParentSpaceProvider>
  );

  return (
    <SubspaceProvider
      mapState={(state) => state.catalogTrackerSkuDetails}
      namespace="catalogTrackerSkuDetails"
    >
      <InsightsTemplateBackButton
        dataControlsOptions={dataControlsOptions}
        viewTypeData={CATALOG_TRACKER_SKU_DETAILS_VIEW_TYPE_DATA}
        parentViewTypeData={parentViewTypeData}
        parentRedirect={parentRedirect}
        pageTitle={"SKU Details"}
        apiProvider={catalogTrackerService}
        body={body}
        hideInsightsFilter={true}
        selectedSku={selectedMatchingId}
        applyInitialState={applyInitialState}
        setSelectedSku={setSelectedMatchingId}
      />
    </SubspaceProvider>
  );
};

export const mapStateToProps = (state) => {
  const {
    selectedDateRange,
    selectedPreviousDateRange,
    selectedCompetitor,
    comparePeriod,
  } = state.catalogTrackerSkuDetails.insights.mbFilterRow;
  return {
    selectedDateRange,
    selectedPreviousDateRange,
    selectedCompetitor,
    comparePeriod,
    selectedMatchingId:
      state.catalogTrackerSkuDetails.catalogTrackerSkuDetails
        .selectedMatchingId,
    loadedInitialView:
      state.catalogTrackerSkuDetails.insights.insights.loadedInitialView,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      applyInitialState,
      setSelectedMatchingId,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CatalogTrackerSkuDetails);
