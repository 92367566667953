import {
  APPLY_INITIAL_STATE,
  FETCH_RANKING_EVENTS_TABLE_DATA_CANCEL,
  FETCH_RANKING_EVENTS_TABLE_DATA_FAILURE,
  FETCH_RANKING_EVENTS_TABLE_DATA_REQUEST,
  FETCH_RANKING_EVENTS_TABLE_DATA_SUCCESS,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_REQUEST,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_SUCCESS,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_FAILURE,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_CANCEL,
  CHANGE_RANKING_EVENTS_TABLE_SORTING,
  CHANGE_RANKING_EVENTS_TABLE_OFFSET,
  CHANGE_VIEW_SETTINGS,
  SET_CURRENT_VIEW_IS_UPDATED,
} from "./constants";

import RankingEventsService from "../../services/rankingEventsService";

const rankingEventsService = new RankingEventsService();

export const applyInitialState = () => ({
  type: APPLY_INITIAL_STATE,
});

/*  Ranking Events Data */
export const RankingEventsTableDataRequested = () => ({
  type: FETCH_RANKING_EVENTS_TABLE_DATA_REQUEST,
});

export const RankingEventsTableDataSuccess = (data) => ({
  type: FETCH_RANKING_EVENTS_TABLE_DATA_SUCCESS,
  payload: { data },
});

export const RankingEventsTableDataError = (error) => ({
  type: FETCH_RANKING_EVENTS_TABLE_DATA_FAILURE,
  payload: { error },
});

export const RankingEventsTableDataCancel = () => ({
  type: FETCH_RANKING_EVENTS_TABLE_DATA_CANCEL,
});

export const changeRankingEventsSettings = (value) => ({
  type: CHANGE_VIEW_SETTINGS,
  payload: value,
});

export const changeRankingEventsTableSorting = (data) => ({
  type: CHANGE_RANKING_EVENTS_TABLE_SORTING,
  payload: { data },
});

export const changeRankingEventsTableOffset = (value) => ({
  type: CHANGE_RANKING_EVENTS_TABLE_OFFSET,
  payload: { value },
});

export const setCurrentViewIsUpdated = () => ({
  type: SET_CURRENT_VIEW_IS_UPDATED,
});

export const fetchRankingEventsTableData = (requestParams) => (dispatch) => {
  dispatch(RankingEventsTableDataRequested());
  dispatch(RankingEventsUpdateOffset(0));

  rankingEventsService
    .fetchTableData(requestParams)
    .then((res) => {
      dispatch(RankingEventsTableDataSuccess(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(RankingEventsTableDataCancel());
      } else {
        dispatch(RankingEventsTableDataError(err.message));
      }
    });
};

// More table data
export const RankingEventsMoreDataRequested = () => ({
  type: FETCH_MORE_RANKING_EVENTS_TABLE_DATA_REQUEST,
});

export const RankingEventsUpdateOffset = (value) => ({
  type: CHANGE_RANKING_EVENTS_TABLE_OFFSET,
  payload: value,
});

export const RankingEventsMoreDataSucceeded = (data = []) => ({
  type: FETCH_MORE_RANKING_EVENTS_TABLE_DATA_SUCCESS,
  payload: { data },
});

export const RankingEventsMoreDataError = (error) => ({
  type: FETCH_MORE_RANKING_EVENTS_TABLE_DATA_FAILURE,
  payload: error,
});

export const RankingEventsMoreDataCancel = () => ({
  type: FETCH_MORE_RANKING_EVENTS_TABLE_DATA_CANCEL,
});

export const fetchMoreRankingEventsTableData = (requestParams) => (
  dispatch
) => {
  const newOffset =
    (requestParams.insightViews.offset ?? 0) + requestParams.insightViews.limit;
  const updatedRequestParams = {
    ...requestParams,
    insightViews: {
      ...requestParams.insightViews,
      offset: newOffset,
    },
  };
  dispatch(RankingEventsMoreDataRequested());
  dispatch(RankingEventsUpdateOffset(newOffset));

  rankingEventsService
    .fetchTableData(updatedRequestParams)
    .then((res) => {
      dispatch(RankingEventsMoreDataSucceeded(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(RankingEventsMoreDataCancel());
      } else {
        dispatch(RankingEventsMoreDataError(err.message));
      }
    });
};
