import React, { useEffect, useState } from "react";
import { useMemo } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./market-share-chart-component/market-share-chart.scss";
import { INDEX_COLORS, MARKET_SHARE_CHARTS } from "../../constants";

import MarketShareChartComponent from "./market-share-chart-component/market-share-chart-component";
import { analysisConfig, kpisConfig } from "./market-share-tabs-config";
import { displayNameAccessor } from "../../utils";
import MarketShareBarChartLoader from "./market-share-chart-component/loaders/market-share-bar-chart-loader";
import MarketShareLineChartLoader from "./market-share-chart-component/loaders/market-share-line-chart-loader";
import { isEmpty } from "lodash";
import SharedEventsProvider from "../../../../components/charts/visx/shared-events-provider";
import EntityDropdown from "./entity-dropdown/entity-dropdown";
import { encodeViewConfigQuery } from "../../../../utils/skus/generateQueryUrl";

function MarketShareCharts(props) {
  const {
    selectedDateRange,
    currentViewConfigQuery,
    settings,
    selectedKeys = [],
    selectedPreviousDateRange,
    comparePeriod,
    marketShareSortedData = [],
    marketShareLineChartData,
    noSegmentsChosen,
    columnsConfig,
    hoveredKey,
  } = props;
  const [highlightKey, setHighlightKey] = useState(null);
  const [relevantConfigQuery, setRelevantConfigQuery] = useState();
  const [dropdownProps, setDropdownProps] = useState({});

  useEffect(() => {
    setHighlightKey(hoveredKey);
  }, [hoveredKey]);

  const requestedParams = {
    headersData: {
      ...selectedDateRange,
      groupBy: settings.dimension,
    },
    insightViews: {
      ...currentViewConfigQuery,
      previousStartDate: selectedPreviousDateRange.previousStartDate,
      previousEndDate: selectedPreviousDateRange.previousEndDate,
      comparePeriod: comparePeriod,
    },
  };

  useEffect(() => {
    const relevantRequestedParams = generateRelevantConfigQuery(
      requestedParams
    );
    if (
      requestedParams.headersData.groupBy !== undefined &&
      // This actually compares object, as opposed to JSON.stringify which keys could be differently sorted
      // (remove this comment after this practice becomes consistent)
      !_.isEqual(relevantRequestedParams, relevantConfigQuery)
    ) {
      setRelevantConfigQuery(relevantRequestedParams);
    }
  }, [requestedParams, comparePeriod]);

  const generateRelevantConfigQuery = (config) => {
    return {
      ...config.headersData,
      insightViews: {
        ...config.insightViews.productAttributes,
        ...config.insightViews.settings,
        previousStartDate: config.insightViews.previousStartDate,
        previousEndDate: config.insightViews.previousEndDate,
        comparePeriod: config.insightViews.comparePeriod,
      },
    };
  };

  const filterAnalysisConfig = (analysisConfig, settings) => {
    var selectedDomain = currentViewConfigQuery?.productAttributes?.find(
      (x) => x.displayName === "Retailer" || x.mbName === "rootdomain.keyword"
    )?.values[0];
    if (settings.dimension) {
      analysisConfig = analysisConfig.filter((x) => {
        if (x.unsupportedDimensions != null) {
          return !x.unsupportedDimensions.includes(settings.dimension);
        } else if (x.supportedDimensions != null) {
          return x.supportedDimensions.includes(settings.dimension);
        } else {
          return true;
        }
      });
    }
    if (selectedDomain) {
      analysisConfig = analysisConfig.filter((x) => {
        if (x.supportedDomains != null) {
          return x.supportedDomains.includes(selectedDomain);
        } else {
          return true;
        }
      });
    }
    return analysisConfig;
  };

  const entities = useMemo(() => {
    if (selectedKeys && marketShareSortedData) {
      const keys = {};
      for (const i of selectedKeys) {
        if (marketShareSortedData[i])
          keys[marketShareSortedData[i]?.name] = {
            stroke: INDEX_COLORS[i],
            displayName: displayNameAccessor(
              marketShareSortedData[i],
              settings.dimension
            ),
            name: marketShareSortedData[i]?.skuData?.name,
            image: marketShareSortedData[i]?.skuData?.image,
          };
      }
      return keys;
    }
    return {};
  }, [marketShareSortedData, selectedKeys]);

  const filteredAnalysisConfig = filterAnalysisConfig(analysisConfig, settings);

  const isLoading =
    isEmpty(marketShareSortedData) || isEmpty(marketShareLineChartData);

  const chartsConfigs = {
    kpisChart: kpisConfig,
    analysisChart: filteredAnalysisConfig,
  };

  // If the charts are not of the same type they shouldn't share an EventEmitter context
  const areChartsSameType = Object.entries(MARKET_SHARE_CHARTS).every(
    ([k, v], i, arr) =>
      settings?.[v]?.selectedType == settings?.[arr[0][1]]?.selectedType
  );

  const marketShareChart = (chartName, chartConfig, highlightKey) => (
    <MarketShareChartComponent
      id={chartName}
      config={chartConfig}
      selectedDateRange={selectedDateRange}
      selectedPreviousDateRange={selectedPreviousDateRange}
      entities={entities}
      marketShareLineChartData={marketShareLineChartData}
      marketShareBarChartData={marketShareSortedData}
      columnsConfig={columnsConfig}
      loaderComponent={<MarketShareLineChartLoader />}
      highlightKey={highlightKey}
    />
  );

  const handleClickEvent = (e) => {
    setDropdownProps(e.value);
  };

  return (
    <>
      {!isLoading && selectedKeys.length === 0 ? (
        <div className={styles.noKeysDisclaimer}>{noSegmentsChosen}</div>
      ) : (
        <>
          <EntityDropdown
            {...dropdownProps}
            entities={entities}
            marketShareAnalysisRef={props.marketShareAnalysisRef}
          />
          <SharedEventsProvider
            areChartsSameType={areChartsSameType}
            chartsConfigs={chartsConfigs}
            highlightKey={highlightKey}
            onClickEvent={handleClickEvent}
          >
            {(chartName, chartConfig, highlightKey) =>
              marketShareChart(chartName, chartConfig, highlightKey)
            }
          </SharedEventsProvider>
        </>
      )}
    </>
  );
}

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.marketShare.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.marketShare.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.marketShare.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.marketShare.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.marketShare.insights.insights.currentViewConfig.query,
    settings:
      state.marketShare.insights.insights.currentViewConfig.query.settings,
    selectedKeys:
      state.marketShare.insights.insights.currentViewConfig.query.settings
        .selectedKeys,
    marketShareSortedData: state.marketShare.marketShare.marketShareSortedData,
    marketShareLineChartData:
      state.marketShare.marketShare.marketShareLineChartData,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MarketShareCharts);
