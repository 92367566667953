/** !!!!ToDo The File should renamed to simple-table */
import React from "react";

import { useTable } from "react-table";
import defaultStyles from "../tables.scss";
import { SimpleTableCellLoader } from "./cell-loader";

export const LoaderWidths = {
  Small: 28,
  Medium: 60,
  Big: 100,
  Large: 300,
  Huge: 400,
};

function SimpleTable({
  columns,
  data,
  sorting,
  styles,
  onSelectRow,
  onHoverRow,
  isLoading = false,
  isSticky = false,
  amountOfLoadingRows = 10,
}) {
  data =
    isLoading && data.length === 0
      ? Array.from(Array(amountOfLoadingRows), () => ({}))
      : data;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <>
      <table
        className={`${styles.simpleTable} ${defaultStyles.simpleTable} `}
        {...getTableProps()}
      >
        <thead className={`${isSticky ? defaultStyles.stickyHeader : ""}`}>
          {headerGroups.map((headerGroup) => (
            <tr
              className={`${styles.tr} ${defaultStyles.tr}`}
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map(
                (column) =>
                  // Add the sorting props to control sorting. For this example
                  // we can add them into the header props
                  column.parent && (
                    <th
                      className={`${styles.th} ${defaultStyles.th}`}
                      {...column.getHeaderProps()}
                    >
                      <div
                        className={`${styles.cellWrapper} ${defaultStyles.cellWrapper}`}
                      >
                        {column.Header && column.render("Header")}
                        <div
                          onClick={
                            column.sortMethod && column.sortMethod.bind()
                          }
                        >
                          {/* Add a sort direction indicator */}
                          {!isLoading &&
                            column.sortMethod &&
                            (sorting.sortingColumn?.accessor === column.id ? (
                              sorting.selectedSortingDirection ? (
                                <div
                                  id={"tableSort"}
                                  className={`${styles.sortUp} ${defaultStyles.sortUp}`}
                                ></div>
                              ) : (
                                <div
                                  id={"tableSort"}
                                  className={`${styles.sortDown} ${defaultStyles.sortDown}`}
                                ></div>
                              )
                            ) : (
                              <div
                                id={"tableSort"}
                                className={`${styles.sortUpDown} ${defaultStyles.sortUpDown}`}
                              ></div>
                            ))}
                        </div>
                      </div>
                    </th>
                  )
              )}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr
                  onClick={(e) =>
                    onSelectRow && onSelectRow(parseInt(row.id), e)
                  }
                  onMouseEnter={() =>
                    onHoverRow && onHoverRow(parseInt(row.id))
                  }
                  className={`${styles.tr} ${defaultStyles.tr}`}
                  style={row.original.rowStyle ?? {}}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        colSpan={`${cell.column.colspan || 0}`}
                        className={`${styles.td} ${defaultStyles.td} $`}
                        {...cell.getCellProps()}
                      >
                        {isLoading && cell.column.loaderWidth ? (
                          <SimpleTableCellLoader
                            width={cell.column.loaderWidth}
                          />
                        ) : (
                          cell.render("Cell")
                        )}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </>
  );
}

export default SimpleTable;
