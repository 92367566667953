import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import styles from "./login.scss";
import utils from "../../../utils";
import {
  changeField,
  trySubmitted,
  changeFieldErrorStatus,
  sendForm,
  thirdPartyAuthenticate,
  supportPortalAuth,
} from "./actions";

import logo from "../../../images/bi-logo-big.png";
import visibilityOn from "../../../images/eye-visibility-on.svg";
import visibilityOff from "../../../images/eye-visibility-off.svg";
import Spinner from "../../../components/spinner";
import { AuthType } from "../constants";

const { getToken } = utils.auth;

class Login extends Component {
  state = {
    isVisiblePassword: false,
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.trySubmitted();

    const { user, password } = this.props;

    if (this.validateForm()) {
      this.props.sendForm({
        user,
        password,
      });
    }
  };

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.props.changeField(name, value);
    this.validateField(name, value);
  };

  thirdPartyAuthenticate = (authType, token, id) => {
    this.props.thirdPartyAuthenticate({ authType, token, id });
  };

  validateField(fieldName, value) {
    let status;
    // let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let regPass = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

    switch (fieldName) {
      case "user":
        status = value.length >= 2;
        // status = value.length >= 2 && regEmail.test(value);
        break;
      case "password":
        status = value.length >= 8 && regPass.test(value);
        break;
      default:
        break;
    }

    this.props.changeFieldErrorStatus(fieldName, status);
  }

  validateForm() {
    return this.props.validFields.user && this.props.validFields.password;
  }

  componentDidMount() {
    if (getToken()) {
      supportPortalAuth();
      this.props.history.push("/");
    }
  }

  toggleVisibility = () => {
    this.setState((prevState) => ({
      isVisiblePassword: !prevState.isVisiblePassword,
    }));
  };

  render() {
    const {
      user,
      password,
      isTrySubmit,
      validFields,
      error,
      loading,
    } = this.props;
    const { isVisiblePassword } = this.state;

    const serverError = error ? (
      <div id="login_err_msg" className={styles.errorBlock}>
        {error}
      </div>
    ) : null;
    const loadingBtn = loading ? "" : null;

    const params = new URLSearchParams(window.location.search);
    const authType = params.get("authType");
    const token = params.get("token");
    const id = params.get("id");

    const submitForm = (
      <div>
        <h3>Sign in to your account</h3>
        <form onSubmit={this.handleSubmit}>
          {loading ? (
            <div className={styles.loader}>
              <Spinner height={100} />
            </div>
          ) : null}
          {serverError}
          <div
            className={`${styles.formGroup} ${
              !validFields.user && isTrySubmit ? styles.error : ""
            }`}
          >
            <input
              type="text"
              className="form-control"
              name="user"
              placeholder="E-Mail"
              value={user}
              onChange={this.handleInput}
            />
            <p className={styles.errorMsg}>Invalid Email Address</p>
          </div>
          <div
            className={`${styles.formGroup} ${
              !validFields.password && isTrySubmit ? styles.error : ""
            }`}
          >
            <div className={styles.eyeCont} onClick={this.toggleVisibility}>
              {isVisiblePassword ? (
                <img src={visibilityOn} />
              ) : (
                <img src={visibilityOff} />
              )}
            </div>
            <input
              type={isVisiblePassword ? "text" : "password"}
              className="form-control"
              name="password"
              placeholder="Password"
              value={password}
              onChange={this.handleInput}
            />
            <p className={styles.errorMsg}>
              Min length 8 characters and must to be special characters(&!#..)
            </p>
          </div>
          <div className={styles.linkCont}>
            <Link to="/forgot-password">Forgot Password?</Link>
          </div>
          <button id="login_btn" type="submit" disabled={loading}>
            Login
          </button>
        </form>
      </div>
    );

    const authContainer = (
      <div>
        {serverError != null ? (
          serverError
        ) : (
          <div>
            <h3>Redirecting...</h3>
            <Spinner />
          </div>
        )}
      </div>
    );

    const isThirdPartyAuth = authType != null && authType != AuthType.DEFAULT;
    if (!getToken() && isThirdPartyAuth) {
      this.thirdPartyAuthenticate(authType, token, id);
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.formCont}>
          <div className={styles.logoCont}>
            <img src={logo} alt="logo" />
          </div>
          <h1>
            Welcome to <br /> Bright Insights
          </h1>
          {isThirdPartyAuth ? authContainer : submitForm}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.auth.login.data.user,
    password: state.auth.login.data.password,
    isTrySubmit: state.auth.login.isTrySubmit,
    validFields: state.auth.login.validFields,
    error: state.auth.login.error,
    loading: state.auth.login.loading,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeField,
      trySubmitted,
      changeFieldErrorStatus,
      sendForm,
      thirdPartyAuthenticate,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
