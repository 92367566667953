import React from "react";
import styles from "./pie-chart-component.scss";
import ContentLoader from "react-content-loader";

const PieChartComponentLoader = ({ size, speed = 2 }) => {
  const width = size * 1.5;
  const height = size;
  const radiusRatio = 1.5;
  const outerRadius = size / radiusRatio / 2;

  return (
    <div className={styles.pieChart}>
      <div>
        <ContentLoader speed={speed} width={width} height={height}>
          <circle cx={width / 2} cy={height / 2} r={outerRadius} />
        </ContentLoader>
        <svg className="pie-chart" height={height} width={width}></svg>
      </div>
    </div>
  );
};

export default PieChartComponentLoader;
