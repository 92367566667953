import {
  ApiService,
  cancelUsersTableData,
  cancelAllCategories,
  cancelAllCompetitors,
  cancelAllScreens,
  cancelAddNewUser,
  cancelDeleteUser,
  cancelUpdateUser,
} from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();
export default class AdministrationService extends BasicViewService {
  addNewUser(userParams) {
    if (cancelAddNewUser !== undefined) {
      cancelAddNewUser("cancel");
    }
    const res = apiService
      .requestAddNewUserPOST(`/user/create`, userParams)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  updateUser(userParams) {
    if (cancelUpdateUser !== undefined) {
      cancelUpdateUser("cancel");
    }
    const res = apiService
      .requestUpdateUserPOST("/user/update", userParams)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  deleteUser(userParams) {
    if (cancelDeleteUser !== undefined) {
      cancelDeleteUser("cancel");
    }
    const res = apiService
      .requestDeleteUserPOST("/user/delete", userParams)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  fetchTableData() {
    if (cancelUsersTableData !== undefined) {
      cancelUsersTableData("cancel");
    }
    const res = apiService
      .requestUsersTableDataGET("/administration/users")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  fetchAllScreens() {
    if (cancelAllScreens !== undefined) {
      cancelAllScreens("cancel");
    }
    const res = apiService
      .requestAllScreensGET("/administration/screens")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  fetchAllCategories() {
    if (cancelAllCategories !== undefined) {
      cancelAllCategories("cancel");
    }
    const res = apiService
      .requestAllCategoriesGET("/administration/categories")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  fetchAllCompetitors() {
    if (cancelAllCompetitors !== undefined) {
      cancelAllCompetitors("cancel");
    }
    const res = apiService
      .requestAllCompetitorsGET("/administration/competitors")
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }
}
