import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InsightsCompetitorsItem from "./components/insights-competitors-item";
import { changeVisibleCompetitors } from "./actions";

import styles from "./insights-competitors.scss";

class InsightsCompetitors extends Component {
  getCurrentCompetitors = (
    activeCategory,
    activeCategoryLevel1,
    competitors,
    competitorsLevel1,
    competitorsLevel2
  ) => {
    if (activeCategoryLevel1) {
      return competitorsLevel2 ? competitorsLevel2 : [];
    }

    if (activeCategory) {
      return competitorsLevel1 ? competitorsLevel1 : [];
    }

    return competitors ? competitors : [];
  };

  sortCompetitors = (items = []) => {
    if (items.length === 0) return [];

    return items.sort((a, b) => b.outflow - a.outflow);
  };

  getSum = (item = null) => {
    let result = 0;

    if (item) {
      item.map((item) => {
        if (item.outflow) {
          result += item.outflow;
        }
      });
    }

    return result;
  };

  searchCurrentSum = (name, category = []) => {
    let result = category.find((item) => name === item.competitorCategoryName);
    return result ? result.outflow : null;
  };

  getCurrentSum = () => {
    const {
      activeCategory,
      activeCategoryLevel1,
      byCategories,
      byCategoriesLevel1,
    } = this.props;

    if (activeCategoryLevel1) {
      return this.searchCurrentSum(activeCategoryLevel1, byCategoriesLevel1);
    }

    if (activeCategory) {
      return this.searchCurrentSum(activeCategory, byCategories);
    }

    return this.getSum(byCategories);
  };

  componentWillUnmount() {
    this.props.changeVisibleCompetitors(false);
  }

  render() {
    const {
      activeCategory,
      activeCategoryLevel1,
      byCompetitors,
      byCompetitorsLevel1,
      byCompetitorsLevel2,
      isVisibleTooltip,
      isVisibleCompetitors,
      competitors,
      loading,
    } = this.props;

    const competitorsList = this.sortCompetitors(
      this.getCurrentCompetitors(
        activeCategory,
        activeCategoryLevel1,
        byCompetitors,
        byCompetitorsLevel1,
        byCompetitorsLevel2
      )
    );

    const sum = this.getCurrentSum();

    // Competitors always have at least 1 item ("all_domains"), so if there are 2 items, no need to display the revenue leakage domains breakdown
    if (loading || competitorsList.length <= 2) {
      return null;
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.toggleBox}>
          <div
            id="show_hide_cmpt_btn"
            className={styles.toggleLabel}
            onClick={() => {
              this.props.changeVisibleCompetitors(!isVisibleCompetitors);
            }}
          >
            {isVisibleCompetitors ? "- Hide Competitors" : "+ Show Competitors"}
          </div>
          {isVisibleTooltip && (
            <div id="cmpt_tooltip" className={styles.toggleTooltip}>
              Press “+ Show Competitors” to view competition breakout
              <span
                id="gotit_btn"
                onClick={() => {
                  this.props.changeVisibleCompetitors(!isVisibleCompetitors);
                }}
              >
                Got it
              </span>
            </div>
          )}
        </div>
        {isVisibleCompetitors && (
          <div id="cmpt_box" className={styles.box}>
            <table>
              <tbody>
                {isVisibleCompetitors &&
                  competitorsList.map((item, i) => {
                    if (item.competitorName != "all_domains")
                      return (
                        <InsightsCompetitorsItem
                          key={i}
                          data={item}
                          competitors={competitors}
                          sum={sum}
                        />
                      );
                  })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    activeCategory: state.insights.insights.activeCategory,
    activeCategoryLevel1:
      state.insights.insights.topCategoryLevel1.activeCategory,

    byCompetitors: !state.insights.insights.subNodesInfo
      ? []
      : Object.keys(state.insights.insights.subNodesInfo).map((competitor) => {
          return {
            competitorRootDomain: competitor,
            outflow: 0,
            competitorName: competitor,
          };
        }),

    byCompetitorsLevel1:
      state.insights.insights.activeCategory == ""
        ? []
        : Object.keys(state.insights.insights.subNodesInfo)
            .filter((competitor) => {
              return state.insights.insights.subNodesInfo[competitor]
                .subNodesInfo[state.insights.insights.activeCategory];
            })
            .map((competitor) => {
              return {
                competitorRootDomain: competitor,
                outflow: 0,
                competitorName: competitor,
              };
            }),

    byCompetitorsLevel2:
      state.insights.insights.topCategoryLevel1.activeCategory == ""
        ? []
        : Object.keys(state.insights.insights.subNodesInfo)
            .filter((competitor) => {
              return (
                state.insights.insights.subNodesInfo[competitor].subNodesInfo[
                  state.insights.insights.activeCategory
                ] &&
                state.insights.insights.subNodesInfo[competitor].subNodesInfo[
                  state.insights.insights.activeCategory
                ].subNodesInfo[
                  state.insights.insights.topCategoryLevel1.activeCategory
                ]
              );
            })
            .map((competitor) => {
              return {
                competitorRootDomain: competitor,
                outflow: 0,
                competitorName: competitor,
              };
            }),

    byCategories: !state.insights.insights.subNodesInfo
      ? []
      : Object.keys(
          state.insights.insights.subNodesInfo["all_domains"].subNodesInfo
        ).map((lvl1) => {
          return {
            competitorCategoryName: lvl1,
            outflow: 0,
            trend: 2,
          };
        }),

    byCategoriesLevel1: !state.insights.insights.topCategoryLevel1.subNodesInfo
      ? []
      : Object.keys(state.insights.insights.topCategoryLevel1.subNodesInfo).map(
          (lvl2) => {
            return {
              competitorCategoryName: lvl2,
              outflow: 0,
              trend: 2,
            };
          }
        ),

    byCategoriesLevel2: !state.insights.insights.topCategoryLevel2.subNodesInfo
      ? []
      : Object.keys(state.insights.insights.topCategoryLevel2.subNodesInfo).map(
          (lvl2) => {
            return {
              competitorCategoryName: lvl2,
              outflow: 0,
              trend: 2,
            };
          }
        ),
    isVisibleTooltip:
      state.insights.insights.competitorsConfig.isVisibleTooltip,
    isVisibleCompetitors:
      state.insights.insights.competitorsConfig.isVisibleCompetitors,
    competitors: state.competitors.data,
    loading: state.insights.insights.loading,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeVisibleCompetitors,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsCompetitors);
