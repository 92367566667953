import React, { useEffect, useState } from "react";
import Pagination from "../../pagination";
import styles from "./paginated-table.scss";

const PaginatedTable = ({ pageRef, tableData, maxRowsPerPage, children }) => {
  const [page, setPage] = useState(pageRef ? pageRef.current : 1);
  const pageCount = Math.ceil(tableData.length / maxRowsPerPage);

  useEffect(() => {
    if (pageRef) pageRef.current = page;
  }, [page]);

  return (
    <>
      {children(
        tableData.slice(
          (page - 1) * maxRowsPerPage,
          (page - 1) * maxRowsPerPage + maxRowsPerPage
        )
      )}
      <div className={styles.tablePagination}>
        <Pagination page={page} setPage={setPage} limit={pageCount} />
      </div>
    </>
  );
};

export default PaginatedTable;
