import {
  APPLY_INITIAL_STATE,
  FETCH_USERS_TABLE_DATA_REQUEST,
  FETCH_USERS_TABLE_DATA_SUCCESS,
  FETCH_ALL_POSSIBLE_CATEGORIES_SUCCESS,
  FETCH_ALL_POSSIBLE_SCREENS_SUCCESS,
  FETCH_ALL_POSSIBLE_COMPETITORS_SUCCESS,
  FETCH_ALL_POSSIBLE_CATEGORIES_REQUEST,
  FETCH_ALL_POSSIBLE_SCREENS_REQUEST,
  FETCH_ALL_POSSIBLE_COMPETITORS_REQUEST,
  CHANGE_CATEGORIES_SELECTED_KEYS,
  CHANGE_SCREENS_SELECTED_KEYS,
  CHANGE_COMPETITORS_SELECTED_KEYS,
  CLOSE_SHOW_POPUP_STATUS,
  SHOW_POPUP,
  TOGGLE_UPDATE_USER,
  SET_SELECTED_USER_DATA,
  UPDATE_USER_REQUEST,
  ADD_NEW_USER_REQUEST,
  DELETE_USER_REQUEST,
  CRUD_OPERATION_FAILURE,
  ADD_NEW_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
} from "./constants";
const initialState = {
  usersTableData: [],
  competitors: [],
  categories: [],
  screens: [],
  selectedScreens: [],
  selectedCategories: [],
  selectedCompetitors: [],
  selectedUser: {
    firstName: "",
    lastName: "",
    email: "",
    domain: "",
  },
  updateUserFlag: true,
  showPopup: false,
  tableStatus: {
    loading: false,
  },
  httpError: {
    message: "",
    active: false,
  },
};

const administrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }
    case CLOSE_SHOW_POPUP_STATUS: {
      return {
        ...state,
        showPopup: false,
        selectedCategories: [],
        selectedCompetitors: [],
        selectedScreens: [],
        selectedUser: {
          ...state.selectedUser,
          firstName: "",
          lastName: "",
          email: "",
          domain: "",
        },
        httpError: {
          ...state.httpError,
          message: "",
          active: false,
        },
      };
    }
    case SHOW_POPUP: {
      return {
        ...state,
        showPopup: true,
      };
    }
    case TOGGLE_UPDATE_USER: {
      const { data } = action.payload;
      return {
        ...state,
        updateUserFlag: data,
      };
    }
    case SET_SELECTED_USER_DATA: {
      const { data } = action.payload;
      return {
        ...state,
        selectedCompetitors: data.selectedCompetitors,
        selectedScreens: data.selectedScreens,
        selectedCategories: data.selectedCategories,
        selectedUser: {
          ...state.selectedUser,
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          domain: data.domain,
        },
      };
    }
    case UPDATE_USER_REQUEST: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loading: true,
        },
      };
    }
    case ADD_NEW_USER_REQUEST: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loading: true,
        },
      };
    }
    case CRUD_OPERATION_FAILURE: {
      return {
        ...state,
        httpError: {
          ...state.httpError,
          message: action.payload.error,
          active: true,
        },
        tableStatus: {
          ...state.tableStatus,
          loading: false,
        },
      };
    }
    case ADD_NEW_USER_SUCCESS: {
      return {
        ...state,
        httpError: {
          ...state.httpError,
          message: "",
          active: false,
        },
      };
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        httpError: {
          ...state.httpError,
          message: "",
          active: false,
        },
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        httpError: {
          ...state.httpError,
          message: "",
          active: false,
        },
      };
    }
    case DELETE_USER_REQUEST: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loading: true,
        },
      };
    }
    case FETCH_USERS_TABLE_DATA_REQUEST: {
      return {
        ...state,
        usersTableData: [],
        tableStatus: {
          ...state.tableStatus,
          loading: true,
        },
      };
    }
    case FETCH_USERS_TABLE_DATA_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        usersTableData: data,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
        },
      };
    }
    case FETCH_ALL_POSSIBLE_CATEGORIES_REQUEST: {
      return {
        ...state,
        categories: [],
      };
    }
    case FETCH_ALL_POSSIBLE_SCREENS_REQUEST: {
      return {
        ...state,
        screens: [],
      };
    }
    case FETCH_ALL_POSSIBLE_COMPETITORS_REQUEST: {
      return {
        ...state,
        competitors: [],
      };
    }
    case FETCH_ALL_POSSIBLE_CATEGORIES_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        categories: data,
      };
    }
    case FETCH_ALL_POSSIBLE_SCREENS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        screens: data,
      };
    }
    case FETCH_ALL_POSSIBLE_COMPETITORS_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        competitors: data,
      };
    }
    case CHANGE_CATEGORIES_SELECTED_KEYS: {
      const { values } = action.payload;
      return {
        ...state,
        selectedCategories: values,
      };
    }
    case CHANGE_SCREENS_SELECTED_KEYS: {
      const { data } = action.payload;
      return {
        ...state,
        selectedScreens: data,
      };
    }
    case CHANGE_COMPETITORS_SELECTED_KEYS: {
      const { data } = action.payload;
      return {
        ...state,
        selectedCompetitors: data,
      };
    }
  }
  return state;
};

export default administrationReducer;
