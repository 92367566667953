import React, { useState } from "react";
import { EventEmitterProvider, useEventEmitter } from "@visx/xychart";
import {
  CHART_CLICK_EVENT,
  CHART_NEAREST_KEY_CHANGE_EVENT,
} from "../../../visx_components/constants";

const LineHighlighter = ({ onClickEvent, children }) => {
  const [highlightKey, setHighlightKey] = useState();

  const ChartEventListener = () => {
    useEventEmitter(CHART_NEAREST_KEY_CHANGE_EVENT, ({ event }) => {
      setHighlightKey(event.value);
    });

    useEventEmitter(CHART_CLICK_EVENT, ({ event }) => {
      onClickEvent && onClickEvent(event);
    });

    return <></>;
  };

  return (
    <EventEmitterProvider>
      <ChartEventListener />
      <span onMouseLeave={() => setHighlightKey(null)}>
        {children(highlightKey)}
      </span>
    </EventEmitterProvider>
  );
};

export default LineHighlighter;
