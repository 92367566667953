import React from "react";
import FrameLoader from "./frame-loader";

const FilterSidebarFilterLoader = (props) => {
  const { speed = 2, height = 60 } = props;
  return (
    <FrameLoader height={height}>
      <rect x="10" y="10" rx="3" ry="3" width="55" height="10" />
      <rect x="10" y="35" rx="3" ry="3" width="72" height="10" />
      <rect x="110" y="35" rx="3" ry="3" width="191" height="10" />
    </FrameLoader>
  );
};

export default FilterSidebarFilterLoader;
