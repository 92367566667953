import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  ParentSpaceProvider,
  SubspaceProvider,
  useSubspace,
} from "react-redux-subspace";
import InsightsTemplate from "../insights-template/insights-template";
import RankingEventsInfiniteTable from "../../containers/ranking-events/components/ranking-events-infinite-table/ranking-events-infinite-table";
import {
  convertBrowserStringSortFieldToConfig,
  convertConfigSortFieldToBrowserString,
} from "../../utils/skus/convertSortValue";

import InsightsService from "../../services/insightsService";
const insightsService = new InsightsService();
import styles from "../insights-template/insights-template.scss";
import {
  RANKING_EVENTS_VIEW_TYPE_DATA,
  EVENTS_PRESETS,
} from "../../containers/ranking-events/constants";
import EventsQuickSelect from "../../containers/ranking-events/components/events-quick-select/events-quick-select";
import MbBasicFilters from "../../mb_components/mb-basic-filters/mb-basic-filters";
import { updateCurrentViewConfigQuery } from "../../containers/insights/actions";
import { useCallback } from "react";
import TriggersDefault from "../../containers/ranking-events/components/triggers-default/triggers-default";
import { getSelectedEventType } from "../../mb_components/event-wizard/utils";
import BetaTag from "../../components/beta-tag/beta-tag";

const RANKING_EVENTS_DATE_PRESETS = [
  "Rolling last day",
  "Rolling last 7 days",
  "Rolling last week",
  "Rolling last 4 weeks",
  "Rolling last 13 weeks",
  "Rolling year to date",
];

const RankingEvents = (props) => {
  const viewTypeData = RANKING_EVENTS_VIEW_TYPE_DATA;

  const mapState = useCallback((state) => state[viewTypeData.subspace]);
  const subspace = useSubspace(mapState, viewTypeData.subspace);
  const search = props.location.search;
  const lastProcessedDay = props.lastProcessedDay;

  const hasSelectedTrigger = getSelectedEventType(props.productAttributes);

  const table = (
    <ParentSpaceProvider>
      <RankingEventsInfiniteTable viewTypeId={viewTypeData.id} />
    </ParentSpaceProvider>
  );

  const TitleComponent = () => {
    return (
      <div style={{ display: "flex" }}>
        Product Page Notifications <BetaTag size="md" />
      </div>
    );
  };

  const BottomRowLeftComponent = () => {
    return (
      <div style={{ margin: "auto 0px" }}>
        <MbBasicFilters
          filterDefinitions={props.filterDefinitions}
          insightsFiltersCollection={props.productAttributes}
          updateFilters={(attrs) => {
            subspace.dispatch(
              updateCurrentViewConfigQuery({
                productAttributes: attrs,
              })
            );
          }}
          viewTypeId={viewTypeData.id}
        />
      </div>
    );
  };

  const minDate = new Date();
  const maxDate = lastProcessedDay ? new Date(lastProcessedDay) : new Date();

  minDate.setDate(maxDate.getDate() - 31);

  const dataControlsOptions = {
    viewTypeData,
    lastAvailableDate: lastProcessedDay ?? null,
    mbFilter: true,
    apiProvider: insightsService,
    hideExport: true,
    hideDate: false,
    hideFrequency: true,
    hideRetailer: true,
    convertConfigSortFieldToBrowserString: convertConfigSortFieldToBrowserString,
    mbFilterMode: "daily",
    hideCompareDates: true,
    datePickerPresets: RANKING_EVENTS_DATE_PRESETS,
    maxDate: maxDate,
    minDate: minDate,
    datePickerPresets: EVENTS_PRESETS,
    showEventTriggers: true,
    BottomRowLeftComponent: <BottomRowLeftComponent />,
    minimizeInsightsFilterByDefault: true,
    hideViewManager: true,
    TitleComponent: TitleComponent,
  };

  const body = (
    <>
      <EventsQuickSelect viewTypeData={viewTypeData} />
      {hasSelectedTrigger ? (
        <div className={styles.wrapper}>{table}</div>
      ) : (
        <TriggersDefault />
      )}
    </>
  );

  return (
    <SubspaceProvider
      mapState={(state) => state.rankingEvents}
      namespace="rankingEvents"
    >
      <InsightsTemplate
        body={body}
        dataControlsOptions={dataControlsOptions}
        dateRange={props.dateRange}
        search={search}
        viewTypeData={viewTypeData}
        apiProvider={insightsService}
        convertBrowserStringSortFieldToConfig={
          convertBrowserStringSortFieldToConfig
        }
      />
    </SubspaceProvider>
  );
};

export const mapStateToProps = (state) => {
  const competitorsList = state.competitors.data;
  const {
    selectedDateRange,
    selectedCompetitor,
  } = state.rankingEvents.insights.mbFilterRow;
  const lastProcessedDay =
    state.rankingEvents.insights.insights.viewDefinitions?.viewMetadata
      ?.lastProcessedDay;

  return {
    filterDefinitions:
      state.rankingEvents.insights.insights.viewDefinitions?.filterDefinitions,
    productAttributes:
      state.rankingEvents.insights.insights.currentViewConfig?.query
        ?.productAttributes,
    currentViewConfigId:
      state.rankingEvents.insights.insights.currentViewConfig.id,
    competitorsList,
    selectedDateRange,
    selectedCompetitor,
    dateRange: state.rankingEvents.insights.insights.dateRange,
    lastProcessedDay,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ updateCurrentViewConfigQuery }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RankingEvents);
