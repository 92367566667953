import convertSortValue from "./convertSortValue";
import { Base64 } from "js-base64";

const generateQueryUrl = (data = {}, viewTypeId = 0) => {
  let result = "?";
  let i = 0;
  _.map(data, (value, key) => {
    if (key == "sort") {
      if (viewTypeId === 0 || viewTypeId === 1) {
        value = encodeURIComponent(convertSortValue(value));
      }
    }

    if (value || value === 0) {
      result += `${i !== 0 ? "&" : ""}${key}=${value}`;
    }
    i++;
  });

  return result;
};

const generateApiQueryUrlForInsightsSkus = (data = {}) => {
  let result = "?";
  let i = 0;
  _.map(data, (value, key) => {
    if (key === "sortField") {
      result += `${i !== 0 ? "&" : ""} sort=${(value = encodeURIComponent(
        convertSortValue(value)
      ))}`;
    } else if (key === "sortOrder") {
      result += `${i !== 0 ? "&" : ""} sortDescending=${!!value}`;
    } else if (!_.isArray(value) || value === 0) {
      result += `${i !== 0 ? "&" : ""}${key}=${value}`;
    } else if (key === "productAttributes") {
      value.forEach((item) => {
        if (item.type === "Numeric") {
          result += `${i !== 0 ? "&" : ""}${item.mbName}=${
            item.criteria.value1
          }`;
        } else {
          item.values.forEach((value) => {
            result += `${i !== 0 ? "&" : ""}${item.mbName}=${value}`;
          });
        }
      });
    }
    i++;
  });

  return result;
};

const encodeViewConfigQuery = (viewConfigQuery) => {
  const stringified = JSON.stringify(viewConfigQuery);
  return Base64.encodeURI(stringified);
};

const generateQueryStringFromViewConfigQuery = (
  viewConfigQuery,
  startDate = null,
  endDate = null,
  rolling = 0,
  prevStartDate = null,
  prevEndDate = null,
  comparePeriod = null
) => {
  return encodeViewConfigQuery({
    ...viewConfigQuery,
    startDate: startDate,
    endDate: endDate,
    previousStartDate: prevStartDate,
    previousEndDate: prevEndDate,
    comparePeriod,
    rolling,
  });
};

export {
  generateQueryUrl,
  generateApiQueryUrlForInsightsSkus,
  encodeViewConfigQuery,
  generateQueryStringFromViewConfigQuery,
};

export default generateQueryUrl;
