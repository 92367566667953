import { combineReducers } from "redux";
import { namespaced } from "redux-subspace";

import authReducer from "./containers/auth/reducer";

// Shared reducers
import userReducer from "./containers/shared/user/reducer";
import competitorsReducer from "./containers/shared/competitors/reducer";
import categoriesReducer from "./containers/shared/categories/reducer";
import brandsReducer from "./containers/shared/brands/reducer";
import analyticsReducer from "./containers/shared/analytics/reducer";

// Components reducers
import mbFilterRowReducer from "./mb_components/mb-filter-row/reducer";

// Pages reducers
import insightsReducer from "./containers/insights/reducer";
import marketShareReducer from "./containers/market-share/reducer";
import marketShareAnalysisReducer from "./containers/market-share-analysis/reducer";
import product360Reducer from "./containers/product-360/reducer";
import rankingEvents from "./containers/ranking-events/reducer";
import administrationReducer from "./containers/administration/reducer";
import insightsBoardReducer from "./containers/insights-board/reducer";
import catalogTrackerReducer from "./containers/catalog-tracker/reducer";
import catalogTrackerSkuDetailsReducer from "./containers/catalog-tracker-sku-details/reducer";
import brandPerformanceReducer from "./containers/brand-performance/reducer";

const appReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  competitors: competitorsReducer,
  categories: categoriesReducer,
  brands: brandsReducer,
  analytics: analyticsReducer,
  insights: combineReducers({
    insights: namespaced("insights")(insightsReducer),
    competitors: competitorsReducer,
    user: userReducer,
  }),
  skus: combineReducers({
    insights: namespaced("skus")(insightsReducer),
    competitors: competitorsReducer,
    user: userReducer,
  }),
  administration: combineReducers({
    user: userReducer,
    administration: administrationReducer,
    competitors: competitorsReducer,
    insights: namespaced("administration")(insightsReducer),
    mbFilterRow: namespaced("administration")(mbFilterRowReducer),
  }),
  marketShare: combineReducers({
    insights: namespaced("marketShare")(insightsReducer),
    insightsBoard: insightsBoardReducer,
    mbFilterRow: namespaced("marketShare")(mbFilterRowReducer),
    marketShareAnalysis: namespaced("marketShare")(marketShareAnalysisReducer),
    marketShare: marketShareReducer,
    competitors: competitorsReducer,
    user: userReducer,
  }),
  product360: combineReducers({
    insights: namespaced("product360")(insightsReducer),
    insightsBoard: insightsBoardReducer,
    mbFilterRow: namespaced("product360")(mbFilterRowReducer),
    product360: product360Reducer,
    competitors: competitorsReducer,
    user: userReducer,
  }),
  rankingEvents: combineReducers({
    rankingEvents: rankingEvents,
    insights: namespaced("rankingEvents")(insightsReducer),
    mbFilterRow: namespaced("rankingEvents")(mbFilterRowReducer),
    competitors: competitorsReducer,
    user: userReducer,
  }),
  insightsBoard: combineReducers({
    insightsBoard: insightsBoardReducer,
    insights: namespaced("insightsBoard")(insightsReducer),
    mbFilterRow: namespaced("insightsBoard")(mbFilterRowReducer),
    marketShareAnalysis: namespaced("insightsBoard")(
      marketShareAnalysisReducer
    ),
    competitors: competitorsReducer,
    user: userReducer,
  }),
  catalogTracker: combineReducers({
    mbFilterRow: namespaced("catalogTracker")(mbFilterRowReducer),
    insights: namespaced("catalogTracker")(insightsReducer),
    catalogTracker: namespaced("catalogTracker")(catalogTrackerReducer),
    user: userReducer,
    competitors: competitorsReducer,
  }),
  catalogTrackerSkuDetails: combineReducers({
    mbFilterRow: namespaced("catalogTrackerSkuDetails")(mbFilterRowReducer),
    insights: namespaced("catalogTrackerSkuDetails")(insightsReducer),
    catalogTrackerReducer: catalogTrackerReducer,
    catalogTrackerSkuDetails: catalogTrackerSkuDetailsReducer,
    user: userReducer,
    competitors: competitorsReducer,
  }),
  brandPerformance: combineReducers({
    mbFilterRow: namespaced("brandPerformance")(mbFilterRowReducer),
    insights: namespaced("brandPerformance")(insightsReducer),
    brandPerformance: namespaced("brandPerformance")(brandPerformanceReducer),
    user: userReducer,
    competitors: competitorsReducer,
  }),
});

const rootReducer = (state, action) => {
  if (action.type === "login/LOGIN_SUCCESS") {
    state = undefined;
  }
  if (action.type === "logout/LOGOUT_SUCCESS") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
