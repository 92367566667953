import React, { useState, useEffect } from "react";
import styles from "./checkbox.scss";

const Checkmark = ({ checked, onChange, color = "#5771f4" }) => {
  return (
    <div
      className={styles.checkmark}
      onClick={(e) => onChange(e, !checked)}
      style={
        color && checked
          ? {
              backgroundColor: color,
              border: `solid 1.5px ${color}`,
            }
          : { backgroundColor: "white" }
      }
    >
      {checked ? <div className={styles.checked}></div> : null}
    </div>
  );
};

export default Checkmark;
