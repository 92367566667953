const path = "marketShareAnalysis";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;

export const FETCH_DATA_REQUEST = `${path}/FETCH_DATA_REQUEST`;
export const FETCH_DATA_SUCCESS = `${path}/FETCH_DATA_SUCCESS`;
export const FETCH_DATA_FAIL = `${path}/FETCH_DATA_FAIL`;
export const MARKET_SHARE_ANALYSIS_CHANGE_GROUP_BY = `${path}/CHANGE_GROUP_BY`;
export const MARKET_SHARE_ANALYSIS_TOGGLE_SHOW_HIDE = `${path}/MARKET_SHARE_ANALYSIS_TOGGLE_SHOW_HIDE`;
export const MARKET_SHARE_ANALYSIS_SET_SUBJECT_NAME = `${path}/MARKET_SHARE_ANALYSIS_SET_SUBJECT_NAME`;

export const MARKET_SHARE_ANALYSIS_SUBJECT_TYPE = {
  BRAND: 0,
  SELLER: 1,
  CUSTOMER: 2,
};

export const MARKET_SHARE_ANALYSIS_GROUP_BY = {
  FAMILY: 0,
  SKU: 1,
};
