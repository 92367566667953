const path = "insights/insights";
export const SET_CURRENT_VIEW_IS_UPDATED = `${path}/SET_CURRENT_VIEW_IS_UPDATED`;
export const CHANGE_FIELD_COMPETITORS = `${path}/CHANGE_FIELD_COMPETITORS`;
export const CHANGE_FILTER_REVENUE_LEAKAGE = `${path}/CHANGE_FILTER_REVENUE_LEAKAGE`;

export const CHANGE_ACTIVE_CATEGORY_REVENUE_LEAKAGE = `${path}/CHANGE_ACTIVE_CATEGORY_REVENUE_LEAKAGE`;
export const CHANGE_SORT_BUTTON = `${path}/CHANGE_SORT_BUTTON`;
export const FETCH_INSIGHTS_REQUEST = `${path}/FETCH_INSIGHTS_REQUEST`;
export const FETCH_INSIGHTS_SUCCESS = `${path}/FETCH_INSIGHTS_SUCCESS`;
export const FETCH_INSIGHTS_FAILURE = `${path}/FETCH_INSIGHTS_FAILURE`;
export const FETCH_INSIGHTS_CANCEL = `${path}/FETCH_INSIGHTS_CANCEL`;

export const CHANGE_ACTIVE_CATEGORY_LEVEL_ONE = `${path}/CHANGE_ACTIVE_CATEGORY_LEVEL_ONE`;
export const FETCH_INSIGHTS_LEVEL_ONE_REQUEST = `${path}/FETCH_INSIGHTS_LEVEL_ONE_REQUEST`;
export const FETCH_INSIGHTS_LEVEL_ONE_SUCCESS = `${path}/FETCH_INSIGHTS_LEVEL_ONE_SUCCESS`;
export const CLEAR_LEVEL_ONE = `${path}/CLEAR_LEVEL_ONE`;

export const CHANGE_ACTIVE_CATEGORY_LEVEL_TWO = `${path}/CHANGE_ACTIVE_CATEGORY_LEVEL_TWO`;
export const FETCH_INSIGHTS_LEVEL_TWO_REQUEST = `${path}/FETCH_INSIGHTS_LEVEL_TWO_REQUEST`;
export const FETCH_INSIGHTS_LEVEL_TWO_SUCCESS = `${path}/FETCH_INSIGHTS_LEVEL_TWO_SUCCESS`;
export const CLEAR_LEVEL_TWO = `${path}/CLEAR_LEVEL_TWO`;

export const CHANGE_VISIBLE_COMPETITORS = `${path}/CHANGE_VISIBLE_COMPETITORS`;

export const CLEAR_INSIGHTS = `${path}/CLEAR_INSIGHTS`;

export const UPDATE_CURRENT_VIEW_CONFIG_QUERY = `${path}/UPDATE_CURRENT_VIEW_CONFIG_QUERY`;
export const UPDATE_VIEW_TYPE = `${path}/UPDATE_VIEW_TYPE`;

export const SHOW_SIDEBAR_FILTER = `${path}/TOGGLE_SIDEBAR_FILTER`;

export const FETCH_BRANDS_SUCCESS = `${path}/FETCH_BRANDS_SUCCESS`;
export const FETCH_BRANDS_FAILURE = `${path}/FETCH_BRANDS_FAILURE`;

export const UPDATE_FILTERS_SELECTED = `${path}/UPDATE_FILTERS_SELECTED`;
export const CLEAR_FILTERS_SELECTED = `${path}/CLEAR_FILTERS_SELECTED`;

const pathSkus = "insights-skus";

export const CHANGE_SKUS_SORTING = `${pathSkus}/CHANGE_SKUS_SORTING`;
export const CHANGE_SKUS_OFFSET = `${pathSkus}/CHANGE_SKUS_OFFSET`;
export const CHANGE_SKUS_SELECTED_COLUMNS = `${pathSkus}/CHANGE_SKUS_SELECTED_COLUMNS`;

export const FETCH_SKUS_REQUEST = `${pathSkus}/FETCH_SKUS_REQUEST`;
export const FETCH_SKUS_SUCCESS = `${pathSkus}/FETCH_SKUS_SUCCESS`;
export const FETCH_SKUS_FAILURE = `${pathSkus}/FETCH_SKUS_FAILURE`;
export const FETCH_SKUS_CANCEL = `${pathSkus}/FETCH_SKUS_CANCEL`;

export const FETCH_DATE_RANGE_SUCCESS = `${pathSkus}/FETCH_DATE_RANGE_SUCCESS`;

export const FETCH_MORE_SKUS_REQUEST = `${pathSkus}/FETCH_MORE_SKUS_REQUEST`;
export const FETCH_MORE_SKUS_SUCCESS = `${pathSkus}/FETCH_MORE_SKUS_SUCCESS`;
export const FETCH_MORE_SKUS_FAILURE = `${pathSkus}/FETCH_MORE_SKUS_FAILURE`;
export const FETCH_MORE_SKUS_CANCEL = `${pathSkus}/FETCH_MORE_SKUS_CANCEL`;

export const CHANGE_SKUS_DETAIL_ID = `${pathSkus}/CHANGE_SKUS_DETAIL_ID`;

export const CHANGE_PRICE_CHART = `${pathSkus}/CHANGE_PRICE_CHART`;
export const SORT_DAILY_VIEWS_CHART = `${pathSkus}/SORT_DAILY_VIEWS_CHART`;

export const CHANGE_DAILY_VIEWS_CHART = `${pathSkus}/CHANGE_DAILY_VIEWS_CHART`;
export const SORT_PRICE_CHART = `${pathSkus}/SORT_PRICE_CHART`;

export const CHANGE_AVAILABILITY_CHART = `${pathSkus}/CHANGE_AVAILABILITY_CHART`;
export const SORT_AVAILABILITY_CHART = `${pathSkus}/SORT_AVAILABILITY_CHART`;

export const CHANGE_DAILY_SALES_CHART = `${pathSkus}/CHANGE_DAILY_SALES_CHART`;
export const SORT_DAILY_SALES_CHART = `${pathSkus}/SORT_DAILY_SALES_CHART`;

export const CLEAR_SKUS_ITEMS = `${pathSkus}/CLEAR_SKUS_ITEMS`;
export const CHANGE_SKUS_SETTINGS = `${pathSkus}/CHANGE_SKUS_SETTINGS`;
export const CHANGE_SKUS_CHARTS_SETTINGS = `${pathSkus}/CHANGE_SKUS_CHARTS_SETTINGS`;
