const path = "skus";

export const CHANGE_FIELD_COMPETITORS = `${path}/CHANGE_FIELD_COMPETITORS`;
export const CHANGE_FIELD_CATEGORIES = `${path}/CHANGE_FIELD_CATEGORIES`;
export const CHANGE_FIELD_CONFIG = `${path}/CHANGE_FIELD_CONFIG`;

export const FETCH_SKUS_REQUEST = `${path}/FETCH_SKUS_REQUEST`;
export const FETCH_SKUS_SUCCESS = `${path}/FETCH_SKUS_SUCCESS`;
export const FETCH_SKUS_FAILURE = `${path}/FETCH_SKUS_FAILURE`;
export const FETCH_SKUS_CANCEL = `${path}/FETCH_SKUS_CANCEL`;

export const FETCH_MORE_SKUS_REQUEST = `${path}/FETCH_MORE_SKUS_REQUEST`;
export const FETCH_MORE_SKUS_SUCCESS = `${path}/FETCH_MORE_SKUS_SUCCESS`;
export const FETCH_MORE_SKUS_FAILURE = `${path}/FETCH_MORE_SKUS_FAILURE`;
export const FETCH_MORE_SKUS_CANCEL = `${path}/FETCH_MORE_SKUS_CANCEL`;

export const CHANGE_PRODUCT_DETAIL_ID = `${path}/CHANGE_PRODUCT_DETAIL_ID`;

export const CHANGE_PRICE_CHART = `${path}/CHANGE_PRICE_CHART`;
export const SORT_PRICE_CHART = `${path}/SORT_PRICE_CHART`;
export const CHANGE_PRICE_SEARCH_CONTAINER = `${path}/CHANGE_PRICE_SEARCH_CONTAINER`;

export const CHANGE_DAILY_SALES_CHART = `${path}/CHANGE_DAILY_SALES_CHART`;
export const SORT_DAILY_SALES_CHART = `${path}/SORT_DAILY_SALES_CHART`;

export const CHANGE_AVAILABILITY_CHART = `${path}/CHANGE_AVAILABILITY_CHART`;
export const SORT_AVAILABILITY_CHART = `${path}/SORT_AVAILABILITY_CHART`;
export const CHANGE_AVAILABILITY_SEARCH_CONTAINER = `${path}/CHANGE_AVAILABILITY_SEARCH_CONTAINER`;

export const FETCH_BRANDS_SUCCESS = `${path}/FETCH_BRANDS_SUCCESS`;
export const FETCH_BRANDS_FAILURE = `${path}/FETCH_BRANDS_FAILURE`;

export const CLEAR_ITEMS = `${path}/CLEAR_ITEMS`;

export const CLEAR_SKUS = `${path}/CLEAR_SKUS`;

export const SKU_TRACKER_VIEW_TYPE_DATA = { name: "skus", id: 1 };

export const FREQUENCY_PRESETS = {
  "Last day": 2,
  "Last 7 days": 1,
  "Last 30 days": 0,
  "Last 2 weeks": 3,
  "Last 4 weeks": 4,
};
