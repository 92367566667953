import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SubspaceProvider, ParentSpaceProvider } from "react-redux-subspace";

import InsightsTemplate from "../insights-template/insights-template";

import { convertUsersTableBrowserStringSortFieldToConfig } from "../../utils/administration/convertSortValue";

import UsersInfiniteTable from "../../containers/administration/components/users-infinite-table/users-infinite-table";

import AdministrationHeader from "../../containers/administration/components/administration-header/administration-header";
import AdministrationService from "../../services/administrationService";
import UserDetails from "../../containers/administration/components/user-details/user-details";

const administrationService = new AdministrationService();
import styles from "../insights-template/insights-template.scss";

class Administration extends Component {
  render() {
    const viewTypeData = { name: "administration", id: 0 };
    const search = this.props.location.search;
    const selectedColumns = [-1, 0, 1, 2];
    const excludedTableColumns = [];

    const table = (
      <ParentSpaceProvider>
        <UsersInfiniteTable
          excludedTableColumns={excludedTableColumns}
          selectedColumns={selectedColumns}
        />
      </ParentSpaceProvider>
    );

    const body = (
      <>
        <div className={styles.wrapper}>{table}</div>
      </>
    );

    return (
      <SubspaceProvider
        mapState={(state) => state.administration}
        namespace="administration"
      >
        <>
          <ParentSpaceProvider>
            <AdministrationHeader />
          </ParentSpaceProvider>
          <InsightsTemplate
            body={body}
            search={search}
            viewTypeData={viewTypeData}
            apiProvider={administrationService}
            table={table}
            convertBrowserStringSortFieldToConfig={
              convertUsersTableBrowserStringSortFieldToConfig
            }
            hideInsightsFilter={true}
          />
          <ParentSpaceProvider>
            <UserDetails />
          </ParentSpaceProvider>
        </>
      </SubspaceProvider>
    );
  }
}

export const mapStateToProps = (state) => {
  return {};
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Administration);
