import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./cell-loader.scss";

export function SimpleTableCellLoader(props) {
  const { speed = 2, width = 20 } = props;
  return (
    <div className={styles.loader}>
      <ContentLoader speed={speed} width={width} height={8}>
        <rect width={width} height="8" />
      </ContentLoader>
    </div>
  );
}
