import React, { useState } from "react";
import styles from "./brand-performance-search-term.scss";
import PanelComponent from "../../../../components/dashboard-components/panel-component/panel-component";
import {
  SearchTermPanelComponentDesignPlan,
  SearchTermPanelComponentWithGraphsDesignPlan,
} from "./brand-performance-search-term-config";

export default function BrandPerformanceSearchTerm(props) {
  const {
    data,
    selectedDisplay = false,
    removeSelectedSearchTermGraph,
    addSelectedSearchTermGraph,
  } = props;
  const [shouldDisplayGraphs, setShouldDisplayGraphs] = useState(
    selectedDisplay
  );

  const buttonCallback = () => {
    if (!shouldDisplayGraphs) {
      addSelectedSearchTermGraph(data.searchTerm);
    } else {
      removeSelectedSearchTermGraph(data.searchTerm);
    }
    setShouldDisplayGraphs(!shouldDisplayGraphs);
  };

  props = { ...props, buttonCallback };
  const designPlan = shouldDisplayGraphs
    ? SearchTermPanelComponentWithGraphsDesignPlan(props)
    : SearchTermPanelComponentDesignPlan(props);

  return (
    <>
      <PanelComponent
        styles={styles}
        componentData={data}
        isEnabled={true}
        // className={styles}
        designPlan={designPlan}
        hideHeader={true}
        hideBackground={true}
      />
    </>
  );
}
