const path = "administration";

export const APPLY_INITIAL_STATE = `${path}/APPLY_INITIAL_STATE`;

export const FETCH_USERS_TABLE_DATA_SUCCESS = `${path}/FETCH_USERS_TABLE_DATA_SUCCESS`;
export const FETCH_USERS_TABLE_DATA_FAILURE = `${path}/FETCH_USERS_TABLE_DATA_FAILURE`;
export const FETCH_USERS_TABLE_DATA_CANCEL = `${path}/FETCH_USERS_TABLE_DATA_CANCEL`;
export const FETCH_USERS_TABLE_DATA_REQUEST = `${path}/FETCH_USERS_TABLE_DATA_REQUEST`;

export const FETCH_ALL_POSSIBLE_CATEGORIES_SUCCESS = `${path}/FETCH_ALL_POSSIBLE_CATEGORIES_SUCCESS`;
export const FETCH_ALL_POSSIBLE_CATEGORIES_FAILURE = `${path}/FETCH_ALL_POSSIBLE_CATEGORIES_FAILURE`;
export const FETCH_ALL_POSSIBLE_CATEGORIES_CANCEL = `${path}/FETCH_ALL_POSSIBLE_CATEGORIES_CANCEL`;
export const FETCH_ALL_POSSIBLE_CATEGORIES_REQUEST = `${path}/FETCH_ALL_POSSIBLE_CATEGORIES_REQUEST`;

export const FETCH_ALL_POSSIBLE_SCREENS_SUCCESS = `${path}/FETCH_ALL_POSSIBLE_SCREENS_SUCCESS`;
export const FETCH_ALL_POSSIBLE_SCREENS_FAILURE = `${path}/FETCH_ALL_POSSIBLE_SCREENS_FAILURE`;
export const FETCH_ALL_POSSIBLE_SCREENS_CANCEL = `${path}/FETCH_ALL_POSSIBLE_SCREENS_CANCEL`;
export const FETCH_ALL_POSSIBLE_SCREENS_REQUEST = `${path}/FETCH_ALL_POSSIBLE_SCREENS_REQUEST`;

export const FETCH_ALL_POSSIBLE_COMPETITORS_SUCCESS = `${path}/FETCH_ALL_POSSIBLE_COMPETITORS_SUCCESS`;
export const FETCH_ALL_POSSIBLE_COMPETITORS_FAILURE = `${path}/FETCH_ALL_POSSIBLE_COMPETITORS_FAILURE`;
export const FETCH_ALL_POSSIBLE_COMPETITORS_CANCEL = `${path}/FETCH_ALL_POSSIBLE_COMPETITORS_CANCEL`;
export const FETCH_ALL_POSSIBLE_COMPETITORS_REQUEST = `${path}/FETCH_ALL_POSSIBLE_COMPETITORS_REQUEST`;

export const ADD_NEW_USER_REQUEST = `${path}/ADD_NEW_USER_REQUEST`;
export const ADD_NEW_USER_SUCCESS = `${path}/ADD_NEW_USER_SUCCESS`;
export const ADD_NEW_USER_FAILURE = `${path}/ADD_NEW_USER_FAILURE`;
export const ADD_NEW_USER_CANCEL = `${path}/ADD_NEW_USER_CANCEL`;

export const DELETE_USER_SUCCESS = `${path}/DELETE_USER_SUCCESS`;
export const DELETE_USER_REQUEST = `${path}/DELETE_USER_REQUEST`;
export const DELETE_USER_FAILURE = `${path}/DELETE_USER_FAILURE`;
export const DELETE_USER_CANCEL = `${path}/DELETE_USER_CANCEL`;

export const UPDATE_USER_REQUEST = `${path}/UPDATE_USER_REQUEST`;
export const UPDATE_USER_SUCCESS = `${path}/UPDATE_USER_SUCCESS`;
export const UPDATE_USER_FAILURE = `${path}/UPDATE_USER_FAILURE`;
export const UPDATE_USER_CANCEL = `${path}/UPDATE_USER_CANCEL`;

export const CRUD_OPERATION_FAILURE = `${path}/CRUD_OPERATION_FAILURE`;

export const CHANGE_CATEGORIES_SELECTED_KEYS = `${path}/CHANGE_CATEGORIES_SELECTED_KEYS`;
export const CHANGE_SCREENS_SELECTED_KEYS = `${path}/CHANGE_SCREENS_SELECTED_KEYS`;
export const CHANGE_COMPETITORS_SELECTED_KEYS = `${path}/CHANGE_COMPETITORS_SELECTED_KEYS`;

export const CLOSE_SHOW_POPUP_STATUS = `${path}/CLOSE_SHOW_POPUP_STATUS`;
export const SHOW_POPUP = `${path}/SHOW_POPUP`;
export const TOGGLE_UPDATE_USER = `${path}/TOGGLE_UPDATE_USER`;
export const SET_SELECTED_USER_DATA = `${path}/SET_SELECTED_USER_DATA`;

const insightsPath = `${path}/insights-skus`;
export const CHANGE_USERS_TABLE_OFFSET = `${insightsPath}/CHANGE_USERS_OFFSET`;
export const CHANGE_USERS_TABLE_SORTING = `${insightsPath}/CHANGE_SKUS_SORTING`;
export const SET_CURRENT_VIEW_IS_UPDATED = `${insightsPath}/SET_CURRENT_VIEW_IS_UPDATED`;
