import React from "react";
import { Link } from "react-router-dom";

import styles from "./auth-wrapper.scss";
import logo from "../../images/bi-logo.png";

const AuthWrapper = (props) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.block}>
        <div className={styles.header}>
          <div className={styles.imgCont}>
            <Link to="/login">
              <img src={logo} />
            </Link>
          </div>
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
};

export default AuthWrapper;
