import React, { Component } from "react";

import InsightsBarItem from "../../../../../components/insights-bar-item";
import styles from "./insights-competitors-item.scss";
import { abbreviateNumber } from "../../../../../utils/other/utilities.js";

export default class InsightsCompetitorsItem extends Component {
  getRange = (inflow, outflow) => {
    if (outflow) {
      return outflow;
    }
    if (inflow) {
      return inflow;
    }
    return null;
  };

  getImages = (competitors = [], rootDomain) => {
    let result = "";

    competitors.map((item) => {
      if (item.rootDomain === rootDomain) {
        result = item.logoImage;
      }
    });

    return result;
  };

  render() {
    const { data, competitors, sum } = this.props;
    const { inflow, outflow, competitorRootDomain } = data;

    const range = this.getRange(inflow, outflow);
    const imgUrl = this.getImages(competitors, competitorRootDomain);

    return (
      <tr className={styles.wrapper}>
        <td>
          <div className={styles.imgBox}>
            <img id={competitorRootDomain + "_lost_logo"} src={imgUrl} />
          </div>
        </td>
        <td>
          <div className={styles.rangeBox}>
            <div className={styles.rangeTitle}>Lost</div>
            <div
              id={competitorRootDomain + "_lost_sum"}
              className={styles.range}
            >
              ${abbreviateNumber(range)}
            </div>
          </div>
        </td>
        <td>
          <div className={styles.barBox}>
            <InsightsBarItem size="big" current={range} sum={sum} />
          </div>
        </td>
      </tr>
    );
  }
}
