import React, { useCallback, useRef, useState } from "react";
import styles from "./entity-dropdown.scss";
import EntityFilterIcon from "../../../../../images/entity-filter.svg";
import EntityExcludeIcon from "../../../../../images/entity-exclude.svg";
import EntityViewSkusIcon from "../../../../../images/entity-view-skus.svg";
import EntityView360Icon from "../../../../../images/entity-view-360.svg";
import EntityMarketShareAnalysisIcon from "../../../../../images/entity-market-share-analysis.svg";
import {
  DIMENSIONS,
  MARKET_SHARE_VIEW_TYPE_DATA,
  PIVOT_NAMES,
} from "../../../constants";
import BaseTooltipComponent from "../../../../../components/charts/visx/tooltips/base-tooltip-component";
import baseStyles from "../../../../../components/charts/visx/tooltips/tooltip-component.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateCurrentViewConfigQuery } from "../../../../insights/actions";
import _ from "lodash";
import { useSubspace } from "react-redux-subspace";
import { setSelectedSku } from "../../../../product-360/actions";
import { changeMarketShareSettings } from "../../../actions";
import { useEffect } from "react";
import { analyticsFilterFromGraph } from "../../../../shared/analytics/actions";
import {
  marketShareAnalysisSetSubjectName,
  marketShareAnalysisToggleShowHide,
} from "../../../../market-share-analysis/actions";
import { ROOTDOMAINS } from "../../../../../utils/other/constants";

const options = {
  filter: { text: "Filter", icon: EntityFilterIcon },
  exclude: { text: "Exclude", icon: EntityExcludeIcon },
  viewSkus: { text: "View SKUs", icon: EntityViewSkusIcon },
  viewSku360: { text: "View SKU 360", icon: EntityView360Icon },
  viewFamily360: { text: "View SKUs 360", icon: EntityView360Icon },
  showAnalysis: { text: "Show Analysis", icon: EntityMarketShareAnalysisIcon },
};

const EntityDropdown = ({
  settings,
  currentViewConfigQuery,
  entity,
  entities,
  filterDefinitions,
  entityFilterDefinitions,
  x,
  y,
  datum,
  setSelectedSku,
  changeMarketShareSettings,
  showMarketShareAnalysis,
  marketShareAnalysisToggleShowHide,
  marketShareAnalysisRef,
}) => {
  const [hide, setHide] = useState(true);
  const marketShareMapState = useCallback((state) => state.marketShare);
  const marketShareSubspace = useSubspace(marketShareMapState, "marketShare");

  const marketShareAnalysisMapState = useCallback(
    (state) => state.marketShareAnalysis
  );
  const marketShareAnalysisSubspace = useSubspace(
    marketShareAnalysisMapState,
    "marketShare"
  );
  const { dimension, dimensionPivot } = settings;
  const timeoutId = useRef();

  useEffect(() => {
    if (datum === undefined) setHide(true);
    else setHide(false);
  }, [x, y, datum]);

  useEffect(() => {
    // This prevents icons from being reloaded each time the dropdown is opened
    const icons = [
      EntityFilterIcon,
      EntityExcludeIcon,
      EntityViewSkusIcon,
      EntityView360Icon,
      EntityMarketShareAnalysisIcon,
    ];
    icons.forEach((i) => {
      new Image().src = i;
    });
  }, []);

  const categoryAttribute = (attrs) =>
    attrs.find((x) => x.mbName.toLowerCase() == "mb_category");

  const getMbCategoryFromL3 = (c) => {
    const mbCategories = categoryAttribute(filterDefinitions)?.values;
    return mbCategories.find((x) => x.split(">")?.[2] == c);
  };

  const getOptionsForDimension = () => {
    const shared = [options.filter, options.exclude];
    switch (dimension) {
      case DIMENSIONS.brands:
      case DIMENSIONS.sellers:
      case DIMENSIONS.manufacturer:
        let optionsForDimension = [...shared, options.viewSkus];
        if (settings?.kpisChart?.selectedTab === 1) {
          optionsForDimension.push(options.showAnalysis);
        }
        return optionsForDimension;
      case DIMENSIONS.attribute:
      case DIMENSIONS.rootdomain:
        return [...shared, options.viewSkus];
      case DIMENSIONS.priceRange:
      case DIMENSIONS.productTypes:
        return [options.filter, options.viewSkus];
      case DIMENSIONS.sku:
        return [...shared, options.viewSku360];
      case DIMENSIONS.family:
        return [...shared, options.viewSkus, options.viewFamily360];
      default:
        return [];
    }
  };

  const handleSelection = (option) => {
    switch (option) {
      case options.viewSkus.text:
        changeMarketShareSettings({
          ...settings,
          dimension: DIMENSIONS.sku,
          dimensionPivot: null,
        });
      case options.exclude.text:
      case options.filter.text:
        const exclude = options.exclude.text == option;
        const attrs = _.cloneDeep(currentViewConfigQuery.productAttributes);
        const prodAttr = _.cloneDeep(
          entityFilterDefinitions[dimension][exclude ? "exclude" : "include"]
        );
        prodAttr.hasValue = true;
        switch (dimension) {
          case DIMENSIONS.brands:
          case DIMENSIONS.sellers:
          case DIMENSIONS.sku:
          case DIMENSIONS.manufacturer:
            prodAttr.values = [entity];
            attrs.push(prodAttr);
            break;
          case DIMENSIONS.family:
            prodAttr.values = entity.split(",");
            attrs.push(prodAttr);
            break;
          case DIMENSIONS.priceRange:
            // Expects entity to be "$x - $y"
            const range = entity.replace(" - ", "").split("$").slice(1);
            prodAttr.criteria[0] = {
              ...prodAttr.criteria[0],
              value1: Number(range[0]),
              value2: Number(range[1]),
            };
            attrs.push(prodAttr);
            break;
          case DIMENSIONS.attribute:
            prodAttr.value = dimensionPivot;
            prodAttr.values = [entity];
            const categoryAttr = categoryAttribute(attrs);
            if (Array.isArray(categoryAttr.childAttributes))
              categoryAttr.childAttributes.push(prodAttr);
            else categoryAttr.childAttributes = [prodAttr];
            break;
          case DIMENSIONS.productTypes:
            const mbCategory = getMbCategoryFromL3(entity);
            if (categoryAttribute(attrs)) break; // If there's a category filter, it must be the current category.
            prodAttr.values = [mbCategory];
            attrs.push(prodAttr);
            break;
          case DIMENSIONS.rootdomain:
            const currentRetailers = attrs.find(
              (x) => x.mbName == prodAttr.mbName
            );
            const entityMbValue = Object.entries(ROOTDOMAINS).find(
              ([k, v]) => v == entity
            )[0];
            if (exclude) {
              currentRetailers.values.splice(
                currentRetailers.values.indexOf(entityMbValue),
                1
              );
            } else {
              currentRetailers.values = [entityMbValue];
            }
            break;
          default:
            break;
        }
        marketShareSubspace.dispatch(
          updateCurrentViewConfigQuery({
            productAttributes: attrs,
          })
        );
        break;
      case options.viewSku360.text:
        setSelectedSku(entity);
        break;
      case options.viewFamily360.text:
        setSelectedSku(entity.split(",")[0]);
        break;
      case options.showAnalysis.text:
        marketShareAnalysisSubspace.dispatch(
          marketShareAnalysisSetSubjectName(entity)
        );
        if (!showMarketShareAnalysis) {
          marketShareAnalysisSubspace.dispatch(
            marketShareAnalysisToggleShowHide(MARKET_SHARE_VIEW_TYPE_DATA.id)
          );
        }
        setTimeout(() => {
          marketShareAnalysisRef?.current?.scrollIntoView({
            behavior: "smooth",
          });
        }, 100);

        break;
    }
    analyticsFilterFromGraph(
      MARKET_SHARE_VIEW_TYPE_DATA.id,
      PIVOT_NAMES[dimension],
      option
    );
    setHide(true);
  };

  const getTitleByDimension = () => {
    switch (dimension) {
      case DIMENSIONS.attribute:
        return `${dimensionPivot}: ${entity}`;
      default:
        return entities[entity]?.displayName ?? entity;
    }
  };

  return (
    <>
      {!hide && (
        <div
          onMouseEnter={() =>
            timeoutId.current && clearTimeout(timeoutId.current)
          }
          onMouseLeave={() => {
            timeoutId.current = setTimeout(() => setHide(true), 200);
          }}
          style={{
            position: "absolute",
            top: y - 10,
            left: screen.availWidth - 200 < x ? x - 200 : x - 100,
            zIndex: 99,
          }}
        >
          <BaseTooltipComponent
            additionalStyles={`${baseStyles.boxshadowStyle} ${styles.baseTooltipOverride}`}
          >
            <div className={styles.dropdownTitle}>{getTitleByDimension()}</div>
            <div className={styles.options}>
              {getOptionsForDimension().map((option) => (
                <div
                  key={option.text}
                  className={styles.option}
                  onClick={() => handleSelection(option.text)}
                >
                  <img className={styles.optionIcon} src={option.icon} />
                  <div className={styles.optionText}>{option.text}</div>
                </div>
              ))}
            </div>
          </BaseTooltipComponent>
        </div>
      )}
    </>
  );
};

export const mapStateToProps = (state) => {
  return {
    showMarketShareAnalysis:
      state.marketShare.marketShareAnalysis.showMarketShareAnalysis,
    currentViewConfigQuery:
      state.marketShare.insights.insights.currentViewConfig.query,
    settings:
      state.marketShare.insights.insights.currentViewConfig.query.settings,
    entityFilterDefinitions:
      state.marketShare.insights.insights.viewDefinitions
        .entityFilterDefinitions,
    filterDefinitions:
      state.marketShare.insights.insights.viewDefinitions.filterDefinitions,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeMarketShareSettings,
      setSelectedSku,
      marketShareAnalysisToggleShowHide,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EntityDropdown);
