import React, { useEffect } from "react";
import styles from "./sku-details.scss";
import ComponentsContainer from "../../../insights-board/components-container/components-container";
import PanelComponent from "../../../../components/dashboard-components/panel-component/panel-component";
import panelStyles from "../../../insights-board/mb-components/category-customer-panel-component/category-customer-panel-component.scss";
import { CategoryCustomerPanelComponentConfig } from "../../../insights-board/mb-components/category-customer-panel-component/category-customer-panel-component-config";
import insightsCellStyles from "../../../insights-board/mb-components/category-customer-panel-component/category-customer-panel-component.scss";
import { connect } from "react-redux";
import SkuDetailsCell from "./sku-details-cell/sku-details-cell";
import skuDetailsCellStyles from "./sku-details-cell/sku-details-cell.scss";
import * as Decorators from "../../../../styles/cell-decorators";
import { STATUS_LOADING, STATUS_SUCCESS } from "../../constants";

const skuDetailsPanelConfig = {
  totalSalesDollars: {
    type: "data",
    title: "Total Sales Dollars",
    taglineAccessor: null,
    accessorSection: "category",
    valueAccessor: "currentSkuSalesDollars",
    valueDecorator: Decorators.priceDecorator,
    diffAccessor: "skuSalesDollarsChange",
    diffDecorator: Decorators.numberChangeDecorator,
    tooltip: null,
    style: {},
    valueStyle: insightsCellStyles.largeValue,
  },
  unitsSold: {
    type: "data",
    title: "Total Units",
    taglineAccessor: null,
    accessorSection: "category",
    valueAccessor: "currentSkuUnitsSold",
    valueDecorator: Decorators.salesDecorator("sales"),
    diffAccessor: "skuUnitsSoldChange",
    diffDecorator: Decorators.roundNumberChangeDecorator,
    tooltip: null,
    style: {},
    valueStyle: insightsCellStyles.largeValue,
  },
  avgSellingPrice: {
    type: "data",
    title: "Average Selling Price",
    taglineAccessor: null,
    accessorSection: "category",
    valueAccessor: "currentSkuAverageSellingPrice",
    valueDecorator: Decorators.priceDecorator,
    diffAccessor: "skuAverageSellingPriceChange",
    diffDecorator: Decorators.numberChangeDecorator,
    tooltip: null,
    style: {},
    valueStyle: insightsCellStyles.largeValue,
  },
  familyTotalSales: {
    type: "data",
    title: "Product Family Total Sales",
    taglineAccessor: null,
    accessorSection: "category",
    valueAccessor: "currentFamilySalesDollars",
    valueDecorator: Decorators.priceDecorator,
    diffAccessor: "familySalesDollarsChange",
    diffDecorator: Decorators.numberChangeDecorator,
    tooltip: null,
    style: {},
    valueStyle: insightsCellStyles.largeValue,
  },
};

const PanelComp = (props) => {
  const panelConfig = CategoryCustomerPanelComponentConfig(props);

  const designPlan = {
    columns: [
      {
        cells: [
          {
            type: "custom",
            className: skuDetailsCellStyles.cellWrapper,
            component: SkuDetailsCell,
          },
        ],
      },
      {
        className: styles.skuDetailsDataPanels,
        rows: [
          {
            className: styles.skuDetailsDataRow,
            cells: [skuDetailsPanelConfig.totalSalesDollars],
          },
          {
            className: styles.skuDetailsDataRow,
            cells: [skuDetailsPanelConfig.avgSellingPrice],
          },
        ],
      },
      {
        className: styles.skuDetailsDataPanels,
        rows: [
          {
            className: styles.skuDetailsDataRow,
            cells: [skuDetailsPanelConfig.unitsSold],
          },
          {
            className: styles.skuDetailsDataRow,
            cells: [skuDetailsPanelConfig.familyTotalSales],
          },
        ],
      },
    ],
  };

  return (
    <PanelComponent
      {...props}
      isEnabled={true}
      className={panelStyles}
      designPlan={designPlan}
      hideHeader={true}
      hideBackground={false}
    />
  );
};

const SkuDetails = ({ familyVariantsData, status }) => {
  const ComponentHolderConfig = {
    components: [
      {
        name: "Panel",
        component: PanelComp,
        actions: {
          fetch: [
            () => {
              return new Promise((res, rej) => {
                res(familyVariantsData);
              });
            },
          ],
          success: [
            (response) => {
              return { category: response };
            },
          ],
        },
        options: {},
      },
    ],
    designPlan: ["Panel"],
  };

  return (
    <div className={styles.skuDetailsContainer}>
      <ComponentsContainer
        selectedDateRange={null}
        selectedPreviousDateRange={null}
        comparePeriod={null}
        configuration={ComponentHolderConfig}
        componentsData={
          status === STATUS_SUCCESS
            ? {
                Panel: {
                  category: {
                    ...familyVariantsData,
                    ...familyVariantsData.selectedSku,
                  },
                },
              }
            : {}
        }
        categories={null}
      />
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    familyVariantsData: state.product360.product360.familyVariants.data,
    status: state.product360.product360.familyVariants.status,
  };
};

export default connect(mapStateToProps)(SkuDetails);
