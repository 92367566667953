import React, { Component } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import Checkbox from "../checkbox";

import CustomScrollbar from "../scrollbar/customScrollbar";

import styles from "./dropdown.scss";

class DropdownWithFilter extends Component {
  static defaultProps = {
    size: "normal",
    trim: 0,
    title: "",
    options: [],
    selectedItems: [],
  };

  static propTypes = {
    size: PropTypes.string,
    trim: PropTypes.number,
    title: PropTypes.string,
    options: PropTypes.array,
    selectedItems: PropTypes.array,
    handleSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      listOpen: false,
      query: "",
    };
  }

  handleClickItem = (item) => {
    item = item.name ? item.name : item;
    if (!this.props.selectedItems.includes(item)) {
      this._addItem(item);
    } else {
      this._removeItem(item);
    }
  };

  handleRemoveItem = (item) => {
    this._removeItem(item);
  };

  _addItem = (item) => {
    const items = [...this.props.selectedItems, item];
    this.props.handleSelect(items);
  };

  _removeItem = (item) => {
    const items = this.props.selectedItems.filter((value) => value != item);
    this.props.handleSelect(items);
  };

  /* Show hide dropdown */
  toggleList = () => {
    this.setState((prevState) => ({
      listOpen: !prevState.listOpen,
    }));
  };

  handleClickOutside = (evt) => {
    this.setState({
      listOpen: false,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const props =
      JSON.stringify(nextProps) === JSON.stringify(this.props) ? false : true;
    const state =
      JSON.stringify(nextState) === JSON.stringify(this.state) ? false : true;
    return props || state;
  }

  onChangeQuery = (event) => {
    this.setState({
      ...this.setState,
      query: event.target.value,
    });
  };

  filterItems = () => {
    let filteredData = [...this.props.options];
    if (this.state.query) {
      filteredData = filteredData.filter((item) =>
        item.name
          .toLocaleLowerCase()
          .includes(this.state.query.toLocaleLowerCase())
      );
    }
    return filteredData;
  };

  render() {
    const { size, title, trim, selectedItems } = this.props;
    const { listOpen } = this.state;

    const options = this.filterItems();

    const dropdownHeaderClassName = `${styles.header} ${
      listOpen ? styles.headerActive : ""
    }`;

    return (
      <div
        ref={this.setWrapperRef}
        className={`${styles.wrapper} ${styles[size]} ${
          trim ? styles.trim : ""
        } ${styles.dropdownWithFilter}`}
      >
        <div id={title + "_dropdown"} className={dropdownHeaderClassName}>
          {selectedItems.length ? (
            selectedItems.map((item, index) => (
              <div
                key={index}
                id={title + "_selectedItems"}
                style={{ width: trim ? trim : "auto" }}
              >
                <div className={styles.selectedValue}>
                  {item}
                  <div
                    className={styles.cross}
                    onClick={this.handleRemoveItem.bind(null, item)}
                  >
                    <i className={styles.x}></i>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div className={styles.defaultValue}>Select</div>
            </div>
          )}
          <div className={styles.arrow} onClick={this.toggleList}></div>
        </div>
        <div>
          {listOpen && options && (
            <div className={styles.listWrapper}>
              <div className={styles.searchFilterBlock}>
                <input
                  className={styles.searchFilter}
                  placeholder="Search"
                  onChange={this.onChangeQuery}
                  type="text"
                />
                <div className={styles.searchLogo}>
                  <i></i>
                </div>
              </div>
              <CustomScrollbar style={{ height: 250 }} styles={styles}>
                <ul id={title + "_list"} className={styles.list}>
                  {options.length !== 0 &&
                    options.map((item, idx) => (
                      <li
                        id={title + item.name}
                        className={styles.listItem}
                        key={item.id || idx}
                        onClick={(checked) => {
                          this.handleClickItem(item);
                        }}
                      >
                        <div>
                          <Checkbox
                            checked={
                              selectedItems.findIndex(
                                (selectItem) => item.name === selectItem
                              ) !== -1
                            }
                            boldBorder={true}
                          />
                        </div>
                        <div className={styles.itemLabel}>{item.name}</div>
                      </li>
                    ))}
                  {options.length === 0 && (
                    <li className={styles.listItem}>List is empty</li>
                  )}
                </ul>
              </CustomScrollbar>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default onClickOutside(DropdownWithFilter);
