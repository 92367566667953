import React from "react";
import styles from "./button-group.scss";

function ButtonGroup({ buttons, toggledButton, onToggle }) {
  return (
    <div className={styles.buttonGroup}>
      {buttons.map((button) => {
        const toggleStyle =
          button.id === toggledButton ? styles.selected : styles.notSelected;
        return (
          <div
            key={button.id}
            className={toggleStyle}
            onClick={() => onToggle(button.id)}
          >
            <img src={button.image} />
            <div className="brands roboto-normal-black-pearl-13px-2">
              {button.label}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default ButtonGroup;
