import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styles from "./search-terms-graph.scss";
import ComboTooltipComponent from "../../../../../components/charts/visx/tooltips/combo-tooltip-component";
import * as Decorators from "../../../../../styles/cell-decorators";
import _ from "lodash";
import MarketShareBarChartLoader from "../../../../market-share/components/market-share-charts/market-share-chart-component/loaders/market-share-bar-chart-loader";
import { STATUS_LOADING } from "../../../constants";
import transformToComparativeLineData from "../../../../../components/charts/visx/transformToComparativeLineData";
import Switch from "../../../../../components/switch/switch";
import ComparativeLineTooltipComponent from "../../../../../components/charts/visx/tooltips/comparative-line-tooltip-component";
import { dateFormatter } from "../../../utils";
import ChartTitle from "../../../../../components/chart-title/chart-title";
import DualAxisChartComponent from "../../../../../components/charts/visx/dual-axis-chart-component";

const xKey = `currentDate`;

const SearchTermsLineChart = ({ data, selectedTerm, showComparison }) => {
  const currentDataKeys = {
    currentVisibility: {
      axis: "left",
      label: `Visibility Rate`,
      stroke: "#5771F4",
      decorator: Decorators.percentDecorator,
      width: 16,
      previousAccessor: "previousVisibility",
    },
    currentAdvertising: {
      axis: "left",
      label: `Sponsored Rate`,
      stroke: "#D871D4",
      decorator: Decorators.percentDecorator,
      width: 16,
      previousAccessor: "previousAdvertising",
    },
    currentSerpRank: {
      axis: "right",
      label: `Rank`,
      stroke: "#41CAC2",
      decorator: Decorators.roundNumberDecorator,
      width: 16,
      previousAccessor: "previousSerpRank",
    },
  };

  const comparativeTooltipCallback = () => {
    return ComparativeLineTooltipComponent({
      valueDecorator: Decorators.numberDecorator,
      section: "",
      entities: currentDataKeys,
      keyFormatter: (x) => currentDataKeys[x]?.label,
      xKey: xKey,
    });
  };

  const tooltipCallback = () => {
    return ComboTooltipComponent({
      section: "",
      entities: currentDataKeys,
      keyFormatter: (x) => currentDataKeys[x]?.label,
      xKey: xKey,
    });
  };

  const transformDataPointsToLineData = () => {
    const termData = data?.[selectedTerm];
    if (termData) {
      const currentDataPoints = [];
      const previousDataPoints = [];

      for (const dataPoint of termData) {
        const filteredCurrent = {};
        const filteredPrevious = {};
        filteredCurrent[xKey] = new Date(dataPoint.currentDate).getTime();
        filteredPrevious[xKey] = new Date(dataPoint.previousDate).getTime();
        Object.keys(currentDataKeys).map(
          (x) => (filteredCurrent[x] = dataPoint[x])
        );
        Object.keys(currentDataKeys).map(
          (x) =>
            (filteredPrevious[x] =
              dataPoint[currentDataKeys[x].previousAccessor])
        );
        currentDataPoints.push(filteredCurrent);
        previousDataPoints.push(filteredPrevious);
      }

      return { current: currentDataPoints, previous: previousDataPoints };
    }
    return { current: [], previous: [] };
  };

  const lineData = transformDataPointsToLineData();

  const comparative = transformToComparativeLineData(
    lineData.current,
    lineData.previous,
    currentDataKeys,
    xKey
  );

  return (
    <DualAxisChartComponent
      data={showComparison ? comparative.data : lineData.current}
      dataKeys={showComparison ? comparative.dataKeys : currentDataKeys}
      legendDataKeys={currentDataKeys}
      curveType={"curveMonotoneX"}
      xTickFormatter={dateFormatter}
      xKey={xKey}
      leftTickFormatter={Decorators.chartTickPercentFormat}
      rightTickFormatter={Decorators.chartTickNumberFormat}
      leftScaleType={`percentage`}
      rightScaleType={`linear`}
      rightScaleInvert={true}
      tooltipCallback={
        showComparison ? comparativeTooltipCallback() : tooltipCallback()
      }
    />
  );
};

const SearchTermsGraph = ({ selectedTerm, data, status }) => {
  const [showComparison, setShowComparison] = useState(false);
  return (
    <div>
      <div className={styles.controlsContainer}>
        <ChartTitle>Visibility vs Rank</ChartTitle>
        {/* <Switch
          toggled={showComparison}
          onChange={() => setShowComparison(!showComparison)}
          titleRight={"Show comparison"}
        /> */}
      </div>
      <div className={styles.graphsContainer}>
        {status == STATUS_LOADING ? (
          <MarketShareBarChartLoader />
        ) : (
          <SearchTermsLineChart
            data={data}
            selectedTerm={selectedTerm}
            showComparison={showComparison}
          />
        )}
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    data: state.product360.product360.searchTerms.data.graphData,
    status: state.product360.product360.searchTerms.status,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchTermsGraph);
