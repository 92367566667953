import {
  APPLY_INITIAL_STATE,
  FETCH_RANKING_EVENTS_TABLE_DATA_CANCEL,
  FETCH_RANKING_EVENTS_TABLE_DATA_FAILURE,
  FETCH_RANKING_EVENTS_TABLE_DATA_REQUEST,
  FETCH_RANKING_EVENTS_TABLE_DATA_SUCCESS,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_REQUEST,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_SUCCESS,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_FAILURE,
  FETCH_MORE_RANKING_EVENTS_TABLE_DATA_CANCEL,
} from "./constants";

const initialState = {
  data: [],
  tableStatus: {
    loading: false,
    loadingMore: false,
    hasMore: true,
  },
};

const rankingEventsReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }
    case FETCH_RANKING_EVENTS_TABLE_DATA_CANCEL: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
        },
      };
    }
    case FETCH_RANKING_EVENTS_TABLE_DATA_FAILURE: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
        },
      };
    }
    case FETCH_RANKING_EVENTS_TABLE_DATA_REQUEST: {
      return {
        ...state,
        data: [],
        tableStatus: {
          ...state.tableStatus,
          loading: true,
          hasMore: true,
        },
      };
    }
    case FETCH_RANKING_EVENTS_TABLE_DATA_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
        },
      };
    }
    case FETCH_MORE_RANKING_EVENTS_TABLE_DATA_REQUEST: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loadingMore: true,
        },
      };
    }
    case FETCH_MORE_RANKING_EVENTS_TABLE_DATA_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        data: [...state.data, ...data],
        tableStatus: {
          ...state.tableStatus,
          loading: false,
          hasMore: data.length !== 0 ? true : false,
          loadingMore: false,
        },
      };
    }
    case FETCH_MORE_RANKING_EVENTS_TABLE_DATA_CANCEL: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loadingMore: false,
        },
      };
    }
    case FETCH_MORE_RANKING_EVENTS_TABLE_DATA_FAILURE: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loadingMore: false,
        },
      };
    }
  }
  return state;
};

export default rankingEventsReducer;
