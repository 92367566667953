import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InsightsExportView from "./components/insights-export-view";
import ExportButton from "../../../components/export-button/export-button";
import insightsAiStyles from "../../../mb_components/mb-insights-ai-sidebar/insights-ai-sidebar.scss";
import Button from "../../../components/button";
import _ from "lodash";
import info from "../../../images/info-circle.svg";
import SharedFunctions from "../shared-functions";
import SettingsIcon from "../../../images/settings.svg";

import moment from "moment";

import {
  setCurrentViewIsUpdated,
  setCurrentViewConfig,
  updateCurrentViewConfig,
  updateCurrentViewConfigQuery,
  exportInsightsView,
  removeExportedInsightsViewData,
  setInitialViewLoaded,
} from "./actions";
import { showInsightsAiSidebar } from "../insights-ai-sidebar/actions";
import { changeSkusSorting } from "../insights-skus/actions";

import {
  setSelectedDateRange,
  setChosenPeriod,
  setComparePeriod,
  setPreviousSelectedDateRange,
} from "../../../mb_components/mb-filter-row/actions";

import styles from "./insights-data-controls.scss";

import utils from "../../../utils";
const {
  convertSortValueAfterChangeFrequency,
  generateQueryStringFromViewConfigQuery,
} = utils.skus;

import MbFilterRow from "../../../mb_components/mb-filter-row/mb-filter-row";
import { ParentSpaceProvider, SubspaceProvider } from "react-redux-subspace";
import {
  analyticsLoadView,
  analyticsInsightsAiOpened,
} from "../../shared/analytics/actions";
import getQueryParams from "../../../utils/skus/getQueryParams";
import {
  generateViewConfigQueryFromEncodedJson,
  generateViewConfigQueryFromQueryString,
} from "../../../utils/skus/generateQueryConfig";
import EventWizard from "../../../mb_components/event-wizard/event-wizard";
import ToggleAIButton from "../insights-ai-sidebar/toggle-ai-button";
import { forceSingleRetailerSelection } from "../../../utils/other/mbDataUtils";
import getLastPredictionDate from "../../../utils/skus/getLastPredictionDate";

const sharedFunctions = new SharedFunctions();

function InsightsDataControls(props) {
  const [currentViewConfigId, setCurrentViewConfigId] = useState(
    currentViewConfig?.id
  );
  const [wizardOpen, setWizardOpen] = useState(false);

  const {
    viewsConfigsList,
    currentViewConfig,
    currentViewConfigQuery,
    dataControlsOptions,
    isWaitingForExportToFinish,
    exportedCsvContent,
    viewDefinitions,
    settings,
    loadedInitialView,
  } = props;

  const {
    viewTypeData,
    lastAvailableDate,
    maxDate,
    minDate = null,
    filters,
    mbFilter,
    mbFilterMode,
    apiProvider,
    hideDate,
    hideExport,
    hideFrequency,
    hideRetailer,
    resetTimeFrequency,
    hideCompareDates,
    resetDateRange,
    convertBrowserStringSortFieldToConfig,
    competitorsList,
    selectedDateRange,
    selectedCompetitor,
    selectedTimeFrequency,
    exportPdfCallback,
    dataAvailableUntilLabel,
    dataUnavailableLabel,
    QuickFilter,
    BottomRowLeftComponent,
    TitleComponent,
    showEventTriggers,
  } = dataControlsOptions;

  const [lastAvailableDateLoaded, setLastAvailableDateLoaded] = useState(false);
  const hideExportPdf = dataControlsOptions.hideExportPdf ?? true;

  const getScreenNameByViewTypeId = (viewTypeId) => {
    switch (viewTypeId) {
      case 1:
        return "SKU Performance Tracker";
      case 3:
        return "Sales and Market Share";
      case 4:
        return "Product Page Notifications";
      case 6:
        return "Account Performance";
      case 7:
        return "Product 360";
      case 9:
        return "Competitive Retail Tracker";
      case 10:
        return "Competitive Retail Tracker Match Details";
      case 11:
        return "Brand Performance";
      default:
        return "";
    }
  };

  const exportInsightsViewHandler = (limit) => {
    const { query } = currentViewConfig;
    const updatedQuery = {
      ...query,
      offset: 0,
      limit,
    };
    props.exportInsightsView(
      apiProvider,
      updatedQuery,
      viewTypeData.id,
      selectedDateRange,
      settings,
      props.selectedPreviousDateRange
    );
  };

  const applyFiltersToQueryString = () => {
    if (!lastAvailableDateLoaded || !loadedInitialView) return;
    const partQueryString = generateQueryStringFromViewConfigQuery(
      currentViewConfig.query,
      props.startDate,
      props.endDate,
      props.rolling,
      props.selectedPreviousDateRange.previousStartDate,
      props.selectedPreviousDateRange.previousEndDate,
      props.comparePeriod
    );
    const url = new URL(document.location.href);
    url.pathname = viewTypeData.name;
    url.searchParams.delete("q");
    url.searchParams.set("b", partQueryString);
    history.pushState({}, "", url);
  };

  useEffect(() => {
    if (exportedCsvContent) {
      var name;
      switch (viewTypeData.id) {
        case 0:
          name = "Insights";
          break;
        case 1:
          name = "SKU Tracker";
          break;
        case 3:
          name = "Market Share";
          break;
        case 4:
          name = "Ranking Events";
          break;
        case 9:
          name = "Competitive Retail Tracker";
          break;
        default:
          name = "Export";
      }
      const fileName = `Bright Insights ${name} ${moment(
        selectedDateRange.startDate
      ).format("MM_DD_YYYY")} - ${moment(selectedDateRange.endDate).format(
        "MM_DD_YYYY"
      )}.csv`;
      const encodedUri = encodeURIComponent(exportedCsvContent);
      const link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8," + encodedUri);
      link.setAttribute("download", fileName);
      document.body.appendChild(link);

      link.click();

      props.removeExportedInsightsViewData();
    }
  }, [exportedCsvContent]);

  useEffect(() => {
    applyFiltersToQueryString();
  }, [
    currentViewConfig.query,
    props.rolling,
    props.comparePeriod,
    selectedDateRange,
  ]);

  useEffect(() => {
    if (currentViewConfig.id !== currentViewConfigId) {
      applyFiltersToQueryString();
      setCurrentViewConfigId(currentViewConfig.id);
      if (currentViewConfig.id) {
        analyticsLoadView(viewTypeData.id, currentViewConfig.name);
      }
    }
  }, [currentViewConfig]);

  let formattedLastAvailableDate = moment(lastAvailableDate).format(
    "MMM DD, YYYY"
  );
  useEffect(() => {
    if (viewDefinitions?.filterDefinitions?.length > 0)
      setLastAvailableDateLoaded(true);
  }, [viewDefinitions]);

  const getUrlStoredConfigs = () => {
    /**
     * params.b - base64 encoded json of insightsViewQuery
     * params.q - old url format with our custom syntax. (To be deprecated)
     */
    const s = window.location.search;
    const params = getQueryParams(s);
    const queryString = params?.b || params?.q;
    if (queryString) {
      if (params?.b) return generateViewConfigQueryFromEncodedJson(queryString);
      if (params?.q)
        return generateViewConfigQueryFromQueryString(
          viewTypeData.id,
          queryString,
          viewDefinitions,
          convertBrowserStringSortFieldToConfig
        );
    }
  };

  const setInitialView = () => {
    // initial view is one of: url query, favorite view, or default configuration
    let viewConfigQuery = getUrlStoredConfigs();
    let viewConfigToSet;

    // if no query provided from url, use the favorite view (if there is)
    if (!viewConfigQuery) {
      const selectedViewConfig = viewsConfigsList.find(
        (viewConfig) =>
          viewConfig.favorite === 1 || viewConfig.favorite === true
      );
      if (selectedViewConfig) {
        viewConfigToSet = _.cloneDeep(selectedViewConfig);
        viewConfigQuery = viewConfigToSet.query;
      }
      // if no favorite is found, viewConfigQuery will remain empty and date handler will use default configuration.
    }

    let lastPrediction;
    if (viewConfigQuery?.productAttributes) {
      const domainsMetadata =
        viewDefinitions?.viewMetadata?.domainsMetadata || null;
      const currentSelectedRetailer =
        domainsMetadata != null
          ? forceSingleRetailerSelection(
              viewDefinitions.filterDefinitions,
              viewConfigQuery.productAttributes
            )
          : null;

      lastPrediction = getLastPredictionDate(
        domainsMetadata,
        currentSelectedRetailer,
        viewConfigQuery.productAttributes
      );
    }

    sharedFunctions.handleQueryDateConfig(
      viewConfigQuery,
      viewTypeData.id,
      lastPrediction ?? lastAvailableDate,
      props.setPreviousSelectedDateRange,
      props.setComparePeriod,
      props.setSelectedDateRange,
      props.setChosenPeriod
    );

    if (viewConfigToSet) props.setCurrentViewConfig(viewConfigToSet);
    else if (viewConfigQuery)
      props.setCurrentViewConfig({
        ...currentViewConfig,
        query: viewConfigQuery,
      });
    props.setInitialViewLoaded();
  };
  useEffect(() => {
    if (
      !loadedInitialView &&
      viewDefinitions?.filterDefinitions?.length > 0 &&
      viewsConfigsList !== null
    )
      setInitialView();
  }, [viewsConfigsList, viewDefinitions, lastAvailableDateLoaded]);

  const dataAvailableUntil = dataAvailableUntilLabel || "Data available until";
  const dataUnavailable = dataUnavailableLabel || "Data is unavailable";

  return (
    <div className={styles.controlsWrapper}>
      <div className={styles.controlsTopRow}>
        <div className={styles.topRowLeft}>
          <div className={styles.screenName}>
            {TitleComponent ? (
              <TitleComponent />
            ) : (
              getScreenNameByViewTypeId(viewTypeData.id)
            )}
          </div>
        </div>
        <div className={styles.topRowRight}>
          {showEventTriggers && (
            <Button
              variant="pale"
              text={"Event Triggers"}
              active={true}
              icon={SettingsIcon}
              onClick={() => setWizardOpen(true)}
            />
          )}
          {wizardOpen && (
            <ParentSpaceProvider>
              <EventWizard
                apiProvider={apiProvider}
                close={() => setWizardOpen(false)}
                viewTypeData={viewTypeData}
              />
            </ParentSpaceProvider>
          )}
          {!hideExportPdf && (
            <div className={styles.rightRowFlexItem}>
              <ExportButton
                title={"Export PDF"}
                onClick={exportPdfCallback}
                showSpinner={false}
              />
            </div>
          )}
          {!hideExport && (
            <div className={styles.rightRowFlexItem}>
              <InsightsExportView
                showExportSpinner={isWaitingForExportToFinish}
                exportInsightsViewHandler={exportInsightsViewHandler}
                dimension={settings && settings.dimension}
              />
            </div>
          )}
          {filters && <div className={styles.filters}>{filters}</div>}
        </div>
      </div>
      <div className={styles.controlsBottomRow}>
        <div className={styles.bottomRowLeft}>{BottomRowLeftComponent}</div>
        {QuickFilter && (
          <QuickFilter
            filterDefinitions={viewDefinitions?.filterDefinitions}
            currentViewConfigQuery={currentViewConfigQuery}
            updateCurrentViewConfigQuery={props.updateCurrentViewConfigQuery}
          />
        )}

        <div className={styles.bottomRowCenter}>
          {mbFilter && (
            <div className={styles.mbFilterRowWrapper}>
              <SubspaceProvider
                mapState={(state) => state.insights.mbFilterRow}
              >
                <MbFilterRow
                  competitorsList={competitorsList}
                  selectedDateRange={selectedDateRange}
                  selectedCompetitor={selectedCompetitor}
                  selectedTimeFrequency={selectedTimeFrequency}
                  resetTimeFrequency={resetTimeFrequency}
                  resetDateRange={resetDateRange}
                  hideRetailer={hideRetailer}
                  hideFrequency={hideFrequency}
                  maxDate={maxDate}
                  minDate={minDate}
                  mbFilterMode={mbFilterMode}
                  hideCompareDates={hideCompareDates}
                  viewTypeId={viewTypeData.id}
                  lastAvailableDate={lastAvailableDate}
                  datePickerPresets={dataControlsOptions.datePickerPresets}
                  datePickerModes={dataControlsOptions.datePickerModes}
                  comparePeriods={dataControlsOptions.comparePeriods}
                  handlePresetClick={dataControlsOptions.handlePresetClick}
                  queryFrequency={currentViewConfigQuery.queryFrequency}
                />
              </SubspaceProvider>
            </div>
          )}
        </div>
        <div className={styles.bottomRowRight}>
          {!hideDate && lastAvailableDateLoaded ? (
            <span>
              {formattedLastAvailableDate !== "Invalid date" ? (
                <div className={styles.dateAvailability}>
                  <img src={info} />
                  {dataAvailableUntil}: {formattedLastAvailableDate}
                </div>
              ) : (
                <div className={styles.dateAvailability}>
                  <img src={info} />
                  {dataUnavailable}
                </div>
              )}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export const mapStateToProps = (state) => {
  const { insights } = state;
  const { endDate, startDate } = insights.mbFilterRow.selectedDateRange;
  return {
    viewsConfigsList: insights.insights.viewsConfigsList,
    currentViewConfig: insights.insights.currentViewConfig,
    currentViewConfigQuery: insights.insights.currentViewConfig.query,
    activeCategory: insights.insights.activeCategory,
    level1ActiveCategory: insights.insights.topCategoryLevel1.activeCategory,
    skusFrequency: insights.insights.currentViewConfig.query.queryFrequency,
    sortField: insights.insights.currentViewConfig.query.sortField,
    exportedCsvContent: insights.insights.exportedCsvContent,
    currentViewConfigUpdated: insights.insights.currentViewConfigUpdated,
    loadedInitialView: insights.insights.loadedInitialView,
    viewTypeData: insights.insights.viewTypeData,
    viewDefinitions: insights.insights.viewDefinitions,
    settings: insights.insights.currentViewConfig.query.settings,
    isWaitingForExportToFinish: insights.insights.isWaitingForExportToFinish,
    rolling: insights.mbFilterRow.rolling,
    startDate,
    endDate,
    selectedPreviousDateRange: insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: insights.mbFilterRow.comparePeriod,
    insightsAiShowSidebar: insights.insights.insightsAiShowSidebar,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentViewIsUpdated,
      setInitialViewLoaded,
      changeSkusSorting,

      setCurrentViewConfig,
      updateCurrentViewConfig,
      updateCurrentViewConfigQuery,

      exportInsightsView,
      removeExportedInsightsViewData,

      setSelectedDateRange,
      setChosenPeriod,
      setComparePeriod,
      setPreviousSelectedDateRange,

      showInsightsAiSidebar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InsightsDataControls);
