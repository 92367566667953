import React from "react";
import * as Decorators from "../../../../styles/cell-decorators";
import {
  commonReplacements,
  placeSeparatorsBetweenElements,
} from "../../../market-share/components/insights-ai-sidebar/text-utils";
import { isFunction } from "lodash";
import AiSidebarBody from "../../../insights/insights-ai-sidebar/ai-sidebar-body";
import { extractTextFromElement } from "../../../../utils/other/utilities";

const AnalysisInsights = ({ skus, textToCopy, viewTypeData }) => {
  const getUnderlyingDecorator = (decoratorName) => {
    try {
      if (isFunction(Decorators[decoratorName]()))
        return Decorators[decoratorName](decoratorName);
    } catch (err) {
      return Decorators[decoratorName];
    }
  };

  const text = placeSeparatorsBetweenElements(
    skus.map((textChunks) =>
      textChunks.map((textChunk) =>
        commonReplacements(
          Decorators.decorateValue(
            textChunk.value,
            getUnderlyingDecorator(textChunk.decorator)
          )
        )
      )
    )
  );

  textToCopy.current = skus
    .map((textChunks) =>
      textChunks
        .map((textChunk) =>
          extractTextFromElement(
            Decorators.decorateValue(
              textChunk.value,
              getUnderlyingDecorator(textChunk.decorator)
            )
          )
        )
        .join("")
    )
    .join("\n");

  return (
    <div>
      <AiSidebarBody
        text={text}
        viewTypeId={viewTypeData.id}
        featureName={"Analysis Insights"}
        featureValue={""}
      />
    </div>
  );
};

export default AnalysisInsights;
