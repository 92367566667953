import React from "react";
import Checkmark from "../../components/checkbox/checkmark";
import _ from "lodash";
import styles from "./seller-type-quick-filter.scss";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { updateCurrentViewConfigQuery } from "../../containers/insights/insights-data-controls/actions";

const FILTER_MB_NAME = "sales_kpis.seller_type";

const SellerTypeQuickFilter = ({
  filterDefinitions,
  productAttributes,
  currentViewConfigQuery,
  updateCurrentViewConfigQuery,
  settings,
  tabsConfig,
}) => {
  const mbName = "sales_kpis.seller_type";

  const filterDefinitionToValue = {
    "1p": "SellerType1p",
    "3p": "SellerType3p",
  };
  const sellerTypesDefinition = filterDefinitions.find(
    (x) => x.mbName === FILTER_MB_NAME
  );
  const selectedValues = currentViewConfigQuery.productAttributes.find(
    (x) => x.mbName === FILTER_MB_NAME
  )?.values;

  const onChangeFilter = (val, toggle) => {
    const prodAttrs = _.cloneDeep(currentViewConfigQuery.productAttributes);
    const sellerTypeFilter = prodAttrs.find((x) => x.mbName === FILTER_MB_NAME);
    if (!toggle) {
      if (sellerTypeFilter.values.length === 1) {
        const i = prodAttrs.findIndex((x) => x.mbName === FILTER_MB_NAME);
        prodAttrs.splice(i, 1);
      } else {
        const i = sellerTypeFilter.values.indexOf(val);
        sellerTypeFilter.values.splice(i, 1);
      }
    } else {
      if (!sellerTypeFilter) {
        prodAttrs.push({
          displayName: sellerTypesDefinition.displayName,
          mbName: sellerTypesDefinition.mbName,
          type: sellerTypesDefinition.type,
          hasValue: true,
          criterion: 0,
          childAttributes: [],
          values: [val],
        });
      } else {
        sellerTypeFilter.values.push(val);
      }
    }
    updateCurrentViewConfigQuery({ productAttributes: prodAttrs });
  };

  const shouldHide = () => {
    if (settings?.selectedTab === undefined) return true;
    const tab = tabsConfig.find((x) => x.id == settings.selectedTab);
    if (tab.hideQuickFilter) return true;
    const excludeConditions = filterDefinitions.find((x) => x.mbName == mbName)
      ?.excludeConditions;
    if (!excludeConditions) return false;
    for (const condition of excludeConditions) {
      if (
        productAttributes
          .find((x) => x.mbName == condition.MbName)
          ?.values.some((x) => condition.Values.includes(x))
      )
        return true;
    }
    return false;
  };

  return shouldHide() ? (
    <></>
  ) : (
    <div className={styles.quickFilter}>
      {/* {sellerTypesDefinition?.values?.length > 0 && (
        <span className={styles.preTitle}>Seller type </span>
      )} */}
      {sellerTypesDefinition?.values?.map((sellerType) => (
        <div key={sellerType} className={styles.sellerTypeWrapper}>
          <Checkmark
            checked={selectedValues?.includes(
              filterDefinitionToValue[sellerType]
            )}
            onChange={(e, toggle) =>
              onChangeFilter(filterDefinitionToValue[sellerType], toggle)
            }
          />
          <span className={styles.sellerTypeValue}>{sellerType}</span>
        </div>
      ))}
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    filterDefinitions:
      state.insights.insights.viewDefinitions.filterDefinitions,
    productAttributes:
      state.insights.insights.currentViewConfig.query.productAttributes,
    currentViewConfigQuery: state.insights.insights.currentViewConfig.query,
    settings: state.insights.insights.currentViewConfig.query.settings,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateCurrentViewConfigQuery,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SellerTypeQuickFilter);
