import React, { useState, useMemo, useEffect } from "react";
import CustomScrollbar from "../../scrollbar/customScrollbar";
import Checkbox from "../../checkbox";

import defaultStyles from "../dropdown2.scss";

function DropdownBodyMultiselect(props) {
  const {
    options,
    handleClickItem,
    selectedItems,
    title,
    overflowReplace,
    scrolled,
    styles,
  } = props;

  const [itemsIsOverflowed, setItemsIsOverflowed] = useState(false);

  useEffect(() => {
    if (itemsIsOverflowed) {
      setTimeout(() => setItemsIsOverflowed(false), 2000);
    }
  }, [itemsIsOverflowed]);

  /* Multiselect handler */
  const onSelectItem = (selectedItem) => {
    let isOverflowed = false;
    const selectedItems = [];

    selectedItems.push(...props.selectedItems);

    // checked === did we just check the item?
    const checked =
      props.selectedItems.findIndex((x) => x.id === selectedItem.id) === -1;

    if (checked) {
      selectedItems.push(selectedItem);
      if (
        props.maxSelectedItems &&
        selectedItems.length > props.maxSelectedItems
      ) {
        if (overflowReplace) {
          selectedItems.splice(0, 1);
        } else {
          selectedItems.splice(selectedItems.length - 1, 1);
          isOverflowed = true;
        }
      }
    } else {
      const index = selectedItems.findIndex(
        (curItem) => curItem.id === selectedItem.id
      );
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    setItemsIsOverflowed(isOverflowed);
    handleClickItem({ checked, selectedItem, selectedItems, isOverflowed });
  };

  const body = (
    <ul id={title + "_list"} className={`${styles.list} ${defaultStyles.list}`}>
      {options.length !== 0 &&
        options.map((item, idx) => (
          <li
            key={item.id || idx}
            className={`${styles.multiselectListItem} ${defaultStyles.multiselectListItem}`}
            onClick={() => {
              onSelectItem(item);
            }}
          >
            <div className={`${styles.checkBox} ${defaultStyles.checkBox}`}>
              <Checkbox
                checked={
                  selectedItems.findIndex(
                    (selectItem) => item.id === selectItem.id
                  ) !== -1
                }
              />
            </div>
            <div className={`${styles.itemLabel} ${defaultStyles.itemLabel}`}>
              {item.name}
            </div>
          </li>
        ))}
      {options.length === 0 && (
        <li className={`${styles.listItem} ${defaultStyles.listItem}`}>
          List is empty
        </li>
      )}
    </ul>
  );

  return (
    <>
      {options && (
        <div className={`${styles.listWrapper} ${defaultStyles.listWrapper} `}>
          {itemsIsOverflowed && (
            <div
              className={`${styles.overflowNotification} ${defaultStyles.overflowNotification}`}
            >
              You can select up to {props.maxSelectedItems} items
            </div>
          )}
          {/* If you uncomment CustomScrollbar you should direct set height and width params to each dropdown  */}
          {scrolled ? (
            <CustomScrollbar
              style={{ height: props.height || 200, width: props.width || 100 }}
              styles={styles}
            >
              {body}
            </CustomScrollbar>
          ) : (
            body
          )}
        </div>
      )}
    </>
  );
}

DropdownBodyMultiselect.defaultProps = {
  scrolled: false,
  styles: {},
};

export default DropdownBodyMultiselect;
