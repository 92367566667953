import React from "react";
import {
  percentDecorator,
  chartTickPercentFormat,
} from "../../../../styles/cell-decorators";
import ChartComponent from "../../../../components/dashboard-components/chart-component/chart-component";

export default function CategoriesInStockRateChartComponent(props) {
  return (
    <ChartComponent
      {...props}
      title={"Category In Stock Rates"}
      currentKey={"currentInStockRate"}
      previousKey={"previousInStockRate"}
      changeKey={"inStockRateDiff"}
      taglineSubject={"The category in stock rate"}
      legendName={"In Stock Rate"}
      valueDecorator={percentDecorator}
      yTickFormat={chartTickPercentFormat}
    />
  );
}
