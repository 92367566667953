import moment from "moment";

const sortDate = (a, b) =>
  moment(a.date, "MM-DD-YYYY") - moment(b.date, "YMM-DD-YYYY");

const transformDataForHeadmap = (
  clientData = [],
  matchedData = [],
  selected = "Last 7 days",
  nameClient,
  nameMatched
) => {
  let xLabels = [];
  let clients = [];
  let matched = [];

  const selectDays = selected === "Last 30 days" ? 30 : 7;

  let lastDays = [...new Array(selectDays)].map((i, idx) =>
    moment().startOf("day").subtract(idx, "days")
  );
  lastDays = lastDays.sort(
    (a, b) => moment(a, "MM-DD-YYYY") - moment(b, "MM-DD-YYYY")
  );

  lastDays.map((item) => {
    xLabels.push(moment(item).format("MM/DD"));
    let currentDate = moment(item).format("MM-DD-YYYY");

    if (clientData && Array.isArray(clientData)) {
      let idx = clientData.findIndex(
        (item) => moment(item.date).format("MM-DD-YYYY") === currentDate
      );
      if (idx !== -1) {
        clients.push(clientData[idx].availability);
      } else {
        clients.push(0);
      }
    } else {
      clients.push(0);
    }

    if (matchedData && Array.isArray(matchedData)) {
      let idx = matchedData.findIndex(
        (item) => moment(item.date).format("MM-DD-YYYY") === currentDate
      );
      if (idx !== -1) {
        matched.push(matchedData[idx].availability);
      } else {
        matched.push(0);
      }
    } else {
      matched.push(0);
    }
  });

  let yLabels = nameMatched ? [nameClient, nameMatched] : [nameClient];

  return {
    xLabels,
    yLabels: yLabels,
    data: [[...clients], [...matched]],
  };
};

export default transformDataForHeadmap;
