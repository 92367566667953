import React from "react";
import styles from "./catalog-tracker-sku-details-header.scss";

const CatalogTrackerSkuDetailsHeader = (props) => {
  const { match } = props;
  const imageUrl = match?.url;

  const rightSideHeaders = ["Brand", "Product Type", "MPN", "UPC"];

  const toCamelCase = (str) => {
    return str
      .toLowerCase()
      .replace(/\s(.)/g, function (match) {
        return match.toUpperCase();
      })
      .replace(/\s/g, "")
      .replace(/^(.)/, function (match) {
        return match.toLowerCase();
      });
  };

  return (
    <>
      <div className={styles.catalogTracker360HeaderWrapper}>
        <div className={styles.leftSideHeader}>
          <img className={styles.headerImage} src={imageUrl} />
          <div className={styles.productName}>{match.name}</div>
        </div>
        <div className={styles.rightSideHeader}>
          <div className={styles.verticalLine} />
          <div className={styles.basicHeaderTitle}>
            {rightSideHeaders.map((header, index) => (
              <div key={index}>{header}</div>
            ))}
          </div>
          <div className={styles.basicHeaderValue}>
            {rightSideHeaders.map((header, index) => (
              <div key={index}>{match[toCamelCase(header)] ?? "N/A"}</div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default CatalogTrackerSkuDetailsHeader;
