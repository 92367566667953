const sumDataPoints = (dataPoints, keys) => {
  const sum = {};
  for (const dataPoint of dataPoints) {
    for (const key of keys) {
      if (dataPoint[key] !== undefined) {
        if (sum[key] === undefined) sum[key] = 0;
        sum[key] += dataPoint[key];
      }
    }
  }
  return sum;
};

export default sumDataPoints;
