import React, { Component } from "react";
import { cancelErrorLog, ApiService } from "../../services/apiService";
import ErrorIndicator from "../error-indicator";
import sourceMappedStackTrace from "sourcemapped-stacktrace";

const apiService = new ApiService();

/* 
  https://github.com/novocaine/sourcemapped-stacktrace/issues/45

  The sourcemapped-stacktrace npm package has a bug: It assumes that the minified filename is
  surrounded in parenthesis in each stack frame.  That's not always the case.  So, parse each
  stack frame to see if it has parenthesis, and if not, add them.  At the end, convert the array
  back into a string with \n at the end of each stack frame.  Why?  Because that's how
  sourcemapped-stacktrace splits the string in order to process each stack frame.
*/
const bugWorkaroundStackTrace = (stackTrace) => {
  let stackTraceArray = stackTrace.split("\n");

  const fixedStackFramesWithLineBreaks = stackTraceArray.map((stackFrame) => {
    stackFrame = bugWorkaroundStackFrame(stackFrame);

    return `${stackFrame}\n`;
  });

  const fixedStackTrace = fixedStackFramesWithLineBreaks.reduce(
    (stackTraceInProgress, currentFrame) => {
      return `${stackTraceInProgress}${currentFrame}`;
    }
  );

  return fixedStackTrace;
};

const bugWorkaroundStackFrame = (stackFrame) => {
  const parenPositionIdentifier = "at ";

  // If the stack frame does not include the minified file name surrounded in parenthesis,
  // sourcemapped-stacktrace will not properly parse the stack frame (it's a bug in the library).
  // So, add parenthesis.
  if (
    stackFrame.includes(parenPositionIdentifier) &&
    (!stackFrame.includes("(") || !stackFrame.includes(")"))
  ) {
    const position = stackFrame.indexOf(parenPositionIdentifier);
    if (position > 0) {
      const fixedStackFrame = `${stackFrame.slice(
        0,
        position + parenPositionIdentifier.length
      )}(${stackFrame.slice(position + parenPositionIdentifier.length)})`;
      stackFrame = fixedStackFrame;
    }
  }

  return stackFrame;
};

export default class ErrorBoundry extends Component {
  state = {
    hasError: false,
  };

  componentDidCatch(error, errorInfo) {
    console.log("Error component stack:\n" + errorInfo.componentStack);
    console.log("Error stack:\n" + error.stack);
    console.log({ error, errorInfo });
    this.requestErrorLog(error.stack, errorInfo.componentStack);
    this.setState({ hasError: true });
  }

  requestErrorLog(message, stackTrace) {
    const url = window.location.href;
    if (cancelErrorLog !== undefined) {
      cancelErrorLog("cancel");
    }

    // this de-obfuscates the stack trace
    sourceMappedStackTrace.mapStackTrace(
      bugWorkaroundStackTrace(message),
      (sourceMapped) => {
        const data = {
          message: message,
          stackTrace: sourceMapped.join("\n"),
          url: url,
        };

        const res = apiService
          .requestErrorLogPOST(`/log/`, data)
          .then((res) => {
            return Promise.resolve(res.data);
          })
          .catch((error) => {
            return Promise.reject(error);
          });

        return res;
      }
    );
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <ErrorIndicator />;
    }

    return this.props.children;
  }
}
