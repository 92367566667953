import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthWrapper from "../../../components/auth-wrapper";
import styles from "./forgot-password-success.scss";
import utils from "../../../utils";

import icon from "../../../images/email-sent.png";

const { getToken } = utils.auth;

class ForgotPasswordSuccess extends Component {
  componentDidMount() {
    if (getToken()) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <AuthWrapper>
        <div className={styles.wrapper}>
          <h3 className={styles.header}>
            An email with password reset instructions has been sent to your
            email address, if it exists on our system
          </h3>
          <div className={styles.imgCont}>
            <img src={icon} />
          </div>
          <p className={styles.info}>
            Didn’t recieve email? <Link to="/forgot-password">Click Here</Link>
          </p>
        </div>
      </AuthWrapper>
    );
  }
}

export default ForgotPasswordSuccess;
