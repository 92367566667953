import {
  FETCH_INSIGHTS_AI_REQUEST,
  FETCH_INSIGHTS_AI_SUCCESS,
  FETCH_INSIGHTS_AI_CANCEL,
  FETCH_INSIGHTS_AI_ERROR,
  SHOW_INSIGHTS_AI_SIDEBAR,
} from "./constants";

// Can be further generalized to receive network service as a parameter
import MarketShareService from "../../../services/marketShareService";
const marketShareService = new MarketShareService();

// Insights Ai
export const showInsightsAiSidebar = (show) => ({
  type: SHOW_INSIGHTS_AI_SIDEBAR,
  show,
});

export const InsightsAiRequested = (insightType) => ({
  type: FETCH_INSIGHTS_AI_REQUEST,
  payload: { insightType },
});

export const insightsAiSuccess = (insightType, data) => ({
  type: FETCH_INSIGHTS_AI_SUCCESS,
  payload: { insightType, data },
});

export const insightsAiCancel = (insightType) => ({
  type: FETCH_INSIGHTS_AI_CANCEL,
  payload: { insightType },
});

export const insightsAiError = (insightType) => ({
  type: FETCH_INSIGHTS_AI_ERROR,
  payload: { insightType },
});

export const fetchMarketShareInsightsAiSummary = (requestParams) => (
  dispatch
) => {
  dispatch(InsightsAiRequested("summary"));

  marketShareService
    .fetchInsightsAiSummary(requestParams)
    .then((res) => {
      dispatch(insightsAiSuccess("summary", res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(insightsAiCancel("summary"));
      } else {
        dispatch(insightsAiError("summary", err.message));
      }
    });
};

export const fetchMarketShareInsightsAiFullInsights = (requestParams) => (
  dispatch
) => {
  dispatch(InsightsAiRequested("fullInsights"));

  marketShareService
    .fetchInsightsAiFullInsights(requestParams)
    .then((res) => {
      dispatch(insightsAiSuccess("fullInsights", res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(insightsAiCancel("fullInsights"));
      } else {
        dispatch(insightsAiError("fullInsights", err.message));
      }
    });
};
