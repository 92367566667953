import React, { useState } from "react";
import styles from "./pivot-dropdown.scss";
import { useEffect } from "react";
import { useRef } from "react";
import NestedDropdown from "../../../../../components/nested-dropdown/nested-dropdown";
import {
  DIMENSIONS,
  PIVOT_DROPDOWN_ORDER,
  PIVOT_NAMES,
} from "../../../constants";

const PivotDropdown = ({
  selected,
  attributes,
  onSelect,
  filterDefinitions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef();

  const onAnyClick = (e) => {
    if (!containerRef.current.contains(e.target)) setIsOpen(false);
  };

  const handleSelect = (toSelect) => {
    const dIndex = parseInt(
      Object.keys(PIVOT_NAMES).find((key) => PIVOT_NAMES[key] === toSelect.main)
    );
    if (toSelect.sub) onSelect(dIndex, toSelect.sub);
    else onSelect(dIndex);
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", onAnyClick);

    return () => {
      document.removeEventListener("mousedown", onAnyClick);
    };
  }, []);

  const DIMENSIONS_TO_FILTER_DISPLAY_NAME = {
    [DIMENSIONS.manufacturer]: "Manufacturer",
    [DIMENSIONS.sellers]: "Seller",
  };

  const dropdownOptions = PIVOT_DROPDOWN_ORDER.map((p) =>
    p !== DIMENSIONS.attribute &&
    p !== DIMENSIONS.manufacturer &&
    p !== DIMENSIONS.sellers
      ? { key: PIVOT_NAMES[p] }
      : p === DIMENSIONS.manufacturer || p === DIMENSIONS.sellers
      ? {
          key: PIVOT_NAMES[p],
          disabled:
            filterDefinitions?.find(
              (x) => x.displayName === DIMENSIONS_TO_FILTER_DISPLAY_NAME[p]
            )?.values?.length === 0,
        }
      : {
          key: PIVOT_NAMES[p],
          options: attributes.map((x) => ({ key: x })),
          disabled: attributes.length === 0,
        }
  );
  return (
    <div className={styles.dropDownWrapper}>
      <div className={styles.dropDownTitlesWrapper}>
        <div className={styles.dropDownPreTitle}>Analyze by</div>
        <div
          className={styles.dropDownToggle}
          onClick={() => setIsOpen(true)}
          ref={containerRef}
        >
          <div className={styles.dropDownTitle}>
            {PIVOT_NAMES[selected.main]}
            {selected.sub && `: ${selected.sub}`}
            <span className={styles.arrowDown}></span>
          </div>
          {isOpen && (
            <NestedDropdown
              options={dropdownOptions}
              onSelect={handleSelect}
              selected={{
                main: PIVOT_NAMES[selected.main],
                sub: selected.sub,
              }}
              allowSubDropdown={true}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PivotDropdown;
