import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./logout.scss";
import Popup from "../../../components/popup";
import Spinner from "../../../components/spinner";
import utils from "../../../utils";
import { closeLogoutBlock, logoutRequest } from "./actions";

import iconLogout from "../../../images/logout-illus.svg";
import { clearInsights } from "../../insights/actions";

class Logout extends Component {
  logout = () => {
    this.props.clearInsights();
    this.props.logoutRequest();
  };

  render() {
    const { visible, error, loading } = this.props.logoutInfo;

    if (!visible) {
      return null;
    }

    const controlBox =
      !error && !loading ? (
        <div className={styles.controlBox}>
          <button
            id="no_stay_connected_btn"
            className={styles.cancelBnt}
            onClick={() => {
              this.props.closeLogoutBlock();
            }}
          >
            no, stay connected
          </button>
          <button
            id="yes_logout_btn"
            className={styles.confirmBtn}
            onClick={() => {
              this.logout();
            }}
          >
            yes, logout
          </button>
        </div>
      ) : null;

    const spinner = loading ? (
      <div className={styles.spinner}>
        <Spinner height={86} />
      </div>
    ) : null;

    const errorMsg =
      error && !loading ? <div className={styles.errorMsg}>{error}</div> : null;

    return (
      <div className={styles.popupWrapper}>
        <Popup
          visible={visible}
          handleClose={() => {
            this.props.closeLogoutBlock();
          }}
        >
          <div className={styles.wrapper}>
            <img src={iconLogout} className={styles.icon} />
            <p className={styles.header}>Are you sure you want to logout?</p>
            {controlBox}
            {spinner}
            {errorMsg}
          </div>
        </Popup>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    logoutInfo: state.auth.logout,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      closeLogoutBlock,
      logoutRequest,
      clearInsights,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
