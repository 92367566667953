import React, { Component } from "react";

import styles from "./insights-bar-item.scss";

export default class InsightsBarItem extends Component {
  render() {
    const { size, sum, current } = this.props;

    const cssWidth = (parseFloat(current) * 100) / parseFloat(sum);

    const cssClass = size === "big" ? styles.big : "";

    return (
      <div className={`${styles.wrapper} ${cssClass}`}>
        <div className={styles.item} style={{ width: `${cssWidth}%` }}></div>
      </div>
    );
  }
}
