import React from "react";
import FilterItemBase from "../filter-item-base/filter-item-base";
import { getFilterDefinitionComponent } from "../filter-item/filter-item";
import styles from "../filter-item/filter-item.scss";

function FilterItemChild(props) {
  const { item, onDispose } = props;

  const controls = getFilterDefinitionComponent(item, props);

  return (
    <FilterItemBase
      isChild={true}
      controls={controls}
      filterSelection={<div className={styles.filterName}>{item.value}</div>}
      onDispose={onDispose}
    />
  );
}

export default FilterItemChild;
