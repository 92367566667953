import React from "react";
import styles from "./metrics-column.scss";
import * as Decorators from "../../../../../styles/cell-decorators";

const MetricsColumn = ({ data, valueDecorator, diffDecorator, title }) => {
  return (
    <div className={styles.rowsContainer}>
      <div className={styles.kpisTitle}>{title}</div>
      {data.map((metric, i) => (
        <div key={metric.title}>
          {i !== 0 && <div className={styles.separator}></div>}
          <div className={styles.rowContainer}>
            <div className={styles.metricTitle}>{metric.title}</div>
            <div>
              <span className={styles.metricValue}>
                {Decorators.decorateValue(metric.value, valueDecorator)}
              </span>
              <span className={styles.metricDiff}>
                {Decorators.decorateValue(metric.diff, diffDecorator)}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MetricsColumn;
