import React, { Component } from "react";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";
import Checkbox from "../checkbox";

import CustomScrollbar from "../scrollbar/customScrollbar";

import defaultStyles from "./dropdown.scss";

class Dropdown extends Component {
  static defaultProps = {
    size: "normal",
    trim: 0,
    title: "",
    options: [],
    value: "",
    styles: {},
    selectedItems: [],
  };

  static propTypes = {
    size: PropTypes.string,
    trim: PropTypes.number,
    title: PropTypes.string,
    options: PropTypes.array,
    value: PropTypes.string,
    styles: PropTypes.object,
    handleSelect: PropTypes.func,
  };

  constructor(props) {
    super(props);

    this.state = {
      listOpen: false,
    };
  }

  handleClickItem = (item) => {
    this.setState({
      listOpen: false,
    });

    this.props.handleSelect(item);
  };
  /* Multiselect handler */
  select = (checked, selectedItem) => {
    const selectedItems = [];

    if (
      typeof this.props.multiSelect !== "undefined" &&
      this.props.multiSelect === true
    ) {
      selectedItems.push(...this.props.selectedItems);
    } else if (typeof this.props.multiSelect === "undefined") {
      selectedItems.push(...this.props.selectedItems);
    }

    if (checked) {
      selectedItems.push(selectedItem);
      if (
        this.props.maxSelectedItems &&
        selectedItems.length > this.props.maxSelectedItems
      ) {
        selectedItems.splice(0, 1);
      }
    } else {
      const index = selectedItems.findIndex(
        (curItem) => curItem.id === selectedItem.id
      );
      if (index !== -1) {
        selectedItems.splice(index, 1);
      }
    }

    this.setState({
      listOpen: false,
    });
    this.props.handleSelect({ checked, selectedItem, selectedItems });
  };
  /* Show hide dropdown */
  toggleList = () => {
    this.setState((prevState) => ({
      listOpen: !prevState.listOpen,
    }));
  };

  handleClickOutside = (evt) => {
    this.setState({
      listOpen: false,
    });
  };

  shouldComponentUpdate(nextProps, nextState) {
    const props =
      JSON.stringify(nextProps) === JSON.stringify(this.props) ? false : true;
    const state =
      JSON.stringify(nextState) === JSON.stringify(this.state) ? false : true;
    return props || state;
  }

  render() {
    const {
      size,
      title,
      options,
      value,
      trim,
      multiselect,
      selectedItems,
      arrow,
      dots,
      className,
    } = this.props;
    const { listOpen } = this.state;

    const styles = defaultStyles;

    const dropdownHeaderClassName = arrow
      ? `${styles.header} ${listOpen ? styles.headerActive : ""}`
      : null;

    return (
      <div
        className={`${styles.wrapper} ${styles[size]} ${
          trim ? styles.trim : ""
        } ${styles[className]}`}
      >
        <div
          id={title + "_dropdown"}
          className={dropdownHeaderClassName}
          onClick={this.toggleList}
        >
          {dots ? (
            <div className={styles.kebab}></div>
          ) : (
            <p className={styles.headerTitle}>
              <span>{title}</span>
              <span
                id={title + "_value"}
                style={{ width: trim ? trim : "auto" }}
              >
                {value}
              </span>
            </p>
          )}
        </div>
        <div style={{ position: "relative" }}>
          {listOpen && options && (
            <div
              className={` ${styles.listWrapper} ${this.props.styles.dropDownCustomStyle}`}
            >
              {/** If you uncomment CustomScrollbar you should direct set height and width params to each dropdown  */}
              {/* <CustomScrollbar style={{ height: this.props.height || 200, width: this.props.width || 100 }} styles={styles}> */}
              <ul id={title + "_list"} className={styles.list}>
                {options.length !== 0 &&
                  options.map((item, idx) =>
                    !multiselect ? (
                      <li
                        id={title + item.name}
                        className={`${styles.listItem} ${
                          item.name === value ? styles.selectedItem : ""
                        }`}
                        key={item.id || idx}
                        onClick={() => {
                          this.handleClickItem(item);
                        }}
                      >
                        {item.name}
                      </li>
                    ) : (
                      <li
                        id={title + item.name}
                        key={item.id || idx}
                        className={styles.multiselectListItem}
                      >
                        <div>
                          <Checkbox
                            onChange={(checked) => {
                              this.select(checked, item);
                            }}
                            checked={
                              selectedItems.findIndex(
                                (selectItem) => item.id === selectItem.id
                              ) !== -1
                            }
                          />
                        </div>
                        <div className={styles.itemLabel}>{item.name}</div>
                      </li>
                    )
                  )}
                {options.length === 0 && (
                  <li className={styles.listItem}>List is empty</li>
                )}
              </ul>
              {/* </CustomScrollbar> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default onClickOutside(Dropdown);
