import {
  CHANGE_FIELD_RESET_PASS,
  CHANGE_FIELD_ERROR_STATUS_RESET_PASS,
  TRY_SUBMIT_RESET_PASS,
  RESET_PASS_REQUEST,
  RESET_PASS_SUCCESS,
  RESET_PASS_FAILURE,
} from "../constants";

import AuthService from "../../../services/authService";
import history from "../../../history";

const authService = new AuthService();

export const changeField = (field, value) => ({
  type: CHANGE_FIELD_RESET_PASS,
  payload: { field, value },
});

export const changeFieldErrorStatus = (field, value) => ({
  type: CHANGE_FIELD_ERROR_STATUS_RESET_PASS,
  payload: { field, value },
});

export const trySubmitted = () => ({
  type: TRY_SUBMIT_RESET_PASS,
});

export const formRequested = () => ({
  type: RESET_PASS_REQUEST,
});

export const formSuccessed = () => ({
  type: RESET_PASS_SUCCESS,
});

export const formError = (error) => ({
  type: RESET_PASS_FAILURE,
  payload: { error },
});

export const sendForm = (data) => (dispatch) => {
  dispatch(formRequested());

  authService
    .requestResetPassword(data)
    .then((res) => {
      history.push("/reset-password-success");
      dispatch(formSuccessed());
    })
    .catch((err) => {
      dispatch(
        formError("Request failed, please check your input or contact support")
      );
    });
};
