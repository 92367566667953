import React from "react";
import moment from "moment";
import DefaultStyles from "./chart-component.scss";
import {
  decorateValue,
  percentChangeDecorator,
} from "../../../styles/cell-decorators";
import { getNextSunday } from "../../../utils/other/dates";
import LegendItemWrapper from "../../../visx_components/legend/legendItem";

const ChartComponentTooltip = (props) => (datumProps) => {
  const {
    title,
    currentKey,
    previousKey,
    changeKey,
    chartsProps,
    valueDecorator,
    diffDecorator,
  } = props;
  const datum = datumProps?.nearestDatum?.datum;
  const currentProps = chartsProps[0];
  const previousProps = chartsProps[1];

  return (
    datum && (
      <div className={DefaultStyles.tooltip}>
        <div className={DefaultStyles.tooltipTitle}>{title}</div>
        <table>
          <tbody>
            <tr className={DefaultStyles.tooltipRow}>
              <td>
                <LegendItemWrapper
                  label={{
                    value: currentProps.stroke,
                  }}
                  strokeDasharray={currentProps.strokeDasharray}
                />
              </td>
              <td className={DefaultStyles.tooltipDate}>
                {datum.currentDate} -{" "}
                {moment(getNextSunday(datum.currentDate)).format("MM/DD/YYYY")}
              </td>
              <td className={DefaultStyles.tooltipData}>
                {decorateValue(datum[currentKey], valueDecorator)}
              </td>
              <td className={DefaultStyles.tooltipChange}>
                {decorateValue(datum[changeKey], diffDecorator)}
              </td>
            </tr>
            <tr className={DefaultStyles.tooltipRow}>
              <td>
                <LegendItemWrapper
                  label={{
                    value: previousProps.stroke,
                  }}
                  strokeDasharray={previousProps.strokeDasharray}
                />
              </td>
              <td className={DefaultStyles.tooltipDate}>
                {datum.previousDate} -{" "}
                {moment(getNextSunday(datum.previousDate)).format("MM/DD/YYYY")}
              </td>
              <td className={DefaultStyles.tooltipData}>
                {decorateValue(datum[previousKey], valueDecorator)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  );
};

export default ChartComponentTooltip;
