import React from "react";
import styles from "./preset-button.scss";
import Button from "../../../../../components/button";

const PresetButton = ({ eventProps, onClick }) => {
  return (
    <div className={styles.presetContainer}>
      <img className={styles.presetIcon} src={eventProps.icon} />
      <div className={styles.presetTitles}>
        <div className={styles.presetTitle}>
          {eventProps.type ?? eventProps.title}
        </div>
        {eventProps.type && (
          <div className={styles.presetSubtitle}>{eventProps.title}</div>
        )}
      </div>
      <div style={{ width: "65px" }}>
        <Button
          variant={"contained"}
          onClick={() => onClick()}
          text={"Create"}
        />
      </div>
    </div>
  );
};

export default PresetButton;
