const getDefaultSort = (sort, competitor) => {
  if (sort === "") {
    if (competitor == "amazon.com" || competitor == "Amazon") {
      return "Views Diff";
    }

    if (competitor == "walmart.com" || competitor == "Walmart") {
      return "Views Diff";
    }
  }

  return sort;
};

export default getDefaultSort;
