const client = {
  label: "Client",
  fill: true,
  lineTension: 0.1,
  backgroundColor: "rgba(255, 134, 145, 0.6)",
  borderColor: "#ff8691",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#ff8691",
  pointBackgroundColor: "#ff8691",
  pointBorderWidth: 1,
  pointHoverRadius: 4,
  pointHoverBackgroundColor: "#ff8691",
  pointHoverBorderColor: "#000",
  pointHoverBorderWidth: 1,
  pointRadius: 4,
  pointHitRadius: 10,
  // data: [],
  spanGaps: false,
};

const matched = {
  label: "Matched",
  fill: true,
  lineTension: 0.1,
  backgroundColor: "rgba(97, 99, 102, 0.6)",
  borderColor: "#616366",
  borderCapStyle: "butt",
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: "miter",
  pointBorderColor: "#616366",
  pointBackgroundColor: "#616366",
  pointBorderWidth: 1,
  pointHoverRadius: 4,
  pointHoverBackgroundColor: "#616366",
  pointHoverBorderColor: "#000",
  pointHoverBorderWidth: 1,
  pointRadius: 4,
  pointHitRadius: 10,
  // data: [],
  spanGaps: false,
};

const createDataForLineChart = (data, clientName = "", matchedName = "") => {
  if (!data || data.length === 0) {
    return {
      labels: [],
      datasets: [],
    };
  }

  let checkClients = data[0].hasOwnProperty("client");
  let checkMatched = data[0].hasOwnProperty("matched");
  let labels = [];
  let clientList = [];
  let matchedList = [];

  if (checkClients && !checkMatched) {
    data.map((item) => {
      labels.push(item.date);
      clientList.push(item.client);
    });

    return {
      labels,
      datasets: [
        {
          ...client,
          label: clientName,
          data: clientList,
        },
      ],
    };
  }

  if (checkMatched && !checkClients) {
    data.map((item) => {
      labels.push(item.date);
      matchedList.push(item.matched);
    });

    return {
      labels,
      datasets: [
        {
          ...matched,
          label: matchedName,
          data: matchedList,
        },
      ],
    };
  }

  data.map((item) => {
    labels.push(item.date);
    clientList.push(item.client);
    matchedList.push(item.matched);
  });

  return {
    labels,
    datasets: [
      {
        ...client,
        label: clientName,
        data: clientList,
      },
      {
        ...matched,
        label: matchedName,
        data: matchedList,
      },
    ],
  };
};

export default createDataForLineChart;
