import React, { Component } from "react";
import Enum from "enum";
import { Route, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import styles from "./main.scss";
import navBarStyles from "../../components/top-nav-bar/top-nav-dropdown.scss";

import insightsIcon from "../../images/insights.svg";
import topSkuIcon from "../../images/st-screen-icon.svg";
import categoriesIcon from "../../images/categories.svg";
import trafficIcon from "../../images/traffic.svg";
import missingInventoryIcon from "../../images/missing-inventory.svg";
import unitSoldIcon from "../../images/sales-voumes.svg";
import productsGroupsIcon from "../../images/products-groups.svg";
import marketShareIcon from "../../images/ms-screen-icon.svg";
import rankingEventsIcon from "../../images/re-screen-icon.svg";
import administrationIcon from "../../images/blue-admin.png";
import insightsBoardIcon from "../../images/ap-screen-icon.svg";
import competitveRetailIcon from "../../images/competitive-retail-tracker.svg";
import brandPerformanceIcon from "../../images/brand-performance.svg";

import TopSkus from "../../pages/top-skus";
import Insights from "../../pages/insights";
import MarketShare from "../../pages/market-share";
import Administration from "../../pages/administration";
import CatalogTracker from "../../pages/catalog-tracker";
import InsightsBoard from "../../pages/insights-board";
import NoMatch from "../../pages/no-match";
import RankingEvents from "../../pages/ranking-events";
import BrandPerformance from "../../pages/brand-performance";
import { Logout } from "../auth";
import ErrorBoundry from "../../components/error-boundry";

import { fetchUser } from "../shared/user/actions";
import { fetchCompetitors } from "../shared/competitors/actions";
import { fetchCategories } from "../shared/categories/actions";
import { fetchBrands } from "../skus/actions";
import { openLogoutBlock } from "../auth/logout/actions";

import { clearInsights } from "../insights/actions";
import { clearSkus } from "../skus/actions";
import { clearBrands } from "../shared/brands/actions";
import { clearCategories } from "../shared/categories/actions";
import { clearCompetitors } from "../shared/competitors/actions";
import { clearUser } from "../shared/user/actions";

import utils from "../../utils";
import { ApiService } from "../../services/apiService";
import TopNavBar from "../../components/top-nav-bar/top-nav-bar";
import Product360 from "../../pages/product-360";
import BetaTag from "../../components/beta-tag/beta-tag";
import catalogTrackerSkuDetails from "../../pages/catalog-tracker-sku-details";

const apiService = new ApiService();

const { setDefaultAuthHeader } = utils.auth;

class Main extends Component {
  componentDidMount() {
    setDefaultAuthHeader();

    this.props.fetchUser();
    this.props.fetchCompetitors();
    this.props.fetchCategories();
    this.props.fetchBrands();
  }

  componentWillUnmount() {
    apiService.cancelAllRequested();

    this.props.clearInsights();
    this.props.clearSkus();
    this.props.clearBrands();
    this.props.clearCategories();
    this.props.clearCompetitors();
    this.props.clearUser();
  }

  getEIScreensEnum = () => {
    return new Enum(
      [
        "insights",
        "skus",
        "categories",
        "discoverability",
        "missing_inventory",
        "product_groups",
        "unit_sold",
        "market_share",
        "ranking_events",
        "administration",
        "insights_board",
        "product360",
        "competitive_retail_tracker",
        "competitive_retail_tracker_sku_details",
        "brand_performance",
      ],
      { separator: "|" }
    );
  };

  getPagesData = () => {
    return [
      {
        id: "insights_board",
        path: "/account-performance",
        component: InsightsBoard,
        src: insightsBoardIcon,
        title: "Account Performance",
        supportedModule: false,
      },
      {
        id: "insights",
        path: "/insights",
        component: Insights,
        src: insightsIcon,
        title: "Insights",
        supportedModule: true,
      },
      {
        id: "skus",
        path: "/skus",
        component: TopSkus,
        src: topSkuIcon,
        title: "SKU Performance Tracker",
        supportedModule: true,
      },
      {
        id: "categories",
        path: "/categories",
        component: NoMatch,
        src: categoriesIcon,
        title: "Categories",
        supportedModule: false,
      },
      {
        id: "discoverability",
        path: "/discoverability",
        component: NoMatch,
        src: trafficIcon,
        title: "Discoverability",
        supportedModule: false,
      },
      {
        id: "missing_inventory",
        path: "/missing-inventory",
        component: NoMatch,
        src: missingInventoryIcon,
        title: "Missing Inventory",
        supportedModule: false,
      },
      {
        id: "unit_sold",
        path: "/unit-sold",
        component: NoMatch,
        src: unitSoldIcon,
        title: "Units Sold",
        supportedModule: false,
      },
      {
        id: "product_groups",
        path: "/product-groups",
        component: NoMatch,
        src: productsGroupsIcon,
        title: "Category Insights",
        supportedModule: false,
      },
      {
        id: "market_share",
        path: "/market_share",
        component: MarketShare,
        src: marketShareIcon,
        title: "Sales and Market Share",
        supportedModule: false,
      },
      {
        id: "product_groups",
        path: "/product-groups/:productGroupId",
        component: NoMatch,
        index: "routeOnly",
      },
      {
        id: "ranking_events",
        path: "/product_page_notifications",
        component: RankingEvents,
        src: rankingEventsIcon,
        title: "Product Page Notifications",
        beta: true,
      },
      {
        id: "administration",
        path: "/administration",
        component: Administration,
        src: administrationIcon,
        title: "Administration",
        supportedModule: false,
      },
      {
        id: "product360",
        path: "/product",
        component: Product360,
        src: administrationIcon,
        title: "Product 360",
        supportedModule: true,
      },
      {
        id: "competitive_retail_tracker",
        path: "/competitive_retail_tracker",
        component: CatalogTracker,
        src: competitveRetailIcon,
        title: "Competitive Retail Tracker",
        supportedModule: false,
      },
      {
        id: "competitive_retail_tracker_sku_details",
        path: "/sku_details",
        component: catalogTrackerSkuDetails,
        src: competitveRetailIcon,
        title: "Competitive Retail Tracker Match Details",
        supportedModule: true,
      },
      {
        id: "brand_performance",
        path: "/brand_performance",
        component: BrandPerformance,
        src: brandPerformanceIcon,
        title: "Brand Performance",
        beta: true,
      },
    ];
  };

  getDefaultRoutes = (defaultComponent) => {
    return [
      { id: "id", path: "", component: defaultComponent },
      { id: "id/", path: "/", component: defaultComponent },
      { id: "no_match", component: NoMatch },
    ];
  };

  getRoutes = (val) => {
    if (!val) {
      return undefined;
    }
    const screens = this.getEIScreensEnum().getKey(val).split("|");
    const filteredComponents = this.getPagesData().filter((page) =>
      screens.includes(page.id)
    );
    if (
      screens.includes("market_share") ||
      screens.includes("insights_board")
    ) {
      filteredComponents.push(this.getPagesData()[12]);
    }
    if (screens.includes("competitive_retail_tracker")) {
      filteredComponents.push(this.getPagesData()[14]);
    }
    const defaultComponents = this.getDefaultRoutes(
      filteredComponents[0].component
    );
    const routeComponents = filteredComponents
      .concat(defaultComponents)
      .map(({ path, component }, key) => (
        <Route exact path={path} component={component} key={key} />
      ));
    return <Switch>{routeComponents}</Switch>;
  };

  getNavLinks = (val) => {
    if (!val) {
      return undefined;
    }
    const screens = this.getEIScreensEnum().getKey(val).split("|");
    const filterdLinks = this.getPagesData().filter(
      (link) => screens.includes(link.id) && link.index !== "routeOnly"
    );
    const navLinks = filterdLinks.map(
      ({ id, path, src, title, supportedModule, beta }, key) => (
        <NavLink
          className={navBarStyles.topNavDropdownOption}
          key={key}
          to={path}
          exact
          name={title}
          onClick={() => {}}
        >
          <img src={src} /> <span>{title}</span> {beta && <BetaTag size="sm" />}
        </NavLink>
      )
    );
    return navLinks;
  };

  render() {
    const { user, screens } = this.props;
    return (
      <ErrorBoundry>
        <div className={styles.wrapper}>
          <TopNavBar
            navLinks={this.getNavLinks(screens)}
            clickLogout={this.props.openLogoutBlock}
            userName={this.props.user.email}
          ></TopNavBar>
          <div className={styles.layout}>
            {screens > 0 && this.getRoutes(screens)}
          </div>
          <Logout />
        </div>
      </ErrorBoundry>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    user: state.user.userInfo,
    screens: state.user.screens,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchUser,
      fetchCompetitors,
      fetchCategories,
      fetchBrands,
      openLogoutBlock,
      clearInsights,
      clearSkus,
      clearBrands,
      clearCategories,
      clearCompetitors,
      clearUser,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Main);
