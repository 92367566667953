import React, { useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import MarketShareAnalysis from "./components/main-component";
import {
  fetchMarketShareAnalysisData,
  marketShareAnalysisChangeGroupBy,
  marketShareAnalysisToggleShowHide,
} from "./actions";
import { setSelectedSku } from "../product-360/actions";
import { useParentSpace } from "react-redux-subspace";
import { SET_SELECTED_SKU } from "../product-360/constants";
import { showInsightsAiSidebar } from "../insights/insights-ai-sidebar/actions";

function MarketShareAnalysisWrapper(props) {
  const {
    subjectType,
    subjectName,
    loading,
    data,
    groupBy,
    insightsViewQuery,
    selectedDateRange,
    selectedPreviousDateRange,
    comparePeriod,
    viewTypeData,
    viewName,
    showInsightsAiSidebar,
    insightsAiShowSidebar,
  } = props;
  const parentSpace = useParentSpace();

  useEffect(() => {
    props.fetchMarketShareAnalysisData(viewTypeData.id, {
      insightsViewQuery: {
        ...insightsViewQuery,
        previousStartDate: selectedPreviousDateRange.previousStartDate,
        previousEndDate: selectedPreviousDateRange.previousEndDate,
        endDate: selectedDateRange.endDate,
        startDate: selectedDateRange.startDate,
        comparePeriod: comparePeriod,
        selectedDateRange: selectedDateRange,
      },
      subjectType: subjectType,
      subjectName: subjectName,
      groupBy: groupBy,
    });
  }, [
    insightsViewQuery,
    groupBy,
    selectedDateRange,
    selectedPreviousDateRange,
    comparePeriod,
    subjectType,
    subjectName,
  ]);

  return (
    <MarketShareAnalysis
      id={"MarketShareAnalysis"}
      loading={loading}
      data={data}
      groupBy={groupBy}
      subjectName={subjectName}
      onChangeGroupBy={props.marketShareAnalysisChangeGroupBy}
      onClose={props.marketShareAnalysisToggleShowHide}
      OnSelectRow={(selectedSku) => {
        parentSpace.dispatch({
          type: SET_SELECTED_SKU,
          payload: selectedSku,
        });
      }}
      viewName={viewName}
      viewTypeData={viewTypeData}
      insightsAiShowSidebar={insightsAiShowSidebar}
      showInsightsAiSidebar={showInsightsAiSidebar}
    />
  );
}

export const mapStateToProps = (state) => {
  const {
    selectedDateRange,
    selectedPreviousDateRange,
    comparePeriod,
  } = state.mbFilterRow;

  return {
    selectedDateRange,
    selectedPreviousDateRange,
    comparePeriod,
    loading: state.marketShareAnalysis.loading,
    data: state.marketShareAnalysis.data,
    groupBy: state.marketShareAnalysis.groupBy,
    subjectName: state.marketShareAnalysis.subjectName,
    insightsViewQuery: state.insights.insights.currentViewConfig.query,
    viewName: state.insights.insights.currentViewConfig.name,
    insightsAiShowSidebar: state.insights.insights.insightsAiShowSidebar,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchMarketShareAnalysisData,
      marketShareAnalysisChangeGroupBy,
      marketShareAnalysisToggleShowHide,
      setSelectedSku,
      showInsightsAiSidebar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketShareAnalysisWrapper);
