import React, { useEffect, useState } from "react";
import styles from "./promotion-activities-table.scss";
import parentStyles from "../sku-promotions.scss";
import DataTable from "../../../../../components/data-table/data-table";
import * as Decorators from "../../../../../styles/cell-decorators";
import { LoaderWidths } from "../../../../../components/tables/simple-table/simple-table";
import PaginatedTable from "../../../../../components/tables/paginated-table/paginated-table";
import { sortTableData } from "../../../utils";
import MbRequestsErrors from "../../../../../mb_components/mb-requests-errors/mb-requests-errors";

const columnsConfig = [
  {
    id: -1,
    label: "Week",
    accessor: "currentDate",
    cellDecorator: Decorators.dateDecorator,
    loaderWidth: LoaderWidths.Small,
    sortMethod: "date",
  },
  {
    id: 0,
    label: "Number of days",
    accessor: "currentPromoDays",
    cellDecorator: Decorators.roundNumberDecorator,
    loaderWidth: LoaderWidths.Small,
  },
  {
    id: 1,
    label: "Sales Dollars",
    accessor: "currentPromoGmv",
    cellDecorator: Decorators.amountDollarsDecorator("gmv"),
    loaderWidth: LoaderWidths.Small,
  },
  {
    id: 2,
    label: "Units Sold",
    accessor: "currentPromoUnitsSold",
    cellDecorator: Decorators.roundNumberDecorator,
    loaderWidth: LoaderWidths.Small,
  },
  {
    id: 3,
    label: "Promo Price",
    accessor: "currentPromoPrice",
    cellDecorator: Decorators.amountDollarsDecorator("currentPromoPrice"),
    loaderWidth: LoaderWidths.Small,
  },
  // {
  //   id: 4,
  //   label: "Discount",
  //   accessor: "currentDiscount",
  //   cellDecorator: Decorators.roundPercentDecorator,
  //   loaderWidth: LoaderWidths.Small,
  // },
];

const DEFAULT_SORT_CONFIG = {
  field: 1,
  direction: 0,
};

const MAX_ROWS_IN_PAGE = 9;

const PromotionActivitiesTable = ({ data, isLoading }) => {
  const sortableColumns = [-1, 0, 1, 2, 3, 4];
  const [tableData, setTableData] = useState([]);
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);

  const prepareSortingValues = (newSelectedSortingColumn) => {
    if (sortConfig.field === newSelectedSortingColumn) {
      setSortConfig({
        field: sortConfig.field,
        direction: sortConfig.direction ? 0 : 1,
      });
    } else {
      setSortConfig({
        field: newSelectedSortingColumn,
        direction: 0,
      });
    }
  };

  const sortData = () => {
    const columnConfig = columnsConfig.find((x) => x.id === sortConfig.field);
    if (columnConfig) {
      let sorted = sortTableData(sortConfig, columnConfig, data);
      setTableData(sorted);
    }
  };

  useEffect(() => {
    if (data?.length > 0) sortData();
  }, [sortConfig, data]);

  useEffect(() => {
    setSortConfig(DEFAULT_SORT_CONFIG);
  }, [data]);

  const columnIds = columnsConfig.map((x) => x.id);

  const Subtitle = () => {
    return (
      <div
        style={{ margin: "8px 0px 16px 0px" }}
        className={parentStyles.subtitle}
      >
        Promotional activities
      </div>
    );
  };

  return (
    <div className={styles.promotionActivitiesTable}>
      {!isLoading && tableData.length === 0 ? (
        <>
          <Subtitle />
          <MbRequestsErrors errorType={"noDataError"} />
        </>
      ) : (
        <PaginatedTable tableData={tableData} maxRowsPerPage={MAX_ROWS_IN_PAGE}>
          {(pageData) => (
            <div>
              <Subtitle />
              <DataTable
                header={"Promotion Activities"}
                columnsConfig={columnsConfig}
                sorting={{
                  sortingColumn: sortConfig.field,
                  selectedSortingDirection: sortConfig.direction,
                }}
                hideColumnsDropdown={true}
                sortableColumns={sortableColumns}
                prepareSortingValues={prepareSortingValues}
                styles={styles}
                data={pageData}
                selectedColumns={columnIds}
                disableTooltipForColumns={columnIds}
                excludedTableColumns={[]}
                prepareDataForTable={(x) => x}
                isLoading={isLoading}
              />
            </div>
          )}
        </PaginatedTable>
      )}
    </div>
  );
};

export default PromotionActivitiesTable;
