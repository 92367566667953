import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SubspaceProvider } from "react-redux-subspace";
import InsightsTemplate from "../insights-template";
import InsightsSkusInfiniteTable from "../../containers/insights/insights-skus/insights-skus-infinite-table";
import {
  convertBrowserStringSortFieldToConfig,
  convertConfigSortFieldToBrowserString,
} from "../../utils/skus/convertSortValue";

import InsightsService from "../../services/insightsService";
const insightsService = new InsightsService();
import styles from "../insights-template/insights-template.scss";

class Insights extends Component {
  render() {
    const viewTypeData = { name: "insights", id: 0 };
    const search = this.props.location.search;
    const selectedColumns = [
      1,
      2,
      3,
      49,
      50,
      20,
      6,
      8,
      10,
      11,
      48,
      14,
      16,
      17,
      52,
      53,
      54,
      55,
      56,
      57,
      58,
      59,
    ];
    const excludedTableColumns = [
      4,
      5,
      9,
      12,
      13,
      21,
      22,
      23,
      24,
      25,
      26,
      27,
      28,
      29,
      30,
      31,
      32,
    ];

    const table = (
      <InsightsSkusInfiniteTable
        viewTypeId={viewTypeData.id}
        defaultSelectedColumns={selectedColumns}
        excludedTableColumns={excludedTableColumns}
      />
    );

    const dataControlsOptions = {
      viewTypeData,
      lastAvailableDate: this.props.dateRange?.endDate ?? null,
      apiProvider: insightsService,
      convertConfigSortFieldToBrowserString: convertConfigSortFieldToBrowserString,
    };

    const body = (
      <>
        <div className={styles.wrapper}>{table}</div>
      </>
    );

    return (
      <SubspaceProvider
        mapState={(state) => state.insights}
        namespace="insights"
      >
        <InsightsTemplate
          body={body}
          dataControlsOptions={dataControlsOptions}
          search={search}
          viewTypeData={viewTypeData}
          apiProvider={insightsService}
          convertBrowserStringSortFieldToConfig={
            convertBrowserStringSortFieldToConfig
          }
        />
      </SubspaceProvider>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    dateRange: state.insights.insights.dateRange,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Insights);
