import { getFilterConditionOptions } from "./filter-item/filter-item";
import {
  CollectionOptions,
  SingleValueCollectionOptions,
  NumericOptions,
  TextOptions,
} from "./filter-controls/condition-options";
import { isArray, uniqueId } from "lodash";
import {
  getEnumDisplayNameFromDefinition,
  getEnumValueNameFromDefinition,
} from "../../utils/other/enumUtils";
import { deepClone } from "../../utils/other/utilities";
import { UpdateFilterBySubjectTo } from "../../utils/filters/filtersUtils";

const getCollectionOptions = (filter) => {
  return filter.constraints?.SingleValue
    ? SingleValueCollectionOptions
    : CollectionOptions;
};

const getSelectedCondition = (filter, options) => {
  return filter.criterion == null
    ? options[0].value
    : options.find((x) => x.value === filter.criterion).value;
};

export const getInsightsFilterSelections = (
  insightFilter,
  filterDefinition
) => {
  if (insightFilter) {
    switch (insightFilter.type) {
      case "Numeric": {
        // Only in numeric type we use criteria, for historical reasons
        const criteria = insightFilter.criteria[0];
        return {
          selectedCondition: NumericOptions.find(
            (x) => x.value === criteria.criterion
          ).value,
          selectedProperties: {
            value1: criteria.value1,
            value2: criteria.value2,
          },
        };
      }
      case "Compound":
      case "Collection":
      case "CollectionDictionary": {
        const options = getCollectionOptions(filterDefinition);
        return {
          selectedCondition: getSelectedCondition(insightFilter, options),
          selectedProperties: filterDefinition.constraints?.SingleValue
            ? insightFilter.values[0]
            : insightFilter.values,
        };
      }
      case "Enum": {
        const options = getCollectionOptions(filterDefinition);
        return {
          selectedCondition: getSelectedCondition(insightFilter, options),
          selectedProperties: filterDefinition.constraints?.SingleValue
            ? getEnumDisplayNameFromDefinition(
                filterDefinition,
                insightFilter.values[0]
              )
            : insightFilter.values?.map((x) =>
                getEnumDisplayNameFromDefinition(filterDefinition, x)
              ),
        };
      }
      case "Text": {
        return {
          selectedCondition: getSelectedCondition(insightFilter, TextOptions),
          selectedProperties: insightFilter.values,
        };
      }
      case "Bool": {
        return {
          selectedProperties: insightFilter.value,
        };
      }
    }
  }
  return {};
};

export const mergeInsightsFilterWithFilterDefinition = (
  insightsFilter,
  filterDefinition,
  filterDefinitions
) => {
  const filter = {
    ...filterDefinition,
    childFilters: [],
    selectedCondition: getFilterConditionOptions(filterDefinition)[0].value,
    selectedProperties: filterDefinition?.values
      ? filterDefinition.values[0]
      : null,
    ...getInsightsFilterSelections(insightsFilter, filterDefinition),
    id: uniqueId(),
    value: insightsFilter?.value,
  };

  if (insightsFilter?.childAttributes) {
    filter.childFilters = insightsFilter.childAttributes.map(
      (childAttribute) => {
        const childFilterDefinition = filterDefinitions.find(
          (x) => x.mbName === childAttribute.mbName
        );
        return mergeInsightsFilterWithFilterDefinition(
          childAttribute,
          childFilterDefinition,
          filterDefinitions
        );
      }
    );
  }
  return filter;
};

export function ConvertInsightsFiltersToFilterSidebar(
  insightsFiltersCollection,
  filterDefinitions,
  anchoredFiltersDefinitions
) {
  let insightsFiltersCollectionToRender = deepClone(insightsFiltersCollection);

  // Populate anchored filters first
  const anchoredFilters = anchoredFiltersDefinitions?.map(
    (filterDefinition) => {
      const insightsFilter = insightsFiltersCollectionToRender.find(
        (x) => x.mbName === filterDefinition.mbName
      );
      if (insightsFilter) {
        // Filter out so we won't render it again
        insightsFiltersCollectionToRender = insightsFiltersCollectionToRender.filter(
          (x) => x.mbName !== insightsFilter.mbName
        );
      }
      return mergeInsightsFilterWithFilterDefinition(
        insightsFilter,
        filterDefinition,
        filterDefinitions
      );
    }
  );

  // Populate rest of filters
  const insightsFilters = insightsFiltersCollectionToRender
    ?.map((insightsFilter) => {
      const filterDefinition = filterDefinitions.find(
        (x) => x.mbName === insightsFilter.mbName
      );
      if (filterDefinition == null) return null;
      return mergeInsightsFilterWithFilterDefinition(
        insightsFilter,
        filterDefinition,
        filterDefinitions
      );
    })
    .filter((x) => x !== null);

  // update filters and filters children
  let allFilters = [...anchoredFilters, ...insightsFilters];
  allFilters = allFilters.map((filter) => {
    const updatedFilter = UpdateFilterBySubjectTo(
      filter,
      insightsFiltersCollection
    );

    updatedFilter.childFilters = updatedFilter?.childFilters?.map(
      (childAttribute) => {
        const childFilterUpdated = UpdateFilterBySubjectTo(
          deepClone(childAttribute),
          allFilters
        );
        if (
          childFilterUpdated.subjectTo?.length > 0 &&
          childFilterUpdated.subjectTo[0] === updatedFilter.mbName
        ) {
          childFilterUpdated.values =
            childFilterUpdated.values[updatedFilter.selectedProperties];
        }
        childFilterUpdated.values =
          childFilterUpdated.values[childFilterUpdated.value];
        return childFilterUpdated;
      }
    );
    return updatedFilter;
  });
  return allFilters;
}

export const hiddenFilter = (x) => x?.hide;

export const validateFilter = (f) =>
  f.selectedProperties !== null && f.selectedProperties !== undefined;

export function ConvertFilterSidebarToInsightsFilter(filters) {
  return filters?.filter(validateFilter).map((filter) => {
    let insightsFilter = convertFilterSidebarToInsightsFilter(filter);

    if (filter.childFilters) {
      insightsFilter.childAttributes = filter.childFilters.map((childFilter) =>
        convertFilterSidebarToInsightsFilter(childFilter)
      );
    }

    return insightsFilter;
  });
}

export function convertFilterSidebarToInsightsFilter(filter) {
  let insightsFilter = {
    displayName: filter.displayName,
    mbName: filter.mbName,
    type: filter.type,
    hasValue: true,
    value: filter.value,
  };

  switch (filter.type) {
    case "Numeric": {
      return {
        ...insightsFilter,
        values: isArray(filter.selectedProperties)
          ? filter.selectedProperties
          : [filter.selectedProperties],

        // Only in numeric type we use criteria, for historical reasons
        criteria: [
          {
            criterion: filter.selectedCondition,
            value1: filter.selectedProperties?.value1,
            value2: filter.selectedProperties?.value2,
          },
        ],
      };
    }
    case "Collection":
    case "CollectionDictionary":
    case "Compound":
    case "Text": {
      const values =
        filter.selectedProperties == null
          ? []
          : isArray(filter.selectedProperties)
          ? filter.selectedProperties
          : [filter.selectedProperties];
      return {
        ...insightsFilter,
        values: values,
        criterion: filter.selectedCondition,
      };
    }
    case "Enum": {
      const valuesBeforeConversion = isArray(filter.selectedProperties)
        ? filter.selectedProperties
        : [filter.selectedProperties];
      const values = valuesBeforeConversion.map((value) =>
        getEnumValueNameFromDefinition(filter, value)
      );

      return {
        ...insightsFilter,
        values: values,
        criterion: filter.selectedCondition,
      };
    }
    case "Bool": {
      return {
        ...insightsFilter,
        value: filter.selectedProperties,
      };
    }
  }

  return insightsFilter;
}
