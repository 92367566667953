import React from "react";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownBody,
} from "../../components/dropdown";
import styles from "../mb-filter-row/mb-filter-row.scss";

const { DropdownTitleSelectedItem } = dropdownTitle;
const { DropdownBodySingleSelect } = dropdownBody;

export const FrequencyOptions = [
  { id: 0, name: "Daily", value: "1d" },
  { id: 1, name: "Weekly", value: "7d" },
  { id: 2, name: "Monthly", value: "" },
];

function MbFrequencyDropdown(props) {
  const { selectedFrequencyId } = props;

  const selectedItem = FrequencyOptions.find(
    (option) => option.id === selectedFrequencyId
  );
  const dropdownTitle = (
    <DropdownTitleSelectedItem
      preTitle={"Frequency:"}
      value={selectedItem ? selectedItem.name : "Last 30 days"}
    />
  );

  return (
    <>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownBody={{
          component: DropdownBodySingleSelect,
          props: {
            options: FrequencyOptions,
            value: selectedItem?.value,
            radiobutton: true,
          },
          styles,
        }}
        handleClickItem={props.handleFrequencyChange}
        wrapDropdownBody={true}
        styles={styles}
        arrow={true}
        className="MbFrequencyDropdown"
      />
    </>
  );
}

export default MbFrequencyDropdown;
