import { isEmpty } from "lodash";
import React from "react";
import FilterDropdown from "../dropdowns/filter-dropdown";
import styles from "./filter-controls.scss";

function FilterControls(props) {
  const {
    item,
    onUpdateFilter,
    propertyFilterComponent,
    conditionOptions,
  } = props;

  const selectedCondition = conditionOptions.find(
    (x) => x.value === item.selectedCondition
  );

  const handleFilterConditionChange = (selectedOption) => {
    onUpdateFilter(item.id, {
      selectedCondition: selectedOption.value,
    });
  };

  const collectionControlsSelection = (
    <>
      <FilterDropdown
        selectedItemClassName={styles.filterConditionName}
        options={conditionOptions}
        selectedItem={selectedCondition}
        handleClickItem={handleFilterConditionChange}
      />
      {propertyFilterComponent}
    </>
  );

  return collectionControlsSelection;
}

export default FilterControls;
