import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InfiniteDataTable from "../../../../components/infinite-data-table/infinite-data-table";

import styles from "./users-infinite-table.scss";
import utils from "../../../../utils";

import UsersTable from "../users-table/users-table";

import {
  fetchUsersTableData,
  fetchAllPossibleCategories,
  fetchAllPossibleCompetitors,
  fetchAllPossibleScreens,
  changeUsersTableSorting,
  setSelectedUserData,
  toggleUpdateUser,
  showPopup,
  setCurrentViewIsUpdated,
} from "../../actions";

const {
  convertUsersTableSortValueToColumn,
  convertUsersTableSortValue2,
} = utils.administration;

function UsersInfiniteTable(props) {
  const {
    insightsFiltersCollection,
    sortField,
    sortOrder,
    currentFrequency,
    selectedColumns,
    excludedTableColumns,
    settings,
    currentViewConfigQuery,
    tableStatus,
    usersTableData,
    categories,
    screens,
    competitors,
  } = props;
  const { loading } = tableStatus;

  const [selectedColumnsIds, setSelectedColumnsIds] = useState(selectedColumns);
  const [sortedColumnReset, setSortedColumnReset] = useState(false);
  const [selectedSortingDirection, setSelectedSortingDirection] = useState(
    false
  );
  const [
    updatedCurrentViewConfigQuery,
    setUpdatedCurrentViewConfigQuery,
  ] = useState();

  const onSaveSelectedColumns = (selectedColumnsIds) => {
    setSelectedColumnsIds(selectedColumnsIds);
    props.setCurrentViewIsUpdated();
  };

  const requestedParams = {
    headersData: {
      groupBy: settings.dimension,
    },
    insightViews: currentViewConfigQuery,
  };

  const columnField = convertUsersTableSortValueToColumn(sortField);

  const prepareSortingValues = (newSelectedSortingColumn) => {
    let sortDirection = false;
    if (columnField === newSelectedSortingColumn && selectedSortingDirection) {
      setSelectedSortingDirection(sortDirection);
    } else if (columnField === newSelectedSortingColumn) {
      sortDirection = true;
      setSelectedSortingDirection(sortDirection);
    } else {
      setSelectedSortingDirection(sortDirection);
    }

    const sortingConfig = {
      sortField: convertUsersTableSortValue2(
        newSelectedSortingColumn,
        currentFrequency
      ), //ToDO Convert
      sortOrder: sortDirection ? 0 : 1,
    };

    props.changeUsersTableSorting(sortingConfig);
    props.setCurrentViewIsUpdated();
  };

  const handleFilterChange = () => {
    if (!sortedColumnReset) {
      currentViewConfigQuery.sortField = 0;
      props.changeUsersTableSorting(currentViewConfigQuery); // Liran: There must be a better way to tackle this!
      setSortedColumnReset(true);
    }

    if (shouldFetchData(requestedParams)) {
      requestedParams.insightViews.offset = 0;
      setUpdatedCurrentViewConfigQuery(requestedParams);
      props.fetchUsersTableData();
    }
  };

  const shouldFetchData = (newUpdatedCurrentViewConfigQuery) => {
    // If view-config is updated, or if date-range is updated
    return (
      newUpdatedCurrentViewConfigQuery.headersData.groupBy !== undefined &&
      JSON.stringify(newUpdatedCurrentViewConfigQuery) !==
        JSON.stringify(updatedCurrentViewConfigQuery)
    );
  };

  useEffect(() => {
    props.fetchUsersTableData();
    props.fetchAllPossibleCategories();
    props.fetchAllPossibleCompetitors();
    props.fetchAllPossibleScreens();
  }, []);

  const onSelectUser = (id) => {
    competitors.sort((a, b) => a.rootDomain?.localeCompare(b.rootDomain));
    const user = usersTableData[id];
    let selectedCompetitors = [],
      selectedScreens = [],
      selectedCategories = [];
    user.competitors.map((comp) => {
      competitors.map((c, i) => {
        if (c.rootDomain == comp) {
          selectedCompetitors.push(i);
        }
      });
    });
    if (user.screens.length > 0) {
      let userScreens = user.screens[0].replace(/\s+/g, "").split(",");
      screens.map((s, i) => {
        if (userScreens.includes(s)) {
          selectedScreens.push(i);
        }
      });
    }
    if (user.permittedCategories.length > 0) {
      categories.map((cat, i) => {
        user.permittedCategories.map((userCat) => {
          if (cat.replace(/\s+/g, "") == userCat.replace(/\s+/g, "")) {
            selectedCategories.push(i);
          }
        });
      });
    }
    const data = {
      email: user.userName,
      firstName: user.firstName,
      lastName: user.lastName,
      domain: user.rootDomain[0],
      selectedCategories: selectedCategories,
      selectedScreens: selectedScreens,
      selectedCompetitors: selectedCompetitors,
    };
    props.setSelectedUserData(data);
    props.toggleUpdateUser(true);
    props.showPopup();
  };

  useEffect(() => {
    handleFilterChange();
  }, [insightsFiltersCollection, sortField, sortOrder, settings]);

  const table = (
    <div id="insights_skus" className={styles.wrapper}>
      <UsersTable
        onSaveSelectedColumns={onSaveSelectedColumns}
        excludedTableColumns={excludedTableColumns}
        selectedColumns={selectedColumnsIds}
        frequency={props.currentFrequency}
        sorting={{
          sortingColumn: columnField,
          selectedSortingDirection: !sortOrder,
        }}
        prepareSortingValues={prepareSortingValues}
        productList={usersTableData}
        onSelectRow={onSelectUser}
      />
    </div>
  );

  return (
    <InfiniteDataTable
      table={table}
      dataLength={usersTableData.length}
      hasMore={false}
      fetchMoreData={() => {}}
      loading={loading}
      loadingMore={false}
    />
  );
}

export const mapStateToProps = (state) => {
  return {
    tableStatus: state.administration.administration.tableStatus,
    usersTableData: state.administration.administration.usersTableData,

    competitors: state.administration.administration.competitors,
    categories: state.administration.administration.categories,
    screens: state.administration.administration.screens,

    //ToDo should refactored
    currentViewConfig: state.administration.insights.insights.currentViewConfig,
    currentViewConfigQuery:
      state.administration.insights.insights.currentViewConfig.query,
    insightsFiltersCollection:
      state.administration.insights.insights.currentViewConfig.query
        .productAttributes,
    sortField:
      state.administration.insights.insights.currentViewConfig.query.sortField,
    sortOrder:
      state.administration.insights.insights.currentViewConfig.query.sortOrder,
    offset:
      state.administration.insights.insights.currentViewConfig.query.offset,
    settings:
      state.administration.insights.insights.currentViewConfig.query.settings,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setCurrentViewIsUpdated,

      fetchUsersTableData,
      fetchAllPossibleCategories,
      fetchAllPossibleCompetitors,
      fetchAllPossibleScreens,
      changeUsersTableSorting,

      setSelectedUserData,
      toggleUpdateUser,
      showPopup,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(UsersInfiniteTable);
