import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as Decorators from "../../../../../styles/cell-decorators";
import styles from "./search-terms-table.scss";

import _ from "lodash";
import DataTable from "../../../../../components/data-table/data-table";
import {
  STATUS_LOADING,
  STATUS_ERROR,
  STATUS_SUCCESS,
  SIMILAR_SKUS_RESULTS_SIZE,
  SIMILAR_SKUS_TABLE_SIZE,
  CURRENT_SKU_TABLE_ROW_BACKGROUND,
} from "../../../constants";
import { fetchSearchTermsData } from "../../../actions";
import { LoaderWidths } from "../../../../../components/tables/simple-table/simple-table";
import { sortTableData } from "../../../utils";
import MbRequestsErrors from "../../../../../mb_components/mb-requests-errors/mb-requests-errors";
import PaginatedTable from "../../../../../components/tables/paginated-table/paginated-table";

const DEFAULT_SORT_CONFIG = {
  field: 1,
  direction: 0,
};

function SearchTermsTable({
  selectedTerm,
  setSelectedTerm,
  selectedSku,
  fetchSearchTermsData,
  tableData,
  status,
  familyVariantsData,
  forceLoading = false,
  productAttributes,
  selectedCompetitor,
  selectedDateRange,
  currentViewConfigQuery,
  selectedPreviousDateRange,
  comparePeriod,
}) {
  const sortableColumns = [1, 2, 3, 4, 5, 6];
  const [sortConfig, setSortConfig] = useState(DEFAULT_SORT_CONFIG);
  const [sortedData, setSortedData] = useState([]);
  const defaultSearchTermSet = useRef(false);

  const columnsConfig = [
    {
      id: -99,
      label: "",
      accessor: "selected",
      cellDecorator: Decorators.radioDecorator(
        "searchTerm",
        selectedTerm,
        setSelectedTerm
      ),
      loaderWidth: LoaderWidths.Small,
    },
    {
      id: -1,
      label: "Search term",
      accessor: "searchTerm",
      cellDecorator: Decorators.noDecorator,
      loaderWidth: LoaderWidths.Large,
    },
    {
      id: 1,
      label: "Visibility Rate",
      accessor: "currentVisibility",
      cellDecorator: Decorators.percentDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 2,
      label: "Visibility Rate Diff",
      accessor: "visibilityChange",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 3,
      label: "Rank",
      accessor: "currentSerpRank",
      cellDecorator: Decorators.roundNumberDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 4,
      label: "Rank Diff",
      accessor: "serpRankChange",
      cellDecorator: Decorators.roundNumberChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 5,
      label: "Sponsored",
      accessor: "currentAdvertising",
      cellDecorator: Decorators.percentDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 6,
      label: "Sponsored Diff",
      accessor: "advertisingChange",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
  ];

  const fetchData = async () => {
    if (!familyVariantsData.selectedSku) return;

    const requestParams = {
      requestBody: {
        insightsViewQuery: {
          ...currentViewConfigQuery,
          startDate: selectedDateRange.startDate,
          endDate: selectedDateRange.endDate,
          previousStartDate: selectedPreviousDateRange.previousStartDate,
          previousEndDate: selectedPreviousDateRange.previousEndDate,
          comparePeriod: comparePeriod,
        },
        sku: selectedSku,
      },
    };

    fetchSearchTermsData(requestParams);
  };

  const requestParams = {
    requestBody: {
      insightsViewQuery: {
        ...currentViewConfigQuery,
        previousStartDate: selectedPreviousDateRange.previousStartDate,
        previousEndDate: selectedPreviousDateRange.previousEndDate,
        startDate: selectedDateRange.startDate,
        endDate: selectedDateRange.endDate,
        comparePeriod: comparePeriod,
      },
      sku: selectedSku,
      size: SIMILAR_SKUS_RESULTS_SIZE,
    },
  };

  const prepareSortingValues = (newSelectedSortingColumn) => {
    if (sortConfig.field === newSelectedSortingColumn) {
      setSortConfig({
        field: sortConfig.field,
        direction: sortConfig.direction ? 0 : 1,
      });
    } else {
      setSortConfig({
        field: newSelectedSortingColumn,
        direction: 0,
      });
    }
  };

  const handleFilterChange = () => {
    // Verify offset is reset to 0
    requestParams.requestBody.insightsViewQuery.offset = 0;
    if (!forceLoading) fetchData(requestParams);
  };

  useEffect(() => {
    handleFilterChange();
  }, [
    forceLoading,
    productAttributes,
    selectedDateRange,
    selectedCompetitor,
    comparePeriod,
    familyVariantsData,
  ]);

  const sortData = () => {
    const columnConfig = columnsConfig.find((x) => x.id === sortConfig.field);
    if (columnConfig) {
      let sorted = sortTableData(sortConfig, columnConfig, tableData);
      setSortedData(sorted);
    }
  };

  useEffect(() => {
    if (familyVariantsData?.variants?.length > 0 && tableData) sortData();
  }, [tableData, sortConfig]);

  useEffect(() => {
    if (!defaultSearchTermSet.current && sortedData?.length > 0) {
      defaultSearchTermSet.current = true;
      setSelectedTerm(sortedData[0].searchTerm);
    }
  }, [sortedData]);

  const allColumnIds = columnsConfig.map((x) => x.id);

  return status != STATUS_ERROR ? (
    <PaginatedTable tableData={sortedData} maxRowsPerPage={5}>
      {(pageData) => (
        <DataTable
          header={"Search Terms"}
          columnsConfig={columnsConfig}
          sorting={{
            sortingColumn: sortConfig.field,
            selectedSortingDirection: sortConfig.direction,
          }}
          hideColumnsDropdown={true}
          sortableColumns={sortableColumns}
          prepareSortingValues={prepareSortingValues}
          styles={styles}
          data={pageData}
          selectedColumns={allColumnIds}
          disableTooltipForColumns={allColumnIds}
          excludedTableColumns={[]}
          prepareDataForTable={(x) => x}
          onSelectRow={(i) => setSelectedTerm(pageData[i].searchTerm)}
          isLoading={status == STATUS_LOADING}
        />
      )}
    </PaginatedTable>
  ) : (
    <MbRequestsErrors errorType={"beError"} />
  );
}

export const mapStateToProps = (state) => {
  return {
    selectedCompetitor:
      state.product360.insights.mbFilterRow.selectedCompetitor,
    selectedDateRange: state.product360.insights.mbFilterRow.selectedDateRange,
    selectedPreviousDateRange:
      state.product360.insights.mbFilterRow.selectedPreviousDateRange,
    comparePeriod: state.product360.insights.mbFilterRow.comparePeriod,
    currentViewConfigQuery:
      state.product360.insights.insights.currentViewConfig.query,
    productAttributes:
      state.product360.insights.insights.currentViewConfig.query
        .productAttributes,
    familyVariantsData: state.product360.product360.familyVariants.data,
    tableData: state.product360.product360.searchTerms.data.tableData,
    status: state.product360.product360.searchTerms.status,
    selectedSku: state.product360.product360.selectedSku,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchSearchTermsData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(SearchTermsTable);
