import React from "react";
import SkuAnalysisCard from "../sku-analysis-card/sku-analysis-card";
import MarketShareAnalysisNoData from "../no-data";
import ScrollbarWrapper from "../../../../components/scrollbar-wrapper";
import styles from "./tab.scss";

export default function MarketShareAnalysisTab(props) {
  const { data, isLoading } = props;
  const hasData = data && data.length > 0;
  const dataBody = hasData
    ? data.map((x, i) => <SkuAnalysisCard {...props} key={i} data={x} />)
    : null;

  const body = isLoading ? (
    <SkuAnalysisCard isLoading={isLoading} />
  ) : hasData ? (
    data.length > 5 ? (
      <ScrollbarWrapper>
        <div className={styles.scroll}>{dataBody}</div>
      </ScrollbarWrapper>
    ) : (
      dataBody
    )
  ) : (
    <MarketShareAnalysisNoData />
  );

  return body;
}
