import React, { Component } from "react";
import { Link } from "react-router-dom";

import AuthWrapper from "../../../components/auth-wrapper";
import styles from "./reset-password-success.scss";
import utils from "../../../utils";

import icon from "../../../images/password-reset.png";

const { getToken } = utils.auth;

class ResetPasswordSuccess extends Component {
  componentDidMount() {
    if (getToken()) {
      this.props.history.push("/");
    }
  }

  render() {
    return (
      <AuthWrapper>
        <div className={styles.wrapper}>
          <h3 className={styles.header}>
            your password has been reset! go to login page to enter your
            dashboard.
          </h3>
          <div className={styles.imgCont}>
            <img src={icon} />
          </div>
          <p className={styles.info}>
            <Link to="/login">Go to login page</Link>
          </p>
        </div>
      </AuthWrapper>
    );
  }
}

export default ResetPasswordSuccess;
