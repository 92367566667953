import React from "react";

function DropdownFooterSingleButton(props) {
  const { title, onClickButton } = props;
  return (
    <>
      <button onClick={onClickButton}>{title}</button>
    </>
  );
}

export default DropdownFooterSingleButton;
