import React from "react";
import styles from "./contact-us-button.scss";
import Button from "../../../../../components/button";
import BinocularsIcon from "../../../../../images/binoculars-icon.svg";
import { SCREENS } from "../../../consts";

const ContactUsButton = ({ moveToScreen }) => {
  return (
    <div className={styles.contactUs}>
      <img src={BinocularsIcon} />
      <div className={styles.contactUsText}>
        <div>
          <b>Can't find what you're looking for?</b>
        </div>
        <div>
          Don't worry - just let us know, and we'll make it happen for you!
        </div>
      </div>
      <Button
        variant="outline"
        text="Contact us"
        active={true}
        onClick={() => moveToScreen(SCREENS.requestPreset)}
      />
    </div>
  );
};

export default ContactUsButton;
