import React from "react";
import styles from "./error-indicator.scss";

import icon from "../../images/error-visual.svg";

const refresh = () => {
  window.location.reload(true);
};

const ErrorIndicator = () => {
  return (
    <div className={styles.wrapper}>
      <img src={icon} className={styles.icon} />
      <p className={styles.header}>oops, look like something went wrong</p>
      <p className={styles.msg}>
        The page you requested could not be found. We suggest you{" "}
        <span onClick={refresh}>refresh</span> the page, or try again later.
      </p>
    </div>
  );
};

export default ErrorIndicator;
