const pathLogin = "login";

export const CHANGE_FIELD_LOGIN = `${pathLogin}/CHANGE_FIELD_LOGIN`;
export const CHANGE_FIELD_ERROR_STATUS_LOGIN = `${pathLogin}/CHANGE_FIELD_ERROR_STATUS_LOGIN`;
export const TRY_SUBMIT_LOGIN = `${pathLogin}/TRY_SUBMIT_LOGIN`;
export const LOGIN_REQUEST = `${pathLogin}/LOGIN_REQUEST`;
export const LOGIN_SUCCESS = `${pathLogin}/LOGIN_SUCCESS`;
export const LOGIN_FAILURE = `${pathLogin}/LOGIN_FAILURE`;

const pathForgotPassword = "forgot-password";

export const CHANGE_FIELD_FORGOT_PASS = `${pathForgotPassword}/CHANGE_FIELD_FORGOT_PASS`;
export const FORGOT_PASS_REQUEST = `${pathForgotPassword}/FORGOT_PASS_REQUEST`;
export const FORGOT_PASS_SUCCESS = `${pathForgotPassword}/FORGOT_PASS_SUCCESS`;
export const FORGOT_PASS_FAILURE = `${pathForgotPassword}/FORGOT_PASS_FAILURE`;

const pathResetPassword = "reset-password";

export const CHANGE_FIELD_RESET_PASS = `${pathResetPassword}/CHANGE_FIELD_RESET_PASS`;
export const CHANGE_FIELD_ERROR_STATUS_RESET_PASS = `${pathResetPassword}/CHANGE_FIELD_ERROR_STATUS_RESET_PASS`;
export const TRY_SUBMIT_RESET_PASS = `${pathResetPassword}/TRY_SUBMIT_RESET_PASS`;
export const RESET_PASS_REQUEST = `${pathResetPassword}/RESET_PASS_REQUEST`;
export const RESET_PASS_SUCCESS = `${pathResetPassword}/RESET_PASS_SUCCESS`;
export const RESET_PASS_FAILURE = `${pathResetPassword}/RESET_PASS_FAILURE`;

const pathLogout = "logout";

export const OPEN_LOGOUT_BLOCK = `${pathLogout}/OPEN_LOGOUT_BLOCK`;
export const CLOSE_LOGOUT_BLOCK = `${pathLogout}/CLOSE_LOGOUT_BLOCK`;
export const LOGOUT_REQUEST = `${pathLogout}/LOGOUT_REQUEST`;
export const LOGOUT_SUCCESS = `${pathLogout}/LOGOUT_SUCCESS`;
export const LOGOUT_FAILURE = `${pathLogout}/LOGOUT_FAILURE`;

export const AuthType = {
    "DEFAULT" : 0
}