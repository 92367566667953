import React from "react";
import * as Decorators from "../../../../styles/cell-decorators";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";
import TableComponent from "../../../../components/dashboard-components/table-component/table-component";

export default function SalesAndShareTableComponent(props) {
  const title = "Sales and Share";

  const columns = [
    {
      id: 1,
      label: "Account Name",
      accessor: "accountName",
      tooltip: "Customer Account",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 2,
      label: "Department",
      accessor: "department",
      tooltip: "Department Name. L1 or L2 in retailers' taxonomy.",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 3,
      label: "Category",
      accessor: "productType",
      tooltip: "Name of product category.",
      cellDecorator: Decorators.basicDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 4,
      label: "Total Category Sales",
      accessor: "totalCategorySales",
      tooltip: "Calculated sales $ for the entire category.",
      cellDecorator: Decorators.amountDollarsDecorator("sales"),
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 5,
      label: "Total Category Sales Diff",
      accessor: "totalCategorySalesDiff",
      tooltip:
        "Percent total category sales change between the current time period and the previous period listed above.",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 6,
      label: "Account Retail Sales Dollars",
      accessor: "accountRetailSalesDollars",
      tooltip:
        "Calculated Retail Sales $ that the retailer makes selling your products.",
      cellDecorator: Decorators.priceDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 7,
      label: "Account Retail Sales Dollars Diff",
      accessor: "accountRetailSalesDollarsDiff",
      tooltip:
        "Percent change in account retail sales dollars between the current time period and the previous period listed above",
      cellDecorator: Decorators.percentChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 8,
      label: "Market Share",
      accessor: "marketShare",
      tooltip:
        "Customer market share (Account Retail Sales Dollars / Total Category Sales)",
      cellDecorator: Decorators.percentDecorator,
      loaderWidth: LoaderWidths.Big,
    },
    {
      id: 9,
      label: "Market Share Diff (in BPS)",
      accessor: "marketShareDiff",
      tooltip:
        "The change in your market share from the previous period to the current period in BPS. One BPS, or Basis Point, represents a 0.01% change",
      cellDecorator: Decorators.bpsChangeDecorator,
      loaderWidth: LoaderWidths.Big,
    },
  ];

  return (
    <TableComponent
      {...props}
      title={title}
      isEnabled={true}
      columns={columns}
      sortingColumn={columns[0]}
      hideColumnsDropdown={true}
      csvName={title}
    />
  );
}
