import React, { useState, useRef, useEffect } from "react";
import ModalWrapper, {
  modalFooter,
} from "../../../../../../../components/modal";
import styles from "./save-view-modal.scss";

const { ModalFooterOneButton } = modalFooter;

function SaveViewModal(props) {
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(!props.viewName);

  const onChangeViewName = (event) => {
    const value = event.target.value;
    setSaveBtnDisabled(!value);
    props.onChangeViewName(value); //ToDO change to Redux
  };

  const modalHeader = (
    <>
      <div className={styles.logoBookmark}></div>
      <div className={styles.title}>Save this View</div>
    </>
  );

  const modalBody = (
    <>
      <label htmlFor="">View name</label>
      <input
        type="text"
        onChange={onChangeViewName}
        value={props.viewName}
        placeholder="Name your view"
      />
    </>
  );

  return (
    <>
      <ModalWrapper
        ref={props.modalWrapperRef}
        modalHeader={modalHeader}
        modalBody={modalBody}
        modalFooter={{
          component: ModalFooterOneButton,
          props: { buttonLabel: "Save", disabled: saveBtnDisabled, styles },
        }}
        handleClickConfirmButton={props.handleClickConfirmButton}
        styles={styles}
      />
    </>
  );
}

export default SaveViewModal;
