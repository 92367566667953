import Attention from "../../images/attention.svg";
import Info from "../../images/info.svg";
import React from "react";
import styles from "./mb-market-share-warning-container.scss";

function MbMarketShareWarningContainer(props) {
  const { currentEndDate, previousStartDate, maxDate, type } = props;

  const INSIGHTS_BOARD_DATA_START_DATE = new Date(2021, 6, 1);
  const minDate = props.minDate ?? INSIGHTS_BOARD_DATA_START_DATE;

  const attentionContainer = (msg, icon) => {
    return (
      <>
        <div className={styles.incompleteMarketShareData}>
          {icon && <img src={icon} />} {msg}
        </div>
      </>
    );
  };

  const dateType = () => {
    return currentEndDate && new Date(currentEndDate) > maxDate ? (
      attentionContainer(
        "Attention: The selected period includes incomplete market share data",
        Attention
      )
    ) : previousStartDate && new Date(previousStartDate) < minDate ? (
      attentionContainer(
        "Attention: The compared period includes incomplete market share data",
        Attention
      )
    ) : (
      <div></div>
    );
  };

  const segmentType = () => {
    return attentionContainer(
      "Please select 1 or more segments to analyze",
      Info
    );
  };

  return type == "date" ? dateType() : segmentType();
}

export default MbMarketShareWarningContainer;
