import React from "react";
import ModalWrapper, {
  modalFooter,
} from "../../../../../../../components/modal";
import styles from "./delete-view-modal.scss";

const { ModalFooterTwoButton } = modalFooter;

function DeleteViewModal(props) {
  const handleClickConfirmButton = () => {
    props.deleteViewConfig(props.viewForDelete);
  };

  const modalHeader = (
    <>
      <div className={styles.logoBookmark}></div>
      <div className={styles.title}>Delete this View</div>
    </>
  );

  const modalBody = (
    <>
      <div>{`Are you sure you want to delete view: "${props.deleteViewName}"?`}</div>
    </>
  );

  const modlaFooterProps = {
    confirmButtonLabel: "Delete View",
    cancelButtonLabel: "Cancel",
    styles,
  };

  return (
    <>
      <ModalWrapper
        modalIsVisible={true}
        modalHeader={modalHeader}
        modalBody={modalBody}
        modalFooter={{
          component: ModalFooterTwoButton,
          props: modlaFooterProps,
        }}
        handleClickConfirmButton={handleClickConfirmButton}
        handleClickCancelButton={props.onCancelRemoving}
        styles={styles}
      />
    </>
  );
}

export default DeleteViewModal;
