import React from "react";

import BrandPerformanceSearchTerm from "../search-term/brand-performance-search-term";

const BrandPerformanceTable = (props) => {
  const {
    data,
    removeSelectedSearchTermGraph,
    addSelectedSearchTermGraph,
    selectedSearchTermsGraphs,
  } = props;

  return (
    <>
      {data?.map((x, i) => (
        <BrandPerformanceSearchTerm
          key={i}
          data={x}
          selectedDisplay={selectedSearchTermsGraphs.includes(x.searchTerm)}
          addSelectedSearchTermGraph={addSelectedSearchTermGraph}
          removeSelectedSearchTermGraph={removeSelectedSearchTermGraph}
        />
      ))}
    </>
  );
};

export default BrandPerformanceTable;
