import React, { useState, useEffect } from "react";
import BarChartComponent from "../../../../../components/charts/visx/bar-chart-component";
import BarTooltipComponent from "../../../../../components/charts/visx/tooltips/bar-tooltip-component";
import { isUndefined } from "lodash";
import { BARS_COLORS } from "../../../constants";

const getPreviousDataKey = (k) => {
  return "previous" + k[0].toUpperCase() + k.slice(1);
};

const X_KEY = "name";

const MarketShareBarChartWrapper = ({
  id,
  chartData,
  selectedDateRange,
  selectedPreviousDateRange,
  entities = {},
  tabConfig,
  chartName,
  columnsConfig,
}) => {
  const [barChartProps, setBarChartProps] = useState({});

  useEffect(() => {
    const barDataKeys = {
      previous: {
        stroke: BARS_COLORS.previous,
        label: isUndefined(selectedPreviousDateRange)
          ? "Previous"
          : `Previous (${selectedPreviousDateRange.previousStartDate} - ${selectedPreviousDateRange.previousEndDate})`,
        displayName: "Previous",
      },
      current: {
        stroke: BARS_COLORS.current,
        label: isUndefined(selectedDateRange)
          ? "Current"
          : `Current (${selectedDateRange.startDate} - ${selectedDateRange.endDate})`,
        displayName: "Current",
      },
    };

    if (!tabConfig.isComparative) {
      delete barDataKeys["previous"];
    }

    const barChartRelevantData = chartData.filter((x) => entities[x[X_KEY]]);

    const barChartSelectedData = barChartRelevantData.map((x) => ({
      name: x[X_KEY],
      current: x[tabConfig.id],
      previous: x[getPreviousDataKey(tabConfig.id)] ?? 0,
      image: x.skuData?.image ?? "",
      fullName: x.skuData?.name ?? "",
    }));

    setBarChartProps({
      barChartRelevantData,
      barChartSelectedData,
      barDataKeys,
    });
  }, [tabConfig, chartData, entities]);

  const keyFormatter = (k) => {
    return entities[k] && entities[k].displayName;
  };

  const tooltipCallback = () => {
    return BarTooltipComponent({
      section: tabConfig.title,
      valueDecorator: tabConfig.decorator,
      fields: {
        current: { ...barChartProps.barDataKeys.current },
        previous: { ...barChartProps.barDataKeys.previous },
      },
      keyFormatter,
      xKey: X_KEY,
    });
  };

  return (
    <>
      {barChartProps.barChartSelectedData && chartData && (
        <BarChartComponent
          id={id}
          data={barChartProps.barChartSelectedData}
          dataKeys={barChartProps.barDataKeys}
          xTickFormat={keyFormatter}
          xKey={X_KEY}
          yTickFormat={tabConfig.yTickFormatter}
          tooltipCallback={tooltipCallback()}
          chartName={chartName}
          yFormatter={tabConfig.decorator}
          graphTitle={tabConfig.title}
          reverseKeys={true}
        />
      )}
    </>
  );
};

export default MarketShareBarChartWrapper;
