import React, { useState, useRef, useEffect } from "react";
import ModalWrapper, {
  modalFooter,
} from "../../../../../../../components/modal";

import AlertFrequencyDropdown from "../alert-frequency-dropdown/alert-frequency-dropdown.js";

import styles from "./save-alert-modal.scss";

const { ModalFooterTwoButton } = modalFooter;

const useEnterPress = (onEnterPress, deps) => {
  const onKeyPress = (e) => {
    if (e.keyCode === 13) onEnterPress();
  };

  useEffect(() => {
    document.addEventListener("keypress", onKeyPress);

    return () => {
      document.removeEventListener("keypress", onKeyPress);
    };
  }, [deps]);
};

function SaveViewModal(props) {
  const {
    alertForEdit: { id, emails, notificationFrequency },
    updateNotificationViewConfig,
    disableNotificationViewConfig,
    onCancelEditing,
    currentViewConfig,
    viewTypeData,
  } = props;
  const [emailsList, setEmailsList] = useState(emails || []);
  const [notification, setNotification] = useState(notificationFrequency);
  const [validEmail, setValidEmail] = useState(true);
  // const [qtyItems, setQtyItems] = useState(5);
  const [currentEmail, setCurrentEmail] = useState("");

  const CustomModalFooter = (props) => {
    const {
      cancelButtonLabel,
      confirmButtonLabel,
      onClickConfirmButton,
    } = props;
    const clickDisableNotification = () => {
      disableNotificationViewConfig(id);
    };

    return (
      <>
        <button
          className={`${styles.confirmButton}`}
          onClick={onClickConfirmButton}
        >
          {confirmButtonLabel}
        </button>
        <a
          className={`${styles.deactevateLink}`}
          onClick={clickDisableNotification}
        >
          {cancelButtonLabel}
        </a>
      </>
    );
  };

  const onChangeCurrentEmail = (event) => {
    const { value } = event.target;
    setCurrentEmail(value);
  };

  const addEmail = () => {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValidEmail = regEmail.test(currentEmail);

    if (!isValidEmail && currentEmail !== "") {
      setValidEmail(false);
      return;
    }

    setEmailsList([...emailsList, currentEmail]);
    setCurrentEmail("");
  };

  useEnterPress(addEmail, currentEmail);

  const removeEmailFromList = (value) => {
    const emails = emailsList.filter((item) => item !== value);
    setEmailsList(emails);
  };

  const handleClickConfirmButton = () => {
    setValidEmail(true);
    const uniqEmails = [...new Set(emailsList)];

    updateNotificationViewConfig(id, {
      emails: uniqEmails, //ToDo Remove hardcode ["eran@themarketbeyond.com", 'reut@themarketbeyond.com']
      notificationFrequency: notification,
    });
    onCancelEditing();
  };

  const handleClickCancelButton = () => {
    onCancelEditing();
  };

  const handleChangeNotificationFrequency = (value) => {
    setNotification(value.id);
  };

  // const onChangeQtyItems = ({value}) => {
  //   setQtyItems(value)
  // }

  const modalHeader = (
    <>
      <div className={styles.logoBookmark}></div>
      <div className={styles.title}>Set Alerts</div>
    </>
  );

  const modalBody = (
    <>
      <div className={styles.title}>{props.viewName}</div>
      <AlertFrequencyDropdown
        selectedItemId={notification}
        handleChangeNotificationFrequency={handleChangeNotificationFrequency}
        viewTypeData={viewTypeData}
      />
      {/* <AlertQtyItemsDropdown
      selectedItem={qtyItems}
      onChangeQtyItems={onChangeQtyItems}
    /> */}

      <div className={styles.emails}>
        <label htmlFor="">Alert email recipients</label>
        <div className={styles.emailsList}>
          {emailsList.map((item, idx) => (
            <div key={idx} className={styles.email}>
              <div>{item}</div>
              <div
                className={styles.removeIcon}
                onClick={() => removeEmailFromList(item)}
              >
                -
              </div>
            </div>
          ))}
        </div>
        <div
          className={`${styles.formGroup} ${!validEmail ? styles.error : ""}`}
        >
          <input
            type="email"
            onChange={onChangeCurrentEmail}
            value={currentEmail}
            placeholder=""
          />
          <p className={styles.errorMsg}>Invalid Email Address</p>
        </div>
        {currentEmail && (
          <div className={styles.addAnotherEmail} onClick={addEmail}>
            + Add email address
          </div>
        )}
      </div>
    </>
  );

  let modalFooter;

  if (id && emailsList.length) {
    modalFooter = {
      component: CustomModalFooter,
      props: {
        cancelButtonLabel: "Deactivate this alert",
        confirmButtonLabel: "Done",
        styles,
      },
    };
  } else {
    modalFooter = {
      component: ModalFooterTwoButton,
      props: {
        cancelButtonLabel: "Cancel",
        confirmButtonLabel: "Create Alert",
        confirmButtonDisabled: true,
        styles,
      },
    };
  }

  return (
    <>
      <ModalWrapper
        ref={props.modalWrapperRef}
        modalHeader={modalHeader}
        modalBody={modalBody}
        modalFooter={modalFooter}
        handleClickConfirmButton={handleClickConfirmButton}
        handleClickCancelButton={handleClickCancelButton}
        modalIsVisible={true}
        styles={styles}
        className={id && emailsList.length && "updateMode"}
        useImperative={false}
      />
    </>
  );
}

export default SaveViewModal;
