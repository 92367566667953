import React from "react";
import styles from "../sku-analysis-card.scss";
import {
  decorateValue,
  percentChangeDecorator,
  percentDecorator,
} from "../../../../../styles/cell-decorators";

export default function SkuAnalysisSalesDollarsShare(props) {
  const { data = {} } = props;

  const { currentSalesDollarsShare, salesDollarsShareChange } = data;

  return (
    <>
      <div className={styles.salesDollarsShare}>
        Sales Dollars Share
        <div className={styles.salesDollarsShareData}>
          <div className={styles.salesDollarsShareCurrent}>
            {decorateValue(currentSalesDollarsShare, percentDecorator)}
          </div>
          <div className={styles.salesDollarsShareChange}>
            {decorateValue(salesDollarsShareChange, percentChangeDecorator)}
          </div>
        </div>
      </div>
    </>
  );
}
