import React, { useState } from "react";
import styles from "./trigger-definitions.scss";
import { SCREENS } from "../../consts";
import EventTypeField from "./fields/event-type/event-type";
import HeaderContainer from "../../components/header-container";
import FullHeading from "../../components/full-heading/full-heading";
import Recipients from "./fields/recipients/recipients";
import AlertFrequency from "./fields/alert-frequency/alert-frequency";
import Button from "../../../../components/button";
import Switch from "../../../../components/switch/switch";
import {
  saveInsightsViewConfig,
  updateInsightsViewConfig,
} from "../../../../containers/insights/insights-data-controls/actions";
import { NOTIFICATION_FREQUENCY } from "../../../../utils/other/constants";
import {
  getDateRangeInfo,
  getPreviousDates,
} from "../../../../utils/other/dates";
import { COMPARE_PERIODS } from "../../../../components/mb-compare-period/constants";
import _ from "lodash";
import { getSelectedEventType } from "../../utils";
import Spinner from "../../../../components/spinner/spinner";
import LoadingCover from "../../components/loading-cover/loading-cover";
import { analyticsTriggerCreated } from "../../../../containers/shared/analytics/actions";

const TriggerDefinition = ({
  moveToScreen,
  formData,
  setFormData,
  configData,
  setConfigData,
  sendToast,
  resetScreensHistory,
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const { notificationFrequency } = formData;
  const alertEnabled = notificationFrequency != NOTIFICATION_FREQUENCY.none;

  const changeAlertFrequency = (frequency) => {
    setFormData({
      ...formData,
      notificationFrequency: frequency,
    });
  };

  const validateView = () => {
    if (configData.mode == "new" && formData.name.length === 0) {
      sendToast("Trigger name is required", "error");
      return false;
    }
    if (!getSelectedEventType(formData.query.productAttributes)) {
      sendToast("Please select an event to track for", "error");
      return false;
    }
    if (alertEnabled && !(formData.emails?.length > 0)) {
      sendToast("An enabled alert requires at least one email", "error");
      return false;
    }
    return true;
  };

  const onSubmit = () => {
    if (!validateView()) return;

    setLoadingSubmit(true);
    const dateConfig = getDateRangeInfo(new Date(), 3);
    const prevDates = getPreviousDates(
      { startDate: dateConfig.startDate, endDate: dateConfig.endDate },
      COMPARE_PERIODS.previousPeriod
    );

    if (configData.mode == "new") {
      configData.subspace
        .dispatch(
          saveInsightsViewConfig(
            configData.apiProvider,
            formData.name,
            formData.query,
            configData.viewTypeData,
            dateConfig.startDate,
            dateConfig.endDate,
            3,
            prevDates,
            0,
            formData.emails,
            formData.notificationFrequency
          )
        )
        .then((res) => {
          onSubmitSuccess(res.data, `Created trigger "${formData.name}"`);
        })
        .catch((err) => onSubmitError());
    } else {
      configData.subspace
        .dispatch(updateInsightsViewConfig(configData.apiProvider, formData))
        .then((res) => {
          onSubmitSuccess(formData, `Modified trigger "${formData.name}"`);
        })
        .catch((err) => {
          onSubmitError();
        });
    }
  };

  const onSubmitSuccess = (submittedView, msg) => {
    const newViews = configData.views.slice(0);
    if (configData.mode == "new") {
      newViews.push(submittedView);
    } else {
      const i = newViews.findIndex((x) => x.id == submittedView.id);
      newViews[i] = submittedView;
    }
    setConfigData({
      ...configData,
      views: newViews,
    });
    sendToast(msg, "success");
    moveToScreen(SCREENS.triggerList);
    resetScreensHistory();
    analyticsTriggerCreated(
      configData.viewTypeData.id,
      configData.eventProps.title
    );
  };

  const onSubmitError = (err) => {
    sendToast("An error occurred", "error");
    setLoadingSubmit(false);
  };

  const getSubmitText = () => {
    if (configData.mode == "new") {
      if (loadingSubmit) return "Creating...";
      return "Create";
    }
    if (loadingSubmit) return "Updating...";
    return "Update";
  };

  return (
    <div style={{ position: "relative" }}>
      <HeaderContainer>
        <FullHeading configData={configData} formData={formData} />
      </HeaderContainer>
      <div className={styles.triggerDefinitions}>
        <div className={styles.viewNameContainer}>
          <div className={styles.configSectionTitle}>Trigger Name</div>
          {configData.mode == "new" ? (
            <input
              className={styles.nameInput}
              placeholder={"Name this trigger"}
              value={formData.name}
              onChange={(e) =>
                setFormData({ ...formData, name: e.target.value })
              }
            />
          ) : (
            <h3 className={styles.viewName}>{formData.name}</h3>
          )}
        </div>
        <div>
          <div className={styles.configSectionTitle}>Send notification</div>
          <div className={styles.configSectionContainer}>
            <div style={{ position: "absolute", zIndex: 11, right: "64px" }}>
              <Switch
                toggled={alertEnabled}
                onChange={(toggled) =>
                  toggled
                    ? changeAlertFrequency(NOTIFICATION_FREQUENCY.hour)
                    : changeAlertFrequency(NOTIFICATION_FREQUENCY.none)
                }
              />
            </div>
            <div style={alertEnabled ? {} : { opacity: 0.5 }}>
              {/* <AlertFrequency
                selected={notificationFrequency}
                onSelect={changeAlertFrequency}
                disabled={!alertEnabled}
              /> */}
              <Recipients
                viewConfig={formData}
                setViewConfig={setFormData}
                disabled={!alertEnabled}
              />
            </div>
          </div>
        </div>
        <div style={{ width: "100px", margin: "0px auto" }}>
          <Button
            variant={"contained"}
            disabled={loadingSubmit}
            onClick={() => onSubmit()}
            text={getSubmitText()}
          />
        </div>
      </div>
      {loadingSubmit && <LoadingCover />}
    </div>
  );
};

export default TriggerDefinition;
