import React from "react";
import styles from "./brand-performance-search-term-config.scss";

import * as Decorators from "../../../../styles/cell-decorators";
import BrandPerformanceSearchTermButton from "./brand-performance-search-term-button";
import BrandPerformanceLineCharts from "../linecharts/brand-performance-linecharts";

export const SearchTermPanelComponentConfig = (props) => {
  return {
    separator: {
      type: "separator",
      style: styles.separator,
    },
    columnsSeparator: {
      type: "separator",
      style: styles.columnsSeparator,
    },
    skusCount: {
      type: "data",
      className: styles.cell,
      title: "# of PDPs",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "skusCount",
      valueDecorator: Decorators.numberDecorator,
      diffAccessor: "skusCountChange",
      diffDecorator: Decorators.roundNumberChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    inStockRates: {
      type: "data",
      className: styles.cell,
      title: "In stock rates",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "inStockRate",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "inStockRateChange",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    averageSellingPrice: {
      type: "data",
      className: styles.cell,
      title: "Average selling price",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "averageSellingPrice",
      valueDecorator: Decorators.priceDecorator,
      diffAccessor: "averageSellingPriceChange",
      diffDecorator: Decorators.numberChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfPromotion: {
      type: "data",
      className: styles.cell,
      title: "Share of promotion",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfPromotion",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfPromoChange",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfReviews: {
      type: "data",
      className: styles.cell,
      title: "Share of reviews",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfReviews",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "reviewsChange",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfSponsored: {
      type: "data",
      className: styles.cell,
      title: "Share of sponsored",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfSponsored",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfSponsoredChange",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfPage1: {
      type: "data",
      className: styles.cell,
      title: "Share of page 1",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfPage1",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfInPage1Change",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfTop1: {
      type: "data",
      className: styles.cell,
      title: "Top 1",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfTop1",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfTop1Change",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfTop3: {
      type: "data",
      className: styles.cell,
      title: "Top 3",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfTop3",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfTop3Change",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfTop5: {
      type: "data",
      className: styles.cell,
      title: "Top 5",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfTop5",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfTop5Change",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfTop10: {
      type: "data",
      className: styles.cell,
      title: "Top 10",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfTop10",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfTop10Change",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    shareOfTop20: {
      type: "data",
      className: styles.cell,
      title: "Top 20",
      titleStyle: styles.titleStyle,
      titleContainer: styles.titleContainer,
      taglineAccessor: null,
      valueAccessor: "shareOfTop20",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "shareOfTop20Change",
      diffDecorator: Decorators.percentChangeDecorator,
      diffStyle: styles.diffValue,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    button: {
      type: "custom",
      component: BrandPerformanceSearchTermButton,
      className: styles.buttonCell,
      ...props,
    },
    graphs: {
      type: "custom",
      component: BrandPerformanceLineCharts,
      className: styles.graphs,
    },
  };
};

export const SearchTermPanelComponentDesignPlan = (props) => {
  const { searchTerm, rank } = props.data;
  const panelConfig = SearchTermPanelComponentConfig(props);
  return {
    rows: [
      {
        className: styles.frame,
        columns: [
          {
            className: styles.fill,
            title: searchTerm,
            titleClassName: styles.title,
            rows: [
              {
                rows: [
                  {
                    className: styles.fill,
                    columns: [
                      {
                        className: styles.fill,
                        title: `Search rank #${rank}`,
                        titleClassName: styles.subtitleBold,
                        rows: [
                          {
                            className: styles.fill,
                            cells: [
                              panelConfig.skusCount,
                              panelConfig.separator,
                              panelConfig.inStockRates,
                              panelConfig.separator,
                              panelConfig.averageSellingPrice,
                              panelConfig.separator,
                              panelConfig.shareOfPromotion,
                              panelConfig.separator,
                              panelConfig.shareOfReviews,
                              panelConfig.separator,
                              panelConfig.shareOfSponsored,
                              panelConfig.separator,
                              panelConfig.shareOfPage1,
                              panelConfig.separator,
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    columns: [
                      {
                        title: "Share of Top Ranks",
                        titleClassName: styles.subtitle,
                        rows: [
                          {
                            cells: [
                              panelConfig.shareOfTop1,
                              panelConfig.shareOfTop3,
                              panelConfig.shareOfTop5,
                              panelConfig.shareOfTop10,
                              panelConfig.shareOfTop20,
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            cells: [panelConfig.button],
          },
        ],
      },
    ],
  };
};

export const SearchTermPanelComponentWithGraphsDesignPlan = (props) => {
  const panelConfig = SearchTermPanelComponentConfig(props);
  let designPlan = SearchTermPanelComponentDesignPlan(props);
  return {
    ...designPlan,
    rows: [
      ...designPlan.rows,
      {
        className: styles.frame,
        cells: [panelConfig.graphs],
      },
    ],
  };
};
