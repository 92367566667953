import React from "react";
import styles from "./switch.scss";

const Switch = ({ toggled, onChange, titleRight, titleLeft }) => {
  return (
    <div className={styles.switchWrapper} onClick={() => onChange(!toggled)}>
      {titleLeft && <div className={styles.switchTitle}>{titleLeft}</div>}
      <div className={toggled ? styles.switchOn : styles.switchOff}></div>
      {titleRight && <div className={styles.switchTitle}>{titleRight}</div>}
    </div>
  );
};

export default Switch;
