import React from "react";
import { chartTickPriceFormat } from "../../../../styles/cell-decorators";
import ChartComponent from "../../../../components/dashboard-components/chart-component/chart-component";

export default function CustomerTotalRevenueChartComponent(props) {
  return (
    <ChartComponent
      {...props}
      title={"Your Total Sales Dollars"}
      currentKey={"currentTotalRevenue"}
      previousKey={"previousTotalRevenue"}
      changeKey={"revenueChange"}
      taglineSubject={"Your total sales dollars"}
      legendName={"Total sales dollars"}
      yTickFormat={chartTickPriceFormat}
    />
  );
}
