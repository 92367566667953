export const forceSingleRetailerSelection = (
  filterDefinitions,
  filtersCollection
) => {
  const retailerFilterDefinition = filterDefinitions.find(
    (filterDefinition) => filterDefinition.displayName === "Retailer"
  );

  if (filtersCollection && filtersCollection.length > 0) {
    const retailerProductAttribute = filtersCollection.find(
      (productAttribute) =>
        productAttribute.displayName === "Retailer" ||
        productAttribute.mbName === "rootdomain.keyword"
    );
    if (retailerProductAttribute && retailerFilterDefinition) {
      if (retailerFilterDefinition.constraints?.SingleValue)
        return retailerFilterDefinition.enumDictionary[
          retailerProductAttribute.values[0]
        ];
      return retailerProductAttribute.values.map(
        (x) => retailerFilterDefinition.enumDictionary[x]
      );
    }
  }
};
