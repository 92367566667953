import {
  CHANGE_FIELD_FORGOT_PASS,
  FORGOT_PASS_REQUEST,
  FORGOT_PASS_SUCCESS,
  FORGOT_PASS_FAILURE,
} from "../constants";

import AuthService from "../../../services/authService";

import history from "../../../history";

const authService = new AuthService();

export const changeField = (field, value) => ({
  type: CHANGE_FIELD_FORGOT_PASS,
  payload: { field, value },
});

export const trySubmitted = () => ({
  type: CHANGE_FIELD_FORGOT_PASS,
  payload: {
    field: "isTrySubmit",
    value: true,
  },
});

export const formRequested = () => ({
  type: FORGOT_PASS_REQUEST,
});

export const formSuccessed = () => ({
  type: FORGOT_PASS_SUCCESS,
});

export const formError = (error) => ({
  type: FORGOT_PASS_FAILURE,
  payload: { error },
});

export const sendForm = (data) => (dispatch) => {
  dispatch(formRequested());

  authService
    .requestForgotPassword(data)
    .then((res) => {
      history.push("/forgot-password-success");
      dispatch(formSuccessed());
    })
    .catch((err) => {
      // Good-571: we decided to show success screen anyhow, so intruders won't be able to understand if user exists or not.
      // dispatch(formError(err.response.data))
      history.push("/forgot-password-success");
      dispatch(formSuccessed());
    });
};
