import React from "react";
import ContentLoader from "react-content-loader";

const MarketShareAnalysisSkuLoader = ({ size, speed = 2 }) => {
  return (
    <>
      <ContentLoader speed={speed}>
        <rect x="10" width="275" height="15" fill="#EEEFF1" />
        <rect x="10" y="25" width="275" height="15" fill="#EEEFF1" />
        <rect x="10" y="50" width="108" height="10" fill="#EEEFF1" />
        <rect x="10" y="70" width="50" height="10" fill="#EEEFF1" />
        <rect x="10" y="85" width="50" height="10" fill="#EEEFF1" />
        <rect x="10" y="100" width="50" height="10" fill="#EEEFF1" />
      </ContentLoader>
    </>
  );
};

export default MarketShareAnalysisSkuLoader;
