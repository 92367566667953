import React, { useEffect } from "react";
import { useState } from "react";
import LineChartComponent from "../../../../../components/charts/visx/line-chart-component";
import LineTooltipComponent from "../../../../../components/charts/visx/tooltips/line-tooltip-component";

import {
  decorateValue,
  amountDollarsDecorator,
} from "../../../../../styles/cell-decorators";
const defaultCellDecorator = amountDollarsDecorator("");

const useFilter = (data, tabConfig, entities) => {
  const [filtered, setFiltered] = useState({
    data: [],
    entities: {},
  });

  const getLineChartRelevantData = () => {
    const filteredData = [];
    const filteredEntities = {};
    for (const entityKey of Object.keys(entities)) {
      filteredEntities[entityKey] = {
        stroke: entities[entityKey].stroke,
        name: entities[entityKey].name,
        displayName: entities[entityKey].displayName,
        image: entities[entityKey].image,
      };
      for (let i = 0; i < data[entityKey].length; i++) {
        const value = data[entityKey][i][tabConfig.id];
        if (!filteredData[i]) {
          filteredData.push({ date: data[entityKey][i].timestamp });
        }
        filteredData[i] = {
          ...filteredData[i],
          [entityKey]: value,
        };
      }
    }

    setFiltered({
      data: filteredData,
      entities: filteredEntities,
    });
  };

  useEffect(() => {
    if (data && tabConfig) getLineChartRelevantData();
  }, [data, tabConfig, entities]);

  return filtered;
};

const MarketShareLineChartWrapper = ({
  id,
  chartData,
  entities,
  tabConfig,
  chartName,
  barChartData,
  columnsConfig,
  highlightKey,
}) => {
  const filtered = useFilter(chartData, tabConfig, entities);

  const lineChartXKey = "date";
  const graphTitle = tabConfig.title;
  const dataKeys = filtered.entities;

  const keyFormatter = (k) => {
    return entities[k] && entities[k].displayName;
  };

  const tooltipCallback = () => {
    return LineTooltipComponent({
      section: tabConfig.title,
      valueDecorator: tabConfig.decorator,
      entities: filtered.entities,
      keyFormatter: keyFormatter,
      xKey: lineChartXKey,
    });
  };

  const getSelectedTabValue = (key) => {
    if (columnsConfig.length === 0) {
      return null;
    }
    const columnConfig = columnsConfig.filter(
      (item) => item.tabName === graphTitle
    )[0];
    const entry = barChartData.filter((item) => item.name === key)[0];
    let val;
    if (columnConfig) {
      val = decorateValue(
        entry[columnConfig.accessor],
        columnConfig.cellDecorator
      );
    } else {
      val = entry["gmv"] / entry["sales"];
      val = decorateValue(val, defaultCellDecorator);
    }
    return val;
  };

  const legendLabelProps = Object.keys(dataKeys).map((x) =>
    Object.assign(
      {},
      {
        stroke: dataKeys[x]["stroke"],
        displayName: keyFormatter(x),
        strokeDasharray: "0",
        name: dataKeys[x].name,
        image: dataKeys[x].image,
        graphTitle: graphTitle,
        totalValue: getSelectedTabValue(x),
      }
    )
  );

  return (
    <LineChartComponent
      id={id}
      data={filtered.data}
      dataKeys={filtered.entities}
      xKey={lineChartXKey}
      yTickFormat={tabConfig.yTickFormatter}
      xNumTicks={Math.min(20, filtered.data.length)}
      tooltipCallback={tooltipCallback()}
      legendLabelProps={legendLabelProps}
      chartName={chartName}
      columnsConfig={columnsConfig}
      highlightKey={highlightKey}
    />
  );
};

export default MarketShareLineChartWrapper;
