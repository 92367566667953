import { extendMoment } from "moment-range";
import Moment from "moment";
import { COMPARE_PERIODS } from "../components/mb-compare-period/constants";

const moment = extendMoment(Moment);

export default class DatePickerService {
  onClickThisWeek(maxMoment) {
    var lastMonday = maxMoment.clone().startOf("isoWeek").format("YYYY/MM/DD");
    return {
      startDate: lastMonday,
      endDate: maxMoment.format("YYYY/MM/DD"),
    };
  }

  onClickToday(maxMoment) {
    return {
      startDate: maxMoment.format("YYYY/MM/DD"),
      endDate: maxMoment.format("YYYY/MM/DD"),
    };
  }

  onClickYesterday(maxMoment) {
    var yesterday = maxMoment
      .clone()
      .subtract(1, "days")
      .endOf("days")
      .format("YYYY/MM/DD");
    return {
      startDate: yesterday,
      endDate: yesterday,
    };
  }

  onClickLastMonth(maxMoment) {
    var dateFrom = maxMoment
      .clone()
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY/MM/DD");
    var dateTo = maxMoment
      .clone()
      .subtract(1, "month")
      .endOf("month")
      .format("YYYY/MM/DD");
    return {
      startDate: dateFrom,
      endDate: dateTo,
    };
  }

  onClickThisQuarter(maxMoment) {
    var quarterStart = maxMoment
      .clone()
      .quarter(maxMoment.clone().quarter())
      .startOf("quarter");
    return {
      startDate: quarterStart.format("YYYY/MM/DD"),
      endDate: maxMoment.format("YYYY/MM/DD"),
    };
  }

  onClickLastQuarter(maxMoment) {
    var currentQuarter = maxMoment.clone().quarter();
    // Current quarter = 1 => move to last year's last quarter, otherwise stays on the year and takes the correct quarter.
    var lastQuarter = currentQuarter == 1 ? 4 : currentQuarter - 1;
    var year = lastQuarter == 4 ? maxMoment.year() - 1 : maxMoment.year();
    var quarterStart = moment(year, "YYYY")
      .quarter(lastQuarter)
      .startOf("quarter");
    var quarterEnd = moment(year, "YYYY").quarter(lastQuarter).endOf("quarter");
    return {
      startDate: quarterStart,
      endDate: quarterEnd,
    };
  }

  onClickThisYear(maxMoment) {
    var startDate = maxMoment.clone().startOf("year").format("YYYY/MM/DD");
    return {
      startDate: startDate,
      endDate: maxMoment.format("YYYY/MM/DD"),
    };
  }

  onClickLastYear(maxMoment) {
    const year = maxMoment.year() - 1;
    var startOfPrevYear = moment(year, "YYYY")
      .startOf("year")
      .format("YYYY/MM/DD");
    var endOfPrevYear = moment(year, "YYYY").endOf("year").format("YYYY/MM/DD");
    return {
      startDate: startOfPrevYear,
      endDate: endOfPrevYear,
    };
  }

  onClickLastXWeeks(numOfWeeks, maxMoment) {
    var lastMonday, lastSunday;
    if (maxMoment.weekday() == 0) {
      lastSunday = maxMoment.clone();
      lastMonday = maxMoment.clone().startOf("isoWeek");
      numOfWeeks -= 1;
    } else {
      lastMonday = maxMoment.clone().startOf("isoWeek");
      lastSunday = lastMonday.clone().subtract(1, "days");
    }
    return {
      startDate: lastMonday.subtract(numOfWeeks, "weeks").format("YYYY/MM/DD"),
      endDate: lastSunday.format("YYYY/MM/DD"),
    };
  }

  onClickLastXDays(numOfDays, maxMoment) {
    var start = maxMoment
      .clone()
      .subtract(numOfDays - 1, "days")
      .endOf("days")
      .format("YYYY/MM/DD");
    return {
      startDate: start,
      endDate: maxMoment.format("YYYY/MM/DD"),
    };
  }

  onClickThisMonth(maxMoment) {
    var monthStart = maxMoment.clone().startOf("month").format("YYYY/MM/DD");
    return {
      startDate: monthStart,
      endDate: maxMoment.format("YYYY/MM/DD"),
    };
  }

  onClickYTD(maxMoment, mode) {
    var yearStart =
      mode && mode == "weekly"
        ? this.getFirstMondayOfYear(maxMoment.year())
        : maxMoment.clone().startOf("year").format("YYYY/MM/DD");
    return {
      startDate: yearStart,
      endDate: maxMoment.format("YYYY/MM/DD"),
    };
  }

  getFirstMondayOfYear(year) {
    var startOfYear = moment().year(year).startOf("year");
    return this.getCertainDayOfWeekByDate(startOfYear.format("YYYY/MM/DD"), 1);
    // TODO -  Might be an issue if January 1st is on Tuesday.
  }

  getWeekNumberByDate(dateStr) {
    /* Returns the number of week (in a year) that given date string is part of. 
    IMPORTANT: If 0 is returned, it means week #52 of previous year. */
    var date = moment(dateStr, "YYYY/MM/DD");
    var firstMondayOfYear = this.getFirstMondayOfYear(date.year());
    var differenceInDays = date.diff(firstMondayOfYear, "days");
    return Math.floor(differenceInDays / 7) + 1;
  }

  getCertainDayOfWeekByDate(dateStr, dayOfWeek) {
    // dayOfWeek: Sunday = 0, Monday = 1, ..., Saturday = 6
    // Returns the actual date of the week that datestr is part of (week starts on Monday and ends on Sunday)
    var date = moment(dateStr, "YYYY/MM/DD");
    var day = date.day();
    var diffDays = 0;
    if (day > dayOfWeek) {
      diffDays = 7 - (day - dayOfWeek);
    } else {
      diffDays = dayOfWeek - day;
    }
    return date.add(diffDays, "days").format("YYYY/MM/DD");
  }

  getMondayByWeekNumYear(weekNum, year) {
    //returns a date string of the beginning of a given week number in a given year.
    var monday = moment(this.getFirstMondayOfYear(year));
    return monday.add(weekNum - 1, "weeks").format("YYYY/MM/DD");
  }

  getSundayByWeekNumYear(weekNum, year) {
    var monday = moment(this.getFirstMondayOfYear(year));
    monday = monday.add(weekNum, "weeks");
    return monday.subtract(1, "days").format("YYYY/MM/DD");
  }

  getPreviousPeriodDates(selectedDateRange) {
    // returns previous start and end dates (as strings) in accordance with the difference in the dates of selectedDateRange.
    const { startDate, endDate } = selectedDateRange;
    var startDatemoment = moment(startDate, "YYYY/MM/DD");
    var daysDiff = moment(endDate, "YYYY/MM/DD").diff(startDatemoment, "days");
    var previousStartDate = startDatemoment
      .clone()
      .subtract(daysDiff + 1, "days")
      .format("YYYY/MM/DD");
    var previousEndDate = startDatemoment
      .clone()
      .subtract(1, "days")
      .format("YYYY/MM/DD");
    return { previousStartDate, previousEndDate };
  }

  getPreviousYearDates(selectedDateRange) {
    var startWeekNum = this.getWeekNumberByDate(selectedDateRange.startDate);
    var endWeekNum = this.getWeekNumberByDate(selectedDateRange.endDate);
    var subtractFromStartYear = 1,
      subtractFromEndYear = 1;
    if (startWeekNum === 0) {
      startWeekNum = 52;
      subtractFromStartYear = 2;
    }
    if (endWeekNum === 0) {
      endWeekNum = 52;
      subtractFromEndYear = 2;
    }
    var endDateYear =
      parseInt(selectedDateRange.endDate.split("/")[0]) - subtractFromEndYear; //subtract one more if endWeek = 0 and change endweek to 52.
    var startDateYear =
      parseInt(selectedDateRange.startDate.split("/")[0]) -
      subtractFromStartYear; // same as top.

    var previousStartDate = this.getMondayByWeekNumYear(
      startWeekNum,
      startDateYear
    );
    var previousEndDate = this.getSundayByWeekNumYear(endWeekNum, endDateYear);
    return { previousStartDate, previousEndDate };
  }

  getPreviousDates(selectedDateRange, comparePeriod) {
    if (comparePeriod === COMPARE_PERIODS.previousPeriod) {
      return this.getPreviousPeriodDates(selectedDateRange);
    } else {
      return this.getPreviousYearDates(selectedDateRange);
    }
  }
}
