import React from "react";
import Outline from "./outline";
import NewButton from "./button";

//variants: contained, outline, pale
export default function Button(props) {
  const { variant = "outline" } = props;

  if (variant != "contained") return <Outline {...props} />;
  return <NewButton {...props} />;
}
