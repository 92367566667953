import { ApiService, cancelRankingEventsTableData } from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();

export default class RankingEventsService extends BasicViewService {
  fetchTableData(requestParams) {
    if (cancelRankingEventsTableData !== undefined) {
      cancelRankingEventsTableData("cancel");
    }
    const { insightViews } = requestParams;

    const res = apiService
      .requestRankingEventsDataPOST(`/rankingEvents/events`, insightViews)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }
}
