import CatalogTrackerService from "../../services/catalogTrackerService";
import {
  GET_MATCHES_REQUEST,
  GET_MATCHES_SUCCESS,
  GET_MATCHES_CANCEL,
  GET_MATCHES_FAILURE,
  GET_MORE_MATCHES_REQUEST,
  GET_MORE_MATCHES_SUCCESS,
  GET_MORE_MATCHES_CANCEL,
  GET_MORE_MATCHES_FAILURE,
  UPDATE_MAIN_ROOTDOMAIN,
} from "./constants";

const catalogTrackerSerivce = new CatalogTrackerService();

export const matchesRequested = () => ({
  type: GET_MATCHES_REQUEST,
});

export const matchesSucceeded = (data) => ({
  type: GET_MATCHES_SUCCESS,
  payload: { data },
});

export const matchesCancel = () => ({
  type: GET_MATCHES_CANCEL,
});

export const matchesError = (error) => ({
  type: GET_MATCHES_FAILURE,
  payload: { error },
});

export const moreMatchesRequested = () => ({
  type: GET_MORE_MATCHES_REQUEST,
});

export const moreMatchesSucceeded = (data) => ({
  type: GET_MORE_MATCHES_SUCCESS,
  payload: { data },
});

export const moreMatchesCancel = () => ({
  type: GET_MORE_MATCHES_CANCEL,
});

export const moreMatchesError = (error) => ({
  type: GET_MORE_MATCHES_FAILURE,
  payload: { error },
});

export const updateMainRootdomain = (mainRootdomain) => ({
  type: UPDATE_MAIN_ROOTDOMAIN,
  payload: { mainRootdomain },
});

export const getMatchedSkus = (currentViewConfigQuery) => (dispatch) => {
  dispatch(matchesRequested());

  catalogTrackerSerivce
    .getMatchedSkus(currentViewConfigQuery)
    .then((res) => {
      dispatch(matchesSucceeded(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(matchesCancel());
      } else {
        dispatch(matchesError(err.message));
      }
    });
};

export const getMoreMatchedSkus = (currentViewConfigQuery, amountOfMatches) => (
  dispatch
) => {
  dispatch(moreMatchesRequested());
  catalogTrackerSerivce
    .getMoreMatchedSkus(currentViewConfigQuery, amountOfMatches)
    .then((res) => {
      dispatch(moreMatchesSucceeded(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(moreMatchesCancel());
      } else {
        dispatch(moreMatchesError(err.message));
      }
    });
};
