import React, { useEffect, useState } from "react";
import {
  DropdownWrapper,
  dropdownTitle,
  dropdownHeader,
  dropdownBody,
  dropdownFooter,
} from "../dropdown";
import styles from "./customize-columns-dropdown.scss";
import DropdownHeaderSearchable from "../dropdown/dropdown-header/dropdown-header-searchable";

const { DropdownTitleIcon } = dropdownTitle;
const { DropdownHeader } = dropdownHeader;
const { DropdownBodyMultiselect } = dropdownBody;
const { DropdownFooterSingleButton } = dropdownFooter;

function CustomizeColumnsDropdown(props) {
  const { options, onSaveSelectedColumns } = props;
  const [filterValue, setFilterValue] = useState("");
  const [filtered, setFiltered] = useState(options);

  useEffect(() => {
    setFiltered(
      options.filter((option) =>
        option.name.toLowerCase().includes(filterValue.toLowerCase())
      )
    );
  }, [filterValue]);

  const remappedSelectedItems = options.filter((option) =>
    props.selectedItems.includes(option.id)
  );

  const [selectedItems, setSelectedItems] = useState(remappedSelectedItems);

  const handleClickItem = ({ selectedItems }) => {
    setSelectedItems(selectedItems);
  };

  const handleClickButton = () => {
    onSaveSelectedColumns(selectedItems.map((item) => item.id));
  };

  const dropdownTitle = <DropdownTitleIcon customizeColumnIcon={true} />;
  const dropdownHeader = (
    <>
      <DropdownHeader title={"Customize Columns"} />
      {options.length > 8 && (
        <DropdownHeaderSearchable
          filterValue={filterValue}
          onChangeHandler={({ target }) => setFilterValue(target.value)}
        />
      )}
    </>
  );

  return (
    <>
      <DropdownWrapper
        dropdownTitle={dropdownTitle}
        dropdownHeader={dropdownHeader}
        dropdownBody={{
          component: DropdownBodyMultiselect,
          props: { options: filtered, selectedItems },
          styles,
        }}
        dropdownFooter={{
          component: DropdownFooterSingleButton,
          props: { title: "Customize" },
          styles,
        }}
        handleClickItem={handleClickItem}
        handleClickButton={handleClickButton}
        wrapDropdownBody={true}
        wrapDropdownFooter={true}
        styles={styles}
        scrolled={options.length > 10}
      />
    </>
  );
}

export default CustomizeColumnsDropdown;
