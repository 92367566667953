import React, { Component } from "react";

import InsightsBarItem from "../../../../../components/insights-bar-item";
import styles from "./insights-revenue-leakage-list-item.scss";
import { abbreviateNumber } from "../../../../../utils/other/utilities.js";

export default class InsightsRevenueLeakageListItem extends Component {
  getRange = (inflow, outflow) => {
    if (outflow) {
      return outflow;
    }
    if (inflow) {
      return inflow;
    }
    return null;
  };

  render() {
    const { item, sum, activeCategory, handleClickItem } = this.props;
    const { inflow, outflow, competitorCategoryName } = item;

    let range = this.getRange(inflow, outflow);

    const active = activeCategory === competitorCategoryName ? "active" : "";

    const categoryl1_btn = competitorCategoryName.split(" ").join("") + "_btn";
    const categoryl1_actv =
      competitorCategoryName.split(" ").join("") + "_actv";
    const categoryl2_list_id =
      competitorCategoryName.split(" ").join("") + "_list";

    return (
      <div id={categoryl1_actv} className={`${styles.wrapper} ${active}`}>
        <div
          id={categoryl1_btn}
          className={styles.item}
          onClick={() => {
            handleClickItem(competitorCategoryName);
          }}
        >
          <div className={styles.itemBox}>
            <div className={styles.title}>{competitorCategoryName}</div>
            {/* <div className={styles.range}>
                  ${ abbreviateNumber(range) }
              </div> */}
          </div>
          <InsightsBarItem sum={sum} current={range} />
        </div>
        <div id={categoryl2_list_id} className={styles.panel}>
          {this.props.children}
        </div>
      </div>
    );
  }
}
