import React from "react";

import styles from "./content.scss";

const Content = (props) => {
  const { additionalClassName } = props;
  return (
    <div className={`${styles.wrapper} ${additionalClassName}`}>
      {props.children}
    </div>
  );
};

export default Content;
