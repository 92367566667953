import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import DefaultStyles from "./chart-component.scss";

import XYChartWrapper from "../../../visx_components/xychart/xychart";
import BaseComponent from "../base-component";
import ChartComponentTooltip from "./chart-component-tooltip";
import { isEmpty, isUndefined } from "lodash";
import {
  decorateValue,
  percentChangeDecorator,
  priceDecorator,
} from "../../../styles/cell-decorators";
import LegendWrapper from "../../../visx_components/legend/legend";
import MbRequestsErrors from "../../../mb_components/mb-requests-errors/mb-requests-errors";
import ChartComponentLoader from "./chart-component-loader";

const chartsProps = [
  {
    stroke: "#5671F5",
    strokeDasharray: "0",
  },
  {
    stroke: "#000000",
    strokeDasharray: "5",
  },
];

function ChartComponent(props) {
  const {
    title,
    legend,
    componentData,
    styles,
    taglineSubject,
    comparePeriod,
    selectedDateRange,
    legendName,
    currentKey,
    previousKey,
    changeKey,
    valueDecorator = priceDecorator,
    diffDecorator = percentChangeDecorator,
    yTickFormat = (x) => x,
    chartType = "lineGroup",
    componentConfig = {},
  } = props;

  const { summaryWidget } = componentConfig;

  let { data } = componentData || {};
  let tagline;
  let summary;

  if (data?.length > 0) {
    data = data.map((x) => ({
      ...x,
      date: moment(x.currentDate).valueOf(),
      currentDate: moment(x.currentDate).format("MM/DD/YYYY"),
      previousDate: moment(x.previousDate).format("MM/DD/YYYY"),
    }));
  }

  const timePeriod =
    comparePeriod === "PreviousPeriod" ? "previous period" : "previous year";

  // Summary + Tagline creation
  if (
    !isUndefined(componentData) &&
    !isUndefined(componentData[currentKey]) &&
    !isUndefined(componentData[changeKey]) &&
    selectedDateRange
  ) {
    // Summary
    summary = (
      <div className={DefaultStyles.summary}>
        <div className={DefaultStyles.group}>
          <div className={DefaultStyles.bold}>
            {decorateValue(componentData[currentKey], valueDecorator)}
          </div>
          <div className={DefaultStyles.normal}>{title}</div>
        </div>
        <div className={DefaultStyles.group}>
          <div className={DefaultStyles.bold}>
            {decorateValue(componentData[changeKey], diffDecorator)}
          </div>
          <div className={`${DefaultStyles.normal} ${DefaultStyles.period}`}>
            {timePeriod}
          </div>
        </div>
      </div>
    );

    // Tagline
    const changeElement = decorateValue(
      componentData[changeKey],
      diffDecorator
    );
    tagline = (
      <div>
        {taglineSubject}{" "}
        {componentData[changeKey] > 0 ? "increased" : "decreased"} by{" "}
        {changeElement} over the {timePeriod}
      </div>
    );
  }

  const dataKeys = Object.assign(
    {},
    ...[currentKey, previousKey].map((x, i) => ({
      [x]: chartsProps[i],
    }))
  );

  // Build the component
  var component =
    data && data.length > 0 ? (
      <div className={DefaultStyles.componentLayout}>
        <div className={DefaultStyles.summaryContainer}>
          {summary}
          {summaryWidget && (
            <div className={DefaultStyles.summaryWidget}> {summaryWidget}</div>
          )}
        </div>

        <div className={DefaultStyles.separator}></div>
        <div className={DefaultStyles.chartContainer}>
          <div>
            {tagline && (
              <div
                className={`${DefaultStyles.tagline} ${styles?.tagline ?? ""}`}
              >
                {tagline}
              </div>
            )}
          </div>
          <div
            className={`${DefaultStyles.chart} ${
              props.pdfFormat ? DefaultStyles.pdfChartFormat : ""
            }`}
          >
            <XYChartWrapper
              data={data}
              dataKeys={dataKeys}
              curveType={"monotoneCurve"}
              xKey={"date"}
              xTickFormat={(x) => moment(x).format("D MMM YY")}
              xNumTicks={Math.min(data?.length, 6)}
              yAxis={true}
              yOrientation={"left"}
              yTickFormat={yTickFormat}
              showGridColumns={false}
              tooltipCallback={ChartComponentTooltip({
                title,
                currentKey,
                previousKey,
                changeKey,
                chartsProps,
                valueDecorator,
                diffDecorator,
              })}
              margin={{ top: 40, bottom: 40, left: 60, right: 40 }}
              chartType={chartType}
              {...props}
            />
          </div>
          <div>
            <LegendWrapper
              labels={[legendName, `${legendName} ${timePeriod}`]}
              labelsProps={chartsProps}
            />
          </div>
          {legend && <div className={styles.legend}>{legend}</div>}
        </div>
      </div>
    ) : (
      "beError"
    );
  const getErrorComponent = (componentName) => {
    let comp = props.componentsConfig[componentName];
    var info =
      comp?.statusCode === 432
        ? { message: comp.message, type: "infoError" }
        : { message: undefined, type: "beError" };
    return <MbRequestsErrors errorType={info.type} errorMsg={info.message} />;
  };
  const isLoading = isEmpty(componentData) && !isUndefined(componentData);

  const isDataAvailable =
    data &&
    data.length > 0 &&
    componentData[currentKey] !== null &&
    componentData[previousKey] !== null &&
    componentData[changeKey] !== null;
  component = isLoading ? (
    <ChartComponentLoader />
  ) : isDataAvailable ? (
    component
  ) : component === "beError" ? (
    getErrorComponent(componentData["componentName"])
  ) : (
    <MbRequestsErrors />
  );

  return (
    <BaseComponent
      isEnabled={true}
      component={component}
      isLoading={isLoading}
      useDefaultLoading={false}
      {...props}
    />
  );
}

export const mapStateToProps = (state) => {
  return {
    componentsConfig: state.insightsBoard.components,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ChartComponent);
