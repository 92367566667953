import {
  FETCH_COMPETITORS_REQUEST,
  FETCH_COMPETITORS_SUCCESS,
  FETCH_COMPETITORS_FAILURE,
  FETCH_COMPETITORS_CANCEL,
  CLEAR_COMPETITORS,
} from "./constants";

import CompetitorsService from "../../../services/competitorsService";

const competitorsService = new CompetitorsService();

export const competitorsRequested = () => ({
  type: FETCH_COMPETITORS_REQUEST,
});

export const competitorsSucceeded = (data) => ({
  type: FETCH_COMPETITORS_SUCCESS,
  payload: { data },
});

export const competitorsError = (error) => ({
  type: FETCH_COMPETITORS_FAILURE,
  payload: { error },
});

export const competitorsCancel = () => ({
  type: FETCH_COMPETITORS_CANCEL,
});

export const fetchCompetitors = () => (dispatch) => {
  dispatch(competitorsRequested());

  return competitorsService
    .fetchCompetitors()
    .then((res) => {
      dispatch(competitorsSucceeded(res));
      return res;
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(competitorsCancel());
      } else {
        dispatch(competitorsError(err.message));
      }
    });
};

export const clearCompetitors = () => ({
  type: CLEAR_COMPETITORS,
});
