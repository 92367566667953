import {
  FETCH_SKUS_REQUEST,
  FETCH_SKUS_SUCCESS,
  FETCH_SKUS_FAILURE,
  FETCH_BRANDS_SUCCESS,
  FETCH_BRANDS_FAILURE,
  CLEAR_ITEMS,
  FETCH_MORE_SKUS_CANCEL,
  FETCH_SKUS_CANCEL,
  CLEAR_SKUS,
} from "./constants";

import SkusService from "../../services/skusService";
import BrandsService from "../../services/brandsService";

const skusService = new SkusService();
const brandsService = new BrandsService();

export const skusRequested = () => ({
  type: FETCH_SKUS_REQUEST,
});

export const skusSuccessed = (data) => ({
  type: FETCH_SKUS_SUCCESS,
  payload: { data },
});

export const skusError = (error) => {
  return {
    type: FETCH_SKUS_FAILURE,
    payload: { error },
  };
};

export const skusCancel = () => ({
  type: FETCH_SKUS_CANCEL,
});

export const fetchSkus = (query, topCategoryLevel1, topCategoryLevel2) => (
  dispatch
) => {
  dispatch(skusRequested());

  skusService
    .fetchSkus(query, topCategoryLevel1, topCategoryLevel2)
    .then((res) => {
      dispatch(skusSuccessed(res));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(skusCancel());
      } else {
        dispatch(skusError(err.message));
      }
    });
};

// brands
export const brandsSuccessed = (data) => ({
  type: FETCH_BRANDS_SUCCESS,
  payload: { data },
});

export const brandsError = () => ({
  type: FETCH_BRANDS_FAILURE,
});

export const fetchBrands = (competitorRootDomain, clientTopCategoryLevel1) => (
  dispatch
) => {
  return brandsService
    .fetchBrands(competitorRootDomain, clientTopCategoryLevel1)
    .then((res) => {
      dispatch(brandsSuccessed(res));
      return res;
    })
    .catch((err) => {
      dispatch(brandsError());
    });
};

export const clearItems = () => ({
  type: CLEAR_ITEMS,
});

export const clearSkus = () => ({
  type: CLEAR_SKUS,
});
