import { ApiService, cancelInsights, cancelSkusInsights } from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();

export default class InsightsService extends BasicViewService {
  fetchCategoryMapping(queryProp) {
    const query = queryProp ? queryProp : "";

    if (cancelInsights !== undefined) {
      cancelInsights("cancel");
    }

    const res = apiService
      .requestInsightsGET(`/categoryMapping${query}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchInsightsSkus(data, viewType) {
    data.offset = 0;
    if (cancelSkusInsights !== undefined) {
      cancelSkusInsights("cancel");
    }
    const res = apiService
      .requestInsightsSkusPOST(`/insights/skus/${viewType}`, data)
      .then((res) => {
        res.data.topSkusInsights = this._transformSkus(
          res.data.topSkusInsights
        );
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchInsightsMoreSkus(lengthProduct, data, viewType) {
    if (cancelSkusInsights !== undefined) {
      cancelSkusInsights("cancel");
    }

    const res = apiService
      .requestInsightsSkusPOST(`/insights/skus/${viewType}`, data)
      .then((res) => {
        return Promise.resolve(
          this._transformMoreSkus(res.data.topSkusInsights, lengthProduct)
        );
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchTopSkus(data) {
    data.offset = 0;
    if (cancelSkusInsights !== undefined) {
      cancelSkusInsights("cancel");
    }
    const res = apiService
      .requestInsightsSkusPOST(`/insights/topskus`, data)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchMoreTopSkus(lengthProduct, data) {
    if (cancelSkusInsights !== undefined) {
      cancelSkusInsights("cancel");
    }

    const res = apiService
      .requestInsightsSkusPOST(`/insights/topskus`, data)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  exportInsightsView(data, viewType) {
    const res = apiService
      .exportInsightsViewPOST(`/insights/skus/csv/${viewType}`, data)
      .then((res) => {
        return Promise.resolve(res);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
    return res;
  }

  _transformSkus = (data = []) => {
    let result =
      data.map((item, i) => {
        return {
          competitorSku: item.competitorSku,
          matchedCustomerSku: item.matchedCustomerSku,
          insights: item.insights,
          id: i,
        };
      }) || [];

    return result;
  };

  _transformMoreSkus = (data = [], lengthProduct) => {
    let result =
      data.map((item, i) => {
        return {
          competitorSku: item.competitorSku,
          matchedCustomerSku: item.matchedCustomerSku,
          insights: item.insights,
          id: i + lengthProduct,
        };
      }) || [];

    return result;
  };
}
