import React from "react";
import styles from "./tabs.scss";

const Tabs = ({ tabs, activeTab, setActiveTab }) => {
  return (
    <div className={styles.tabs}>
      {tabs.map(
        (tab) =>
          !tab.disabled && (
            <div
              className={activeTab === tab.id ? styles.activeTab : styles.tab}
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
            >
              {tab.name}
            </div>
          )
      )}
    </div>
  );
};

export default Tabs;
