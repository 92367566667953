import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import AuthWrapper from "../../../components/auth-wrapper";
import Spinner from "../../../components/spinner";

import { changeField, trySubmitted, sendForm } from "./actions";

import utils from "../../../utils";

import styles from "./forgot-password.scss";

const { getToken } = utils.auth;

class ForgotPassword extends Component {
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.trySubmitted();

    const { email, validEmail } = this.props;

    if (validEmail) {
      this.props.sendForm({ email });
    }
  };

  handleInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.props.changeField(name, value);
    this.validateEmail(value);
  };

  validateEmail(value) {
    let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.props.changeField("validEmail", regEmail.test(value));
  }

  componentDidMount() {
    if (getToken()) {
      this.props.history.push("/");
    }
  }

  render() {
    const { email, isTrySubmit, validEmail, error, loading } = this.props;

    const serverError = error ? (
      <div className={styles.errorBlock}>{error}</div>
    ) : null;

    return (
      <AuthWrapper>
        <div className={styles.wrapper}>
          <h3 className={styles.header}>Forgot your password?</h3>
          <p className={styles.desc}>
            Let’s get you a new one! Enter your e-mail address and we will send
            you a link to reset your password.
          </p>
          <form onSubmit={this.handleSubmit}>
            {loading ? (
              <div className={styles.loader}>
                <Spinner height={100} />
              </div>
            ) : null}
            {serverError}
            <div
              className={`${styles.formGroup} ${
                !validEmail && isTrySubmit ? styles.error : ""
              }`}
            >
              <input
                type="text"
                className="form-control"
                name="email"
                placeholder="E-Mail"
                value={email}
                onChange={this.handleInput}
              />
              <p className={styles.errorMsg}>Invalid Email Address</p>
            </div>
            <button type="submit" disabled={loading}>
              send link
            </button>
          </form>
        </div>
      </AuthWrapper>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    email: state.auth.forgotPassword.email,
    isTrySubmit: state.auth.forgotPassword.isTrySubmit,
    validEmail: state.auth.forgotPassword.validEmail,
    error: state.auth.forgotPassword.error,
    loading: state.auth.forgotPassword.loading,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeField,
      trySubmitted,
      sendForm,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
