import {
  APPLY_INITIAL_STATE,
  BEFORE_FETCH_MARKET_SHARE_DATA,
  FETCH_MARKET_SHARE_CHART_DATA_CANCEL,
  FETCH_MARKET_SHARE_CHART_DATA_FAILURE,
  FETCH_MARKET_SHARE_CHART_DATA_REQUEST,
  FETCH_MARKET_SHARE_CHART_DATA_SUCCESS,
  FETCH_MARKET_SHARE_TABLE_DATA_CANCEL,
  FETCH_MARKET_SHARE_TABLE_DATA_FAILURE,
  FETCH_MARKET_SHARE_TABLE_DATA_REQUEST,
  FETCH_MARKET_SHARE_TABLE_DATA_SUCCESS,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_REQUEST,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_SUCCESS,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_FAILURE,
  FETCH_MORE_MARKET_SHARE_TABLE_DATA_CANCEL,
  DATEPICKER_CHANGED,
  FETCH_MARKET_SHARE_TABLE_DATA_PARTIAL,
} from "./constants";

const initialState = {
  summary: {
    gmv: 0,
    gmvChange: 0,
    sales: 0,
    salesChange: 0,
    asp: 0,
    aspChange: 0,
    inStockRate: 0,
    inStockRateChange: 0,
  },
  marketShareLineChartData: [],
  marketShareSortedData: [],
  hasData: true,
  tableStatus: {
    loading: false,
    loadingMore: false,
    hasMore: true,
    partial: false,
  },
};

const marketShareReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }

    case FETCH_MARKET_SHARE_CHART_DATA_CANCEL: {
      return {
        ...state,
      };
    }
    case FETCH_MARKET_SHARE_CHART_DATA_FAILURE: {
      return {
        ...state,
      };
    }
    case FETCH_MARKET_SHARE_CHART_DATA_REQUEST: {
      return {
        ...state,
      };
    }
    case FETCH_MARKET_SHARE_CHART_DATA_SUCCESS: {
      const { data } = action.payload;
      const marketShareChartData = prepareMarketShareData(data.data);
      marketShareChartData.sort((a, b) => marketShareDataSortByGmv(a, b));

      return {
        ...state,
        ...data,
        marketShareChartData: marketShareChartData,
      };
    }
    case BEFORE_FETCH_MARKET_SHARE_DATA: {
      return {
        ...state,
        hasData: true,
        tableStatus: {
          ...state.tableStatus,
          loading: true,
          partial: false,
        },
      };
    }
    case FETCH_MARKET_SHARE_TABLE_DATA_CANCEL: {
      return {
        ...state,
        hasData: true,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
        },
      };
    }
    case FETCH_MARKET_SHARE_TABLE_DATA_FAILURE: {
      return {
        ...state,
        hasData: true,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
        },
      };
    }
    case FETCH_MARKET_SHARE_TABLE_DATA_REQUEST: {
      return {
        ...state,
        hasData: true,
        marketShareSortedData: [],
        marketShareLineChartData: {},
        tableStatus: {
          ...state.tableStatus,
          partial: false,
          loading: true,
          hasMore: true,
        },
      };
    }
    case FETCH_MARKET_SHARE_TABLE_DATA_SUCCESS: {
      const { data } = action.payload;
      const sortedData = prepareMarketShareData(data.tableData);
      const lineChartData = data.lineChartData;
      const hasData = sortedData.length > 0;
      return {
        ...state,
        marketShareSortedData: sortedData,
        marketShareLineChartData: lineChartData,
        hasData,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
          partial: false,
        },
      };
    }
    case FETCH_MARKET_SHARE_TABLE_DATA_PARTIAL: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loading: false,
          partial: true,
        },
      };
    }
    case FETCH_MORE_MARKET_SHARE_TABLE_DATA_REQUEST: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loadingMore: true,
        },
      };
    }
    case FETCH_MORE_MARKET_SHARE_TABLE_DATA_SUCCESS: {
      const { data } = action.payload;

      return {
        ...state,
        marketShareSortedData: [...state.MarketShareData, ...data],
        tableStatus: {
          ...state.tableStatus,
          loading: false,
          hasMore: data.length !== 0 ? true : false,
          loadingMore: false,
        },
      };
    }
    case FETCH_MORE_MARKET_SHARE_TABLE_DATA_CANCEL: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loadingMore: false,
        },
      };
    }
    case FETCH_MORE_MARKET_SHARE_TABLE_DATA_FAILURE: {
      return {
        ...state,
        tableStatus: {
          ...state.tableStatus,
          loadingMore: false,
        },
      };
    }

    case DATEPICKER_CHANGED: {
      return {
        ...state,
        hasData: true,
      };
    }
  }

  return state;
};

const prepareMarketShareData = (marketShareChartData) => {
  return Object.keys(marketShareChartData).map((x) => {
    return { name: x, ...marketShareChartData[x] };
  });
};

const marketShareDataSortByGmv = (a, b) => {
  return a.currentGmvShare > b.currentGmvShare
    ? -1
    : a.currentGmvShare < b.currentGmvShare
    ? 1
    : 0;
};

export default marketShareReducer;
