const CATEGORY_DELIMITER = ">";

export const getCategoriesFromConfig = (
  config,
  { mb_category_field = "mb_category", return_as_product_types = false }
) => {
  const categories = config.query?.productAttributes?.filter(
    (x) => x.mbName === mb_category_field
  );
  if (categories) {
    let values = categories.map((x) => x.values[0]);
    if (
      return_as_product_types &&
      values.length > 0 &&
      values[0].indexOf(CATEGORY_DELIMITER) > 0
    ) {
      values = values.map((x) => x.split(CATEGORY_DELIMITER).pop());
    }
    return values;
  }
  return [];
};
