import React, { useState } from "react";
import styles from "./recipients.scss";

const Recipients = ({ viewConfig, setViewConfig, disabled }) => {
  const emails = viewConfig?.emails ?? [];
  const [currentEmail, setCurrentEmail] = useState("");
  const [error, setError] = useState(null);

  const setEmails = (newEmails) => {
    setViewConfig({ ...viewConfig, emails: newEmails });
  };

  const validateEmail = (email) => {
    const re = /^\S+@\S+$/;
    return re.test(email);
  };

  const onEmailAdd = () => {
    if (currentEmail.length == 0) return;
    if (!validateEmail(currentEmail)) {
      setError(true);
      return;
    }
    if (emails.indexOf(currentEmail) == -1)
      setEmails([...emails, currentEmail]);
    setCurrentEmail("");
  };

  const removeEmail = (i) => {
    const newEmails = [...emails];
    newEmails.splice(i, 1);
    setEmails(newEmails);
  };

  const handleKeyDown = (e) => {
    setError(false);
    if ([" ", ",", "enter", "spacebar"].includes(e.key.toLowerCase())) {
      onEmailAdd();
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div style={{ display: "flex", gap: "10px", flexDirection: "column" }}>
      <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
        <div style={{ fontWeight: 500 }}>To</div>
        <input
          disabled={disabled}
          className={error ? styles.emailError : styles.emailInput}
          placeholder={"Email address"}
          value={currentEmail}
          onKeyDownCapture={handleKeyDown}
          onChange={(e) => setCurrentEmail(e.target.value)}
          onBlur={() => onEmailAdd(currentEmail)}
        />
      </div>
      {error && <div className={styles.error}>Email format is invalid.</div>}
      {emails.length > 0 && (
        <div style={{ display: "flex", flexWrap: "wrap", paddingLeft: "16px" }}>
          {emails.map((email, i) => (
            <div key={email} className={styles.emailInList}>
              <div>{email}</div>
              <div
                className={styles.removeEmail}
                onClick={() => !disabled && removeEmail(i)}
              >
                x
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Recipients;
