import React from "react";

import styles from "./filtered-table.scss";

const RowsLimitLabel = ({ maxTableResults, maxExportRows }) => {
  // Todo: this should be a prop and controlled from outside
  const onExport = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTimeout(() => {
      window.location.hash = "#export";
    }, 500);
  };

  return (
    <div className={styles.rowsLimitLabel}>
      <span>
        We limit the number of segments to {maxTableResults}.{" "}
        <span className={styles.clickable} onClick={() => onExport()}>
          Export up to {maxExportRows} segments via CSV
        </span>
        .
      </span>
    </div>
  );
};

export default RowsLimitLabel;
