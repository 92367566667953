import React from "react";
import CustomScrollbar from "../../scrollbar/customScrollbar";
import { Radiobutton } from "../../checkbox";
import defaultStyles from "../dropdown2.scss";

function DropdownBodySingleSelect(props) {
  const {
    options,
    handleClickItem,
    value,
    radiobutton,
    scrolled,
    styles,
  } = props;

  const onSelectItem = (item) => {
    props.onSelectItem ? props.onSelectItem(item) : handleClickItem(item);
  };

  const body = (
    <ul className={defaultStyles.list}>
      {options.length !== 0 &&
        options.map((item, idx) => (
          <li
            id={item.name}
            className={`${defaultStyles.listItem} ${
              item.name === value ? defaultStyles.selectedItem : ""
            }`}
            key={item.id || item.name + "_" + idx}
            onClick={() => {
              onSelectItem(item);
            }}
          >
            {radiobutton && (
              <div>
                <Radiobutton checked={item.value === value} />
              </div>
            )}
            <div className={defaultStyles.itemLabel}>{item.name}</div>
          </li>
        ))}
      {options.length === 0 && (
        <li className={defaultStyles.listItem}>List is empty</li>
      )}
    </ul>
  );

  return (
    <>
      {options && (
        <div className={` ${defaultStyles.listWrapper} `}>
          {/* If you uncomment CustomScrollbar you should direct set height and width params to each dropdown  */}
          {scrolled ? (
            <CustomScrollbar
              style={{ height: props.height || 200, width: props.width || 100 }}
              styles={styles}
            >
              {body}
            </CustomScrollbar>
          ) : (
            body
          )}
        </div>
      )}
    </>
  );
}

DropdownBodySingleSelect.defaultProps = {
  scrolled: false,
  styles: {},
};

export default DropdownBodySingleSelect;
