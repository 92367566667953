import React, { Component } from "react";

import Spinner from "../../../../../components/spinner";
import styles from "./insights-revenue-leakage-list-inner.scss";
import InsightsRevenueLeakageListInnerItem from "../insights-revenue-leakage-list-inner-item";

export default class InsightsRevenueLeakageListInner extends Component {
  sortItems = (items = [], flag) => {
    if (flag === "Ascending") {
      return items.sort((a, b) => b.outflow - a.outflow);
    }

    return items.sort((a, b) => a.outflow - b.outflow);
  };

  render() {
    const {
      data,
      loadingRevenueItem,
      level1ActiveCategory,
      handleClickInnerItem,
      sortFlag,
    } = this.props;

    const sortCategories = this.sortItems(data, sortFlag);

    if (loadingRevenueItem) {
      return (
        <div className={styles.spinner}>
          <Spinner height={70} />
        </div>
      );
    }

    if (sortCategories.length === 0) {
      return <div className={styles.emptyList}></div>;
    }

    return (
      <div className={styles.wrapper}>
        {sortCategories
          ? sortCategories.map((item) => (
              <InsightsRevenueLeakageListInnerItem
                key={item.competitorCategoryName}
                data={item}
                handleClickInnerItem={handleClickInnerItem}
                level1ActiveCategory={level1ActiveCategory}
              />
            ))
          : null}
      </div>
    );
  }
}
