import { analyticsMarketShareAnalysisClicked } from "../shared/analytics/actions";
import {
  APPLY_INITIAL_STATE,
  FETCH_DATA_REQUEST,
  FETCH_DATA_SUCCESS,
  FETCH_DATA_FAIL,
  MARKET_SHARE_ANALYSIS_GROUP_BY,
  MARKET_SHARE_ANALYSIS_CHANGE_GROUP_BY,
  MARKET_SHARE_ANALYSIS_TOGGLE_SHOW_HIDE,
  MARKET_SHARE_ANALYSIS_SET_SUBJECT_NAME,
} from "./constants";

const initialState = {
  loading: false,
  data: null,
  groupBy: MARKET_SHARE_ANALYSIS_GROUP_BY.FAMILY,
  subjectName: null,
  showMarketShareAnalysis: false,
};

const marketShareAnalysisReducer = (state = initialState, action) => {
  switch (action.type) {
    case APPLY_INITIAL_STATE: {
      return initialState;
    }

    case FETCH_DATA_SUCCESS: {
      const data = action.payload;
      return {
        ...state,
        loading: false,
        data: data,
      };
    }
    case FETCH_DATA_FAIL: {
      const error = action.payload.error;
      return {
        ...state,
        loading: false,
        data: {},
      };
    }
    case FETCH_DATA_REQUEST: {
      return {
        ...state,
        loading: true,
        data: null,
      };
    }
    case MARKET_SHARE_ANALYSIS_CHANGE_GROUP_BY: {
      return {
        ...state,
        groupBy: 1 - state.groupBy,
      };
    }
    case MARKET_SHARE_ANALYSIS_TOGGLE_SHOW_HIDE: {
      const newState = !state.showMarketShareAnalysis;
      analyticsMarketShareAnalysisClicked(action.viewTypeId, newState);

      return {
        ...state,
        showMarketShareAnalysis: newState,
      };
    }

    case MARKET_SHARE_ANALYSIS_SET_SUBJECT_NAME: {
      return {
        ...state,
        subjectName: action.name,
      };
    }
  }
  return state;
};

export default marketShareAnalysisReducer;
