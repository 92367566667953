import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { SubspaceProvider } from "react-redux-subspace";
import InsightsTemplate from "../insights-template";
import InsightsSkusInfiniteTable from "../../containers/insights/insights-skus/insights-skus-infinite-table";
import {
  convertBrowserStringSortFieldToConfig,
  convertConfigSortFieldToBrowserString,
} from "../../utils/skus/convertSortValue";

import InsightsService from "../../services/insightsService";
const insightsService = new InsightsService();
import styles from "../insights-template/insights-template.scss";
import { changeSkusSorting } from "../../containers/insights/insights-skus/actions";
import { setCurrentViewIsUpdated } from "../../containers/insights/actions";
import {
  SKU_TRACKER_VIEW_TYPE_DATA,
  FREQUENCY_PRESETS,
} from "../../containers/skus/constants";
import { COMPARE_PERIODS } from "../../components/mb-compare-period/constants";
import { CALENDAR_MODES } from "../../components/date-range-picker/constants";
import moment from "moment";
import { TopSkusChosenPeriodToDateConfig } from "../../containers/skus/utils";

const TopSkus = (props) => {
  const viewTypeData = SKU_TRACKER_VIEW_TYPE_DATA;
  const search = props.location.search;
  const selectedColumns = [2, 35, 117];
  const initialSortField = 116;
  const mySkusProductAttribute =
    props.customerRootDomain != null
      ? [
          {
            displayName: "Map",
            mbName: "map",
            type: "Numeric",
            hasValue: true,
            values: [
              {
                value1: 0,
              },
            ],
            criteria: [
              {
                criterion: 4,
                value1: 0,
              },
            ],
            childAttributes: [],
          },
        ]
      : [];
  const excludedTableColumns = [];
  const lastProcessedDay = props.lastProcessedDay;
  const table = (
    <InsightsSkusInfiniteTable
      viewTypeId={viewTypeData.id}
      defaultSelectedColumns={selectedColumns}
      excludedTableColumns={excludedTableColumns}
      initialSortField={initialSortField}
      mySkusProductAttribute={mySkusProductAttribute}
    />
  );

  const handlePresetClick = (option) => {
    var dateToCalculate = lastProcessedDay
      ? moment(lastProcessedDay)
      : moment();
    return TopSkusChosenPeriodToDateConfig(option, dateToCalculate);
  };

  const dataControlsOptions = {
    viewTypeData,
    lastAvailableDate: lastProcessedDay ?? null,
    maxDate: lastProcessedDay ? new Date(lastProcessedDay) : null,
    apiProvider: insightsService,
    hideCompareDates: false,
    datePickerPresets: Object.keys(FREQUENCY_PRESETS),
    comparePeriods: [COMPARE_PERIODS.previousPeriod],
    datePickerModes: [CALENDAR_MODES.preset],
    handlePresetClick: handlePresetClick,
    hideFrequency: true,
    mbFilter: true,
    convertConfigSortFieldToBrowserString: convertConfigSortFieldToBrowserString,
    minimizeInsightsFilterByDefault: true,
    showEventTriggers: false,
  };

  const body = (
    <>
      <div className={styles.wrapper}>{table}</div>
    </>
  );

  return (
    <SubspaceProvider mapState={(state) => state.skus} namespace={"skus"}>
      <InsightsTemplate
        body={body}
        dataControlsOptions={dataControlsOptions}
        lastAvailableDate={lastProcessedDay ? new Date(lastProcessedDay) : null}
        search={search}
        viewTypeData={viewTypeData}
        apiProvider={insightsService}
        convertBrowserStringSortFieldToConfig={
          convertBrowserStringSortFieldToConfig
        }
      />
    </SubspaceProvider>
  );
};

export const mapStateToProps = (state) => {
  const lastProcessedDay =
    state.skus.insights.insights.viewDefinitions?.viewMetadata
      ?.lastProcessedDay;
  return {
    dateRange: state.skus.insights.insights.dateRange,
    chosenPeriod: state.skus.insights.mbFilterRow.chosenPeriod,
    currentViewConfigQuery:
      state.skus.insights.insights.currentViewConfig.query,
    lastProcessedDay,
    customerRootDomain: state.skus.user.customerInfo.rootDomain,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      changeSkusSorting,
      setCurrentViewIsUpdated,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TopSkus);
