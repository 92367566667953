import _ from "lodash";
import moment from "moment";

const numericalSort = (data, valueAccessor, direction) => {
  if (direction === 0) {
    // descending
    data.sort((a, b) => {
      return b[valueAccessor] - a[valueAccessor];
    });
  } else {
    // ascending
    data.sort((a, b) => {
      return a[valueAccessor] - b[valueAccessor];
    });
  }
};

const dateSort = (data, valueAccessor, direction) => {
  if (direction === 0) {
    // descending
    data.sort((a, b) => {
      return new Date(b[valueAccessor]) - new Date(a[valueAccessor]);
    });
  } else {
    // ascending
    data.sort((a, b) => {
      return new Date(a[valueAccessor]) - new Date(b[valueAccessor]);
    });
  }
};

export const sortTableData = (sortConfig, columnConfig, data) => {
  const valueAccessor = columnConfig.accessor;
  const toSort = _.cloneDeep(data);
  switch (columnConfig.sortMethod) {
    case "date":
      dateSort(toSort, valueAccessor, sortConfig.direction);
    default:
      numericalSort(toSort, valueAccessor, sortConfig.direction);
  }
  return toSort;
};

export const dateFormatter = (x) => moment(x).format("D MMM YY");
