import React, { useRef } from "react";
import styles from "./insights-ai-sidebar.scss";
import CopyButton from "../../components/copy-button";

import { analyticsTextCopied } from "../../containers/shared/analytics/actions";

const InsightsAiSidebar = (props) => {
  const sidebarRef = useRef(null);
  const textToCopy = useRef("");
  const { isLoading = false, hideSidebarCallback, viewTypeId } = props;

  const onTextCopied = () => {
    analyticsTextCopied(viewTypeId, "InsightsAiSidebar");
  };

  return (
    <div className={styles.filterModal} ref={sidebarRef}>
      <div className={styles.header}>
        <div className={styles.controls}>
          <CopyButton textToCopy={textToCopy} onTextCopied={onTextCopied} />
          <i className={styles.close} onClick={hideSidebarCallback} />
        </div>
        <div className={styles.title}>
          <i className={styles.aiInsights} />
          Insights
        </div>
      </div>
      {isLoading ? <FilterSidebarLoader /> : <>{props.children(textToCopy)}</>}
    </div>
  );
};

export default InsightsAiSidebar;
