import React from "react";
import styles from "../sku-analysis-card.scss";
import {
  decorateValue,
  percentChangeDecorator,
  percentDecorator,
  roundNumberChangeDecorator,
} from "../../../../../styles/cell-decorators";

export default function MarketShareAnalysisSearchTerms(props) {
  const { searchTermsKpis } = props;

  return (
    <table className={styles.searchTerms}>
      <thead>
        <tr>
          <th>Top Terms</th>
          <th>Rank</th>
          <th>Visibility</th>
          <th>Sponsored Rate</th>
        </tr>
      </thead>
      <tbody>
        {searchTermsKpis.map((x) => (
          <tr className={styles.productDataRow} key={x.title}>
            <td className={styles.alignLeft}>{x.title}</td>
            <td className={styles.productDataValue}>
              {x.currentSerpRank}{" "}
              {decorateValue(
                x.serpRankChange == null ? "Infinity" : x.serpRankChange,
                roundNumberChangeDecorator
              )}
            </td>
            <td className={styles.productDataValue}>
              {decorateValue(x.currentVisibility, percentDecorator)}{" "}
              {decorateValue(
                x.visibilityChange == null ? "Infinity" : x.visibilityChange,
                percentChangeDecorator
              )}
            </td>
            <td className={styles.productDataValue}>
              {decorateValue(x.currentAdvertising, percentDecorator)}{" "}
              {decorateValue(
                x.advertisingChange == null ? "Infinity" : x.advertisingChange,
                percentChangeDecorator
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
