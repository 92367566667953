import React from "react";
import DataTable from "../../../../components/data-table/data-table";
import * as Decorators from "../../../../styles/cell-decorators";
import { LoaderWidths } from "../../../../components/tables/simple-table/simple-table";
import styles from "./catalog-tracker-domains-table.scss";

let smallLoader = {
  loaderWidth: LoaderWidths.Small,
};

const CatalogTrackerDomainsTable = (props) => {
  const { skuUrls, domainsTableData, isLoading } = props;
  const columnsConfig = [
    {
      id: 0,
      label: "Retailer",
      accessor: "retailer",
      tooltip: "Retailer",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.boldDecorator,
    },
    {
      id: 1,
      label: "SKU",
      accessor: "sku",
      tooltip: "SKU",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.catalogTracker360SkuDecorator,
    },
    {
      id: 2,
      label: "Average Price",
      accessor: "averagePrice",
      tooltip: "Average Price",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.priceDecorator,
    },
    {
      id: 3,
      label: "Regular Price",
      accessor: "averageListPrice",
      tooltip: "List Price",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.priceDecorator,
    },
    {
      id: 4,
      label: "Promo Price",
      accessor: "averagePromoPrice",
      tooltip: "Average price on days with promo",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.promoPriceDecorator,
    },
    {
      id: 5,
      label: "Promo Days",
      accessor: "promoDays",
      tooltip: "Number of days on promo",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.numberDecorator,
    },
    {
      id: 6,
      label: "Reviews",
      accessor: "averageNumOfRatings",
      tooltip: "Number of reviews",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.numberWithCommasAndFloorDecorator,
    },
    {
      id: 7,
      label: "Average Rating",
      accessor: "averageRating",
      tooltip: "Average rating",
      loaderWidth: smallLoader,
      cellDecorator: Decorators.catalogTrackerAverageRatingsDecorator,
    },
    // {
    //   id: 8,
    //   label: "Availability",
    //   accessor: "availability",
    //   tooltip: "Availability %",
    //   loaderWidth: smallLoader,
    //   cellDecorator: Decorators.roundPercentDecorator,
    // },
  ];

  const getTableData = () => {
    const dataList = [];
    for (const retailer in domainsTableData) {
      const retailerData = domainsTableData[retailer];
      const data = { retailer: retailer };
      for (const key in retailerData) {
        data[key] = retailerData[key];
      }
      data["sku"] = skuUrls.find((s) => s.url.includes(retailer));
      dataList.push(data);
    }
    return dataList;
  };

  const tableData = getTableData();
  const sorting = {
    sortingColumn: 1,
  };

  return (
    <div>
      <DataTable
        header="Competitive Retail Tracker Domains Data"
        selectedColumns={columnsConfig.map((column) => column.id)}
        onSelectRow={null}
        data={tableData ?? []}
        prepareDataForTable={(x) => x}
        isSticky={true}
        columnsConfig={columnsConfig}
        sortableColumns={[]}
        disableTooltipForColumns={[0, 1, 2, 6, 7, 8]}
        isLoading={isLoading ?? false}
        excludedTableColumns={[]}
        sorting={sorting}
        styles={styles}
        hideColumnsDropdown={true}
      />
    </div>
  );
};

export default CatalogTrackerDomainsTable;
