import React from "react";
import styles from "./export-button.scss";
import { dropdownTitle } from "../dropdown";
import Spinner from "../spinner";

const { DropdownTitleWrapper } = dropdownTitle;

function ExportButton(props) {
  const { title, onClick, showSpinner } = props;

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <DropdownTitleWrapper>
      {showSpinner ? <Spinner height={"32px"} /> : ""}
      <div className={styles.exportViewTitle} onClick={onClickHandler}>
        {title ? title : "Export"}
      </div>
    </DropdownTitleWrapper>
  );
}

export default ExportButton;
