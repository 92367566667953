import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  FETCH_CATEGORIES_CANCEL,
  CLEAR_CATEGORIES,
} from "./constants";

import CategoriesService from "../../../services/categoriesService";

const categoriesService = new CategoriesService();

export const categoriesRequested = () => ({
  type: FETCH_CATEGORIES_REQUEST,
});

export const categoriesSuccessed = (data) => ({
  type: FETCH_CATEGORIES_SUCCESS,
  payload: { data },
});

export const categoriesError = (error) => ({
  type: FETCH_CATEGORIES_FAILURE,
  payload: { error },
});

export const categoriesCancel = () => ({
  type: FETCH_CATEGORIES_CANCEL,
});

export const fetchCategories = (rootDomain) => (dispatch) => {
  dispatch(categoriesRequested());

  return categoriesService
    .fetchCategories(rootDomain)
    .then((res) => {
      dispatch(categoriesSuccessed(res));
      return res;
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(categoriesCancel());
      } else {
        dispatch(categoriesError(err.message));
      }
    });
};

export const clearCategories = () => ({
  type: CLEAR_CATEGORIES,
});
