import React from "react";
import styles from "./brand-performance-search-term-config.scss";
import Button from "../../../../components/button/button";
import BrandPerformanceButtonIcon from "../../../../images/brand-performance-button.svg";

export default function BrandPerformanceSearchTermButton(props) {
  const { buttonCallback } = props;

  return (
    <>
      <Button
        className={styles.button}
        icon={BrandPerformanceButtonIcon}
        onClick={buttonCallback}
        variant={"contained"}
      />
    </>
  );
}
