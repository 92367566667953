import React, { Component } from "react";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import onClickOutside from "react-onclickoutside";

import styles from "./popup.scss";

class Popup extends Component {
  static propTypes = {
    visible: PropTypes.bool,
  };

  close = (flag) => {
    this.props.handleClose(flag);
  };

  handlePressKey = (e) => {
    if (e.keyCode === 27) {
      this.props.handleClose(false);
    }
  };

  componentDidMount() {
    document.body.style.overflow = "hidden";
    document.addEventListener("keyup", this.handlePressKey);
  }

  componentWillUnmount() {
    document.body.style.overflow = "visible";
    window.removeEventListener("keyup", this.handlePressKey);
  }

  render() {
    const { visible } = this.props;

    if (visible) {
      return (
        <div className={`${styles.wrapper}`}>
          <div
            className={`${styles.inner} `}
            ref={(el) => {
              this.el = el;
            }}
          >
            <div className={styles.start}></div>
            <ModalComponent close={this.close}>
              {this.props.children}
            </ModalComponent>
            <div className={styles.start}></div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

class Modal extends Component {
  static propTypes = {
    visible: PropTypes.bool,
  };

  handleClickOutside = (evt) => {
    this.props.close(false);
  };

  render() {
    return <div className={styles.modal}>{this.props.children}</div>;
  }
}

var ModalComponent = onClickOutside(Modal);

export default Popup;
