const getSortOptions = (sortOptions, competitor) => {
  let rootdomainSortOptions = sortOptions;
  let values = [];
  if (competitor == "amazon.com" || competitor == "Amazon") {
    values = ["Unit", "Sales Dollars"];
  }
  if (competitor == "amazon.com" || competitor == "Amazon") {
    values = ["Unit", "Sales Dollars"];
  }
  values.map((value) => {
    rootdomainSortOptions = rootdomainSortOptions.filter(
      (item) => item.name !== value
    );
  });

  return rootdomainSortOptions;
};

export default getSortOptions;
