import React from "react";
import styles from "../charts/visx/tooltips/tooltip-component.scss";
import ReactTooltip from "react-tooltip";

function ItemTooltip(props) {
  const { tooltipName, tooltipData, children } = props;
  const stopPropagation = (event) => {
    event.stopPropagation();
  };

  return (
    <>
      <span
        data-tip
        data-for={tooltipName}
        style={{ width: "fit-content", height: "fit-content" }}
      >
        {children ?? (
          <span
            className={
              tooltipData
                ? styles.questionMarkIcon
                : styles.questionMarkIconMissing
            }
          >
            ?
          </span>
        )}
      </span>
      <ReactTooltip
        delayHide={100}
        className={styles.tooltip}
        id={tooltipName}
        place="left"
        effect="solid"
        getContent={(id) => {
          if (!tooltipData || !id) return null;

          return tooltipData ? (
            <>
              <div className={styles.tooltipTitleWrapper}>
                <div className={styles.tooltipTitle}>
                  {tooltipData.headline}
                </div>
                <div
                  onClick={stopPropagation}
                  className={styles.tooltipSubtitle}
                >
                  {tooltipData.text}{" "}
                  {tooltipData.link && (
                    <a target="_blank" href={tooltipData.link}>
                      Learn more
                    </a>
                  )}
                </div>
              </div>
            </>
          ) : null;
        }}
      />
    </>
  );
}

export default ItemTooltip;
