import React, { useRef, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { showInsightsAiSidebar } from "./actions";
import InsightsAiSidebar from "../../../mb_components/mb-insights-ai-sidebar";
const MbInsightsAiSidebar = (props) => {
  const sidebarRef = useRef(null);

  const {
    shouldSetSideBarVisibilityOnClickOutside = false,
    handleClose,
  } = props;

  useEffect(() => {
    if (shouldSetSideBarVisibilityOnClickOutside) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  });

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      hideSidebar();
    }
  };

  const hideSidebar = () => {
    props.showInsightsAiSidebar(false);
    if (handleClose) handleClose();
  };

  return (
    <div ref={sidebarRef}>
      <InsightsAiSidebar {...props} hideSidebarCallback={hideSidebar} />
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    insightsAiShowSidebar: state.insights.insights.insightsAiShowSidebar,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showInsightsAiSidebar,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MbInsightsAiSidebar);
