import React, { useState, useEffect } from "react";
import styles from "./ranking-events-infinite-table.scss";
import { fillBlue } from "../../../../styles/variables.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import InfiniteDataTable from "../../../../components/infinite-data-table/infinite-data-table";

import {
  fetchMoreRankingEventsTableData,
  fetchRankingEventsTableData,
  changeRankingEventsTableSorting,
  setCurrentViewIsUpdated,
} from "../../actions";
import DataTable from "../../../../components/data-table/data-table";
import { COLUMNS, EVENT_TYPE_PROPERTIES } from "../../constants";
import {
  getEventsDefinition,
  getSelectedEventTypeProperties,
} from "../../../../mb_components/event-wizard/utils";
import NoEvents from "../../../../images/no-events.svg";
import EventSkuDropdown from "./event-sku-dropdown/event-sku-dropdown";
import { SubspaceProvider } from "react-redux-subspace";
import Spinner from "../../../../components/spinner";
import Toast from "../../../../components/toast";
import _ from "lodash";

function RankingEventsInfiniteTable(props) {
  const {
    insightsFiltersCollection,
    sortField,
    sortOrder,
    tableStatus,
    data,
    selectedDateRange,
    currentViewConfigQuery,
    filterDefinitions,
    insightsFilterShowSidebar,
  } = props;
  const { loading, hasMore } = tableStatus;

  const eventsDefinition = getEventsDefinition(filterDefinitions ?? []);
  const selectedEvent = eventsDefinition
    ? getSelectedEventTypeProperties(
        insightsFiltersCollection,
        eventsDefinition
      )
    : null;
  const [dropdownConfig, setDropdownConfig] = useState({
    open: false,
  });

  const [sortedColumnReset, setSortedColumnReset] = useState(false);
  const [
    updatedCurrentViewConfigQuery,
    setUpdatedCurrentViewConfigQuery,
  ] = useState();
  const [dateRangeTracker, setDateRange] = useState();

  const requestedParams = {
    insightViews: {
      ...currentViewConfigQuery,
      startDate: selectedDateRange.startDate,
      endDate: selectedDateRange.endDate,
    },
  };

  const handleFilterChange = () => {
    if (!sortedColumnReset) {
      // currentViewConfigQuery.sortField = 0;
      props.changeRankingEventsTableSorting(currentViewConfigQuery); // Liran: There must be a better way to tackle this!
      setSortedColumnReset(true);
    }

    // Liran: This is a trick to avoid re-calling fetchSearchTerms several times. Surely there's a better way to tackle this.
    if (shouldFetchData(requestedParams)) {
      // Verify offset is reset to 0
      requestedParams.insightViews.offset = 0;
      setUpdatedCurrentViewConfigQuery(requestedParams);
      setDateRange(selectedDateRange);
      props.fetchRankingEventsTableData(requestedParams);
    }
  };

  const shouldFetchData = (newUpdatedCurrentViewConfigQuery) => {
    // If view-config is updated, or if date-range is updated
    return (
      JSON.stringify(newUpdatedCurrentViewConfigQuery) !==
        JSON.stringify(updatedCurrentViewConfigQuery) ||
      JSON.stringify(dateRangeTracker) !== JSON.stringify(selectedDateRange)
    );
  };

  const fetchMoreData = () => {
    props.fetchMoreRankingEventsTableData(requestedParams);
  };

  useEffect(() => {
    handleFilterChange();
  }, [insightsFiltersCollection, sortField, sortOrder, selectedDateRange]);

  const prepareSortingValues = (newSelectedSortingColumn) => {
    let newSorting = {};
    if (sortField === newSelectedSortingColumn) {
      newSorting = {
        sortField,
        sortOrder: sortOrder ? 0 : 1,
      };
    } else {
      newSorting = {
        sortField: newSelectedSortingColumn,
        sortOrder: 0,
      };
    }
    props.changeRankingEventsTableSorting(newSorting);
  };

  const defaultColumns = [
    COLUMNS.sku,
    COLUMNS.time,
    COLUMNS.category,
    COLUMNS.brand,
    COLUMNS.seller,
    COLUMNS.retailer,
    COLUMNS.lastSeen,
    COLUMNS.sales,
  ];

  const eventTypeColumns = selectedEvent?.columns ?? defaultColumns;
  const allColumnIds = eventTypeColumns.map((x) => x.id);

  const onRowClick = (i, e) => {
    if (data?.[i]?.sku) {
      setDropdownConfig({
        open: true,
        x: insightsFilterShowSidebar ? e.clientX - 350 : e.clientX,
        y: e.clientY - 225 + window.scrollY,
        sku: data[i].sku,
        url: data[i].url,
      });
    }
  };

  const styleData = () => {
    if (data && dropdownConfig?.open) {
      const dataClone = [...data];
      const i = data.findIndex((x) => x.sku == dropdownConfig.sku);
      dataClone[i] = { ...data[i], rowStyle: { backgroundColor: fillBlue } };
      return dataClone;
    }
    return data;
  };

  const table = (
    <DataTable
      header={"Ranking Events"}
      columnsConfig={eventTypeColumns}
      sorting={{
        sortingColumn: sortField,
        selectedSortingDirection: !sortOrder,
      }}
      hideColumnsDropdown={true}
      sortableColumns={[COLUMNS.time.id, COLUMNS.sales.id]}
      prepareSortingValues={prepareSortingValues}
      data={styleData()}
      styles={styles}
      selectedColumns={allColumnIds}
      disableTooltipForColumns={allColumnIds}
      excludedTableColumns={[]}
      prepareDataForTable={(x) => x}
      isLoading={loading}
      onSelectRow={(i, e) => onRowClick(i, e)}
    />
  );

  const NoDataComponent = () => {
    return (
      <div className={styles.noEvents}>
        <img src={NoEvents} />
        <div>No events found for this trigger.</div>
      </div>
    );
  };

  return (
    <Toast>
      {(sendToast) => (
        <div style={{ position: "relative" }}>
          {dropdownConfig?.loading && (
            <div className={styles.loadingContainer}>
              <Spinner width={300} height={300} />
              <div className={styles.loadingText}>Modifying trigger...</div>
            </div>
          )}

          {dropdownConfig?.open && (
            <SubspaceProvider mapState={(state) => state.rankingEvents}>
              <div style={{ position: "relative" }}>
                <EventSkuDropdown
                  dropdownConfig={dropdownConfig}
                  setDropdownConfig={setDropdownConfig}
                  sendToast={sendToast}
                  selectedEvent={selectedEvent}
                />
              </div>
            </SubspaceProvider>
          )}
          <InfiniteDataTable
            table={table}
            noDataComponent={<NoDataComponent />}
            dataLength={data.length}
            hasMore={hasMore}
            fetchMoreData={fetchMoreData}
            loading={loading}
            loadingMore={tableStatus.loadingMore}
          />
        </div>
      )}
    </Toast>
  );
}

export const mapStateToProps = (state) => {
  return {
    loading: state.rankingEvents.insights.insights.loading,
    tableStatus: state.rankingEvents.rankingEvents.tableStatus,
    data: state.rankingEvents.rankingEvents.data,
    selectedDateRange:
      state.rankingEvents.insights.mbFilterRow.selectedDateRange,
    filterDefinitions:
      state.rankingEvents.insights.insights.viewDefinitions.filterDefinitions,
    insightsFilterShowSidebar:
      state.rankingEvents.insights.insights.insightsFilterShowSidebar,

    //ToDo should refactored
    currentViewConfig: state.rankingEvents.insights.insights.currentViewConfig,
    currentViewConfigQuery:
      state.rankingEvents.insights.insights.currentViewConfig.query,
    insightsFiltersCollection:
      state.rankingEvents.insights.insights.currentViewConfig.query
        .productAttributes,
    sortField:
      state.rankingEvents.insights.insights.currentViewConfig.query.sortField,
    sortOrder:
      state.rankingEvents.insights.insights.currentViewConfig.query.sortOrder,
    offset:
      state.rankingEvents.insights.insights.currentViewConfig.query.offset,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      fetchRankingEventsTableData,
      setCurrentViewIsUpdated,
      fetchMoreRankingEventsTableData,
      changeRankingEventsTableSorting,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RankingEventsInfiniteTable);
