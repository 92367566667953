import React, { Component } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import Main from "../main";
import {
  Login,
  ForgotPassword,
  ForgotPasswordSuccess,
  ResetPassword,
  ResetPasswordSuccess,
} from "../auth";

import utils from "../../utils";

const { getToken, setDefaultAuthHeader } = utils.auth;

class App extends Component {
  componentWillMount() {
    if (getToken()) {
      setDefaultAuthHeader();
    }
  }

  render() {
    const { history } = this.props;

    return (
      <Router history={history}>
        <Switch>
          <Route path="/login" exact component={Login} />
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route
            path="/forgot-password-success"
            exact
            component={ForgotPasswordSuccess}
          />
          <Route path="/reset-password/:id" exact component={ResetPassword} />
          <Route
            path="/reset-password-success"
            exact
            component={ResetPasswordSuccess}
          />
          <AuthorizedRoute path="/" component={Main} />
        </Switch>
      </Router>
    );
  }
}

class AuthorizedRoute extends Component {
  render() {
    const { component: Component } = this.props;

    return (
      <Route
        render={(props) => {
          return getToken() ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          );
        }}
      />
    );
  }
}

export default App;
