import React from "react";
import styles from "./category-customer-panel-component.scss";
import {
  CategoryCustomerPanelComponentConfig,
  CategoryCustomerTableComponentConfig,
} from "./category-customer-panel-component-config";
import { decorateValue } from "../../../../styles/cell-decorators";
import { isEmpty } from "lodash";
import PanelComponent from "../../../../components/dashboard-components/panel-component/panel-component";

export default function CategoryCustomerPanelComponent(props) {
  const { pdfFormat, componentData } = props;

  const panelConfig = CategoryCustomerPanelComponentConfig(props);
  const tableConfig = CategoryCustomerTableComponentConfig(props);

  const designPlan = {
    columns: [
      // category
      {
        className: styles.category,
        rows: [
          // upper row
          {
            className: styles.frame,
            cells: [
              panelConfig.categoryImage,
              panelConfig.totalCategorySales,
              panelConfig.separator,
              panelConfig.totalCategoryUnits,
            ],
          },
          // lower row
          {
            className: styles.frame,
            cells: [
              panelConfig.categoryAsp,
              panelConfig.separator,
              panelConfig.categoryAvgListedPrice,
              panelConfig.separator,
              panelConfig.categoryInStock,
              panelConfig.separator,
              panelConfig.categoryActiveSellingSkus,
            ],
          },
        ],
      },
      // customer
      {
        className: styles.customer,
        rows: [
          // upper row
          {
            className: styles.frame,
            cells: [
              panelConfig.customerImage,
              panelConfig.retailSales,
              panelConfig.separator,
              panelConfig.marketShare,
              panelConfig.separator,
              panelConfig.totalUnits,
              panelConfig.separator,
              panelConfig.unitShare,
            ],
          },
          // lower row
          {
            className: styles.frame,
            cells: [
              panelConfig.avgSellingPrice,
              panelConfig.separator,
              panelConfig.avgListedPrice,
              panelConfig.separator,
              panelConfig.inStockRate,
              panelConfig.separator,
              panelConfig.activeSellingSkus,
            ],
          },
        ],
      },
    ],
  };

  const tableComponents =
    componentData && !isEmpty(componentData) ? (
      <div className={styles.tablesWrapper}>
        {tableConfig.map((table) => (
          <table className={styles.table} key={table.title}>
            <thead className={styles.thead}>
              <tr className={styles.tr}>
                <th className={styles.th} colSpan={2}>
                  {table.title}
                </th>
              </tr>
            </thead>
            <tbody className={styles.tbody}>
              {table.fields.map((field) => (
                <tr className={styles.tr} key={field.title}>
                  <td className={styles.td}>{field.title}</td>
                  <td id={field.title} className={styles.td}>
                    {field.valueDecorator
                      ? decorateValue(
                          componentData?.[table.accessorSection]?.[
                            field.valueAccessor
                          ],
                          field.valueDecorator
                        )
                      : componentData?.[table.accessorSection]?.[
                          field.valueAccessor
                        ]}
                    {"    "}(
                    {field.diffDecorator
                      ? decorateValue(
                          componentData?.[table.accessorSection]?.[
                            field.diffAccessor
                          ],
                          field.diffDecorator
                        )
                      : componentData?.[table.accessorSection]?.[
                          field.diffAccessor
                        ]}
                    )
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    ) : null;

  return pdfFormat ? (
    tableComponents
  ) : (
    <PanelComponent
      {...props}
      isEnabled={true}
      className={styles}
      designPlan={designPlan}
      hideHeader={true}
      hideBackground={true}
    />
  );
}
