import React, { useState } from "react";
import styles from "./promotion-activities-charts.scss";
import parentStyles from "../sku-promotions.scss";
import ChartToggleComponent from "../../../../../components/charts/visx/toggle/chart-toggle-component";
import PieChartComponent from "../../../../../components/charts/visx/pie-chart-component/pie-chart-component";
import * as Decorators from "../../../../../styles/cell-decorators";
import LineChartComponent from "../../../../../components/charts/visx/line-chart-component";
import moment from "moment";
import BarStackComponent from "../../../../../components/charts/visx/bar-stack-component";
import LineTooltipComponent from "../../../../../components/charts/visx/tooltips/line-tooltip-component";
import PieChartComponentLoader from "../../../../../components/charts/visx/pie-chart-component/pie-chart-component-loader";
import BarTooltipComponent from "../../../../../components/charts/visx/tooltips/bar-tooltip-component";
import MarketShareBaseChartLoader from "../../../../market-share/components/market-share-charts/market-share-chart-component/loaders/market-share-base-chart-loader";
import { analyticsChartInteraction } from "../../../../shared/analytics/actions";
import { PRODUCT_360_VIEW_TYPE_DATA } from "../../../constants";
import { CHART_TYPE_SELECTED } from "../../../../shared/analytics/constants";
import sumDataPoints from "../../../../../utils/other/sumDataPoints";
import { dateFormatter } from "../../../utils";

const DEFAULT_SELECTED_CHART = "Pie";
const X_KEY = "currentDate";
const CHARTS_TITLE = "Promotional Sales";

const PromotionPieChart = ({ data, dataKeys }) => {
  const sumData = () => {
    return sumDataPoints(data, Object.keys(dataKeys));
  };
  return (
    <PieChartComponent
      size={400}
      dataKeys={dataKeys}
      valueFormatter={Decorators.priceDecorator}
      data={sumData()}
      baseStyles={styles}
    />
  );
};

const PromotionLineChart = ({ data, dataKeys }) => {
  const tooltipCallback = () => {
    return LineTooltipComponent({
      valueDecorator: Decorators.priceDecorator,
      entities: dataKeys,
      keyFormatter: (x) => dataKeys[x].displayName,
      xKey: X_KEY,
    });
  };

  return (
    <LineChartComponent
      id={"promo-line-chart"}
      data={data.map((dataPoint) => {
        return {
          currentDate: new Date(dataPoint.currentDate).getTime(),
          currentPromoGmv: dataPoint.currentPromoGmv,
          currentNoPromoGmv: dataPoint.currentNoPromoGmv,
        };
      })}
      xScaleType={"time"}
      dataKeys={dataKeys}
      keyFormatter={(x) => dataKeys[x].displayName}
      xKey={X_KEY}
      yTickFormat={Decorators.chartTickPriceFormat}
      tooltipCallback={tooltipCallback()}
      baseStyles={styles}
      xNumTicks={8}
      stack={true}
    />
  );
};

const PromotionBarChart = ({ data, dataKeys }) => {
  const tooltipCallback = () => {
    return BarTooltipComponent({
      valueDecorator: Decorators.priceDecorator,
      fields: dataKeys,
      keyFormatter: dateFormatter,
      xKey: X_KEY,
    });
  };

  return (
    <BarStackComponent
      data={data.map((dataPoint) => {
        return {
          currentDate: dataPoint.currentDate,
          currentPromoGmv: dataPoint.currentPromoGmv,
          currentNoPromoGmv: dataPoint.currentNoPromoGmv,
        };
      })}
      dataKeys={dataKeys}
      keyFormatter={(x) => dataKeys[x].displayName}
      xValuesNumeric={false}
      xKey={X_KEY}
      xScaleType={"band"}
      xTickFormat={dateFormatter}
      yTickFormat={Decorators.chartTickPriceFormat}
      tooltipCallback={tooltipCallback()}
      baseStyles={styles}
      xNumTicks={8}
    />
  );
};

const PromotionActivitiesCharts = ({ chartsData, isLoading }) => {
  const [selectedChart, setSelectedChart] = useState(DEFAULT_SELECTED_CHART);

  const dataKeys = {
    currentPromoGmv: {
      displayName: `Promo`,
      stroke: "#D871D4",
      width: 16,
    },
    currentNoPromoGmv: {
      displayName: `No Promo`,
      stroke: "#41CAC2",
      width: 16,
    },
  };

  const onSelectChartType = (t) => {
    setSelectedChart(t);
    analyticsChartInteraction(
      PRODUCT_360_VIEW_TYPE_DATA.id,
      CHARTS_TITLE,
      CHART_TYPE_SELECTED,
      t
    );
  };

  return (
    <div className={styles.promotionsCharts}>
      <div className={styles.promotionsChartsHeadline}>
        <div className={parentStyles.subtitle}>{CHARTS_TITLE}</div>
        <ChartToggleComponent
          availableTypes={["Pie", "Line", "Bar"]}
          selectedType={selectedChart}
          onSelectType={(t) => !isLoading && onSelectChartType(t)}
        />
      </div>
      {selectedChart == "Pie" ? (
        <>
          {!isLoading ? (
            <PromotionPieChart data={chartsData} dataKeys={dataKeys} />
          ) : (
            <MarketShareBaseChartLoader>
              <PieChartComponentLoader size={400} />
            </MarketShareBaseChartLoader>
          )}
        </>
      ) : selectedChart == "Line" ? (
        <PromotionLineChart data={chartsData} dataKeys={dataKeys} />
      ) : (
        <PromotionBarChart data={chartsData} dataKeys={dataKeys} />
      )}
    </div>
  );
};

export default PromotionActivitiesCharts;
