import { ApiService, cancelBrands } from "./apiService";
import utils from "../utils";
const apiService = new ApiService();
const { generateQueryUrl } = utils.skus;

export default class BrandsService {
  fetchBrands(
    competitorRootDomainProp,
    clientTopCategoryLevel1Prop,
    clientTopCategoryLevel2Prop,
    getMissingMatchedSkusProp
  ) {
    const config = {
      competitorRootDomain: competitorRootDomainProp,
      clientTopCategoryLevel1: clientTopCategoryLevel1Prop
        ? encodeURIComponent(clientTopCategoryLevel1Prop)
        : "",
      clientTopCategoryLevel2: clientTopCategoryLevel2Prop
        ? encodeURIComponent(clientTopCategoryLevel2Prop)
        : "",
      getMissingMatchedSkus: getMissingMatchedSkusProp,
    };

    const query = generateQueryUrl(config);

    if (cancelBrands != undefined) {
      cancelBrands("cancel");
    }

    // TODO: This is a fix for GOOD-2386. Need to remove this file as part of refactoring, it has no use anymore
    return Promise.resolve(this._transformBrands([]));
  }

  _transformBrands = (data = []) => {
    data.unshift("All");
    let result =
      data.map((item, i) => {
        return {
          name: item,
          id: i,
        };
      }) || [];

    return result;
  };
}
