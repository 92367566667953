import React from "react";
import styles from "./market-share-chart-loader.scss";
import BaseChartComponent from "../../../../../../components/charts/visx/base-chart-component";
import { LegendLoader } from "./legend-loader";

export default function MarketShareBaseChartLoader(props) {
  return (
    <BaseChartComponent>
      <div className={styles.loader}>
        <div className={styles.legend}>
          <LegendLoader />
          <LegendLoader />
          <LegendLoader />
          <LegendLoader />
        </div>
        <div className={styles.chart}>{props.children}</div>
      </div>
    </BaseChartComponent>
  );
}
