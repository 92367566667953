import {
  ApiService,
  cancelSalesAndShare,
  cancelAverageListedPrice,
  cancelAverageSellingPrice,
  cancelCategoryAssortment,
  cancelCategoryInStockRate,
  cancelCustomerPerformanceSummary,
  cancelCategoriesPerformanceSummary,
  cancelCategoriesTotalRevenueGraph,
  cancelCustomerTotalRevenueGraph,
  cancelMarketShareGraph,
  cancelCustomerPricingGraph,
  cancelCategoriesPricingGraph,
  cancelCustomerInStockRatesGraph,
  cancelCategoriesInStockRatesGraph,
  cancelCustomerActiveSellingSkusGraph,
  cancelCategoriesActiveSellingSkusGraph,
} from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();

const postRequest = (funcName, viewConfiguration) => {
  const res = funcName(viewConfiguration)
    .then((res) => {
      return Promise.resolve(res);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
  return res;
};

export default class InsightsBoardService extends BasicViewService {
  fetchSalesAndShare(viewConfiguration) {
    if (cancelSalesAndShare !== undefined) {
      cancelSalesAndShare("cancel");
    }
    return postRequest(
      apiService.requestFetchSalesAndSharePOST,
      viewConfiguration
    );
  }

  fetchAverageListedPrice(viewConfiguration) {
    if (cancelAverageListedPrice !== undefined) {
      cancelAverageListedPrice("cancel");
    }
    return postRequest(
      apiService.requestAverageListedPricePOST,
      viewConfiguration
    );
  }

  fetchAverageSellingPrice(viewConfiguration) {
    if (cancelAverageSellingPrice !== undefined) {
      cancelAverageSellingPrice("cancel");
    }
    return postRequest(
      apiService.requestAverageSellingPricePOST,
      viewConfiguration
    );
  }

  fetchCategoryAssortment(viewConfiguration) {
    if (cancelCategoryAssortment !== undefined) {
      cancelCategoryAssortment("cancel");
    }
    return postRequest(
      apiService.requestCategoryAssortmentPOST,
      viewConfiguration
    );
  }

  fetchCategoryInStockRate(viewConfiguration) {
    if (cancelCategoryInStockRate !== undefined) {
      cancelCategoryInStockRate("cancel");
    }
    return postRequest(
      apiService.requestCategoryInStockRatePOST,
      viewConfiguration
    );
  }

  fetchCustomerPerformanceSummary(viewConfiguration) {
    if (cancelCustomerPerformanceSummary !== undefined) {
      cancelCustomerPerformanceSummary("cancel");
    }
    return postRequest(
      apiService.requestCustomerPerformanceSummaryPOST,
      viewConfiguration
    );
  }

  fetchCategoriesPerformanceSummary(viewConfiguration) {
    if (cancelCategoriesPerformanceSummary !== undefined) {
      cancelCategoriesPerformanceSummary("cancel");
    }
    return postRequest(
      apiService.requestCategoriesPerformanceSummaryPOST,
      viewConfiguration
    );
  }

  fetchCustomerTotalRevenue(viewConfiguration) {
    if (cancelCustomerTotalRevenueGraph !== undefined) {
      cancelCustomerTotalRevenueGraph("cancel");
    }
    return postRequest(
      apiService.requestCustomerTotalRevenuePOST,
      viewConfiguration
    );
  }

  fetchCategoriesTotalRevenue(viewConfiguration) {
    if (cancelCategoriesTotalRevenueGraph !== undefined) {
      cancelCategoriesTotalRevenueGraph("cancel");
    }
    return postRequest(
      apiService.requestCategoriesTotalRevenuePOST,
      viewConfiguration
    );
  }

  fetchMarketShare(viewConfiguration) {
    if (cancelMarketShareGraph !== undefined) {
      cancelMarketShareGraph("cancel");
    }
    return postRequest(apiService.requestMarketSharePOST, viewConfiguration);
  }

  fetchCustomerPricing(viewConfiguration) {
    if (cancelCustomerPricingGraph !== undefined) {
      cancelCustomerPricingGraph("cancel");
    }
    return postRequest(
      apiService.requestCustomerPricingPOST,
      viewConfiguration
    );
  }

  fetchCategoriesPricing(viewConfiguration) {
    if (cancelCategoriesPricingGraph !== undefined) {
      cancelCategoriesPricingGraph("cancel");
    }
    return postRequest(
      apiService.requestCategoriesPricingPOST,
      viewConfiguration
    );
  }

  fetchCustomerActiveSellingSkus(viewConfiguration) {
    if (cancelCustomerActiveSellingSkusGraph !== undefined) {
      cancelCustomerActiveSellingSkusGraph("cancel");
    }
    return postRequest(
      apiService.requestCustomerActiveSellingSkusPOST,
      viewConfiguration
    );
  }

  fetchCategoriesActiveSellingSkus(viewConfiguration) {
    if (cancelCategoriesActiveSellingSkusGraph !== undefined) {
      cancelCategoriesActiveSellingSkusGraph("cancel");
    }
    return postRequest(
      apiService.requestCategoriesActiveSellingSkusPOST,
      viewConfiguration
    );
  }

  fetchCustomerInStockRates(viewConfiguration) {
    if (cancelCustomerInStockRatesGraph !== undefined) {
      cancelCustomerInStockRatesGraph("cancel");
    }
    return postRequest(
      apiService.requestCustomerInStockRatesPOST,
      viewConfiguration
    );
  }

  fetchCategoriesInStockRates(viewConfiguration) {
    if (cancelCategoriesInStockRatesGraph !== undefined) {
      cancelCategoriesInStockRatesGraph("cancel");
    }
    return postRequest(
      apiService.requestCategoriesInStockRatesPOST,
      viewConfiguration
    );
  }

  exportSalesAndShare(viewConfiguration) {
    return postRequest(apiService.exportSalesAndSharePOST, viewConfiguration);
  }

  exportCategoryAssortment(viewConfiguration) {
    return postRequest(
      apiService.exportCategoryAssortmentPOST,
      viewConfiguration
    );
  }

  exportAverageListedPrice(viewConfiguration) {
    return postRequest(
      apiService.exportAverageListedPricePOST,
      viewConfiguration
    );
  }

  exportAverageSellingPrice(viewConfiguration) {
    return postRequest(
      apiService.exportAverageSellingPricePOST,
      viewConfiguration
    );
  }

  exportCategoryInStockRate(viewConfiguration) {
    return postRequest(
      apiService.exportCategoryInStockRatePOST,
      viewConfiguration
    );
  }
}
