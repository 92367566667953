import {
  EVENT_GROUPS,
  EVENT_TYPE_PROPERTIES,
} from "../../containers/ranking-events/constants";
import { EVENT_TYPE_MB_NAME } from "./consts";

export const getEventFullDisplayName = (eventProps) => {
  if (eventProps) {
    if (eventProps.type) return `${eventProps.type}: ${eventProps.title}`;
    return eventProps.title;
  }
  return "Custom trigger";
};

export const hasNotifications = (view) => view.notificationFrequency > 0;

export const getEventsDefinition = (filterDefinitions) =>
  filterDefinitions.find((x) => x.mbName == EVENT_TYPE_MB_NAME);

export const getSelectedEventType = (productAttributes) =>
  productAttributes?.find((x) => x.mbName == EVENT_TYPE_MB_NAME)?.values?.[0];

export const getSelectedEventTypeProperties = (
  productAttributes,
  eventsDefinition
) => {
  const selectedEventType = getSelectedEventType(productAttributes);
  if (!selectedEventType) return null;
  const eventIndex = eventsDefinition.enumDictionary[selectedEventType];
  if (eventIndex != undefined) return EVENT_TYPE_PROPERTIES[eventIndex];
  return null;
};

export const sortEventsByGroup = () => {
  const byGroup = {};
  for (const [eventIndex, eventProps] of Object.entries(
    EVENT_TYPE_PROPERTIES
  )) {
    const eventType = eventProps.type ?? EVENT_GROUPS.other;
    if (!byGroup[eventType]) byGroup[eventType] = [eventIndex];
    else byGroup[eventType].push(eventIndex);
  }
  return byGroup;
};

export const getEventNameIndex = (eventsDefinition, eventName) =>
  Object.entries(eventsDefinition.enumDictionary).find(
    ([k, v]) => k == eventName
  )[1];

export const setEventType = (
  eventsDefinition,
  eventIndex,
  productAttributes
) => {
  const eventName = Object.entries(eventsDefinition.enumDictionary).find(
    ([k, v]) => v == eventIndex
  )?.[0];
  if (!eventName) return;
  const attribute = {
    displayName: eventsDefinition.displayName,
    mbName: eventsDefinition.mbName,
    type: eventsDefinition.type,
    hasValue: true,
    values: [eventName],
    criterion: 0,
    childAttributes: [],
  };
  const newAttrs = [...productAttributes];
  const i = newAttrs.findIndex((x) => x.mbName == EVENT_TYPE_MB_NAME);
  if (i > -1) newAttrs[i] = attribute;
  else newAttrs.push(attribute);
  return newAttrs;
};
