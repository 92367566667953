import React from "react";
import DataTable from "../../../../components/data-table/data-table";

import * as Decorators from "../../../../styles/cell-decorators";
import styles from "../users-infinite-table/users-infinite-table.scss";

function prepareDataForTable(data) {
  return data;
}

function UsersTable(props) {
  const columnsConfig = [
    {
      id: -1,
      label: "Email",
      accessor: "userName",
      tooltip: "",
      cellDecorator: Decorators.noDecorator,
    },
    {
      id: 0,
      label: "First Name",
      accessor: "firstName",
      tooltip: "",
      cellDecorator: Decorators.noDecorator,
    },
    {
      id: 1,
      label: "Last Name",
      accessor: "lastName",
      tooltip: "",
      cellDecorator: Decorators.noDecorator,
    },
    {
      id: 2,
      label: "Domain",
      accessor: "rootDomain",
      tooltip: "",
      cellDecorator: Decorators.noDecorator,
    },
  ];

  const sortableColumns = [];

  return (
    <>
      <DataTable
        header="User Table"
        columnsConfig={columnsConfig}
        onSaveSelectedColumns={props.onSaveSelectedColumns}
        excludedTableColumns={props.excludedTableColumns}
        sorting={props.sorting}
        selectedColumns={props.selectedColumns}
        sortableColumns={sortableColumns}
        disableTooltipForColumns={[-1, 0, 1, 2]}
        prepareSortingValues={props.prepareSortingValues}
        prepareDataForTable={prepareDataForTable}
        onSelectRow={props.onSelectRow}
        styles={styles}
        data={props.productList}
      />
    </>
  );
}

export default UsersTable;
