const MarketShareTableSortBy = {
  Gmv: 0,
  GmvChange: 1,
  GmvShare: 2,
  GmvShareChange: 3,
  Sales: 4,
  SalesChange: 5,
  SalesShare: 6,
  SalesShareChange: 7,
};

const convertMarketShareTableSortValue = (sortValue) => {
  if (sortValue == "Sales Dollars" || sortValue == 0) {
    return "Gmv";
  }

  if (sortValue == "Sales Dollars Change" || sortValue == 1) {
    return "GmvChange";
  }

  if (sortValue == "Sales Dollars Share" || sortValue == 2) {
    return "GmvShare";
  }

  if (sortValue == "Sales Dollars Share Change" || sortValue == 3) {
    return "GmvShareChange";
  }

  if (sortValue == "Sales" || sortValue == 4) {
    return "Sales";
  }

  if (sortValue == "Sales Change" || sortValue == 5) {
    return "SalesChange";
  }

  if (sortValue == "Sales Share" || sortValue == 6) {
    return "SalesShare";
  }

  if (sortValue == "Sales Share Change" || sortValue == 7) {
    return "SalesShareChange";
  }

  return sortValue;
};

export default convertMarketShareTableSortValue;

export const convertMarketShareTableSortValue2 = (sortValue) => {
  if (sortValue == "Sales Dollars" || sortValue == 0) {
    return MarketShareTableSortBy.Gmv;
  }

  if (sortValue == "Sales Dollars Change" || sortValue == 1) {
    return MarketShareTableSortBy.GmvChange;
  }

  if (sortValue == "Sales Dollars Share" || sortValue == 2) {
    return MarketShareTableSortBy.GmvShare;
  }

  if (sortValue == "Sales Dollars Share Change" || sortValue == 3) {
    return MarketShareTableSortBy.GmvShareChange;
  }

  if (sortValue == "Sales" || sortValue == 4) {
    return MarketShareTableSortBy.Sales;
  }

  if (sortValue == "Sales Change" || sortValue == 5) {
    return MarketShareTableSortBy.SalesChange;
  }

  if (sortValue == "Sales Share" || sortValue == 6) {
    return MarketShareTableSortBy.SalesShare;
  }

  if (sortValue == "Sales Share Change" || sortValue == 7) {
    return MarketShareTableSortBy.SalesShareChange;
  }

  return sortValue;
};

export const convertMarketShareTableSortValueToColumn = (sortValue) => {
  if (sortValue == MarketShareTableSortBy.Gmv) {
    return 0;
  }

  if (sortValue == MarketShareTableSortBy.GmvChange) {
    return 1;
  }

  if (sortValue == MarketShareTableSortBy.GmvShare) {
    return 2;
  }

  if (sortValue == MarketShareTableSortBy.GmvShareChange) {
    return 3;
  }

  if (sortValue == MarketShareTableSortBy.Sales) {
    return 4;
  }

  if (sortValue == MarketShareTableSortBy.SalesChange) {
    return 5;
  }

  if (sortValue == MarketShareTableSortBy.SalesShare) {
    return 6;
  }

  if (sortValue == MarketShareTableSortBy.SalesShareChange) {
    return 7;
  }

  return sortValue;
};

export const convertMarketShareTableConfigSortFieldToBrowserString = (
  viewTypeId,
  sortValue
) => {
  return Object.keys(MarketShareTableSortBy).find(
    (key) => MarketShareTableSortBy[key] === sortValue
  );
};

export const convertMarketShareTableBrowserStringSortFieldToConfig = (
  viewTypeId,
  sortValue,
  frequency
) => {
  var key = Object.keys(MarketShareTableSortBy).find(
    (k) => k.toLowerCase() === sortValue.toLowerCase()
  );
  return MarketShareTableSortBy[key];
};
