import React, { useEffect, useState } from "react";
import styles from "./trigger-list.scss";
import { SCREENS } from "../../consts";
import Button from "../../../../components/button";
import HeaderContainer from "../../components/header-container";
import {
  deleteInsightsViewConfig,
  fetchInsightsViewsConfigs,
  fetchInsightsViewsDefinitions,
  updateInsightsViewConfig,
} from "../../../../containers/insights/insights-data-controls/actions";
import _ from "lodash";
import PencilIcon from "../../../../images/pencil.svg";
import TrashIcon from "../../../../images/trash.svg";
import NoTriggers from "../../../../images/no-triggers.svg";
import Switch from "../../../../components/switch/switch";
import { NOTIFICATION_FREQUENCY } from "../../../../utils/other/constants";
import {
  getEventFullDisplayName,
  getEventsDefinition,
  getSelectedEventTypeProperties,
  hasNotifications,
} from "../../utils";
import CustomScrollbar from "../../../../components/scrollbar/customScrollbar";
import TriggerSkeleton from "./trigger-skeleton";
import BasicViewService from "../../../../services/basicViewService";

const TriggerList = ({
  moveToScreen,
  formData,
  setFormData,
  configData,
  setConfigData,
  sendToast,
  loadingDefinitions,
}) => {
  const isLoading =
    loadingDefinitions ||
    !configData.views ||
    !(configData?.filterDefinitions?.length > 0);

  const onNewTrigger = () => {
    moveToScreen(SCREENS.triggerPresets);
  };

  const onViewEdit = (view, screen) => {
    setFormData(_.cloneDeep(view));
    setConfigData({
      ...configData,
      mode: "edit",
      custom: false,
      filterValueAttribute: null,
    });
    moveToScreen(screen);
  };

  const onViewDelete = (viewConfig) => {
    configData.subspace
      .dispatch(deleteInsightsViewConfig(configData.apiProvider, viewConfig.id))
      .then((res) => {
        sendToast(`Deleted view "${viewConfig.name}"`, "info");
      })
      .catch((err) => {
        sendToast(`Could not delete view "${viewConfig.name}"`, "error");
      });
    const viewsClone = [...configData.views];
    viewsClone.splice(
      viewsClone.findIndex((x) => x.id == viewConfig.id),
      1
    );
    // optimistic delete
    setConfigData({
      ...configData,
      views: viewsClone,
    });
  };

  const onViewAlertToggle = (viewConfig) => {
    const viewClone = _.cloneDeep(viewConfig);
    if (viewClone.notificationFrequency != NOTIFICATION_FREQUENCY.none) {
      // currently has alert, wants to toggle off
      if (!viewClone.query.settings) viewClone.query.settings = {};
      viewClone.query.settings.preservedNotificationFrequency =
        viewClone.notificationFrequency;
      viewClone.notificationFrequency = NOTIFICATION_FREQUENCY.none;
    } else {
      // alert is toggled off. If it wasn't previously configured, allow the user to configure.
      if (
        !viewClone.query.settings?.preservedNotificationFrequency ||
        !(viewClone.emails?.length > 0)
      ) {
        onViewEdit(
          {
            ...viewConfig,
            notificationFrequency: NOTIFICATION_FREQUENCY.hour,
          },
          SCREENS.triggerDefinition
        );
        return;
      }
      viewClone.notificationFrequency =
        viewClone.query.settings.preservedNotificationFrequency;
    }

    // save current state before optimistic update
    const viewsBefore = [...configData.views];

    // perform optimistic update
    const modifiedViews = [...configData.views];
    const i = modifiedViews.findIndex((x) => x.id == viewConfig.id);
    modifiedViews[i] = viewClone;
    setConfigData({
      ...configData,
      views: modifiedViews,
    });

    configData.subspace
      .dispatch(updateInsightsViewConfig(configData.apiProvider, viewClone))
      .then((res) => {
        sendToast(`Modified trigger "${viewClone.name}"`, "success");
      })
      .catch((err) => {
        sendToast("An error occurred", "error");
        // revert optimistic update on api call failure
        setConfigData({
          ...configData,
          views: viewsBefore,
        });
      });
  };

  const eventsDefinition = getEventsDefinition(configData.filterDefinitions);

  const View = ({ viewConfig }) => {
    const eventProps = getSelectedEventTypeProperties(
      viewConfig?.query?.productAttributes,
      eventsDefinition
    );

    const parseViewFilters = () => {
      let toStr = "";
      for (const attr of viewConfig.query.productAttributes) {
        const def = configData.filterDefinitions.find(
          (x) => x.mbName == attr.mbName
        );
        if (!def) continue;
        switch (attr.type.toLowerCase()) {
          case "collection":
          case "text":
            toStr += `${toStr.length > 0 ? ", " : ""}${def.displayName}: ${
              attr.criterion != 0 ? "not " : ""
            }${attr.values.join(", ")}`;
            break;
          default:
            break;
        }
      }
      return toStr;
    };

    return (
      <div className={styles.viewItem}>
        <div className={styles.viewLeftSide}>
          <img src={eventProps?.icon ?? null} />
          <div className={styles.viewDescription}>
            <h3 style={{ width: "min-content", margin: 0 }}>
              {viewConfig.name}
            </h3>
            <div style={{ whiteSpace: "nowrap" }}>
              <b>{eventProps && getEventFullDisplayName(eventProps)}</b>
            </div>
            <div className={styles.filtersPreview}>{parseViewFilters()}</div>
          </div>
        </div>
        <div className={styles.viewActionsVertical}>
          <div className={styles.viewAlertToggle}>
            <Switch
              titleRight="On"
              titleLeft="Off"
              toggled={hasNotifications(viewConfig)}
              onChange={() => onViewAlertToggle(viewConfig)}
            />
          </div>
          <div className={styles.viewActionsHorizontal}>
            <img
              className={styles.actionButton}
              onClick={() => onViewEdit(viewConfig, SCREENS.customFilter)}
              src={PencilIcon}
            />
            <img
              className={styles.actionButton}
              onClick={() => onViewDelete(viewConfig)}
              src={TrashIcon}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <HeaderContainer>
        <div className={styles.header}>
          <div style={{ width: "min-content" }}>
            <h2>Triggers settings</h2>
          </div>
          <Button
            text={"+ New trigger"}
            active={!isLoading}
            onClick={() => onNewTrigger()}
          />
        </div>
      </HeaderContainer>
      <CustomScrollbar style={{ height: 400 }}>
        <div
          style={{
            padding: "20px 30px",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          {isLoading ? (
            [0, 1, 2].map((i) => <TriggerSkeleton key={i} />)
          ) : configData.views?.length > 0 ? (
            configData.views.map((viewConfig) => (
              <View key={viewConfig.id} viewConfig={viewConfig} />
            ))
          ) : (
            <div className={styles.noTriggers}>
              <img src={NoTriggers} />
              <h3>There are no triggers for events</h3>
            </div>
          )}
        </div>
      </CustomScrollbar>
    </>
  );
};

export default TriggerList;
