export const convertSkuToSkusFlat = (sku, index = 0) => {
  const skuEntry = { ...sku.skuEntry };
  skuEntry.sku = sku.sku;
  skuEntry.brand = sku.brand;
  skuEntry.price = sku.price;
  skuEntry.upc = sku.upc;
  skuEntry.mpn = sku.mpn;
  skuEntry.ean = sku.ean;
  skuEntry.url = sku.url;
  skuEntry.rootDomain = sku.rootDomain;
  skuEntry.name = sku.name;
  skuEntry.mbVariants = sku.mbVariants;
  skuEntry.category = sku.category;
  skuEntry.categoryPath = sku.category?.split(">").map(item=>item.trim()) ?? [];
  var competitor = {
    ...skuEntry,
    images: [sku.image],
    availabilityHistory: sku.availabilityHistory,
    dailyViewsHistory: sku.dailyViewsHistory,
    priceHistory: sku.priceHistory,
    dailyGmvHistory: sku.dailyGmvHistory,
    searchTermsHistory: sku.searchTermsHistory,
  };
  return {
    id: index,
    competitorSku: competitor,
    matchedCustomerSku: {},
    insights: {},
  };
};
