import React from "react";
import moment from "moment";
import styles from "./tooltip-component.scss";
import { decorateValue } from "../../../../styles/cell-decorators";
import BaseTooltipComponent from "./base-tooltip-component";
import LegendItemWrapper from "../../../../visx_components/legend/legendItem";

const LineTooltipComponent = (props) => (datumProps, highlightKey) => {
  const datum = datumProps?.nearestDatum?.datum;
  const {
    section,
    entities,
    valueDecorator,
    keyFormatter = (x) => x,
    xKey,
  } = props;
  return (
    datum && (
      <BaseTooltipComponent additionalStyles={styles.boxshadowStyle}>
        <table>
          <tbody>
            <tr className={styles.tooltipRow}>
              <td>{moment(datum[xKey]).format("MM/DD/YYYY")}</td>
              <td>{section}</td>
            </tr>
            {Object.keys(datum).map(
              (x, i) =>
                x !== xKey && (
                  <tr
                    key={i}
                    className={
                      highlightKey
                        ? highlightKey === x
                          ? styles.tooltipRowHighlight
                          : styles.tooltipRowDim
                        : styles.tooltipRow
                    }
                  >
                    <td className={styles.tooltipLegendWrapper}>
                      <LegendItemWrapper
                        label={{
                          value: entities[x].stroke,
                        }}
                      />
                      {keyFormatter(x)}
                    </td>
                    <td>{decorateValue(datum[x], valueDecorator)}</td>
                  </tr>
                )
            )}
          </tbody>
        </table>
      </BaseTooltipComponent>
    )
  );
};

export default LineTooltipComponent;
