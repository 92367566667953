import React from "react";
import defaultStyles from "./link-button.scss";

const LinkButton = ({ href, text, styles }) => {
  return (
    <a className={defaultStyles.linkWrapper} href={href} target="_blank">
      <span className={styles}>{text ?? ""}</span>
      <div className={defaultStyles.linkBox}></div>
    </a>
  );
};

export default LinkButton;
