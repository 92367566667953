import React from "react";
import styles from "./beta-tag.scss";

// sizes: sm, md
const BetaTag = ({ size = "md" }) => {
  const fontSize = size == "sm" ? 12 : 14;
  return (
    <div className={styles.betaTag} style={{ fontSize: fontSize }}>
      BETA
    </div>
  );
};

export default BetaTag;
