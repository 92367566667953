import React from "react";
import BaseComponent from "../base-component";
import DataTable from "../../../components/data-table/data-table";

export default function TableComponent(props) {
  const {
    id,
    title,
    columns,
    styles,
    disableTooltipForColumns,
    prepareDataForTable,
    onSelectRow,
    excludedTableColumns,
    selectedColumns,
    sortableColumns,
    sortingColumn,
    hideColumnsDropdown,
    componentData,
  } = props;

  const { data, relevantStartDate, relevantEndDate } = componentData ?? {};
  const dataRelevantTo = {
    relevantStartDate,
    relevantEndDate,
  };

  const selectedColumnsForTable = selectedColumns || columns.map((x) => x.id);

  const isLoading = data === undefined;
  const component =
    isLoading || data.length > 0 ? (
      <div id={!isLoading && `table-${id}`} className={styles}>
        <DataTable
          header={title ?? "Table"}
          columnsConfig={columns}
          excludedTableColumns={excludedTableColumns || []}
          sorting={sortingColumn}
          selectedColumns={selectedColumnsForTable}
          sortableColumns={sortableColumns || []}
          disableTooltipForColumns={disableTooltipForColumns || []}
          prepareDataForTable={prepareDataForTable || ((data) => data)}
          onSelectRow={onSelectRow || ((x) => x)}
          styles={{ ...styles }}
          data={data ?? []}
          hideColumnsDropdown={hideColumnsDropdown || false}
          isLoading={isLoading}
        />
      </div>
    ) : (
      <div className={styles}>No data to show...</div>
    );

  return (
    <BaseComponent
      {...props}
      component={component}
      styles={styles}
      dataRelevantTo={dataRelevantTo}
      isLoading={isLoading}
      useDefaultLoading={false}
    />
  );
}
