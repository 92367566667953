import {
  FETCH_SKUS_REQUEST,
  FETCH_SKUS_SUCCESS,
  FETCH_SKUS_FAILURE,
  FETCH_SKUS_CANCEL,
  FETCH_DATE_RANGE_SUCCESS,
  FETCH_MORE_SKUS_REQUEST,
  FETCH_MORE_SKUS_SUCCESS,
  FETCH_MORE_SKUS_FAILURE,
  FETCH_MORE_SKUS_CANCEL,
  CHANGE_SKUS_DETAIL_ID,
  CLEAR_SKUS_ITEMS,
  CHANGE_SKUS_SORTING,
  CHANGE_SKUS_OFFSET,
  CHANGE_SKUS_SELECTED_COLUMNS,
} from "../constants";

import InsightsService from "../../../services/insightsService";

const insightsService = new InsightsService();

// skus sorting

export const changeSkusSorting = (data) => ({
  type: CHANGE_SKUS_SORTING,
  payload: { data },
});

export const changeSkusOffset = (value) => ({
  type: CHANGE_SKUS_OFFSET,
  payload: { value },
});

export const changeSkusSelectedColumns = (value) => ({
  type: CHANGE_SKUS_SELECTED_COLUMNS,
  payload: { value },
});

// skus fetch
export const skusRequested = (field) => ({
  type: FETCH_SKUS_REQUEST,
  payload: { field },
});

export const skusSucceed = (field = "productList", data = []) => ({
  type: FETCH_SKUS_SUCCESS,
  payload: { field, data },
});

export const skusError = (error) => ({
  type: FETCH_SKUS_FAILURE,
  payload: error,
});

export const skusCancel = () => ({
  type: FETCH_SKUS_CANCEL,
});

export const dateRangeSucceed = (data = []) => ({
  type: FETCH_DATE_RANGE_SUCCESS,
  payload: { data },
});

function filterSkus(filter, res) {
  let arr = [];
  if (filter === "MatchedSKUs") {
    res.forEach(function (item) {
      if (item["matchedCustomerSku"] !== undefined) {
        arr.push(item);
      }
    });
  }

  if (filter === "UnmatchedSKUs") {
    res.forEach(function (item) {
      if (item["matchedCustomerSku"] === undefined) {
        arr.push(item);
      }
    });
  }

  if (filter == "All" || filter == undefined) {
    arr = res.slice(0);
  }
  return arr;
}

export const fetchSkus = (field, currentViewConfigQuery, viewType) => (
  dispatch
) => {
  dispatch(skusRequested(field));

  insightsService
    .fetchInsightsSkus(currentViewConfigQuery, viewType)
    .then((res) => {
      let arr = filterSkus("All", res.topSkusInsights);
      dispatch(skusSucceed(field, arr));
      dispatch(dateRangeSucceed(res.dateRange));
    })

    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(skusCancel());
      } else {
        dispatch(skusError(err.message));
      }
    });
};

export const fetchTopSkus = (field, currentViewConfigQuery) => (dispatch) => {
  dispatch(skusRequested(field));

  insightsService
    .fetchTopSkus(currentViewConfigQuery)
    .then((res) => {
      dispatch(skusSucceed(field, res.data.skus["All"]));
      dispatch(dateRangeSucceed(res.data.dateRange));
    })

    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(skusCancel());
      } else {
        dispatch(skusError(err.message));
      }
    });
};

export const changeSkusDetailId = (
  activeId = null,
  isVisibleProductDetails = false
) => ({
  type: CHANGE_SKUS_DETAIL_ID,
  payload: { activeId, isVisibleProductDetails },
});

// skus more
export const skusMoreRequested = () => ({
  type: FETCH_MORE_SKUS_REQUEST,
});

export const skusMoreSuccessed = (field = "productList", data = []) => ({
  type: FETCH_MORE_SKUS_SUCCESS,
  payload: { field, data },
});

export const skusMoreError = (error) => ({
  type: FETCH_MORE_SKUS_FAILURE,
  payload: error,
});

export const skusMoreCancel = () => ({
  type: FETCH_MORE_SKUS_CANCEL,
});

export const fetchMoreSkus = (
  lengthProduct,
  field,
  currentViewConfigQuery,
  updatedRroductAttributes,
  /*clientTopCategoryLevel1, clientTopCategoryLevel2,*/ viewType
) => (dispatch) => {
  const updatedCurentViewConfigQuery = {
    ...currentViewConfigQuery,
    productAttributes: updatedRroductAttributes,
    offset: currentViewConfigQuery.offset + currentViewConfigQuery.limit,
  };
  dispatch(skusMoreRequested());

  insightsService
    .fetchInsightsMoreSkus(
      lengthProduct,
      updatedCurentViewConfigQuery,
      viewType
    )
    .then((res) => {
      let arr = filterSkus("All", res);
      dispatch(skusMoreSuccessed(field, arr));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(skusMoreCancel());
      } else {
        dispatch(skusMoreError(err.message));
      }
    });
};

export const fetchMoreTopSkus = (
  lengthProduct,
  field,
  currentViewConfigQuery,
  updatedRroductAttributes
) => (dispatch) => {
  const updatedCurentViewConfigQuery = {
    ...currentViewConfigQuery,
    productAttributes: updatedRroductAttributes,
    offset: currentViewConfigQuery.offset + currentViewConfigQuery.limit,
  };
  dispatch(skusMoreRequested());

  insightsService
    .fetchMoreTopSkus(lengthProduct, updatedCurentViewConfigQuery)
    .then((res) => {
      dispatch(skusMoreSuccessed(field, res.data.skus["All"]));
    })
    .catch((err) => {
      if (err.message === "cancel") {
        dispatch(skusMoreCancel());
      } else {
        dispatch(skusMoreError(err.message));
      }
    });
};

export const clearSkusItems = () => ({
  type: CLEAR_SKUS_ITEMS,
});
