import React from "react";
import styles from "./pie-chart.scss";
import Pie from "@visx/shape/lib/shapes/Pie";
import { Group } from "@visx/group";
import { Annotation, HtmlLabel } from "@visx/annotation";
import * as Decorators from "../../styles/cell-decorators";

const FILL_ARC = "arc_filler";

const PieArc = ({
  path,
  arc,
  color,
  onMouseEnterHandler,
  onMouseLeaveHandler,
  valueFormatter,
}) => {
  const pathValue = path(arc) ?? "";
  const middleAngle =
    Math.PI / 2 - (arc.startAngle + (arc.endAngle - arc.startAngle) / 2);

  const radius = path.outerRadius()(arc);
  const normalX = Math.cos(middleAngle);
  const normalY = Math.sin(-middleAngle);

  const labelX = normalX * 30;
  const labelY = normalY * 30;

  const surfaceX = (radius * normalX) / 6.5;
  const surfaceY = (radius * normalY) / 6.5;

  return (
    <g onMouseEnter={onMouseEnterHandler} onMouseLeave={onMouseLeaveHandler}>
      <path d={pathValue} fill={color} />
      {arc.data.label !== FILL_ARC && (
        <Annotation
          x={normalX * radius}
          y={normalY * radius}
          dx={surfaceX}
          dy={surfaceY}
        >
          {/* <Connector stroke={"#D8DCE2"} type={"elbow"} /> */}
          <HtmlLabel showAnchorLine={false}>
            <div className={styles.pieAnnotation}>
              <div className={styles.title}>{arc.data.displayName}</div>
              <div className={styles.subtitle}>
                {Decorators.decorateValue(arc.data.value, valueFormatter)}
              </div>
            </div>
          </HtmlLabel>
        </Annotation>
      )}
    </g>
  );
};

const PieChart = ({
  size = 400,
  data,
  dataKeys,
  valueFormatter = (x) => x,
}) => {
  const width = size * 1.5;
  const height = size;
  const radiusRatio = 1.5;
  const outerRadius = size / radiusRatio / 2;
  const innerRadius = size / radiusRatio / 2.75;

  const dataToArcs = () => {
    let arcs = [];
    let greaterThanZero = false;
    for (const [key, config] of Object.entries(dataKeys)) {
      arcs.push({
        label: key,
        value: data[key],
        ...config,
      });
      if (data[key] > 0) greaterThanZero = true;
    }
    if (!greaterThanZero) {
      arcs = [
        {
          label: FILL_ARC,
          value: 1,
          stroke: "#dddfe3",
        },
      ];
    }
    return arcs;
  };

  return (
    <div className={styles.pieChart}>
      <svg height={height} width={width}>
        <Group top={height / 2} left={width / 2}>
          <Pie
            data={dataToArcs()}
            pieValue={(x) => x.value}
            outerRadius={outerRadius}
            innerRadius={innerRadius}
            arcStar={0}
            padAngle={0}
          >
            {({ arcs, path }) => {
              return arcs.map((arc) => {
                const onMouseEnterHandler = () => {};
                const onMouseLeaveHandler = () => {};
                return (
                  <PieArc
                    key={arc.data.label}
                    valueFormatter={valueFormatter}
                    arc={arc}
                    path={path}
                    color={arc.data.stroke}
                    onMouseEnterHandler={onMouseEnterHandler}
                    onMouseLeaveHandler={onMouseLeaveHandler}
                  />
                );
              });
            }}
          </Pie>
        </Group>
      </svg>
    </div>
  );
};

export default PieChart;
