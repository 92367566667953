import React from "react";
import styles from "./outline.scss";

export default function Outline(props) {
  const {
    className,
    onClick,
    text,
    active,
    selected,
    imageClass,
    icon,
    id,
    variant,
    iconRight,
  } = props;

  const activeClass = () => {
    switch (variant) {
      case "pale":
        return styles.activePale;
      case "outline":
      default:
        return styles.activeOutline;
    }
  };
  return (
    <div
      id={id}
      className={`${styles.wrapper} ${
        !selected ? (active ? activeClass() : styles.inactive) : null
      } ${selected ? styles.selected : null} ${className}`}
      onClick={active ? onClick : () => {}}
    >
      {icon && <img src={icon} />}
      {imageClass && <i className={imageClass} />}
      <div className={styles.text}>{text}</div>
      {iconRight && <img src={iconRight} />}
    </div>
  );
}
