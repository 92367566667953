import React, { Component } from "react";
import PropTypes from "prop-types";

import styles from "./bar-item.scss";

const BarItem = (props) => {
  const { width, height, rate, bgColor } = props;

  const cssWidth = width * rate;

  return (
    <div
      className={styles.wrapper}
      style={{ width: cssWidth, height, background: bgColor }}
    ></div>
  );
};

BarItem.defaultProps = {
  width: 100,
  height: 10,
  rate: 1,
  bgColor: "",
};

BarItem.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  rate: PropTypes.number,
  bgColor: PropTypes.string,
};

export default BarItem;
