import React, { useRef, useEffect, useState } from "react";

import styles from "./filter-sidebar.scss";

import { isFunction } from "lodash";

import { ConvertFilterSidebarToInsightsFilter } from "./filter-conversions";
import FilterSidebarLoader from "./loader";
import MbViewsManager from "../mb-views-manager/mb-views-manager";
import { analyticsFiltersApplied } from "../../containers/shared/analytics/actions";
import FilterCollection from "./filter-collection";

const FilterSidebar = (props) => {
  const sidebarRef = useRef(null);

  const {
    showSidebar,
    isLoading,
    insightsFiltersCollection,
    filterDefinitions,
    onUpdateFilters,
    onClearFilters,
    setSideBarVisibility = () => {
      console.log("setSideBarVisibility invoked");
    },
    shouldSetSideBarVisibilityOnClickOutside = true,
    lastAvailableDate,
    apiProvider,
    viewTypeData,
    hideViewManager = false,
    filterDefinitionsHaveLoaded,
  } = props;

  const [filtersCollection, setFiltersCollection] = useState([]);

  useEffect(() => {
    if (shouldSetSideBarVisibilityOnClickOutside) {
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  });

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      hideSidebar();
    }
  };

  const hideSidebar = () => {
    setSideBarVisibility(false);
  };

  const onClearFiltersCallback = () => {
    if (isFunction(onClearFilters)) {
      onClearFilters();
    }
  };

  const onUpdateFiltersCallback = () => {
    if (isFunction(onUpdateFilters)) {
      const filters = ConvertFilterSidebarToInsightsFilter(filtersCollection);
      onUpdateFilters(filters);
      analyticsFiltersApplied(viewTypeData.id, filtersCollection);
    }
  };

  return (
    <div
      className={styles.filterModal}
      style={{ display: showSidebar ? "block" : "none" }}
      ref={sidebarRef}
    >
      <div className={styles.modalHeader}>
        <i className={styles.chevronLeft} onClick={hideSidebar} />
      </div>
      {isLoading ? (
        <FilterSidebarLoader />
      ) : (
        <>
          {!hideViewManager && (
            <MbViewsManager
              lastAvailableDate={lastAvailableDate}
              apiProvider={apiProvider}
            />
          )}
          <FilterCollection
            filtersCollection={filtersCollection}
            setFiltersCollection={setFiltersCollection}
            insightsFiltersCollection={insightsFiltersCollection}
            filterDefinitions={filterDefinitions}
            filterDefinitionsHaveLoaded={filterDefinitionsHaveLoaded}
            height={hideViewManager ? 780 : 740}
          />
          <div className={styles.modalFooter}>
            <div>
              <button
                className={[styles.btn, styles.btnClearFilter].join(" ")}
                onClick={onClearFiltersCallback}
              >
                Clear Filters
              </button>
            </div>
            <div>
              <button
                className={[styles.btn, styles.btnUpdateResults].join(" ")}
                onClick={onUpdateFiltersCallback}
              >
                Update Results
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FilterSidebar;
