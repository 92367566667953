import React from "react";
import styles from "./sku-card.scss";
import * as Decorators from "../../../../../styles/cell-decorators";
import Checkmark from "../../../../../components/checkbox/checkmark";

const skuDetailsConfig = [
  { name: "SKU", accessor: "name" },
  { name: "Brand", accessor: "brand" },
  { name: "Product Type", accessor: "productType" },
];

const dataTableConfig = [
  {
    name: "Sales Dollars",
    valueAccessor: "gmv",
    valueDecorator: Decorators.amountDollarsDecorator("gmv"),
    diffAccessor: "gmvChange",
    diffDecorator: Decorators.numberChangeDecorator,
  },
  {
    name: "Sales Share",
    valueAccessor: "gmvShare",
    valueDecorator: Decorators.percentShareDecorator,
    diffAccessor: "gmvShareChange",
    diffDecorator: Decorators.percentChangeDecorator,
  },
  {
    name: "Units",
    valueAccessor: "sales",
    valueDecorator: Decorators.roundNumberDecorator,
    diffAccessor: "salesChange",
    diffDecorator: Decorators.roundNumberChangeDecorator,
  },
  {
    name: "Units Share",
    valueAccessor: "salesShare",
    valueDecorator: Decorators.percentShareDecorator,
    diffAccessor: "salesShareChange",
    diffDecorator: Decorators.percentChangeDecorator,
  },
  {
    name: "In-Stock Rate",
    valueAccessor: "inStockRate",
    valueDecorator: Decorators.percentShareDecorator,
    diffAccessor: "inStockRateChange",
    diffDecorator: Decorators.percentChangeDecorator,
  },
  {
    name: "ASP",
    valueAccessor: "asp",
    valueDecorator: Decorators.priceDecorator,
    diffAccessor: "aspChange",
    diffDecorator: Decorators.percentChangeDecorator,
  },
];

const SkuCard = ({
  data,
  selected,
  entityColor,
  onSelectionUpdate,
  onClick,
}) => {
  return (
    <div className={styles.skuCard} onClick={onClick}>
      <div style={{ position: "relative" }}>
        <img className={styles.skuImage} src={data.image} />
        <div style={{ position: "absolute", top: 8, left: 8 }}>
          <Checkmark
            checked={selected}
            onChange={onSelectionUpdate}
            color={entityColor}
          />
        </div>
      </div>
      <div className={styles.skuTitle}>{data?.skuData?.name ?? ""}</div>
      <table className={styles.dataTable}>
        <tbody>
          {skuDetailsConfig.map((field) => (
            <tr key={field.name}>
              <td className={styles.fieldName}>{field.name}</td>
              <td colSpan={2} className={styles.detailsFieldValue}>
                <div className={styles.fieldValue}>
                  {data[field.accessor] ?? ""}
                </div>
              </td>
            </tr>
          ))}
          <br />
          {dataTableConfig.map((field) => (
            <tr key={field.name}>
              <td className={styles.fieldName}>{field.name}</td>
              <td className={styles.fieldValue}>
                {Decorators.decorateValue(
                  data[field.valueAccessor],
                  field.valueDecorator
                )}
              </td>
              <td className={styles.fieldDiff}>
                {Decorators.decorateValue(
                  data[field.diffAccessor],
                  field.diffDecorator
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkuCard;
