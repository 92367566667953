export const ROOTDOMAINS = {
  WayfairCom: "wayfair.com",
  AmazonCom: "amazon.com",
  OverstockCom: "overstock.com",
  TargetCom: "target.com",
  LazadaSg: "lazada.sg",
  LazadaVn: "lazada.vn",
  LazadaComMy: "lazada.com.my",
  ShopeeSg: "shopee.sg",
  ShopeeVn: "shopee.vn",
  ShopeeComMy: "shopee.com.my",
  ShopeeCoId: "shopee.co.id"
};

export const ROOTDOMAINS_ENUM_NAMES = {
  WayfairCom: "WayfairCom",
  AmazonCom: "AmazonCom",
  OverstockCom: "OverstockCom",
  TargetCom: "TargetCom",
  LazadaSg: "LazadaSg",
  LazadaVn: "LazadaVn",
  LazadaComMy: "LazadaComMy",
  ShopeeSg: "ShopeeSg",
  ShopeeVn: "ShopeeVn",
  ShopeeComMy: "ShopeeComMy",
  ShopeeCoId: "ShopeeCoId"
};

export const NOTIFICATION_FREQUENCY = {
  none: 0,
  hour: 8,
  day: 1,
  week: 2,
  month: 4,
};
