import {
  ApiService,
  cancelBrandPerformanceTable,
  cancelBrandPerformanceGraph,
} from "./apiService";
import BasicViewService from "./basicViewService";
const apiService = new ApiService();

export default class BrandPerformanceService extends BasicViewService {
  fetchBrandPerformanceTable(requestParams) {
    if (cancelBrandPerformanceTable !== undefined) {
      cancelBrandPerformanceTable("cancel");
    }
    const res = apiService
      .requestBrandPerformanceTablePOST(
        `/brandPerformance/table`,
        requestParams
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }

  fetchBrandPerformanceGraph(
    requestParams,
    searchTerm,
    graphType,
    ignoreCancel = false
  ) {
    if (!ignoreCancel && cancelBrandPerformanceGraph !== undefined) {
      cancelBrandPerformanceGraph("cancel");
    }
    const res = apiService
      .requestBrandPerformanceGraphPOST(
        `/brandPerformance/graph/${graphType}/${searchTerm}`,
        requestParams
      )
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        return Promise.reject(error);
      });

    return res;
  }
}
