import React from "react";
import { decorateValue } from "../../../styles/cell-decorators";
import BaseComponent from "../base-component";
import panelStyles from "./panel-component.scss";
import Rectangle from "../../../images/rectangle.svg";
import HorizontalRectangle from "../../../images/horizontal-rectangle.svg";
import PanelComponentLoader from "./panel-component-loader";

export default function PanelComponent(props) {
  const { styles, designPlan, componentData } = props;
  const hasData = componentData && Object.keys(componentData).length;
  const renderedComponent = buildDesignPlan(designPlan, componentData);
  const component = (
    <div
      id={hasData && "topPanels"}
      className={`${panelStyles.panel} ${styles?.panel ?? ""}`}
    >
      {renderedComponent}
    </div>
  );
  return (
    <BaseComponent
      {...props}
      component={component}
      isLoading={!hasData}
      useDefaultLoading={false}
    />
  );
}

function buildDesignPlan(designPlan, data, key = 0) {
  const { columns, rows, cells } = designPlan;
  if (columns) {
    return (
      <>
        {columns.map((x, i) => (
          <div
            className={`${x.className ?? ""} ${panelStyles.columns}`}
            key={i}
          >
            {x.title ? (
              <div
                className={`${x.titleClassName ?? ""} ${panelStyles.cellTitle}`}
              >
                {x.title}
              </div>
            ) : (
              ""
            )}
            {buildDesignPlan(x, data, key + i)}
          </div>
        ))}
      </>
    );
  }
  if (rows) {
    return (
      <>
        {rows.map((x, i) => (
          <div className={`${x.className ?? ""} ${panelStyles.rows}`} key={i}>
            {x.title ? (
              <div
                className={`${x.titleClassName ?? ""} ${panelStyles.cellTitle}`}
              >
                {x.title}
              </div>
            ) : (
              ""
            )}
            {buildDesignPlan(x, data, key + i)}
          </div>
        ))}
      </>
    );
  }
  if (cells) {
    return (
      <>
        {cells.map((x, i) => (
          <div className={`${x.className ?? ""} ${panelStyles.cells}`} key={i}>
            {renderCell(x, data)}
          </div>
        ))}
      </>
    );
  }
}

function renderCell(cell, data) {
  const isLoading = data == null || Object.keys(data).length === 0;
  switch (cell.type) {
    case "data":
      return isLoading ? renderLoaderCell() : renderDataCell(cell, data);
    case "image":
      return isLoading ? renderLoaderCell() : renderImageCell(cell);
    case "separator":
      return renderSeparator(cell);
    case "horizontalSeparator":
      return renderHorizontalSeparator(cell);
    case "custom":
      return renderCustomCell(cell, data);
  }
}

function renderLoaderCell() {
  return <PanelComponentLoader />;
}

function renderDataCell(cell, data) {
  const sectionData = data[cell.accessorSection] ?? data;
  const value = cell.valueDecorator
    ? decorateValue(sectionData[cell.valueAccessor], cell.valueDecorator)
    : sectionData[cell.valueAccessor];

  const diffValue = cell.diffDecorator
    ? decorateValue(sectionData[cell.diffAccessor], cell.diffDecorator)
    : sectionData[cell.diffAccessor];

  return (
    <>
      <div
        className={`${panelStyles.dataTitleContainer} ${
          cell.titleContainer ?? ""
        }`}
      >
        <div className={`${panelStyles.dataTitle} ${cell.titleStyle ?? ""}`}>
          {cell.title}
        </div>

        {cell.taglineAccessor ?? (
          <div className={panelStyles.dataTagLine}>
            {sectionData[cell.taglineAccessor]}
          </div>
        )}
      </div>
      <div className={panelStyles.dataValuesContainer}>
        <div
          id={`${cell.accessorSection}-${cell.valueAccessor}`}
          className={`${panelStyles.dataValue} ${cell.valueStyle ?? ""}`}
        >
          {value}
        </div>
        <div className={`${panelStyles.dataDiff} ${cell.diffStyle ?? ""}`}>
          {diffValue}
        </div>
      </div>
    </>
  );
}

function renderImageCell(cell) {
  return (
    <>
      <div className={panelStyles.image}>
        <img src={cell.image} />
        <div className={panelStyles.imageTitle}>{cell.title}</div>
      </div>
    </>
  );
}

function renderSeparator(cell) {
  return (
    <>
      <div className={`${panelStyles.separator} ${cell.divStyle ?? ""}`}>
        <img className={cell.style ?? ""} src={Rectangle} />
      </div>
    </>
  );
}

function renderHorizontalSeparator(cell) {
  return (
    <>
      <div
        className={`${panelStyles.horizontalSeparator} ${cell.divStyle ?? ""}`}
      >
        <img className={cell.style ?? ""} src={HorizontalRectangle} />
      </div>
    </>
  );
}

function renderCustomCell(cell, data) {
  return <cell.component data={data} {...cell} />;
}
