import React from "react";
import Button from "../../../components/button";
import InsightsAiIcon from "../../../images/ai-insights.svg";

const ToggleAIButton = ({ active, onClick }) => {
  return (
    <Button
      icon={InsightsAiIcon}
      text="Insights"
      onClick={onClick}
      active={active}
    />
  );
};

export default ToggleAIButton;
