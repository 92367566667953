import React from "react";
import LegendWrapper from "../../../visx_components/legend/side-legend";
import defaultStyles from "./base-chart-component.scss";
import BaseChartComponent from "./base-chart-component";
import XYChartDualAxis from "../../../visx_components/xychart/xychart-dual-axis";

export default function DualAxisChartComponent(props) {
  const {
    data = {},

    dataKeys = {},
    legendDataKeys,

    leftTickFormatter,
    leftLabel,
    leftScaleType,

    rightScaleType,
    rightTickFormatter,
    rightLabel,
    rightScaleInvert,

    curveType = "cardinal",
    xTickFormatter,
    xLabel,
    xKey,

    tooltipCallback,
    xScaleType,
    xValuesNumeric,
    xPadding,
    xPaddingInner,
    xNumTicks,
    styles = {},
  } = props;

  const legendLabelProps = Object.keys(legendDataKeys).map((x) =>
    Object.assign(
      {},
      {
        ...legendDataKeys[x],
      }
    )
  );

  const legendLabels = Object.keys(legendDataKeys).map(
    (x) => legendDataKeys[x].label || x
  );

  return (
    <BaseChartComponent styles={styles} data={data}>
      <div className={defaultStyles.legend}>
        <LegendWrapper
          labels={legendLabels}
          labelsProps={legendLabelProps}
          width={16}
        />
      </div>

      <XYChartDualAxis
        data={data}
        dataKeys={dataKeys}
        curveType={curveType}
        xLabel={xLabel}
        xKey={xKey}
        xNumTicks={xNumTicks}
        xTickFormatter={xTickFormatter}
        xScaleType={xScaleType}
        xValuesNumeric={xValuesNumeric}
        xPadding={xPadding}
        xPaddingInner={xPaddingInner}
        yScaleType={"linear"}
        leftTickFormatter={leftTickFormatter}
        leftLabel={leftLabel}
        leftScaleType={leftScaleType}
        rightScaleType={rightScaleType}
        rightTickFormatter={rightTickFormatter}
        rightScaleInvert={rightScaleInvert}
        rightLabel={rightLabel}
        showGridColumns={false}
        tooltipCallback={tooltipCallback}
      />
    </BaseChartComponent>
  );
}
