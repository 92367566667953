import React from "react";
import ContentLoader from "react-content-loader";
import styles from "../dropdown2.scss";

function DropdownBodyLoader(props) {
  return (
    <div className={styles.loader}>
      <ContentLoader speed={2} width={130} height={130}>
        <rect x="10" y="15" rx="3" ry="3" width="10" height="10" />
        <rect x="30" y="15" rx="3" ry="3" width="100" height="10" />

        <rect x="10" y="45" rx="3" ry="3" width="10" height="10" />
        <rect x="30" y="45" rx="3" ry="3" width="100" height="10" />

        <rect x="10" y="75" rx="3" ry="3" width="10" height="10" />
        <rect x="30" y="75" rx="3" ry="3" width="100" height="10" />

        <rect x="10" y="105" rx="3" ry="3" width="10" height="10" />
        <rect x="30" y="105" rx="3" ry="3" width="100" height="10" />
      </ContentLoader>
    </div>
  );
}

export default DropdownBodyLoader;
