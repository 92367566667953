import { isEmpty } from "lodash";
import React from "react";
import FilterAttributesDropdown from "../../../components/filter-attributes-dropdown/filter-attributes-dropdown";
import FilterControls from "../filter-controls";
import styles from "../../../components/dropdown/dropdown2.scss";
import filterItemStyles from "./collection-controls.scss";

function MultipleValuesCollectionFilterControls(props) {
  const { item, onUpdateFilter, conditionOptions } = props;

  const handleFilterPropertiesChange = (selectedProperty) => {
    onUpdateFilter(item.id, {
      selectedProperties: selectedProperty.map((x) => x.value),
      childFilters: [],
    });
  };

  const values = item.values?.map((x) => ({ id: x, name: x, value: x }));
  const title = isEmpty(item.selectedProperties)
    ? "Select property"
    : item.selectedProperties.length <= 2
    ? item.selectedProperties.join(", ")
    : `${item.selectedProperties[0]}, ${item.selectedProperties[1]} and ${
        item.selectedProperties.length - 2
      } more`;
  const titleClassName = isEmpty(item.selectedProperties)
    ? null
    : filterItemStyles.filterConditionName;

  const selectionComponent = (
    <FilterAttributesDropdown
      title={title}
      titleClassName={titleClassName}
      selectedItems={item.selectedProperties}
      options={values}
      handleChangeAttributesValues={handleFilterPropertiesChange}
      styles={styles}
      keepInput={true}
    />
  );

  return (
    <FilterControls
      item={item}
      onUpdateFilter={onUpdateFilter}
      propertyFilterComponent={selectionComponent}
      conditionOptions={conditionOptions}
    />
  );
}

export default MultipleValuesCollectionFilterControls;
