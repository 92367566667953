import React, { useRef } from "react";
import styles from "./events-quick-select.scss";
import { useState } from "react";
import Button from "../../../../components/button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import {
  getEventNameIndex,
  getEventsDefinition,
  getSelectedEventType,
  getSelectedEventTypeProperties,
} from "../../../../mb_components/event-wizard/utils";
import {
  EVENT_GROUPS,
  EVENT_GROUPS_ICONS,
  EVENT_TYPE_PROPERTIES,
} from "../../constants";
import Chevron from "../../../../images/chevron-nav-icon.svg";
import NestedDropdown from "../../../../components/nested-dropdown/nested-dropdown";
import { setCurrentViewConfig } from "../../../insights/insights-data-controls/actions";
import _ from "lodash";
import useClickOutside from "../../../../hooks/useClickOutside";
import { analyticsTriggerSelected } from "../../../shared/analytics/actions";

const EventsQuickSelect = (props) => {
  const {
    viewTypeData,
    productAttributes,
    filterDefinitions,
    viewsConfigsList = [],
    setCurrentViewConfig,
  } = props;
  const [dropdownOpen, setDropdownOpen] = useState();
  const eventsDefinition = getEventsDefinition(filterDefinitions);
  const selectedEvent = getSelectedEventTypeProperties(
    productAttributes,
    eventsDefinition
  );
  const dropdownsRef = useRef();

  useClickOutside(() => setDropdownOpen(false), dropdownsRef);

  const getEventIndexOfView = (x) => {
    const eventType = getSelectedEventType(x.query.productAttributes);
    if (eventType) return getEventNameIndex(eventsDefinition, eventType);
    return -1;
  };

  const onSelect = (viewName, triggerType) => {
    setCurrentViewConfig(
      _.cloneDeep(viewsConfigsList.find((x) => x.name == viewName))
    );
    setDropdownOpen(null);
    analyticsTriggerSelected(viewTypeData.id, viewName, triggerType);
  };

  const sortViewsByEventType = () => {
    if (!viewsConfigsList) return {};
    const byGroup = {};
    for (const view of viewsConfigsList) {
      const eventIndex = getEventIndexOfView(view);
      const eventProps = EVENT_TYPE_PROPERTIES[eventIndex];
      if (!eventProps) continue;
      const eventType = eventProps.type ?? EVENT_GROUPS.other;
      if (!byGroup[eventType]) byGroup[eventType] = {};
      if (!byGroup[eventType][eventIndex]) byGroup[eventType][eventIndex] = [];
      byGroup[eventType][eventIndex].push(view.name);
    }
    return byGroup;
  };
  const byGroup = sortViewsByEventType();

  const EventGroupButton = ({ groupName }) => {
    const isGroupSelected = selectedEvent?.type == groupName;

    const toggleText = isGroupSelected
      ? `${groupName}: ${selectedEvent.title}`
      : groupName;

    const sharedProps = {
      active: true,
      icon: EVENT_GROUPS_ICONS[groupName],
      text: toggleText,
      iconRight: Chevron,
    };

    if (dropdownOpen == groupName) {
      return (
        <div className={styles.eventsButton}>
          <Button variant="outline" {...sharedProps} />
          <NestedDropdown
            options={Object.entries(byGroup[groupName]).map(([i, views]) => ({
              key: EVENT_TYPE_PROPERTIES[i].title,
              options: views ? views.map((x) => ({ key: x })) : null,
            }))}
            selected={{}}
            onSelect={(selection) => onSelect(selection.sub, selection.main)}
          />
        </div>
      );
    }
    return (
      <div className={styles.eventsButton}>
        <Button
          variant={isGroupSelected ? "outline" : "pale"}
          active={true}
          onClick={() => setDropdownOpen(groupName)}
          {...sharedProps}
        />
      </div>
    );
  };

  const EventSingleButton = ({ eventIndex }) => {
    const eventProps = EVENT_TYPE_PROPERTIES[eventIndex];

    const sharedProps = {
      active: true,
      text: eventProps.title,
      icon: eventProps.icon,
      iconRight: Chevron,
    };

    return (
      <div className={styles.eventsButton}>
        {dropdownOpen == eventProps.title ? (
          <>
            <Button variant={"outline"} {...sharedProps} />
            <NestedDropdown
              options={byGroup[EVENT_GROUPS.other][eventIndex].map((view) => ({
                key: view,
              }))}
              selected={{}}
              onSelect={(selection) =>
                onSelect(selection.main, eventProps.title)
              }
            />
          </>
        ) : (
          <Button
            variant={
              eventProps.title == selectedEvent?.title ? "outline" : "pale"
            }
            onClick={() => setDropdownOpen(eventProps.title)}
            {...sharedProps}
          />
        )}
      </div>
    );
  };

  return (
    <div ref={dropdownsRef} className={styles.eventsSelectionContainer}>
      {Object.keys(byGroup).map(
        (eventGroup) =>
          eventGroup != EVENT_GROUPS.other && (
            <EventGroupButton key={eventGroup} groupName={eventGroup} />
          )
      )}
      {byGroup[EVENT_GROUPS.other] &&
        Object.keys(byGroup[EVENT_GROUPS.other]).map((eventIndex) => (
          <EventSingleButton key={eventIndex} eventIndex={eventIndex} />
        ))}
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    productAttributes:
      state.insights.insights.currentViewConfig.query.productAttributes,
    filterDefinitions:
      state.insights.insights.viewDefinitions.filterDefinitions,
    viewsConfigsList: state.insights.insights.viewsConfigsList,
  };
};

export const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCurrentViewConfig }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EventsQuickSelect);
