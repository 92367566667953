export default function createFilterValue(
  mbName,
  type,
  values,
  value,
  criteria,
  displayName
) {
  const filterValue = {
    displayName,
    mbName,
    type,
  };

  if (values) filterValue.values = values;
  if (criteria) filterValue.criteria = criteria;
  if (value) filterValue.value = value;

  return filterValue;
}
