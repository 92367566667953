import React, { useState } from "react";
import DataTable from "../../data-table/data-table";
import DefaultStyles from "./filtered-table.scss";
import RowsLimitLabel from "./rows-limit-label";
import ListSearch from "../../list-search/list-search";

export default function FilteredTable(props) {
  const {
    header,
    data,
    sorting,
    columnsConfig,
    selectedColumns,
    sortableColumns,
    excludedTableColumns = [],
    pinnedColumns = [],
    disableTooltipForColumns = [],
    onSaveSelectedColumns,
    prepareSortingValues,
    prepareDataForTable = (x) => x,
    styles = DefaultStyles,
    maxTableResults,
    maxExportRows,
    filterFieldAccessor = "name",
    onSelectRow,
    onHoverRow,
    isLoading = false,
  } = props;

  const [filteredData, setFilteredData] = useState(data);

  const onSelectFilteredRow = (i) => {
    if (onSelectRow) onSelectRow(filteredData?.[i]);
  };

  const onHoverFilteredRow = (i) => {
    if (onHoverRow) onHoverRow(filteredData?.[i]);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchboxContainer}>
        <ListSearch
          unfilteredData={data}
          setFilteredData={setFilteredData}
          fieldAccessor={filterFieldAccessor}
        />
      </div>
      <DataTable
        header={header}
        columnsConfig={columnsConfig}
        onSaveSelectedColumns={onSaveSelectedColumns}
        excludedTableColumns={excludedTableColumns}
        sorting={sorting}
        selectedColumns={selectedColumns}
        pinnedColumns={pinnedColumns}
        sortableColumns={sortableColumns}
        disableTooltipForColumns={disableTooltipForColumns}
        prepareSortingValues={prepareSortingValues}
        prepareDataForTable={prepareDataForTable}
        styles={styles}
        data={filteredData}
        onSelectRow={onSelectFilteredRow}
        onHoverRow={onHoverFilteredRow}
        isLoading={isLoading}
      />
      {maxTableResults && filteredData.length === maxTableResults && (
        <RowsLimitLabel
          maxTableResults={maxTableResults}
          maxExportRows={maxExportRows}
        />
      )}
    </div>
  );
}
