import createFilterValue from "./createFilterValue";
import { Base64 } from "js-base64";

const generateQueryConfig = (config, field, name) => {
  return {
    ...config,
    [field]: name,
  };
};

const decodeViewConfigQuery = (encodedView) => {
  return JSON.parse(Base64.decode(encodedView));
};

/* Handles urls that are using base64-encoded viewConfigQuery to save views. */
const generateViewConfigQueryFromEncodedJson = (encodedView) => {
  const parsed = decodeViewConfigQuery(encodedView);
  if (parsed.startDate)
    parsed.startDate = new Date(parsed.startDate).toLocaleDateString(
      "zh-Hans-CN"
    );
  if (parsed.endDate)
    parsed.endDate = new Date(parsed.endDate).toLocaleDateString("zh-Hans-CN");

  return parsed;
};

/* Handles urls that are using our query language to save views. (To be deprecated) */
const generateViewConfigQueryFromQueryString = (
  viewTypeId,
  queryString,
  viewDefinitions,
  convertBrowserStringSortFieldToConfig
) => {
  const filtersValues = [];
  const filtersObject = {};
  const queryConfig = {
    sortField: undefined,
    sortOrder: undefined,
    selectedColumns: undefined,
    settings: undefined,
    queryFrequency: undefined,
    productAttributes: filtersValues,
  };

  queryString = queryString.split("% ").join("%25 ");

  if (!queryString) return null;

  const parsedQueryArrayByOrder = decodeURIComponent(queryString).split(
    " ORDER BY "
  );
  const parsedQueryArrayByAnd = parsedQueryArrayByOrder[0].split(" AND");

  if (parsedQueryArrayByOrder[1]) {
    const sortingValues = parsedQueryArrayByOrder[1].toLowerCase();

    const { sortingField, sortingDirection } = createConfigForSorting(
      sortingValues
    );

    queryConfig.sortField = sortingField;
    queryConfig.sortOrder = sortingDirection;
  }
  const dateRangeConfig = {
    startdate: null,
    enddate: null,
    rolling: 0,
  };
  const comparePeriodConfig = {
    previousStartDate: null,
    previousEndDate: null,
    comparePeriod: null,
  };
  parsedQueryArrayByAnd.forEach((item) => {
    const re = /\<(?!\=)|<=|>=|>|=| in /;
    const operator = re.exec(item);
    if (!operator) return;

    if (item.includes("frequency")) {
      const [key, value] = item.split(operator[0]);
      queryConfig.queryFrequency = createConfigForQueryFrequency(value);
    } else if (item.includes("selectedColumns")) {
      const [key, value] = item.split(operator[0]);
      queryConfig.selectedColumns = JSON.parse(value);
    } else if (item.includes("settings")) {
      const [key, value] = item.split(operator[0]);
      queryConfig.settings = JSON.parse(value);
    } else if (item.includes("startdate")) {
      const [key, value] = item.split(operator[0]);
      dateRangeConfig.startdate = value;
    } else if (item.includes("enddate")) {
      const [key, value] = item.split(operator[0]);
      dateRangeConfig.enddate = value;
    } else if (item.includes("rolling")) {
      const [key, value] = item.split(operator[0]);
      dateRangeConfig.rolling = value;
    } else if (item.includes("prevStartDate")) {
      const [key, value] = item.split(operator[0]);
      comparePeriodConfig.previousStartDate = value;
    } else if (item.includes("prevEndDate")) {
      const [key, value] = item.split(operator[0]);
      comparePeriodConfig.previousEndDate = value;
    } else if (item.includes("compare")) {
      const [key, value] = item.split(operator[0]);
      comparePeriodConfig.comparePeriod = value;
    } else {
      const [key, value] = item.split(operator[0]);
      let trimedKey = key.trim();

      const filterDefinition = viewDefinitions?.filterDefinitions?.find(
        ({ displayName }) => toQueryRepr(displayName) === trimedKey
      );

      if (filterDefinition) {
        const { displayName, mbName: name } = filterDefinition;
        if (name) {
          if (["<", "<=", "=", ">=", ">"].includes(operator[0].trim())) {
            filtersObject[name] = createConfigForNumericValues(
              item,
              filtersObject[name]
            );
          } else {
            filtersObject[name] = createConfigForCollectionValues(
              filterDefinition,
              item
            );
          }
          filtersObject[name].displayName = displayName;
        }
      }
    }
  });

  for (let e in filtersObject) {
    const { type, values, value, criteria, displayName } = filtersObject[e];
    filtersValues.push(
      createFilterValue(e, type, values, value, criteria, displayName)
    );
  }
  if (Number(queryConfig.sortField) >= 0)
    queryConfig.sortField = Number(queryConfig.sortField);
  else
    queryConfig.sortField = convertBrowserStringSortFieldToConfig(
      viewTypeId,
      queryConfig.sortField,
      queryConfig.queryFrequency
    );
  return {
    ...queryConfig,
    ...dateRangeConfig,
    ...comparePeriodConfig,
  };
};

function toQueryRepr(mbName) {
  return mbName?.split(" ")?.join("")?.toLowerCase();
}

function createConfigForSorting(sortingValues) {
  let sortingDirection, sortingField;
  if (sortingValues.includes("desc")) {
    sortingDirection = 1;
    sortingField = sortingValues.replace("desc", "").trim();
  } else {
    sortingDirection = 0;
    sortingField = sortingValues.replace("asc", "").trim();
  }

  return { sortingField, sortingDirection };
}

function createConfigForQueryFrequency(queryFrequencyValue) {
  const frequencyMap = [
    { id: 4, value: "Previous4CalendarWeeks" },
    { id: 3, value: "Previous2CalendarWeeks" },
    { id: 2, value: "Last1D" },
    { id: 1, value: "Last7D" },
    { id: 0, value: "Last30D" },
  ];

  return frequencyMap.find((item) => item.value === queryFrequencyValue)?.id;
}

function createConfigForCollectionValues(definition, queryString) {
  const [operator] = /\<(?!\=)|<=|>=|>|=| in /.exec(queryString);
  const [filterName, value] = queryString.split(operator);
  const filterValue = value
    .replace(/\(|\)/g, "")
    .replace("%26", "&")
    .split(" OR ");

  let type = "Collection";

  if (definition) {
    type = definition.type;
  } else {
    if (filterName.includes("category")) {
      type = "Compound";
    }
    if (filterName.includes("text")) {
      type = "Text";
    } else if (filterName.includes("insighttype")) {
      type = "Enum";
    } else if (filterName.includes("hasmatchingsku")) {
      type = "Enum";
    }
  }

  return {
    type,
    values: filterValue.map((item) => item.trim()),
  };
}

function createConfigForNumericValues(queryString, filterObj) {
  const filterValue = {};
  const [operator] = /\<(?!\=)|<=|>=|>|=|in /.exec(queryString);
  const trimedOperator = operator.trim();
  const [filterName, value] = queryString.split(trimedOperator);
  const parsedValue = parseFloat(value);

  if (filterObj?.criteria) {
    switch (operator.trim()) {
      case "<=": {
        if (!isNaN(parsedValue)) {
          filterValue.criterion = 5;
          filterValue.value1 = filterObj.criteria[0].value1;
          filterValue.value2 = parseFloat(value);
        }
        break;
      }
      case ">=": {
        if (!isNaN(parsedValue)) {
          filterValue.criterion = 5;
          filterValue.value1 = parseFloat(value);
          filterValue.value2 = filterObj.criteria[0].value1;
        }
        break;
      }
      default:
        break;
    }
  } else {
    switch (operator.trim()) {
      case "=": {
        filterValue.criterion = 0;
        break;
      }
      case "<": {
        filterValue.criterion = 1;
        break;
      }
      case "<=": {
        filterValue.criterion = 2;
        break;
      }
      case ">": {
        filterValue.criterion = 3;
        break;
      }
      case ">=": {
        filterValue.criterion = 4;
        break;
      }
      default:
        break;
    }
    if (!isNaN(parsedValue)) {
      filterValue.value1 = parseFloat(value);
    }
  }

  return { type: "Numeric", criteria: [filterValue] };
}

export {
  generateQueryConfig,
  generateViewConfigQueryFromQueryString,
  generateViewConfigQueryFromEncodedJson,
};

export default generateQueryConfig;

//http://localhost:8080/insights?q=monthlyrank%20%3E=%2010%20AND%20monthlyrank%20%3C=%20100%20AND%20views%20%3C%2024%20AND%20viewsdiff%20%3E%2040%20AND%20brand%20in%20(%2747%20OR%20(RB)%20OR%201ST%20PLAYER)%20AND%20category%20in%20(Accessible%20Home%20OR%20All%20Chairs%20OR%20All%20Tvs%20\u0026%20Tuner%20Free%20Displays)%20AND%20rootdomain%20in%20(amazon.com%20OR%20bestbuy.ca%20OR%20bestbuy.com)%20AND%20text%20in%20(fffff%20OR%20ggggg%20OR%20wwwwww)%20AND%20text%20in%20(bbbb%20OR%20rrrrr%20OR%20tttttt)%20AND%20text%20in%20(333333%20OR%20dsdfgsdf%20OR%20qwettr4)

// http://localhost:8080/insights?q=
// monthlyrank%20%3E=%2010%20AND%20monthlyrank%20%3C=%20100%20AND%20
// views%20%3C%2024%20AND%20v
// iewsdiff%20%3E%2040%20AND%20
// brand%20in%20(%2747%20OR%20(RB)%20OR%201ST%20PLAYER)%20AND%20
// category%20in%20(Accessible%20Home%20OR%20All%20Chairs%20OR%20All%20Tvs%20&%20Tuner%20Free%20Displays)%20AND%20
// rootdomain%20in%20(amazon.com%20OR%20bestbuy.ca%20OR%20bestbuy.com)%20AND%20
// text%20in%20(fffff%20OR%20ggggg%20OR%20wwwwww)%20AND%20
// text%20in%20(bbbb%20OR%20rrrrr%20OR%20tttttt)%20AND%20
// text%20in%20(333333%20OR%20dsdfgsdf%20OR%20qwettr4)
