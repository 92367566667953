import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./panel-component.scss";

const PanelComponentLoader = (props) => {
  const { speed = 2, width = 180, height = 50, thickness = 1 } = props;
  return (
    <div className={styles.loader}>
      <ContentLoader speed={speed} width={width} height={height}>
        <rect x="0" y="0" rx="3" ry="3" width="30" height="10" />
        <rect x="0" y="25" rx="3" ry="3" width="130" height="25" />
        <rect x="150" y="40" rx="3" ry="3" width="30" height="10" />
      </ContentLoader>
    </div>
  );
};

export default PanelComponentLoader;
