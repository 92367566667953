import React from "react";
import styles from "./side-legend.scss";
import { scaleOrdinal } from "@visx/scale";
import { LegendOrdinal } from "@visx/legend";
import LegendItemWrapper from "./legendItem";
import CustomScrollbar from "../../components/scrollbar/customScrollbar";

export default function SideLegendWrapper(props) {
  const { labels, labelsProps, size, height = 320, width, itemsStyle } = props;
  const labelsScale = scaleOrdinal({
    domain: labels,
    range: labelsProps.map((x) => x.stroke),
  });
  return (
    <CustomScrollbar style={{ height }}>
      <LegendOrdinal scale={labelsScale}>
        {(labels) => (
          <div className={`${styles.items} ${itemsStyle ?? ""}`}>
            {labels.map((label, i) => (
              <LegendItemWrapper
                key={`legend-${i}`}
                size={size}
                width={labelsProps[i]?.width ?? width}
                label={{
                  text: labelsProps[i]?.displayName ?? label.text,
                  value: labelsProps[i]?.stroke,
                  name: labelsProps[i]?.name,
                  image: labelsProps[i]?.image,
                  graphtitle: labelsProps[i]?.graphTitle,
                  totalvalue: labelsProps[i]?.totalValue,
                }}
                strokeDasharray={labelsProps[i]?.strokeDasharray}
              />
            ))}
          </div>
        )}
      </LegendOrdinal>
    </CustomScrollbar>
  );
}
