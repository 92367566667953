import React from "react";
import * as Decorators from "../../../../styles/cell-decorators";
import DataTable from "../../../../components/data-table/data-table";
import styles from "../insights-skus.scss";

import {
  setCustomerNameInString,
  setFrequencyInString,
} from "../../../../utils/other/utilities";

let columnsConfig = [
  {
    id: 1,
    label: "Image",
    accessor: "imageUrl",
    tooltip: "",
    cellDecorator: Decorators.imageDecorator,
  },
  {
    id: 2,
    label: "Title",
    accessor: "title",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 59,
    label: "Has Matching SKU",
    accessor: "hasMatchingSku",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 49,
    label: "{customer} SKU",
    accessor: "customerSku",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 3,
    label: "Competitor",
    accessor: "retailer",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 50,
    label: "Competitor SKU",
    accessor: "competitorSku",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 4,
    label: "Retailer Category",
    accessor: "retailerCategory",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 5,
    label: "Retailer Sub Category",
    accessor: "retailerSubCategory",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 20,
    label: "Category",
    accessor: "mbCategory",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 21,
    label: "Competitor Sub Category",
    accessor: "retailerSubCategory",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 6,
    label: "Brand",
    accessor: "brand",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 19,
    label: "UPC",
    accessor: "upc",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 60,
    label: "MPN",
    accessor: "mpn",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 51,
    label: "EAN",
    accessor: "ean",
    tooltip: "",
    cellDecorator: Decorators.noDecorator,
  },
  {
    id: 13,
    label: "Monthly Competitor Search Rank",
    accessor: "monthlyRank",
    tooltip: "",
    cellDecorator: Decorators.rankDecorator,
  },
  {
    id: 48,
    label: "{customer} Price",
    accessor: "customerPrice",
    tooltip: "",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    id: 14,
    label: "Competitor Price",
    accessor: "competitorPrice",
    tooltip: "",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    id: 15,
    label: "Price Diff",
    accessor: "priceDiff",
    tooltip: "",
    cellDecorator: Decorators.priceDiffDecorator,
  },
  {
    id: 22,
    label: "Price Diff %",
    accessor: "topSkuPriceDiffPct",
    tooltip: "",
    cellDecorator: Decorators.percentageDiffDecorator,
  },
  {
    id: 23,
    label: "Visibility Periods",
    accessor: "topSkuVisibilityPeriods",
    tooltip: "",
    cellDecorator: Decorators.visibilityPeriodDecorator,
  },
  {
    id: 17,
    label: "{customer} Days OOS",
    accessor: "customerOutOfStockDays",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("your_days_oos"),
  },
  {
    id: 16,
    label: "Competitor Days OOS",
    accessor: "outOfStockDays",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("days_oos"),
  },
  {
    id: 24,
    label: "Sales",
    accessor: "sales",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("sales"),
  },
  {
    id: 29,
    label: "Sales 1st Party",
    accessor: "sales1p",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("sales"),
  },
  {
    id: 30,
    label: "Sales 3rd Party",
    accessor: "sales3p",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("sales"),
  },
  {
    id: 26,
    label: "Sales Dollars",
    accessor: "gmv",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("gmv"),
  },
  {
    id: 31,
    label: "Sales Dollars 1st Party",
    accessor: "gmv1p",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("gmv"),
  },
  {
    id: 32,
    label: "Sales Dollars 3rd Party",
    accessor: "gmv3p",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("gmv"),
  },
  {
    id: 25,
    label: "Number Of Reviews",
    accessor: "reviewCountDiff",
    tooltip: "",
    cellDecorator: Decorators.rankDecorator,
  },
  {
    id: 27,
    label: "Average Rating",
    accessor: "averageRating",
    tooltip: "",
    cellDecorator: Decorators.rankDecorator,
  },
  {
    id: 28,
    label: "Number Of Ratings",
    accessor: "ratingCountDiff",
    tooltip: "",
    cellDecorator: Decorators.rankDecorator,
  },
  {
    id: 52,
    label: "Units Sold 30d",
    accessor: "unitsSold30d",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("sales"),
  },
  {
    id: 53,
    label: "Sales Dollars 30d",
    accessor: "salesDollars30d",
    tooltip: "",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    id: 54,
    label: "Sales Dollars 30d Share",
    accessor: "salesDollars30dShare",
    tooltip: "",
    cellDecorator: Decorators.percentageDecorator,
  },
  {
    id: 55,
    label: "Units Sold 60d",
    accessor: "unitsSold60d",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("sales"),
  },
  {
    id: 56,
    label: "Sales Dollars 60d",
    accessor: "salesDollars60d",
    tooltip: "",
    cellDecorator: Decorators.priceDecorator,
  },
  {
    id: 57,
    label: "Units Sold 90d",
    accessor: "unitsSold90d",
    tooltip: "",
    cellDecorator: Decorators.defaultNumericDecorator("sales"),
  },
  {
    id: 58,
    label: "Sales Dollars 90d",
    accessor: "salesDollars90d",
    tooltip: "",
    cellDecorator: Decorators.priceDecorator,
  },
];

const disableTooltipForColumns = [
  1,
  2,
  3,
  4,
  5,
  6,
  19,
  21,
  48,
  49,
  50,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
];
var sortableColumns = [
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  19,
  22,
  24,
  25,
  26,
  27,
  28,
  29,
  30,
  31,
  32,
  48,
  52,
  53,
  54,
  55,
  56,
  57,
  58,
];

function prepareDataForTable(data) {
  const { productList, frequency: frequencyId } = data;
  const frequencyMap = ["", "7d", "1d"];
  const frequency = frequencyMap[frequencyId];

  return productList.map(({ competitorSku, matchedCustomerSku, insights }) => {
    let mbCategory = `${competitorSku.mbCategoryPath?.[0]} > ${
      competitorSku.mbCategoryPath?.[1]
    } > ${
      competitorSku.mbCategoryPath?.[competitorSku.mbCategoryPath?.length - 1]
    }`;
    if (
      !competitorSku.mbCategoryPath ||
      competitorSku.mbCategoryPath.length === 0
    ) {
      mbCategory = "";
    } else if (competitorSku.mbCategoryPath.length === 1) {
      mbCategory = `${competitorSku.mbCategoryPath?.[0]}`;
    } else if (competitorSku.mbCategoryPath.length === 2) {
      mbCategory = `${competitorSku.mbCategoryPath?.[0]} > ${competitorSku.mbCategoryPath?.[1]}`;
    }

    return {
      imageUrl: competitorSku.images?.[0],
      title: competitorSku.name,
      retailer: competitorSku.rootDomain,
      retailerCategory: competitorSku.categoryPath?.[0],
      retailerSubCategory: competitorSku.categoryPath?.[1],
      leafCategory:
        competitorSku.mbCategoryPath?.[
          competitorSku.mbCategoryPath?.length - 1
        ],
      mbCategory: mbCategory,
      brand: competitorSku.brand,
      views: competitorSku["views" + frequency],
      viewsDiff: competitorSku["viewsDiff" + frequency],
      searchChange: competitorSku["viewsDiffPct" + frequency],
      rank: frequency
        ? competitorSku["rankViews" + frequency]
        : competitorSku.rankViews,
      rankDiff: competitorSku["rankViewsDiff" + frequency],
      monthlyViews: competitorSku.views,
      monthlyRank: competitorSku.rankViews,
      competitorPrice: competitorSku.price,
      customerPrice: matchedCustomerSku?.price,
      priceDiff: competitorSku.priceDiff,
      topSkuPriceDiffPct: competitorSku["priceDiffPct" + frequency],
      topSkuVisibilityPeriods: competitorSku["visibilityPeriods" + frequency],
      reviewCountDiff: competitorSku["reviewCountDiff" + frequency],
      averageRating: competitorSku.averageRating,
      ratingCountDiff: competitorSku["ratingCountDiff" + frequency],
      outOfStockDays: competitorSku.outOfStockDays,
      customerOutOfStockDays: matchedCustomerSku?.outOfStockDays,
      upc: competitorSku.upc,
      mpn: competitorSku.mpn,
      ean: competitorSku.ean,
      url: competitorSku.url,
      sales: competitorSku["sales" + frequency],
      sales1p: competitorSku["sales1p" + frequency],
      sales3p: competitorSku["sales3p" + frequency],
      gmv: competitorSku["gmv" + frequency],
      gmv1p: competitorSku["gmv1p" + frequency],
      gmv3p: competitorSku["gmv3p" + frequency],
      competitorSku: competitorSku.sku,
      customerSku: matchedCustomerSku?.sku,
      hasMatchingSku:
        matchedCustomerSku?.sku === undefined || matchedCustomerSku?.sku === ""
          ? "No"
          : "Yes",
      unitsSold30d: competitorSku?.unitsSold30d,
      salesDollars30d: competitorSku?.salesDollars30d,
      salesDollars30dShare: competitorSku?.salesDollars30dShare,
      unitsSold60d: competitorSku?.unitsSold60d,
      salesDollars60d: competitorSku?.salesDollars60d,
      unitsSold90d: competitorSku?.unitsSold90d,
      salesDollars90d: competitorSku?.salesDollars90d,
    };
  });
}

const updateColumnsConfigLabels = (customerName, frequency, columnsConfig) => {
  if (
    customerName !== undefined &&
    customerName !== null &&
    customerName !== ""
  ) {
    columnsConfig = columnsConfig.map((x) => {
      x["label"] = x["dynamicLabel"] ?? x["label"];
      x["label"] = setCustomerNameInString(x["label"], customerName);
      x["label"] = setFrequencyInString(x["label"], frequency);
      return x;
    });
  }
  return columnsConfig;
};

function InsightsSkusTable(props) {
  const data = {
    productList: props.productList,
    frequency: props.frequency,
  };
  if (props.frequency) sortableColumns.push(7, 10);

  columnsConfig = updateColumnsConfigLabels(
    props.customerName,
    props.frequency,
    columnsConfig
  );

  return (
    <>
      <DataTable
        header="Insight Skus"
        columnsConfig={columnsConfig}
        onSaveSelectedColumns={props.onSaveSelectedColumns}
        excludedTableColumns={props.excludedTableColumns}
        sorting={props.sorting}
        selectedColumns={props.selectedColumns}
        sortableColumns={sortableColumns}
        disableTooltipForColumns={disableTooltipForColumns}
        prepareSortingValues={props.prepareSortingValues}
        prepareDataForTable={prepareDataForTable}
        onSelectRow={props.onSelectRow}
        styles={styles}
        data={data}
      />
    </>
  );
}

export default InsightsSkusTable;
