import React from "react";
import defaultStyles from "../modal-wrapper.scss";

function ModalFooterSingleButton(props) {
  const { buttonLabel, onClickConfirmButton, disabled, styles } = props;
  return (
    <>
      <button
        className={`${styles.confirmButton} ${defaultStyles.confirmButton}`}
        onClick={onClickConfirmButton}
        disabled={disabled}
      >
        {buttonLabel}
      </button>
    </>
  );
}

export default ModalFooterSingleButton;
