import React from "react";
import PanelComponent from "../../../../components/dashboard-components/panel-component/panel-component";
import styles from "../../../insights-board/mb-components/category-customer-panel-component/category-customer-panel-component.scss";
import { CategoryCustomerPanelComponentConfig } from "../../../insights-board/mb-components/category-customer-panel-component/category-customer-panel-component-config";

export default function MarketSharePanelComponent(props) {
  const panelConfig = CategoryCustomerPanelComponentConfig(props);

  const designPlan = {
    columns: [
      {
        className: styles.category,
        rows: [
          {
            className: styles.frame,
            cells: [
              {
                ...panelConfig.totalCategorySales,
                valueStyle: styles.mediumValue,
              },
              panelConfig.separator,
              {
                ...panelConfig.totalCategoryUnits,
                valueStyle: styles.mediumValue,
              },
              panelConfig.separator,
              panelConfig.categoryAsp,
              panelConfig.separator,
              panelConfig.categoryAvgListedPrice,
              panelConfig.separator,
              panelConfig.categoryInStock,
              panelConfig.separator,
              panelConfig.categoryActiveSellingSkus,
            ],
          },
        ],
      },
    ],
  };

  return (
    <PanelComponent
      {...props}
      isEnabled={true}
      className={styles}
      designPlan={designPlan}
      hideHeader={true}
      hideBackground={true}
    />
  );
}
