import InsightsBoardCategoryIcon from "../../../../images/insights-board-category.svg";
import InsightsBoardBrandIcon from "../../../../images/insights-board-your-brand.svg";
import styles from "./category-customer-panel-component.scss";

import * as Decorators from "../../../../styles/cell-decorators";

const categoryTitle = (categories) => {
  return categories && categories.length > 0
    ? categories.length === 1
      ? `${categories[0]} Category`
      : "Selected Categories"
    : "All Categories";
};

export const CategoryCustomerPanelComponentConfig = (props) => {
  return {
    separator: {
      type: "separator",
    },
    columnsSeparator: {
      type: "separator",
      style: styles.columnsSeparator,
    },
    categoryImage: {
      type: "image",
      image: InsightsBoardCategoryIcon,
      title: categoryTitle(props.categories),
    },
    totalCategorySales: {
      type: "data",
      title: "Total Sales Dollars",
      taglineAccessor: null,
      accessorSection: "category",
      valueAccessor: "retailSalesDollars",
      valueDecorator: Decorators.priceDecorator,
      diffAccessor: "retailSalesChange",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.largeValue,
    },
    totalCategoryUnits: {
      type: "data",
      title: "Total Units",
      taglineAccessor: null,
      accessorSection: "category",
      valueAccessor: "unitsSold",
      valueDecorator: Decorators.numberDecorator,
      diffAccessor: "unitsSoldChange",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.largeValue,
    },
    categoryAsp: {
      type: "data",
      title: "Avg. Selling Price",
      taglineAccessor: null,
      accessorSection: "category",
      valueAccessor: "avgSellingPrice",
      valueDecorator: Decorators.priceDecorator,
      diffAccessor: "avgSellingPriceDiff",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    categoryAvgListedPrice: {
      type: "data",
      title: "Avg. Retail Price",
      taglineAccessor: null,
      accessorSection: "category",
      valueAccessor: "avgListedPrice",
      valueDecorator: Decorators.priceDecorator,
      diffAccessor: "avgListedPriceDiff",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    categoryInStock: {
      type: "data",
      title: "In-Stock Rate",
      taglineAccessor: null,
      accessorSection: "category",
      valueAccessor: "inStockRate",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "inStockRateDiff",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    categoryActiveSellingSkus: {
      type: "data",
      title: "Active Selling SKUs",
      taglineAccessor: null,
      accessorSection: "category",
      valueAccessor: "activeSellingSkus",
      valueDecorator: Decorators.numberDecorator,
      diffAccessor: "activeSellingSkusDiff",
      diffDecorator: Decorators.roundNumberChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    customerImage: {
      type: "image",
      image: InsightsBoardBrandIcon,
      title: "Your Brand",
    },
    totalUnits: {
      type: "data",
      title: "Total Units",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "customerUnitsSold",
      valueDecorator: Decorators.numberDecorator,
      diffAccessor: "customerUnitsSoldChange",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.largeValue,
    },
    unitShare: {
      type: "data",
      title: "Units Share",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "customerUnitShare",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "customerUnitShareChange",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.largeValue,
    },
    retailSales: {
      type: "data",
      title: "Total Sales Dollars",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "customerRetailSalesDollars",
      valueDecorator: Decorators.priceDecorator,
      diffAccessor: "customerRetailSalesChange",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.largeValue,
    },
    marketShare: {
      type: "data",
      title: "Market Share",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "marketShare",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "marketShareChange",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.largeValue,
    },
    avgListedPrice: {
      type: "data",
      title: "Avg. Retail Price",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "avgListedPrice",
      valueDecorator: Decorators.priceDecorator,
      diffAccessor: "avgListedPriceDiff",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    avgSellingPrice: {
      type: "data",
      title: "Avg. Selling Price",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "avgSellingPrice",
      valueDecorator: Decorators.priceDecorator,
      diffAccessor: "avgSellingPriceDiff",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    inStockRate: {
      type: "data",
      title: "In-Stock Rate",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "customerInStockRate",
      valueDecorator: Decorators.percentDecorator,
      diffAccessor: "customerInStockRateDiff",
      diffDecorator: Decorators.percentChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
    activeSellingSkus: {
      type: "data",
      title: "Active Selling SKUs",
      taglineAccessor: null,
      accessorSection: "customer",
      valueAccessor: "customerActiveSellingSkus",
      valueDecorator: Decorators.roundNumberChangeDecorator,
      diffAccessor: "customerActiveSellingSkusDiff",
      diffDecorator: Decorators.roundNumberChangeDecorator,
      tooltip: null,
      style: {},
      valueStyle: styles.mediumValue,
    },
  };
};

export const CategoryCustomerTableComponentConfig = (props) => {
  const panelConfig = CategoryCustomerPanelComponentConfig(props);
  return [
    {
      title: categoryTitle(props.categories),
      accessorSection: "category",
      fields: [
        panelConfig.totalCategorySales,
        panelConfig.totalCategoryUnits,
        panelConfig.categoryAsp,
        panelConfig.categoryAvgListedPrice,
        panelConfig.categoryInStock,
        panelConfig.categoryActiveSellingSkus,
      ],
    },
    {
      title: "Your brand",
      accessorSection: "customer",
      fields: [
        panelConfig.retailSales,
        panelConfig.marketShare,
        panelConfig.totalUnits,
        panelConfig.unitShare,
        panelConfig.avgSellingPrice,
        panelConfig.avgListedPrice,
        panelConfig.inStockRate,
        panelConfig.activeSellingSkus,
      ],
    },
  ];
};
