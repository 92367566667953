import React from "react";

function dropdownItemHOC(WrappingComponent, toggleDropdownVisibility) {
  return class extends React.Component {
    onSelectHandler = (value) => {
      toggleDropdownVisibility &&
        !value?.isOverfloved &&
        toggleDropdownVisibility();
      this.props.handleClickItem(value);
    };

    render() {
      return (
        <WrappingComponent
          onSelectItem={this.onSelectHandler}
          {...this.props}
        />
      );
    }
  };
}

export default dropdownItemHOC;
