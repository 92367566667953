import ModalWrapper from "./modal-wrapper";
import ModalFooterOneButton from "./modal-footer/modal-footer-one-button";
import ModalFooterTwoButton from "./modal-footer/modal-footer-two-button";

const modalFooter = {
  ModalFooterOneButton,
  ModalFooterTwoButton,
};

export { ModalWrapper, modalFooter };

export default ModalWrapper;
