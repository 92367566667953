import axios from "axios";
import { API_URL } from "../config";
import utils from "../utils";

import { ApiService } from "./apiService";
const apiService = new ApiService();

const { handleServerError } = utils.other;

export default class AuthService {
  request(url, data) {
    const { user, password } = data;

    return axios
      .request({
        url: `${API_URL}${url}`,
        headers: {
          "Content-Type": "text/plain",
          email: user,
          password,
        },
        method: "POST",
        // data
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        handleServerError(error);
        return Promise.reject(error);
      });
  }

  requestLogout() {
    const res = apiService.requestPOST(`/logout`);

    return res;
  }

  sendData(data) {
    const res = this.request(`/login`, data);

    return res;
  }

  requestThirdPartyAuth(data) {
    const { authType, token, id } = data;
    console.log(authType + " " + token + " " + id);

    return axios
      .request({
        url: `${API_URL}/user/thirdPartyAuth`,
        headers: {
          "Content-Type": "text/plain",
          authType: authType,
          token: token,
          id: id,
        },
        method: "POST",
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  requestSupportPortalAuth(authParams) {
    return axios
      .request({
        url: `${API_URL}/login/portal`,
        method: "POST",
        data: authParams,
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  requestForgotPassword({ email }) {
    return axios
      .request({
        url: `${API_URL}/user/password/reset`,
        headers: {
          "Content-Type": "text/plain",
          email: email,
        },
        method: "GET",
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  requestResetPassword({ user, password, confirmPassword, token }) {
    return axios
      .request({
        url: `${API_URL}/user/password/set`,
        headers: {
          "Content-Type": "text/plain",
          email: user,
          password: password,
          token: token,
        },
        method: "POST",
      })
      .then((response) => {
        return Promise.resolve(response);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
