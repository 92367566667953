import styles from "./analysis-kpis-component-config.scss";

import * as Decorators from "../../../../../styles/cell-decorators";

export const AnalysisKpisComponentConfig = {
  serpRank: {
    type: "data",
    title: "SERP Rank",
    taglineAccessor: null,
    accessorSection: "marketShareAnalysisKpis",
    valueAccessor: "currentSerpRank",
    valueDecorator: Decorators.intDecorator,
    diffAccessor: "serpRankChange",
    diffDecorator: Decorators.roundNumberChangeDecorator,
    diffStyle: styles.diffStyle,
    tooltip: null,
    titleStyle: styles.label,
    valueStyle: styles.largeValue,
    className: styles.cells,
  },
  asp: {
    type: "data",
    title: "Average Selling Price",
    taglineAccessor: null,
    accessorSection: "marketShareAnalysisKpis",
    valueAccessor: "currentSkuAverageSellingPrice",
    valueDecorator: Decorators.priceDecorator,
    diffAccessor: "skuAverageSellingPriceChange",
    diffDecorator: Decorators.percentChangeDecorator,
    diffStyle: styles.diffStyle,
    tooltip: null,
    titleStyle: styles.label,
    valueStyle: styles.largeValue,
    className: styles.cells,
  },
  advertising: {
    type: "data",
    title: "Sponsored Rate",
    taglineAccessor: null,
    accessorSection: "marketShareAnalysisKpis",
    valueAccessor: "currentAdvertising",
    valueDecorator: Decorators.percentDecorator,
    diffAccessor: "advertisingChange",
    diffDecorator: Decorators.percentChangeDecorator,
    diffStyle: styles.diffStyle,
    tooltip: null,
    style: {},
    titleStyle: styles.label,
    valueStyle: styles.largeValue,
    className: styles.cells,
  },
  visibility: {
    type: "data",
    title: "Visibility",
    taglineAccessor: null,
    accessorSection: "marketShareAnalysisKpis",
    valueAccessor: "currentVisibility",
    valueDecorator: Decorators.percentDecorator,
    diffAccessor: "visibilityChange",
    diffDecorator: Decorators.percentChangeDecorator,
    diffStyle: styles.diffStyle,
    tooltip: null,
    style: {},
    titleStyle: styles.label,
    valueStyle: styles.largeValue,
    className: styles.cells,
  },
  promotion: {
    type: "data",
    title: "Promotion Events",
    taglineAccessor: null,
    accessorSection: "marketShareAnalysisKpis",
    valueAccessor: "currentPromoDays",
    valueDecorator: Decorators.intDecorator,
    diffAccessor: "promotionChange",
    diffDecorator: Decorators.percentChangeDecorator,
    diffStyle: styles.diffStyle,
    tooltip: null,
    style: {},
    titleStyle: styles.label,
    valueStyle: styles.largeValue,
    className: styles.cells,
  },
  stockPosition: {
    type: "data",
    title: "Stock Position",
    taglineAccessor: null,
    accessorSection: "marketShareAnalysisKpis",
    valueAccessor: "currentStockPosition",
    valueDecorator: Decorators.percentDecorator,
    diffAccessor: "stockPositionChange",
    diffDecorator: Decorators.percentChangeDecorator,
    diffStyle: styles.diffStyle,
    tooltip: null,
    style: {},
    titleStyle: styles.label,
    valueStyle: styles.largeValue,
    className: styles.cells,
  },
  separator: {
    type: "separator",
    divStyle: styles.separatorStyle,
  },
  horizontalSeparator: {
    type: "horizontalSeparator",
  },
};
