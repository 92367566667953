import { isEmpty } from "lodash";
import React from "react";
import FilterDropdown from "../dropdowns/filter-dropdown";
import FilterControls from "../filter-controls";
import styles from "./collection-controls.scss";
import { deepClone } from "../../../utils/other/utilities";
import { CategoryLeaf } from "../../../utils/filters/filtersUtils";

// A quick fix for GOOD-7400. if need to expand, consider move to a neutral location
function NameToValue(item, name) {
  if (item != null) {
    return ["mb_category", "mb_category.keyword"].includes(item.mbName)
      ? CategoryLeaf(name)
      : name;
  }
}

function ValueToName(item, value, name) {
  if (item != null) {
    return ["mb_category", "mb_category.keyword"].includes(item.mbName)
      ? value
      : name;
  }
}

function defaultProperty() {
  return { id: -1, name: "Select property" };
}

function SingleValueCollectionFilterControls(props) {
  const { item, onUpdateFilter, conditionOptions } = props;

  const values =
    item.values?.map((x) => ({
      id: x,
      name: NameToValue(item, x),
      value: x,
    })) ?? [];
  values.sort((x, y) => (x.name > y.name ? 1 : -1));

  const selectedProperty = isEmpty(item.selectedProperties)
    ? defaultProperty()
    : deepClone(
        values.find((x) => x.value === item.selectedProperties) ??
          defaultProperty()
      );

  if (selectedProperty?.value != null) {
    selectedProperty.name = ValueToName(
      item,
      selectedProperty.value,
      selectedProperty.name
    );
  }

  const handleFilterPropertiesChange = (selectedProperty) => {
    onUpdateFilter(item.id, {
      selectedProperties: selectedProperty.value,
      childFilters: [],
    });
  };

  const propertiesFilter = (
    <FilterDropdown
      selectedItemClassName={styles.filterConditionName}
      options={values}
      selectedItem={selectedProperty}
      handleClickItem={handleFilterPropertiesChange}
    />
  );

  return (
    <FilterControls
      item={item}
      onUpdateFilter={onUpdateFilter}
      propertyFilterComponent={propertiesFilter}
      conditionOptions={conditionOptions}
    />
  );
}

export default SingleValueCollectionFilterControls;
