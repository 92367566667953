import React from "react";
import LineChartComponent from "./line-chart-component";
import BarChartComponent from "./bar-chart-component";
import LegendWrapper from "../../../visx_components/legend/side-legend";

const ComboChart = ({
  id,

  barData,
  lineData,

  barDataKeys,
  lineDataKeys,

  xTickFormatter = (x) => x,
  lineTickFormatter = (x) => x,
  barLabelFormatter = (x) => x,

  xKey,
  tooltipCallback,
  baseStyles,
}) => {
  const dataKeys = { ...barDataKeys, ...lineDataKeys };

  const legendLabelProps = Object.keys(dataKeys).map((x) =>
    Object.assign(
      {},
      {
        stroke: dataKeys[x].stroke,
        displayName: dataKeys[x].label,
        strokeDasharray: dataKeys[x].strokeDasharray ?? "0",
        name: dataKeys[x].name,
        width: dataKeys[x].width ?? 16,
      }
    )
  );

  return (
    <div>
      <div style={{ width: "max-content", float: "right" }}>
        <LegendWrapper
          labels={Object.keys(dataKeys)}
          labelsProps={legendLabelProps}
          width={16}
        />
      </div>
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            padding: "0px 40px",
            top: 0,
          }}
        >
          <BarChartComponent
            id={id}
            data={barData}
            dataKeys={barDataKeys}
            xTickFormat={xTickFormatter}
            xKey={xKey}
            yFormatter={barLabelFormatter}
            baseStyles={baseStyles}
            hideLegend={true}
          />
        </div>
        <div style={{ position: "absolute", width: "100%", top: 0 }}>
          <LineChartComponent
            id={id}
            data={lineData}
            dataKeys={lineDataKeys}
            keyFormatter={(x) => x}
            xValuesNumeric={false}
            xScaleType={"band"}
            xKey={xKey}
            xAxis={false}
            yTickFormat={lineTickFormatter}
            tooltipCallback={tooltipCallback}
            baseStyles={baseStyles}
            hideLegend={true}
          />
        </div>
      </div>
    </div>
  );
};

export default ComboChart;
