import _ from "lodash";
import React, { useEffect } from "react";

const DEFAULT_CHART_SETTINGS = {
  selectedType: "Line",
  selectedTab: 0,
  showComparePeriod: false,
};

export const useChartSettings = (
  chartName,
  settings,
  changeSettings,
  initialSettings = DEFAULT_CHART_SETTINGS
) => {
  useEffect(() => {
    if (
      settings &&
      (settings[chartName] === undefined || _.isEmpty(settings[chartName]))
    )
      changeSettings({
        [chartName]: initialSettings,
      });
  }, [settings]);

  const setChartSettings = (newSettings, closeInsightsAiSidebar = true) => {
    changeSettings(
      {
        [chartName]: {
          ...settings[chartName],
          ...newSettings,
        },
      },
      closeInsightsAiSidebar
    );
  };

  return [settings[chartName] ?? DEFAULT_CHART_SETTINGS, setChartSettings];
};
